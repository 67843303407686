import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Typography, CardHeader, Breadcrumbs, Button ,IconButton, Card, CardContent
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import theme, { textClasses, buttonClasses } from "../../../static/styles/theme";
import { Link, useHistory, useParams } from "react-router-dom";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getWorkflowDetails, getAllJobTypes } from "../../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GenericLoader from "../../Components/Generic/GenericLoader";
import CreateUpdateWorkflow from "./CreateUpdateWorkflow";
import { deepCopy_Object, showToast } from "../../../services/functions";

const initialWorkflowState = [
    {
        type: 'Operations',
        subText: '* add all your workflow process steps in their completion order. you can create multiple workflows and update them anytime.',
        // workflowType:'inwardOperations',
        operations: [
            {
                taskName: '',
                taskId:'',
                canBeDeleted: true,
                icon: <DoubleArrowIcon />,
                // type: 'Inward Operations',
            }
        ]
    },
]

export default function AddWorkflow() {
    let { workflowId } = useParams();
    const currentUrl = window.location.pathname
    const isEditable = currentUrl.includes("edit-workflow") && !!workflowId;
    const history = useHistory();
    const dispatch = useDispatch();
    const [items, setItems] = useState([])
    const [loader, setLoader] =useState(false)
    const [workflowDetailData, setWorkflowDetailData] = useState({})
    // State to track the currently active category key
    const [activeCategoryKey, setActiveCategoryKey] = useState(null);
    // State to track previously used category keys
    const [blockedCategoryKeys, setBlockedCategoryKeys] = useState([]);

    const [viewModal,setViewModal]=useState({type:"",action:{}})
    const [collapse, setCollapse] = useState({
        collapse1: false,
        collapse2:false,
        collapse3:false
    })
    const [workflowState, setworkflowState] = useState(deepCopy_Object(initialWorkflowState))
    const Expand = (ind) => {
        var element = document.getElementById(`table-${ind}`);
        let temp = {...collapse}
        if (element.classList.length === 1) {
            element.classList.add("priyanshuScroll");
        }
        else {
            element.classList.remove("priyanshuScroll");
        }
        if(ind=== 0){
            if(collapse.collapse1 === true){
                temp.collapse1 = false
                setCollapse(temp)
            }
            else{
                temp.collapse1 = true
                setCollapse(temp)
            }
        }
        if( ind === 1){
            if(collapse.collapse2 === true){
                temp.collapse2 = false
                setCollapse(temp)
            }
            else{
                temp.collapse2 = true
                setCollapse(temp)
            }
        }
       if(ind === 2){
        if(collapse.collapse3 === true){
            temp.collapse3 = false
            setCollapse(temp)
        }
        else{
            temp.collapse3 = true
            setCollapse(temp)
        }
       }
       
    }

    const removeTask = (index, ind) => {
        // Create a copy of the workflow state
        let temp = [...workflowState];

        // Remove the task at the specified index
        temp[index].operations.splice(ind, 1);

        // Check if operations array is empty after removal
        if (temp[index].operations.length === 0) {
            // If no operations remain, reset activeCategoryKey and blockedCategoryKeys
            setActiveCategoryKey(null);
            setBlockedCategoryKeys([]);
        } else {
            // If operations still exist, update the last operation's canBeDeleted property
            const lastOperation = temp[index].operations[temp[index].operations.length - 1];
            lastOperation.canBeDeleted = true;

            // Safely access taskActionTypes[0]?.categoryKey
            const lastCategoryKey = lastOperation?.taskActionTypes?.[0]?.categoryKey;

            // Update activeCategoryKey
            setActiveCategoryKey(lastCategoryKey);

            // Update blockedCategoryKeys based on the category sequence
            const categorySequence = ["Inward Operations", "In-house Operations", "Outward Operations"];
            const lastCategoryIndex = categorySequence.indexOf(lastCategoryKey);

            // Block all categories before the current active one
            const newBlockedKeys = lastCategoryIndex !== -1 ? categorySequence.slice(0, lastCategoryIndex) : [];
            setBlockedCategoryKeys(newBlockedKeys);
        }

        // Update the workflow state
        setworkflowState(temp);
    };

    

    const ItemTypes = {
        ITEM: 'item',
    };

    const ReOrderTypes = {
        reItem: 'item',
    };

    const DragDropItem = ({ name, outerIndex, index, approved, actionID, categoryKey, order, actionTypes }) => {
        // console.log("props",name, outerIndex, index, approved, actionID, categoryKey, order,actionTypes)
        const ref = React.useRef(null);
        const [, drop] = useDrop({
            accept: ItemTypes.ITEM,
            // drop: (item) => moveItem(item.index, index),
        });

        const [{ isDragging }, drag] = useDrag({
            item: { name, outerIndex, index, approved, actionID, categoryKey, order ,actionTypes },
            type: ItemTypes.ITEM,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const opacity = isDragging ? 0.4 : 1;

        drag(drop(ref));

        return (
            <Grid ref={ref} item sx={{
                opacity, mr: '5px', mb: '8px', width: 'auto', display: 'flex', alignItems: 'center', p: '3px', border: '1px solid #ff7200', whiteSpace: "nowrap",
                overflow: "hidden", textOverflow: "ellipsis", backgroundColor: "#FFE3CC", color: "#ff7200", cursor: "move", borderRadius: '8px', fontSize: '13px'
            }}>
                {name}
            </Grid>
        );
    };

    const DropTargets = () => {
        const [, drop] = useDrop({
            accept: ItemTypes.ITEM,
            drop: (item) => moveItem(item.outerIndex, item.index),
        });

        return (
            <Grid item ref={drop} sx={{
                width: 'auto',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px dashed #E0E0E0',
                color: '#E0E0E0',
                marginBottom: '10px',
                borderRadius: '6px',
                p: '5px'
            }} style={{ cursor: 'no-drop' }}>
                <span>Drop Here</span>
            </Grid>
        );
    };

    const moveItem = (outerIndex, innerIndex) => {

    
        if (outerIndex !== undefined && innerIndex !== undefined) {
            const draggedItem = items[outerIndex]?.jobTypes[innerIndex];
    
            if (!draggedItem) {
                console.error("Dragged item is not found.");
                return;
            }
    
            // Get the categoryKey of the dragged item
            const draggedCategoryKey = draggedItem?.actionTypes[0]?.categoryKey;
    
            // Define the allowed sequence of categories
            const categorySequence = ["Inward Operations", "In-house Operations", "Outward Operations"];
            const draggedCategoryIndex = categorySequence.indexOf(draggedCategoryKey);
            const activeCategoryIndex = activeCategoryKey
                ? categorySequence.indexOf(activeCategoryKey)
                : -1;
    
            // Check if the draggedCategoryKey is in the blocked list
            if (blockedCategoryKeys.includes(draggedCategoryKey)) {
                showToast(
                    `Can not drag items from "${draggedCategoryKey}". It is now blocked after switching to "${activeCategoryKey}".`,
                    true
                );
                return;
            }
    
            // Check sequence rules
            if (activeCategoryIndex !== -1 && draggedCategoryIndex < activeCategoryIndex) {
                showToast(
                    `Can not move items from "${draggedCategoryKey}" as it is in a previous category key.`,
                    true
                );
                return;
            }
    
            // Update activeCategoryKey and block previous categories
            if (activeCategoryKey === null) {
                // No active category, set it to the dragged item's categoryKey
                setActiveCategoryKey(draggedCategoryKey);
            } else if (draggedCategoryIndex > activeCategoryIndex) {
                // Switch to a new category key and block previous categories
                const newBlockedKeys = categorySequence.slice(0, draggedCategoryIndex);
                setBlockedCategoryKeys(newBlockedKeys);
                setActiveCategoryKey(draggedCategoryKey);
            }
    
            // Update workflow state
            let updatedWorkflowState = [...workflowState];
    
            if (updatedWorkflowState[0].operations.length === 1) {
                updatedWorkflowState[0].operations[0].canBeDeleted = false;
                updatedWorkflowState[0].operations.push({
                    taskName: draggedItem?.name,
                    taskId: draggedItem?._id,
                    taskActionTypes: draggedItem?.actionTypes,
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />,
                });
            } else {
                updatedWorkflowState[0].operations[
                    updatedWorkflowState[0].operations.length - 1
                ].canBeDeleted = false;
                updatedWorkflowState[0].operations.push({
                    taskName: draggedItem?.name,
                    taskId: draggedItem?._id,
                    taskActionTypes: draggedItem?.actionTypes,
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />,
                });
            }
    
            setworkflowState(updatedWorkflowState);
        } else {
            console.error("Invalid outerIndex or innerIndex provided.");
        }
    };
    
    const DragDropReorder = ({ items, index, ind }) => {
        const ref = React.useRef(null);
        const [, drop] = useDrop({
            accept: ReOrderTypes.reItem,
                drop: (item) => {
                    moveAndReorder(item.ind,ind,index)} 
                });

        const [{ isDragging }, drag] = useDrag({
            item: { index, ind },
            type: ReOrderTypes.reItem,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
        });

        const opacity = isDragging ? 0.4 : 1;
        drag(drop(ref));
        return (
            <Grid sx={{ width: 'auto', display: 'flex', height: 'auto', m:1 }} >
                {items.taskName !== '' ?
                    <Card
                        sx={{
                            border: `1px solid ${theme.themeOrange}`,
                            borderRadius: "8px",
                            boxShadow: "none",
                            width: "fit-content", // Allows card width to adjust based on content
                        }}
                        ref={ref}
                    >
                        {/* Header Section */}
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                backgroundColor: theme.themeOrange,
                                color: "#fff",
                                padding: "3px", // Minimal padding
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                            }}
                        >
                            <Typography
                                sx={{
                                    ...textClasses.t12n,
                                    fontWeight: "bold",
                                    flexGrow: 1, // Allows dynamic width growth for taskName
                                }}
                            >
                                {items.taskName}
                            </Typography>
                            {ind !== 0 && (
                                <IconButton
                                    size="small"
                                    sx={{ color: "#fff", padding: "3px" }} // Minimal padding
                                    onClick={() => removeTask(index, ind)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Grid>

                        {/* Body Section */}
                        <CardContent sx={{ padding: "8px 8px" }}> {/* Adjusted padding */}
                            <ul
                                style={{
                                    margin: 0,
                                    padding: 0,
                                    listStyleType: "none", // Remove default list styling
                                    color: theme.themeOrange,
                                }}
                            >
                                {items?.taskActionTypes?.map((dl, i) => (
                                    <li key={i} style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
                                        {/* Custom Dot */}
                                        <span
                                            style={{
                                                display: "inline-block",
                                                width: "6px",
                                                height: "6px",
                                                backgroundColor: theme.themeOrange,
                                                borderRadius: "50%",
                                                marginRight: "8px", // Space between dot and label
                                            }}
                                        ></span>
                                        <Typography sx={{ ...textClasses.t12n, color: theme.themeOrange }}>
                                            {dl?.actionName}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </CardContent>

                    </Card>
                    //  <>
                    //     <div className="customOrangeBtn" ref={ref}  style={{ opacity ,marginBottom: '12px', position: 'relative', zIndex: '10' }}>
                    //     {items.taskName}
                    //         {ind !== 0 ?
                    //             <span><CloseIcon className="closeIcon" onClick={() => removeTask(index, ind)} /></span>
                    //             : ''}
                    //     </div>
                    //     <div className="customOrangeBtn" ref={ref}>
                    //     {items?.taskActionTypes?.map(dl=>dl?.actionName)}
                    //         </div>
                    //         </>
                    : ''}
                {items.taskName !== '' ?
                    <Grid item sx={{display:'flex',height:'auto',justifyContent:'center',alignItems:'center',color:theme.themeOrange,m:1}}>
                        {items.icon}
                    </Grid>
                    : ''}
                {items.canBeDeleted === true ?
                    <DropTargets
                        items={items}
                        index={index}
                        ind={ind}
                    />
                    : ''}
            </Grid>
        );
    };

    const moveAndReorder = (dragIndex, hoverIndex, index) => {
        let temp = [...workflowState];
        
        const draggedItem = temp[index].operations[dragIndex];
        const targetItem = temp[index].operations[hoverIndex];
        
        // Check if the categoryKeys match
        const draggedCategoryKey = draggedItem?.taskActionTypes[0]?.categoryKey;
        const targetCategoryKey = targetItem?.taskActionTypes[0]?.categoryKey;
        
        if (draggedCategoryKey !== targetCategoryKey) {
            // Show a message or just return if the keys don't match
            showToast(
                `Can not switch items. "${draggedItem.taskName}" and "${targetItem.taskName}" belong to different categories.`,
                true
            );
            return;
        }
    
        // Swap the items
        [temp[index].operations[dragIndex], temp[index].operations[hoverIndex]] = 
            [temp[index].operations[hoverIndex], temp[index].operations[dragIndex]];
    
        let len = temp[index].operations.length - 1;
        if (hoverIndex === len) {
            temp[index].operations[dragIndex].canBeDeleted = false;
            temp[index].operations[temp[index].operations.length - 1].canBeDeleted = true;
        } else {
            temp[index].operations[temp[index].operations.length - 1].canBeDeleted = true;
            temp[index].operations[hoverIndex].canBeDeleted = false;
        }
    
        setworkflowState(temp);
    };
    
    const clearAddedWorkflow = (index) =>{
        const tempWfState = deepCopy_Object(workflowState)
        tempWfState[index] = deepCopy_Object(initialWorkflowState)?.at(index)
        setworkflowState(tempWfState)
        setBlockedCategoryKeys([])
        setActiveCategoryKey(null)
    }


    useEffect(() => {
        setLoader(true)
        const bodyData = {}
        dispatch(getAllJobTypes({fetch_for:"workflowCreation"},({data=[]})=>{
            setLoader(false)
            setItems(data)
          }));

        if(isEditable){
            dispatch(getWorkflowDetails({objectId:workflowId,fetch_for:"updateWorkflow"}, (data) => {
                setLoader(false)
                setWorkflowDetailData(data?.data)
            }))
        }
    }, [])

    useEffect(() => {
        if (isEditable && workflowDetailData?.jobTypesId?.length > 0) {
            const initialState = workflowDetailData?.jobTypesId?.map((item, index) => ({
                taskName: item?.name,  // now actionName will become name
                taskId: item?.jobTypeId,  // actionId will become _id
                taskActionTypes: item?.actionTypes,
                canBeDeleted: index === workflowDetailData?.length - 1, // Only the last item can be deleted
                icon: <DoubleArrowIcon />,
            }));
            initialState.push({
                taskName: '',
                taskId: '',
                canBeDeleted: true,
                icon: <DoubleArrowIcon />,
                // type: 'Inward Operations',
            })
            setworkflowState([{ operations: initialState }]); // Assuming a single group of operations
        }
        // else {
        //     setworkflowState([{ operations: [] }]);
        // }
    }, [isEditable, workflowDetailData]);


    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Grid container sx={{ flexDirection: "column" }}>
                    <Grid item >
                        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                            Manage Workflow(s)
                        </Typography>
                        <Breadcrumbs sx={{ mb: 1 }}>
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    fontSize: "12px",
                                    color: theme.themeOrange,
                                }}
                            >
                                <Link
                                    style={{ color: theme.themeOrange }}
                                    to="/dashboard/setting/manage-workflows"
                                >
                                    Manage Workflow
                                </Link>
                            </Typography>

                            <Typography 
                            data-testid='create'
                                sx={{ ...textClasses.normalText, fontSize: "12px" }}
                            >
                                {isEditable ? 'edit workflow' : 'create workflow'}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <div className="customCard">
                        <div className="customCardLeft" >
                            <div  className='clHeader' >
                            {isEditable ? "Edit Workflow" : "Create Workflow"}
                            </div>
                               
                            <div style={{ borderBottom: '1px solid #D9D9D9', width: '97%', height: 'auto', margin: 'auto', position: 'relative', top: '13px' }}>
                                <span style={{ fontSize: '12px' }}>
                                    * You can’t remove the default process steps. You can only change the order of any step any time
                                </span>
                            </div>
                            {workflowState.map((item, Parentindex) => {
                                return (
                                    <div className="pcardContainer">
                                        <div className="pcardCTop">
                                            <span className="workflowName">{item.type}</span>
                                            <span className="operationText">{item.subText}</span>
                                        </div>
                                        <div className="pcardCBody">
                                            {item.operations.map((data, ind) => {
                                                return (
                                                    <DragDropReorder
                                                        items={data}
                                                        index={Parentindex}
                                                        ind={ind}
                                                        isLastZone={item.operations[ind] }
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div className="pcardCHeader">
                                            <Button
                                                variant="contained"
                                                className='UM_delete-btn'
                                                disabled={item?.operations?.length==1}
                                                onClick={() => {
                                                    clearAddedWorkflow(Parentindex)
                                                }}
                                                sx={{ fontSize: "12px", borderRadius: "8px", marginRight: "11px", height: '33px', color: "black", border: "1px solid black" }}
                                            > Clear
                                            </Button>
                                            {!isEditable && <Button
                                                variant="contained"
                                                size="small"
                                                sx={buttonClasses.lynkitOrangeEmpty}
                                                disabled={item?.operations?.length==1}
                                                onClick={() => {
                                                    setViewModal({ ...viewModal, type: "submitAndAddWf", action: item })
                                                }}
                                            >
                                                Submit & Add New
                                            </Button>
                            }
                                            <Button
                                                variant="contained"
                                                className='UM_uploadfile-btn'
                                                onClick={() => {
                                                    // setActionStatus("Activate/Block")
                                                    // setEditBlock(true)
                                                    if(isEditable){
                                                        setViewModal({ ...viewModal, type: "updateWf", action: item })

                                                    }
                                                    else{
                                                        setViewModal({ ...viewModal, type: "submitNewWf", action: item })
                                                    }
                                                }}
                                                disabled={item?.operations?.length==1}
                                                sx={{ fontSize: "12px", borderRadius: "8px", margin: "10px", height: '33px', color: "black", border: "1px solid black" }}
                                            >{isEditable ? "Update" : "Submit"}                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {
                           ["submitAndAddWf","submitNewWf","updateWf"].includes(viewModal?.type) &&
                            <CreateUpdateWorkflow 
                            viewModal={viewModal}
                            setViewModal={setViewModal}
                            refreshTableData={(idx)=>{clearAddedWorkflow(idx)}}
                            isEditable={isEditable}
                            {...(isEditable && { detailData: workflowDetailData })} // Conditionally pass detailData
                            />
                        }

                        <div className="customCardRight">
                            <CardHeader
                                title={
                                    <Typography sx={{ fontSize: "18px", color: '#ff7200' }}>
                                        Standard Operations
                                    </Typography>
                                }
                            />
                            <div style={{ width: '92%', height: 'auto', margin: 'auto', position: 'relative', top: '-13px' }}>
                                <span style={{ fontSize: '12px' }}>
                                    Use standard operations to define how work progresses with in warehouse.
                                </span>
                            </div>
                            {loader
                                ? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                    <Grid item><GenericLoader /></Grid>
                                </Grid>
                                :
                            items && items.map((item, index) => {
                                return (
                                    <div className="pcardContainer" style={{ marginTop: '-7px',marginBottom:'10px', boxShadow: 'none' }}>
                                        <div className="pcardCTop">
                                            <span className="workflowName">{index + 1}. {item.categoryKey}</span>
                                            <span className="operationText">
                                                {item.categoryKey === "Inward Operations" ?
                                                    '( Gate In )'
                                                    : ''
                                                }
                                                {item.categoryKey === "In-house Operations" ?
                                                    ''
                                                    : ''
                                                }
                                                {item.categoryKey === "Outward Operations" ?
                                                    '( Gate Out )'
                                                    : ''
                                                }
                                            </span>
                                        </div>
                                        <div className="pcardO" id={`table-${index}`} >
                                            <div className="pcardBoyChange pcardO">
                                                {item?.jobTypes?.map((data, ind) => {
                                                    return (
                                                        <DragDropItem
                                                            key={data.ind}
                                                            outerIndex={index}
                                                            index={ind}
                                                            name={data?.name}
                                                            actionTypes={data?.actionTypes}
                                                            // approved={data.approved}
                                                            // actionID={data.actionId}
                                                            // categoryKey={data.categoryKey}
                                                            // order={data.order}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="pcardScroll"  id={`collapse-${index}`} onClick={() => Expand(index)}>
                                        {index === 0 ?
                                        collapse.collapse1 === false ?
                                        <>
                                            <span>View All</span>
                                             <span><ExpandMoreIcon/></span>
                                        </>
                                            :
                                            <>
                                            <span>Hide All</span>
                                            <span><ExpandLessIcon/></span>

                                            </>
                                        :''}
                                        {index === 1 ?
                                        collapse.collapse2 === false ?
                                        <>
                                            <span>View All</span>
                                             <span><ExpandMoreIcon/></span>
                                        </>
                                            :
                                            <>
                                            <span>Hide All</span>
                                            <span><ExpandLessIcon/></span>

                                            </>
                                        :''
                                        }
                                        {index === 2 ?
                                        collapse.collapse3 === false ?
                                        <>
                                            <span>View All</span>
                                             <span><ExpandMoreIcon/></span>
                                        </>
                                            :
                                            <>
                                            <span>Hide All</span>
                                            <span><ExpandLessIcon/></span>

                                            </>

                                           :'' 
                                        }
                                        </div>
                                    </div>

                                )
                            })}
                            

                        </div>

                    </div>

                </Grid>
            </DndProvider>
        </>
    );
}

// import React , {useState} from 'react';
// import { useDrag, useDrop, DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// const AddWorkflow = () => {
//     const [items, setItems] = useState([
//         { id: 'item1', text: 'Item 1' },
//         { id: 'item2', text: 'Item 2' },
//         { id: 'item3', text: 'Item 3' },
//         { id: 'item4', text: 'Item 4' },
//     ]);

//     const moveItem = (dragIndex, hoverIndex) => {
//         const draggedItem = items[dragIndex];
//         const updatedItems = [...items];
//         updatedItems.splice(dragIndex, 1);
//         updatedItems.splice(hoverIndex, 0, draggedItem);
//         ////('draggeeeeeeeed', dragIndex, hoverIndex);
//         setItems(updatedItems);
//     };

//     const ItemTypes = {
//         ITEM: 'item',
//     };

//     const DragDropItem = ({ id, index, text }) => {
//         const ref = React.useRef(null);
//         const [, drop] = useDrop({
//             accept: ItemTypes.ITEM,
//             drop: (item) =>////('i',item,index)
//         });
//         const [{ isDragging }, drag] = useDrag({
//             item: { id, index },
//             type: ItemTypes.ITEM,
//             collect: (monitor) => ({
//                 isDragging: monitor.isDragging(),
//             }),
//         });

//         const opacity = isDragging ? 0.4 : 1;

//         drag(drop(ref));

//         return (
//             <div ref={ref} className="draggable-item" style={{ opacity }}>
//                 {text}
//             </div>
//         );
//     };

    // const DropTarget = () => {
    //     const [, drop] = useDrop({
    //         accept: ItemTypes.ITEM,
    //         // drop: (item) => moveItem(item.index, items.length),
    //     });

    //     return (
    //         <div ref={drop} className="drop-target" style={{ width: '100%', height: '100px', border: '1px dashed silver' }}>
    //             Drop here
    //         </div>
    //     );
    // };
 
//     return (
//         <DndProvider backend={HTML5Backend}>
//             <div>
//                 <div>
//                     {items.map((item, index) => (
//                         <DragDropItem
//                             key={item.id}
//                             id={item.id}
//                             index={index}
//                             text={item.text}
//                         />
//                     ))}
//                 </div>
//                 {/* <DropTarget /> */}
//             </div>
//         </DndProvider>
//     );
// };

// export default AddWorkflow;