import React from "react";
import PropTypes from "prop-types";
import {Box,Paper,Grid,Typography,PaginationItem,Button} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import TableFooter from "@mui/material/TableFooter";
import theme, {buttonClasses, textClasses } from '../../../static/styles/theme';
import TablePagination from "@mui/material/TablePagination";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { DataNotFound } from "../../Components/TemplateInputConfiguration";
import {getShortText } from "../../../services/functions";

export const renderData = (column_data, sortLength=27) => {
	if (column_data && ["number", "string"].includes(typeof column_data)) {
		if ((typeof column_data=="number" && (column_data+"".length<sortLength)) || (column_data.length <sortLength)) {
			return column_data
		}
		else {
			
			if(typeof column_data=="object" && column_data.props && typeof column_data.props=="object"){
				column_data={
					...column_data,
					props:{
						...column_data.props,
						sx:column_data?.props?.sx || {...textClasses.t13n}
					}
				}
			}
			else if(typeof column_data=="object"){
				column_data={
					...column_data,
					sx:column_data?.sx || {...textClasses.t13n}
				}
			}
			return <Tooltip title={column_data} placement="bottom">
				<Typography {...column_data.props} sx={{...textClasses.t13n}}>
					{getShortText(column_data, sortLength)}
				</Typography>
			</Tooltip>
		}
	}
	else if (column_data?.props?.children && ["number", "string"].includes(typeof column_data.props.children)) {
		
		if ((typeof column_data.props.children=="number" && (column_data.props.children+"".length<sortLength)) ||
		 (column_data.props.children.length < sortLength)) {
			return column_data
		}
		else {
			if(typeof column_data=="object" && column_data.props && typeof column_data.props=="object"){
				column_data={
					...column_data,
					props:{
						...column_data.props,
						sx:column_data?.props?.sx || {...textClasses.t13n}
					}
				}
			}
			else if(typeof column_data=="object"){
				column_data={
					...column_data,
					sx:column_data?.sx || {...textClasses.t13n}
				}
			}

			return <Tooltip title={column_data} placement="bottom" componentsProps={{ tooltip: { sx:{
				maxWidth: "40vw"
			}}}}>
				<Typography  {...column_data.props}>
					{getShortText(column_data.props.children, sortLength)}
				</Typography>
			</Tooltip>
		}
	}
	else {
		
		return column_data
	}
}

export default function GenericTable({
	header,
	rows,
	pageCount,
	pageNumber,
	handleChangePage,
	handleChangeRowsPerPage,
	rowsPerPage,
	width="250px",
	height="250px",
	isHideRowPerPage=false,
	viewPagination=true,
	isHeaderTheme=true,
	isShowNotDataFound=true
}) {
	
	return (
		<>
			<Paper className="muitable" sx={{
				maxWidth: "100vw",
				// maxWidth: "1260px",
				overflowX: "auto",
			}}>

				<table style={{ width: "100%" }}>
					<thead style={isHeaderTheme?{ backgroundColor: theme.themeGray }:{}}>
						<tr className='roundedRow'>
							{
								header.map((head, i) => {
									return (
										//padding: "13px 10px 13px 10px", 
										<td key={i} style={{ ...textClasses.boldText, textAlign: "center" }}>
											{head}
										</td>
									)
								})
							}
						</tr>
					</thead>
					<tbody>
						{
							rows?.length > 0
								?
								<>
									{
										rows.map((row, i) => (
											<tr
												className="table-row"
												key={i}
												style={{ borderBottom: "1px solid #0000002B" }}
											>
												{
													row.map((col, j) => {
														return (
															//padding: "10px 5px 10px 5px", 
															<td key={i + "/" + j} style={{ ...textClasses.t13n, textAlign: "center" }}>
																{/* {col} */}
																{renderData(col)}
															</td>
														)
													})
												}

											</tr>
										))
									}

								</>
								:
								isShowNotDataFound
									? <tr
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<td component="th" scope="row" colSpan={11} style={{ ...textClasses.normalText, textAlign: "center", overflowWrap: "break-word" }}>
											<Grid container justifyContent={"center"} alignItems="center" sx={{ mb: 2 }}>
												<DataNotFound width={width} height={height} />
											</Grid>
										</td>

									</tr>
									: ""
						}
					</tbody>
				</table>
				{viewPagination && pageCount > 0 && rows?.length > 0 &&
					<PaginationComp
						pageCount={pageCount} pageNumber={pageNumber} rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
						isHideRowPerPage={isHideRowPerPage}
					/>
				}
			</Paper>
		</>
	);
}

function PaginationComp({
	pageCount,pageNumber,rowsPerPage,handleChangePage,handleChangeRowsPerPage,isMultiMove=true,isCustumButton=false,isHideRowPerPage=false
}){


	return(
		<>
		{
			pageCount>0?
			<TablePagination
			component="div"
			count={pageCount}
			page={pageNumber}
			rowsPerPage={rowsPerPage}
			rowsPerPageOptions={isHideRowPerPage ? []: pageCount > 10 ? [10, 25, 50,100] : [10]}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			ActionsComponent={(item)=>(<TablePaginationActions {...item} isCustumButton={isCustumButton} isMultiMove={isMultiMove}/>)}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				mt: "0.5em",
				"& .MuiTablePagination-selectLabel ": {
					margin: '0'
				},
				"& .MuiTablePagination-displayedRows":{
					margin: '0'
				}
			}}
			/>
			:""

		}
		</>
	)
}
export {PaginationComp}

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange,isMultiMove=true,isCustumButton=false } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {isMultiMove ? (
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      ) : (
        ""
      )}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          isCustumButton ? (
            <Button
			disabled={page === 0}
			 sx={{
				// color: "white",
                    textTransform: "none",
                    padding: "10px 30px",
					fontSize:'14px',
                    ...buttonClasses.lynkitOrangeFill,
			}}
			>Next</Button>
          ) : (
            <KeyboardArrowRight />
          )
        ) : isCustumButton ? (
          <Button 
		  disabled={page === 0}
		  sx={{
			  // color: "#FF7200",
			  textTransform: "none",
			  padding: "10px 30px",
			  fontSize:'14px',
			  marginRight: "15px",
			  ...buttonClasses.lynkitOrangeEmpty,
		  }}
		  >Prev</Button>
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          isCustumButton ? (
            <Button
			disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			 sx={{
				 // color: "#FF7200",
				 textTransform: "none",
				 padding: "10px 30px",
				 fontSize:'14px',
				 marginRight: "15px",
				 ...buttonClasses.lynkitOrangeEmpty,
			  }}
			  >Prev</Button>
          ) : (
            <KeyboardArrowLeft />
          )
        ) : isCustumButton ? (
          <Button 
		  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
		  sx={{
			color: "white",
                    textTransform: "none",
                    padding: "10px 30px",
					fontSize:'14px',
                    ...buttonClasses.lynkitOrangeFill,
		  }}
		  >Next</Button>
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      {isMultiMove ? (
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      ) : (
        ""
      )}
    </Box>
  );
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};