import React, { useEffect, useState,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid, Box, InputLabel, Select, MenuItem, FormControl, Button, Typography, Autocomplete, TextField, OutlinedInput,
    InputAdornment, Menu, Tabs, Tooltip, Tab, Switch, FormControlLabel,
} from "@mui/material";
import "../.././Style/GridView.css";
import {
    getCellStorageCapacity,
    getCellCapacityDetail,
    updateGridAccessbilityPreferences,
    getGridAccessbilityDetail,
    getGridAccessbilityList,
    getGridBehaviourDetail,
    getGridBehaviourList,
} from "../../../../../redux/actions";
import { showToast, textCapitalize, renderOptionHandler, deepCopy_Object } from "../../../../../services/functions";
import {
    Search,
} from "@mui/icons-material";
import theme from "../../../../../static/styles/theme";
import { selectStylesOverride } from "../../../../../utils/util";
import AddIcon from '@mui/icons-material/Add';
import GridBoxIcon from "../../../../../static/images/Icon/GridDesign-pana.png";
import {
    buttonClasses,
    inputClasses,
    textClasses,
} from "../../../../../static/styles/theme";
import GenericAutoSizerComponent from "../../../Generic/GenericAutoSizerComponent";
import AreaUtilizationStep1 from "./AreaUtilizationStep1";
import AreaAccessbility from "./AreaAccessbility";
import GenericTable from "../../../CustomTable/GenericTable";
import { useDebounce } from "../../../../../utils/hooks";
import GenericLoader from "../../../Generic/GenericLoader";
import AlertDialog from "../../../DialogBox/dialogbox";
import GenericToggle from "../../../GenericToggle/index";


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

let initail_filter = {
    page: 1,
    limit: 10,
    areaName: "",
    areaType: "",
    fetch: false,
};
const dataRead = {
    "heatMap": [
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "",
                    "default_value": "0",
                    "postfix": " New Location(s) Identified as Slow Moving in Last ",
                    "key": "slowMovingCount",

                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "",
                    "postfix": " Days",
                    "default_value": "7",
                    "key": "unit"
                },
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "",
                    "postfix": " New Location(s) Identified as Fast Moving in Last ",
                    "default_value": "0",
                    "key": "fastMovingCount",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "",
                    "postfix": " Days",
                    "default_value": "7",
                    "key": "unit"
                },
            ]
        },
    ],
    "utilization": [
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total area is ",
                    "postfix": " sq.",
                    "key": "totalArea",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " ",
                    "postfix": "",
                    "key": "unit"
                }
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total usable area is ",
                    "postfix": " sq.",
                    "key": "usableArea",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " ",
                    "postfix": "",
                    "key": "unit"
                }
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Warehouse Capacity Is ",
                    "postfix": " cubic",
                    "key": "totalwarehouseCapacity",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " ",
                    "postfix": "",
                    "key": "unit"
                }
            ]
        },
    ],
    "accessbility": [
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total Pick Not Allowed Location(s) ",
                    "postfix": "",
                    "key": "pickUpNotAllowed",
                }
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total Drop Not Allowed Location(s) ",
                    "postfix": "",
                    "key": "dropNotAllowed",
                },
            ]
        },
    ],
}

let tabList = [
    {
        "tabHeading": "Location(s) Behaviour",
        "cardHeading": "Location(s) Behaviour",
        "cardSubHeading": "Location(s) Behaviour",
        "carddescription": "Location(s) Behaviour.",
        "icon": "GridBoxIcon",
        "permission": [ "viewHeatMap"],
        "buttonText": "Select Location",
        "buttonPermission": "defineHeatMapThresold",
        "tabKey": "heatMap"
    },
    {
        "tabHeading": "Utilization matrix",
        "cardHeading": "Cell Dimensions & Area Analytics",
        "cardSubHeading": "Define area utilization matrix",
        "carddescription": "If your warehouse is presently operating at its maximum storage capacity, you're probably exploring methods to expandits storage capabilities.",
        "icon": "GridBoxIcon",
        "permission": ["addAreaUtilization", "viewAreaUtilization", "editAreaUtilization"],
        "buttonText": "Add Dimension",
        "buttonPermission": "addAreaUtilization",
        "tabKey": "utilization"
    },
    {
        "tabHeading": "Grid Accessibility",
        "cardHeading": "Grid Accessibility && Preferences",
        "cardSubHeading": "Identify and Prepare the Location",
        "carddescription": "Choose the rack, shelf, or bin that will be used for pick and drop operations. Ensure it is strategically placed for efficient access.",
        "icon": "GridBoxIcon",
        "permission": ["updateGridAccessbilityPreferences", "defineGridAccessibility", "getGridAccessbilityList",],
        "buttonText": "Select Location",
        "buttonPermission": "defineGridAccessibility",
        "tabKey": "accessbility"
    },
]

function AccessbilityAndUtilization(props) {

    const { warehouseInfo = {}, floorInfo = {} } = props
    const dispatch = useDispatch();
    const { cellCapacityDetail } = useSelector((state) => state.warehouseData);

    const { userPermissions = {} } = useSelector((state) => state.userPermission);

    const [tableData, setTableData] = useState({});
    const [filter, setFilter] = useState({
        ...initail_filter,
    });
    const [loader, setLoader] = useState("");
    const [showModel, setShowModel] = useState("");
    const [utiliZationID, setUtilizationID] = useState("");
    const [tabIndex, setTabIndex] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const debouncedSearchedAreaName = useDebounce(filter.areaName, 500);
    const areaTypeOption = [
        { label: "Block", value: "block" },
        // { label: "rack" },
        { label: "Shelves", value: "shelves" },
    ];

    let validTabList = useMemo(() => {
        return tabList?.filter(info => {
            return userPermissions?.permissions?.some((dl) => info?.permission?.includes(dl?.permissionId))
        })
    },[userPermissions])

    let tabKey = validTabList?.at(tabIndex)?.tabKey || ""

    const handleToggle = (event, cellDetail = {}, rowIndex = 0) => {
        let name = event.target.name
        let checked = event.target.checked
        let payload = {
            "cellId": cellDetail?.cellId,
            "floorId": cellDetail?.floorId,
            "markAllDamaged": cellDetail?.isLocationDamaged == true,
            "updatePreferences": { ...cellDetail?.accessbilityPreferences || {} }
        }

        let tempData = deepCopy_Object(tableData || {})
        if (name == "isLocationDamaged") {
            payload["markAllDamaged"] = checked
            tempData["data"][rowIndex][name] = checked
        }
        else {
            payload["updatePreferences"][name] = {
                isDisabled: !checked
            }
            tempData["data"][rowIndex]["accessbilityPreferences"][name] = {
                isDisabled: !checked
            }
        }


        // console.log(payload,"payload")
        setLoader("row")
        dispatch(updateGridAccessbilityPreferences(payload, ({ success, message }) => {
            setLoader("")
            showToast(message, !success)
            if (success) {
                if (name == "pickUp") {
                    tempData.floorAccessbility.pickUpNotAllowed = Number(tempData?.floorAccessbility?.pickUpNotAllowed || 0) + (checked ? -1 : 1)
                }
                else if (name == "drop") {
                    tempData.floorAccessbility.dropNotAllowed = Number(tempData?.floorAccessbility?.dropNotAllowed || 0) + (checked ? -1 : 1)
                }

                // console.log(tempData)
                setTableData(tempData)
                // setFilter({ ...filter, fetch: false,})
                // fetchAreautilization(floorInfo,filter, tabIndex)
            }
        }))
    }


    const gridTableHeader = useMemo(() => {
        if (tabKey == "heatMap") {
            return [
                "Behaviour Pattern",
                "Unit Location Counts",
                "Location Name",
                "Action",
            ]
        }
        else if (tabKey == "accessbility") {
            return [
                "Area Type",
                "Area Name",
                "Pick Up",
                "Drop",
                "Tempareture Sensors",
                "Damaged",
            ]
        }
        else {
            return [
                "Utilization ID",
                "Area Type",
                "Area Name",
                "Dimensions ",
                "Accepted Inventory Type",
                "Primary Capacity Matrix",
                "Entity Type & Max. Capacity ",
                ...((userPermissions?.permissions || []).find((dl) => dl.permissionId == "editAreaUtilization") ? ["Action"] : [])
            ]
        }
    }, [tabKey, userPermissions])


    const gridTableRowData = useMemo(() => {
        return tableData?.data?.map((cellData, index) => {
            const item = [];
            if(tabKey == "utilization" || tabKey == "accessbility"){
                if (tabKey == "utilization") {
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                            }}
                        >
                            {cellData?.utiliZationID}
                        </Typography>
                    );
                }
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                            textTransform: "capitalize"
                        }}
                    >
                        {cellData?.areaType}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {cellData?.cellName || (cellData?.cellDetail?.map((dl) => textCapitalize(dl.name))?.join(", ")) || "-"}
                    </Typography>
                );
                if (tabKey == "accessbility") {
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textAlign: "center"
                            }}
                        >
                            <GenericToggle
                                disbledName="'Not Allowed'"
                                enabledName="'Allowed'"
                                isEnable={!(cellData?.accessbilityPreferences?.pickUp?.isDisabled == true)}
                                handleToggle={(event) => handleToggle(event, cellData, index)}
                                name="pickUp"
                                width={100}
                                isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}
    
                            />
                        </Typography>
    
                    );
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textAlign: "center"
                            }}
                        >
                            <GenericToggle
                                disbledName="'Not Allowed'"
                                enabledName="'Allowed'"
                                isEnable={!(cellData?.accessbilityPreferences?.drop?.isDisabled == true)}
                                handleToggle={(event) => handleToggle(event, cellData, index)}
                                name="drop"
                                width={100}
                                isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}
    
                            />
                        </Typography>
    
                    );
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textAlign: "center"
                            }}
                        >
                            <GenericToggle
                                disbledName="'No'"
                                enabledName="'Yes'"
                                isEnable={!(cellData?.accessbilityPreferences?.temperatureSensors?.isDisabled == true)}
                                handleToggle={(event) => handleToggle(event, cellData, index)}
                                name="temperatureSensors"
                                width={60}
                                isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}
    
                            />
                        </Typography>
    
                    );
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textAlign: "center"
                            }}
                        >
                            <GenericToggle
                                disbledName="'No'"
                                enabledName="'Yes'"
                                isEnable={cellData?.isLocationDamaged == true}
                                handleToggle={(event) => handleToggle(event, cellData, index)}
                                name="isLocationDamaged"
                                width={60}
                                isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}
    
                            />
                        </Typography>
    
                    );
    
                }
                else {
    
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                            }}
                        >
                            {`${cellData?.dimension?.height} ${cellData?.dimension?.unit}  * ${cellData?.dimension?.length} ${cellData?.dimension?.unit} * ${cellData?.dimension?.width} ${cellData?.dimension?.unit}`}
                        </Typography>
                    );
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
    
                            }}
                        >
                            {cellData?.acceptedInventoryType
                                ?.map((dl) => textCapitalize(dl))
                                ?.join(", ") || "-"}
                        </Typography>
                    );
                    item.push(
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textTransform: "capitalize"
                            }}
                        >
                            {cellData?.primaryCapacityMatrix || "-"}
                        </Typography>
                    );
                    item.push(
                        <Typography
                            data-testid="dropdown"
                            sx={{
                                ...textClasses.t13n,
                                color: theme.themeOrange,
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={(e) => handleViewData(e, index, cellData?.utiliZationID)}
                        >
                            View
                        </Typography>
                    );
                    if (cellData?.quantityCapacity?.quantity && cellData?.quantityCapacity?.unit) {
                        item.push(
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                }}
                            >{`${cellData?.quantityCapacity?.quantity} ${cellData?.quantityCapacity?.unit}`}</Typography>
                        );
                    }
                    if ((userPermissions?.permissions || []).some((dl) => dl.permissionId == "editAreaUtilization")) {
                        item.push(
                            <Typography
                                data-testid="dropdown"
                                sx={{
                                    ...textClasses.t13n,
                                    color: theme.themeOrange,
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={(e) => {
                                    setUtilizationID(cellData?.utiliZationID)
                                    handleEditDimensions(cellData?.utiliZationID)
                                }}
                            >
                                Edit
                            </Typography>
                        );
                        // item.push(
                        //     <Typography
                        //         data-testid="dropdown"
                        //         sx={{
                        //             ...textClasses.t13n,
                        //             color: theme.themeOrange,
                        //             cursor: "pointer",
                        //         }}
                        //         onClick={(e) => handleOpenAction(e, index, cellData?.utiliZationID)}
                        //     >
                        //         Action <KeyboardArrowDown />
                        //     </Typography>
                        // );
                    }
                }
            }
        
            else{
                const renderLocationNames = ()=>{
                    const connectedItems = cellData?.locations?.join(", ")
                    const moreItems = cellData?.locationCount > cellData?.locations?.length && <Typography sx={{
                        ...textClasses.t13n,
                        textAlign: "center",
                        color: theme.themeOrange,
                        cursor: "pointer",
                        textDecoration: "underline",
                        alignSelf: "center",
                    }}
                        data-testid="clickableItemMasterCount"
                        onClick={() => {
                         handleViewData("",index, cellData?.movingStatus)
                        }}>+{Number(cellData?.locationCount || 0) - Number(cellData?.locations?.length || 0)}</Typography>;

                    return (cellData?.locationCount > cellData?.locations?.length
                        ? <Typography sx={{ display: "flex",justifyContent:'center' }}>{connectedItems} &nbsp; {moreItems} </Typography>
                        : <Typography >{connectedItems}</Typography>
                    )
                    
                  }
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                            textTransform: "capitalize"
                        }}
                    >
                        {textCapitalize(cellData?.movingStatus)}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                            textTransform: "capitalize"
                        }}
                    >
                        {cellData?.locationCounts?.shelveCount || 0} Shelves, {cellData?.locationCounts?.blockCount || 0} Blocks
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                            textTransform: "capitalize"
                        }}
                    >
                        {renderLocationNames()}
                    </Typography>
                );
                {
                    cellData?.locationCount > 0 &&
                        item.push(
                            <Typography
                                data-testid="dropdown"
                                sx={{
                                    ...textClasses.t13n,
                                    color: theme.themeOrange,
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                }}
                                onClick={(e) => handleViewData(e, index, cellData?.movingStatus)}
                            >
                                View
                            </Typography>
                        );
                }
            }
            return item;
        });
    }, [tableData,tabKey])

    const detail = useMemo(() => {
        let tempDetail = {}
        if (tabKey == "accessbility") {
            tempDetail["dropNotAllowed"] = tableData?.floorAccessbility?.dropNotAllowed || "0";
            tempDetail["pickUpNotAllowed"] = tableData?.floorAccessbility?.pickUpNotAllowed || "0";
        }
        else {
            tempDetail["totalArea"] = tableData?.floorCapacity?.totalDimension?.length * tableData?.floorCapacity?.totalDimension?.width || "0";
            tempDetail["nonUsableArea"] = tableData?.floorCapacity?.nonStorageDimension?.length * tableData?.floorCapacity?.nonStorageDimension?.width || "0";
            tempDetail["usableArea"] = (Number(tempDetail["totalArea"] || 0) - Number(tempDetail["nonUsableArea"] || 0)) || "0";
            tempDetail["unit"] = tableData?.floorCapacity?.totalDimension?.unit || "";
            tempDetail["totalwarehouseCapacity"] = (Number(tempDetail["usableArea"]) * tableData?.floorCapacity?.maxStackHeight?.height) || "0";
            tempDetail["slowMovingCount"] = tableData?.data?.find(dl=>dl?.movingStatus=="slowMovingLocations")?.sevenDayCount
            tempDetail["fastMovingCount"] = tableData?.data?.find(dl=>dl?.movingStatus=="fastMovingLocations")?.sevenDayCount
        }
        return tempDetail
    }, [tableData, tabKey])


    const handlTabChange = (tab_key, tab_Index = 0) => {
        setFilter({ ...initail_filter, fetch: false })
        setTabIndex(tab_Index)
        fetchAreautilization(floorInfo, initail_filter, tab_Index)
    }

    const handleOpenAction = (event, i, utiliZationID = "") => {
        setUtilizationID(utiliZationID);
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };
    const handleViewData = (e, index, utiliZationID = "") => {
        setUtilizationID(utiliZationID);
        if (tabKey == "heatMap") {
            setShowModel("showHeatmapDetail");
        }
        else {
            setShowModel("showUtilizationDetail");
        }
    };

    const handleAddDimensions = (tab_key) => {
        if (tab_key == "accessbility") {
            setShowModel(showModel ? "" : "addAccessbility");
        }
        else {
            setShowModel(showModel ? "" : "submitCapacity");
        }
    };
    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };

    const handleEditDimensions = (ID="") => {
        setActionIndex(null);
        setShowModel(showModel ? "" : "updateCapacity");
        const payload = {
            floorId: floorInfo?.floorId,
            utiliZationID: !!ID? ID : utiliZationID,
            // updateUtilization:true
        };
        dispatch(
            getCellCapacityDetail(payload, (data) => {
            })
        );
    }

    const TabPanel = (panprops) => {
        const { children, value, index, ...other } = panprops;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2,pt:0 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const callBack = (response) => {
        setTableData(response)
        setLoader("");
    }

    const fetchAreautilization = (floor = {}, temp_filter = {}, tab_Index) => {
        setTableData({})
        const payload = {
            floorId: floor?.floorId,
            page: temp_filter.page,
            limit: temp_filter.limit,
            areaName: temp_filter.areaName || "",
            areaType: temp_filter.areaType || "",
        };
        setLoader("table");
        let tabKey = validTabList?.at(tab_Index)?.tabKey || ""
        if (tabKey == "accessbility") {
            dispatch(getGridAccessbilityList(payload, callBack));
        }
        else if (tabKey == "utilization"){
            dispatch(getCellStorageCapacity(payload, callBack));
        }
        else {
            dispatch(getGridBehaviourList({ floorId: floor?.floorId, warehouseId: warehouseInfo?.warehouseId}, callBack));
            // setTableData({ ...tableData, data: heatMapTableData })
            setLoader("");
        }

    };
    useEffect(() => {
        setFilter({ ...initail_filter, fetch: false })
        let tempTab = tabIndex
        if (validTabList?.length - 1 < tabIndex) {
            setTabIndex(0)
            tempTab = 0
        }
        fetchAreautilization(floorInfo, initail_filter, tempTab)
    }, [floorInfo?.floorId])

    useEffect(() => {
        if (filter?.fetch) {
            fetchAreautilization(floorInfo, filter, tabIndex)
        }
    }, [filter?.page, filter?.limit, filter?.areaType, debouncedSearchedAreaName])

    return (
        <Box>
            <Box
                sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}
            >
                <Tabs
                    value={tabIndex}
                    aria-label="disabled tabs example"
                    onChange={(event, tab_Index) => {
                        handlTabChange(event?.target?.name, tab_Index)
                    }}
                    sx={{
                        "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                        "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
                        "ml": 1,
                    }}

                >
                    {
                        validTabList?.map((ele, index) => {
                            return <Tab
                                name={ele?.tabKey}
                                key={index}
                                data-testid={ele.tabHeading}
                                label={ele.tabHeading}
                                // label={`${ele.tabHeading} (${tableData?.count || 0})`}
                                {...a11yProps(index)}
                                sx={{ ...textClasses.normalText, textTransform: "none" }}
                                disabled={!!loader}
                            />

                        })
                    }
                </Tabs>
            </Box>

            {
                validTabList?.map((ele, index) => {
                    return <>
                        <TabPanel
                            value={tabIndex} index={index}
                        >
                            {
                                (ele?.tabKey == "heatMap" ? true : (ele?.tabKey == "utilization" ? floorInfo?.isCapacityDefined : floorInfo?.isAccessbilityDefined))
                                &&
                                <>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            mb: ele?.tabKey == "heatMap" && 2 || 0
                                        }}
                                    >
                                        {
                                            (dataRead[ele?.tabKey] || [])?.map((option, dataIndex) => {
                                                return <Grid key={dataIndex} item sx={{ ml: 1 }}>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.t14n,
                                                            textAlign: "center",
                                                            border: "1px solid #FF7200",
                                                            borderRadius: "8px",
                                                            bgcolor: "#ff720014",
                                                            padding: "10px",
                                                            color: "#FF7200",
                                                            fontWeight: "700",
                                                            // marginTop: "-60px",
                                                            mt: { sm: 1, md: 0 }
                                                        }}
                                                    >
                                                        {renderOptionHandler(detail, option?.renderOption || [])}
                                                    </Typography>
                                                </Grid>
                                            })
                                        }
                                    </Grid>
                                    {
                                        ["utilization", "accessbility"].includes(ele?.tabKey) && <Grid
                                            container
                                            spacing={2}
                                            className="mt-0 mb-2"
                                            direction={"row"}
                                            justifyContent={"space-between"}
                                        >
                                            <Grid
                                                container
                                                item
                                                direction={"row"}
                                                justifyContent={"start"}
                                                xs={9}
                                            >
                                                <Grid item xs={3} sx={{ mr: 2 }}>
                                                    <OutlinedInput
                                                        onChange={(e) =>
                                                            setFilter({
                                                                ...filter,
                                                                areaName: e?.target?.value || "",
                                                                fetch: true,
                                                                page: 1
                                                            })
                                                        }
                                                        size="small"
                                                        sx={{
                                                            ...selectStylesOverride,
                                                            width: "100%",
                                                            fontSize: "12px",
                                                            "& input::placeholder": {
                                                                fontSize: "14px",
                                                            },
                                                        }}
                                                        value={filter.areaName || ""}
                                                        placeholder="Search by area name"
                                                        endAdornment={
                                                            <InputAdornment>
                                                                <Search fontSize="small" />
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={3} sx={{ mr: 2 }}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        size="small"
                                                        onChange={(e, option) =>
                                                            setFilter({
                                                                ...filter,
                                                                page: 1,
                                                                areaType: option?.value || "",
                                                                fetch: true
                                                            })
                                                        }
                                                        value={
                                                            (filter.areaType &&
                                                                areaTypeOption.find((dl) => dl.value == filter.areaType)
                                                                    ?.label) ||
                                                            ""
                                                        }
                                                        options={areaTypeOption}
                                                        sx={{ ...selectStylesOverride, width: "100%" }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="area type" />
                                                        )}
                                                    />
                                                </Grid>
                                                {(filter.areaName || filter.areaType) && (
                                                    <Grid item xs={2}>
                                                        <Button type="button">
                                                            <Typography
                                                                sx={{
                                                                    ...textClasses.boldText,
                                                                    color: theme.themeOrange,
                                                                    textDecoration: "underline",
                                                                    cursor: "pointer",
                                                                    textTransform: "none",
                                                                }}
                                                                onClick={() => {
                                                                    setFilter({ ...initail_filter, fetch: true });
                                                                }}
                                                            >
                                                                Clear Filter(s)
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {(userPermissions?.permissions || []).find((dl) => dl.permissionId == ele?.buttonPermission) &&
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        spacing={1}
                                                        onClick={() => handleAddDimensions(ele?.tabKey)}
                                                        className="RF_sampledownload-btn"
                                                        sx={{
                                                            ...buttonClasses.lynkitBlackFill,
                                                            textTransform: "none",
                                                            textAlign: "start",
                                                            ...textClasses.t12n,
                                                            padding: "10px 10px",
                                                        }}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        {ele?.buttonText}
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </>}

                            {loader == "table" ? (
                                <Grid
                                    data-testid="loader"
                                    container
                                    direction={"column"}
                                    justifyContent={"center"}
                                    alignItems="center"
                                    sx={{ p: 15 }}
                                >
                                    <Grid item>
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                            ) : (
                                (ele?.tabKey == "heatMap" ? true : (ele?.tabKey == "utilization" ? floorInfo?.isCapacityDefined : floorInfo?.isAccessbilityDefined))
                                    ?
                                    <>
                                        <GenericTable
                                            header={gridTableHeader}
                                            rows={gridTableRowData}
                                            pageCount={tableData?.count}
                                            pageNumber={filter.page - 1}
                                            handleChangePage={(event, pageValue) => {
                                                setFilter({ ...filter, page: pageValue + 1, fetch: true });
                                            }}
                                            handleChangeRowsPerPage={(event) => {
                                                setFilter({ ...filter, limit: +event.target.value, fetch: true });
                                            }}
                                            rowsPerPage={filter.limit}
                                        />
                                        {actionIndex != null && (userPermissions?.permissions || []).some((dl) => dl.permissionId == "editAreaUtilization") && (
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={actionIndex != null}
                                                onClose={handleCloseAction}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                                            >
                                                {(userPermissions?.permissions || []).some((dl) => dl.permissionId == "editAreaUtilization") && (
                                                    <MenuItem key={1} onClick={handleEditDimensions}>
                                                        Edit
                                                    </MenuItem>
                                                )}

                                            </Menu>
                                        )}
                                    </>
                                    :
                                    <Grid sx={{ width: "100%" }}
                                    >
                                        <Typography sx={{ ...textClasses.t16n, fontFamily: "Nunito", fontWeight: "700", marginBottom: "10px", }}
                                        >
                                            {ele?.cardHeading}
                                        </Typography>
                                        <Box
                                            sx={{ backgroundColor: "#ff720014", width: "100%", height: "185px", borderRadius: "8px", border: "1px solid #ff7200c2", }}
                                        >
                                            <Grid container spacing={2} className="ms-2 mt-2">
                                                <Grid item xs={2}>
                                                    <img src={GridBoxIcon} />
                                                </Grid>
                                                <Grid item xs={7.5}>
                                                    <Typography
                                                        sx={{ color: "#FF7200", fontSize: "30px", fontWeight: "700", }}
                                                    >
                                                        {ele?.cardSubHeading}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ color: "#FF7200", fontSize: "16px", fontWeight: "600", fontFamily: "Nunito", }}
                                                    >
                                                        {ele?.carddescription}
                                                    </Typography>
                                                </Grid>
                                                {(userPermissions?.permissions || []).some((dl) => dl.permissionId == ele?.buttonPermission) &&
                                                    <Grid item xs={2}>
                                                        <Button
                                                            variant="contained"
                                                            spacing={1}
                                                            onClick={() => handleAddDimensions(ele?.tabKey)}
                                                            className="RF_sampledownload-btn"
                                                            sx={{ ...buttonClasses.lynkitBlackFill, textTransform: "none", textAlign: "start", ...textClasses.t12n, padding: "10px 10px", }}
                                                            startIcon={<AddIcon />}
                                                        >

                                                            {ele?.buttonText}
                                                        </Button>
                                                    </Grid>}
                                            </Grid>
                                        </Box>
                                    </Grid>
                            )
                            }
                        </TabPanel>
                    </>
                })
            }
            
            {["submitCapacity", "updateCapacity"].includes(showModel) ? (
                <AreaUtilizationStep1
                    fetchAreautilization={() => {
                        setFilter({ ...initail_filter, fetch: false })
                        let tempTab = tabIndex
                        if (validTabList?.length - 1 < tabIndex) {
                            setTabIndex(0)
                            tempTab = 0
                        }
                        fetchAreautilization(floorInfo, initail_filter, tempTab)
                    }}
                    floorInfo={floorInfo}
                    floorId={floorInfo?.floorId}
                    showModel={showModel}
                    setShowModel={setShowModel}
                    warehouseInfo={warehouseInfo}
                    cellCapacityDetail={cellCapacityDetail}

                />
            ) : (
                ""
            )}
            {["addAccessbility"].includes(showModel) ? (
                <AreaAccessbility
                    fetchAreautilization={() => {
                        setFilter({ ...initail_filter, fetch: false })
                        let tempTab = tabIndex
                        if (validTabList?.length - 1 < tabIndex) {
                            setTabIndex(0)
                            tempTab = 0
                        }
                        fetchAreautilization(floorInfo, initail_filter, tempTab)
                    }}
                    floorInfo={floorInfo}
                    floorId={floorInfo?.floorId}
                    showModel={showModel}
                    setShowModel={setShowModel}
                    warehouseInfo={warehouseInfo}
                />
            ) : (
                ""
            )}
            { ["showUtilizationDetail","showHeatmapDetail"]?.includes(showModel) && <ViewAreaUtilizationDetail
                showModel={showModel}
                setShowModel={setShowModel}
                cellCapacityDetail={cellCapacityDetail}
                utiliZationID={utiliZationID}
                setUtilizationID={setUtilizationID}
                floorInfo={floorInfo}
                warehouseInfo={warehouseInfo}
            />}
        </Box>
    );
}

export default AccessbilityAndUtilization;


let detail_Initail_filter = {
    page: 1,
    limit: 10,
    entityID: "",
    entityType: "",
};
const ViewAreaUtilizationDetail = (props) => {
    const dispatch = useDispatch();

    const {
        showModel = false,
        setShowModel,
        cellCapacityDetail,
        utiliZationID,
        floorInfo,
        warehouseInfo
    } = props;
    const [dialogFilter, setdialogFilter] = useState({
        ...detail_Initail_filter,
    });
    const [dialogLoader, setDialogLoader] = useState(true);
    const entityTypeOption = [
        { label: "Loose Items", value: "looseItems" },
        { label: "box Or Packages", value: "boxOrPackages" },
    ];
    const [locationBehaviorDetailData,setLocationBehaviorDetailData]=useState({})
    const header = [
        "Entity ID ",
        "Entity Type",
        "Max. QTY ",
        "Max Accepted Weight",
        // "Created By ",
        // "Created At ",
    ];

    const areaUtilizationTableRowData = useMemo(() => {
       return cellCapacityDetail?.data?.map(
            (cellData, index) => {
                const item = [];
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                            textTransform: "capitalize"
                        }}
                    >
                        {cellData?.SKU_ID || "-"}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {cellData?.type && textCapitalize(cellData?.type) || "-"}
                    </Typography>
                );
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {cellData?.maxCount || "-"}
                    </Typography>
                );

                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >
                        {cellCapacityDetail?.capacityDetail?.weightCapacity?.maxWeight || "-"}
                    </Typography>
                );
                return item;
            }

        );
    },[cellCapacityDetail])
    

    const debouncedSearchedentityID = useDebounce(dialogFilter.entityID, 500);
    const fetchContainerData = () =>{
        if(showModel == "showUtilizationDetail"){
            const payload = {
                floorId: floorInfo?.floorId,
                utiliZationID: utiliZationID,
                page: dialogFilter.page,
                limit: dialogFilter.limit,
                entityID: dialogFilter.entityID,
                entityType: dialogFilter.entityType,
            };
            setDialogLoader(true);
            dispatch(
                getCellCapacityDetail(payload, (data) => {
                    setDialogLoader(false);
                })
            ); 
        }
        else{
            const payload = {
                floorId: floorInfo?.floorId,
                type: utiliZationID,
                warehouseId: warehouseInfo?.warehouseId
          
            };
            setDialogLoader(true);
            dispatch(
                getGridBehaviourDetail(payload, (data) => {
                    if(data.success){
                        setLocationBehaviorDetailData(data)
                    }
                    setDialogLoader(false);
                })
            ); 
        }
    }
    useEffect(() => {
        fetchContainerData();
    }, [
        debouncedSearchedentityID,
        dialogFilter.entityType,
        dialogFilter.page,
        dialogFilter.limit,
        utiliZationID,
    ]);

    let containerData = showModel == "showUtilizationDetail" ? cellCapacityDetail?.capacityDetail?.cellDetail : locationBehaviorDetailData?.data?.data || [];
    const getBody = () => {
        return (
            <>
                {
                    showModel == "showHeatmapDetail" && utiliZationID != "notMovingLocations" &&
                    <Grid container item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mb: 2
                        }}>
                        <Grid item sx={{ ml: 1 }}>
                            <Typography
                                sx={{
                                    ...textClasses.t14n,
                                    textAlign: "center",
                                    border: "1px solid #FF7200",
                                    borderRadius: "8px",
                                    bgcolor: "#ff720014",
                                    padding: "10px",
                                    color: "#FF7200",
                                    fontWeight: "700",
                                    // marginTop: "-60px",
                                    mt: { sm: 1, md: 0 }
                                }}
                            >
                                {locationBehaviorDetailData?.data?.sevenDayData || 0} New Location(s) Identified as {textCapitalize(utiliZationID.replace(/Locations/g, ""))} In Last 7 Days
                                {/* {renderOptionHandler(detail, option?.renderOption || [])} */}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ ml: 1 }}>
                            <Typography
                                sx={{
                                    ...textClasses.t14n,
                                    textAlign: "center",
                                    padding: "10px",
                                    color: "#FF7200",
                                    fontWeight: "700",
                                    textDecoration: 'underline',
                                    mt: { sm: 1, md: 0 }
                                }}
                            >
                                Total : {locationBehaviorDetailData?.data?.totalData || 0}
                                {/* {renderOptionHandler(detail, option?.renderOption || [])} */}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                {showModel == "showHeatmapDetail" && dialogLoader ?
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{ p: 10 }}
                    >
                        <Grid item>
                            <GenericLoader />
                        </Grid>
                    </Grid>
                    :
                    <GenericAutoSizerComponent
                        mappedData={containerData?.map(dl => dl?.name) || []}
                        containerHeight="210px"
                        // customStyle={{
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     justifyContent: "space-between",
                        //     width: '65vw',
                        //     position: 'absolute',
                        //     left: '1.5vw'
                        // }}
                        xsCol={1}
                        smCol={2}
                        mdCol={3}
                        lgCol={4}
                        xlCol={6}
                    />}
                {
                    showModel == "showUtilizationDetail" && <>
                        <Grid
                            container
                            item
                            direction={"row"}
                            justifyContent={"start"}
                            xs={12}
                            mb={3}
                        >
                            <Grid item xs={4} sx={{ mr: 2 }}>
                                <OutlinedInput
                                    onChange={(e) =>
                                        setdialogFilter({
                                            ...dialogFilter,
                                            entityID: e?.target?.value || "",
                                            page: 1
                                        })
                                    }
                                    size="small"
                                    sx={{
                                        ...selectStylesOverride,
                                        width: "100%",
                                        fontSize: "12px",
                                        "& input::placeholder": {
                                            fontSize: "14px",
                                        },
                                    }}
                                    value={dialogFilter.entityID || ""}
                                    placeholder="Entity ID"
                                    endAdornment={
                                        <InputAdornment>
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} sx={{ mr: 2 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size="small"
                                    onChange={(e, option) =>
                                        setdialogFilter({
                                            ...dialogFilter,
                                            page: 1,
                                            entityType: option?.value || "",
                                        })
                                    }
                                    value={
                                        (dialogFilter.entityType &&
                                            entityTypeOption.find(
                                                (dl) => dl.value == dialogFilter.entityType
                                            )?.label) ||
                                        ""
                                    }
                                    options={entityTypeOption}
                                    sx={{ ...selectStylesOverride, width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Entity Type" />
                                    )}
                                />
                            </Grid>
                            {(dialogFilter.entityID || dialogFilter.entityType) && (
                                <Grid item xs={2}>
                                    <Button type="button">
                                        <Typography
                                            sx={{
                                                ...textClasses.boldText,
                                                color: theme.themeOrange,
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                textTransform: "none",
                                            }}
                                            onClick={() => {
                                                setdialogFilter({ ...detail_Initail_filter });
                                            }}
                                        >
                                            Clear Filter(s)
                                        </Typography>
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        {dialogLoader ? (
                            <Grid
                                container
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems="center"
                                sx={{ p: 10 }}
                            >
                                <Grid item>
                                    <GenericLoader />
                                </Grid>
                            </Grid>
                        ) : (
                            <GenericTable
                                header={header}
                                rows={areaUtilizationTableRowData || []}
                                pageCount={cellCapacityDetail?.count}
                                pageNumber={dialogFilter.page - 1}
                                handleChangePage={(event, pageValue) => {
                                    setdialogFilter({ ...dialogFilter, page: pageValue });
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setdialogFilter({ ...dialogFilter, limit: +event.target.value });
                                    // setFilter(+event.target.value);
                                }}
                                rowsPerPage={dialogFilter.limit}
                            />
                        )}
                    </>
                }
            </>
        );
    };

    return (
        <>
            <AlertDialog
                viewDialog={showModel}
                sx={{ borderRadius: "50px" }}
                handleClose={()=>setShowModel(false)}
                width="70%"
                title={
                    <Typography sx={{ fontWeight: "1000", fontSize: "24px" }}>
                        <span>
                            {showModel === "showUtilizationDetail"
                                ? "Inventory Holding Capacity"
                                : `Location Behavior - ${textCapitalize(utiliZationID)}`}
                        </span>
                    </Typography>
                }
                body={getBody()}
            >
                <Button
                    //   fullWidth
                    variant="outlined"
                    onClick={() => setShowModel(false)}
                    sx={{
                        ...buttonClasses.lynkitBlackFill,
                        m: 1,
                        fontSize: ".7rem",
                        minWidth: "100px",
                    }}
                >
                    Cancel
                </Button>
            </AlertDialog>{" "}
        </>
    );
};

