import React, { useEffect, useState } from "react";
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { Grid, Typography, Button, FormControl, OutlinedInput, FormControlLabel, Radio } from "@mui/material";
import GenericLoader from "../../Components/Generic/GenericLoader";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../../redux/actions";
import { showToast, textCapitalize } from "../../../services/functions";
import { selectStylesOverride } from "../../../utils/util";
import MultiSelectAll from "../MainDashboard/MultiSelect";
import TypeAndSearch from "../../Components/TypeAndSearch";
import { allWarehouses, component } from "./AutomationRules/constant";
import history from "../../../services/history";
import GenericAutoSizerComponent from "../../Components/Generic/GenericAutoSizerComponent";
import { getInputField } from "../../Components/TemplateInputConfiguration";
import { saveWorkflow, updateWorkflow } from "../../../redux/actions";

let allWfScope = [
    { label: "Global", value: "global", },
    { label: "Warehouse", value: "warehouse", },
    { label: "SKU", value: "sku", },
    { label: "Both", value: "both", },
    { label: "Source", value: "source", },
]

const CreateUpdateWorkflow = ({ viewModal, setViewModal, refreshTableData, setSelectedEntries, isEditable = false, detailData = {} }) => {
    const dispatch = useDispatch();
    const warehouseDetail = useSelector(
        (state) => state.superadmin.warehouseDetail
    );
    const selectedWorkFlowType = viewModal?.action?.workflowType || ""
    const selectedOperations = viewModal?.action?.operations?.filter(dl => dl?.taskId != "")?.map(dl => dl?.taskId)
    const [dataReceieved, isDataReceived] = useState(false)
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        selectedWarehouses: [],
        wfScope: null,
        wfName: "",
        selectedSkus: [],
        wfDescription: ""
    })
    const handleWarehouseSelection = (result) => {
        setFormData({ ...formData, selectedWarehouses: result })
    }

    const handleRadioChange = (event) => {
        const selectedValue = event.target.value;

        setFormData((prev) => ({
            ...prev,
            selectedWarehouses: [],
            selectedSkus: [],
            wfScope: selectedValue,
        }));
    };
    const handleSubmit = () => {
        isDataReceived(true)
        const { selectedWarehouses, wfScope, wfName, selectedSkus, wfDescription } = formData;
        const payload = {
            ...(isEditable && { objectId: detailData?._id || "" }), // Include only if isEditable is true
            skuId: selectedSkus?.map(dl => dl?.value) || [],
            warehouseId: selectedWarehouses?.map(dl => dl?._id || []),
            workflowName: wfName || "",
            workflowScope: wfScope || null,
            description: wfDescription || "",
            // workflowType: selectedWorkFlowType || "",
            jobTypesId: selectedOperations || []
        }

        if (isEditable) {
            dispatch(
                updateWorkflow(payload, (data = {}) => {
                    showToast(data.message, !data.success);
                    isDataReceived(false)
                    if (data.success) {
                        setViewModal({ ...viewModal, type: '', action: {} })
                        history.push("/dashboard/setting/manage-workflows")
                    }
                })
            )
        }
        else {
            dispatch(
                saveWorkflow(payload, (data = {}) => {
                    showToast(data.message, !data.success);
                    isDataReceived(false)
                    if (data.success) {
                        if (viewModal.type == "submitAndAddWf") {
                            refreshTableData(0)
                            setViewModal({ ...viewModal, type: '', action: {} })
                        } else {
                            history.push("/dashboard/setting/manage-workflows")
                        }
                    }
                })
            )
        }
    }
    useEffect(() => {
        dispatch(getCompany({ type: "warehouse", status: "active" }));

        if (isEditable) {
            // Setting the form data with detailData when editing
            setFormData({
                selectedWarehouses: detailData?.warehouseId?.map((warehouseId) => {
                    // Find the corresponding warehouse object
                    const warehouse = warehouseDetail?.data?.find(w => w._id === warehouseId);
                    // Return the full warehouse object if found, else return a fallback
                    return warehouse || { _id: warehouseId, name: "Unknown Warehouse" };
                }) || [],
                wfScope: detailData?.workflowScope || null,
                wfName: detailData?.workflowName || "",
                selectedSkus: detailData?.skuId?.map((sku) => ({
                    value: sku,
                })) || [],
                wfDescription: detailData?.description || "",
            });
        }
        else {
            // Setting the default data for new workflow creation

            setFormData((prev) => ({
                ...prev,
                selectedWarehouses: [],
                selectedSkus: [],
                wfScope: "global",
            }));
        }
    }, [isEditable])
    return (
        <>

            <AlertDialog
                viewDialog={["submitAndAddWf", "submitNewWf", "updateWf"].includes(viewModal.type)}
                handleClose={() => setViewModal({ ...viewModal, type: '', action: {} })}
                showDivider={true}
                // viewSearchInput={true}
                width="40%"
                // title="Publish Inward Operations Workflow"
                actionsCSS={{ display: "flex", justifyContent: 'left' }}
                body={
                    <>
                        {loader ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid> :
                            <Grid container sx={{ p: 2 }} alignItems={"center"} justifyContent={"center"}>
                                <Grid item sm={12} xs={12} >
                                    <Typography align='left' sx={{ ...textClasses.t20n, fontWeight: "600", }}>Publish {textCapitalize(selectedWorkFlowType)} Workflow</Typography>
                                </Grid>
                                <Grid item sm={12} xs={12} sx={{ my: 1 }}>
                                    <Typography align='left' sx={{ ...textClasses.t14n }}>To save workflow fill the below details</Typography>
                                </Grid>
                                <Grid item sm={12} xs={12} sx={{ my: 1 }}>
                                    <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                        <OutlinedInput
                                            value={formData?.wfName}
                                            data-testid="enter-workflowname"
                                            onChange={(e) => {
                                                setFormData({ ...formData, wfName: e.target.value })
                                            }}
                                            sx={{
                                                fontSize: "12px",
                                                width: '100%',
                                                height: '35px',
                                                ...selectStylesOverride,
                                                "& input::placeholder": {
                                                    fontSize: "12px",
                                                },
                                            }}
                                            // disabled={action === "view" ? true : false}
                                            // error={errorMessage.name ? true : false}
                                            placeholder='Enter Workflow Name*'
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item sm={12} xs={12} sx={{ my: 1 }}>
                                    {/* Left side: Workflow Scope label */}
                                    <Grid item sm={4} xs={4}>
                                        <Typography
                                            align="left"
                                            data-testid="radio-select-wfscope"
                                            sx={{
                                                ...textClasses.t14n,
                                                fontWeight: "500",
                                                mr: 1,
                                                mt: 1
                                            }}
                                        >
                                            Workflow Scope:
                                        </Typography>
                                    </Grid>

                                    {/* Right side: Radio buttons */}
                                    <Grid item sm={8} xs={8} container >
                                        {allWfScope?.map((m, i) => (
                                            <Grid item key={i}>
                                                <FormControlLabel
                                                    label={
                                                        <Typography sx={{ ...textClasses.t13n }}>
                                                            {m.label}
                                                        </Typography>
                                                    }
                                                    control={
                                                        <Radio
                                                            value={m.value}
                                                            checked={formData.wfScope === m.value} // Set checked based on formData
                                                            onChange={handleRadioChange} // Handle radio button change
                                                            sx={{
                                                                "&, &.Mui-checked": { color: theme.themeOrange },
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                {
                                    ["warehouse", "both"].includes(formData?.wfScope) &&
                                    <Grid item sm={12} xs={12} sx={{ my: 1 }}>
                                            <MultiSelectAll
                                                data-testid="select-warehouses"
                                                sx={{ maxheight: "700px" }}
                                                items={warehouseDetail?.data || []}
                                                selectAllLabel="Select All"
                                                value={formData?.selectedWarehouses}
                                                onChange={handleWarehouseSelection}
                                                label="Select Warehouse(Multiple)"
                                                defaultSelectAll={false}
                                            />
                                    </Grid>
                                }
                                {["sku", "both"].includes(formData?.wfScope) &&
                                    <>
                                        <Grid item sm={12} xs={12} sx={{ my: 1 }}>
                                            <TypeAndSearch
                                                data-testid="select-skus"
                                                component={component({ type: "SKU", filter: [{ label: "dataEntryType", value: ["item", "package", "kit"] }] })}
                                                // set_Value={true}
                                                setEmptyOnChange={true}
                                                handleChange={(e, option) => {
                                                    const fetchBlock = option?.value.split("_")
                                                    const existingBlock = fetchBlock && fetchBlock[0] && formData?.selectedSkus?.find((option) => option.value === fetchBlock[0])
                                                    if (existingBlock) {
                                                        showToast("This SKU_ID is already selected!", true)
                                                    } else
                                                        if (
                                                            option &&
                                                            !formData?.selectedSkus.find(
                                                                (item) => item.value === option.value
                                                            )
                                                        ) {
                                                            setFormData({ ...formData, selectedSkus: [option, ...formData?.selectedSkus] })
                                                        }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12} sx={{ my: 1 }}>
                                            {formData?.selectedSkus?.length === 0 ? null :
                                                <GenericAutoSizerComponent
                                                    mappedData={formData?.selectedSkus?.map(
                                                        (dl) => dl.value
                                                    ) || []}
                                                    onDelete={(index) => {
                                                        const filteredData = formData?.selectedSkus?.filter((_, i) => i !== index);
                                                        setFormData({ ...formData, selectedSkus: filteredData });
                                                    }}
                                                    containerHeight="200px"
                                                    xsCol={8}
                                                    smCol={12}
                                                    mdCol={12}
                                                    lgCol={12}
                                                    xlCol={12}
                                                />
                                            }
                                        </Grid>
                                    </>
                                }
                                <Grid item sm={12} xs={12} sx={{ my: 1 }} data-testid="enter-shortdesc">
                                    {getInputField({
                                        component: {
                                            type: "textarea",
                                            _id: "wfDescription",
                                            placeholder: "Write a short description (Optional)"
                                        }, componentValues: formData, handleChange: (comp, event) => { setFormData({ ...formData, wfDescription: event?.target?.value }) }
                                    })}
                                </Grid>
                            </Grid>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '', action: {} }) }}
                    sx={{ ...buttonClasses.lynkitBlackEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
                <Button
                    variant="outlined"
                    data-testid="submit-btn"
                    onClick={handleSubmit}
                    disabled={dataReceieved || !formData?.wfName || (["warehouse", "both"].includes(formData?.wfScope)
                        ? (!formData?.selectedWarehouses || formData?.selectedWarehouses.length === 0)
                        : false) || (["sku", "both"].includes(formData?.wfScope) ? (!formData?.selectedSkus || formData?.selectedSkus.length === 0)
                            : false)}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >{isEditable ? "Update" : "Publish"}
                </Button>
            </AlertDialog>
        </>
    )
}
export default CreateUpdateWorkflow;
