export const automationTableDataInward= [
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Rahul Mukim, Admin ",
    UpdatedAt: "23-05-2023 13:56",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Govind, Admin ",
    UpdatedAt: "23-04-2023 23:56",
    Status: true,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Joseph, Admin ",
    UpdatedAt: "23-04-2023 03:56",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Madhur, Admin ",
    UpdatedAt: "23-04-2023 22:51",
    Status: true,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Rahul Mukim, Admin ",
    UpdatedAt: "23-04-2023 23:56",
    Status: false,
    Action: "Action",
  },
 
];
export const automationTableDataOutward = [
  {
    Name: "Outward Task Scheduling",
    Details: "After Picking create dispatch task",
    WorkFlow: "Outward FG",
    CreatedBy: "Mayank, Admin ",
    UpdatedAt: "13-06-2023 11:36",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Outward Task Scheduling",
    Details: "After Picking create dispatch task",
    WorkFlow: "Outward FG",
    CreatedBy: "Ratan Mukim, Admin ",
    UpdatedAt: "23-04-2023 23:56",
    Status: true,
    Action: "Action",
  },
  {
    Name: "Outward Task Scheduling",
    Details: "After Picking create dispatch task",
    WorkFlow: "Outward FG",
    CreatedBy: "Ajay, Admin ",
    UpdatedAt: "12-08-2023 21:12",
    Status: false,
    Action: "Action",
  },
];
export const automationTableDataInventory = [
  {
    Name: "Inventory Task Scheduling",
    Details: "Create inventory audit task",
    WorkFlow: "Inventory FG",
    CreatedBy: "yatin, Admin ",
    UpdatedAt: "12-09-2023 11:33",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Inventory Task Scheduling",
    Details: "Create inventory audit task",
    WorkFlow: "Inventory FG",
    CreatedBy: "Rinku, Admin ",
    UpdatedAt: "23-04-2023 12:56",
    Status: true,
    Action: "Action",
  },

];
export const automationHeader = [
  "name",
  "Detail",
  "Workflow",
  "Created By",
  "Updated At",
  "Status",
];

export const allWorkflows = [
  {
    status: "active",
    isGridExist: false,
    _id: "6373811b45d7f86888289496",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouse",
    companyName: "Ninja Coding",
    name: "Workflow I",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.638Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "634010de65af23502ffbc0c6",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouses",
    companyName: "Ninja Coding",
    name: "Workflow II",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "641bfde82b311227ac6d1164",
    companyName: "Ninja Coding",
    base: "Coding Ninjas",
    name: "Workflow III",
    type: "warehouse",
    location: "Lynkit",
    description: "Added on 23-03-2023",
    createdAt: "2022-11-23T10:29:02.688Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
    __v: 0,
  },
];
export const allWarehouses = [
  {
    status: "active",
    isGridExist: false,
    _id: "6373811b45d7f86888289496",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouse",
    companyName: "Ninja Coding",
    name: "Okhla Warehouse III",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.638Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "634010de65af23502ffbc0c6",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouses",
    companyName: "Ninja Coding",
    name: "Okhla Warehouse 1",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "641bfde82b311227ac6d1164",
    companyName: "Ninja Coding",
    base: "Coding Ninjas",
    name: "Lynkit Warehouse 1",
    type: "warehouse",
    location: "Lynkit",
    description: "Added on 23-03-2023",
    createdAt: "2022-11-23T10:29:02.688Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
    __v: 0,
  },
];

export const jobTypes = [
    {
        "status": "active",
        "isGridExist": false,
        "_id": "6373811b45d7f86888289496",
        "base": "Coding Ninjas",
        "description": "Coding Ninjas warehouse",
        "companyName": "Ninja Coding",
        "name": "Picking",
        "type": "warehouse",
        "location": "Okhla",
        "createdAt": "2022-10-07T07:41:46.078Z",
        "updatedAt": "2023-04-28T03:46:06.638Z"
    },
    {
        "status": "active",
        "isGridExist": false,
        "_id": "634010de65af23502ffbc0c6",
        "base": "Coding Ninjas",
        "description": "Coding Ninjas warehouses",
        "companyName": "Ninja Coding",
        "name": "Change Location",
        "type": "warehouse",
        "location": "Okhla",
        "createdAt": "2022-10-07T07:41:46.078Z",
        "updatedAt": "2023-04-28T03:46:06.573Z"
    },
    {
        "status": "active",
        "isGridExist": false,
        "_id": "641bfde82b311227ac6d1164",
        "companyName": "Ninja Coding",
        "base": "Coding Ninjas",
        "name": "Put Away",
        "type": "warehouse",
        "location": "Lynkit",
        "description": "Added on 23-03-2023",
        "createdAt": "2022-11-23T10:29:02.688Z",
        "updatedAt": "2023-04-28T03:46:06.573Z",
        "__v": 0
    },
    {
        "status": "active",
        "isGridExist": false,
        "_id": "641bfde82b311227ac6d1164",
        "companyName": "Ninja Coding",
        "base": "Coding Ninjas",
        "name": "Quality Check",
        "type": "warehouse",
        "location": "Lynkit",
        "description": "Added on 23-03-2023",
        "createdAt": "2022-11-23T10:29:02.688Z",
        "updatedAt": "2023-04-28T03:46:06.573Z",
        "__v": 0
    }
];

export const taskFieldList = [
    {
        "_id": "6373811b45d7f86888289496",
        "name": "Current Status",
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Total Count",
    },
];

export const initialConditionList = [
    {
   
        "_id": "6373811b45d7f86888289496",
        "name": "greater than",
        "value": ">",

    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "less than",
        "value": "<",   
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "equal to",
        "value": "==",
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "greater than & equal to",
        "value": ">=",
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "less than & equal to",
        "value": "<=",
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "not equal to",
        "value": "!=",
    },
];

export const fieldValueData = [
    {
        "_id": "6373811b45d7f86888289496",
        "name": "Pass",
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Fail"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Yes"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "No"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Full"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Partial"
    },
];

export const initialFieldConditionalValues = {
  numberValue: "", 
  stringValue: "", 
  dateValue: "",
}

export const ruleCategoryOptions = [
  {
    label: 'Above SKU Can Not Be Place Together, only can place',
    value: 'canNotBePlacedTogether',
    options: [
      {
        name: "Shelves(s) Gap",
        type: "number",
        fieldId: "shelfGap"
      },
      {
        name: "Rack Gap",
        type: "number",
        fieldId: "rackGap"
      },
      {
        name: "Block Gap",
        type: "number",
        fieldId: "blockGap"
      },
      {
        name: "Gap Distance",
        type: "number",
        fieldId: "gapDistance"
      },
      {
        name: "UOM",
        type: "dropdown",
        fieldId: "UOM",
        dropdownOptions: [
          {
            label: "ft",
            value: "ft"
          },
          {
            label: "m",
            value: "m"
          },
          {
            label: "cm",
            value: "cm"
          },
          {
            label: "inch",
            value: "inch"
          },
        ]

      },
      {
        name: "Additional Condition(s)",
        type: "dropdown",
        fieldId: "additionalConditions",
        dropdownOptions: [
          {
            label: "Can Not Be Stacked at Same Rack",
            value: "notSameRack"
          },
          {
            label: "Can Not Be Stacked at Same Shelve(s)",
            value: "notSameShelf"
          },
          {
            label: "Can Not Be Stacked at Same Block",
            value: "notSameBlock"
          },
          {
            label: "Can Not Be Stacked at Same Floor",
            value: "notSameFloor"
          },
        ]
      },
    ]
  },
  {
    label: 'Above SKU Can Be Place Together, only can place',
    value: 'canBePlacedTogether',
    options: []
  }
]

export const component = ({ type = "SKU",filter=[]}) => {
  if (type == "SKU") {
    return (
      {
        _id: "SKU",
        type: "select",
        icon: "simple-icon-note",
        label: "Select SKU ID/Name",
        description: "Select SKU ID/Name",
        placeholder: "Select SKU ID/Name",
        className: "form-control",
        subtype: "text",
        regex: "",
        handle: true,
        forType: "all",
        elementType: "Selection Type",
        value: "",
        required: true,
        dynamic: true,
        auth: true,
        apiBindingValue: "label",
        apiBindingKey: "value",
        apiDomain: "/v1/",
        apiEndPoint: "inventory/getDistinctMasterList",
        "renderOption" : [
          {
              "label" : "",
              "multiple" : false,
              "prefix" : "",
              "postfix" : "",
              "key" : "label",
              "keys" : [

              ]
          },
          {
              "label" : "",
              "multiple" : false,
              "prefix" : " (",
              "postfix" : ")",
              "key" : "SKU_Detail"
          }
      ],
        apiMethod: "post",
        name: "Item Master List",
        apiDataReturnKey: "data",
        apiParameters: [
          {
            label: "isSkipTemplate",
            value: true,
          },
          {
            label: "master_Type",
            value: "item-master",
          },
          {
            label: "page",
            value: 1,
          },
          {
            label: "limit",
            value: 25,
          },
          {
            label: "base_ID",
            value: "onSearch",
          },
          ...filter
        ],
        "setTargetValue": true,
        "targetAPI": "scan/scanEntityMaterData",
        "targetAPIMethod": "post",
        "targetParameters": [
          {
            "label": "isSkipTemplate",
            "value": true
          },
          {
            "label": "base_ID",
            "value": "$self.value"
          },
          {
            "label": "master_Type",
            "value": "item-master"
          }
        ],
        "targetApiDataReturnKey": "data",
        "onChangeSet": {
          "SKU_ID": "$response.formData.SKU_ID",
        },
        "values": [],

      })
  }
}


export const AUTOMATION_TRIGGER= "AUTOMATION_TRIGGER";
export const JOB_CREATION_STEP = "JOB_CREATION_STEP";

export const ADD_COMPONENT = "ADD_COMPONENT";
export const ADD_CONDITIONS = "ADD_CONDITIONS";
