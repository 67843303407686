/* eslint-disable react/react-in-jsx-scope */
import { Button, Checkbox, Grid, MenuItem, Paper, Select, Typography, Box, Tabs, Tab, FormControl, OutlinedInput, InputAdornment, Card, CardHeader, IconButton, CardContent, Divider, TablePagination, Modal, TextField, Menu } from '@mui/material'
import { Close, ControlPoint, KeyboardArrowDown, Label, QrCodeScanner, QuestionMark, ReplyTwoTone, Search, Sell, SellOutlined } from '@mui/icons-material'
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../static/styles/theme'
import moment from 'moment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ImageIcon from '@mui/icons-material/Image';

export const getSpentTime = (then, now) => {
    if (then != null) {
        then = moment(then);
        now = now ? moment(now) : moment();

        let dayDiff = now.diff(then, "days");
        let hourDiff = now.diff(then, "hours");
        let minDiff = now.diff(then, "minutes");
        let timeSpent;

        if (dayDiff < 1) {
            if (hourDiff < 1) {
                timeSpent = `${minDiff} minutes`
            }
            else {
                timeSpent = `${hourDiff} hours`
            }
        }
        else {
            timeSpent = `${dayDiff} days`
        }
        return timeSpent
    }
    else {
        return "-"
    }
}

export const getHeader = (allTemplates, tempateData, templateIndex, checkedArray, allHandleChecked,urlPath,checkPermission) => {
    let baseLabels = [];
    let baseIds = []
    let scanFeature={
        "label":"",
        "value":""
    }

    if (allTemplates.length > 0) {
        var staticTableHeaders = ['Created At', 'Updated At'];
        var staticTableKeys = ['createdAt', 'updatedAt',]

        if(urlPath && ['item-master',"kit-master"].includes(urlPath)){
            staticTableHeaders.unshift("Current Stock")
            staticTableKeys.unshift("currentStock")
            staticTableHeaders.unshift("Stock Status")
            staticTableKeys.unshift("stockStatus")
        }
        if(urlPath && urlPath=="pallet-master"){
            if (checkPermission("attachTag")) {
                staticTableHeaders.unshift("Attached Tags")
                staticTableKeys.unshift("attachedTags")
            }
            staticTableHeaders.unshift("Current Status")
            staticTableKeys.unshift("currentStatus")
        }
      
        if(checkPermission("view") || checkPermission("delete")   || checkPermission("edit")){
            baseLabels.push(
                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                    <Checkbox
                        size='small'
                        sx={{ marginBottom: '4px' }}
                        onClick={(event) => allHandleChecked(event)}
                        disabled={(tempateData || []).length === 0 ? true : false}
                        checked={(tempateData || []).map((item) => { return (item._id) }).every(el => checkedArray.includes(el)) && (tempateData || []).length !== 0  ? true : false }
                    />
                </Typography>
            )
            baseIds.push('check')
            staticTableHeaders.push('Action')
            staticTableKeys.push('action')
        }
      
        baseLabels.push(
            <Typography sx={{ ...textClasses.normalText, color: '#000' }}>
                S.No.
            </Typography>
        )
        baseIds.push('sNo')
        for (let j = 0; j < allTemplates[templateIndex]?.attributes?.length; j++) {
            // if(j <= 3){
                baseLabels.push(allTemplates[templateIndex].attributes[j].label)
                baseIds.push(allTemplates[templateIndex].attributes[j]._id)
            // }
            // else{
            //     break;
            // }
        }
        // if(urlPath && urlPath=="item-master"){
        //     baseLabels.push(
        //         <Typography sx={{ ...textClasses.normalText, color:"#F7200" }}>
        //             Generate Label
        //         </Typography>
        //     ) 
        //     baseIds.push("generateLabel")
        // }
        if(allTemplates && templateIndex >= 0 && (allTemplates[templateIndex] || [])?.attributes?.filter(h => h.isForBarcode).length > 0){
            scanFeature["label"]="Generate Label"
            scanFeature["value"]="generateLabel"
        }
        baseLabels.push(...staticTableHeaders)
        baseIds.push(...staticTableKeys)
    }
    return { baseLabels, baseIds,scanFeature }
}


export const modelInfoSingle={
    "item-master":{
        "page_label":"Item Master",
        "apiPath":"getItemMasterData",
        "apiKey":"itemMasterId",
        "saveAPIPath":"saveItemMaster",
        "editAPIPath":"editItemMaster",
        "deleteAPIPath":"deleteItemMaster",
        "countAPI":"getItemTemplateWiseCount",
        "templateType":"itemMaster",
        "dataEntryType":["item","package"],
        "page_header":"Please select an Item Master template first.",
        "page_detail":"Hey, you need to select a previously created Item Master template, to enter details for master SKU's/Items and to automate the Inward operation(s).",
    },
    "kit-master":{
        "page_label":"Kit Master",
        "apiPath":"getItemMasterData",
        "apiKey":"itemMasterId",
        "saveAPIPath":"saveItemMaster",
        "editAPIPath":"editItemMaster",
        "deleteAPIPath":"deleteItemMaster",
        "countAPI":"getItemTemplateWiseCount",
        "templateType":"itemMaster",
        "dataEntryType":"kit",
        "page_header":"Please select an Kit Master template first.",
        "page_detail":"Hey, you need to select a previously created Kit Master template, to enter details for master SKU's/Items and to automate the Inward operation(s).",
    },
    "pallet-master":{
        "page_label":"Pallet Master",
        "apiPath":"getItemMasterData",
        "apiKey":"itemMasterId",
        "saveAPIPath":"saveItemMaster",
        "editAPIPath":"editItemMaster",
        "deleteAPIPath":"deleteItemMaster",
        "countAPI":"getItemTemplateWiseCount",
        "templateType":"itemMaster",
        "dataEntryType":"pallet",
        "page_header":"Please select a Pallet Master template first.",
        "page_detail":"Hey, you need to select a previously created Pallet Master template, to enter details for master SKU's/Items and to automate the Inward operation(s).",
    },
}

export const modelInfoMultiple={
    "item-master":{
        "page_label":"Item Master",
        "templateType":"itemMaster",
        "bulkUploadAPI":"bulkUpload",
        "page_header":"Please select an Item Master template first and Download the sample file.",
        "page_detail":"Hey, please download the sample file as per the selected template and add your Item master data in required format, to create new Item master database",
    },
    "kit-master":{
        "page_label":"kit Master",
        "templateType":"itemMaster",
        "bulkUploadAPI":"bulkUpload",
        "page_header":"Please select an Kit Master template first and Download the sample file.",
        "page_detail":"Hey, please download the sample file as per the selected template and add your kit master data in required format, to create new kit master database",
    },
    "pallet-master":{
        "page_label":"Pallet Master",
        "templateType":"itemMaster",
        "bulkUploadAPI":"bulkUpload",
        "page_header":"Please select an Pallet Master template first and Download the sample file.",
        "page_detail":"Hey, please download the sample file as per the selected template and add your Pallet master data in required format, to create new pallet master database",
    },
   
}

export const titleMap = {
    'item-master': 'Item Master',
    'kit-master': 'Kit Master',
    'pallet-master': 'Pallet Master',
    'customer-master': 'Customer Master',
    'supplier-master': 'Supplier Master',
};


export const RFIDMapping =(elementType="",LYNKTID_URL="")=> {
   const RFIDMapping={
     "_id": elementType,
    "type": "select",
    "label": "Select Tag",
    "description": "Select Tag",
    "placeholder": "Select Tag",
    "elementType": "Selection Type",
    "value": "",
    "required":true,
    "dynamic": true,
    "auth": true,
    "apiBindingValue": ["epc","tId"],
    "apiBindingKey": ["epc","tId"],
    "suffix": "displayName",
    "extraValue": [
        { label: "epc", value: "epc", },
        { label: "tId", value: "tId", },
        { label: "displayName", value: "displayName" },
        { label: "tagType", value: "tagType" },
    ],
    // "baseURL": LYNKTID_URL || "",
    "baseURL": "",
    "apiEndPoint": "tag/getTagList",
    // "apiEndPoint": "api/devices/tag/get-tag",
    "apiMethod": "post",
    "name": "Tag List",
    "apiDataReturnKey": "data",
    "apiParameters": [
      {
        "label": "page_no",
        "value": 1
      },
      {
        "label": "pagesize",
        "value": 25
      },
      {
        "label": "status",
        "value": ["", "Deallocated"]
      },
      {
        "label": "base_ID",
        "value": "onSearch"
      },
        {
            "label": "projectionObj",
            "value": {"epc":1,"tId":1,"displayName":1,"tagType":1,"_id":0}
        }
    ],
    "renderOption":[
        {
            "label": "EPC",
            "multiple": false,
            "prefix": "",
            "postfix": "",
            "key": "epc",
            "keys": [

            ]
        },
        {
            "label": "TID",
            "multiple": false,
            "prefix": ", ",
            "postfix": "",
            "key": "tId"
        },
        {
            "label": "",
            "multiple": false,
            "prefix": "(",
            "postfix": ")",
            "key": "diplayName"
        },
    ],
    "setTargetValue": false,
}
return RFIDMapping;
}
