import React from "react";
import { textClasses, inputClasses, buttonClasses } from "../../../../../static/styles/theme";
import {
  Autocomplete,
  Box,
  Grid,
  Link,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import ificon from "../../../../../static/images/Icon/createAutomation/ificon.png";
import ifblockicon from "../../../../../static/images/Icon/createAutomation/ifblockicon.svg";
import { useEffect, useState } from "react";
import {
  initialConditionList,
  fieldValueData,
  jobTypes,
  taskFieldList,
  initialFieldConditionalValues
} from "../constant";
import { selectStylesOverride } from "../../../../../utils/util";
import AddIcon from "../../../../../static/images/Icon/AddIcon.png";
import { showToast } from "../../../../../services/functions";
import { useDispatch, useSelector } from "react-redux";
import { getAutomationTaskFields } from "../../../../../redux/actions/AutomationRule";
import CreateAutomationCard from "./CreateAutomationCard";
import blackicon from "../../../../../static/images/Icon/createAutomation/blackicon.svg";
import plusCreateIcon from "../../../../../static/images/Icon/createAutomation/plusCreateIcon.svg";
import CustomChip from "../../../../Components/CustomChip";
import sendnotifactionicon from "../../../../../static/images/Icon/createAutomation/Sendnotificationicon.svg";
import DateField from "../../../../Components/TemplateInputConfiguration/InputComponent/DateField";
import InputField from "../../../../Components/TemplateInputConfiguration/InputComponent/InputField";
import NumberField from "../../../../Components/TemplateInputConfiguration/InputComponent/NumberField";
import moment from "moment";

const numberFieldComponent = {
  _id: "numberValue",
  label: "Enter Number",
  required: true,
  placeholder: "Type a number...",
}

const stringFieldComponent = {
  _id: "stringValue",
  label: "Enter Text",
  required: true,
  placeholder: "Type some text...",
}
const dateFieldComponent = {
  _id: "dateValue",
  label: "Enter Date",
  required: true,
  placeholder: "Type a date...",
}


const AddConditions = ({
  selectJobType,
  setSelectJobType,
  nextJobTypeList,
  taskFieldValue,
  setTaskFieldValue,
  conditionValue,
  setConditionValue,
  fieldConditionalValue,
  setFieldConditionalValue,
  addEmail,
  setAddEmail,
  visibleEmail,
  setVisibleEmail,
  selectJobAutomation,
  setSelectJobAutomation,
  displayCreateJob,
  setDisplayCreateJob,
  displayEmailSection,
  setDisplayEmailSection,
  displayAddAnAction,
  setDisplayAddAnAction,
}) => {
  const dispatch = useDispatch();

  const [taskFieldList, setTaskFieldList] = useState([]);
  const [conditionList, setConditionList] = useState(initialConditionList)
 
  // State to hold the value of the field

  const handleConditionChange = (option, setValue) => {
    setValue(option);
    if (option === null) {
      setDisplayAddAnAction(false);
      setDisplayCreateJob(false);
    }
  }

  const handleEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(addEmail)) {
      setVisibleEmail([addEmail, ...visibleEmail]);
      setAddEmail("");
    } else {
      showToast("Please enter valid email", true);
      return;
    }
  };
  const taskFieldTriggerConditionList = (taskType) => {
    let updatedConditionList;
    if (taskType != "number") {
      updatedConditionList = conditionList?.filter(dl => ["==", "!="].includes(dl.value))
      setConditionList(updatedConditionList)
    }
    else {
      setConditionList(initialConditionList)
    }
    setConditionValue(null)
    setFieldConditionalValue(initialFieldConditionalValues)
    setDisplayCreateJob(false);
    setDisplayEmailSection(false);
  }

  // `handleChange` function to handle input changes

  // HandleChange function
  const handleChangeFieldCondtionalValue = (component, event) => {
    // Safeguard to ensure event.target exists
    let value;
    if (["number", "string"].includes(taskFieldValue.type)) {
      value = event?.target?.value || "";
    }
    else {  // if type is date
      value = event;
      value = moment(new Date(value))
    }
    const fieldId = component?._id;

    // Update the value for the specific component
    setFieldConditionalValue((prev) => ({
      ...prev,
      [fieldId]: value, // Dynamically update the value based on the `_id`
    }));
  };

  useEffect(() => {
    dispatch(getAutomationTaskFields({}, (data) => {
      setTaskFieldList(data?.data)
    }))
  }, [])


  return (
    <>
      <>
        <Grid
          container
          spacing={0}
          sx={{
            marginLeft: "30px",
            marginTop: "25px",
          }}
        >
          <Grid item xs={0}>
            {" "}
            <img src={ificon} />
          </Grid>
          <Grid item xs={0}>
            {" "}
            <Typography
              sx={{
                ...textClasses.boldText,
                color: "#000000",
                paddingLeft: "15px",
              }}
            >
              IF, ELSE : Add Condition Options
            </Typography>
          </Grid>
        </Grid>
        <Typography
          sx={{
            ...textClasses.t12n,
            color: "#827878",
            marginLeft: "65px",
          }}
        >
          Set up your rule to run one path out of multiple, depending on which
          condition met.
        </Typography>
        <Box
          sx={{
            borderRadius: "8px",
            border: "1px solid #E0E0E0",
            margin: "10px 10px 0 55px",
            padding: " 10px 0px 15px 0px",
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              padding: "10px 0 0 15px",
            }}
          >
            <img src={ifblockicon} />
            <Typography
              data-testid="If-block"
              sx={{
                ...textClasses.boldText,
                color: "#000000",
                marginLeft: "8px",
              }}
            >
              If Block
            </Typography>
          </Grid>
          <Typography
            sx={{
              ...textClasses.t12n,
              marginLeft: "40px",
            }}
          >
            The if block executes the actions within that block when the all
            specified conditions matches.
          </Typography>
          <Grid
            container
            sx={{
              mt: 1,
              p: 1
            }}
            spacing={1}
          >
            <Grid item sm={4}>
              <Autocomplete
                value={taskFieldValue}
                data-testid="select-tasks"
                onChange={(e, option) => {
                  handleConditionChange(option, setTaskFieldValue)
                  taskFieldTriggerConditionList(option?.type)
                }}
                getOptionLabel={(option) => option.label}
                size="small"
                options={taskFieldList || []}
                sx={{
                  ...inputClasses.filterField,
                  // width: "277px",
                  // margin: "10px 0 0 10px",
                  background: "white",
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "yellow", ...textClasses.normalText }}
                    {...params}
                    label="Select Task Field *"
                  />
                )}
              />
            </Grid>
            {taskFieldValue &&
              <Grid item sm={4}>
                <Autocomplete
                  value={conditionValue}
                  data-testid="select-conditions"
                  onChange={(e, option) => {
                    handleConditionChange(option, setConditionValue)
                  }}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  options={conditionList || []}
                  sx={{
                    ...inputClasses.filterField,
                    // width: "277px",
                    // margin: "10px 0 0 10px",
                    background: "white",
                  }}
                  // disabled={!taskFieldValue}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "yellow", ...textClasses.normalText }}
                      {...params}
                      label="Condition  *"
                    />
                  )}
                />
              </Grid>
            }

            <Grid item sm={4} data-testid="input-field">
              {
                taskFieldValue?.type == "number" ?
                  <NumberField
                    component={numberFieldComponent}
                    componentValues={fieldConditionalValue}
                    disabled={false}
                    handleChange={handleChangeFieldCondtionalValue}
                    errors={{}} // Pass errors if required
                  />
                  : taskFieldValue?.type === "string" ? (
                    <InputField
                      component={stringFieldComponent}
                      componentValues={fieldConditionalValue} // Pass the shared state
                      disabled={false} // Field remains enabled
                      handleChange={handleChangeFieldCondtionalValue}
                      errors={{}} // Pass errors if required
                    />
                  ) : taskFieldValue?.type === "date" ? (
                    <DateField
                      component={dateFieldComponent}
                      componentValues={fieldConditionalValue} // Pass the shared state
                      disabled={false} // Field remains enabled
                      handleChange={handleChangeFieldCondtionalValue}
                      errors={{}} // Pass errors if required
                    />
                  ) : null

              }
            </Grid>

          </Grid>
          <Box
            sx={{
              margin: "0px 0 10px 35px",
            }}
          >
            {!displayAddAnAction &&
              taskFieldValue &&
              conditionValue &&
              fieldConditionalValue &&
              // fieldValueCondition &&
              (
                <CreateAutomationCard
                  text="THEN: Add an action"
                  subText="Actions will execute when your rule runs successfully."
                  icon={blackicon}
                  width="281px"
                  handleJobCreate={() => {
                    setDisplayAddAnAction(true);
                  }}
                />
              )}
          </Box>

          {displayAddAnAction && !displayCreateJob && !displayEmailSection && (
            <Box
              sx={{
                borderRadius: "8px",
                margin: "10px 10px 0 35px",
              }}
            >
              <Grid
                container
                spacing={0}
                sx={{
                  margin: "18px 0 0 10px",
                }}
              >
                <img src={blackicon} />
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: "#000000",
                    marginLeft: "8px",
                  }}
                >
                  THEN: Add an action
                </Typography>
              </Grid>

              <Typography
                sx={{
                  ...textClasses.t12n,
                  margin: "0 0 0 38px",
                }}
              >
                Actions will execute when your condition met successfully.
              </Typography>

              <Grid
                container
                spacing={0}
                sx={{
                  margin: "18px 0 10px 30px",
                  gap: "20px",
                }}
              >
                {(["jobCreationProcess", "jobCompletionProcess"]).includes(selectJobAutomation?.value) &&
                  <CreateAutomationCard
                    text="Create A New Job "
                    subText="Create a new Job if above mentioned condition met. "
                    icon={plusCreateIcon}
                    width="281px"
                    testId="create-job"
                    handleJobCreate={() => {
                      setDisplayCreateJob(true);
                    }}
                  />
                }
                <CreateAutomationCard
                  text="Send notification and emails to users "
                  subText="send email to if above mentioned condition met. "
                  width="281px"
                  icon={sendnotifactionicon}
                  handleJobCreate={() => {
                    setDisplayEmailSection(true);
                  }}
                />
              </Grid>
            </Box>
          )}
          {displayCreateJob && (
            <>
              {" "}
              <Grid
                container
                spacing={0}
                sx={{
                  margin: "10px 0 0 10px",
                }}
              >
                <img src={plusCreateIcon} />
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: "#000000",
                    marginLeft: "8px",
                  }}
                >
                  Create A New Job{" "}
                </Typography>
              </Grid>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  margin: "0 0 0 38px",
                }}
              >
                Create a new Job if above mentioned condition met.{" "}
              </Typography>
              <Box
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Autocomplete
                  value={selectJobType}
                  onChange={(e, option) => {
                    setSelectJobType(option);
                  }}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  options={nextJobTypeList || []}
                  sx={{
                    ...inputClasses.filterField,
                    width: "380px",
                    margin: "10px 0 0 35px",
                    background: "white",
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "yellow", ...textClasses.normalText }}
                      {...params}
                      label="Select Job Type *"
                    />
                  )}
                />
              </Box>
            </>
          )}
          {
            displayEmailSection &&
            <Grid item sm={12}>
              <Box sx={{
                mt: 1, m: 0,
                ml: 2,
              }}>
                <TextField
                  data-testid="enter-email"
                  sx={{
                    ...selectStylesOverride,
                    ...textClasses.t12n,
                    width: "284px",
                  }}
                  size="small"
                  label="Enter email(s) to sent form link"
                  variant="outlined"
                  onChange={(e) => {
                    setAddEmail(e.target.value);
                  }}
                  value={addEmail}
                />

                <Button
                  autoFocus
                  variant="contained"
                  spacing={1}
                  data-testid="add-btn"
                  // disabled={!addEmail}
                  onClick={() => handleEmail()}
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    // position:"relative",
                    // left:"-10px"
                    margin: "-1px 0px 0px -15px",
                    borderTopLeftRadius: "0",
                    borderBottomLeftRadius: "0"
                  }}
                >
                  <img
                    style={{
                      marginRight: "10px",
                    }}
                    src={AddIcon}
                  />
                  Add
                </Button>
                {visibleEmail?.length > 0 ?
                  <Grid container xs={12} sx={{ mt: 1 }} spacing={1}>
                    <CustomChip
                      data={visibleEmail || []}
                      makeDisabled={false}
                      handleDelete={(eleIndex, elem) => setVisibleEmail(visibleEmail.filter((dl, index) => index !== eleIndex))}
                      readLabelKey={"$self"}
                    />
                  </Grid>
                  : ""}
              </Box>
            </Grid>
          }
        </Box>
      </>
    </>
  );
};
export default AddConditions;
