import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericSingleRangeComponent from "../../Components/Generic/GenericSingleRangeComponent";
import {
  Grid, Tooltip,
  TextField,
  Typography,
  Box,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  ListItemText,
  FormControlLabel,
  CardActions,
  IconButton,
  Autocomplete,
  TextareaAutosize,
  Paper,
  TableContainer,
  ListSubheader,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Button,
  Table,
  MenuItem,
  FormControl,
  ButtonGroup,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Add, Delete, Remove, Check as CheckIcon, CoPresentOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  createJob,
  getAllJobTypes,
  getAllUsers,
  getGridDetail,
  generateDispatchOrderNo,
  getCompany,
  getMappedArea,
  getAllTemplates,
  getJobProcessConfig,
  getSalesOrderitemList,
  getInvForJobCreation
} from "../../../redux/actions";
import LocationWiseCategory from "./LocationWiseCategory";
import theme, {
  textClasses,
  buttonClasses,
  inputClasses,
} from "../../../static/styles/theme";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  // getItemDetail,
  // getPackageDetail,
  // getPackageItemDetail,
  getAllInventory,
} from "../../../redux/actions/Inventory";
import { selectStylesOverride } from "../../../utils/util";
import TypeAndSearch from "../../Components/TypeAndSearch";
import {
  showToast,
  removeLeadingChars,
  dateTimeFormater,
  capitalizeSentence,
  compareTwoArrayOrObject,
  deepCopy_Object,
  truncateNumber
} from "../../../services/functions";
import {
  StockTranferState,
  templateState,
  initialCountFor_SPI,
  ShipmentFilter,
  getLabelKeyForJobConfig,
  reasonComponent,
  pickingOptions
} from "./Constant";
import {
  gateINJobValidation,
  stockJobValidation,
  invAuditJobValidation,
  loction_validation,
  gateOutJobValidation,
} from "./validaterFunction";
import DynamicFormRender from "../../Components/DynamicFormRender";
import SelectSalesItems from "./SelectSalesItems"
import GenericLoader from "../../Components/Generic/GenericLoader";
import PackageObjectsSlider from "./PackageObjectsSlider";
import GenericCollapsableItemList from "../../Components/Generic/GenericCollapsableItemList";
import CreatePackageDialog from "./CreatePackageDialog";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getShortText } from "../../../services/functions";
import GenericDropdown from "../../Components/Generic/GenericDropdown";


const statusConfig = {
  pending: "Pending",
  partialGateIn: "Partial Gate In",
  gateIn: "Gate In",
  partialGateOut: "Partial Gate Out",
  gateOut: "Gate Out",
};

let initialJobTypeSelected={
  isTypeGateIn: false,
  isTypeGateOut: false,
  isTypeQCInward: false,
  isTypeQCInhouse: false,
  isTypeInventoryCheck: false,
  isTypePickingAndPackaging: false,
  isTypePicking: false,
  isChangeLocation: false,
  isTypeInventoryAudit: false
}

export default function JobCreate() {
  const { state } = useLocation();
  const { sortingMethod, salesDetail, salesWarehouse, selectJobTypeId = null, pickingMethod } = state ? state : {}
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const job_types = useSelector((state) => state?.job?.job_types?.data || []);
  const { jobProcessConfig } = useSelector((state) => state?.job); //{jobCreationCategory=[]}
  // const { AllUserData } = useSelector((state) => state.users);
  const { AllUserWithoutFilter } = useSelector((state) => state.users);
  const { allWFDetail = [] } = useSelector((state) => state.superadmin);
  const jobCreationData = useSelector((state) => state?.job?.jobCreation);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoader, setIsLoader] = useState("");
  const [jobType, setJobType] = useState({ label: "", value: "", types: [] });
  const [warehouse, setWarehouse] = useState({
    label: "",
    value: "",
    single: false,
  });
  const [floor, setFloor] = useState({ label: "", value: "" });
  const [AllUserData, setAllUserData] = useState([]);
  const [cell, setCell] = useState([]);
  const [category, setCategory] = useState("");
  const [scanning, setScanning] = useState("");
  const [allProductType, setAllProductType] = useState([]);
  const [inventoryData, setInventoryData] = useState({
    shipments: [],
    packages: [],
    items: [],
  });
  const [dataFilter, setDataFilter] = useState({});
  const [filterValue, setFilterValue] = useState([]);
  const [worker, setWorker] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [disableElement, setDisableElement] = useState({
    categoryDropdown: true,
    listDropDown: true,
    // scanningDropDown: true
  });
  const [remark, setRemark] = useState("");
  const [addedItems, setAddedItems] = useState([]);
  const [inventoryType, setInventoryType] = useState("package");
  const [isJobType, setIsJobType] = useState({...initialJobTypeSelected});

  const [stockTransferDetail, setStockTransferDetail] = useState({
    ...StockTranferState,
  });
  let [templateDetail, setTemplateDetail] = useState({ ...templateState });
  const [countFor_SPI, setCountFor_SPI] = useState({ ...initialCountFor_SPI });
  const [scanningInput, setScanningInput] = useState('')

  // For Picking and Packing
  const [packagingList, setPackagingList] = useState([]);
  const usePrevious = (value = "") => {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  let previousWarehouse = usePrevious(stockTransferDetail?.transferFromWarehouse?.value)
  //  useEffect(()=>{
  // 	if(jobType?.value){
  // 		dispatch(getAllUsers({userType:"worker",approved:true, jobActionId : jobType?.value}));
  // 	}
  //  },[jobType, dispatch])

  const getActionType = (action_Types = []) => {
    const aT = action_Types.map((type) => {
      let tempType = (typeof type == "string" && type) || type.actionId;
      return tempType;
    });
    return aT;
  };

  const jobTypeOptions = (allOption = null) => {
    return [
      // { label: "--select--", value: "", types: [] },
      ...(allOption || job_types || []).map((_) => ({
        label: capitalizeSentence(_.name || ""),
        value: _._id,
        types: getActionType(_?.actionTypes || []),
      })),
    ]
  };
  const warehouseOptions = (allOption = null) => {
    return [
      // { label: "--select--", value: "", },
      ...(allOption || allWFDetail || [])
        .filter((el) => el?.type == "warehouse")
        .map((_) => ({
          label: _.name,
          value: _._id,
          single: _.single || false,
        })),
    ]
  }

  const floorOptions = (warehouse_Id, floorOption = null) => {
    let options = []; //{ label: "--select--", value: "", }
    for (let _ of (floorOption || allWFDetail || [])) {
      if (
        warehouse_Id &&
        _?.type == "floor" &&
        _?.warehouseId == warehouse_Id
      ) {
        options.push({
          label: _.name,
          value: _._id,
        });
      }
    }

    return options;
  };

  
  const categoryOptions = (allOption = null, actionTypes = null) => {
    return (allOption || jobProcessConfig?.jobCreationCategory || []).filter((category) => {
      return (
        category.type === "all" || compareTwoArrayOrObject(actionTypes || jobType?.types || [], category?.type || [], "one")
      );
    }) || [];

  };

  const handleJobTypeSelect = (e, option) => {
    clearStates(null,["worker"]);
    if (option?.value) {
      let templateInfo = { visible: false, templateType: "", title: "" };

      let isTypeGateIn = option.types.includes("gateIn");
      let isChangeLocation = option.types.includes("changeLocation");
      let isTypeInventoryAudit = option.types.includes("inventoryAudit")
      let isTypeGateOut = option.types.includes("sendToUser");
      let isTypeStockTransfer = option.types.includes("stockTransfer");
      let isTypeQCInhouse =option.types.includes("inHouseQualityCheck")
      let isTypeQCInward =option.types.includes("inwardQualityCheck")
      let isTypeInventoryCheck =option.types.includes("inventoryCheck")
      let isTypePickingAndPackaging = option.types.includes("pickingAndPackaging");
      let isTypePicking = option.types.includes("picking");

      if (isTypeGateOut || isTypePicking) {
        templateInfo = {
          visible: true,
          templateType: "outwardDataEntry",
          title: "Dispatch",
        };
        // This we can use in future if we use api of scanningOptions
        if (scanningOptions()?.length == 1) {
          handleScanningSelect({}, scanningOptions()?.at(0) || {})
        }
      } else if (isTypeQCInhouse || isTypeQCInward) {
        templateInfo = {
          visible: true,
          templateType: "qualityCheck",
          title: "Quality Check",
          subTitle: "This form to be filled at the time of job completion",
        };
      } else if (isTypeInventoryCheck) {
        templateInfo = {
          visible: true,
          templateType: "qualityCheck",
          title: "Inventory Check",
          subTitle: "This form to be filled at the time of job completion",
        };
      } else if (isTypeStockTransfer) {
        templateInfo = {
          visible: true,
          templateType: "stockTransfer",
          title: "Stock Transfer",
        };
      } else if (isTypePickingAndPackaging) {
        templateInfo = {
          visible: true,
          templateType: "outwardDataEntry",
          title: "Dispatch",
        };
      }
      setTemplateDetail({ ...templateState, ...templateInfo });
      let tempJobTypes={
        isTypeGateIn,
        isTypeGateOut,
        isTypeStockTransfer,
        isTypeQCInward,isTypeQCInhouse,
        isTypeInventoryCheck,
        isChangeLocation,
        isTypePickingAndPackaging,
        isTypePicking,
        isTypeInventoryAudit
      }
      setIsJobType(tempJobTypes);
      setJobType(option);
      if (!isTypeStockTransfer) {
        let filteredWarehouses = warehouseOptions();
        if (filteredWarehouses?.length === 1) {
          jobWarehouseSelect({},filteredWarehouses?.at(0)||{},option.types)
        }
      }

      dispatch(
        getAllUsers({
          userType: "worker",
          approved: true,
          jobActionId: option?.types || [],
        },({data})=>{  
          setAllUserData(data || [])
          if( data?.length===1){
            handleWorkerSelect({},{
              label: data?.at(0)?.name,
              value: data?.at(0)?._id,
            })
          }
        })
      );
      if(option?.types?.includes("stockTransfer")){
        dispatch(
          getAllUsers({
            userType: "worker",
            approved: true,
            withoutFilterStockTransfer: true,
          })
        );
      }
    }


  };

  const jobWarehouseSelect = (e, option,jobTypes=null) => {
    // ////(option,'option');
    let resetState = [
      "inventory",
      "floor",
      "category",
      "shipmentId",
      "remark",
      "addedItems",
      "stockTransfer",
      "dataFilter",
      "inventoryType",
      "cell",
    ]
    if (option?.value) {
      setWarehouse(option);
      if ((jobTypes || jobType?.types)?.some(dl => ["inwardQualityCheck", "gateIn"]?.includes(dl))) {
        // Auto-select logic for floor
        let filteredFloors = floorOptions(option?.value)
        if (filteredFloors?.length === 1) {
          jobFloorSelect({}, filteredFloors?.at(0) || {}, jobTypes)
          resetState.splice(1, 1)
        }
      }
      else {
      }
    }
    else {
      setWarehouse({ label: "", value: "", single: false });
    }
    

    clearStates(resetState);
  };

  const jobFloorSelect = (e, option,jobTypes) => {
    // ////({ option });
    let resetState=[
      "inventory",
      "category",
      "shipmentId",
      "remark",
      "addedItems",
      "dataFilter",
      "inventoryType",
    ]
    if (option?.value) {
      setFloor(option);
      if(categoryOptions(null,jobTypes)?.length===1){
        handleCategorySelect({},categoryOptions(null,jobTypes)?.at(0)||{})
        resetState.splice(1,1)
      }
    } else {
      setFloor({ label: "", value: "" });
    }

    clearStates(resetState);
  };

  const handleFloorForLocationWise = (e, option) => {
    if (option?.value) {
      setFloor(option);
    } else {
      setFloor({ label: "", value: "" });
    }
  };

  let allJobType = [
    { label: "Shipment ID", value: "Shipment ID", type: "all" },
    { label: "Location", value: "Location", type: "inventoryCheck" },
  ];


  const scanningOptions = () => {
    let arr = []
    if (jobProcessConfig?.jobScanningTypeOption?.length > 0) {
      if (["batchWise", "locationWise"].includes(pickingMethod?.value)) {
      arr = jobProcessConfig?.jobScanningTypeOption?.filter(dl => dl?.value !== "anyInventory") || []
    }
    else{
      arr = jobProcessConfig?.jobScanningTypeOption || []
      }
    }

    // arr.push({
    //   value: "restricted",
    //   label: "Restricted"

    // })
    // arr.push({
    //   value: "anyInventory",
    //   label: "Any Inventory"

    // })
    return arr;
  }
  const jobCompletionOptions=[
    {value: "scanSameTypeSingleQR",label: "Scan Same Type Single QR"},
    {value: "scanAllIndividualQR",label: "Scan All individual QR"},
  ];

  const handleCategorySelect = (e, option) => {
    clearStates([
      "inventory",
      "shipmentId",
      "remark",
      "addedItems",
      "stockTransfer",
      "dataFilter",
      "inventoryType",
      "cell",
    ]);

    setAllProductType([]);
    if (option?.value) {
      const { value } = option;
      setCategory(value);
      if (value.match(/shipment[_-\s]*id/i)) {
        //shipment
        if (
          isJobType?.isTypeGateIn ||
          isJobType?.isTypeGateOut ||
          isJobType?.isTypeQCInhouse ||
          isJobType?.isTypeQCInward ||
          isJobType?.isTypePickingAndPackaging ||
          isJobType?.isTypePicking
        ) {
          fetchShipment({});
        }
      }
      // else if (value.match(/location[_-\s]*/i)) {
      //   let body = { type: "block", status: ["partial", "full", "available"] };
      //   // let body = {type:["block","rack"], status:["partial","full","available"] };
      //   //mapping Area
      //   let wf = {
      //     floorId: floor?.value || "",
      //     warehouseId: warehouse?.value || "",
      //   };
      //   dispatch(getGridDetail({ ...body, ...wf, fetch_for:"pickUp", }));
      // }
      else if (value.match(/package[_-\s]*name/i)) {
        if (isJobType?.isTypeGateIn || isJobType?.isTypeQCInhouse || isJobType?.isTypeQCInward) {
          let wf = {
            floorId: floor?.value || "",
            warehouseId: warehouse?.value || "",
          };

          dispatch(
            getMappedArea({ ...wf, fetchDistinct: true, allAreaNameOnly: true }, ({ data }) => {
              setAllProductType(data?.length > 0 ? data : [""]);
            }
            )
          );
        }
        setDisableElement({ ...disableElement });
      }
    }
    else {
      setCategory("");
    }


  };

  const handleScanningSelect = (e, option) => {
    setScanningInput(option)
    if (option?.value) {
      const { value } = option;
      setScanning(value);
    }
    else {
      setScanning("");
    }
  }
const filterEnable=()=>{
  return (isJobType?.isTypeGateOut || isJobType?.isTypePicking || isJobType.isTypeStockTransfer || isJobType.isTypeQCInhouse
  || false)
}

  const fetchShipment = ({
    warehouseId,
    data_Filter = {},
    filter_Value = [],
  }) => {
    setInventoryData({ shipments: [], packages: [], items: [] });
    let body = { dataFetchType: "shipmentWise", projection_for: "job" };

    if (isJobType?.isTypeGateIn || isJobType?.isTypeQCInward) {
      body = { ...body, status: ["pending", "partialGateIn"] };
    } else {
      body = { ...body, status: ["partialGateIn", "gateIn", "partialGateOut"] };
    }

    let wf = {
      floorId: floor?.value || "",
      warehouseId: warehouseId || warehouse?.value || "",
    };

    if (isJobType?.isTypeGateIn || isJobType?.isTypeQCInward) {
      body["fetch_for"] = "beforeEntry";
      dispatch(
        getMappedArea(
          { ...wf, fetchDistinct: true, allAreaNameOnly: true },
          ({ data }) => {
            body["mappingArea"] = data;
            dispatch(
              getAllInventory(body, ({ success = false, data = [] }) => {
                setInventoryData({ shipments: data, packages: [], items: [] });
              })
            );
            setAllProductType(data?.length > 0 ? data : [""]);
          }
        )
      );
    }
    else {
      body["fetch_for"] = "afterEntry";
      body = { ...body, ...wf };

      if (filterEnable() && data_Filter?.value) {
        body = { ...body, filterType: data_Filter?.value };

        if (data_Filter?.value == "date" || data_Filter?.value == "dateRange") {
          let isTrue = false;
          if (data_Filter?.value == "date" && filter_Value?.length > 0) {
            isTrue = true;
          }
          if (data_Filter?.value == "dateRange" && filter_Value?.length == 2) {
            isTrue = true;
          }
          if (isTrue) {
            body["filterValue"] = filter_Value;
          } else {
            delete body["filterType"];
          }
        }
      }

      if (warehouseId) {
        body["warehouseId"] = warehouseId;
      }
      dispatch(
        getAllInventory(body, ({ success = false, data = [] }) => {
          setInventoryData({ shipments: data, packages: [], items: [] });
        })
      );
      setAllProductType([]);
    }

    // ////("hhhhhh,dfhgeriuhgveriuvhgfr");
    setCountFor_SPI({ ...initialCountFor_SPI });
  };

  const workerOptions = [
    // { label: "--select--", value: "" },
    ...(AllUserData|| []).map((_) => {
      return {
        label: _.name,
        value: _._id,
      };
    }),
  ];

  const workersWithoutFilterOptions = [
    // { label: "--select--", value: "" },
    ...(AllUserWithoutFilter?.data || []).map((_) => {
      return {
        label: _.name,
        value: _._id,
      };
    }),
  ];

  const handleWorkerSelect = (e, option) => {
    if (option) {
      // ////(option);
      const { value, label } = option; //e.target;
      setWorker(value);
    } else {
      setWorker("");
    }
  };

  const handleRemarkChange = (e) => {
    const { name, value } = e.target;
    setRemark(value);
  };

  const clearStates = (key = null,skip=[]) => {
    /****all options */
    let allOptions = key || [
      "inventory",
      "dataFilter",
      "warehoue",
      "floor",
      "jobtype",
      "worker",
      "category",
      "shipmentId",
      "remark",
      "addedItems",
      "disableElement",
      "isJobType",
      "templateState",
      "stockTransfer",
      "inventoryType",
      "cell"
    ];

    // ////("clearStates",allOptions);
    if (allOptions.includes("jobtype")) {
      setJobType({ label: "", value: "", types: [] });
      setAllProductType([]);
    }
    if (allOptions.includes("filterValue")) {
      setFilterValue([]);
    }
    if (allOptions.includes("cell")) {
      setCell([]);
    }
    if (!skip.includes("worker") && allOptions.includes("worker")) {
      setWorker("");
    }
    if (allOptions.includes("inventoryType")) {
      setInventoryType("package");
    }
    if (allOptions.includes("category")) {
      setCategory("");
    }
    if (allOptions.includes("remark")) {
      setRemark("");
    }
    if (allOptions.includes("addedItems")) {
      setAddedItems([]);
      setPackagingList([]);
    }
    if (allOptions.includes("warehoue")) {
      setWarehouse({ label: "", value: "", single: false });
      setAllProductType([]);
    }
    if (allOptions.includes("floor")) {
      setFloor({ label: "", value: "" });
      setAllProductType([]);
    }
    if (allOptions.includes("disableElement")) {
      setDisableElement({ categoryDropdown: true, listDropDown: true });
    }
    if (allOptions.includes("isJobType")) {
      setIsJobType({...initialJobTypeSelected});
    }
    if (allOptions.includes("templateState")) {
      setTemplateDetail({ ...templateState });
    }
    if (allOptions.includes("stockTransfer")) {
      setStockTransferDetail({ ...StockTranferState });
    }
    if (allOptions.includes("dataFilter")) {
      setDataFilter({});
    }
    if (allOptions.includes("inventory")) {
      setInventoryData({ shipments: [], packages: [], items: [] });
    }
  };

  const renderTableFooter = () => {
    let S = [],
      P = [],
      I = [];

    for (let ele of addedItems) {
      if (ele?.shipmentId && !S.includes(ele.shipmentId)) {
        S.push(ele.shipmentId);
      }
      if (ele?.packageId && !P.includes(ele.packageId)) {
        P.push(ele.packageId);
      }
      if (ele?.itemId && !I.includes(ele.itemId)) {
        I.push(ele.itemId);
      }
    }

    if (S.length == 0 && P.length == 0 && I.length == 0) return;
    return (
      <Grid container sx={{ flexDirection: "row", mt: 2 }}>
        <Typography sx={{ fontWeight: "800", fontSize: ".9rem", mr: 4 }}>
          Total Count(s) :
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Unique Shipments(s) :{S.length}
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Unique Package(s) : {P.length}
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Item(s) : {I.length}
        </Typography>
      </Grid>
    );
  };

 


  const disableCategoryAndList = () => {
    let listDropDown = true, categoryDropdown = true;
    let isTypeGateIn = isJobType?.isTypeGateIn || false
    let isTypeGateOut = isJobType?.isTypeGateOut || false
    let isTypeQCInward = isJobType?.isTypeQCInward || false
    let isTypeQCInhouse = isJobType?.isTypeQCInhouse || false
    let isTypePickingAndPackaging = isJobType?.isTypePickingAndPackaging || false
    let isTypePicking = isJobType?.isTypePicking || false
    let isChangeLocation = isJobType?.isChangeLocation || false;
    let isTypeInventoryAudit = isJobType?.isTypeInventoryAudit || false;

    if (jobType?.types?.length > 0) {
      if (isTypeGateIn || isJobType?.isTypeInventoryCheck || isTypeQCInward) {
        //if gate or gate Out
        if (warehouse?.value && floor?.value) {
          categoryDropdown = false;
        }
        if (category) {
          listDropDown = false;
        }
      }
      else if (isTypeQCInhouse || isTypeGateOut || isTypePickingAndPackaging || isChangeLocation || isTypePicking || isTypeInventoryAudit) {
        //if gate or gate Out
        if (warehouse?.value) {
          categoryDropdown = false;
        }
        if (category) {
          listDropDown = false;
        }
      }
      else if (jobType.types.includes("stockTransfer")) {
        categoryDropdown = false;
        // ////("ffff");
        if (stockTransferDetail?.transferFromWarehouse?.value) {
          if (category == "shipmentId" && stockTransferDetail?.transferFromWarehouse?.value != previousWarehouse) {
            setInventoryData({ shipments: [], packages: [], items: [] });
            fetchShipment({
              warehouseId: stockTransferDetail.transferFromWarehouse.value,
              data_Filter: dataFilter || {}, filter_Value: filterValue || [],
            });
          }
          listDropDown = false;
        }
      }
    }
    else {
      listDropDown = true;
      categoryDropdown = true;
    }

    setDisableElement({ listDropDown, categoryDropdown });
  };

  const handleFilterSelect = (e, option) => {
    setInventoryData({ shipments: [], packages: [], items: [] });
    let temp = {};
    if (option?.value) {
      temp = { ...option };
    }

    clearStates(["filterValue", "inventory", "addedItems"]);
    if (category == "shipmentId" && (isJobType?.isTypeStockTransfer ? stockTransferDetail?.transferFromWarehouse?.value:true)) {
      fetchShipment({ data_Filter: temp, filter_Value: [],warehouseId: stockTransferDetail?.transferFromWarehouse?.value || "", });
    }
    setDataFilter(temp);
  };

  const handleClearAll = () => {
    
    clearStates();
    if(state?.jobType){
      processFromInventory(job_types, allWFDetail, jobProcessConfig?.jobCreationCategory)
    }
    else if(salesDetail && salesDetail.length > 0){
      salesOrderProcess(job_types, allWFDetail, jobProcessConfig?.jobCreationCategory)
    }
  };

  const submitData = (clickType = "submit") => {
    let req_payload = {
      remark: remark || "",
      userId: worker || "",
      jobData: [],
      location: [],
      jobCreationCategory: category,
    },
      floorId = "",
      warehouseId = "";
    //check jobType
    if (!jobType?.types?.length) {
      showToast("Please select Job Name!", true);
      return;
    }

    req_payload["jobTypeId"] = jobType.value || "";

    //category
    if (!category) {
      showToast("Please select category!", true);
      return;
    }
    if ((isJobType?.isTypeQCInhouse || isJobType?.isTypeQCInward)) {
      if(!scanning){
        showToast("Please select quality check method!", true);
        return;
      }

      req_payload["jobCompletionType"] = scanning || "";
    }

    let validate_for_cell = false;

    // ////("templateDetail",templateDetail);

    for (let action of jobType.types) {
      let actionId = typeof action == "string" ? action : action.actionId;

      req_payload["jobData"].push({ jobType: actionId })

      //validateion for gate in job creation
      if (["gateIn", "inwardQualityCheck", "inventoryCheck"].includes(actionId)) {
        const { success, message, ...rest } = gateINJobValidation(
          actionId,
          req_payload,
          floor,
          warehouse
        );
        if (!success) {
          showToast(message, true);
          return;
        }

        floorId = rest.floorId;
        warehouseId = rest.warehouseId;
        validate_for_cell = actionId == "inventoryCheck";
      }
      if (actionId == "stockTransfer") {
        const { success, message, ...rest } = stockJobValidation(
          "stockTransfer",
          req_payload,
          stockTransferDetail
        );

        if (!success) {
          showToast(message, true);
          return;
        }

        req_payload["jobExtraDetail"] = rest.jobExtraDetail || {};
        floorId = rest.floorId || "";
        warehouseId = rest.warehouseId || "";
      }
      if (actionId == "inventoryAudit") {
        const { success, message, ...rest } = invAuditJobValidation(
          "inventoryAudit",
          req_payload,
          warehouse,
          stockTransferDetail
        );

        if (!success) {
          showToast(message, true);
          return;
        }

        req_payload["jobExtraDetail"] = rest.jobExtraDetail || {};
        warehouseId = rest.warehouseId || "";
      }
      if(["sendToUser", "picking"].includes(actionId)) {
        req_payload["pickingMethod"] = pickingMethod?.value
      }
      if (["sendToUser", "picking","inHouseQualityCheck"].includes(actionId)) {
        const { success, message, ...rest } = gateOutJobValidation(
          actionId,
          req_payload,
          warehouse
        );

        if (!success) {
          showToast(message, true);
          return;
        }
        warehouseId = rest.warehouseId || "";

        if (dataFilter.value) {
          req_payload["jobProcessFilter"] = {
            filterType: dataFilter?.value,
          };
          if (
            dataFilter?.value === "date" ||
            dataFilter?.value === "dateRange"
          ) {
            if (filterValue?.length) {
              const isValidDate = filterValue?.every((date) => date?.isValid());
              if (isValidDate) {
                req_payload["jobProcessFilter"]["filterValue"] = filterValue;
              } else {
                showToast("Please Add Valid Date", true);
                return;
              }
            } else {
              showToast("Please Add Date", true);
              return;
            }
          }
        }
      }
      if (actionId == "pickingAndPackaging") {
        const { success, message, ...rest } = gateOutJobValidation(
          "pickingAndPackaging",
          req_payload,
          warehouse
        );
        if (!success) {
          showToast(message, true);
          return;
        }
        warehouseId = rest.warehouseId || "";
      }
    }
    if (!isJobType.isTypePickingAndPackaging) {
      //Inventory Detail
      if (!addedItems || addedItems.length == 0) {
        showToast("Please Add Packages!", true);
        return;
      }
      const { success, message, ...rest } = loction_validation(
        validate_for_cell,
        addedItems,
        "",
        req_payload,
        floorId,
        warehouseId,
        category,
        isJobType.isChangeLocation ? "changeLocation" : ""
      );
      if (!success) {
        showToast(message, true);
        return;
      }
    }
    else {
      if (!packagingList || packagingList.length == 0) {
        showToast("Please provide Inventory Detail!", true);
        return;
      }
      let locations = [];
      for (let i = 0; i < packagingList.length; i++) {
        const pkg = packagingList[i];
        for (let j = 0; j < pkg.itemDetail.length; j++) {
          const element = pkg.itemDetail[j];
          // ////("OOOLLA", element);
          locations.push({
            shipmentId: element.shipmentId ? element.shipmentId : "",
            packageId: element.packageId ? element.packageId : "",
            itemId: element.itemId ? element.itemId : "",
            count: element.count,
            warehouseId: warehouseId,
            masterPackageId: pkg.masterPackageId,
          });
        }
      }
      req_payload["location"] = locations;
    }

    if (templateDetail?.templateOption?.value) {
      let formData = watch();
      req_payload["template_Id"] = templateDetail?.templateOption?.value;
      req_payload["templateData"] = formData;
    }
    if (category == "quantityWise") {
      req_payload["jobScanningType"] = scanning
    }

    // Sales order Addition
    if (salesDetail && salesDetail.length > 0) {
      req_payload["isWithSalesOrder"] = true;
    }

    // showToast("Ready for Gate In Job creation :: Need API Integration",false)
    // ////("submitData",req_payload);
    // console.log(req_payload, "sheck req payload")
    if (jobProcessConfig?.requiredFields?.userRequired && (!worker || typeof worker != "string")) {
      showToast("Please provide user detail!", true);
      return;
    }

    setDisableSubmit(true)
    dispatch(
      createJob(req_payload, ({ success, message }) => {
        showToast(message, !success);
        setTimeout(() => {
          setDisableSubmit(false)
        }, 500)
        if (success) {
          clearStates();
          if (clickType == "submit") {
            setTimeout(() => {
              if (salesDetail?.length > 0) {
                history.push("/dashboard/salesOrder");
              }
              else {
                history.push("/dashboard/jobs");
              }
            }, 2000);
          }
        }
      })
    );
  };

  let salesOrderProcess = (jobtypeOption = null, warehouseOption = null, allCategory) => {
    if (salesDetail && salesDetail.length > 0) {
      if ((jobtypeOption && jobtypeOption.length > 0)) {
        let desiredType = jobTypeOptions(jobtypeOption).find(jt => {
          let condition = jt.types.includes("sendToUser")
          if (selectJobTypeId) {
            condition = jt?.value == selectJobTypeId
          }
          return condition
        });
        if (desiredType) {
          handleJobTypeSelect({}, desiredType)
          jobWarehouseSelect({}, { label: salesWarehouse?.name, value: salesWarehouse?._id, single: false })
          if (allCategory.length > 0) {
            let desiredCategory = categoryOptions(allCategory, desiredType?.types || null)?.find(cat => cat.value == "quantityWise");
            // console.log("inside all category",desiredCategory,categoryOptions(allCategory))
            if (desiredCategory) {
              handleCategorySelect({}, desiredCategory);
              handleFilterSelect({}, sortingMethod);
            }
            let desiredScanning = scanningOptions().find(sc => sc.value == "anyInventory");
            if (desiredScanning) {
              handleScanningSelect({}, desiredScanning)
            }
          }
        }
      }
    }
    setIsLoader(false)
  }

  let processFromInventory = (jobtypeOption = null, warehouseOption = null,) => {
    if (state?.jobType && job_types) {
      if (["gateIn", "dispatch"].includes(state?.jobType)) {
        let type = state?.jobType == "dispatch" ? "sendToUser" : state?.jobType
        let tempJob = jobTypeOptions(jobtypeOption)?.find(dl => dl?.types?.includes(type))
        if ((!jobType || !jobType?.value) && tempJob?.value) {
          /***
           * set job type first 
           * next warehouse selection will be happen
           * if job is gateIn then floor info will be required
           */

          handleJobTypeSelect({}, tempJob)

          let warehouse = warehouseOptions(warehouseOption).find(dl => dl.value == state?.warehouseId)
          if (warehouse) {
            jobWarehouseSelect({}, warehouse)
          }

          if (state?.jobType == "gateIn") {
            let floor = floorOptions(state?.warehouseId, warehouseOption?.filter(dl => dl.type == "floor") || null).find(dl => dl.value == state.floorId)
            if (warehouse) {
              jobFloorSelect({}, floor)
            }
          }
          setCategory("shipmentId")
          const payload = {
            jobType:
              state?.jobType == "dispatch" ? "sendToUser" : state?.jobType,
            inventoryType: state?.inventoryType,
            inventoryID: state?.inventoryID,
            warehouseId: state?.warehouseId,
            ...(state?.jobType == "gateIn" ? { floorId: state?.floorId } : {}),
          };
          dispatch(getInvForJobCreation(payload, ({ success = false, data = [] }) => {
            let formatData = []
            if (success) {
              formatData = tableDataFormater(data, state?.jobType == "dispatch" ? "sendToUser" : state?.jobType)
            }
            // console.log("formatData",formatData);
            setAddedItems(formatData)
          }))
        }
      }
    }
    setIsLoader(false)
  }

  useEffect(() => {
    disableCategoryAndList();
  }, [jobType, stockTransferDetail, warehouse, floor, category]);

  useEffect(() => {
    if (initialFetch) {
      /////addForcefully=["createDispatchJob"]
      let payload = { isForJobCreation: true }
      if (salesDetail && salesDetail.length > 0 || state?.jobType) {
        if (salesDetail && salesDetail.length > 0) {
          payload["addForcefully"] = ["createDispatchJob", "createPickingJob"]
        }
        setIsLoader(true)
      }


      dispatch(getJobProcessConfig({ approved: true }, (processOut) => {
      }));
      dispatch(getAllJobTypes(payload, (jobOut) => {
      }));
      dispatch(getCompany({ type: ["warehouse", "floor"], status: "active" }, (warehouseFloorData) => {
    
        // // console.log("ddd",processOut?.data?.jobCreationCategory,jobOut?.data,warehoueOut?.data)
        // if (state?.jobType) {
        //   console.log("inside inv job")
        //   processFromInventory(jobOut?.data || null, warehoueOut?.data || null, processOut?.data?.jobCreationCategory || null)
        // }
        // else if (salesDetail && salesDetail.length > 0) {
        //   // console.log("inside sales job")
        //   salesOrderProcess(jobOut?.data || null, warehoueOut?.data || null, processOut?.data?.jobCreationCategory || null)
        // }
      }));
    }
    if (initialFetch) {
      setInitialFetch(false);
    }
  }, []);

  useEffect(() => {
    if (!initialFetch) {
      /////addForcefully=["createDispatchJob"]
      let cond = salesDetail && salesDetail.length > 0 || state?.jobType || false
      if (cond && allWFDetail?.length > 0 && job_types?.length && jobProcessConfig?.jobCreationCategory?.length > 0) {
        if (state?.jobType) {
          // console.log("inside inv job")
          processFromInventory(job_types, allWFDetail, jobProcessConfig?.jobCreationCategory)
        }
        else if (salesDetail && salesDetail.length > 0) {
          // console.log("inside sales job")
          salesOrderProcess(job_types, allWFDetail, jobProcessConfig?.jobCreationCategory)
        }
      }
    }

  }, [job_types, allWFDetail, jobProcessConfig]);
  // console.log("ppppp",category,jobType);


  let tableDataFormater = (data = [], type = "sendToUser") => {
    return data.map((dl) => {
      let obj = {
        shipmentId: dl?.shipmentId,
        packageId: dl?.packageId,
        SKU_Number: dl?.SKU_Number,
        packageName: dl?.packageName,
        itemId: dl?.itemId,
        mappingArea: dl?.mappingArea,
        isPackageExist: dl?.packageId ? true : false,
        isItemExist: dl?.itemId ? true : false,
        count: Number(dl?.count || 0),
        isCountOnly: true,
        gateInTime: dl?.gateInTime || "",
      }

      if (type == "sendToUser") {
        if (dl.isItemExist) {
          obj["balance_I_Count"] = Number(dl?.count || 0)
        }
        else if (dl.isPackageExist) {
          obj["balance_P_Count"] = Number(dl?.count || 0)
        }
        else {
          obj["balance_S_Count"] = Number(dl?.count || 0)
        }
      }
      else {
        obj["availableCount"] = Number(dl?.count || 0)
      }
      // ,
      // balance_S_Count,
      // balance_I_Count:dl?.count,

      // all_S_Count,
      // gateIn_S_Count,
      // all_P_Count,
      // gateIn_P_Count,
      // all_I_Count,
      // gateIn_I_Count,

      return obj
    })
  }
  // useEffect(() => {
  //   console.log("All Sales Order", allSaleOrderItems);
  //   if(salesDetail && allSaleOrderItems && allSaleOrderItems.length > 0){
  //     let newItems = allSaleOrderItems.map((it) => {
  //       let res = {
  //         "SKU_ID": it.product_ID,
  //         "count": it.count,
  //         "warehouseId": "",
  //         "inventoryType": it.dataEntryType,
  //         "floorId": "",
  //         "availableCount": it.count,
  //         "resetField": false,
  //         "Sales_Id": it.Sales_Id
  //       }
  //       return res
  //     })
  //     setAddedItems(newItems)
  //   }
  // }, [allSaleOrderItems])

  const handleDateChange = useCallback(
    (date, type) => {
      setInventoryData({ shipments: [], packages: [], items: [] });

      let tem_data = [];
      if (!date.isValid()) {
        showToast(`Please select a valid date`, true);
      }
      if (type === "date") {
        tem_data = [date];
        // setFilterValue([date])
      } else if (type === "from") {
        if (filterValue?.length === 2) {
          if (date?.isAfter(filterValue[1])) {
            showToast("Start Date must be smaller than End Date", true);
            tem_data = [undefined, filterValue[1] || undefined];
            // setFilterValue(prev => [undefined, ...prev.slice(1)])
          } else {
            tem_data = [date, filterValue[1] || undefined];
            // setFilterValue(prev => [date, ...prev.slice(1)]);
          }
        } else {
          tem_data = [date, filterValue[1] || undefined];
          // setFilterValue(prev => [date, ...prev.slice(1)]);
        }
      } else if (type === "to") {
        let tempEndDate = date
        tempEndDate = dayjs(tempEndDate).hour(23).minute(59).second(59).millisecond(999)
        if (filterValue?.length) {
          if (date?.isBefore(filterValue[0])) {
            showToast("End Date must be Greater than Start Date", true);
            tem_data = [filterValue[0] || undefined, undefined];
            // setFilterValue(prev => [prev[0], undefined])
          } else {
            tem_data = [filterValue[0] || undefined, tempEndDate];
            // setFilterValue(prev => [prev[0], date]);
          }
        } else {
          tem_data = [undefined, tempEndDate];
          // setFilterValue(prev => [undefined, date]);
        }
      }

      setFilterValue(tem_data);

      if (category == "shipmentId" && (isJobType?.isTypeStockTransfer ? stockTransferDetail?.transferFromWarehouse?.value:true)) {
        fetchShipment({ data_Filter: dataFilter, filter_Value: tem_data,warehouseId: stockTransferDetail?.transferFromWarehouse?.value, });
      }
    },
    [filterValue]
  );

  const renderActionElement = (action) => {
    let status = []
    // ////("jhjhhhhhhhdfvegvgtbt>>>>renderActionElement");
    if (["gateIn", "inwardQualityCheck"].includes(action)) {
      status = ["pending", "partialGateIn"];
    }
    else {
      status = ["partialGateIn", "gateIn", "partialGateOut"];
    }

    if (["inventoryCheck"].includes(action)) {
      return (
        <>
          <InventoryCheckComponent
            listDropDown={disableElement.listDropDown}
            addedItems={addedItems}
            setAddedItems={setAddedItems}
            status={["partial", "full", "available"]}
            warehouseId={warehouse?.value || ""}
            floorId={floor?.value || ""}
            countFor_SPI={countFor_SPI}
            setCountFor_SPI={setCountFor_SPI}
            isTypeInventoryCheck={isJobType?.isTypeInventoryCheck}
            jobCreationData={jobCreationData}
          >
          </InventoryCheckComponent>
        </>
      );
    }
    //  else if (["stockTransfer"].includes(action)) {
    //   children = <>
    //     <StockTranferElement
    //       allWarehouse={warehouseOptions()}
    //       allUser={workersWithoutFilterOptions}
    //       stockTransferDetail={stockTransferDetail}
    //       setStockTransferDetail={setStockTransferDetail}
    //       floorOptions={floorOptions}
    //       jobType={jobType}
    //     />
    //   </>
      
    // } 
    else if (["sendToUser", "picking"].includes(action) && category === "quantityWise") {
      return (
        <QuantityWiseElement
          scanningInput={scanningInput}
          addedItems={addedItems}
          setAddedItems={setAddedItems}
          inventoryType={inventoryType}
          warehouseId={warehouse?.value}
          dataFilter={dataFilter}
          filterValue={filterValue}
          setInventoryType={setInventoryType}
          salesDetail={salesDetail}
        />
      );
    } else if (
      ["changeLocation", "inventoryAudit","inHouseQualityCheck"].includes(action) && ["locationWise", "skuWise"].includes(category)
    ) {
      return (
        <LocationWiseCategory
          isJobType={isJobType}
          warehouse={warehouse}
          floorOptions={floorOptions}
          jobFloorSelect={handleFloorForLocationWise}
          floor={floor}
          cell={cell}
          setCell={setCell}
          setAddedItems={setAddedItems}
          addedItems={addedItems}
          clearStates={clearStates}
          jobProcessConfig={jobProcessConfig}
          category={category}
          jobType={action}
        />
      );
    }

    else if(["shipmentId", "packageName"].includes(category))
    return (
      <>
        <InventoryComponent
          allProductType={allProductType}
          disableElement={disableElement}
          setDisableElement={setDisableElement}
          dataFilter={dataFilter}
          filterValue={filterValue}
          addedItems={addedItems}
          setAddedItems={setAddedItems}
          status={status}
          isTypeGateIn={isJobType?.isTypeGateIn}
          isTypeGateOut={isJobType?.isTypeGateOut}
          isTypePicking={isJobType?.isTypePicking}
          isTypeQCInward={isJobType?.isTypeQCInward}
          isTypeQCInhouse={isJobType?.isTypeQCInhouse}
          isTypePickingAndPackaging={isJobType?.isTypePickingAndPackaging}
          isTypeStockTransfer={isJobType?.isTypeStockTransfer}
          countFor_SPI={countFor_SPI}
          setCountFor_SPI={setCountFor_SPI}
          packagingList={packagingList}
          setPackagingList={setPackagingList}
          inventoryData={inventoryData}
          setInventoryData={setInventoryData}
          category={category}
          jobProcessConfig={jobProcessConfig}
          warehouseId={isJobType?.isTypeStockTransfer ? (stockTransferDetail?.transferFromWarehouse?.value || "") : warehouse?.value || ""}
          floorId={floor?.value || ""}
          jobCreationData={jobCreationData}
          filterEnable={filterEnable}
        >
          {action == "stockTransfer" ? (
            <StockTranferElement
              allWarehouse={warehouseOptions()}
              allUser={workersWithoutFilterOptions}
              stockTransferDetail={stockTransferDetail}
              setStockTransferDetail={setStockTransferDetail}
              floorOptions={floorOptions}
              jobType={jobType}
            />
          ) : ""}
        </InventoryComponent>
      </>
    );

    else return;
  };
  return (
    <>
      <Grid
        container
        sx={{ flexDirection: "column" }}
        data-testid="job-creation-dashboard"
      >
        <Grid item>
          <Typography
            sx={{ ...textClasses.cardTitle }}
            data-testid="job-creation-header"
          >
            Manage Warehouse Jobs
          </Typography>
          <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-jobC">
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: theme.themeOrange,
              }}
            >
              <Link
                style={{ color: theme.themeOrange, textDecoration: "none" }}
                to="/dashboard/jobs"
              >
                Manage Warehouse Jobs
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              Create Job
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Card sx={{ mt: 2, border: "1px solid grey" }}>
          <CardHeader
            title={
              <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                Create Job
              </Typography>
            }
            action={
              <>
                {!isLoader &&
                  <Box
                    sx={{
                      // marginTop: "25px",
                      padding: "0px 5px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      flexDirection: { xs: "column", sm: "row" },
                      gap: 1
                    }}>
                    <Autocomplete
                      data-testid="jobType-select"
                      id="job-type-label"
                      size="small"
                      required
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <Typography sx={{ color: theme.themeOrange }}>
                              Job Name *
                            </Typography>
                          }
                          sx={{
                            ...selectStylesOverride,
                            color: theme.themeOrange,
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box data-testid="MenuOption" component="li" {...props}>
                          {option?.label}
                        </Box>
                      )}
                      sx={{
                        width: 250,
                        // minWidth: "10em",
                        // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                        // 	borderColor: theme.themeOrange,
                        // },
                        mr: 1,

                        "& legend span": {
                          color: theme.themeOrange,
                        },
                      }}
                      options={jobTypeOptions()}
                      isOptionEqualToValue={(option, value) => {
                        return option?.label === value?.label;
                      }}
                      onChange={handleJobTypeSelect}
                      value={jobType?.label || ""}
                      disabled={salesDetail && salesDetail.length > 0}
                    />
                    {(isJobType?.isTypeGateIn ||
                      isJobType?.isTypeGateOut ||
                      isJobType?.isTypePicking ||
                      isJobType?.isTypeQCInhouse ||
                      isJobType?.isTypeQCInward ||
                      isJobType?.isChangeLocation ||
                      isJobType?.isTypeInventoryAudit ||
                      isJobType?.isTypeInventoryCheck ||
                      isJobType?.isTypePickingAndPackaging) && (
                        <Autocomplete
                          id="warehouse-label"
                          data-testid="warehouse-select"
                          size="small"
                          required
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <Typography sx={{ color: theme.themeOrange }}>
                                  Warehouse *
                                  {/* Warehouse {isJobType?.isTypeQCInward ? "" :"*"} */}
                                </Typography>
                              }
                              sx={{
                                ...selectStylesOverride,
                                color: theme.themeOrange,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.label}
                            </Box>
                          )}
                          sx={{
                            // minWidth: "10em",
                            width: 250,
                            // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                            // 	borderColor: theme.themeOrange,
                            // },
                            mr: 1,
                            "& legend span": {
                              color: theme.themeOrange,
                            },
                          }}
                          options={warehouseOptions()}
                          onChange={(e,newoption)=>jobWarehouseSelect(e,newoption)}
                          value={warehouse.label}
                          disabled={salesDetail && salesDetail.length > 0}
                        />
                      )}
                    {(isJobType?.isTypeGateIn ||
                      isJobType?.isTypeQCInward ||
                      isJobType?.isTypeInventoryCheck) && (
                        <Autocomplete
                          id="floor-label"
                          data-testid="floor-select"
                          size="small"
                          required
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <Typography sx={{ color: theme.themeOrange }}>
                                  Floor *
                                  {/* Floor {isJobType?.isTypeQCInward ? "" :"*"} */}
                                </Typography>
                              }
                              sx={{
                                ...selectStylesOverride,
                                color: theme.themeOrange,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.label}
                            </Box>
                          )}
                          sx={{
                            // minWidth: "10em",
                            width: 250,
                            // "& .Mui-focused.MuiOutlinedInput-notchedOutline": {
                            // 	borderColor: theme.themeOrange,
                            // },
                            mr: 1,
                            "& legend span": {
                              color: theme.themeOrange,
                            },
                          }}
                          options={floorOptions(warehouse?.value || "")}
                          onChange={jobFloorSelect}
                          value={floor.label}
                        />
                      )}
                  </Box>}
              </>
            }
          />
          <CardContent>
            <Card sx={{ border: "1px solid grey" }} data-testid="task-detail">
              <CardHeader
                title={
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: 700 }}
                    data-testid="task-detail-header"
                  >
                    Task Details
                  </Typography>
                }
              />
              <CardContent>

                {isLoader ?
                  <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15, pt: 10, pb: 10 }}>
                    <Grid item><GenericLoader /></Grid>
                  </Grid>
                  : <>
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          id="category-label"
                          size="small"
                          disabled={disableElement?.categoryDropdown || (salesDetail && salesDetail.length > 0)}
                          data-testid="category-select"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Select Category"
                              sx={{
                                ...selectStylesOverride,
                              }}
                            />
                          )}
                          sx={{ flexBasis: "50%", mb: 1 }}
                          options={categoryOptions()}
                          onChange={handleCategorySelect}
                          value={
                            categoryOptions()?.find((dl) => dl.value == category)
                              ?.label || ""
                          }
                        />
                      </Grid>
                      {
                       pickingMethod && pickingMethod?.value && (isJobType?.isTypePicking || isJobType?.isTypeGateOut) && salesDetail && salesDetail.length > 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                          <Autocomplete
                            id="picking-label"
                            size="small"
                            disabled={true}
                            data-testid="scanning-select"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Picking Method"
                                sx={{
                                  ...selectStylesOverride,
                                }}
                              />
                            )}
                            sx={{ flexBasis: "50%", mb: 1 }}
                            options={pickingOptions}
                            onChange={handleScanningSelect}
                            value={
                              pickingOptions?.find((dl)=> dl?.value == pickingMethod?.value)?.label || ""
                            }
                          />
                        </Grid>
                      }
                      {
                        category == "quantityWise" &&
                        <Grid item xs={12} sm={6} md={4}>
                          <Autocomplete
                            id="scanning-label"
                            size="small"
                            // disabled={disableElement?.scanningDropdown || (salesDetail && salesDetail.length > 0)}
                            data-testid="scanning-select"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Select Scanning Type"
                                sx={{
                                  ...selectStylesOverride,
                                }}
                              />
                            )}
                            sx={{ flexBasis: "50%", mb: 1 }}
                            options={scanningOptions()}
                            onChange={handleScanningSelect}
                            value={
                              scanningOptions()?.find((dl) => dl.value == scanning)
                                ?.label || ""
                            }
                          />
                        </Grid>
                      }
                      {
                        (isJobType?.isTypeQCInhouse || isJobType?.isTypeQCInward) &&
                        <Grid item xs={12} sm={6} md={4}>
                          <Autocomplete
                            id="completion-method-label"
                            size="small"
                            data-testid="ompletion-methodselect"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Select Quanlity Check Method"
                                sx={{
                                  ...selectStylesOverride,
                                }}
                              />
                            )}
                            sx={{ flexBasis: "50%", mb: 1 }}
                            options={jobCompletionOptions}
                            onChange={handleScanningSelect}
                            value={scanningInput?.label || ""}
                          />
                        </Grid>
                      }
                      <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          id="assign-user-label"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Assign User${jobProcessConfig?.requiredFields?.userRequired ? "*" : ""}`}
                              sx={{
                                ...selectStylesOverride,
                              }}
                            />
                          )}
                          disabled={!jobType?.value}
                          data-testid="user-select"
                          sx={{ flexBasis: "50%", mb: 1 }}
                          options={workerOptions}
                          onChange={handleWorkerSelect}
                          value={
                            workerOptions.find((_) => _.value === worker)?.label ||
                            ""
                          }
                        />
                      </Grid>
                      {filterEnable() && (
                        <Grid item xs={12} sm={6} md={4}>
                          <Autocomplete
                            id="shipmentFilter"
                            size="small"
                            disabled={!category || (salesDetail && salesDetail.length > 0)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Filter (optional)"
                                sx={{
                                  ...selectStylesOverride,
                                }}
                              />
                            )}
                            sx={{ flexBasis: "50%", mb: 1 }}
                            options={ShipmentFilter}
                            onChange={handleFilterSelect}
                            value={dataFilter?.label || ""}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {(dataFilter?.value === "date" ||
                      dataFilter?.value === "dateRange") && (
                        <Grid container xs={12} mt={1} spacing={2}>
                          {dataFilter?.value === "date" && (
                            <Grid item xs={12} sm={6} md={4}>
                              <DatePicker
                                fullWidth
                                format="DD/MM/YYYY"
                                data-testid="receivedAt"
                                disableFuture
                                value={filterValue && filterValue[0]}
                                onChange={(d) => handleDateChange(d, "date")}
                                label="Date"
                                sx={{ ...inputClasses.textField, width: "100%" }}
                                slotProps={{ textField: { size: "small" } }}
                              />
                            </Grid>
                          )}
                          {dataFilter?.value === "dateRange" && (
                            <>
                              <Grid item xs={12} sm={6} md={4}>
                                <DatePicker
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  data-testid="receivedAt"
                                  disableFuture
                                  value={filterValue && filterValue[0]}
                                  onChange={(d) => handleDateChange(d, "from")}
                                  label="From"
                                  sx={{ ...inputClasses.textField, width: "100%" }}
                                  slotProps={{ textField: { size: "small" } }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <DatePicker
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  data-testid="receivedAt"
                                  disableFuture
                                  value={filterValue && filterValue[1]}
                                  onChange={(d) => handleDateChange(d, "to")}
                                  label="To"
                                  sx={{ ...inputClasses.textField, width: "100%" }}
                                  slotProps={{ textField: { size: "small" } }}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}

                    {jobType?.types?.length && category
                      ? jobType?.types.map((ele, index) =>
                        renderActionElement(ele, index)
                      )
                      : null}
                    {addedItems?.length ? renderTableFooter(addedItems) : ""}
                  </>}
              </CardContent>
              <CardActions></CardActions>
            </Card>

            {/* Template Section */}
            {templateDetail?.visible ? (
              <TemplateRender
                templateDetail={templateDetail}
                setTemplateDetail={setTemplateDetail}
                formHooks={{ register, control, setValue, errors, reset }}
              />
            ) : (
              ""
            )}
            {isJobType?.isTypeInventoryAudit &&
              <Grid mt={3} data-testid="remark-section">
                <Box>
                  <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                    Reason
                  </Typography>
                </Box>
                <Box mt={2} sx={{ display: "flex", gap: "2em" }}>
                  <Grid item sm={3}>
                    <TypeAndSearch
                      data-testid="select-reason"
                      component={reasonComponent("inventoryAudit")}
                      value={stockTransferDetail?.reason?.value}
                      label={stockTransferDetail?.reason?.label}
                      setEmptyOnChange={false}
                      handleChange={(newValue, option) => {
                        setStockTransferDetail({
                          ...stockTransferDetail,
                          reason: option,
                        })
                      }}
                      reasonType={"inventoryAudit"}
                      addNewOptions={{ isEnabled: true, label: 'Add New Reason' }}
                    />
                  </Grid>
                </Box>
              </Grid>
            }
            <Grid mt={3} data-testid="remark-section">
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
                  Remark(s)
                </Typography>
              </Box>
              <Box mt={2} sx={{ display: "flex", gap: "2em" }}>
                <TextareaAutosize
                  minRows={5}
                  maxRows={5}
                  style={{
                    flexBasis: "50%",
                    borderColor: "#cacaca",
                    borderRadius: "12px",
                    padding: "1em",
                  }}
                  onChange={handleRemarkChange}
                  value={remark}
                />
              </Box>
            </Grid>
          </CardContent>
          <CardActions>
            <Box sx={{ display: "flex", gap: "1em" }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  border: `1px solid ${theme.themeOrange}`,
                  color: theme.themeOrange,
                  "&:hover": {
                    border: `1px solid ${theme.themeOrange}`,
                  },
                }}
                disabled={isLoader}
                data-testid="clear-btn"
                onClick={handleClearAll}
              >
                Clear All
              </Button>
              {
                (salesDetail && salesDetail.length > 0 || state?.jobType)
                  ? ""
                  : <Button
                    variant="outlined"
                    size="small"
                    disabled={isLoader || disableSubmit}
                    sx={{
                      ...buttonClasses.lynkitOrangeEmpty,
                      border: `1px solid ${theme.themeOrange}`,
                      color: theme.themeOrange,
                      "&:hover": {
                        border: `1px solid ${theme.themeOrange}`,
                      },
                    }}
                    data-testid="addnew-btn"
                    onClick={() => submitData("submitAndCreateNew")}
                  >
                    Submit & Create New
                  </Button>
              }
              <Button
                variant="contained"
                size="small"
                data-testid="submit-btn"
                disabled={isLoader || disableSubmit}
                sx={{
                  ...buttonClasses.lynkitOrangeFill,
                  backgroundColor: theme.themeOrange,
                  "&:hover": {
                    backgroundColor: theme.themeOrange,
                  },
                }}
                onClick={() => submitData("submit")}
              >
                Submit
              </Button>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
}

const InventoryCheckComponent = ({
  addedItems,
  setAddedItems,
  jobCreationData,
  isTypeInventoryCheck = true,
  status = ["partial", "full", "available"],
  children,
  listDropDown = false,
  warehouseId = "",
  floorId = "",
  countFor_SPI: { countFor_Shipment_Item = {} },
}) => {
  const dispatch = useDispatch();
  const { gridCellDetails = [], gridRackDetails = [] } = useSelector(
    (state) => state.warehouseData
  );
  const initial = {
    cellId: "",
    cellName: "",
    isRackExist: false,
    isAdd: false,
  };
  const [selectedData, setSelectedData] = useState(initial);

  const cellOptions = () => {
    let opt = [];
    for (let _ of gridCellDetails) {
      if (_?.warehouseId == warehouseId && _?.floorId == floorId) {
        opt.push({
          label: _.name + " ( " + ([_.status] || "") + " )",
          cellName: _.name,
          value: _.cellId,
          isRackExist: _.isRackExist || false,
          mappingArea: _.subProductType || [],
        });
      }
    }
    return opt;
  };

  const rackOptions = () => {
    let opt = [];
    for (let _ of gridRackDetails) {
      if (
        _?.warehouseId == warehouseId &&
        _?.floorId == floorId &&
        _?.parentId == selectedData?.cellId
      ) {
        opt.push({
          label: _.name + " ( " + ([_.status] || "") + " )",
          name: _.name,
          value: _.cellId,
          mappingArea: (_.productType && [_.productType]) || "",
          parentId: _?.parentId || "",
        });

        if (selectedData.cellId) {
          countFor_Shipment_Item[selectedData.cellId] = opt.length;
        }
      }
    }
    return opt;
  };

  const handleCellSelect = (e, option) => {
    let temp_data = { ...selectedData };
    if (option?.value) {
      const {
        label,
        value,
        mappingArea,
        isRackExist = false,
        cellName,
      } = option;
      temp_data["isAdd"] = true;
      temp_data = {
        ...temp_data,
        isRackExist,
        cellName,
        C_label: label,
        cellId: value,
        isAdd: true,
        mappingArea,
      };

      if (isRackExist) {
        dispatch(
          getGridDetail({
            parentId: value,
            type: "rack",
            floorId,
            warehouseId,
            status,
            fetch_for:"pickUp",
          })
        );
      } else {
        temp_data["isAdd"] = true;
        temp_data["isRackExist"] = false;
      }
    } else {
      delete temp_data["C_label"];
      delete temp_data["cellId"];
      delete temp_data["mappingArea"];
      temp_data["isRackExist"] = false;
      temp_data["isAdd"] = false;
    }

    delete temp_data["rackDetail"];
    setSelectedData(temp_data);
  };

  const handleRackSelect = (e, option) => {
    ////("option",option);
    let temp_data = { ...selectedData };
    if (option?.length) {
      temp_data["rackDetail"] = option;
    } else {
      delete temp_data["rackDetail"];
    }
    setSelectedData(temp_data);
  };

  const handleItemAdd = () => {
    // ////("fffsdewfc>>>>handleItemAdd");
    setAddedItems((o) => [selectedData, ...o]);
    setSelectedData(initial);
  };

  const diableCell = ({ cellId, isRackExist = false }) => {
    if (!cellId) {
      return true;
    }

    let isPKGAdded = false,
      allAddedCount = 0;

    for (let ele of addedItems) {
      if (ele?.cellId == cellId) {
        if (isRackExist) {
          isPKGAdded = true;
          allAddedCount = allAddedCount + (ele?.rackDetail?.length || 0);
          continue;
        }
        return true;
      }
    }

    if (isPKGAdded) {
      let available = countFor_Shipment_Item[cellId] || 0;
      if (allAddedCount >= available) {
        return true;
      }
    }

    return false;
  };

  const diableRack = ({ rackId }) => {
    if (!rackId) {
      return true;
    }
    return addedItems.find((el) =>
      el?.rackDetail?.find((dl) => dl.value == rackId) ? true : false
    )
      ? true
      : false;
  };

  const header = ["Sr. No", "Cell", "Rack", "Mapping Area", "Action"];

  const handleRowDelete = (indx) => {
    setAddedItems((o) => o.filter((_, i) => i !== indx));
  };

  const rows = addedItems.map((data, indx) => {
    let row = [];
    ////("data",data);
    const {
      cellId,
      cellName,
      isRackExist = false,
      C_label,
      mappingArea,
      rackDetail = [],
    } = data;

    row.push(
      indx + 1,
      cellName,
      isRackExist
        ? rackDetail.length == 0
          ? "All"
          : rackDetail.map((dl) => dl.name).join(", ")
        : "N/A",
      (mappingArea?.length && mappingArea.join(", ")) || "PUT IT ANY WHERE",
      [
        <IconButton
          aria-label="delete"
          key={indx}
          onClick={() => handleRowDelete(indx)}
        >
          <Delete sx={{ color: "red" }} />
        </IconButton>,
      ]
    );
    return row;
  });

  return (
    <>
      {children && <Grid mt={1}>{children}</Grid>}
      <Grid mt={3}>
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Inventory Details
          </Typography>
        </Box>
        <Box mt={2} sx={{ display: "flex", gap: "2em" }}>
          <Autocomplete
            id="Cell-select"
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Cell"
                sx={{
                  ...selectStylesOverride,
                }}
              />
            )}
            disabled={listDropDown}
            getOptionLabel={(option) => option.label}
            sx={{ flexBasis: "30%" }}
            options={cellOptions()}
            onChange={handleCellSelect}
            getOptionDisabled={(option) =>
              diableCell({
                ...option,
                cellId: option.value,
                isRackExist: option.isRackExist || false,
              })
            }
            inputValue={selectedData.C_label || ""}
            isOptionEqualToValue={(option, value) => {
              return option.label === value.label;
            }}
            key="cell-select_gateOut"
          />
          {selectedData?.isRackExist ? (
            <FormControl
              size="small"
              // fullWidth
              sx={{ flexBasis: "30%" }}
            >
              <Autocomplete
                multiple
                id="rack-select"
                size="small"
                limitTags={5}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Rack"
                    sx={{
                      ...selectStylesOverride,
                    }}
                  />
                )}
                getOptionLabel={(option) => option.label}
                options={rackOptions()}
                onChange={handleRackSelect}
                renderOption={(props, option, { selected }) => {
                  const { label, value } = option;
                  return (
                    <MenuItem
                      value={value}
                      key={value}
                      sx={{ ml: 2 }}
                      {...props}
                    >
                      <Checkbox checked={selected} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  );
                }}
                getOptionDisabled={(option) =>
                  diableRack({ ...option, rackId: option.value })
                }
                value={selectedData?.rackDetail || []}
                key="rack-select_gateOut"
                isOptionEqualToValue={(option, value) => {
                  return option.label === value.label;
                }}
                disableCloseOnSelect
              />
            </FormControl>
          ) : null}
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: theme.themeOrange,
              "&:hover": {
                backgroundColor: theme.themeOrange,
              },
            }}
            onClick={handleItemAdd}
            disabled={!selectedData || !selectedData.isAdd}
          >
            Add
          </Button>
        </Box>

        {addedItems.length ? (
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "2em",
              maxWidth: "100%",
              // width: "60%",
              overflowX: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroungColor: theme.themeGray }}>
                <TableRow sx={{ backgroundColor: theme.themeGray }}>
                  {header.map((head, i) => {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          textAlign: "center",
                          fontWeight: 600,
                          // borderLeft: "1px solid #a3a3a3",
                          // '& .MuiTableCell-root':{
                          // }
                        }}
                        key={i}
                      >
                        {head}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0
                  ? rows.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        // "&:last-child td, &:last-child th": {
                        // 	border: 0,
                        // },
                        "& .MuiTableCell-root": {
                          // borderLeft:
                          // "1px solid rgba(224, 224, 224, 1)",
                        },
                      }}
                    >
                      {row.map((col, j) => {
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              overflowWrap: "break-word",
                            }}
                            key={j}
                          >
                            {col}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </>
  );
};

const InventoryComponent = ({
  addedItems,
  setAddedItems,
  allProductType = [],
  jobCreationData,
  status = ["pending", "partialGateIn"],
  jobProcessConfig,
  isTypeGateIn = false,
  isTypeGateOut = false,
  isTypePicking = false,
  isTypeStockTransfer = false,
  isTypeQCInhouse = false,
  isTypeQCInward = false,
  children,
  disableElement: { listDropDown = false, categoryDropdown = false },
  countFor_SPI: {
    countFor_PKG_Item = {},
    countFor_Shipment_Item = {},
    countFor_Shipment_PKG = {},
  },
  category,
  packagingList,
  setPackagingList,
  isTypePickingAndPackaging = false,
  inventoryData: { shipments = [], packages = [], items },
  setInventoryData,
  warehouseId = "",
  dataFilter = {},
  filterValue = [],
  setDisableElement,
  filterEnable

}) => {
  const dispatch = useDispatch();
  const isDataLoader=useSelector((state)=>state.common)?.loading==true
  // const shipments = useSelector((state) => state?.job?.shipments  || []);
  // const packages = useSelector((state) => state?.inventory?.packageDetail || []);
  // const items = useSelector((state) => state?.inventory?.itemDetail || []);

  //countFor_PKG_Item, countFor_Shipment_Item, countFor_Shipment_PKG
  // ////("jhhhhhhhhhhhh>>>>countFor_SPI",{ countFor_PKG_Item,countFor_Shipment_Item,countFor_Shipment_PKG});
  const initial = {
    isPackageExist: false,
    isPackageExist: false,
    isAdd: false,
    isCountOnly: false,
    checked: [],
    saveAs: "single",
  };

  const valid_mappingArea = allProductType?.length > 0 && allProductType || "";
  // console.log("valid_mappingArea", valid_mappingArea)
  const [selectedData, setSelectedData] = useState(initial);

  // For Picking & Packing
  const [objectsInPackage, setObjectsInPackage] = useState([]);
  const [openCreatePackage, setOpenCreatePackage] = useState(false);
  const [packageDialogType, setPackageDialogType] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [packageEditIndex, setPackageEditIndex] = useState(null);

  const generate_balance_Count = (_data = {}) => {
    let balance_Count = _data
      ? typeof _data == "object"
        ? Number(_data?.physical || 0)
        : Number(_data || 0)
      : 0;

    return Number(balance_Count || 0);
  };

  let selectedCount = (key, value) => {
    return truncateNumber((addedItems || []).reduce((prev, current) => {
      let tempCount = prev;
      if (current[key] == value && current?.count)
        tempCount = tempCount + Number(current?.count);
      return tempCount;
    }, 0));
  };
  const shipmentOptions = () => {
  // const shipmentOptions = useMemo(() => {
    let arr = [];

    for (let _ of shipments) {
      let selected_count = selectedCount("shipmentId", _?.shipmentId);
      const allGateInCount=truncateNumber(Number(_?.gateInCount || 0)+Number(_?.gateInHoldingQty || 0))
      const allGateOutCount=truncateNumber(generate_balance_Count(_?.balanceCount)- Number(_?.gateOutHoldingQty || 0) || 0)
      if (isTypeGateIn || isTypeQCInward) {
        if (Number(_?.totalCount || 0) - allGateInCount <= selected_count) continue;
      }
      else {
        if (allGateOutCount <= selected_count) continue;
      }
      arr.push({
        label: getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", _),
        // label: _.shipmentId + " (" + (statusConfig[_.status] || "") + ")",
        value: _.shipmentId,
        isPackageExist: _.isPackageExist || false,
        isItemExist: _.isItemExist || false,
        mappingArea: (_?.mappingArea?.length && _.mappingArea[0]) || "",
        all_S_Count: Number(_?.totalCount || 1),
        gateIn_S_Count:allGateInCount,
        balance_S_Count: allGateOutCount,
        gateInTime: _?.gateInTime,
      });
    }

    // console.log("arr",arr,shipments)
    return arr;
  }
  // },[shipments,addedItems,selectedData]);

  const packageOptions = () => {
  // const packageOptions = useMemo(() => {
    let opt = [];
    for (let _ of packages) {
      let selected_count = selectedCount("packageId", _?.packageId);
      const allGateInCount=truncateNumber(Number(_?.gateInCount || 0)+Number(_?.gateInHoldingQty || 0))
      const allGateOutCount=truncateNumber(Number(generate_balance_Count(_?.balanceCount)- Number(_?.gateOutHoldingQty || 0) || 0))
      if (isTypeGateIn || isTypeQCInward) {
        if (
          Number(_?.count || 0) - allGateInCount <=
          selected_count
        )
          continue;
      } else {
        if (allGateOutCount <= selected_count) continue;
      }
      let condition_val = false;
      if (["packageName"].includes(category)) condition_val = true;
      else {
        condition_val = _.shipmentId
          ? _.shipmentId == selectedData.shipmentId
          : false;
      }

      if (condition_val) {
        opt.push({
          ...(_?.dynamicData || {}),
          label: getLabelKeyForJobConfig(jobProcessConfig, "package", "key", _),
          // label:
          //   _[getLabelKeyForJobConfig(jobProcessConfig, "package", "key")] +
          //   " (" +
          //   (statusConfig[_.status] || "") +
          //   ")",
          value: _.packageId,
          shipmentId: _.shipmentId,
          isItemExist: _.isItemExist || false,
          mappingArea: (_?.mappingArea?.length && _.mappingArea[0]) || "",
          all_P_Count: Number(_?.count || 1),
          gateIn_P_Count:allGateInCount,
          balance_P_Count: allGateOutCount,
          gateInTime: _?.gateInTime,
        });
      }
    }
    return opt;
  };
  // },[shipments,addedItems,packages,selectedData]);

  const itemOptions =() => {
  // const itemOptions = useMemo(() => {
    let opt = [];

    for (let _ of items) {
      let selected_count = selectedCount("itemId", _?.itemId);
      const allGateInCount=truncateNumber(Number(_?.gateInCount || 0)+Number(_?.gateInHoldingQty || 0))
      const allGateOutCount=truncateNumber(Number(generate_balance_Count(_?.balanceCount)- Number(_?.gateOutHoldingQty || 0) || 0))
      if (isTypeGateIn || isTypeQCInward) {
        if (
          Number(_?.count || 0) -allGateInCount <=
          selected_count
        )
          continue;
      } else {
        if (allGateOutCount <= selected_count) continue;
      }

      let condition_val = false;
      if ((selectedData?.checked || []).includes("package")) {
        condition_val =
          (_.packageId &&
            packageOptions()
              .map((dl) => dl.value)
              .includes(_.packageId)) ||
          false;
      } else if (selectedData.isPackageExist) {
        condition_val =
          (_.packageId && _.packageId == selectedData.packageId) || false;
      } else {
        condition_val =
          (_.shipmentId && _.shipmentId == selectedData.shipmentId) || false;
      }

      if (condition_val) {
        opt.push({
          ...(_?.dynamicData || {}),
          label: getLabelKeyForJobConfig(jobProcessConfig, "item", "key", _),
          // label:
          //   _[getLabelKeyForJobConfig(jobProcessConfig, "item", "key")] +
          //   " (" +
          //   (statusConfig[_.status] || "") +
          //   ")",
          value: _.itemId,
          shipmentId: _.shipmentId,
          packageId: _.packageId || "",
          mappingArea: Array.isArray(_.mappingArea || "")
            ? (_?.mappingArea?.length && _.mappingArea[0]) || ""
            : _.mappingArea || "",
          all_I_Count: Number(_?.count || 1),
          gateIn_I_Count: allGateInCount,
          balance_I_Count: allGateOutCount,
          gateInTime: _?.gateInTime,
        });
      }
    }

    // ////("ItemDiabefffffffff",{selectedData:selectedData.shipmentId,countFor_PKG_Item,countFor_Shipment_Item});
    return opt;
  };
  // },[shipments,addedItems,packages,items,selectedData]);

  const fetchPackageItem = ({
    shipmentId = "",
    packageId = "",
    dataFetchType = "",
  }) => {
    let req_payload = {
      status,
      projection_for: "job",
      // warehouseId,
    };

    if (shipmentId) req_payload["shipmentId"] = shipmentId;

    if (allProductType?.length > 0) {

      req_payload["mappingArea"] = allProductType;
    }
    if (isTypeGateIn || isTypeQCInward) {
      req_payload["fetch_for"] = "beforeEntry";
    }
    else {
      req_payload["warehouseId"] = warehouseId;
      req_payload["fetch_for"] = "afterEntry";
      if (filterEnable() && dataFilter?.value) {
        req_payload = { ...req_payload, filterType: dataFilter?.value };

        if (dataFilter?.value == "date" || dataFilter?.value == "dateRange") {
          let isTrue = false;
          if (dataFilter?.value == "date" && filterValue?.length > 0) {
            isTrue = true;
          }
          if (dataFilter?.value == "dateRange" && filterValue?.length == 2) {
            isTrue = true;
          }
          if (isTrue) {
            req_payload["filterValue"] = filterValue;
          } else {
            delete req_payload["filterType"];
          }
        }
      }
    }

    if (dataFetchType == "packageWise") {
      req_payload["dataFetchType"] = "packageWise";
      // console.log(req_payload, "valid_mappingAreaiiiii", allProductType)
      dispatch(
        getAllInventory(req_payload, ({ success = false, data = [] }) => {
          setInventoryData({ shipments, packages: data, items: [] });
          if (isTypeStockTransfer && setDisableElement) {
            setDisableElement({ categoryDropdown, listDropDown: false });
          }
        })
      );
    }
    else if (dataFetchType == "itemWise") {
      if (packageId) req_payload["packageId"] = packageId;
      req_payload["dataFetchType"] = "itemWise";

      dispatch(
        getAllInventory(req_payload, ({ success = false, data = [] }) => {
          setInventoryData({
            shipments,
            packages: packageId ? packages : [],
            items: data,
          });
        })
      );
    }
    else {

    }
  };

  const getValidCount_For_SPI = (option, type = "shipment") => {
    if (!option.value) return 0;
    else {
      let valid_count = 0;
      if (isTypeGateIn || isTypeQCInward) {
        if (type == "item") {
          valid_count = option?.all_I_Count - option?.gateIn_I_Count || 0;
        } else if ("package") {
          valid_count = option?.all_P_Count - option?.gateIn_P_Count || 0;
        } else {
          valid_count = option?.all_S_Count - option?.gateIn_S_Count || 0;
        }
      } else {
        if (type == "item") {
          valid_count = option?.balance_I_Count || 0;
        } else if ("package") {
          valid_count = option?.balance_P_Count || 0;
        } else {
          valid_count = option?.balance_S_Count || 0;
        }
      }

      return truncateNumber(valid_count);
    }
  };

  const handleShipmentSelect = (e, option) => {
    let temp_data = { ...initial };

    // ////("option",option);

    if (option?.value) {
      const {
        label,
        value,
        isPackageExist = false,
        isItemExist = false,
        mappingArea,
        all_S_Count,
        gateIn_S_Count,
        balance_S_Count = 0,
      } = option; //e.target;
      // ////(option);
      temp_data = {
        S_label: label,
        shipmentId: value,
        mappingArea,
        all_S_Count,
        gateIn_S_Count,
        isPackageExist,
        isItemExist,
        isCountOnly: false,
        balance_S_Count,
      };

      let valid_count = getValidCount_For_SPI(option, "shipment");
      // let req_payload={
      // 	shipmentId: value,
      // 	status,
      // 	projection_for:"job"
      // }

      // if(valid_mappingArea?.length){
      // 	req_payload["mappingArea"]=valid_mappingArea

      // }

      // if(isTypeGateIn || isTypeQCInward){
      // 	req_payload["fetch_for"]="beforeEntry"
      // }
      // else{
      // 	req_payload["fetch_for"]="afterEntry"
      // }

      if (isPackageExist) {
        temp_data["count"] = 0;
        fetchPackageItem({
          shipmentId: value,
          packageId: "",
          dataFetchType: "packageWise",
        });
        // req_payload["dataFetchType"]= "packageWise"
        // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
        // 	setInventoryData({shipments,packages: data, items: [] })
        // }));
      } else if (isItemExist) {
        temp_data["count"] = 0;
        fetchPackageItem({
          shipmentId: value,
          packageId: "",
          dataFetchType: "itemWise",
        });
        // req_payload["dataFetchType"]= "itemWise"
        // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
        // 	setInventoryData({ shipments, packages: [], items: data })
        // }));
      } else {
        setInventoryData({ shipments, packages: [], items: [] });
        temp_data["isAdd"] = true;
        temp_data["isCountOnly"] = true;
        temp_data["count"] = valid_count;
        //only count
      }
    } else {
      temp_data["isAdd"] = false;
      temp_data["count"] = 0;
      setInventoryData({ shipments, packages: [], items: [] });
    }
    // ////(temp_data);

    setSelectedData(temp_data);
  };

  const handlePackageSelect = (e, option, returnData = false) => {
    let temp_data = { ...selectedData };
    // ////("Package Option", option);
    if (option?.value) {
      const { label, value, isItemExist, shipmentId } = option;

      temp_data = {
        ...temp_data,
        ...option,
        packageId: value,
        P_label: label,
        isAdd: true,
        isCountOnly: false,
        isPackageExist: true,
      };

      let valid_count = getValidCount_For_SPI(option, "package");

      // let req_payload={
      // 	shipmentId,status,
      // 	packageId: value,
      // 	projection_for:"job"
      // }

      // if(valid_mappingArea?.length){
      // 	req_payload["mappingArea"]=valid_mappingArea
      // }
      // if(isTypeGateIn || isTypeQCInward){
      // 	req_payload["fetch_for"]="beforeEntry"
      // }
      // else{
      // 	req_payload["fetch_for"]="afterEntry"
      // }
      if (isItemExist) {
        temp_data["isAdd"] = false;
        temp_data["count"] = 0;
        fetchPackageItem({
          shipmentId,
          packageId: value,
          dataFetchType: "itemWise",
        });
        // req_payload["dataFetchType"]= "itemWise"
        // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
        // 	setInventoryData({ shipments, packages, items: data })
        // }));
      } else {
        //package more than one count
        // if(Number(all_P_Count || 0)>1){
        // 	isBool=false
        // }
        temp_data["isAdd"] = true;
        temp_data["isCountOnly"] = true;
        temp_data["count"] = valid_count;
        setInventoryData({ shipments, packages, items: [] });
      }
    } else {
      setInventoryData({ shipments, packages, items: [] });
      delete temp_data["packageId"];
      delete temp_data["P_label"];
      delete temp_data["all_P_Count"];
      delete temp_data["gateIn_P_Count"];
      delete temp_data["balance_P_Count"];
      temp_data["isCountOnly"] = true;
      temp_data["count"] = 0;
      temp_data["isAdd"] = false;
    }

    delete temp_data["I_label"];
    delete temp_data["itemId"];

    // ////("iiiiiiii",temp_data);
    if (returnData) return temp_data;
    else {
      setSelectedData(temp_data);
    }
  };

  const handleItemSelect = (e, option, returnData = false) => {
    let temp_data = { ...selectedData };
    // ////("Item Option", option);
    if (option?.value) {
      const { label, value } = option;
      let valid_count = getValidCount_For_SPI(option, "item");
      temp_data = {
        ...temp_data,
        ...option,
        isItemExist: true,
        isAdd: true,
        itemId: value,
        I_label: label,
        count: valid_count,
        isItemExist: true,
      };
    } else {
      delete temp_data["itemId"];
      delete temp_data["I_label"];
      temp_data["isAdd"] = false;
      temp_data["count"] = 0;
    }

    temp_data["isCountOnly"] = true;

    ////("temp_data",temp_data);
    if (returnData) return temp_data;
    else {
      setSelectedData(temp_data);
    }
  };

  const diableShipment = ({ shipmentId }) => {
    if (!shipmentId) {
      return false;
    }

    let isShipmentAdded = false,
      allAddedCount = 0;
    const { isItemExist = false, isPackageExist = false } = shipments.find(
      (el) => el.shipmentId == shipmentId
    );

    for (let ele of addedItems) {
      if (ele?.shipmentId == shipmentId) {
        if (isItemExist || isPackageExist) {
          isShipmentAdded = true;
          allAddedCount++;
          continue;
        }

        return true;
      }
    }

    if (isShipmentAdded) {
      let available = 0;

      if (isItemExist) {
        if (isPackageExist) {
          for (let key in countFor_PKG_Item || {}) {
            if (key.includes(shipmentId)) {
              available = available + countFor_PKG_Item[key];
            }
          }
          // countFor_PKG_Item[packageId] || 0
        } else {
          available = countFor_Shipment_Item[shipmentId] || 0;
        }
      } else {
        available = countFor_Shipment_PKG[shipmentId] || 0;
      }

      // ////("shipmentId",shipmentId,{
      // 	allAddedCount,available,countFor_PKG_Item,countFor_Shipment_PKG,
      // 	countFor_Shipment_Item,isItemExist
      // });
      //check disable condition
      if (allAddedCount >= available) {
        return true;
      }
    }

    return false;
  };
  // ////(addedItems);
  const diablePackage = ({ packageId, isItemExist }) => {
    if (!packageId) {
      return true;
    }

    let isPKGAdded = false,
      allAddedCount = 0;

    for (let ele of addedItems) {
      if (ele?.packageId == packageId) {
        if (isItemExist) {
          isPKGAdded = true;
          allAddedCount++;
          // allAddedCount+=Number(isItemExist ? 1 : ele.count || 1)
          continue;
        }
        // ////("inside not Itmem",packageId);
        return true;
      }
    }
    // ////("added",isPKGAdded,allAddedCount);

    if (isPKGAdded) {
      let available = countFor_PKG_Item[packageId] || 0;
      // ////("inside isItme",packageId,allAddedCount,available);
      if (allAddedCount >= available) {
        return true;
      }
    }

    return false;
  };

  const diableItem = ({ ItemId }) => {
    // ////("diableItem",ItemId);
    if (!ItemId) {
      return true;
    }
    return addedItems.find((el) => el?.itemId == ItemId) ? true : false;
  };

  const header = (() => {
    if (isTypePickingAndPackaging) {
      let arr = [
        "Sr. No",
        getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label"),
        getLabelKeyForJobConfig(jobProcessConfig, "package", "label"),
        getLabelKeyForJobConfig(jobProcessConfig, "item", "label"),
        "Gate In Time",
        "Packed In",
        "Quantity",
      ];

      if (["packageName"].includes(category)) arr.splice(1, 1);
      return arr;
    }
    else {
      let arr = [
        "Sr. No",
        getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label"),
        getLabelKeyForJobConfig(jobProcessConfig, "package", "label"),
        getLabelKeyForJobConfig(jobProcessConfig, "item", "label"),
        "Mapping Area",
        "Count",
        "Action",
      ];

      if (!isTypeGateIn && !isTypeQCInward) arr.splice(5, 0, "Gate In Time");

      if (["packageName"].includes(category)) arr.splice(1, 1);
      return arr;
    }
  })();

  const handleRowDelete = (indx) => {
    setAddedItems((o) => o.filter((_, i) => i !== indx));
  };

  const countHandler = (count_num = "", index, available_count) => {
    let temp_added = [...addedItems];

    let elem = temp_added[index],count_convert = removeLeadingChars(count_num);

    // ////("fffff",{count_num,count_convert});

    if (Number(count_convert) > available_count) {
      showToast(
        `Maximum count should be ${available_count} for row number ${index + 1
        }`,
        true
      );
      elem = { ...elem, count: available_count };
      temp_added.splice(index, 1, elem);
    } else {
      elem = { ...elem, count: count_convert };
      temp_added.splice(index, 1, elem);
    }
    setAddedItems(temp_added);
  };
  const rows = (() => {
    if (isTypePickingAndPackaging) {
      let allRows = [];
      let counter = 0;
      for (let i = 0; i < packagingList.length; i++) {
        const pkg = packagingList[i];
        for (let j = 0; j < pkg.itemDetail.length; j++) {
          const element = pkg.itemDetail[j];
          let row = [];
          row.push(
            counter + 1,
            getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", element, ["gateInTime"]) || "NA",
            getLabelKeyForJobConfig(jobProcessConfig, "package", "key", element, ["gateInTime"]) || "NA",
            getLabelKeyForJobConfig(jobProcessConfig, "item", "key", element, ["gateInTime"]) || "NA",
            dateTimeFormater(element.gateInTime),
            // element.shipmentId ? element.shipmentId : "NA",
            // element[
            //   getLabelKeyForJobConfig(jobProcessConfig, "package", "key")
            // ] || "NA",
            // element[getLabelKeyForJobConfig(jobProcessConfig, "item", "key")] ||
            //   "NA",
            pkg.masterPackageId,
            element.count
          );
          if (["packageName"].includes(category)) row.splice(1, 1);
          allRows.push(row);
          counter++;
        }
      }
      return allRows;
    } else {
      return addedItems.map((data, indx) => {
        let row = [];
        const {
          shipmentId,
          packageId,
          itemId,
          mappingArea,
          count,
          isCountOnly = false,
          balance_P_Count,
          balance_S_Count,
          all_S_Count,
          gateIn_S_Count,
          all_P_Count,
          gateIn_P_Count,
          all_I_Count,
          gateIn_I_Count,
          balance_I_Count,
          isPackageExist = false,
          isItemExist = false,
          availableCount = false
        } = data;

        // ////("data", data);
        // ////("mappia",mappingArea);
        row.push(
          indx + 1,
          getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", data, ["gateInTime"], true),
          getLabelKeyForJobConfig(jobProcessConfig, "package", "key", data, ["gateInTime"], true),
          getLabelKeyForJobConfig(jobProcessConfig, "item", "key", data, ["gateInTime"], true),
          // shipmentId,
          // data[getLabelKeyForJobConfig(jobProcessConfig, "package", "key")] ||
          //   "NA",
          // data[getLabelKeyForJobConfig(jobProcessConfig, "item", "key")] ||
          //   "NA",
          mappingArea && mappingArea != null && mappingArea != ""
            ? mappingArea
            : "PUT IT ANY WHERE",
          count || 1,
          [
            <IconButton
              aria-label="delete"
              key={indx}
              onClick={() => handleRowDelete(indx)}
            >
              <Delete sx={{ color: "red" }} />
            </IconButton>,
          ]
        );

        if (isCountOnly) {
          let available_count = 0;
          if (isItemExist) {
            if (Number(all_I_Count) == 1) {
              row.splice(5, 1, 1);
              if (!isTypeGateIn && !isTypeQCInward) {
                row.splice(5, 0, dateTimeFormater(data.gateInTime));
              }
              if (["packageName"].includes(category)) row.splice(1, 1);

              return row;
            }
            if (isTypeGateIn || isTypeQCInward) {
              if (availableCount) {
                available_count = availableCount
              }
              else {
                available_count = truncateNumber(Number(all_I_Count || 1) - Number(gateIn_I_Count || 0));
              }
            }
            else {
              available_count = Number(balance_I_Count);
            }
          }
          else if (isPackageExist) {
            if (Number(all_P_Count) == 1) {
              row.splice(5, 1, 1);
              if (!isTypeGateIn && !isTypeQCInward) {
                row.splice(5, 0, dateTimeFormater(data.gateInTime));
              }
              if (["packageName"].includes(category)) row.splice(1, 1);
              return row;
            }
            if (isTypeGateIn || isTypeQCInward) {
              if (availableCount) {
                available_count = availableCount
              }
              else {
                available_count =truncateNumber(Number(all_P_Count || 1) - Number(gateIn_P_Count || 0));
              }
            } else {
              available_count = Number(balance_P_Count);
            }
          }
          else {
            if (Number(all_S_Count) == 1) {
              row.splice(5, 1, 1);
              if (!isTypeGateIn && !isTypeQCInward) {
                row.splice(5, 0, dateTimeFormater(data.gateInTime));
              }
              if (["packageName"].includes(category)) row.splice(1, 1);
              return row;
            }
            if (isTypeGateIn || isTypeQCInward) {
              if (availableCount) {
                available_count = availableCount
              }
              else {
                available_count =truncateNumber(Number(all_S_Count || 1) - Number(gateIn_S_Count || 0));
              }
            }
            else {
              available_count = Number(balance_S_Count);
            }
          }

          if(isTypeQCInhouse || isTypeQCInward){
          }
          else{
            row.splice(
              5,
              1,
              <GenericSingleRangeComponent
                startRange={count || null}
                endRange={Number(available_count || 0)}
                getOnChange={(countValue) => countHandler(countValue, indx, available_count)}
                endRangeInfinity={false}
                valueType={"decimal"}
              />,
            );
          }
        }

        if (!isTypeGateIn && !isTypeQCInward) {
          row.splice(5, 0, dateTimeFormater(data.gateInTime));
        }
        if (["packageName"].includes(category)) row.splice(1, 1);
        return row;
      });
    }
  })();

  // Picking & Packing functions
  // ////("Added Items", addedItems);

  const handleCreatePackaging = (data) => {
    data = {
      ...data,
      masterPackageId: `${packagingList.length + 1}`,
      itemDetail: objectsInPackage,
    };

    setPackagingList([...packagingList, data]);
    setOpenCreatePackage(false);
    setPackageDialogType(null);
    setObjectsInPackage([]);
  };

  const handleSavePackaging = (data, i) => {
    let oldPkgObjects = packagingList[i].itemDetail;
    let currentPkgObjects = data.itemDetail.map((it) => {
      let itemKey = it.isItemExist
        ? "itemId"
        : it.isPackageExist
          ? "packageId"
          : "shipmentId";
      return it[itemKey];
    });
    let excludedObjects = oldPkgObjects
      .filter((o) => {
        let itemKey = o.isItemExist
          ? "itemId"
          : o.isPackageExist
            ? "packageId"
            : "shipmentId";
        if (!currentPkgObjects.includes(o[itemKey])) {
          return true;
        }
        return false;
      })
      .map((it) => {
        let itemKey = it.isItemExist
          ? "itemId"
          : it.isPackageExist
            ? "packageId"
            : "shipmentId";
        return it[itemKey];
      });

    let oldAdded = JSON.parse(JSON.stringify(addedItems));
    oldAdded = oldAdded.filter((od) => {
      let itemKey = od.isItemExist
        ? "itemId"
        : od.isPackageExist
          ? "packageId"
          : "shipmentId";
      if (!excludedObjects.includes(od[itemKey])) {
        return true;
      }
      return false;
    });
    setAddedItems(oldAdded);

    let oldP = JSON.parse(JSON.stringify(packagingList));
    oldP[i] = data;
    setPackagingList(oldP);
    setOpenCreatePackage(false);
    setPackageDialogType(null);
    setObjectsInPackage([]);
  };

  const removeObjectFromAdded = (objectToRemove) => {
    // Find in addedItems
    let itemKey = objectToRemove.isItemExist
      ? "itemId"
      : objectToRemove.isPackageExist
        ? "packageId"
        : "shipmentId";
    let itemIndex = addedItems
      .map((ai) => ai[itemKey])
      .indexOf(objectToRemove[itemKey]);

    if (itemIndex >= 0) {
      let oldAdded = JSON.parse(JSON.stringify(addedItems));
      oldAdded.splice(itemIndex, 1);
      setAddedItems(oldAdded);
    }
  };

  const handleRemoveSelectedObject = (i) => {
    let oldS = JSON.parse(JSON.stringify(objectsInPackage));
    let objectToRemove = oldS[i];

    removeObjectFromAdded(objectToRemove);

    oldS.splice(i, 1);
    setObjectsInPackage(oldS);
    if (oldS.length == 0) {
      setOpenCreatePackage(false);
      setPackageDialogType(null);
      setPackageEditIndex(null);
    }
  };

  const handleDeletePackaging = (i) => {
    let oldPackaging = [...packagingList];

    let excludedObjects = oldPackaging[i].itemDetail.map((it) => {
      let itemKey = it.isItemExist
        ? "itemId"
        : it.isPackageExist
          ? "packageId"
          : "shipmentId";
      return it[itemKey];
    });

    let oldAdded = JSON.parse(JSON.stringify(addedItems));
    oldAdded = oldAdded.filter((od) => {
      let itemKey = od.isItemExist
        ? "itemId"
        : od.isPackageExist
          ? "packageId"
          : "shipmentId";
      if (!excludedObjects.includes(od[itemKey])) {
        return true;
      }
      return false;
    });
    setAddedItems(oldAdded);

    oldPackaging.splice(i, 1);
    oldPackaging = oldPackaging.map((pkg, i) => ({
      ...pkg,
      masterPackageId: i + 1,
    }));
    setPackagingList(oldPackaging);
  };
  // ////("Selected Item", selectedData);

  const getRemainingCount = () => {
    return selectedData.isItemExist
      ? selectedData.balance_I_Count
      : selectedData.isPackageExist
        ? selectedData.balance_P_Count
        : selectedData.balance_S_Count
  }

  const handleCountChange = (countValue) => {
    let oldSelectedData = JSON.parse(JSON.stringify(selectedData));
    if (countValue <= 0) {
      oldSelectedData.count = 0
    }
    else {
      const remaining = getRemainingCount();
      oldSelectedData.count = Math.min(remaining, countValue)
    }
    setSelectedData(oldSelectedData);
  };

  const validToAdd = () => { };

  const selectAllHandler = (checked = false, type = "package") => {
    let temp_checked = { ...selectedData},
      isAdd = false,
      isItemExist = false;
    if (checked) {
      if (type == "package") {
        temp_checked["checked"] = ["package"];
        let allPackage = packageOptions().filter((dl) => dl.isItemExist); //.map(el=>el.packageId)
        if (allPackage?.length > 0) {
          fetchPackageItem({
            packageId: allPackage.map((el) => el.value),
            dataFetchType: "itemWise",
          });
          isAdd = selectedData.itemId ? true : false;
          isItemExist = true;
          temp_checked["saveAs"] = "single";
        } else {
          isAdd = true;
          isItemExist = false;
          temp_checked["saveAs"] = "multiple";
        }
      } else {
        temp_checked["checked"] = [...(temp_checked["checked"] || []), type];
        temp_checked["saveAs"] = "multiple";
        isItemExist = true;
        isAdd = true;
      }
    } else {
      if (type == "package") {
        temp_checked["checked"] = [];
        isAdd = false;
        isItemExist = ["packageName"].includes(category)
          ? false
          : temp_checked.isItemExist || false;
      } else {
        temp_checked["checked"] = (temp_checked["checked"] || []).filter(
          (dl) => dl != type
        );
        isAdd = false;
        isItemExist = true;
      }
      temp_checked["saveAs"] = "single";
    }

    if (type == "package") {
      delete temp_checked["packageId"];
      delete temp_checked["P_label"];
      delete temp_checked["all_P_Count"];
      delete temp_checked["gateIn_P_Count"];
      delete temp_checked["balance_P_Count"];
    }
    delete temp_checked["I_label"];
    delete temp_checked["itemId"];
    //("temp_checked",temp_checked);
    setSelectedData({ ...temp_checked, isAdd, isItemExist });
  };

  const handleItemAdd = () => {
    //{shipments=[],packages=[],items},setInventoryData
    let inventoryShipment = { ...shipments };
    if (selectedData?.saveAs == "multiple" && selectedData?.checked?.length && Array.isArray(selectedData?.checked)) {
      let tempData = [],
        isInvalid = true;
      if (selectedData.checked.includes("item")) {
        isInvalid = false;
        tempData = itemOptions().map((option) => {
          const { label, value, packageId } = option;
          let valid_count = getValidCount_For_SPI(option, "item");
          let packageInfo = {};
          if (packageId)
            packageInfo =
              packages.find((dl) => dl.packageId == packageId) || {};
          let temp_data = {
            ...selectedData,
            ...packageInfo,
            ...option,
            isAdd: true,
            itemId: value,
            I_label: label,
            count: valid_count,
            isItemExist: true,
            isCountOnly: true,
          };
          return temp_data;
        });
      }
      let remaining_PKG = packageOptions().filter(
        (pkg_info) => !pkg_info.isItemExist
      );
      // let total_count=0,balance_count=0,gateIn_count=0
      if (
        selectedData.checked.includes("package") && remaining_PKG?.length > 0
      ) {
        isInvalid = false;
        let tempArr = remaining_PKG.map((option) => {
          const { label, value } = option;
          let valid_count = getValidCount_For_SPI(option, "package");
          let temp_data = {
            ...selectedData,
            ...option,
            packageId: value,
            P_label: label,
            isAdd: true,
            isCountOnly: true,
            isPackageExist: true,
            count: valid_count,
            isItemExist: false,
          };

          // total_count=total_count+Number(option?.all_P_Count || 0)
          // balance_count=balance_count+Number(option?.balance_P_Count || 0)
          // gateIn_count=gateIn_count+Number(option?.gateIn_P_Count || 0)

          return temp_data;
        });
        if (tempArr?.length > 0) {
          tempData.push(...tempArr);
        }
      }

      if (isInvalid) {
        showToast("Not able to add data!", true);
        return;
      }
      // ////("tempData",tempData);
      setAddedItems((o) => [...tempData, ...o]);
    } 
    else {
      if (selectedData.count <= 0) {
        showToast("Count cannot be 0", true);
        return;
      }
      setAddedItems((o) => [selectedData, ...o]);
      if (isTypePickingAndPackaging) {
        setObjectsInPackage([...objectsInPackage, selectedData]);
      }
    }
    setSelectedData(initial);
  };

  const DynamicFields = (
    <Grid container spacing={2} flexDirection={"row"}>
      {!["packageName"].includes(category) && (
        <Grid container item sm={3}>
          <GenericDropdown
            options={shipmentOptions()}
            option={{ label: selectedData.S_label || "" }}
            handleSelect={(e, option) => handleShipmentSelect(e, option)}
            disabled={listDropDown}
            id="shipmentId-select"
            size="small"
            key="shipmentId-select_gateOut"
            label="Select Shipment"
            noOptionsText={isDataLoader ? "Loading..." : "No Shipment found"}
          />
          {/* <Autocomplete
            fullWidth
            id="shipmentId-select"
            size="small"
            noOptionsText={isDataLoader ? "Loading..." : "No Shipment found"}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Shipment ID"
                sx={{
                  ...selectStylesOverride,

                }}
              />
            )}
            disabled={listDropDown}
            // getOptionLabel={(option) => option.label}
            // sx={{ flexBasis: "30%" }}
            options={shipmentOptions()}
            onChange={handleShipmentSelect}
            // getOptionDisabled={(option) => diableShipment({...option,shipmentId:option.value})}
            value={selectedData.S_label || ""}
            // isOptionEqualToValue={(option, value) => {
            // 	return option.label === value.label;
            // }}
            key="shipmentId-select_gateOut"
          /> */}
        </Grid>
      )}
      {(selectedData?.isPackageExist || ["packageName"].includes(category)) && (
        <Grid container item sm={3} justifyContent="end">
          <GenericDropdown
            options={packageOptions()}
            option={{ label: selectedData.P_label || "" }}
            handleSelect={(e, option) => handlePackageSelect(e, option, false)}
            disabled={listDropDown || (selectedData?.checked || []).includes("package")}
            id="packageId-select"
            size="small"
            label="Select Package"
            key="packageId-select_gateOut"
            noOptionsText={isDataLoader ? "Loading..." : "No Package found"}
          />
          {/* <Autocomplete
            fullWidth
            id="packageId-select"
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Package"
                sx={{
                  ...selectStylesOverride,
                }}
              />
            )}
            noOptionsText={isDataLoader ? "Loading..." : "No Package found"}
            disabled={listDropDown || (selectedData?.checked || []).includes("package")}
            // getOptionLabel={(option) => option.label}

            options={packageOptions()}
            onChange={(e, option) => handlePackageSelect(e, option, false)}
            // getOptionDisabled={(option) => diablePackage({...option,packageId:option.value})}
            value={selectedData.P_label || ""}
            // isOptionEqualToValue={(option, value) => {
            // 	return option.label === value.label;
            // }}
            // inputValue={selectedData.P_label || ""}
            // isOptionEqualToValue={(option, value) => {
            // 	return option.label === value.label;
            // }}
            key="packageId-select_gateOut"
          /> */}
          {!isTypePickingAndPackaging && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={(selectedData?.checked || []).includes("package")}
                  onChange={(e) =>
                    selectAllHandler(e.target.checked, "package")
                  }
                  color="primary"
                  inputProps={{ "aria-label": "controlled-checkbox" }}
                  variant="contained"
                  sx={{
                    "&:hover": {
                      color: theme.themeOrange,
                    },
                  }}
                  disabled={packageOptions()?.length == 0}
                />
              }
              label={
                <Typography style={{ color: theme.themeOrange }}>
                  All Package
                </Typography>
              }
              labelPlacement="start"
            />
          )}
        </Grid>
      )}
      {selectedData?.isItemExist && (
        <Grid container item sm={3} justifyContent="end">
          <GenericDropdown
            options={itemOptions()}
            option={{ label: selectedData.I_label || "" }}
            handleSelect={(e, option) => handleItemSelect(e, option, false)}
            disabled={(selectedData?.checked || []).includes("item")}
            id="itemId-select"
            size="small"
            key="itemId-select_gateOut"
            label="Select Item"
            noOptionsText={isDataLoader ? "Loading..." : "No Item found"}
          />
          {/* <Autocomplete
            fullWidth
            id="itemId-select"
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                // type="search"
                label="Select Item"
                sx={{
                  ...selectStylesOverride,
                }}
              />
            )}
            noOptionsText={isDataLoader ? "Loading..." : "No Item found"}
            disabled={(selectedData?.checked || []).includes("item")}
            // getOptionLabel={(option) => option.label}
            options={itemOptions()}
            onChange={(e, option) => handleItemSelect(e, option, false)}
            // getOptionDisabled={(option) => diableItem({ItemId:option?.value || ""})}
            value={selectedData.I_label || ""}
            // inputValue={selectedData.I_label || ""}
            // isOptionEqualToValue={(option, value) => {
            // 	return option.label === value.label;
            // }}
            key="itemId-select_gateOut"
          /> */}
          {!isTypePickingAndPackaging && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={(selectedData?.checked || []).includes("item")}
                  onChange={(e) => selectAllHandler(e.target.checked, "item")}
                  color="primary"
                  inputProps={{ "aria-label": "controlled-checkbox" }}
                  variant="contained"
                  sx={{
                    "&:hover": {
                      color: theme.themeOrange,
                    },
                  }}
                  disabled={itemOptions()?.length == 0}
                />
              }
              label={
                <Typography style={{ color: theme.themeOrange }}>
                  All Item
                </Typography>
              }
              labelPlacement="start"
            />
          )}
        </Grid>
      )}
      <Grid container item sm={3} justifyContent="center">
        <Box sx={{ display: "flex" }}>
          {isTypePickingAndPackaging && (
            <GenericSingleRangeComponent
              startRange={!selectedData || !selectedData.isAdd ? 0:selectedData?.count || null}
              endRange={getRemainingCount() || 0}
              getOnChange={(countValue) => handleCountChange(countValue)}
              endRangeInfinity={false}
              disabled={!selectedData || !selectedData.isAdd}
              valueType={"decimal"}
            />
          )}
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                ml: 2,
                backgroundColor: theme.themeOrange,
                "&:hover": {
                  backgroundColor: theme.themeOrange,
                },
              }}
              onClick={handleItemAdd}
              disabled={!selectedData || !selectedData.isAdd}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    let condition = ["packageName"].includes(category)
    if (condition && (isTypeGateIn || isTypeQCInward)) {
      condition = allProductType.length > 0
    }
    else if (condition && (isTypeStockTransfer)) {
      condition = !!warehouseId
    }
    if (condition) {

      fetchPackageItem({
        shipmentId: "",
        packageId: "",
        dataFetchType: "packageWise",
      });
      // let req_payload={
      // 	status,
      // 	projection_for:"job"
      // }

      // if(valid_mappingArea?.length){
      // 	req_payload["mappingArea"]=valid_mappingArea
      // }
      // if(isTypeGateIn || isTypeQCInward){
      // 	req_payload["fetch_for"]="beforeEntry"
      // }
      // else{
      // 	req_payload["fetch_for"]="afterEntry"
      // }
      // req_payload["dataFetchType"]= "packageWise"
      // dispatch(getAllInventory(req_payload, ({ success = false, data = [] }) => {
      // 	setInventoryData({ shipments:[], packages:data, items: [] })
      // }));
    }
  }, [category, dataFilter, filterValue, allProductType, warehouseId]);

  return (
    <>
      {children && <Grid mt={1}>{children || ""}</Grid>}
      <Grid mt={3}>
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 400 }}>
            {isTypePickingAndPackaging ? "Packaging Details" : "Inventory Details"}
          </Typography>
        </Box>

        {isTypePickingAndPackaging ? (
          <>
            <Grid
              container
              sx={{
                // border: "1px dashed #D9D9D9", p: 3,
                mt: 2,
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                p: 3,
              }}
            >
              {packagingList.length > 0 && (
                <PackageObjectsSlider
                  objects={packagingList}
                  slideIndex={slideIndex}
                  setSlideIndex={setSlideIndex}
                  handleOpenEditModal={(i) => {
                    setPackageEditIndex(i);
                    setPackageDialogType("edit");
                    setOpenCreatePackage(true);
                  }}
                  handleDeleteObject={handleDeletePackaging}
                />
              )}
              {/* getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key",data),
          getLabelKeyForJobConfig(jobProcessConfig, "package", "key",data),
          getLabelKeyForJobConfig(jobProcessConfig, "item", "key",data), */}
              <GenericCollapsableItemList
                items={objectsInPackage}
                limit={3}
                itemSpacing={{ sm: 3, xs: 4 }}
                handleRemoveItem={handleRemoveSelectedObject}
                getContent={(itemList, i) => {
                  let itemObj = itemList[i];
                  // let itemKey = itemObj.isItemExist
                  //   ? getLabelKeyForJobConfig(
                  //       jobProcessConfig,
                  //       "item",
                  //       "key"
                  //     ) || "NA"
                  //   : itemObj.isPackageExist
                  //   ? getLabelKeyForJobConfig(
                  //       jobProcessConfig,
                  //       "package",
                  //       "key"
                  //     ) || "NA"
                  //   : getLabelKeyForJobConfig(
                  //       jobProcessConfig,
                  //       "shipment",
                  //       "key"
                  //     ) || "NA";
                  // return `${itemObj[itemKey]} (${itemObj.count} qty)`;
                  let labelToView = itemObj.isItemExist
                    ? getLabelKeyForJobConfig(jobProcessConfig, "item", "key", itemObj, ["gateInTime"]) || "NA"
                    : itemObj.isPackageExist
                      ? getLabelKeyForJobConfig(jobProcessConfig, "package", "key", itemObj, ["gateInTime"]) || "NA"
                      : getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key", itemObj, ["gateInTime"]) || "NA";
                  return <Tooltip title={`${labelToView} (${itemObj.count} qty)`} placement="bottom">
                    <Typography sx={{ ...textClasses.t13n }}>
                      {getShortText(`${labelToView} (${itemObj.count} qty)`, 21)}
                    </Typography>
                  </Tooltip>
                    ;
                }}
                sx={{ mb: 3 }}
              />

              <CreatePackageDialog
                open={openCreatePackage}
                onClose={() => {
                  setOpenCreatePackage(false);
                  setPackageDialogType(null);
                }}
                items={objectsInPackage}
                getLabelKeyForJobConfig={getLabelKeyForJobConfig}
                jobProcessConfig={jobProcessConfig}
                handleRemoveSelection={handleRemoveSelectedObject}
                handleCreatePackaging={handleCreatePackaging}
                dialogType={packageDialogType}
                editIndex={packageEditIndex}
                packageObj={
                  packageDialogType == "edit"
                    ? packagingList[packageEditIndex]
                    : null
                }
                handleSavePackaging={handleSavePackaging}
              />
              {DynamicFields}
              <Grid container sx={{ mt: 2 }}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    backgroundColor:
                      objectsInPackage.length < 1 ? "#D9D9D9" : "black",
                    minWidth: "150px",
                    minHeight: "40px",
                  }}
                  disabled={objectsInPackage.length < 1}
                  onClick={() => {
                    setOpenCreatePackage(true);
                    setPackageDialogType("create");
                  }}
                >
                  Create Package
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>{DynamicFields}</>
        )}

        {(isTypePickingAndPackaging && packagingList.length > 0) ||
          (!isTypePickingAndPackaging && addedItems.length > 0) ? (
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "2em",
              maxWidth: "100%",
              // width: "60%",
              maxHeight: "60vh",
              overflowX: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroungColor: theme.themeGray }}>
                <TableRow sx={{ backgroundColor: theme.themeGray }}>
                  {/* <TableCell sx={{fontSize: "10px", textAlign: "center"}}>
                        <Checkbox size='small'/>
                    </TableCell> */}
                  {header.map((head, i) => {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          textAlign: "center",
                          fontWeight: 600,
                          // borderLeft: "1px solid #a3a3a3",
                          // '& .MuiTableCell-root':{
                          // }
                        }}
                        key={i}
                      >
                        {head}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  maxHeight: "60%",
                  overflow: "auto",
                }}
              >
                {rows.length > 0
                  ? rows.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        // "&:last-child td, &:last-child th": {
                        // 	border: 0,
                        // },
                        "& .MuiTableCell-root": {
                          // borderLeft:
                          // "1px solid rgba(224, 224, 224, 1)",
                        },
                      }}
                    >
                      {row.map((col, j) => {
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              overflowWrap: "break-word",
                            }}
                            key={j}
                          >
                            {col}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </>
  );
};

const StockTranferElement = ({
  allWarehouse = [],
  allUser = [],
  stockTransferDetail,
  setStockTransferDetail,
  floorOptions,
  jobType
}) => {
  let stockTransferOption = [
    {
      label: `Other Warehouse ( ${allWarehouse.length} )`,
      value: "otherWarehouse",
    },
    { label: `Internal User ( ${allUser.length} )`, value: "internalUser" },
    { label: "External User", value: "externalUser" },
    { label: "Internal Consumption", value: "internalConsumption" },

  ];

  return (
    <>
      <Grid mt={3}>
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Stock Transfer Details
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
          <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%", mb: 2 }}>
            <Autocomplete
              id="stock-transfer-from-warehouse"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transfer From * (Warehouse)"
                  sx={{
                    ...selectStylesOverride,
                  }}
                />
              )}
              sx={{ flexBasis: "50%", mb: 2 }}
              options={allWarehouse}
              onChange={(e, option) =>
                setStockTransferDetail({
                  ...StockTranferState,
                  transferFromWarehouse: option,
                })
              }
              value={stockTransferDetail?.transferFromWarehouse?.label || ""}
            />
            <Autocomplete
              id="stock-transfer-to-category"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Transfer To*"
                  sx={{
                    ...selectStylesOverride,
                  }}
                />
              )}
              disabled={!stockTransferDetail?.transferFromWarehouse?.value}
              sx={{ flexBasis: "50%", mb: 2 }}
              options={stockTransferOption}
              onChange={(e, option) =>
                setStockTransferDetail({
                  ...StockTranferState,
                  transferFromWarehouse:
                    stockTransferDetail?.transferFromWarehouse || {},
                  transferToCategory: option,
                })
              }
              value={stockTransferDetail?.transferToCategory?.label || ""}
            />
          </Box>
          {stockTransferDetail?.transferToCategory?.value == "otherWarehouse" ? (
            <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%" }}>
              <Autocomplete
                id="stock-transfer-to-warehouse"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Warehouse*"
                    sx={{
                      ...selectStylesOverride,
                    }}
                  />
                )}
                sx={{ flexBasis: "49%", mb: 2 }}
                options={allWarehouse}
                getOptionDisabled={(option) =>
                  option?.value ==
                  stockTransferDetail?.transferFromWarehouse?.value
                }
                onChange={(e, option) =>
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    transferToWarehouse: option,
                    transferToFloor: {},
                  })
                }
                value={stockTransferDetail?.transferToWarehouse?.label || ""}
              />

              <Autocomplete
                id="stock-transfer-to-floor"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Floor*"
                    sx={{
                      ...selectStylesOverride,
                    }}
                  />
                )}
                sx={{ flexBasis: "49%", mb: 2 }}
                options={floorOptions(
                  stockTransferDetail?.transferToWarehouse?.value || ""
                )}
                onChange={(e, option) =>
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    transferToFloor: option,
                  })
                }
                value={stockTransferDetail?.transferToFloor?.label || ""}
              />
            </Box>
          ) : (
            ""
          )}
          {stockTransferDetail?.transferToCategory?.value == "internalUser" ? (
            <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%" }}>
              <Autocomplete
                id="stock-transfer-to-user"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User*"
                    sx={{
                      ...selectStylesOverride,
                    }}
                  />
                )}
                sx={{ flexBasis: "49%", mb: 2 }}
                options={allUser}
                onChange={(e, option) =>
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    transferToInternalUser: option,
                  })
                }
                value={stockTransferDetail?.transferToInternalUser?.label || ""}
              />
            </Box>
          ) : (
            ""
          )}

          {stockTransferDetail?.transferToCategory?.value == "externalUser" ? (
            <Box sx={{ display: "flex", gap: "2em", flexBasis: "100%" }}>
              <TextField
                id="outlined-adornment-Rows"
                label="Enter User Name*"
                type="text"
                size="small"
                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                value={stockTransferDetail?.name || ""}
                onChange={(event) =>
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    name: event.target.value,
                  })
                }
              />
              <TextField
                id="outlined-adornment-Rows"
                label="Enter User Email*"
                type="email"
                size="small"
                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                value={stockTransferDetail?.email || ""}
                onChange={(event) =>
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    email: event.target.value,
                  })
                }
              />
              <TextField
                id="outlined-adornment-Rows"
                label="Enter User Contact Number*"
                type="number"
                inputProps={{
                  pattern: "[0-9]*", // Only allow numbers
                }}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  } else if (
                    e.key == "0" &&
                    (e.target.value == "" || e.target.value[0] == "0")
                  ) {
                    e.preventDefault();
                  }
                }}
                size="small"
                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                value={stockTransferDetail?.mobile || ""}
                onChange={(event) => {
                  if (event.target.value.length > 10) {
                    return;
                  }
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    mobile: event.target.value,
                  });
                }}
              />
              <TextField
                id="outlined-adornment-Rows"
                label="User Address"
                type="text"
                sx={{ flexBasis: "50%", ...selectStylesOverride }}
                size="small"
                value={stockTransferDetail?.address || ""}
                onChange={(event) =>
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    address: event.target.value,
                  })
                }
              />
            </Box>
          ) : (
            ""
          )}
          {stockTransferDetail?.transferToCategory?.value == "internalConsumption" ? (
            <Grid item sm={5.9}>
              <TypeAndSearch
                data-testid="select-reason"
                component={reasonComponent("stockTransfer")}
                setEmptyOnChange={false}
                value={stockTransferDetail?.reason?.value}
                label={stockTransferDetail?.reason?.label}
                handleChange={(newValue, option) => {
                  setStockTransferDetail({
                    ...stockTransferDetail,
                    reason: option,
                  })
                }}
                reasonType={"stockTransfer"}
                addNewOptions={{ isEnabled: true, label: 'Add New Reason' }}
              />
            </Grid>
          ) : (
            ""
          )}
        </Box>
      </Grid>
    </>
  );
};

const QuantityWiseElement = ({
  scanningInput,
  setAddedItems,
  addedItems,
  warehouseId,
  dataFilter,
  filterValue,
  inventoryType,
  setInventoryType,
  salesDetail
}) => {
  const initial = {
    SKU_ID: "",
    count: "",
    warehouseId: "",
    inventoryType: inventoryType,
    floorId: "",
    availableCount: "",
    resetField: false,
  };
  const [selectedData, setSelectedData] = useState({...initial});
  const [alreadySelectedItem, setAlreadySelectedItem] = useState("");

  const [showSalesItems, setShowSalesItems] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleClose = useCallback(() => {
    setShowSalesItems(false);
    setSelectedItems([]);
  }, []);

  const handleSubmit = useCallback(() => {
    let newItems = selectedItems.map((it) => {
      let res = {
        "_id": it._id,
        "SKU_ID": it.product_ID,
        "count": it.filledCount,
        "warehouseId": "",
        "inventoryType": it.dataEntryType,
        "floorId": "",
        "availableCount": it.availableCount,
        "resetField": false,
        "Sales_Id": it.Sales_Id
      }
      return res
    })
    setAddedItems((prev) => [...newItems, ...prev]);
    setShowSalesItems(false);
  }, [selectedItems]);

  const handleChangeSKU = useCallback(
    (option, newValue) => {
      setSelectedData((prev) => ({
        ...prev,
        SKU_ID: option,
        availableCount: newValue?.count,
        count: newValue.count,
      }));
    },
    [setSelectedData]
  );

  let header;
  if (salesDetail && salesDetail.length > 0) {
    header = ["Sr. No", "SKU", "Inventory Type", "Sales Id", "Count", "Delete"];
  }
  else {
    header = ["Sr. No", "SKU", "Inventory Type", "Count", "Delete"];
  }
  // const header = ["Sr. No", "SKU", "Inventory Type", "Count", "Delete"];

  const handleRowDelete = (indx) => {
    setAddedItems((o) => o.filter((_, i) => i !== indx));
  };

  const countHandler = (countValue = "", i, available_count = 0) => {
    let temp_added = [...addedItems];
    if (countValue <= 0) {
      temp_added[i].count = 0
    }
    else {
      temp_added[i].count = Math.min(available_count, countValue)
    }
    setAddedItems(temp_added);
  };

  const rows = addedItems.map((data, indx) => {
    let row = [];
    const { cellId, SKU_ID, inventoryType, count, availableCount, Sales_Id } = data;

    row.push(indx + 1, SKU_ID, inventoryType)

    if (salesDetail && salesDetail.length > 0) {
      row.push(Sales_Id)
    }
    row.push(count, [
      <IconButton
        aria-label="delete"
        key={indx}
        onClick={() => handleRowDelete(indx)}
      >
        <Delete sx={{ color: "red" }} />
      </IconButton>,
    ]);


    if (availableCount >= count) {
      row.splice(
        (salesDetail && salesDetail.length > 0) ? 4 : 3,
        1,
        <GenericSingleRangeComponent
          startRange={count || null}
          endRange={availableCount || 0}
          getOnChange={(countValue) => countHandler(countValue,indx, availableCount)}
          endRangeInfinity={false}
          disabled={!availableCount}
          valueType={"decimal"}
        />,
      );
    }

    return row;
  });

  const component = {
    _id: "categoryKey",
    type: "select",
    disabled: false,
    label: "Select SKU",
    placeholder: "Select SKU",
    description: "Select SKU",
    required: true,
    dynamic: true,
    auth: true,
    apiDomain: "/v1/",
    apiEndPoint: "inventory/getDistinctInventorySKU",
    apiMethod: "post",
    fetched: false,
    name: "Item Master List",
    apiDataReturnKey: "data",
    multipleAPICall: true,
    dontFetch:false,
    apiParameters: [
      {
        label: "type",
        value: `${selectedData?.inventoryType}`,
      },
      {
        label: "searchValue",
        value: "$self",
      },
      {
        label: "page",
        value: 1,
      },
      {
        label: "limit",
        value: 25,
      },
      {
        label: "warehouseId",
        value: warehouseId,
      },
      dataFilter && {
        label: "filterType",
        value: dataFilter?.value,
      },
      dataFilter &&
      filterValue && {
        label: "filterValue",
        value: filterValue.every((value) => value?.isValid()) && filterValue?.map(value=>value?.$d?.toISOString()) || [],
        // value: filterValue.some((value) => value?.isValid()) && filterValue?.filter(value=>value)?.map(value=>value.$d.toISOString()) || [],
      },
    ],
    apiBindingKey: "SKU_ID",
    apiBindingValue: "SKU_ID",
    suffix: "",
    extraValue: [
      { label: "count", value: "count" },
      { label: "SKU_Detail", value: "SKU_Detail" },
    ],
    "renderOption": [
      {
        "label": "",
        "multiple": false,
        "prefix": "",
        "postfix": "",
        "key": "SKU_ID",
        "keys": [

        ]
      },
      {
        "label": "",
        "multiple": false,
        "prefix": " (",
        "postfix": ")",
        "key": "SKU_Detail"
      }
    ],
    subtype: "single",
    forType: "all",
    elementType: "Selection Type",
    sortType: 2,
  };

  const handleCountChange = (countValue,limit) => {
    let oldSelectedData = {...selectedData};
    if (countValue <= 0) {
      oldSelectedData.count = 0
    }
    else {
      oldSelectedData.count = Math.min(limit || 0, countValue)
    }
    setSelectedData(oldSelectedData);
  };

  const handleItemAdd = useCallback(() => {
    setAddedItems((prev) => [selectedData, ...prev]);
    const initialData = { ...initial, SKU_ID: "" };
    setSelectedData(initialData);
  }, [selectedData, setAddedItems, initial]);

  const isTypeAndSearchDisabled = useMemo(() => {
    return dataFilter
      ? filterValue.some((value) => value === null || !value?.isValid())
      : false;
  },[dataFilter,filterValue]);

  const handleEntityTypeChange = useCallback(
    (value) => {
      setSelectedData((prev) => ({ ...prev, inventoryType: value,count:0,availableCount:0 }));
      setInventoryType(value);
    },
    [setInventoryType, setSelectedData]
  );

  return (
    <>
      <Grid mt={3}>
        <Box>
          <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
            Inventory Details
          </Typography>
        </Box>
        {
          (salesDetail && salesDetail.length > 0)
            ?
            <>
              <SelectSalesItems
                open={showSalesItems}
                onClose={handleClose}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
                onSubmit={handleSubmit}
                salesDetail={salesDetail}
                addedItems={addedItems}
                warehouseId={warehouseId}
              />
              <Box
                sx={{ display: "flex", flexDirection: "row", mb: 1, gap: "0.5rem" }}
              >
                <Button
                  disabled={!scanningInput}
                  variant="contained"
                  size="small"
                  sx={{
                    mt: 1,
                    backgroundColor: theme.themeBlack,
                    "&:hover": {
                      backgroundColor: theme.themeBlack,
                    },
                  }}
                  onClick={() => setShowSalesItems(true)}
                >
                  Select Inventory & Allocate Stock
                </Button>
              </Box>
            </>
            :
            <>

              <Box
                sx={{ display: "flex", flexDirection: "row", mb: 1, gap: "0.5rem" }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, alignSelf: "center" }}
                >
                  Inventory Type :
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectedData?.inventoryType}
                  onChange={(e) => {
                    handleEntityTypeChange(e.target.value);
                  }}
                >
                  <FormControlLabel
                    sx={{
                      color: "rgb(128, 128, 128)",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                      },
                    }}
                    value="package"
                    label="Package"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&, &.Mui-checked": {
                            color: "#FF7200",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{
                      color: "rgb(128, 128, 128)",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                      },
                    }}
                    value="item"
                    label="Item"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&, &.Mui-checked": {
                            color: "#FF7200",
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Grid xs={4}>
                  <TypeAndSearch
                    component={deepCopy_Object(component)}
                    disabled={isTypeAndSearchDisabled}
                    // value={selectedData?.SKU_ID || ''}
                    label={selectedData?.SKU_ID || ''}
                    setEmptyOnChange={false}
                    handleChange={(option, newValue) => {
                      setAlreadySelectedItem("");
                      if (!option.target.value) {
                        setSelectedData((prev) => ({
                          ...prev,
                          count: "",
                          SKU_ID: "",
                          availableCount: "",
                        }));
                      }
                      const isAlreadyExist = addedItems?.find(
                        (data) => data?.SKU_ID === option?.target.value
                      );
                      if (isAlreadyExist) {
                        setAlreadySelectedItem(option.target.value);
                      }
                      if (option.target.value && !isAlreadyExist) {
                        handleChangeSKU(option?.target.value, newValue);
                      }
                    }}
                  />
                </Grid>
                {/* <Grid xs={3}> */}
                {/* <Box> */}
                <GenericSingleRangeComponent
                  startRange={selectedData?.count || null}
                  endRange={selectedData?.availableCount || 0}
                  getOnChange={(countValue) => handleCountChange(countValue,selectedData?.availableCount)}
                  endRangeInfinity={false}
                  disabled={!selectedData || !selectedData.availableCount}
                  valueType={"decimal"}
                />

                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 2,
                    backgroundColor: theme.themeOrange,
                    "&:hover": {
                      backgroundColor: theme.themeOrange,
                    },
                  }}
                  onClick={handleItemAdd}
                  disabled={
                    !selectedData || !selectedData.SKU_ID || !selectedData.count
                  }
                >
                  Add
                </Button>

                {/* </Grid> */}
              </Box>
              {!!alreadySelectedItem && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      alignSelf: "center",
                      fontSize: "12px",
                      mt: "0.5rem",
                      color: "#d11b1b",
                    }}
                  >
                    {`${alreadySelectedItem} has already been selected`}
                  </Typography>
                </Box>
              )}
              {(selectedData?.availableCount>0) && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      alignSelf: "center",
                      fontSize: "14px",
                      mt: "0.5rem",
                      color: theme.themeOrange,
                    }}
                  >
                    Available : {selectedData?.availableCount}
                  </Typography>
                </Box>
              )}
            </>
        }

        {addedItems.length>0 ? (
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "2em",
              maxWidth: "100%",
              // width: "60%",
              overflowX: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ backgroungColor: theme.themeGray }}>
                <TableRow sx={{ backgroundColor: theme.themeGray }}>
                  {header.map((head, i) => {
                    return (
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          textAlign: "center",
                          fontWeight: 600,
                        }}
                        key={i}
                      >
                        {head}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length > 0
                  ? rows.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "& .MuiTableCell-root": {
                          // borderLeft:
                          // "1px solid rgba(224, 224, 224, 1)",
                        },
                      }}
                    >
                      {row.map((col, j) => {
                        return (
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              overflowWrap: "break-word",
                            }}
                            key={j}
                          >
                            {col}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </>
  );
};

const TemplateRender = ({
  templateDetail: {
    visible = false,
    templateOption = {},
    templateType = "",
    title = "",
    subTitle = "",
    isLoader = false,
  },
  setTemplateDetail,
  formHooks,
}) => {
  const dispatch = useDispatch();
  const [templateList, setTemplateList] = useState([]);

  const dispatchOrderNoGenerator = () => {
    setTemplateDetail((o) => ({ ...o, isLoader: true }));
    dispatch(
      generateDispatchOrderNo(
        { templateId: templateOption?.value || "" },
        ({ success = false, message, data = {} }) => {
          showToast(message, !success);
          if (success) {
            formHooks.setValue("dispatchOrderNo", data);
            setTemplateDetail((o) => ({ ...o, isLoader: false }));
          }
        }
      )
    );
  };

  useEffect(() => {
    if (templateType) {
      dispatch(
        getAllTemplates({ templateType }, (data) => {
          setTemplateList(data || []);
          if (data?.length > 0) {
            setTemplateDetail({
              visible,
              title,
              templateType,
              subTitle,
              templateOption: {
                value: data[0]._id,
                label: data[0].name,
                index: 0,
              },
            });
          }
          formHooks.reset();
        })
      );
    } else {
      setTemplateList([]);
    }
  }, [templateType]);
  //sx={{ border:"1px solid grey" }}
  return (
    <Card sx={{ mt: 4, border: "1px solid grey" }}>
      <CardHeader
        title={
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              {title} Details
              {subTitle && (
                <Typography sx={{ fontSize: "12px", color: "#F77200" }}>
                  ({subTitle})
                </Typography>
              )}
            </Typography>

            {formHooks &&
              templateType == "outwardDataEntry" &&
              templateOption?.value &&
              templateList?.length && (
                <Button
                  variant="contained"
                  size="small"
                  data-testid="DA-btn"
                  sx={{
                    ...buttonClasses.lynkitOrangeEmpty,
                    ml: 2,
                  }}
                  disabled={isLoader}
                  onClick={() => dispatchOrderNoGenerator()}
                >
                  Generate{" "}
                  {(templateList[templateOption?.index] &&
                    templateList[templateOption?.index].attributes?.find(
                      (dl) => dl._id == "dispatchOrderNo"
                    )?.label) ||
                    "DA Number"}
                </Button>
              )}
          </Box>
        }
        action={
          <Box
            sx={{
              padding: "15px 5px 0 5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Autocomplete
              id="template-label"
              size="small"
              required
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <Typography sx={{ color: theme.themeOrange }}>
                      Select {(title == "Dispatch" && "OutWard") || title}{" "}
                      Template *
                    </Typography>
                  }
                  sx={{
                    ...selectStylesOverride,
                    color: theme.themeOrange,
                  }}
                />
              )}
              sx={{
                width: 250,
                mr: 1,
                "& legend span": {
                  color: theme.themeOrange,
                },
              }}
              options={
                (templateList &&
                  templateList.map((dl, index) => ({
                    value: dl._id,
                    label: dl.name,
                    index,
                  }))) ||
                []
              }
              onChange={(e, option) => {
                setTemplateDetail({
                  visible,
                  title,
                  templateType,
                  subTitle,
                  templateOption: option || {},
                  isLoader: false,
                });
                formHooks.reset();
              }}
              value={templateOption.label || null}
            />
          </Box>
        }
        sx={{ pt: 0, mt: 1, mb: 1, pb: 0 }}
      />
      <CardContent>
        <DynamicFormRender
          formHooks={{
            ...formHooks,
            action: templateType == "qualityCheck" ? "view" : "add",
          }}
          templateId={templateOption?.value || ""}
        />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};
