/* eslint-disable react/react-in-jsx-scope */

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Table2 from "../Asn/Table2";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import { Add, Remove, Search } from "@mui/icons-material";
import theme, { buttonClasses, textClasses} from "../../../../static/styles/theme";
import { getInputFieldForTableRow } from "../../../Components/TemplateInputConfiguration";
// import { convertDocsBase64 } from "../../../../services/functions";
import { handleDynamicChange } from "../../../../services/formFunction";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import DetailDialog from "../Asn/DetailDialog";

const ComponentSection = ({template, errors, dimensions, items, setItems, kitQuantity, validationCallback, AddRow}) => {
    const dispatch = useDispatch();
    const { reset } = useForm();

    // const masterType = (() => {
    //     if(template){
    //         if(template.connectedMaster && template.connectedMaster.itemMaster){
    //             return "itemMaster";
    //         }
    //     }
    //     return null;
    // })();
    const [dialogData, setDialogData] = useState(null);
    // const [errors, setErrors] = useState({})

    const allUom = useSelector(state => state.uom.allUom);
    // //console.log(template)
    //
    const allFields = template.otherTemplate && template?.otherTemplate?.length ? template.otherTemplate?.at(0)?.attributes : [];
    // //console.log(allFields,"allllll")
    // let uomIndex = allFields.map(f => f._id).indexOf("UOM");
    // if(uomIndex >= 0){  
    //     allFields[uomIndex].values = allUom.map(u => ({
    //         label: u.unitName,
    //         value: u.unitValue
    //     }))
    // }
    // else{
    //     let field = {
    //         "_id": "UOM",
    //         "type": "select",
    //         "label": "Unit Of Measurement (UOM)",
    //         "description": "unit of measurements",
    //         "placeholder": "select Unit",
    //         "values": allUom.map(u => ({
    //             label: u.unitName,
    //             value: u.unitValue
    //         })),
    //         "required": true,
    //         "forType": "all",
    //         "elementType": "Selection Type",
    //         "sortType": 2,
    //     }
    //     allFields.push(field);
    // }
    // const mandatoryFieldsId = useSelector(state => state.canvas.components.itemTemplate.mandatory).filter(
    //     (f) => f.templateType == template.templateType
    // ).map(f => f._id);
    // const mandatoryFields = allFields.filter(f => mandatoryFieldsId.indexOf(f._id) >= 0);
    // const otherFields = allFields.filter(f => mandatoryFieldsId.indexOf(f._id) < 0)
    // //("Other Fields", otherFields);
    const autoFillOnFocus = (index) => {
        // if(dimensions){
        //     let oldItems = [...items];
        //     for (let i = 0; i < otherFields.length; i++) {
        //         let compId = otherFields[i]._id
        //         if(["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]){
        //             oldItems[index][compId] = dimensions[compId];
        //         }
        //     }
        //     setItems(oldItems)
        // }
    }

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleGenericChange = async (event, i, fieldId,option={}) => {
        const targetField = allFields.find(f => f._id == fieldId);
     
        let oldItems = [...items];
        let oldObject = {...oldItems[i]};
        if(targetField.type == "file"){
            const file = await convertDocsBase64(event.target.files[0]);
            oldObject[fieldId] = file;
        }
        else if(targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if(event.checked){
                oldValues.push(event.value);
            }
            else{
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else{
            oldObject[fieldId] = event.target.value;
        }

        if (targetField?.extraValue?.length) {
            targetField.extraValue.map(dl => {
                if (typeof dl == "string") {
                    oldObject[dl] = option[dl] || ""
                }
                else if (typeof dl == "object" && dl.label) {
                    oldObject[dl.label] = option[dl.label] || ""
                }
            })
        }
        
        if(["select"].includes(targetField.type) && targetField.setTargetValue){
            // rowElement,item,option={},storeDetailIn=""
            changeLoader(i, true)
            oldObject["loader"]=true
            await handleDynamicChange(oldObject,targetField,{value:event.target.value || ""},"itemDetail")
            oldObject["loader"]=false
            // //console.log("oldObject1111",oldObject);
        }
        oldItems[i] = {...oldObject};
        // console.log("oldItems",{event,i,fieldId,oldItems});
        setItems([...oldItems]);
    }

    // console.log(allFields, "show all fields")
    const getTableHeader = (headerType) => {
        let base = ["Per Kit QTY", "Item Details"]
        base.push("Detail")
        // if(masterType == "itemMaster"){
        // }
        //console.log(allFields.filter(f => ["product_ID", "quantity"].indexOf(f._id) < 0), "show fields")
        let otherHeader = allFields.filter(f => ["product_ID", "quantity"].indexOf(f._id) < 0).map((of) => {
            let label = of.label;
            if(headerType == "excel" && ["select", "radio", "checkbox"].indexOf(of.type) >= 0){
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            return label
        })
        // console.log(otherHeader, "show other header")
        return [...base, ...otherHeader];
    }

    const getItemRow = (i) => {
        let item = items[i];
        //console.log(item, "show item data to form row")
        const adornment = (
            <InputAdornment position='end'>
                <IconButton
                    // onClick={() => handleSearchButton(i)}
                    disabled={item.product_ID ?item.product_ID.length < 3 :"" || item.itemDetail != null}
                >
                    <Search/>
                </IconButton>
            </InputAdornment>
        )
        
        let disabled=null
        let base = [
            (
                <Typography sx={{...textClasses.t13n}}>
                    {item.quantity || ""}
                </Typography>
            ),
            getInputFieldForTableRow({...allFields.find(f => f._id == "product_ID") || {},disabled:item?.disabled?.includes("product_ID"),dontFetch:true}, items,  i, handleGenericChange, autoFillOnFocus, errors, false, adornment ,disabled )
        ]
        // if(masterType == "itemMaster"){
            base.push(
                item.loader
                    ?
                        <GenericLoader/>
                    :
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange,cursor:"pointer"}} onClick={() => handleOpenDetail(i)}>
                            {
                                item.itemDetail != null
                                ?
                                    "View Details"
                                :
                                    "No data yet"
                            }

                        </Typography>
            )
        // }
        const others = allFields.filter( f => ["product_ID", "quantity"].indexOf(f._id) < 0).map((of) => {
            let comp = {...of};
            if((comp._id == "UOM" && items[i][comp._id] != null && items[i][comp._id] != "") || (comp._id=="totalQuantity" )){
                comp.dontFetch=true
                comp.disabled = true;
            }
            else if(item?.disabled?.includes(comp._id)){
                comp.disabled = true;
                comp.dontFetch=true
            }
            else{
                comp.disabled = false;
            }
            return (
                getInputFieldForTableRow(comp, items,  i, handleGenericChange, autoFillOnFocus, errors, false)
            )  
        })
        
        return [...base, ...others]
    }

    const header = getTableHeader();
    const rows = items.map((item, i) => {
        //console.log(item, "show item")
        return getItemRow(i);
    })
    

    // console.log(header, "show header ", rows, "show rows njbhv")

    // useEffect(()=>{
    //     handleAddRow()
    // },[])

    // const layout = getFieldsLayoutForEntry(otherFields, register, control, autoFillOnFocus, errors);

    const handleAddRow = () => {
        // let row = {
        //     itemId: "",
        //     itemDetail: null,
        //     quantity: 1,
        //     loader: false
        // }
        // setItems([...items, row])

        let fieldsData = {}
        let fields = allFields.filter(f => f._id != "quantity")
        //console.log(fields, "show fields to show")
        for (let i = 0; i < fields.length; i++) {
            if (fields[i]?.type == "checkbox"){
                fieldsData[fields[i]._id] = []    
            }
            else if (fields[i]?.type == "file"){
                fieldsData[fields[i]._id] = null;
            }
            else{
                fieldsData[fields[i]._id] = ""
            }
        }
        let newRow = {
            ...fieldsData,
            quantity: 1,
            totalQuantity:1*Number(kitQuantity || 0),
            itemDetail: null,
            loader: false,
        }
        setItems([...items, newRow]);
        reset()
    }

    const handleRemoveRow = (i) => {
        let oldItems = [...items];
        oldItems.splice(i, 1)
        setItems(oldItems);
    }

 

    const changeLoader = (i, status) => {
        let oldItems = [...items]
        oldItems[i] = {
            ...oldItems[i],
            loader: status
        }
        setItems(oldItems);
    }

    const handleOpenDetail = (i) => {
        setDialogData(items[i].itemDetail)
    }

    const handleCloseDetail = () => {
        setDialogData(null)   
    }


    return (
        <Grid container sx={{mt: 2}}>
            <Grid item sm={12} xs={10}>
                <Card sx={{borderRadius: "8px"}}>
                    <CardHeader
                        sx={
                            {
                                p: 2,
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray"
                            }
                        }
                    
                        title={
                            <Typography sx={{...textClasses.normalText}}>2. Component Details</Typography>
                        }
                    />
                        <CardContent>
                            <Grid container>
                                
                               {items?.length >0 ? <Table2 
                                    header={header}
                                    rows={rows}
                                    AddRow={AddRow}

                                />: "Kit not selected"}  
                            </Grid>
                        {dialogData?.template_id && <DetailDialog
                            data={dialogData}
                            onClose={handleCloseDetail}
                        />}
                           
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

}

export default ComponentSection;