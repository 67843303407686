import React from "react";
import { Grid, Paper, Tooltip, Typography, } from "@mui/material";
import theme, {
    textClasses
} from "../../../../static/styles/theme";

import { customerMasterFields, dynamicComponent } from "./utils";
import TypeAndSearch from "../../../Components/TypeAndSearch";
import { dataReadFunction, getShortText } from "../../../../services/functions";


export default function cutomerSupplierAdd(props) {
    // console.log(props,"props")
    let {
        connectedCust_Sup_Detail, searchMaster = console.log("Function not passed"),
        type = "customer", filter = [], header = [], isBodyVisible = true, connectedWithAddressBook,
        heading,
    } = props

    let displayHeader = header?.length > 0 ? header : customerMasterFields

    return (<Grid container sx={{ border: `1px solid ${theme.themeGray}`, borderRadius: 3, p: 2, mt: 2, mb: 2 }}>
        <Grid container sx={{ mt: isBodyVisible && 2, mb: isBodyVisible && 2, ml: !isBodyVisible && 2 }}>
            {isBodyVisible && <Grid
                container
                item
                sm={2}
                xs={3}
                alignItems={"center"}
            >
                <Typography sx={{ ...textClasses.normalText }}>{heading || "Customer Detail"} </Typography>
            </Grid>}
            <Grid
                item
                sm={isBodyVisible ? 3 : 5.7}
                xs={4}
                justifyContent="left"
                alignItems={"center"}
            >
                <TypeAndSearch
                    component={dynamicComponent({ type: type.toLowerCase(), filter, connectedWithAddressBook })}
                    value={connectedCust_Sup_Detail?.value || ""}
                    label={connectedCust_Sup_Detail?.label || ""}
                    options={connectedCust_Sup_Detail?.value && [connectedCust_Sup_Detail] || []}
                    set_Value={true}
                    handleChange={(e, option) => {
                        searchMaster(e?.target?.value || "", option);
                    }}
                />
            </Grid>
        </Grid>
        {isBodyVisible &&
            <Grid container>
                {displayHeader &&
                    connectedCust_Sup_Detail?.value && Object.keys(connectedCust_Sup_Detail)?.length > 0 &&
                    displayHeader.filter(
                        (f) => {
                            return dataReadFunction(f, connectedCust_Sup_Detail).toString().length < 25
                        }
                    )
                        .map((f, i) => {
                            return (
                                <Grid
                                    container
                                    item
                                    sm={4}
                                    xs={6}
                                    alignItems="center"
                                    key={i}
                                    sx={{ mb: 2 }}
                                >
                                    <Grid container>
                                        <Grid container item sm={4} xs={6}>
                                            <Typography
                                                sx={{
                                                    ...textClasses.normalText,
                                                    color: "#827878",
                                                }}
                                            >
                                                {f.label} :{" "}
                                            </Typography>
                                        </Grid>
                                        <Grid container item sm={8} xs={6}>
                                            <Typography
                                                sx={{ ...textClasses.boldText, ml: 2 }}
                                            >
                                                {getShortText(dataReadFunction(f, connectedCust_Sup_Detail).toString())}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
            </Grid>}
        <Grid container>
            {displayHeader?.length > 0 &&
                connectedCust_Sup_Detail?.value && Object.keys(connectedCust_Sup_Detail)?.length > 0 &&
                displayHeader
                    .filter(
                        (f) =>
                            dataReadFunction(f, connectedCust_Sup_Detail).toString().length >= 25
                    )
                    .map((f, i) => {
                        return (
                            <Grid
                                container
                                item
                                sm={6}
                                xs={12}
                                alignItems="center"
                                key={i}
                                sx={{ mb: 2 }}
                            >
                                <Grid container>
                                    <Grid container item sm={4} xs={6}>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: "#827878",
                                            }}
                                        >
                                            {f.label} :{" "}
                                        </Typography>
                                    </Grid>
                                    <Grid container item sm={8} xs={6}>
                                        <Tooltip
                                            title={dataReadFunction(f, connectedCust_Sup_Detail)}
                                        >
                                            <Typography
                                                sx={{
                                                    ...textClasses.boldText,
                                                    ml: 2,
                                                }}
                                            >
                                                {getShortText(dataReadFunction(f, connectedCust_Sup_Detail).toString())}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
        </Grid>
    </Grid>
    )
}