import React, { useEffect, useState,useMemo } from "react";
import {
    Grid,
    Button,
    MenuItem,
    Typography,
    Checkbox,
    Paper,
    Menu,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
    cardClasses,
} from "../../../../static/styles/theme"
import {
    Add,
    KeyboardArrowDown,
} from "@mui/icons-material";
import { useDebounce } from "../../../../utils/hooks";
import { dataReadFunction, dateTimeFormater, textCapitalize, showToast, renderHeadersFilter } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import { getAutomationRulesTableData, disableEnableSkuGroupRule } from "../../../../redux/actions/AutomationRule";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import ConfirmSkuGroupDialogPopUp from "./ConfirmSkuGroupDialogPopUp";
import GenericToggle from "../../../Components/GenericToggle";
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg"
import AddIcon from '@mui/icons-material/Add';
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";



let initial_state = {
    "search": {},
    "other": {},
}

const AutomationProcessRules = ({ dataCount, selectedTab }) => {
    const dispatch = useDispatch();
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState(initial_state);
    const [statusFilter, setStatusFilter] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [actionIndex, setActionIndex] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [initialRender, setInitialRender] = useState(true)
    const debouncedFilterSearch = useDebounce(filter?.search, 500);
    // const [tableData, setTableData] = useState(automationTableDataInward || []);
    const [tableData, setTableData] = useState({ data: [], count: 0 });

    const handleToggle = (event, rowData, rowIndex = 0) => {
        let checked = event.target.checked
        let payload = {
            ruleId: [rowData?._id],
            approved: checked ? true : false,
            ruleType: selectedTab
        };
        dispatch(
            disableEnableSkuGroupRule(payload, (data) => {
                showToast(data.message, !data.success);
                if (data) {
                    refreshTableData();
                }
            })
        );
    }

    const isFilterApplied = (() => {
        if (tabIndex == 0 && statusFilter?.value) {
            return true;
        }
        if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(filter["other"]).some(dl => dl && dl.length > 0)) {
            return true;
        }
        return false;
    })();

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    };
    const handleAddSelection = (obj) => {
        setSelectedEntries([...selectedEntries, obj]);
    };

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map((o) => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
            if (oldEntries.length == 0) {
                handleCloseConfirmModal();
            }
        }
    };
    const handleCheckChange = (event, row) => {
        if (event.target.checked) {
            handleAddSelection(row);
        } else {
            handleRemoveSelection(row._id);
        }
    };
    const handleCheckAll = () => {
        if (selectedEntries.length < tableData?.data?.length) {
            let sT = tableData?.data?.map((t) => ({
                _id: t._id,
                ruleName: t.ruleName
            }));
            setSelectedEntries(sT);
        } else {
            setSelectedEntries([]);
        }
    };
    const handleCloseAction = () => {
        setActionConfirmModal(null);
        if (setSelectedEntries) {
            setSelectedEntries([]);
        }
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };
    const handleClearFilter = () => {
        setFilter(initial_state)
        setPage(0);
        setLimit(10);
        if (tabIndex == 0) {
            setStatusFilter(null);
        }
    };
    const tableHeader = useSelector((state) => state.customHeader["automationRuleTable"]);
    let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
        if (h.handleType === "selectRow") {
            return (
                <Checkbox
                    data-testid="checkbox"
                    checked={
                        tableData?.data?.length != 0 &&
                        selectedEntries.length == tableData?.data?.length
                    }
                    onChange={handleCheckAll}
                    size="small"
                />
            )
        }
        return h.fieldName
    })
        || [];

    const getTableCell = (header, row, i) => {

        let value = ""
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "", renderOption: header?.renderOption || [] }, row)
        }

        if (header?.handleType) {
            if (header?.handleType === "selectRow") {
                return (
                    <Checkbox
                        size="small"
                        checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleCheckChange(e, row)}
                    />
                );
            }
            // else if (header?.handleType === "showDetail") {
            //     return (
            //         <Typography
            //             onClick={() => handleRowAndViewClick(row)}
            //             style={{
            //                 ...textClasses.normalText,
            //                 color: theme.themeOrange,
            //                 textAlign: "center",
            //                 cursor: "pointer",
            //             }}
            //         >
            //             {value ?? "-"}
            //         </Typography>
            //     );
            // }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
        }
        else if (header?.isFromRenderFunction === true) {
            let no;
            if (page === 0) {
                no = i + 1;
            } else {
                no = page * 10 + (i + 1);
            }
            if (header?.renderFunction === "srNo") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                        {no}
                    </Typography>
                );
            }
            else if (header?.renderFunction === "status") {
                const statusColor = {
                    enabled: "#3E621E",
                    disabled: "#ff0c64",
                };
                const statusText = {
                    enabled: "Enabled",
                    disabled: "Disabled",
                };
                return (
                    <>
                        {permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "updateInwardOperationRuleStatus"
                            ) ? (
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    textAlign: "center"
                                }}
                            >
                                <GenericToggle
                                    disbledName="'Disable'"
                                    enabledName="'Enable'"
                                    isEnable={row?.approved === true}
                                    handleToggle={(event) => handleToggle(event, row, i)}
                                    name="drop"
                                    width={80}
                                />
                            </Typography>
                        ) : (
                            // Show Typography with status if permission is not available
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                    color: statusColor[row?.approved ? "enabled" : "disabled"],
                                }}
                            >
                                {row?.approved ? statusText["enabled"] : statusText["disabled"]}
                            </Typography>
                        )}
                    </>
                );
            }
        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? dateTimeFormater(value) : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
                    >
                        {textCapitalize(value) ?? "-"}
                    </Typography>
                );
            }
        }
    };

    const tableRows = tableData?.data?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }
        return items;
    });

    const searchableFilterItems = useMemo(() => {
        if (!tableHeader || !tableHeader.validHeader) return [];
        return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
      }, [tableHeader]);

    const refreshTableData = (page, limit) => {
        setLoader(true);
        const payload = {
            page: page,
            limit: limit,
            type:"automation"
            // ruleType: selectedTab
        };
        if (filter && Object.keys(filter).length > 0) {
            for (let key in filter) {
                if (key && Object.keys(filter[key]).length > 0) {
                    Object.assign(payload, filter[key])
                }
            }
            // Process date filter items
            const dateFilterItems = searchableFilterItems?.filter(
                (item) => item?.searchInputType === "date"
            );

            dateFilterItems.forEach((element) => {
                const payloadKey = element?.payloadKey || element?.mappingPath || "";
                if (payloadKey && payload[payloadKey]) {
                    payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
                }
            });

        }
        dispatch(getAutomationRulesTableData(payload, (data = {}) => {
            setLoader(false);
            if (data?.success) {
                setTableData({ ...tableData, data: data?.data, count: data?.count })
            }
        }))
    };
    let fetchData = (fetchCount = false) => {
        refreshTableData(page + 1, limit);
        // setSelectedTemplates([]);
        setConfirmModal(null);
        // setReceiveModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);
        // setViewSkuGroupDetail(false)
    };

    useEffect(() => {

        fetchData(initialRender);
        if (initialRender) {
            setInitialRender(false)
        }

    }, [page, limit, filter.other, debouncedFilterSearch]);


    return (<>
        {
            dataCount > 0 ? loader ? (
                <Grid
                    data-testid="loader"
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ p: 10 }}
                >
                    <Grid item>
                        <GenericLoader />
                    </Grid>
                </Grid>
            ) :
                <>
                    <Grid container spacing={1} xs={12} data-testid="filters">
                        {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setPage, setLimit, tabIndex)}
                        {isFilterApplied && (
                            <Grid container item xs={"auto"} alignItems={"center"}>
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleClearFilter}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        )}
                        <Grid container item xs={"auto"} alignItems={"center"} data-testid="customise-table">
                            <GenericCustomiseHeader
                                tableName={"automationRuleTable"}
                                tableType={"automationRuleTable"}
                            />
                        </Grid>
                    </Grid>
                    {selectedEntries?.length > 0 &&
                        <Grid container sx={{ mt: 2, textTransform: "none" }}>
                            {
                                permissions &&
                                (permissions.permissions || []).some(
                                    (item) => item.permissionId === "updateInwardOperationRuleStatus"
                                ) && selectedEntries?.every(dl => dl?.approved == false) && (
                                    <>
                                        {
                                            // selectedEntries?.every(entry => entry.status === "active") && 
                                            (
                                                <Button
                                                    data-testid="enable-btn"
                                                    size="small"
                                                    variant="contained"
                                                    sx={{
                                                        ...buttonClasses.lynkitOrangeFill,
                                                        mr: 1,
                                                        textTransform: "none",
                                                    }}
                                                    onClick={() => setConfirmModal("enable")}
                                                >
                                                    Enable
                                                </Button>
                                            )}
                                    </>
                                )
                            }
                            {
                                permissions &&
                                (permissions.permissions || []).some(
                                    (item) => item.permissionId === "updateInwardOperationRuleStatus"
                                ) && selectedEntries?.every(dl => dl?.approved == true) && (
                                    <>
                                        {
                                            // selectedEntries?.every(entry => entry.status === "active") && 
                                            (
                                                <Button
                                                    data-testid="disable-btn"
                                                    size="small"
                                                    variant="contained"
                                                    sx={{
                                                        ...buttonClasses.lynkitOrangeFill,
                                                        mr: 1,
                                                        textTransform: "none",
                                                    }}
                                                    onClick={() => setConfirmModal("disable")}
                                                >
                                                    Disable
                                                </Button>
                                            )}
                                    </>
                                )
                            }
                        </Grid>
                    }
                    <Grid container sx={{ mt: 2 }}>

                        <Grid item
                            // sm={viewSkuGroupDetail ? 8 : 12}
                            xs={12}>
                            <Paper>
                                <GenericTable
                                    data-testid="inwardRuleTable"
                                    header={header}
                                    rows={tableRows}
                                    pageCount={tableData?.count}
                                    pageNumber={page}
                                    handleChangePage={(event, pagevalue) => {
                                        setPage(pagevalue);
                                    }}
                                    handleChangeRowsPerPage={(event) => {
                                        setLimit(+event.target.value);
                                    }}
                                    rowsPerPage={limit}
                                />
                            </Paper>
                        </Grid>


                        <ConfirmSkuGroupDialogPopUp
                            status={confirmModal}
                            handleClose={handleCloseConfirmModal}
                            selectedEntries={selectedEntries}
                            setSelectedEntries={setSelectedEntries}
                            refreshTableData={() => {
                                refreshTableData(page + 1, limit)
                                setTabIndex(0)
                            }}
                            handleRemoveSelection={handleRemoveSelection}
                            selectedTab={selectedTab}
                        />
                        <ConfirmSkuGroupDialogPopUp
                            status={actionConfirmModal}
                            handleClose={handleCloseAction}
                            selectedEntries={
                                actionIndex != null ? [tableData?.data[actionIndex]] : []
                            }
                            refreshTableData={() => {
                                refreshTableData(page + 1, limit)
                                setTabIndex(0)
                            }}
                            selectedTab={selectedTab}
                        />

                        {actionIndex != null && (
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={actionIndex != null}
                                onClose={handleCloseAction}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                                sx={{
                                    display: actionIndex != null ? "block !important" : "none !important",
                                }}
                            >
                                {permissions &&
                                    (permissions.permissions || []).some(
                                        (item) => item.permissionId === "updateInwardOperationRuleStatus"
                                    ) && tableData?.data[actionIndex]?.approved == false && (
                                        <MenuItem
                                            key={1}
                                            sx={{ ...textClasses.normalText }}
                                            onClick={() => setActionConfirmModal("enable")}
                                        >
                                            Enable
                                        </MenuItem>
                                    )}
                                {permissions &&
                                    (permissions.permissions || []).some(
                                        (item) => item.permissionId === "updateInwardOperationRuleStatus"
                                    ) && tableData?.data[actionIndex]?.approved == true && (
                                        <MenuItem
                                            key={1}
                                            sx={{ ...textClasses.normalText }}
                                            onClick={() => setActionConfirmModal("disable")}
                                        >
                                            Disable
                                        </MenuItem>
                                    )}
                            </Menu>
                        )}
                    </Grid>
                </>
                :

                <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4, border: `1px solid ${theme.themeGray}`, borderRadius: 2 }}>
                    <img src={SelectImage} />
                    <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>create your first inward operation rule(s)</Typography>
                    <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                        Hey, you need to create a group of previously created SKU(s), apply some placement related rules on SKU groups build a smart space utilization.
                    </Typography>
                    <Button
                        variant="contained"
                        data-testid="create-rule"
                        spacing={1}
                        sx={{
                            ...buttonClasses.lynkitBlackFill,
                            mt: 2,
                            ...textClasses.t14n,
                        }}
                        startIcon={<AddIcon />}
                    >
                        Create Rule
                    </Button>
                </Grid>
        }
    </>)
}

export default AutomationProcessRules;