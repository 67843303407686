import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import {
    Grid, Button, MenuItem, Typography, Tab, Tabs, Box, Checkbox, IconButton, Menu,
    Card, CardHeader, CardContent, Autocomplete,TextField
} from '@mui/material'
// import { makeStyles } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
// import EmptyPage from './EmptyPage';
import { Add, Close, Delete, KeyboardArrowDown } from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, showMessage } from '../../../redux/actions';
import { getAdjustmentStatusCount, getAllAdjustmentReasons,changeInventoryAdjustmentStatus,getAllInventoryAdjustment,  } from '../../../redux/actions/InventoryAdjustment'
import { getAllWarehouses, getWarehouseFloors } from '../../../redux/actions';
import theme, { buttonClasses, cardClasses, textClasses, inputClasses } from '../../../static/styles/theme';
import { Link } from 'react-router-dom';
import { getCamelCaseText, getShortText, showToast } from '../../../services/functions';
import history from '../../../services/history';
import { renderHeadersFilter, dataReadFunction, dateTimeFormater,paginateAfterDelete } from "../../../services/functions"
import GenericLoader from '../../Components/Generic/GenericLoader';
import GenericCustomiseHeader from '../../Components/Generic/GenericCustomiseHeader';
import { useDebounce } from '../../../utils/hooks';
import GenericTable from '../../Components/CustomTable/GenericTable';
import AlertDialog from '../../Components/DialogBox/dialogbox';
import GenericSingleRangeComponent from '../../Components/Generic/GenericSingleRangeComponent';
import { multiDateToISOConvertor } from '../../Components/Generic/GenericMultiDateRange';

const ConfirmDialog = ({ modelVisible, handleClose, checkedRow, setCheckedRow, refreshTableData }) => {
    let tableHeader = ["S.No", "Entity ID", "Ref No.","Action Type","Adjusment Qty","Remaining Qty", "Selected Qty", "Action"]
    const dispatch = useDispatch();

    let [filter, setFilter] = useState({
        page: 1,
        limit: 10,
    });
    let [allRow, setAllRow] = useState([]);
    let [loader, setLoader] = useState(false);


    const handleRemoveChange = (_id) => {
        let tempData = [...allRow]
        let rowIndex = tempData.findIndex(x => x._id === _id)
        if (rowIndex >= 0) {
            tempData.splice(rowIndex, 1)
            // let { page,totalPages,paginatedItems}=paginateAfterDelete(tempData, filter?.page, filter?.limit,rowIndex) || {}
            // setFilter({...filter,page})
        }
        setAllRow(tempData)
    }

    const handleSubmit=()=>{
        let payload={
            isApproved:modelVisible=="Approve",
            adjustmentData:allRow.map(({_id,selectedQuantity=0})=>({adjustmentId:_id,count:Number(selectedQuantity || 0)}))

        }

        setLoader(true)  
        dispatch(changeInventoryAdjustmentStatus(payload,({success=false,message})=>{
            setLoader(false)  
            showToast(message,!success)
            if(success){
                refreshTableData();
                handleClose();
                if (setCheckedRow) {
                    setCheckedRow([]);
                }
            }
        }))
    }

    const handleCountChange = (_id, value) => {
        let tempData = [...allRow]
        let rowIndex = tempData.findIndex(x => x._id === _id)
        // console.log(_id, value, rowIndex)
        if (rowIndex >= 0) {
            tempData[rowIndex]["selectedQuantity"] = value
        }
        // console.log(tempData)
        setAllRow(tempData)
    }
    let tableRows = allRow?.map((row, index) => {

        return [
            (index + 1),
            // ((filter?.page - 1) * filter.limit) + (index + 1),
            row?.categoryKey,
            row.referenceNumber,
            row?.actionType,
            Number(row.quantity || 0),
            Number(row.availableQuantity || 0),
            <Typography sx={{ ...textClasses.normalText, }}>
                <GenericSingleRangeComponent
                    data-testid="rangeSFilter"
                    startRange={Number(row.selectedQuantity || 0)}
                    endRange={Number(row.availableQuantity || 0)}
                    getOnChange={(value = "") => handleCountChange(row?._id, value)}
                    valueType={"decimal"}
                />
            </Typography>,
            <IconButton onClick={() => handleRemoveChange(row?._id)} disabled={checkedRow?.length == 1}><Delete /></IconButton>
        ]
    })

    useEffect(() => {
        setAllRow(checkedRow)
    },[checkedRow])

    return (
        <AlertDialog
            viewDialog={modelVisible}
            showDivider={true}
            handleClose={handleClose}
            sx={{ borderRadius: "50px" }}
            width="70%"
            title={
                <Typography
                    sx={{ fontWeight: "1000", fontSize: "24px" }}
                >
                    {modelVisible} Adjustment
                </Typography>
            }
            body={
                <Box sx={{ width: "inherit" }}>
                    {loader
                        ? <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                            <Grid item >
                                <GenericLoader />
                            </Grid>
                        </Grid>
                        : <GenericTable
                            data-testid="adj-table"
                            header={tableHeader}
                            rows={tableRows}
                            // pageCount={checkedRow?.length || 0}
                            // pageNumber={filter?.page - 1}
                            // handleChangePage={(event, pagevalue) => {
                            //     setFilter({ ...filter, page: pagevalue + 1 });
                            // }}
                            // handleChangeRowsPerPage={(event) => {
                            //     setFilter({ ...filter, limit: +event.target.value });
                            // }}
                            // rowsPerPage={filter?.limit}
                        />
                    }
                </Box>
            }
        >
            <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                    ...buttonClasses.lynkitOrangeEmpty,
                    m: 1,
                    fontSize: ".7rem",
                    minWidth: "100px",
                    display: 'flex !important',
                }}
            >
                No, Cancel
            </Button>
            <Button
                variant="outlined"
                onClick={handleSubmit}
                sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    m: 1,
                    fontSize: ".7rem",
                    minWidth: "100px",
                    display: 'flex !important',
                }}
            >
                Yes, {modelVisible}
            </Button>
        </AlertDialog>
    )
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

let initial_state = {
    "search": {},
    "other": {},
    "page": 1,
    "limit": 10,
}

export default function InventoryAdjustments() {

    const dispatch = useDispatch();
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    const statusCount = useSelector(state => state?.inventoryAdjustment?.statusCount) || {};
    const tableHeader = useSelector((state) => state.customHeader["adjustmentTable"]);
    const allWarehouses = useSelector((state) => state.inward.allWarehouses);
    const allFloors = useSelector((state) => state.inward.allFloors);
    // const allReasons = useSelector(state => state.inventoryAdjustment.adjustmentReasons)

    const [tabIndex, setTabIndex] = useState(0);
    const [filter, setFilter] = useState({ ...initial_state });
    const [initial, setinitial] = useState(true);
    const [loader, setLoader] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [checkedRow, setCheckedRow] = useState([]);
    const [adjustments, setAdjustments] = useState({});
    const [modelVisible, setModelVisible] = useState(null);
    const [warehouseFilter, setWarehouseFilter] = useState(null);
    const [floorFilter, setFloorFilter] = useState(null);
    const debouncedFilterSearch = useDebounce(filter?.search, 500);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);


    const statusList = [
        {
            "label": "Pending Adjustment",
            "value": "pending",
            "color": "#FF7200"
        },
        {
            "label": "Rejected Adjustment",
            "value": "rejected",
            "color": "#FF7200"
        },
        {
            "label": "Partial Adjusted",
            "value": "partialAdjusted",
            "color": "#FF7200"
        },
        {
            "label": "Fully Adjusted",
            "value": "adjusted",
            "color": "#FF7200"
        }
    ];



    const handleSelectedRow = (option) => {
        ////(option)
        setSelectedRow(option);
        handleCloseAction();
    }

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }

    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

    const isFilterApplied = (() => {
        if (tabIndex == 0 && filter?.other?.status) {
            return true;
        }
        let {status,...restOther}= { ...filter["other"] || {} }
        // delete other["status"]
        if (Object.values(restOther || {})?.some(dl => dl && dl.length > 0) || Object.values(filter["search"]).some(dl => dl && dl.length > 0)) {
            return true;
        }
        return false;
    })();

    const handleTabChange = (event, value) => {
        switch (value) {
            case 0: setFilter({ ...filter, other: { ...filter["other"] || {}, status: null }, page: 1, limit: 10 }); break;
            case 1: setFilter({ ...filter, other: { ...filter["other"] || {}, status: "pending" }, page: 1, limit: 10 }); break;
            case 2: setFilter({ ...filter, other: { ...filter["other"] || {}, status: "rejected" }, page: 1, limit: 10 }); break;
            case 3: setFilter({ ...filter, other: { ...filter["other"] || {}, status: "partialAdjusted" }, page: 1, limit: 10 }); break;
            case 2: setFilter({ ...filter, other: { ...filter["other"] || {}, status: "rejected" }, page: 1, limit: 10 }); break;
            default: setFilter({ ...filter, other: { ...filter["other"] || {}, status: "adjusted" }, page: 1, limit: 10 }); break;
        }
        setTabIndex(value)
        handleSelectedRow({})
    }

    const handleCheckAll = (e) => {
        let tempSelect = [...checkedRow]
        let checked = e.target.checked;
        if (checked) {
            let sT = adjustments?.data?.filter(st => !tempSelect.map(el => el._id).includes(st._id))
            setCheckedRow([...tempSelect, ...sT]);
        }
        else {
            tempSelect = tempSelect?.filter(st => !adjustments?.data?.map(el => el._id).includes(st._id))
            setCheckedRow(tempSelect);
        }
    }
    const handleRowCheckChange = (row = {}) => {
        let tempSelect = [...checkedRow]
        let { _id } = row || {}
        let index = tempSelect.findIndex(el => el._id == _id)
        if (index >= 0) {
            tempSelect.splice(index, 1)
        }
        else {
            tempSelect.push(row)
        }
        setCheckedRow(tempSelect);
    }

    const getTableCell = (header, row, i) => {

        let value = ""
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "" }, row)
        }

        if (header?.handleType) {
            if (header?.handleType === "selectRow") {
                return (
                    <Checkbox
                        size="small"
                        checked={checkedRow?.map((o) => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleRowCheckChange(row)}
                    />
                );
            }
            else if (header?.handleType === "showDetail") {

                return (
                    <Typography
                        onClick={(e) => handleSelectedRow(row)}
                        style={{
                            ...textClasses.normalText,
                            textDecoration: "none",
                            color: header?.color || theme.themeOrange,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
        }
        else if (header?.isFromRenderFunction === true) {
            if (header?.renderFunction === "srNo") {
                let no;
                if (filter?.page == 0) no = i + 1;
                else no = ((filter.page - 1) * filter.limit) + (i + 1);
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                        {no}
                    </Typography>
                );
            }
            else if (header?.renderFunction === "status") {
                let option = header?.values?.find(o => o.value == value) || {}
                return (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textTransform: "capitalize",
                                textAlign: "center",
                                color: option?.color || theme.themeText,
                            }}
                        >
                            {option?.label || "-"}
                        </Typography>
                    </>
                );
            }
        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? moment(value).format(header?.format || "DD MMM YYYY hh:mm A") : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
        }
    };

    //"Action"
    let header = tableHeader?.validHeader?.map((h) => {
        if (h.handleType === "selectRow") {
            return (
                <Checkbox
                    data-testid="checkbox"
                    disabled={!adjustments || !adjustments.count}
                    checked={
                        adjustments?.data?.length > 0 && adjustments?.data?.every(st => checkedRow.map(el => el._id).includes(st._id))
                    }
                    onChange={handleCheckAll}
                    size="small"
                />
            );
        }
        return h.fieldName;
    })
        || [];

    const tableRows = adjustments?.data?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }

        return items;
    }) || [];

    const setPageHandler = (pageNo = 0) => {
        // setFilter({ ...filter, page: pageNo + 1 });
    }

    const setLimitHandler = (pageLimit = 10) => {
        // setFilter({ ...filter, limit: pageLimit });
    }

    const handleClearFilter = () => {
        let tempFilter = { ...initial_state }
        tempFilter["other"]["status"] = tabIndex == 0 ? null : filter?.other?.status || ""
        setFilter(tempFilter)
    };

    const searchableFilterItems = useMemo(() => {
        if (!tableHeader || !tableHeader.validHeader) return [];
        return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
      }, [tableHeader]);

    const refreshTableData = (pageNo, limitNo, fetchCount = false,makeInitial=false) => {

        setLoader(true);

        const payload = {
            page: pageNo,
            limit: limitNo,
            warehouseId:warehouseFilter ? warehouseFilter._id : "",
            floorId: floorFilter ? floorFilter._id : "",
        }
        if(makeInitial){
            setFilter({...initial_state,page: pageNo,limit: limitNo,})
        }
        else{
            if (filter && Object.keys(filter).length > 0) {
                for (let key in filter) {
                  if (key && Object.keys(filter[key]).length > 0) {
                    Object.assign(payload, filter[key])
                  }
                }
                // Process date filter items
                const dateFilterItems = searchableFilterItems?.filter(
                    (item) => item?.searchInputType === "date"
                );

                dateFilterItems.forEach((element) => {
                    const payloadKey = element?.payloadKey || element?.mappingPath || "";
                    if (payloadKey && payload[payloadKey]) {
                        payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
                    }
                });
            }
        }
        dispatch(
            getAllInventoryAdjustment(payload, ({ data = [], count }) => {
                setLoader(false);
                setAdjustments({ data, count });
            })
        )
        // delete payload.status
        if (fetchCount) {
            dispatch(getAdjustmentStatusCount({
                warehouseId: warehouseFilter ? warehouseFilter._id : "",
                floorId: floorFilter ? floorFilter._id : "",
            }));
        }
    }
    useEffect(()=>{
        dispatch(getAllWarehouses({ type: "warehouse" }));
      },[])

      useEffect(() => {
        if (warehouseFilter) {
          dispatch(
            getWarehouseFloors({ type: "floor", warehouseId: warehouseFilter?._id })
          );
        }
      }, [warehouseFilter]);
    useEffect(() => {
        
        setCheckedRow([]);
        setModelVisible(null);
        setAnchorEl(null);
        setActionIndex(null);
        if (initial) {
            setinitial(false)
            refreshTableData(filter.page, filter.limit, initial);
            // dispatch(getAllAdjustmentReasons({}));
        }
    }, [])
    useEffect(() => {
        setModelVisible(null);
        setAnchorEl(null);
        setActionIndex(null);
        setinitial(false)
        if (!initial) {
            refreshTableData(filter.page, filter.limit, initial);
        }
    }, [warehouseFilter,floorFilter, filter.other, debouncedFilterSearch, filter.page, filter.limit])

    useEffect(() => {
        if (!initial) {
            setinitial(false)
            refreshTableData(filter.page, filter.limit, true);
        }
    }, [warehouseFilter,floorFilter])
    return (
        <>
            {
                <>
                    <Grid container>
                        {/* Title Section */}
                        <Grid item sm={12} xs={12}>
                            <Grid container>
                                <Grid item sm={3} xs={4}>
                                    <Typography sx={{ ...textClasses.cardTitle }}>
                                        Inventory Adjustments
                                    </Typography>
                                </Grid>

                                <Grid container item sm={9} xs={8} justifyContent="end">
                                    <Autocomplete
                                        value={warehouseFilter}
                                        onChange={(e, option) => {
                                            setWarehouseFilter(option);
                                            setFilter({ ...filter, page: 1 ,limit:10});
                                            setFloorFilter(null)
                                            // setPage(0);
                                            // setLimit(10);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        size="small"
                                        options={allWarehouses}
                                        sx={{
                                            ...inputClasses.filterField,
                                            minWidth: "250px",
                                            mr:1,
                                            ".MuiInputBase-input": {
                                                fontSize: "15px",
                                                color: "#455A64",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                                {...params}
                                                label="All Warehouse"
                                            />
                                        )}
                                    />
                                    {warehouseFilter && (
                                        <Autocomplete
                                            value={floorFilter}
                                            onChange={(e, option) => {
                                                setFloorFilter(option);
                                                setFilter({ ...filter, page: 1 ,limit:10});
                                                //   setPage(0);
                                                //   setLimit(10);
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            size="small"
                                            options={allFloors}
                                            sx={{
                                                ...inputClasses.filterField,
                                                minWidth: "250px",
                                                mr:1,
                                                ".MuiInputBase-input": {
                                                    fontSize: "15px",
                                                    color: "#455A64",
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                                    {...params}
                                                    label="Select Floor"
                                                />
                                            )}
                                        />
                                    )}
                                    {permissions && (permissions.permissions || []).some(item => item.permissionId === "createInventoryAdjustment") && (
                                        // <Grid container item sm={12} justifyContent="end" alignItems="end">
                                        <Button
                                            data-testid="createNew"
                                            variant="outlined"
                                            size="small"
                                            startIcon={<Add />}
                                            sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                                            onClick={() => history.push("/dashboard/data/adjustment")}
                                        >
                                              Create New
                                        </Button>
                                        // </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* tab section */}
                    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
                        <Tabs
                            value={tabIndex}
                            aria-label="disabled tabs example"
                            onChange={handleTabChange}
                            sx={{
                                "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                                "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
                            }}
                        >
                            <Tab
                                label={`All Adjustment (${statusCount?.totalCount || 0})`}
                                {...a11yProps(0)}
                                sx={{ ...textClasses.normalText, textTransform: "none" }}
                            />

                            {statusList.map((s, i) => {
                                return (
                                    <Tab
                                        data-testid={`${s.label}`}
                                        label={`${s.label} (${statusCount && statusCount[`${s.value}Count`]
                                            ? statusCount[`${s.value}Count`]
                                            : 0
                                            })`}
                                        {...a11yProps(1)}
                                        sx={{ ...textClasses.normalText, textTransform: "none" }}
                                        key={i}
                                    />
                                );
                            })}
                        </Tabs>
                    </Box>

                    {/* Filter section */}
                    <Grid container spacing={1} xs={12}>
                        {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, (tempFilter)=>{setFilter({...tempFilter,page:1,limit:10})}, setPageHandler, setLimitHandler, tabIndex)}
                        {isFilterApplied && (
                            <Grid container item xs={"auto"} alignItems={"center"}>
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleClearFilter}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        )}
                        <Grid container item xs={"auto"} alignItems={"center"}>
                            <GenericCustomiseHeader
                                tableName={"adjustmentTable"}
                                tableType={"adjustmentTable"}
                            />
                        </Grid>
                    </Grid>


                    {/* Action Button section */}
                    {
                        checkedRow.length > 0 &&
                        <Grid container sx={{ mt: 2, textTransform: "none" }}>
                            {
                                checkedRow.length == 1 &&
                                <>
                                    {/* {permissions && (permissions.permissions || []).some(item => item.permissionId === "updateInventoryAdjustment")  &&  checkedRow?.every(dl=>dl.status == "drafted")?
                                            <Button
                                                size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                                onClick={() => history.push(`/dashboard/inventory/adjustments/edit/${checkedRow[0]._id}`)}
                                            >Edit</Button>
                                            : ''
                                            } */}
                                    {permissions && (permissions.permissions || []).some(item => item.permissionId === "viewInventoryAdjustment") ?
                                        <Button
                                            size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                            onClick={() => setSelectedRow(checkedRow[0] || {})}
                                        >View</Button>
                                        : ''
                                    }
                                </>
                            }
                            {
                                permissions && (permissions.permissions || []).some(item => item.permissionId === "approvedInventoryAdjustment") && checkedRow?.every(dl => dl.status &&["pending","partialAdjusted"].includes(dl?.status)) ?
                                    <Button
                                        size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                        onClick={() => setModelVisible("Approve")}
                                    >Approved</Button>
                                    : ''
                            }
                            {
                                permissions && (permissions.permissions || []).some(item => item.permissionId === "rejectInventoryAdjustment") && checkedRow?.every(dl => dl.status &&["pending","partialAdjusted"].includes(dl?.status)) ?
                                    <Button
                                        size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                                        onClick={() => setModelVisible("Reject")}
                                    >Reject</Button>
                                    : ''
                            }
                            {/* {permissions && (permissions.permissions || []).some(item => item.permissionId === "deleteInventoryAdjustment") && checkedRow?.every(dl => dl.status == "pending") ?
                                    <Button
                                        size='small' variant="contained"
                                        sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "black", border: "1px solid black", textTransform: "none" }}
                                        onClick={() => setModelVisible("Delete")}
                                    >Delete</Button>
                                    : ''
                                } */}
                        </Grid>
                    }
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item md={selectedRow?._id ? 8 : 12} sm={12} xs={12} order={{ xs: 2, sm: 2, md: 1 }} >
                            {
                                loader
                                    ?
                                    <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                                        <Grid item >
                                            <GenericLoader />
                                        </Grid>
                                    </Grid>
                                    :
                                    <GenericTable
                                        header={header}
                                        rows={tableRows}
                                        pageCount={adjustments?.count || 0}
                                        pageNumber={filter?.page - 1}
                                        handleChangePage={(event, pagevalue) => {
                                            setFilter({ ...filter, page: pagevalue + 1 });
                                        }}
                                        handleChangeRowsPerPage={(event) => {
                                            setFilter({ ...filter, limit: +event.target.value });
                                        }}
                                        rowsPerPage={filter?.limit}
                                    />
                            }
                        </Grid>
                        {
                            selectedRow?._id &&
                            <Grid item md={4} sm={12} xs={12} order={{ xs: 1, sm: 1, md: 2 }} sx={{ p: 1, pt: 0, }}>
                                <DetailCard
                                    data-testid="cardDetail"
                                    data={selectedRow}
                                    handleSelectedRow={handleSelectedRow}
                                    statusMapping={tableHeader?.allHeader?.find(item => item.mappingPath === "status")?.values || []}
                                />
                            </Grid>
                        }


                    </Grid>
                    {
                        actionIndex != null && (permissions?.permissions || []).some(item => [
                            // "deleteInventoryAdjustment",
                            "approvedInventoryAdjustment",
                            "rejectInventoryAdjustment",
                            "updateInventoryAdjustment",
                            "viewInventoryAdjustment",
                        ].includes(item.permissionId))
                        &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={actionIndex != null}
                            onClose={handleCloseAction}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                        >
                            {/* {permissions && (permissions.permissions || []).some(item => item.permissionId === "editTemplate") ?
                                <MenuItem 
                                sx={{ ...textClasses.normalText }} key={1} 
                                onClick={() => history.push(`/dashboard/data/edit-adjustment/${adjustments["data"][actionIndex].referenceNumber}`)}
                                >Edit</MenuItem>
                                : ''} */
                            }
                            {permissions?.permissions?.some(item => item.permissionId === "viewInventoryAdjustment") ?
                                <MenuItem
                                    sx={{ ...textClasses.normalText }}
                                    key={2}
                                    onClick={() => handleSelectedRow(adjustments["data"][actionIndex])}
                                >View
                                </MenuItem>
                                : ''
                            }
                            {permissions?.permissions?.some(item => item.permissionId === "approvedInventoryAdjustment") &&
                            (adjustments["data"][actionIndex] || {})?.status &&["pending","partialAdjusted"].includes(adjustments["data"][actionIndex].status) ?
                                <MenuItem
                                    sx={{ ...textClasses.normalText }}
                                    key={2}
                                    onClick={() => {
                                        setModelVisible("Approve")
                                        setCheckedRow([adjustments["data"][actionIndex]])
                                    }}
                                >Approved
                                </MenuItem>
                                : ''
                            }
                                {permissions?.permissions?.some(item => item.permissionId === "rejectInventoryAdjustment") &&
                                    (adjustments["data"][actionIndex] || {})?.status && ["pending", "partialAdjusted"].includes(adjustments["data"][actionIndex].status)
                                    ?
                                <MenuItem
                                    sx={{ ...textClasses.normalText }}
                                    key={2}
                                    onClick={() => {
                                        setModelVisible("Reject")
                                        setCheckedRow([adjustments["data"][actionIndex]])
                                    }}
                                >Reject
                                </MenuItem>
                                : ''
                            }
                            {/* {permissions?.permissions?.some(item => item.permissionId === "deleteInventoryAdjustment") ?
                                    <MenuItem
                                        sx={{ ...textClasses.normalText }}
                                        key={2}
                                        onClick={() => handleSelectedRow(adjustments["data"][actionIndex])}
                                    >Delete
                                    </MenuItem>
                                    : ''
                                } */}
                        </Menu>
                    }

                    <ConfirmDialog
                        modelVisible={modelVisible}
                        handleClose={() => {
                            setModelVisible(null);
                        }}
                        checkedRow={checkedRow?.map(item => ({ ...item, selectedQuantity: Number(item?.availableQuantity || 0) }))}
                        setCheckedRow={setCheckedRow}
                        refreshTableData={() => {
                            setTabIndex(0)
                            setinitial(true)
                            refreshTableData(initial_state.page, initial_state.limit, true,true);
                        }}
                    />
                </>
            }
        </>
    )
}

const DetailCard = ({ data = {}, handleSelectedRow, statusMapping = [] }) => {

    // let dispatch = useDispatch()

    const { AllUserData = {} } = useSelector((state) => state.users) || {};

    let status = data?.status && statusMapping?.find(dl => dl.value == data.status.toLowerCase()) || { color: theme.themeText, background: "#ff720026", label: data?.status || "N/A" }
    if (!status.color) status["color"] = theme.themeText
    if (!status.background) status["background"] = "#ff720026"

    const DetailRow = ({ title, children }) => {
        return (
            <Grid container sx={{ mt: 1 }}>
                <Grid item sm={4} xs={4}>
                    <Typography sx={{ ...textClasses.t12n, color: "#827878" }}>{title}</Typography>
                </Grid>
                <Grid container item sm={8} xs={8} direction="row" alignItems={"center"} sx={{ textTransform: "capitalize", color: theme.themeText }}>
                    {children}
                </Grid>
            </Grid>
        )
    }

    // useEffect(() => {
    //     dispatch(getAllUsers({isAdminIncluded: true,projection:{_id:"$_id",name:"$name",email:"$email",designation:"$designation"}}))
    // }, [data._id])

    return (
        <>
            {
                data &&

                <Card sx={{ ...cardClasses.basic, borderRadius: "8px", border: `1px solid ${theme.themeOrange}`, height: "400px", overflow: "hidden" }} >
                    <CardHeader
                        sx={{ height: "46px", backgroundColor: theme.themeOrange }}
                        title={
                            <Typography sx={{ ...textClasses.boldText, color: "white", fontSize: "16px" }}>
                                Ref No: {getCamelCaseText(data.referenceNumber)}
                            </Typography>
                        }
                        action={
                            <IconButton size='small' sx={{ p: 0, mb: 1 }} onClick={() => handleSelectedRow([])}>
                                <Close sx={{ color: "white" }} />
                            </IconButton>
                        }
                    />
                    <CardContent sx={{ minHeight: "350px", maxHeight: "600px", overflowY: "auto" }}>
                        <DetailRow title="Entity ID:">
                            <Typography sx={{ ...textClasses.t12n }}>{data?.categoryKey || "-"}</Typography>
                        </DetailRow>

                        <DetailRow title="Current Status:">
                            <Button sx={{ p: 0, cursor: "text !important", ...buttonClasses.small, color: status.color, backgroundColor: status, border: `1px solid ${status.color}`, "&:hover": { backgroundColor: status.background } }}>{status?.label}</Button>
                            <Typography sx={{ ...textClasses.t12n, color: theme.themeOrange, ml: 1 }}>
                            </Typography>
                        </DetailRow>
                        <DetailRow title="Entity Type:">
                            <Typography sx={{ ...textClasses.t12n }}>{data?.categoryName || "-"}</Typography>
                        </DetailRow>
                        <DetailRow title="Adjustment Type:">
                            <Typography sx={{ ...textClasses.t12n }}>{data?.adjustmentType || "-"}</Typography>
                        </DetailRow>
                        <DetailRow title="Reason:">
                            <Typography sx={{ ...textClasses.t12n }}>{data?.reasonDetail?.reason || "-"}</Typography>
                        </DetailRow>
                        <DetailRow title="Remarks:">
                            <Typography sx={{ ...textClasses.t12n }}>{data?.remarkDetail?.remark || "-"}</Typography>
                        </DetailRow>
                        <DetailRow title="Submission Date:">
                            <Typography sx={{ ...textClasses.t12n }}>{dateTimeFormater(data?.submissionDate || null) || "-"}</Typography>
                        </DetailRow>
                        <DetailRow title="Submitted By:">
                            <Typography sx={{ ...textClasses.t12n }}>
                                {(function () {
                                    if (data?.submitted_by?._id) {
                                        let detail = []
                                        // let user = AllUserData?.data?.find(u=>u._id==data?.submitted_by?._id)
                                        // if(user) detail.push(user.name,user.designation || "") 
                                        // else detail.push(data?.submitted_by?.name || "-" ,data?.submitted_by?.designation || "-") 
                                        detail.push(data?.submitted_by?.name || "-", data?.submitted_by?.designation || "-")
                                        return detail.join(", ")
                                    }
                                    else return "-"
                                })() || "-"}
                            </Typography>
                        </DetailRow>
                    </CardContent>
                </Card>
            }
        </>
    )
}

