import React, { useEffect, useState,useMemo } from "react";
import { useDebounce } from "../../../../utils/hooks";
import {
    Grid,
    Button,
    MenuItem,
    Typography,
    Checkbox,
    Paper,
    IconButton,
    Menu,
} from "@mui/material";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../services/history";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
    cardClasses,
} from "../../../../static/styles/theme"
import {
    Add,
    KeyboardArrowDown,
} from "@mui/icons-material";
import { getSuperAdminUsersDetails, getTemplateHeader, getAllUsers, getActiveBlockCounts, getUsersTypeWiseCount } from "../../../../redux/actions";
import { capitalizeSentence, showToast, renderHeadersFilter, dataReadFunction } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import GenericPageConfig from "../../../Components/Generic/GenericPageConfig";
import ViewRowDetailCard from "../ViewRowDetailCard";
import EnableDisableDialog from "../EnableDisableDialog";
import ViewUserProductvity from "../../UserManagement/Components/ViewUserProductvity";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";


let initial_state = {
    "search": {},
    "other": {},
}
const usersDetailTemplateHeader2 = [
    {
        "label": "Company Name",
        "name": "company",
        "type": "input",
        "format": "",
    },
    {
        "label": "Role Name",
        "name": "roleName",
        "type": "input",
        "format": "",
    },
    {
        "label": "Role Type",
        "name": "userType",
        "type": "input",
        "format": "",
    },
    {
        "label": "Created At",
        "name": "createdAt",
        "type": "date",
        "format": "",
    },
    {
        "label": "Updated At",
        "name": "updatedAt",
        "type": "date",
        "format": "",
    },

]

const AdminWorkerPage = () => {
    const dispatch = useDispatch();
    const { pageTitle = "Manage Users" } = useSelector(state => state?.DynamicPageConfig?.AdminWorkersPage) || {};
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    const [statusFilter, setStatusFilter] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [filter, setFilter] = useState(initial_state);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState("");
    const [awData, setAwData] = useState({
        data: [],
        count: {}
    })
    const [adminWorkerStatusCount, setAdminWorkerStatusCount] = useState({})
    const [adminWorkerTypeWiseCount, setAdminWorkerTypeWiseCount] = useState({})
    const [cardType, setCardType] = useState("")
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [viewAdminWorkerDetail, setViewAdminWorkerDetail] = useState(false)
    const [usersDetailTemplateHeaders, setUsersDetailTemplateHeaders] = useState([])
    const [usersDetailData, setUsersDetailData] = useState({})
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    const [initialRender, setInitialRender] = useState(true);
    const [showViewProductivityModal,setShowViewProductivityModal] = useState({view:"",action:{}})
    const debouncedFilterSearch = useDebounce(filter?.search, 500);
    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };
    const handleCloseAction = () => {
        setActionConfirmModal(null);
        if (setSelectedEntries) {
            setSelectedEntries([]);
        }
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    };

    const handleClearFilter = () => {
        setFilter(initial_state)
        setPage(0);
        setLimit(10);
        if (tabIndex == 0) {
            setStatusFilter(null);
        }
    };
    const handleRowAndViewClick = (selectedRowData) => {
        setViewAdminWorkerDetail(true)
        let payload = {
            _id: selectedRowData?._id,
        }
        setLoader("cardRowDetail")
        dispatch(getSuperAdminUsersDetails(payload, ({ data = {}, success }) => {
            setLoader("")
            if (success) {
                setUsersDetailData(data)
            }
        }))
        dispatch(getTemplateHeader({ type: "user" }, (data) => {
            setUsersDetailTemplateHeaders([...data?.templateHeader?.filter(dl => dl?.name != "password"), ...usersDetailTemplateHeader2])
        }))
    }
    const handleTabChange = (value, statusObject = {}) => {
        setStatusFilter(statusObject)
        setPage(0);
        setTabIndex(value);
        setSelectedEntries([])
        setViewAdminWorkerDetail(false)
        refreshTableData(1, limit, value && statusObject?.value || "", false, value)

    };
    const handleTypeChange = (type) => {
        setSelectedEntries([])
        setCardType(type);
        // setSelectedRow(null);
        setPage(0);
        setLimit(10);
        setInitialRender(true);
        setViewAdminWorkerDetail(false)
    };
    const handleAddSelection = (obj) => {
        setSelectedEntries([...selectedEntries, obj]);
    };

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map((o) => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
            if (oldEntries.length == 0) {
                handleCloseConfirmModal();
            }
        }
    };
    const handleCloseDetail = () => {
        setViewAdminWorkerDetail(false)
    }
    const isFilterApplied = (() => {
        if (tabIndex == 0 && statusFilter?.value) {
            return true;
        }
        const { status, ...rest } = filter["other"] || {}
        if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(rest).some(dl => dl && dl.length > 0)) {
            return true;
        }
        return false;
    })();


    const handleCheckAll = () => {
        if (selectedEntries.length < awData?.data?.length) {
            let sT = awData?.data?.map((t) => ({
                _id: t._id,
                status: t.status,
                name: t.name,
            }));
            setSelectedEntries(sT);
        } else {
            setSelectedEntries([]);
        }
    };

    const handleCheckChange = (event, row) => {
        if (event.target.checked) {
            handleAddSelection(row);
        } else {
            handleRemoveSelection(row._id);
        }
    };

    const tableHeader = useSelector((state) => state.customHeader["usersTable"]);
    let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
        if (h.handleType === "selectRow") {
            return (
                <Checkbox
                    data-testid="checkbox"
                    checked={
                        awData?.data?.length != 0 &&
                        selectedEntries.length == awData?.data?.length
                    }
                    onChange={handleCheckAll}
                    size="small"
                />
            )
        }
        return h.fieldName
    })
        || [];


    const getTableCell = (header, row, i) => {
        let value = ""
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "" }, row)
        }

        if (header?.handleType) {
            if (header?.handleType === "selectRow") {
                return (
                    <Checkbox
                        size="small"
                        checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleCheckChange(e, row)}
                    />
                );
            }
            else if (header?.handleType === "showDetail") {
                return (
                    <Typography
                        onClick={() => handleRowAndViewClick(row)}
                        style={{
                            ...textClasses.normalText,
                            color: theme.themeOrange,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
            else if (header?.handleType === "viewProductivity") {
                return (
                    value?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer", textDecoration: 'underline' }}
                        onClick={() => {
                            setShowViewProductivityModal({ ...showViewProductivityModal, view: "viewProductivity", action: row })
                        }}>
                        View
                    </Typography> : "-"
                )
            }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
        }
        else if (header?.isFromRenderFunction === true) {
            let no;
            if (page === 0) {
                no = i + 1;
            } else {
                no = page * 10 + (i + 1);
            }
            if (header?.renderFunction === "srNo") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                        {no}
                    </Typography>
                );
            }
            else if (header?.renderFunction === "status") {
                return (
                    <>
                        {value === true ?
                            < Typography sx={{ ...textClasses.t13n, textAlign: "center", color: "green" }}>
                                Active
                            </Typography >
                            :
                            < Typography sx={{ ...textClasses.t13n, textAlign: "center", color: theme.themeRed }}>
                                Blocked
                            </Typography >
                        } </>
                );
            }
        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? moment(value).format(header?.format || "DD MMM YYYY hh:mm A") : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: header?.mappingPath != "email" ? "capitalize" : "none", color: header?.color || theme.themeText }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
        }
    };

    const tableRows = (awData?.data)?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }
        return items;
    });


    const searchableFilterItems = useMemo(() => {
        if (!tableHeader || !tableHeader.validHeader) return [];
        return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
    }, [tableHeader]);


    const refreshTableData = (pageNo, limitNo, status = "", fetchCount = true) => {
        setLoader("tableData");
        setAwData({
            data: [],
            count: {}
        })
        const payload = {
            page: pageNo,
            limit: limitNo,
            userType: cardType.toLowerCase(),
            approved: status || "",
            status: status || ""
        };
        if (filter && Object.keys(filter).length > 0) {
            for (let key in filter) {
                if (key && Object.keys(filter[key]).length > 0) {
                    Object.assign(payload, filter[key])
                }
            }
            // Process date filter items
            const dateFilterItems = searchableFilterItems?.filter(
                (item) => item?.searchInputType === "date"
            );

            dateFilterItems.forEach((element) => {
                const payloadKey = element?.payloadKey || element?.mappingPath || "";
                if (payloadKey && payload[payloadKey]) {
                    payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
                }
            });
        }
        dispatch(getAllUsers(payload, (data = {}) => {
            setAwData({ data: data?.data, count: data?.count })
            setLoader("")
        }))
        if (initialRender) {
            dispatch(getActiveBlockCounts({ ...payload, status: "" }, (data = {}) => {
                setAdminWorkerStatusCount(data?.data);
            }));

            dispatch(getUsersTypeWiseCount({ ...payload, status: "" }, (data = {}) => {
                setAdminWorkerTypeWiseCount(data?.data);
            }));

        }

    };

    let fetchData = (fetchCount = false) => {
        refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount, tabIndex);
        // setSelectedTemplates([]);
        setConfirmModal(null);
        // setReceiveModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);
        setViewAdminWorkerDetail(false);
    };

    useEffect(() => {
        if (cardType) {
            fetchData(initialRender);
            if (initialRender) {
                setInitialRender(false)
            }
        }

    }, [page, limit, filter.other, debouncedFilterSearch, cardType]);
    return (
        <>
            <Grid container>
                <Grid item sm={8}>
                    <Typography sx={{ ...textClasses.cardTitle }}>
                        {pageTitle}
                    </Typography>
                </Grid>
                <Grid container item sm={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
                    {cardType == "Worker" &&
                        (permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "createUser"
                            ) ? (
                            <Button
                                data-testid="add"
                                variant="outlined"
                                size="small"
                                startIcon={<Add />}
                                sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                                onClick={() => history.push("/dashboard/worker/add")}
                            >
                                Add Worker
                            </Button>) : null)
                    }
                    {cardType == "Admin" &&
                        (permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "createUser"
                            ) ? (
                            <Button
                                data-testid="add"
                                variant="outlined"
                                size="small"
                                startIcon={<Add />}
                                sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                                onClick={() => history.push("/dashboard/admin/add")}
                            >
                                Add Admin
                            </Button>) : null)
                    }

                </Grid>
                <GenericPageConfig
                    storedInPage={"AdminWorkersPage"}
                    pageName={"adminAndWorkers"}
                    pageTabCount={adminWorkerTypeWiseCount}
                    pageStatusCount={adminWorkerStatusCount}
                    handlePageTabChange={handleTypeChange}
                    selectedPageTab={cardType}
                    selectedStatusTab={tabIndex}
                    handleStatusTabChange={handleTabChange}
                    disabledPageTabs={loader}
                    disabledStatusTabs={loader}

                />
            </Grid>
            <Grid container spacing={1} xs={12}>
                {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setPage, setLimit, tabIndex)}
                {isFilterApplied && (
                    <Grid container item sx={2} alignItems={"center"}>
                        <Typography
                            sx={{
                                ...textClasses.boldText,
                                color: theme.themeOrange,
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={handleClearFilter}
                        >
                            Clear Filter(s)
                        </Typography>
                    </Grid>
                )}
                <Grid container item xs={"auto"} alignItems={"center"}>
                    <GenericCustomiseHeader
                        tableName={"usersTable"}
                        tableType={"usersTable"}
                    />
                </Grid>
            </Grid>
            {selectedEntries?.length > 0 &&
                <Grid container sx={{ mt: 2, textTransform: "none" }}>

                    {selectedEntries.length == 1 && permissions && (
                        <>
                            {(permissions.permissions || []).some(
                                ((item) => item.permissionId === "viewUser")
                            ) && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            mr: 1,
                                            textTransform: "none",
                                        }}
                                        onClick={() => handleRowAndViewClick(selectedEntries[0])}
                                    >
                                        View
                                    </Button>
                                )}
                            {(permissions.permissions || []).some(
                                ((item) => item.permissionId === "editUser")
                            ) && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            mr: 1,
                                            textTransform: "none",
                                        }}
                                        onClick={() => {
                                            cardType == "Admin" ?
                                                history.push(`/dashboard/admin/edit?id=${selectedEntries[0]._id}`) :
                                                history.push(`/dashboard/worker/edit?id=${selectedEntries[0]._id}`)
                                        }}
                                    >
                                        Edit
                                    </Button>
                                )}
                        </>
                    )}
                    {
                        permissions &&
                        (permissions.permissions || []).some(item => item.permissionId === "changeUserStatus") && (
                            selectedEntries?.every(entry => entry.approved === true) ? (
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        mr: 1,
                                        textTransform: "none",
                                    }}
                                    onClick={() => setConfirmModal("disable")}
                                >
                                    Disable
                                </Button>
                            ) : selectedEntries?.every(entry => entry.approved === false) ? (
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        mr: 1,
                                        textTransform: "none",
                                    }}
                                    onClick={() => setConfirmModal("enable")}
                                >
                                    Enable
                                </Button>
                            ) : null
                        )
                    }
                </Grid>

            }
            <Grid container sx={{ mt: 2 }}>
                <Grid item sm={viewAdminWorkerDetail ? 8 : 12} xs={12}>
                    <Paper>
                        {loader == "tableData" ? (
                            <Grid
                                data-testid="loader"
                                container
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems="center"
                                sx={{ p: 10 }}
                            >
                                <Grid item>
                                    <GenericLoader />
                                </Grid>
                            </Grid>
                        ) : (
                            <GenericTable
                                header={header}
                                rows={tableRows}
                                pageCount={awData?.count}
                                pageNumber={page}
                                handleChangePage={(event, pagevalue) => {
                                    setPage(pagevalue);
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setLimit(+event.target.value);
                                }}
                                rowsPerPage={limit}
                            />
                        )}
                    </Paper>
                </Grid>
                {
                    viewAdminWorkerDetail && (
                        <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
                            <ViewRowDetailCard
                                detailData={usersDetailData}
                                onClose={handleCloseDetail}
                                readKey={'name'}
                                cardHeader={usersDetailTemplateHeaders}
                                loader={loader == "cardRowDetail"}
                            />
                        </Grid>
                    )
                }
                {/* this is for the disable and enable buttons */}

                <EnableDisableDialog
                    status={confirmModal}
                    handleClose={handleCloseConfirmModal}
                    selectedEntries={selectedEntries}
                    setSelectedEntries={setSelectedEntries}
                    refreshTableData={() => {
                        setInitialRender(true)
                        refreshTableData(1, limit)
                        setTabIndex(0)
                        setPage(0)
                    }}
                    handleRemoveSelection={handleRemoveSelection}
                    cardType={cardType}
                />
                {/* this is for the disable and enable action menu items */}

                <EnableDisableDialog
                    status={actionConfirmModal}
                    cardType={cardType}
                    handleClose={handleCloseAction}
                    selectedEntries={
                        actionIndex != null ? [awData?.data[actionIndex]] : []
                    }
                    refreshTableData={() => {
                        setInitialRender(true)
                        refreshTableData(1, limit)
                        setTabIndex(0)
                        setPage(0)
                    }}
                />

                {/* This is to open viewProductivity on click of view */}
                {showViewProductivityModal?.view == "viewProductivity" &&
                    <ViewUserProductvity
                        open={showViewProductivityModal?.view == "viewProductivity"}
                        hide={(type = "") => {
                            setShowViewProductivityModal({ ...showViewProductivityModal, view: "", action: {} })
                        }}
                        selectedValue={showViewProductivityModal?.action}
                    />}
                {actionIndex != null && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={actionIndex != null}
                        onClose={handleCloseAction}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                        sx={{
                            display:
                                actionIndex != null ? "block !important" : "none !important",
                        }}
                    >
                        {permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "viewUser"
                            ) ? (
                            <MenuItem
                                key={1}
                                sx={{ ...textClasses.normalText }}
                                onClick={() => {
                                    handleRowAndViewClick(awData?.data[actionIndex])
                                    handleCloseAction()
                                }}
                            >
                                View
                            </MenuItem>
                        ) : (
                            ""
                        )}
                        {permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "editUser"
                            ) ? (
                            <MenuItem
                                key={1}
                                sx={{ ...textClasses.normalText }}
                                onClick={() => {
                                    cardType == "Admin" ?
                                        history.push(`/dashboard/admin/edit?id=${awData?.data[actionIndex]?._id}`) :
                                        history.push(`/dashboard/worker/edit?id=${awData?.data[actionIndex]?._id}`)
                                }}
                            >
                                Edit
                            </MenuItem>
                        ) : (
                            ""
                        )}
                        {permissions &&
                            (permissions.permissions || []).some((item) => item.permissionId === "changeUserStatus") && (
                                <>
                                    {awData?.data[actionIndex]?.approved === true && (
                                        <MenuItem
                                            key={2}
                                            sx={{ ...textClasses.normalText }}
                                            onClick={() => setActionConfirmModal("disable")}
                                        >
                                            Disable
                                        </MenuItem>
                                    )}
                                    {awData?.data[actionIndex]?.approved === false && (
                                        <MenuItem
                                            key={3}
                                            sx={{ ...textClasses.normalText }}
                                            onClick={() => setActionConfirmModal("enable")}
                                        >
                                            Enable
                                        </MenuItem>
                                    )}
                                </>
                            )}
                    </Menu>
                )}
            </Grid>
        </>
    );
}

export default AdminWorkerPage;