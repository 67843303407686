import { FETCH_INWARD_WORKFLOW_LISTING, FETCH_INHOUSE_WORKFLOW_LISTING, FETCH_OUTWARD_WORKFLOW_LISTING, FETCH_WORKFLOW_DETAILS, FETCH_WORKFLOW_TYPEWISE_COUNT } from "../types"
const INIT_STATE = {
    inwardWorkflowList: [],
    inHouseWorkflowList: [],
    outwardWorkflowList: [],
    totalInwardWorkflows: 0,
    totalInhouseWorkflows: 0,
    totalOutwardWorkflows: 0,
    workflowDetail: null,
    workflowTypeWiseCount: null
}
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_INWARD_WORKFLOW_LISTING: return { ...state, inwardWorkflowList: action.payload.data, totalInwardWorkflows: action?.payload?.count || 0 };
        case FETCH_INHOUSE_WORKFLOW_LISTING: return { ...state, inHouseWorkflowList: action.payload.data, totalInhouseWorkflows: action?.payload?.count || 0 };
        case FETCH_OUTWARD_WORKFLOW_LISTING: return { ...state, outwardWorkflowList: action.payload.data, totalOutwardWorkflows: action?.payload?.count || 0 };
        case FETCH_WORKFLOW_DETAILS: return { ...state, workflowDetail: action.payload || {} };
        case FETCH_WORKFLOW_TYPEWISE_COUNT: return { ...state, workflowTypeWiseCount: action.payload || {} };
        default: return state
    }
}