/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react'
import {
    Typography, TextField, FormControl, FormLabel, InputLabel, Select, MenuItem, FormControlLabel,
    Checkbox, RadioGroup, Radio, FormGroup, Box, Grid, Tooltip,Button
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import theme, { inputClasses, textClasses,buttonClasses } from '../../../static/styles/theme'
import CheckIcon from "@mui/icons-material/Check";
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import no_data_found from '../../../static/images/no_data_found.svg'
import { Add,Remove } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import AddUom from '../AddModel/AddUom';
import { customAPI_For_APIPara,handleDynamicChange } from "../../../services/formFunction"
import { debounce } from '@mui/material/utils';
import { selectStylesOverride } from '../../../utils/util';
import moment from 'moment'
import { getShortText, showToast } from '../../../services/functions';



const filterMethod = createFilterOptions({
    stringify: (option) => option?.renderOptionLabel || option?.label,
})


const MultiInputField = ({
    other = {},
    basicProps = {},
    component = {},
    inputProps = null,
}) => {
    const { onChange, handleDataChange, value: otherValue, ...restOther } = other;
    const { value: fieldValue, ...restBasicProps } = basicProps;
    const isNumberType = component?.type == "number"
    // Determine initial value
    let tempValue = fieldValue || otherValue || [isNumberType ? 0 : ""];
    if (!tempValue || tempValue.length === 0) {
        tempValue = [isNumberType ? 0 : ""];
    }
    if(typeof tempValue == "string" || typeof tempValue == "number"){
        tempValue = [tempValue]
    }

    // console.log("tempValue",tempValue)

    const handleMultiInputChange = (event, locIndex) => {
        let eventValue = event.target.value || (isNumberType ? 0 : "")
        if (eventValue && typeof eventValue != "number") {
            tempValue.splice(locIndex, 1, eventValue.trimStart())
        }
        else {
            tempValue.splice(locIndex, 1, eventValue)
        }
        return { ...event, target: { value:tempValue } }
    }

    const handleAddInput = (locIndex) => {
        tempValue.splice(tempValue.length, 0, isNumberType ? 0 : "")
        // tempValue.splice(locIndex, 0, isNumberType ? 0 : "")
        return { target: { value:tempValue } }
    }

    const handleDeleteInput = (locIndex) => {
        tempValue.splice(locIndex, 1)
        return { target: { value:tempValue } }
    }


    // Unified change handler
    const onChangeHandler = (currentValue) => {
        // console.log("currentValue",currentValue)
        if (handleDataChange) {
            handleDataChange(currentValue);
        } else if (onChange) {
            onChange(currentValue);
        } else if (restBasicProps.onChange) {
            restBasicProps.onChange(currentValue);
        }
    };

    return (
        <fieldset style={{
            border: `1px solid ${theme.themeGray}`,
            padding: "5px 10px 10px",
            borderRadius: "10px",
        }}>
            <legend style={{
                color: theme.themeBlack,
                fontSize: "11px"
            }}><span style={{color: theme.themeOrange,}}>Multiple Input Element:</span> You can fill multiple value for this input.</legend>
            {Array.from({ length: tempValue.length }, (_, i) => (
                <Box
                    key={i}
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,

                    }}
                >
                    {/* Input Field */}
                    <TextField
                        {...restBasicProps}
                        {...restOther}
                        value={tempValue.at(i) || ""}
                        onChange={(e) =>
                            onChangeHandler(
                                handleMultiInputChange(e, i)
                            )
                        }
                        sx={{ ...inputClasses.themeBoundary, mt: i == 0 ? 0 : 1 }}
                        InputProps={inputProps ? { endAdornment: inputProps } : {}}
                        label={(component?.label || "")+" "+(i+1)}
                        placeholder={(component?.placeholder || component?.label || "")+" "+(i+1)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mt: i == 0 ? 0 : 1, gap: 0.5 }}>
                        {/* Remove Input Button */}
                        <Tooltip title="This is for removing input" placement="top">
                            <Button
                                disabled={!tempValue || tempValue.length <= 1}
                                size="small"
                                sx={{
                                    ...buttonClasses.lynkitBlackFill,
                                    fontSize: "12px",
                                    maxHeight: "13px!important",
                                    maxWidth: "30px!important",
                                    minWidth: "30px!important",
                                }}
                                onClick={() => onChangeHandler(handleDeleteInput(i))}
                            >
                                <Remove fontSize="inherit" />
                            </Button>
                        </Tooltip>

                        {/* Add Input Button */}
                        {i == (tempValue?.length - 1) &&
                            <Tooltip title="This is for adding new input" placement="bottom">
                                <Button
                                    size="small"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        fontSize: "12px",
                                        maxHeight: "13px!important",
                                        maxWidth: "30px!important",
                                        minWidth: "30px!important",
                                    }}
                                    onClick={() =>
                                        onChangeHandler(handleAddInput(i))
                                    }
                                >
                                    <Add fontSize="inherit" />
                                </Button>
                            </Tooltip>
                        }
                    </Box>
                </Box>
            ))}

        </fieldset>
    );
};



const CustomInput = ({ component, autoFillOnFocus, errors, field, multiline, inputProps, ...other }) => {
    const dispatch = useDispatch();
    const [selectOptions, setSelectOptions] = useState([]);
    const [initial, setInitial] = useState(true);
    const [searching, setSearching] = useState("");
    const [inputValue, setInputValue] = useState('');
    // const [enableFetch, setEnableFetch] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    // const usePrevious = (value = "") => {
    //     const ref = useRef();

    //     useEffect(() => {
    //         ref.current = value;
    //     }, [value]);

    //     return ref.current;
    // };


    // const prevData = usePrevious(other?.value || field.value || (component.type == "checkbox" && [] || ""));
    // const prevInput = usePrevious(inputValue);


    useEffect(() => {
        let value=other?.value || field.value || ""
        if (component?.setDefaultValue && component?.defaultValueFunction && !value) {
            let eValue = eval(component?.defaultValueFunction || "")

            if (component.type == "date") {
                eValue = moment(eValue).format("YYYY-MM-DD")
            }
            if (field.onChange) {
                setTimeout(() => {
                    field.onChange({ target: { value: eValue } })

                }, 400);
            }
            else if (other.onChange) {
                setTimeout(() => {
                    other.onChange({ target: { value: eValue } })
                }, 400);
            }
        }
    }, []);


    const fetch = React.useMemo(
        () =>
            debounce(async (component, value, callback) => {
                setSearching("Searching...")
                await customAPI_For_APIPara(component, value, callback)
            }, 400),
        [],
    );

    useEffect(async () => {
        let active = true;
        if (initial && active) {
            if (component?.dynamic && !component.dontFetch ) {
                fetchOptions(other?.value || field.value)
            }
            setInitial(false)
        }
        return () => {
            active = false;
        };
    }, [other?.value, field.value]);


    const handleInputChange = (event, newInputValue) => {
        if (newInputValue !== inputValue) {
            setInputValue(newInputValue); // Update input value state
            if (newInputValue === "") {
                fetchOptions(""); // Fetch default options when input is cleared
            } else {
                fetchOptions(newInputValue); // Fetch options for the typed input
            }
        }
    };

    const fetchOptions = (tempSearch=null) => {
        let temp_option = []
        if (selectOptions?.some(dl => dl?.isNewGenerated)) {
            temp_option=[...selectOptions?.filter(dl => dl?.isNewGenerated)]
        }
        fetch(component, tempSearch, (results) => {
            setSearching("")
            if (results) temp_option = [...temp_option, ...results];
            // console.log("temp_option",temp_option)
            setSelectOptions(temp_option);
        });
    }

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleOpenModal = async(option,changeHandler) => {
        //["isGenerateOption" ,"isAddOption","isFreeSolo"]
        // console.log("handleOpenModal",option)
        if(option?.addMethod=="isAddOption"){
            setOpenModal(option?.addMethod)
        }
        else{
            if(component?.addNewOptions?.apiEndPoint){
                let targetField={
                    "setTargetValue":true,
                    "targetAPI" : component?.addNewOptions?.apiEndPoint,
                    "targetAPIMethod" : "post",
                    "targetParameters" : component?.addNewOptions?.apiParameters || [],
                    "targetApiDataReturnKey" : component?.addNewOptions?.apiDataReturnKey || "data",
                    "onChangeSet" : {
                        "data" : "$response.data",
                    }
                }
                let responseData={}
                setOpenModal(option?.addMethod)
                await handleDynamicChange(responseData, targetField,{ value: "" },"generateID",[],true)
                // console.log("responseData",responseData)
                let newOption={label:responseData?.generateID || "",value:responseData?.generateID || "",isNewGenerated:true}
                if(responseData?.generateID){
                    setSelectOptions([newOption,...selectOptions])
                }
                changeHandler({ target: { value: responseData?.generateID || "" } }, newOption)
                setOpenModal("")
            }
            else{
                setInputValue("")
                showToast("To generate ID api config not exists in form element",true)
            }
        }
    }

    const handleSelect = (idChecked, values = []) => {
        const newValue = (Array.isArray(values) && values || [])?.includes(idChecked)
            ? values?.filter((id) => id !== idChecked)
            : [...values, idChecked];

        // console.log("idChecked, values",{idChecked, values,newValue});
        // console.log("newValue",{newValue});
        return newValue;
    }

    let basicProps = {
        size: "small",
        fullWidth: true,
        type: component.type,
        required: component.required,
        multiline: component.type == "textarea",
        rows: component.type == "textarea" ? 3 : 1,
        label: component.label,
        placeholder: component.placeholder,
        onFocus: autoFillOnFocus ? autoFillOnFocus : () => { },
        error: errors && errors[component._id] ? true : false
    }

    if (component.type == "number") {
        basicProps.onWheel = (event) => event.target.blur()
        basicProps.onKeyDown = (e) => {
            if (['e', 'E', '+', '-'].includes(e.key)) {
                e.preventDefault();
            }
            else if (e.key === "." && e.target.value.includes(".")) {
                e.preventDefault();
            }
            // else if (e.key == '0' && (e.target.value == "" || e.target.value[0] == '0')) {
            //     e.preventDefault();
            // }
        }
        basicProps = {
            ...field,
            ...basicProps
        }
    }
    if (component.type == "date") {
        basicProps.InputLabelProps = { shrink: true }
        basicProps = {
            ...field,
            ...basicProps
        }
    }
    if (component.type == "file") {
        basicProps.focused = true;
        basicProps.onChange = async (e) => {
            const file = await convertDocsBase64(e.target.files[0])
            field.onChange(file)
        };
    }

    if (component.type != "file") {
        basicProps = {
            ...field,
            ...basicProps
        }
    }

    if (["input", "number", "textarea", "date", "file", "searchTags"].indexOf(component.type) >= 0) {
        // console.log("component.type",component.type,other.value,field.value)
        let multiple=false 
        if(["input", "number","date"].indexOf(component.type) >= 0&& component?.multiple==true){
            multiple=true
        }
        // console.log(";;;;;;",component)
        if(multiple){
            return (<MultiInputField
                other={other}
                basicProps={basicProps}
                inputProps={inputProps}
                component={component}
            />)
        }
        return (
            <TextField
                {...basicProps}
                {...other}
                sx={{ ...inputClasses.themeBoundary }}
                InputProps={
                    inputProps ? { endAdornment: inputProps } : {}
                }
            />
        )
    }
    else if (component.type == "select") {
        // console.log(component._id,"value",field.value, other.value, selectOptions)
        let { onChange, handleDataChange,value:otherValue, ...rest } = other
        let {value:fieldValue,...restField}=field
        // if(component.dynamic){
        //     console.log("rest",rest,field,selectOptions,selectOptions.find(dl => dl.value == (rest.value ? rest.value : field.value))?.label)
        // }
        return (
            <>
                <FormControl
                    fullWidth={basicProps.fullWidth}
                    size={basicProps.size}
                    required={basicProps.required}
                    error={basicProps.error}
                >
                    {
                        !component.dynamic
                            ? <>
                                <InputLabel>{component.label}</InputLabel>
                                <Select
                                    {...restField}
                                    label={`${component?.label}${component?.required ? "*" : ""}`}
                                    value={otherValue || fieldValue || ""}
                                    onFocus={basicProps.onFocus}
                                    {...rest}
                                    onChange={handleDataChange}
                                    sx={{ ...inputClasses.themeBoundary }}
                                >
                                    <MenuItem sx={{ color: "gray" }} value={""}>{component.placeholder ? component.placeholder : "Select"}</MenuItem>
                                    {
                                        component.values && component.values.map((op, i) => {
                                            return <MenuItem value={op["value"]} key={i}>{op["label"]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </>
                            :
                            <Autocomplete
                                id="gmap-demo"
                                {...restField}
                                {...rest}
                                {...(openModal=="isGenerateOption" ? {disabled:true}:{})}
                                // freeSolo={component?.freeSolo==true}
                                sx={{ ...inputClasses.themeBoundary, marginRight: '-10px' }}
                                // onFocus={basicProps.onFocus}
                                filterOptions={(options, params) => {
                                    const filtered = filterMethod(options, params);

                                    if (
                                        (params.inputValue !== '' && (["UOM"].includes(component._id) || component?.addNewOptions?.isActive == true)) ||
                                        (component?.addNewOptions?.isActive == true && component?.addNewOptions?.isGenerateValue == true)
                                    ) {
                                        let addMethod = component._id == "UOM"
                                            ? "isAddOption"
                                            : component?.addNewOptions?.isGenerateValue == true
                                                ? "isGenerateOption"
                                                : component?.addNewOptions?.isFreeSolo == true
                                                    ? "isFreeSolo"
                                                    : "isAddOption"
                                        filtered.unshift({
                                            value: params.inputValue,
                                            label: `${addMethod == "isGenerateOption" ? "Generate New " : "Add"}${addMethod == "isGenerateOption" ? component.label || "ID" : ` "` + params.inputValue + `"`}`,
                                            addMethod: addMethod
                                        });
                                    }
                                    return filtered;
                                }}
                                options={selectOptions}
                                // getOptionLabel={(option) => console.log(option,"option",option?.label)}
                                // isOptionEqualToValue={(option,value) => console.log(option,"option",value)}
                                // isOptionEqualToValue={(option, value) => option.value === value.value}
                                // {...((component.renderOption?.length > 0 || component.suffix) > 0 ? {
                                //     value: (selectOptions?.length && (other.value || field.value) &&
                                //         selectOptions.find(dl => dl.value == (other.value ? other.value : field.value))?.label)
                                //         || (other.value ? other.value : field.value) || ""
                                // } : {})}
                                value={otherValue || fieldValue || null}
                                // value={selectOptions.find(dl => dl.value == (otherValue ? otherValue : fieldValue))?.label || null}
                                getOptionLabel={(option) => {
                                    // console.log("option",option)
                                    if(typeof option=="string"){
                                        return selectOptions.find(dl=>dl.value==option)?.label || option || ""
                                    }
                                    else{
                                      return option?.label  
                                    }
                                }} // Ensures label is displayed
                                isOptionEqualToValue={(option, value) => option.value === value?.value} // Matches selected value
                                // value={selectOptions.find((opt) => opt.value === (field.value || rest.value))?.label || null} // Bind the correct option object
                                autoComplete
                                size="small"
                                includeInputInList
                                filterSelectedOptions
                                noOptionsText={searching
                                    ? searching
                                    : inputValue && "No Data Found" || "Type Here"
                                }
                                onChange={(event, newValue) => {
                                    if(newValue?.addMethod == "isFreeSolo"){
                                        newValue.isNewGenerated=true
                                    }
                                    handleDataChange({ target: { value: newValue?.value || "" } }, newValue)
                                    // setEnableFetch(newValue?.isNewGenerated ?  false: !newValue.value)
                                    // setEnableFetch(false)
                                    setSelectOptions(newValue ? [newValue] : []);
                                }}
                                onFocus={() => {
                                    if (!otherValue && !fieldValue) {
                                        setSearching("wait....")
                                        setSelectOptions([])
                                        fetchOptions("")
                                    }
                                }}
                                handleKeyDown={(event) => {
                                    setSearching("Typing...")
                                }}
                                onInputChange={handleInputChange}
                                // onInputChange={(event, newInputValue) => {
                                //     // if(searching=="Typing..."){
                                //         setInputValue(newInputValue);
                                //         setSelectOptions(selectOptions?.some(dl=>dl?.isNewGenerated)?selectOptions?.filter(dl=>dl?.isNewGenerated):[])
                                //         // setSearching(newInputValue ? "Typing..." : "")
                                //         setEnableFetch(true)
                                //         console.log("hhh")
                                //         // setEnableFetch(newInputValue != prevInput || false)
                                //         // newInputValue != prevInput || false
                                //     // }
                                // }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label={`${component.label}${component.required ? "*" : ""}`}
                                        fullWidth
                                        sx={{
                                            ...selectStylesOverride,
                                        }}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => {//, "customShipment"
                                    if (["UOM"].includes(component._id) ? option?.addMethod == "isAddOption" : ["isGenerateOption", "isAddOption", "isFreeSolo"].includes(option?.addMethod)) {
                                        return <MenuItem
                                            key={option.value}
                                            sx={{ color: theme.themeOrange }}
                                            {...["isGenerateOption", "isAddOption"].includes(option?.addMethod) ? {
                                                onClick: () => handleOpenModal(option,handleDataChange)
                                            } : {}}
                                            {...(option?.addMethod == "isFreeSolo" ? props : {})}
                                        >
                                            <Add fontSize='inherit' /> {option.label}
                                        </MenuItem>
                                    }
                                    return (
                                        <>
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                sx={{ justifyContent: "space-between" }}
                                                {...props}
                                            >
                                                <Tooltip sx={{
                                                    width: '50px'
                                                }} title={option.label}>
                                                    <Typography>
                                                        {option?.renderOptionLabel || option?.label || ""}
                                                        {/* {getShortText(option.label, 21)} */}
                                                    </Typography>

                                                </Tooltip>
                                                {selected ? <CheckIcon color="info" /> : null}
                                            </MenuItem>
                                        </>
                                    )
                                }}
                                inputValue={inputValue}
                            />
                    }
                </FormControl>
                {openModal=="isAddOption" && <AddUom open={!!openModal} handleClose={()=>setOpenModal(false)} inputValue={inputValue}/>}
            </>
        )
    }
    else if (component.type == "radio") {
        const innerComp = (
            <RadioGroup
                row
                {...field}
                sx={{ mt: 1 }}
            >
                {
                    component.values && component.values.map((op, i) => {
                        return (
                            <FormControlLabel
                                key={i}
                                {...other}
                                label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                control={<Radio value={op.value} size="small" sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} />}
                            />
                        )
                    })
                }
            </RadioGroup>
        )
        if (multiline != null && multiline == false) {
            return (
                <Grid container justifyContent={"left"} alignItems="center">
                    {innerComp}
                </Grid>
            )
        }
        return (
            <div style={{ ...inputClasses.shadowField, border: `1px solid ${basicProps.error ? "#e74c3c" : "#D9D9D9"}`, width: "100%", padding: "10px" }}>
                <FormControl fullWidth size='small' {...other}>
                    <FormLabel
                        sx={{
                            color: "rgb(0 0 0 / 60%) !important",
                            "& .MuiFormLabel-root.Mui-focused": {
                                color: "#F77200",
                            }
                        }}
                    >
                        {component.label}
                    </FormLabel>
                    {innerComp}
                </FormControl>
            </div>
        )
    }
    else if (component.type == "checkbox") {
        let { onChange, ...rest } = other
        const innerComp = (
            <FormGroup
                row
                sx={{ mt: 1 }}
            >
                {
                    component.values && component.values.map((op, i) => {
                        // console.log("oppp",op)
                        // console.log("fff",field)

                        return (
                            <FormControlLabel

                                key={i}
                                {...rest}
                                label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                control={<Checkbox
                                    checked={field?.value?.includes(op.value)}
                                    // onChange={() => field.onChange(handleSelect(op.value, field.value || []))}
                                    onChange={() =>  field.onChange({target:{value:handleSelect(op.value, field.value || [])}})}
                                    size="small"
                                    sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} />}
                            />
                        )
                    })
                }
            </FormGroup>
        )
        if (multiline != null && multiline == false) {
            return (
                <Grid container justifyContent={"left"} alignItems="center">
                    {innerComp}
                </Grid>
            )
        }
        return (
            <div style={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormControl fullWidth size='small' {...other}>
                    <FormLabel
                        sx={{
                            color: "rgb(0 0 0 / 60%) !important",
                            "& .MuiFormLabel-root.Mui-focused": {
                                color: "#F77200",
                            }
                        }}
                    >
                        {component.label}
                    </FormLabel>
                    {innerComp}
                </FormControl>
            </div>
        )
    }
}

export const CustomControlledInput = ({
    component, control, errors, autoFillOnFocus, register, action, multiline, inputProps,
    handleChange = false
}) => {
    return (
        <>
            <Grid container justifyContent={"center"} alignItems="center">

                <Controller
                    name={component._id}
                    control={control}
                    ref={register}
                    rules={{
                        required: {
                            value: component.required,
                            message: `${component.label} is required`
                        },
                        pattern: {
                            value: component.regex ? new RegExp(component.regex) : "",
                            message: `${component.errorText || "Invalid value"} for ${component.label}`
                        }
                    }}
                    defaultValue={component.defaultValue || ""}
                    render={
                        ({ field }) => (
                            <>
                            <CustomInput
                                component={component}
                                autoFillOnFocus={autoFillOnFocus}
                                errors={errors}
                                field={{
                                    ...field,
                                    ...(handleChange ? {
                                        onChange: (event) => handleChange(event, component?._id || "",component)
                                    } : {})
                                }}
                                handleDataChange={(...rest) => {
                                    if (component.onChange) {
                                        // console.log("no onChange",handleChange)
                                        component.onChange(...rest)
                                    }
                                    else if (handleChange) {
                                        handleChange(rest[0], component?._id,component,...rest.slice(1,rest.length))
                                    }
                                    else if (field.onChange) {
                                        field.onChange(...rest)
                                    }
                                    else {
                                        // console.log("no onChange2")
                                    }
                                }}
                                disabled={(() => {
                                    // console.log("feild",field,component)
                                    if (action == "view") {
                                        return true;
                                    }
                                    if (component.disabled != null) {
                                        return component.disabled
                                    }
                                    return false;
                                    // disabled != null ? disabled : false
                                })()}
                                inputProps={inputProps}
                                // disabled={action === 'view' ? true : (component.disabled)}
                                multiline={multiline != null ? multiline : null}
                            />
                            </>
                        )
                    }
                />
            </Grid>
            {/* {console.log("errors12",errors)} */}
            {errors && errors[component._id] &&
                <Grid container>
                    <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                        {errors && errors[component._id] ? errors[component._id].message : ""}
                    </Typography>
                </Grid>
            }
        </>
    )
}

export const getInputField = ({ component,componentValues={}, disabled = false, errors = {}, handleChange = () => { } }) => {
    switch (component.type) {
        case "input": return (
            <>
                <TextField
                    required={component.required} size='small' fullWidth disabled={disabled} label={component.label}
                    placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }}
                    onChange={(event) => {
                        handleChange(component, event)
                    }}
                    value={componentValues[component?._id] || ""}
                />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "number": return (
            <>
                <TextField
                    required={component.required} type="number" size='small' fullWidth disabled={disabled}
                    label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }}
                    onChange={(event) => {
                        handleChange(component, event)
                    }}
                    value={componentValues[component?._id] || ""}
                />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "date": return (
            <>
                <DatePicker
                    disabled
                    label={component.label}
                    sx={{ ...inputClasses.shadowField }}
                    slotProps={{ textField: { size: "small", fullWidth: true, required: component.required } }}
                    onChange={(date)=>{
                        handleChange(component,date)
                    }}
                    value={componentValues[component?._id] || ""}
                />                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "textarea": return (
            <>
                <TextField 
                required={component.required} multiline rows={3} size='small' disabled={disabled}
                 fullWidth label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} 
                 onChange={(event)=>{
                    handleChange(component,event)
                }}
                value={componentValues[component?._id] || ""}
                 />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "searchTags": return (
            <Grid sx={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormLabel required={component.required} component="legend">{component.label}</FormLabel>
                {
                    component.values && component.values.map((op, i) => {
                        return <FormControlLabel
                            disabled={disabled}
                            control={<Checkbox sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                            value={op.value} key={i} size='small' />}
                            label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                            onChange={(event) => {
                                handleChange(component, event)
                            }}
                            checked={(componentValues[component?._id] || []).includes(op.value)}
                        />
                    })
                }                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
        case "file": return (
            <>
                <TextField 
                required={component.required} type="file" size='small' 
                fullWidth disabled={disabled} label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} 
                onChange={(event) => {
                    handleChange(component, event)
                }}
                value={componentValues[component?._id] || ""}
                />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>)
        case "select": return (
            <>
                <FormControl required={component.required} fullWidth size='small' disabled={disabled}>
                    <InputLabel>{component.label}</InputLabel>
                    <Select
                        sx={{ ...inputClasses.shadowField }}
                        label={component.label}
                        onChange={(event) => {
                            handleChange(component, event)
                        }}
                        value={componentValues[component?._id] || ""}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return <MenuItem value={op.value} key={i}>{op.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>            {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>

        )
        case "checkbox": return (
            <Grid  justifyContent={"center"} alignItems="center" sx={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormLabel required={component.required} component="legend">{component.label}</FormLabel>
                {
                    component.values && component.values.map((op, i) => {
                        return <FormControlLabel 
                            disabled={disabled}
                            control={<Checkbox
                                sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                                value={op.value} key={i} size='small'
                                checked={(componentValues[component?._id] || []).includes(op.value)}
                            />} label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                            onChange={(event) => {
                                handleChange(component, event)
                            }}

                        />
                    })
                }
                {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
        case "radio": return (
            <Grid sx={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormControl>
                    <FormLabel required={component.required} id="demo-row-radio-buttons-group-label">{component.label}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ mt: 1 }}
                        onChange={(event) => {
                            handleChange(component, event)
                        }}
                        value={componentValues[component?._id] || ""}

                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return <FormControlLabel disabled={disabled} control={<Radio sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} value={op.value} key={i} size="small" />} label={op.label} key={i} />
                            })
                        }
                    </RadioGroup>
                </FormControl>
                {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>

        )
        default:
            <>
                <TextField 
                required={component.required} size='small' fullWidth disabled={disabled} 
                label={component.label} placeholder={component.placeholder} 
                sx={{ ...inputClasses.shadowField }} 
                onChange={(event) => {
                    handleChange(component, event)
                }}
                />
                value={componentValues[component?._id] || ""}
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
    }
}

export const getFieldsLayout = ({ fields, disabled = false,componentValues={},errors = {}, handleChange }) => {
    let layout = {
        single: [],
        multi: []
    };
    for (let i = 0; i < fields.length; i++) {
        let component = fields[i]
        if (["input", "number", "date", "searchTags", "file", "select"].indexOf(fields[i].type) >= 0) {

            layout.single.push(
                getInputField({ component, disabled,componentValues, errors, handleChange })
            )
        }
        else {
            layout.multi.push(
                getInputField({ component, disabled,componentValues, errors, handleChange })
            )
        }

    }
    return layout;

}

export const getInputFieldForTableRow = (component, items, index, handleChange, handleFocus, errors, showLabelForMulti, inputProps, disabled) => {


    switch (component?.type) {
        case "input":
        case "number":
        case "textarea":
        case "date": return (
            <>
                <Grid container justifyContent={"center"} alignItems="center">
                    <CustomInput
                        component={component}
                        autoFillOnFocus={() => handleFocus(index)}
                        errors={errors[index]}
                        field={{}}
                        // defaultValue={calculateFieldValue({value:items[index][component._id] || ""})}
                        value={items[index][component._id]
                            ? items[index][component._id]
                            : component.type == "checkbox" && [] || ""
                        }
                        onChange={(e) => handleChange(e, index, component._id)}
                        inputProps={inputProps}
                        disabled={(() => {
                            if (disabled == null) {
                                if (component.disabled != null) {
                                    return component.disabled
                                }
                                return false;
                            }
                            return disabled;
                            // disabled != null ? disabled : false
                        })()}
                    />
                </Grid>
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )

        case "searchTags": return (
            <TextField onFocus={() => handleFocus(index)} value={items[index][component._id] ? items[index][component._id] : ""} onChange={(e) => handleChange(e, index, component._id)} required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} />
        )
        case "file": return (
            <>
                <TextField
                    size='small'
                    focused
                    fullWidth
                    type={"file"}
                    label={component.label}
                    required={component.required}
                    onFocus={() => handleFocus(index)}
                    onChange={(e) => handleChange(e, index, component._id)}
                    sx={{ ...inputClasses.shadowField }}
                />
                {
                    (items[index][component._id] || [])?.length > 0 && (items[index][component._id] || [])?.at(0)?.name &&
                    <Typography sx={{ ...textClasses.t12n, mt: "5px" }}>Selected File: {(items[index][component._id] || [])?.at(0)?.name}</Typography>
                }
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "select":
            return (
                <>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <CustomInput
                            component={component}
                            autoFillOnFocus={() => handleFocus(index)}
                            errors={errors[index]}
                            field={{}}
                            // defaultValue={calculateFieldValue({value:items[index][component._id]})}
                            value={items[index][component._id]
                                ? items[index][component._id]
                                : component.type == "checkbox" && [] || ""
                            }
                            // onChange={(e) => handleChange(e, index, component._id)}
                            handleDataChange={(e,option={}) => handleChange(e, index, component._id,option)}

                            disabled={(() => {
                                if (disabled == null) {
                                    if (component.disabled != null) {
                                        return component.disabled
                                    }
                                    return false;
                                }
                                return disabled;
                                // disabled != null ? disabled : false
                            })()}
                        />

                    </Grid>
                    {
                        <Grid container>
                            <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                                {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                            </Typography>
                        </Grid>
                    }
                </>
            )
        case "checkbox": return (
            <>
                <Grid container justifyContent={"center"} alignItems="center">
                    {
                        showLabelForMulti &&
                        <FormLabel required={component.required} sx={{ mr: 1, mt: 1 }}>{component.label}: </FormLabel>
                    }

                    <FormGroup
                        row
                        sx={{ mt: 1 }}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                        control={
                                            <Checkbox
                                                checked={items[index][component._id] && items[index][component._id].includes(op.value)}
                                                onChange={(e) => handleChange({ checked: e.target.checked, value: op.value }, index, component._id)}
                                                size="small"
                                                sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                                            />
                                        }
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </Grid>
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }

            </>

        )
        case "radio": return (
            <>
                <Grid container justifyContent={"center"} alignItems="center">
                    {
                        showLabelForMulti &&
                        <FormLabel required={component.required} sx={{ mr: 1 }}>{component.label}: </FormLabel>
                    }
                    <RadioGroup
                        row
                        value={items[index][component._id] ? items[index][component._id] : ""}
                        // defaultValue={calculateFieldValue({value:items[index][component._id] || ""})}
                        onChange={(e) => handleChange(e, index, component._id)}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                        control={<Radio sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} value={op.value} size="small" />}
                                    />
                                )
                            })
                        }
                    </RadioGroup>
                </Grid>
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        default:
            <TextField onFocus={() => handleFocus(index)} value={items[index][component._id] ? items[index][component._id] : ""} onChange={(e) => handleChange(e, index, component._id)} required size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} />
    }
}

export const getFieldsLayoutForEntry = (fields, register, control, handleFocus, errors, action, handleChange) => {
    let layout = {
        single: [],
        multi: []
    };
    // console.log("fields",fields?.length)
    for (let i = 0; i < fields.length; i++) {
        if (["input", "number", "date", "searchTags", "file", "select"].indexOf(fields[i].type) >= 0) {
            layout.single.push(
                <CustomControlledInput action={action}
                    handleChange={handleChange}
                    register={register} component={fields[i]} control={control} errors={errors} autoFillOnFocus={handleFocus} />
            )
        }
        else {
            layout.multi.push(
                <CustomControlledInput action={action}
                    register={register}
                    handleChange={handleChange}
                    component={fields[i]} control={control} errors={errors} autoFillOnFocus={handleFocus} />
            )
        }

    }
    return layout;

}

export const DataNotFound = ({height='250px', width='250px',label="No Data Found"}) => {
    return <>
        <Box>
            <img src={no_data_found} height={height} width={width} />
            <Typography  sx={{ textAlign: label !== "No Data Found" ? 'center' : '' }}>{label}</Typography>
        </Box>
    </>
}