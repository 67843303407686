
export const GST_Validation = (GST) => {
    var regex = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}Z[a-zA-Zd]{1}?$/;
    if(GST.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter valid gst number"};
    }
}

export const PAN_Validation = (PAN) => {
    var regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}?$/;
    if(PAN.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter valid permanent account number"};
    }
}

export const Mobile_Validation = (mobile) => {
    var regex = /^[6-9][0-9]{9}$/;
    if((mobile+"").match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Mobile Number must be 10 digit and start with (6-9)"};
    }
}

export const Email_Validation = (Email) => {
    var regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    // //("Email",Email,regex);
    if(Email.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter valid email id"};
    }
}

export const Password_Validation = (Password) => {
    var regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%]).{8,}$/;
    if((Password+"").match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Password is not a valid. It should have at least 8 characters, contain at least one capital letter one small letter, one number and one special character (@#$%)"};
    }
}

export const Pincode_Validation = (Pincode) => {
    var regex = /^\\d{6}$/;
    if((Pincode+"").match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter valid pincode"};
    }
}

export const NUMBER_REGEX_INTEGER=/^[1-9][0-9]*$/
export const Number_Validation_Integer = (number) => {
    var regex = NUMBER_REGEX_INTEGER || /^[1-9][0-9]*$/;
    if((number + "").match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter integer number only"};
    } 
}
export const NUMBER_REGEX_ANY=/^(0|[1-9]\d*)?(\.\d+)?$/ || /^\d+(\.\d+)?$/
export const Number_Only_Validation = (number) => {
    var regex = NUMBER_REGEX_ANY || /^\d+(\.\d+)?$/;
    if((number + "").match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter number only"};
    }
}
export const Alphanumberic_Input_Validation = (Alphanumberic_Input) => {
    var regex = /^[a-zA-Z0-9]+$/;
    if(Alphanumberic_Input.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter Alphanumberic value only"};
    }
}

export const Alphanumberic_Input_Char4_Digit7_Validation = (Char4_Digit7) => {
    var regex = /^([a-zA-Z]{4})([0-9]{7})$/;
    if(Char4_Digit7.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter Alphanumberic Char-4,Digit-7 value only"};
    }
}

export const Alphabetic_Input_Validation = (Alphabetic_Input) => {
    var regex = /^[a-zA-Z\\s]+$/;//^[a-zA-Z]+$
    if(Alphabetic_Input.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter Alphabetic value only"};
    }
}

export const decaimal_number_Validation = (decaimal_number) => {
    var regex = /^((\\d+(\\.\\d*)?)|(\\.\\d+))$/;
    if(decaimal_number.match(regex)){
        return {success:true};
    }
    else{
        return {success:false,message:"Enter decaimal number only"};
    }
}

