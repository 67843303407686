import React from "react";
import {
    Grid,
    Typography,
} from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import { Add, Edit, Minus } from "@mui/icons-material"
import { getInputFieldForTableRow } from "../../Components/TemplateInputConfiguration";
import RemoveIcon from '@mui/icons-material/Remove';
import { convertDocsBase64 } from "../../../services/functions";


const MultipleFormSave = ({ formAttributes = [], type, items=[], setItems,errors=[],isEditable}) => {

    const dispatch = useDispatch();

    const handleDeleteForm = (index) =>{
        let tempItem= [...items]
        tempItem.splice(index,1)
        setItems(tempItem)
    }

    const handleGenericChange = async (event, i, fieldId) => {
        const targetField = formAttributes.find(f => f._id == fieldId);
        let oldItems = [...items];
        let oldObject = { ...oldItems[i] };
        if (targetField.type == "file") {
            const file = await convertDocsBase64(event.target.files[0]);
            oldObject[fieldId] = file;
        }
        else if (targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if (event.checked) {
                oldValues.push(event.value);
            }
            else {
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else {
            oldObject[fieldId] = event.target.value;
        }
        oldItems[i] = { ...oldObject };
        setItems([...oldItems]);
    }


    const getItemRow = (i) => {
        let item = items[i];
        const others = formAttributes?.map((of) => {
            let comp = { ...of };
            if ((comp._id == "row" || comp._id=="col") && item?.col && item?.row && isEditable) {
                comp.disabled = true;
            }
            else {
                comp.disabled = false;
            }
            return (
                getInputFieldForTableRow(comp, items, i, handleGenericChange, ()=>{}, errors, false)
            )
        })

        return others
    }

    const rows = items.map((item, i) => {
        return getItemRow(i);
    })

    return (<>
        <Grid container >
            {
                rows?.map((dl, parentIndex) => {
                    return <Grid container sx={{ overflowY: 'auto', border: '1px solid #E8E8E8', overflowX: 'hidden', padding: '10px', paddingTop: '20px', paddingBottom: '20px', mt: 3, mb: 3 }}>
                        <Grid item container xs={12} >
                            {dl?.map((elem, childIndex) => {
                                return <Grid item container xs={4} sx={{ display: "flex", justifyContent: 'space-between' }} >
                                    <Grid item container xs={12} sx={{ display: "flex", justifyContent: 'space-between', p: 1 }} >
                                        {elem}
                                    </Grid>

                                </Grid>
                            })}
                        </Grid>
                        {rows?.length > 1 && <Typography
                            sx={{
                                ...textClasses.boldText,
                                color: theme.themeOrange,
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                        onClick={(i) => handleDeleteForm(parentIndex) }
                        >
                            <RemoveIcon fontSize='inherit' />
                            Remove {type.toLowerCase()}
                        </Typography>}
                    </Grid>

                })
            }
        </Grid>
        {!isEditable &&
        <Typography
            sx={{
                ...textClasses.boldText,
                color: theme.themeOrange,
                textDecoration: "underline",
                cursor: "pointer"
            }}
        onClick={() => setItems([...items,{}])}
        >
            <Add fontSize='inherit' />
            Add {type.toLowerCase()}
        </Typography>
        }

    </>)
}

export default MultipleFormSave;