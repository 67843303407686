import React, { useState, useEffect } from "react";
import { textClasses, inputClasses } from "../../../../../static/styles/theme";
import plusCreateIcon from "../../../../../static/images/Icon/createAutomation/plusCreateIcon.svg";
import { Autocomplete, Grid, TextField, Typography, Button, FormControl } from "@mui/material";
import { selectStylesOverride } from "../../../../../utils/util";
import { ADD_COMPONENT, ADD_CONDITIONS, jobTypes } from "../constant";
import AddComponent from "./AddComponent";
import AddConditions from "./AddConditions";
import { useDispatch, useSelector } from "react-redux";
import { buttonClasses } from "../../../../../static/styles/theme";
import { getJobTypeListByWorkflow } from "../../../../../redux/actions";
import PublishIcon from '@mui/icons-material/Publish';
import SubmitAutomationRuleView from "../SubmitAutomationRuleView";
import SelectImage from "../../../../../static/images/templates/select_template.svg"
import { saveAutomationTriggerRules } from "../../../../../redux/actions";
import { showToast } from "../../../../../services/functions";
import history from "../../../../../services/history";


const JobCreationStep = ({
  selectJobType,
  setSelectJobType,
  setCreationStep,
  creationStep,
  jobTypefilter,
  setJobTypefilter,
  resetStates,
  workflowFilter,
  taskFieldValue,
  setTaskFieldValue,
  conditionValue,
  setConditionValue,
  fieldConditionalValue,
  setFieldConditionalValue,
  addEmail,
  setAddEmail,
  visibleEmail,
  setVisibleEmail,
  ruleName,
  setRuleName,
  selectJobAutomation,
  setSelectJobAutomation
}) => {
  const dispatch = useDispatch();
  const [viewModal, setViewModal] = useState({ type: '', action: '' })
  const [jobTypeLists, setJobTypeLists] = useState([]);
  const [dataReceieved, isDataReceived] = useState(false)
  const [displayAddAnAction, setDisplayAddAnAction] = useState(false);
  const [displayCreateJob, setDisplayCreateJob] = useState(false);
  const [displayEmailSection, setDisplayEmailSection] = useState(false);
  const isAddComponent = creationStep === ADD_COMPONENT;
  const isAddConditions = creationStep === ADD_CONDITIONS;

  const getNextJobTypeItems = (selectedItemId) => {
    // Find the index of the selected item
    const selectedIndex = jobTypeLists?.findIndex(item => item?._id === selectedItemId);

    // If the item is found, return the items that come after it
    if (selectedIndex !== -1) {
      return jobTypeLists?.slice(selectedIndex + 1); // Get items after the selected item
    }
    // Return an empty array if the item is not found
    return [];
  };
  // console.log("last page values",workflowFilter,conditionValue,taskFieldValue,fieldConditionalValue, selectJobType,visibleEmail, selectJobAutomation)
  const handleSubmitAutomationRule = () => {
    isDataReceived(true)
    const payload = {
      type: "automation",
      ruleCategory: selectJobAutomation?.value || "",
      ruleName: ruleName || "",
      workFlowId: workflowFilter?.workflowId || "",
      workFlowName: workflowFilter?.workflowName,
      jobTypeID: jobTypefilter?._id || "",
      jobTypeName: jobTypefilter?.name || "",
      rules: [
        {
          rule: {
            operator: conditionValue?.value || "",
            operand1: taskFieldValue?.value || "",
            operand2: fieldConditionalValue[`${taskFieldValue?.type}Value`] || "",
          },
          action: [
            visibleEmail?.length > 0
              ? { type: "email", value: visibleEmail }
              : selectJobType
                ? { type: "createJob", value: selectJobType?._id }
                : { type: "", value: "" }
          ],
        },
      ],
    };

    // console.log("payload",payload)
    dispatch(saveAutomationTriggerRules(payload, (data) => {
      showToast(data.message, !data.success);
      isDataReceived(false)
      if (data.success) {
        if (resetStates) {
          resetStates();
        }
        history.push("/dashboard/setting/automation-rules")
      }
    }))
  }
  const nextJobTypeList = getNextJobTypeItems(jobTypefilter?._id)

  const submitBtnDisableCondition = () => {
    // Check if ruleName or other required fields are empty
    let isRequiredFieldEmpty =
      !jobTypefilter || // jobTypefilter is empty
      !ruleName || // ruleName is empty
      Object.values(fieldConditionalValue).every((value) => value === "") || // All fieldConditionalValue fields are empty
      !conditionValue || // conditionValue is empty
      !taskFieldValue || // taskFieldValue is empty
      !displayAddAnAction;
  
    if (isRequiredFieldEmpty) {
      return true; // Button disabled if required fields are empty
    }
  
    // Handle displayAddAnAction condition
    if (displayAddAnAction === true) {
      const isAnyFieldFilled = (visibleEmail && visibleEmail.length > 0) || selectJobType;
      return !isAnyFieldFilled; // Disable if both fields are empty
    }
  
    // Handle displayEmailSection condition
    if (displayEmailSection === true) {
      return !visibleEmail || visibleEmail?.length === 0; // Disable if visibleEmail is empty
    }
  
    // Handle displayCreateJob condition
    if (displayCreateJob === true) {
      return !selectJobType; // Disable if selectJobType is empty
    }
  
    return isRequiredFieldEmpty;
  };
  
  useEffect(() => {
    if (workflowFilter?.workflowName) {
      dispatch(getJobTypeListByWorkflow({ workFlowId: workflowFilter?._id }, (data) => {
        setJobTypeLists(data?.data)
      }))
    }
    else {
      setJobTypefilter(null)
    }

  }, [workflowFilter])
  return (
    <>
      {
        workflowFilter?.workflowName ?
          <>
            <Grid
              sx={{
                minHeight: "725px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                border: "1px solid #E0E0E0",
                backgroundColor: "white",
                boxShadow: "0px 3px 5px #D9D9D9",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                  paddingLeft: "40px",
                }}
              >
                <img src={plusCreateIcon} />
                <Typography
                  sx={{
                    paddingLeft: "10px",
                    ...textClasses.t12n,
                    color: "#000000",
                  }}
                >
                  When A New Job Created{" "}
                </Typography>
              </Grid>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  color: "#827878",
                  paddingLeft: "50px",
                  marginTop: "5px",
                }}
              >
                Rule is run when a new Job or Task created by users or automatically
                created{" "}
              </Typography>
              <Grid
                container
                item
                justifyContent="flex-start"
                sx={{
                  marginTop: "10px",
                  marginLeft: "40px",
                }}
                data-testid="select-jobtype"
              >
                <Autocomplete
                  value={jobTypefilter}
                  onChange={(e, option) => {
                    setJobTypefilter(option);
                    if (option) {
                      setCreationStep(ADD_COMPONENT);
                    } else {
                      resetStates();
                      setCreationStep("");
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  options={jobTypeLists || []}
                  sx={{
                    ...inputClasses.filterField,
                    minWidth: "380px",
                    background: "white",
                    ".MuiInputBase-input": {
                      fontSize: "15px",
                      color: "yellow",
                      "& label.Mui-focused": {
                        color: "#455A64",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "yellow", ...textClasses.normalText }}
                      {...params}
                      label="Select a Job Type "
                    />
                  )}
                />
              </Grid>
              {isAddComponent && <AddComponent setCreationStep={setCreationStep} />}
              {isAddConditions && (
                <AddConditions
                  selectJobType={selectJobType}
                  setSelectJobType={setSelectJobType}
                  nextJobTypeList={nextJobTypeList}
                  taskFieldValue={taskFieldValue}
                  setTaskFieldValue={setTaskFieldValue}
                  conditionValue={conditionValue}
                  setConditionValue={setConditionValue}
                  fieldConditionalValue={fieldConditionalValue}
                  setFieldConditionalValue={setFieldConditionalValue}
                  addEmail={addEmail}
                  setAddEmail={setAddEmail}
                  visibleEmail={visibleEmail}
                  setVisibleEmail={setVisibleEmail}
                  selectJobAutomation={selectJobAutomation}
                  setSelectJobAutomation={setSelectJobAutomation}
                  displayCreateJob={displayCreateJob}
                  setDisplayCreateJob={setDisplayCreateJob}
                  displayEmailSection={displayEmailSection}
                  setDisplayEmailSection={setDisplayEmailSection}
                  displayAddAnAction={displayAddAnAction}
                  setDisplayAddAnAction={setDisplayAddAnAction}
                />
              )}
              <Grid item xs={4} sx={{ mt: 2, ml: 5 }}>
                <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                  <TextField
                    label={<Typography sx={{ fontSize: "12px" }}>Rule Name*</Typography>}
                    data-testid="enter-rulename"
                    labelId="job-name-label"
                    type='text'
                    size="small"
                    sx={{
                      fontSize: "12px",
                      width: '100%',
                      ...selectStylesOverride,
                      "& input::placeholder": {
                        fontSize: "12px",
                      },
                    }}
                    focusColor='red'
                    value={ruleName || ""}
                    onChange={(e) => setRuleName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                size="small"
                data-testid="submit-btn"
                startIcon={<PublishIcon />}
                disabled={dataReceieved || submitBtnDisableCondition()}
                sx={{
                  ...buttonClasses.lynkitOrangeFill,
                  mt: 2, ml: 5
                }}
                onClick={
                  handleSubmitAutomationRule
                  // setViewModal({ ...viewModal, type: 'submitAutomationRule' })
                  // if (resetStates) {
                  //   resetStates();
                  // }
                }
              >
                Submit
              </Button>
            </Grid>
            {viewModal.type == "submitAutomationRule" &&
              <SubmitAutomationRuleView
                viewModal={viewModal}
                setViewModal={setViewModal}
              />}
          </> :
          <>
            <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
              <img src={SelectImage} />
              <Typography sx={{ ...textClasses.t20n, mt: 2 }}>Hey, Please Select the Workflow first...</Typography>
            </Grid>
          </>
      }
    </>
  );
};

export default JobCreationStep;
