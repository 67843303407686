import {FETCH_SKU_GROUP_DATA, FETCH_AUTOMATION_RULES_STATUS_COUNT, FETCH_SKU_GROUP_DETAIL, FETCH_AUTOMATION_TABLE_DATA, FETCH_AUTOMATION_TASK_FIELDS, FETCH_JOB_TYPES_BY_WORKFLOW} from "../types"

const INIT_STATE = {
    skuGroupTableData: [],
    totalRows: 0,
    skuGroupDetail: null,
    automationRulesStatusCount: null,
    automationRuleTableData: [],
    automationRuleTableRows: 0,
    automationTaskFields: null,
    jobTypeListsByWorkflow: []


}
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_SKU_GROUP_DATA: return { ...state, skuGroupTableData: action?.payload?.data || [], totalRows: action?.payload?.count || 0 }
        case FETCH_AUTOMATION_RULES_STATUS_COUNT: return { ...state, automationRulesStatusCount: action?.payload}
        case FETCH_SKU_GROUP_DETAIL: return { ...state, skuGroupDetail: action?.payload?.data || {} }
        case FETCH_AUTOMATION_TASK_FIELDS: return { ...state, automationTaskFields: action?.payload?.data || {} }
        case FETCH_JOB_TYPES_BY_WORKFLOW: return { ...state, jobTypeListsByWorkflow: action?.payload?.data || [] }

        default: return state
    }
}