import React, { useEffect, useRef, useState, useReducer } from 'react'
import { Autocomplete, Breadcrumbs, Button, Grid, Paper, TextField, Typography, FormControl, OutlinedInput, FormControlLabel, Radio, ListSubheader, Checkbox, ListItemText, MenuItem, IconButton } from "@mui/material";
import theme, { buttonClasses, textClasses, cardClasses, inputClasses } from '../../../../static/styles/theme';
import { getCamelCaseText } from '../../../../services/functions';
import { useDispatch, useSelector } from "react-redux";
import { selectStylesOverride } from '../../../../utils/util';
import { Link, useParams } from 'react-router-dom';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { blockAreaOperations, assignUserOptions, manualHandlingOptions, jobCompletionMethodOptions, jobCreationMethodOptions } from '../Constant';
import { addJobTypes, updateJobTypes, getJobTypeDetails } from '../../../../redux/actions';
import { getAllJobActions } from '../../../../redux/actions';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import { showToast, getTimeFormISODate } from '../../../../services/functions';
import history from '../../../../services/history';
import dayjs from 'dayjs';
import moment from 'moment';


let initialFormDataState = {
    "name": "", "actionTypes": [], "disabled": true, "categoryKey": "", "isBlockedArea": "no",
    "isAssignedUser": "optional", "isMHERequired": "no", "jobCreationMethod": "", "jobCompletionMethod": "", "standardDuration": null
}

const initialStatusFlow = [
    {
        statusFlowLabel: 'To Do',
        statusFlowKey: 'todo',
        isCreateButtonAtTop: false,
        textBackgroundColor: 'rgba(250, 10, 10, 0.25)',
        textBorderColor: "rgba(233, 0, 26, 1)",
        statusList: [{
            label: 'Initiated (To Do)',
            isDelete: false,
            isSwap: false,
            isEdit: false,
        }]
    },
    {
        statusFlowLabel: 'Ongoing',
        statusFlowKey: 'ongoing',
        textBackgroundColor: "rgba(233, 252, 62, 0.25)",
        textBorderColor: "rgba(231, 177, 23, 1)",
        statusList: [{
            label: 'ongoing',
            isDelete: true,
            isSwap: true,
            isEdit: true,
        }]
    },
    {
        statusFlowLabel: 'Completed',
        statusFlowKey: 'completed',
        isCreateButtonAtTop: true,
        textBackgroundColor: "rgba(127, 202, 61, 0.25)",
        textBorderColor: "rgba(95, 165, 33, 1)",
        statusList: [{
            label: 'Completed (Done)',
            isDelete: false,
            isSwap: false,
            isEdit: false,
        }]
    },
];

const CreateUpdateJobType = () => {
    let { jobTypeId } = useParams();
    const currentUrl = window.location.pathname
    const isEditable = currentUrl.includes("edit-job-type") && !!jobTypeId;
    const dispatch = useDispatch()
    const { allJobActions } = useSelector((state) => state.job);
    const [jobStatusFlow, setJobStatusFlow] = useState(initialStatusFlow);
    const reducer = (state, action) => {
        switch (action.type) {
            case 'set_initial_state': {
                ////("action.valueObj",action.valueObj);
                let data = {
                    "name": "",
                    "actionTypes": [],
                    "disabled": true,
                    "categoryKey": "",
                    "isBlockedArea": "no",
                    "isAssignedUser": "optional", "isMHERequired": "no", "jobCreationMethod": "", "jobCompletionMethod": "", "standardDuration": null, "jobStatusFlow": []
                }
                if (action.valueObj) {
                    let { name, actionTypes = [], _id, categoryKey = "", isBlockedArea, isAssignedUser, isMHERequired, jobCreationMethod, jobCompletionMethod, standardDuration, jobStatusFlow } = action.valueObj
                    setJobStatusFlow(jobStatusFlow)
                    data = { disabled: true, name: name || "", actionTypes, categoryKey, isBlockedArea, isAssignedUser, isMHERequired, jobCreationMethod, jobCompletionMethod, standardDuration }
                    if (_id) data["jobTypeId"] = _id
                }
                else {
                    setJobStatusFlow(initialStatusFlow)
                }
                return data;
            }
            case 'change_action': {
                // ////("action",action);
                return {
                    ...state,
                    "actionTypes": action.value,
                    "categoryKey": action?.value?.length > 0 && state.categoryKey || "",
                    "disabled": false
                };
            }
            case 'changed_jobname': {
                // ////("action",action);
                return {
                    ...state,
                    "name": action.value || "",
                    "disabled": false
                };
            }
            case 'changed_blockarea_operation': {
                // ////("action",action);
                return {
                    ...state,
                    "isBlockedArea": action.value || "",
                    "disabled": false
                };
            }
            case 'changed_assigned_user': {
                // ////("action",action);
                return {
                    ...state,
                    "isAssignedUser": action.value || "",
                    "disabled": false
                };
            }
            case 'changed_mhe_required': {
                // ////("action",action);
                return {
                    ...state,
                    "isMHERequired": action.value || "",
                    "disabled": false
                };
            }
            case 'changed_jobcreation_method': {
                // ////("action",action);
                return {
                    ...state,
                    "jobCreationMethod": action.value || "",
                    "disabled": false
                };
            }
            case 'changed_jobcompletion_method': {
                // ////("action",action);
                return {
                    ...state,
                    "jobCompletionMethod": action.value || "",
                    "disabled": false
                };
            }
            case 'changed_standard_duration': {
                // ////("action",action);
                return {
                    ...state,
                    "standardDuration": action.value || null,
                    "disabled": false
                };
            }
            case 'select_by_category': {
                // ////("action",action);

                let { actionTypes = [] } = state, { categoryKey = "", checked = false } = action

                let select_action = actionTypes.filter(el => !el.categoryKey || el.categoryKey != categoryKey)
                // ////("select_action",select_action);
                if (checked) {
                    let category_action = allJobActions.find(dl => dl?.categoryKey && dl.categoryKey == categoryKey)
                    // ////("category_action",category_action);
                    if (category_action && category_action?.actions?.length > 0) {
                        select_action.push(...category_action.actions)
                    }
                }
                return {
                    ...state,
                    "actionTypes": select_action,
                    "disabled": false,
                    "categoryKey": categoryKey
                };
            }
            default:
                return state;
        }
    }
    const [formData, dispatchReducer] = useReducer(reducer, initialFormDataState);
    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const [dragOverItemIndex, setDragOverItemIndex] = useState(null);
    const [dataReceieved, isDataReceived] = useState(false)
    const [jobTypeDetailData, setJobTypeDetailData] = useState({})
    const actionCovertor = () => {
        let arr = []
        // ////("allJobActions", allJobActions);
        for (let ele of allJobActions) {
            if (ele?.categoryKey && ele?.actions && ele?.actions?.length > 0) {
                arr.push({ isCategory: true, actionName: ele.categoryKey, categoryKey: ele.categoryKey })
                arr.push(...ele.actions)
            }
        }
        // ////("hhhh", arr);
        return arr
    }

    /** Update an existing status label in the specified category */
    const handleJobStatusValue = (categoryKey, index, updatedLabel) => {
        setJobStatusFlow((prevFlow) =>
            prevFlow.map((category) =>
                category.statusFlowKey === categoryKey
                    ? {
                        ...category,
                        statusList: category.statusList.map((status, idx) =>
                            idx === index ? { ...status, label: updatedLabel } : status
                        ),
                    }
                    : category
            )
        );
    };

    /** Add a new status to the specified category */
    const handleAddJobStatusFlow = (categoryKey) => {
        setJobStatusFlow((prevFlow) =>
            prevFlow.map((category) =>
                category.statusFlowKey === categoryKey
                    ? {
                        ...category,
                        statusList: [

                            ...(!category.isCreateButtonAtTop ? category.statusList : []),
                            {
                                label: "",
                                isDelete: true,
                                isSwap: true,
                                isEdit: true,
                            },
                            ...(category.isCreateButtonAtTop ? category.statusList : []),

                        ],
                    }
                    : category
            )
        );
    };


    /** Delete a status from the specified category */
    const handleDeleteJobStatusFlow = (categoryKey, index) => {
        setJobStatusFlow((prevFlow) =>
            prevFlow.map((category) =>
                category.statusFlowKey === categoryKey
                    ? {
                        ...category,
                        statusList: category.statusList.filter((_, idx) => idx !== index),
                    }
                    : category
            )
        );
    };

    // Handle the start of dragging
    const handleDragStart = (e, categoryKey, index) => {
        const statusItem = jobStatusFlow
            .find(category => category.statusFlowKey === categoryKey)
            ?.statusList[index];

        if (statusItem?.isSwap) {
            setDraggedItemIndex({ categoryKey, index });
        } else {
            // Prevent drag if isSwap is false
            e.preventDefault();
        }
    };

    // Handle dragging over another item
    const handleDragOver = (e, index) => {
        e.preventDefault();
        setDragOverItemIndex(index);
    };

    // Handle the drop and perform the swap
    const handleDrop = (categoryKey) => {
        if (draggedItemIndex && draggedItemIndex.categoryKey === categoryKey && dragOverItemIndex !== null) {
            const draggedItem = jobStatusFlow
                .find(category => category.statusFlowKey === draggedItemIndex.categoryKey)
                ?.statusList[draggedItemIndex.index];

            const dragOverItem = jobStatusFlow
                .find(category => category.statusFlowKey === categoryKey)
                ?.statusList[dragOverItemIndex];

            if (draggedItem?.isSwap && dragOverItem?.isSwap) {
                const updatedJobStatusFlow = [...jobStatusFlow];
                const categoryIndex = updatedJobStatusFlow.findIndex(
                    (category) => category.statusFlowKey === categoryKey
                );
                const category = updatedJobStatusFlow[categoryIndex];

                // Swap the items in the statusList
                category.statusList[draggedItemIndex.index] = dragOverItem;
                category.statusList[dragOverItemIndex] = draggedItem;

                setJobStatusFlow(updatedJobStatusFlow);
            }

            // Clear the dragged item and dragOver item indices
            setDraggedItemIndex(null);
            setDragOverItemIndex(null);
        }
    };

    const submitBtnDisableCondition = () => {
        const { name, actionTypes } = formData;

        // Disable the button if any of the conditions are met
        return !name || actionTypes?.length === 0;
    }
    const handleSubmitData = (clickType) => {
        isDataReceived(true)
        let payload = {
            ...formData,
            jobStatusFlow, // Add `jobStatusFlow` as is
        };
        
        if (     // check the statuslist labels must be non empty 
            jobStatusFlow?.some((eachStatus) =>
                eachStatus?.statusList.some((dl) => dl?.label === "")
            )
        ) {
            showToast("Please fill all the Job Statuses", true);
            isDataReceived(false)
            return;
        }
        if (formData?.standardDuration) {
            payload["standardDuration"] = getTimeFormISODate(formData?.standardDuration, false)
        }
        // Transform `actionTypes` to include only `actionId`
        payload.actionTypes = formData?.actionTypes?.map((action) => action.actionId);

        // Remove `disabled` and `categoryKey` from the payload
        const { disabled, categoryKey, ...filteredPayload } = payload;
        if (clickType == "update") {
            dispatch(updateJobTypes(payload, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/job/viewJobTypes")
                }
            }))
        }
        else {   // click type is submit & submit & add new 
            dispatch(addJobTypes(payload, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    if (clickType == "submit") {
                        history.push("/dashboard/job/viewJobTypes")
                    } else {
                        window.location.reload()
                    }
                }
            }))
        }
    }

    const actionTypeJobStatusFlowTrigger = (actionTypes = []) => {

        if (actionTypes.length > 0) {
            const updatedStatusFlow = initialStatusFlow
                .filter((flow) => flow.statusFlowKey !== "ongoing") // Remove the "ongoing" statusFlowKey
                .reduce((acc, flow) => {
                    if (flow.statusFlowKey === "todo") {
                        // Check if "todo" already exists in jobStatusFlow
                        const existingTodo = jobStatusFlow?.find(
                            (existingFlow) => existingFlow.statusFlowKey === "todo"
                        );

                        // Add the "To Do" status flow with existing statusList if present
                        acc.push({
                            ...flow,
                            statusList: existingTodo?.statusList?.length > 0
                                ? existingTodo.statusList
                                : flow.statusList,
                        });

                        // Map through the data and insert after "To Do", sort them by 'order', and add them
                        const mappedActions = actionTypes
                            ?.sort((a, b) => a.order - b.order)
                            ?.map((action) => {
                                let obj = {
                                    statusFlowLabel: action.actionName,
                                    statusFlowKey: action.actionId,
                                    textBackgroundColor: "rgba(233, 252, 62, 0.25)", // Use the appropriate background color
                                    textBorderColor: "rgba(231, 177, 23, 1)", // Use the appropriate border color
                                    statusList: [
                                        {
                                            label: action.actionName,
                                            isDelete: true,
                                            isSwap: true,
                                            isEdit: true,
                                        },
                                    ],
                                };

                                // Check if this actionId already exists in jobStatusFlow
                                let existingStatusList = jobStatusFlow?.find(
                                    (dl) => dl?.statusFlowKey === action.actionId
                                );
                                if (existingStatusList?.statusList?.length > 0) {
                                    obj.statusList = existingStatusList?.statusList;
                                }

                                return obj;
                            });

                        acc.push(...mappedActions);
                    } else if (flow.statusFlowKey === "completed") {
                        // Check if "completed" already exists in jobStatusFlow
                        const existingCompleted = jobStatusFlow?.find(
                            (existingFlow) => existingFlow.statusFlowKey === "completed"
                        );

                        // Add the "Completed" status flow with existing statusList if present
                        acc.push({
                            ...flow,
                            statusList: existingCompleted?.statusList?.length > 0
                                ? existingCompleted.statusList
                                : flow.statusList,
                        });
                    }

                    return acc;
                }, []);

            setJobStatusFlow(updatedStatusFlow);
        } else {
            setJobStatusFlow(initialStatusFlow);
        }
    };

    // useEffect(() => {

    // }, [formData?.actionTypes])

    useEffect(() => {
        dispatch(getAllJobActions({ type: "WMS" }));
        if (isEditable) {
            dispatch(getJobTypeDetails({ jobTypeId: jobTypeId }, (data) => {
                data.data.standardDuration = moment(data.data.standardDuration, 'HH:mm:ss');
                dispatchReducer({ type: "set_initial_state", valueObj: data?.data })
                setJobTypeDetailData(data?.data)
                // setLoader(false)
                // setWorkflowDetailData(data?.data)

            }))
        }
        else {
            dispatchReducer({ type: "set_initial_state" })
        }
    }, [])

    return (<>
        <Typography sx={{ ...textClasses.cardTitle }}>Configure Your Warehouse Operations</Typography>
        <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-jobtype">
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/job/viewJobTypes">
                    Manage Warehouse Jobs
                </Link>
            </Typography>
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>{isEditable ? "Edit" : "Create"} Job Type</Typography>
        </Breadcrumbs>
        <Paper elevation={1} sx={{ ...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%" }}>
            <Grid container>
                <Grid container item xs={12} md={6} sx={{ p: 2, height: '560px' }}>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography sx={{ ...textClasses.t13n, fontWeight: 900 }}>
                            Operation Details Configuration*
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography sx={{ ...textClasses.t13n }}>
                            You can group multiple job type under a single operations
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ mt: 2 }}>
                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                            <TextField
                                label={<Typography sx={{ fontSize: "12px" }}>Job Name*</Typography>}
                                labelId="job-name-label"
                                type='text'
                                data-testid="enter-jobname"
                                size="small"
                                sx={{
                                    fontSize: "12px",
                                    width: '100%',
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                focusColor='red'
                                value={formData?.name || ""}
                                onChange={(e) => dispatchReducer({ type: "changed_jobname", value: e.target.value })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} sx={{ mt: 2 }}>
                        <FormControl
                            size="small"
                            fullWidth
                            data-testid="enter-actiontype"
                        >
                            <Autocomplete
                                multiple
                                labelId="action"
                                size='small'
                                sx={{
                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                limitTags={5}
                                options={actionCovertor()}
                                getOptionLabel={(option) => option.actionName}
                                onChange={(event, option) => {
                                    // ////("options>>>>", option);
                                    // Filter out duplicates before updating state
                                    const uniqueOptions = option.filter(
                                        (item, index, self) =>
                                            index === self.findIndex((o) => o.actionId === item.actionId)
                                    );

                                    dispatchReducer({ type: "change_action", value: uniqueOptions });
                                    actionTypeJobStatusFlowTrigger(uniqueOptions || [])

                                }}
                                disableCloseOnSelect
                                renderOption={(props, option, { selected }) => {
                                    const { isCategory = false, categoryKey, actionName, actionId } = option
                                    // ////("selected",selected,props,option)
                                    if (isCategory) {
                                        return (
                                            <ListSubheader key={categoryKey} sx={{ mt: 0, pt: 0 }}>
                                                {actionName}
                                                <Checkbox
                                                    onChange={(e) => {
                                                        // ////("checkbox",e.target.checked)
                                                        let select_action = formData?.actionTypes.filter(el => !el.categoryKey || el.categoryKey != categoryKey)
                                                        // ////("select_action",select_action);
                                                        if (e.target.checked) {
                                                            let category_action = allJobActions.find(dl => dl?.categoryKey && dl.categoryKey == categoryKey)
                                                            // ////("category_action",category_action);
                                                            if (category_action && category_action?.actions?.length > 0) {
                                                                select_action.push(...category_action.actions)
                                                            }
                                                        }
                                                        dispatchReducer({ type: "select_by_category", categoryKey: categoryKey, checked: e.target.checked })
                                                        actionTypeJobStatusFlowTrigger(select_action || [])
                                                    }}
                                                    checked={allJobActions.find(dl => dl?.categoryKey == categoryKey)?.actions?.length == formData?.actionTypes.filter(el => el.categoryKey == categoryKey)?.length}
                                                />
                                            </ListSubheader>
                                        )
                                    }
                                    else {
                                        return (
                                            <MenuItem value={actionId} key={actionId} sx={{ ml: 2 }} {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                />
                                                <ListItemText primary={actionName} />
                                            </MenuItem>
                                        )
                                    }
                                }
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={<Typography sx={{ fontSize: "12px" }}>Action Type*</Typography>}
                                    />
                                )}
                                value={formData?.actionTypes || []}
                            />
                        </FormControl>
                    </Grid>

                    {/* This is the whole radio label and options field */}
                    <Grid container item xs={12} sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                        {/* Left side: Workflow Scope label */}
                        <Grid item sm={5} xs={5} >
                            <Typography
                                align="left"
                                sx={{
                                    ...textClasses.t14n,
                                    fontWeight: "500",
                                    mr: 1,
                                    mt: 1
                                }}
                                data-testid="radio-select-block"
                            >
                                Block Area During Operation * :
                            </Typography>
                        </Grid>

                        {/* Right side: Radio buttons */}
                        <Grid item xs={5} container >
                            {blockAreaOperations?.map((m, i) => (
                                <Grid item key={i}>
                                    <FormControlLabel
                                        label={
                                            <Typography sx={{ ...textClasses.t13n }}>
                                                {m.label}
                                            </Typography>
                                        }
                                        control={
                                            <Radio
                                                value={m.value}
                                                checked={formData.isBlockedArea === m.value} // Set checked based on formData
                                                onChange={(e) => {
                                                    dispatchReducer({ type: "changed_blockarea_operation", value: e.target.value })
                                                }} // Handle radio button change
                                                sx={{
                                                    "&, &.Mui-checked": { color: theme.themeOrange },
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                        {/* Left side: Workflow Scope label */}
                        <Grid item sm={5} xs={5} >
                            <Typography
                                align="left"
                                sx={{
                                    ...textClasses.t14n,
                                    fontWeight: "500",
                                    mr: 1,
                                    mt: 1
                                }}
                                data-testid="radio-select-user"
                            >
                                Assign User  * :
                            </Typography>
                        </Grid>

                        {/* Right side: Radio buttons */}
                        <Grid item xs={5} container >
                            {assignUserOptions?.map((m, i) => (
                                <Grid item key={i}>
                                    <FormControlLabel
                                        label={
                                            <Typography sx={{ ...textClasses.t13n }}>
                                                {m.label}
                                            </Typography>
                                        }
                                        control={
                                            <Radio
                                                value={m.value}
                                                checked={formData.isAssignedUser === m.value} // Set checked based on formData
                                                onChange={(e) => {
                                                    dispatchReducer({ type: "changed_assigned_user", value: e.target.value })
                                                }} // Handle radio button change
                                                sx={{
                                                    "&, &.Mui-checked": { color: theme.themeOrange },
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
                        {/* Left side: Workflow Scope label */}
                        <Grid item sm={5} xs={5} >
                            <Typography
                                align="left"
                                sx={{
                                    ...textClasses.t14n,
                                    fontWeight: "500",
                                    mr: 1,
                                    mt: 1
                                }}
                                data-testid="radio-select-mhe"
                            >
                                MHE Required * :
                            </Typography>
                        </Grid>

                        {/* Right side: Radio buttons */}
                        <Grid item xs={5} container >
                            {manualHandlingOptions?.map((m, i) => (
                                <Grid item key={i}>
                                    <FormControlLabel
                                        label={
                                            <Typography sx={{ ...textClasses.t13n }}>
                                                {m.label}
                                            </Typography>
                                        }
                                        control={
                                            <Radio
                                                value={m.value}
                                                checked={formData.isMHERequired === m.value} // Set checked based on formData
                                                onChange={(e) => {
                                                    dispatchReducer({ type: "changed_mhe_required", value: e.target.value })
                                                }} // Handle radio button change
                                                sx={{
                                                    "&, &.Mui-checked": { color: theme.themeOrange },
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={8} sx={{ mt: 2 }}>
                        <Autocomplete
                            data-testid="select-creation-method"
                            value={formData?.jobCreationMethod && jobCreationMethodOptions?.find(dl => dl.value == formData?.jobCreationMethod)}
                            onChange={(e, option) => {
                                dispatchReducer({ type: "changed_jobcreation_method", value: option?.value })
                            }}
                            // getOptionLabel={(option) => getCamelCaseText(option.label)}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            size="small"
                            options={jobCreationMethodOptions}
                            sx={{
                                ...inputClasses.textField,
                                ...selectStylesOverride,
                                minWidth: "220px",
                                m: 0,
                                // ml: 2,
                                ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64",
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                    {...params}
                                    label="Select Job Creation Method"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={8} sx={{ mt: 2 }}>
                        <Autocomplete
                            data-testid="select-completion-method"
                            value={formData?.jobCompletionMethod && jobCompletionMethodOptions?.find(dl => dl.value == formData?.jobCompletionMethod)}
                            onChange={(e, option) => {
                                dispatchReducer({ type: "changed_jobcompletion_method", value: option?.value })
                            }}
                            // getOptionLabel={(option) => getCamelCaseText(option.label)}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            size="small"
                            options={jobCompletionMethodOptions}
                            sx={{
                                ...inputClasses.textField,
                                ...selectStylesOverride,
                                minWidth: "220px",
                                m: 0,
                                // ml: 2,
                                ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64",
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                    {...params}
                                    label="Select Job Completion Method"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={8} sx={{ mt: 2 }} data-testid="enter-standard-duration">
                        <TimePicker
                            label="Standard Duration (In hrs)"
                            slotProps={{ textField: { size: "small" } }}
                            ampm={false} // Disables AM/PM and uses 24-hour format
                            sx={{
                                ...selectStylesOverride, color: theme.themeOrange, minWidth: "220px",
                                width: "100%",
                                ".MuiTextField-root": {
                                    width: "100%",
                                },
                            }}
                            value={formData?.standardDuration || null}
                            onChange={(d) => {
                                dispatchReducer({ type: "changed_standard_duration", value: d })
                            }}
                            required
                        />
                    </Grid>
                    <Grid container sx={{ gap: 2, mt: 2, ml: 2 }}>
                        {
                            isEditable ?
                                <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        ...buttonClasses.lynkitBlackEmpty,

                                    }}
                                    // disabled={isLoader}
                                    // data-testid="clear-btn"
                                    onClick={() => dispatchReducer({ type: "set_initial_state", valueObj: jobTypeDetailData })}
                                // onClick={handleClearAll}
                                >
                                    Reset
                                </Button>
                                :
                                <Button
                                    variant="outlined"
                                    data-testid="clear-all-btn"
                                    size="small"
                                    sx={{
                                        ...buttonClasses.lynkitBlackEmpty,

                                    }}
                                    // disabled={isLoader}
                                    // data-testid="clear-btn"
                                    onClick={() => dispatchReducer({ type: "set_initial_state", value: initialFormDataState })}
                                // onClick={handleClearAll}
                                >
                                    Clear All
                                </Button>
                        }
                        {
                            isEditable ?
                                <Button
                                    variant="outlined"
                                    size="small"
                                    disabled={dataReceieved || submitBtnDisableCondition()}
                                    sx={{
                                        ...buttonClasses.lynkitOrangeEmpty,

                                    }}
                                    data-testid="update-btn"
                                    onClick={() => handleSubmitData("update")}
                                >
                                    Update
                                </Button> :
                                <>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        disabled={dataReceieved || submitBtnDisableCondition()}
                                        sx={{
                                            ...buttonClasses.lynkitOrangeEmpty,

                                        }}
                                        data-testid="submit-btn-add-new"
                                        onClick={() => handleSubmitData("submitAndCreateNew")}
                                    >
                                        Submit & Create New
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        data-testid="submit-btn"
                                        disabled={dataReceieved || submitBtnDisableCondition()}
                                        // disabled={isLoader || disableSubmit}
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,

                                        }}
                                        onClick={() => handleSubmitData("submit")}
                                    >
                                        Submit
                                    </Button>
                                </>
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={6} sx={{ border: '1px solid #D9D9D9', p: 2, borderRadius: 2, height: 'auto' }}>
                    <Grid item xs={12} sx={{ mb: 2 }} data-testid="status-flow">
                        <Typography sx={{ ...textClasses.t13n, fontWeight: 900 }}>
                            Job Status Flow Configuration*
                        </Typography>
                        <Typography sx={{ ...textClasses.t13n }}>
                            Categories group multiple statuses of the same kind
                        </Typography>
                    </Grid>

                    {jobStatusFlow.map((category) => (
                        <fieldset
                            key={category.statusFlowKey}
                            style={{
                                border: '1px dotted green',
                                padding: '8px',
                                marginBottom: '16px',
                                position: 'relative',
                                width: '100%',
                                height: 'auto',
                                mt: 1,
                            }}
                        >
                            {/* Legend */}
                            <legend
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    width: 'auto',
                                    color: 'white',
                                    backgroundColor:
                                        category.statusFlowKey === 'todo'
                                            ? '#E9001A'
                                            : ['ongoing', 'Ongoing Completed'].includes(category.statusFlowKey)
                                                ? '#FCCB3E'
                                                : category.statusFlowKey === 'completed'
                                                    ? '#1A4B1C'
                                                    : '#FF7200',
                                    padding: '2px 8px',
                                    position: 'absolute',
                                    top: '-10px',
                                }}
                            >
                                {category.statusFlowLabel}
                            </legend>

                            {/* Conditionally Render "Create Status(s)" Button at Top */}
                            {category.isCreateButtonAtTop && (
                                <Grid
                                    item
                                    xs={3.5}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        mt: 1,
                                        ml: 1,
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleAddJobStatusFlow(category.statusFlowKey)}
                                >
                                    <Typography sx={{ ...textClasses.t13n }}>
                                        <AddOutlinedIcon fontSize="small" /> Create More Status(s)
                                    </Typography>
                                </Grid>
                            )}

                            {/* Render Rows for Each Status */}
                            {category?.statusList?.map((eachStatus, index) => (
                                <Grid
                                    key={index}
                                    container
                                    item
                                    xs={12}
                                    sx={{ m: 1, mt: 1, alignItems: 'center', height: 'auto' }}
                                    draggable={eachStatus.isSwap}
                                    onDragStart={(e) =>
                                        handleDragStart(e, category.statusFlowKey, index)
                                    }
                                    onDragOver={(e) => handleDragOver(e, index)}
                                    onDrop={() => handleDrop(category.statusFlowKey)}
                                >
                                    {/* Drag Icon */}
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{ display: 'flex', justifyContent: 'center' }}

                                    >
                                        <IconButton
                                            disabled={!eachStatus.isSwap}

                                            sx={{
                                                border: `1px solid ${eachStatus.isSwap ? 'black' : 'gray'}`,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <DragHandleOutlinedIcon
                                                sx={{ cursor: 'pointer' }}
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </Grid>

                                    {/* Status Input */}
                                    <Grid item xs={9}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <OutlinedInput
                                                sx={{
                                                    fontSize: '12px',
                                                    width: '100%',
                                                    height: '35px',
                                                    '& input::placeholder': {
                                                        fontSize: '12px',
                                                        color: category.textBorderColor,
                                                    },
                                                    backgroundColor: category.textBackgroundColor,
                                                    '& input': {
                                                        color: `${category.textBorderColor} !important`,
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: category.textBorderColor,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: category.textBorderColor,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: category.textBorderColor,
                                                    },
                                                    '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: category.textBorderColor,
                                                    },
                                                }}
                                                value={eachStatus?.label}
                                                onChange={(e) =>
                                                    handleJobStatusValue(
                                                        category.statusFlowKey,
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </FormControl>
                                    </Grid>

                                    {/* Delete Icon */}
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                        <IconButton
                                            disabled={!eachStatus.isDelete}
                                            onClick={() =>
                                                handleDeleteJobStatusFlow(category.statusFlowKey, index)
                                            }
                                            sx={{
                                                border: `1px solid ${eachStatus.isDelete ? 'black' : 'gray'}`,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <DeleteOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>

                                    {/* Edit Icon */}
                                    {/* <Grid
                                        item
                                        xs={1}
                                        sx={{ display: 'flex', justifyContent: 'center' }}
                                    >
                                        <IconButton
                                            disabled={!eachStatus.isEdit}
                                            sx={{
                                                border: `1px solid ${eachStatus.isEdit ? 'black' : 'gray'}`,
                                                borderRadius: 2,
                                            }}
                                        // onClick={() =>
                                        //     handleEditJobStatusFlow(category.categoryKey, index)
                                        // }
                                        >
                                            <EditOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                            ))}

                            {/* Conditionally Render "Create Status(s)" Button at Bottom */}
                            {!category.isCreateButtonAtTop && (
                                <Grid
                                    item
                                    xs={3.5}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        mt: 1,
                                        ml: 1,
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleAddJobStatusFlow(category.statusFlowKey)}
                                >
                                    <Typography sx={{ ...textClasses.t13n }}>
                                        <AddOutlinedIcon fontSize="small" /> Create More Status(s)
                                    </Typography>
                                </Grid>
                            )}
                        </fieldset>
                    ))}

                </Grid>
            </Grid>

        </Paper>
    </>)

}

export default CreateUpdateJobType;