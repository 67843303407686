import React, { useState, useMemo, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "lodash";
import { selectStylesOverride } from "../../../utils/util";

const GenericDropdown = ({
    options,
    option = {},
    handleSelect,
    disabled = false,
    noOptionsText = "No Data Found",
    label="Select Option",
    ...rest
}) => {
    const [query, setQuery] = useState("");

    const [visibleItems, setVisibleItems] = useState([]);

    // Chunk size for pagination
    const CHUNK_SIZE = 50;

    // Debounced input handling
    const handleSearch = useMemo(
        () =>
            debounce((event) => {
                setQuery(event.target.value);
            }, 300),
        []
    );

    // Filter options based on query (client-side filtering)
    const filteredOptions = useMemo(
        () =>
            options.filter((option) =>
                option.label.toLowerCase().includes(query.toLowerCase())
            ),
        [query, options]
    );

    // Initialize visible items when options change
    useEffect(() => {
        setVisibleItems(
            options
                .filter((item) =>
                    item.label.toLowerCase().includes(query.toLowerCase())
                )
                .slice(0, CHUNK_SIZE)
        );
    }, [options, query]);

    
    
    // Reset query and visibleItems when the dropdown is closed
    const handleDropdownClose = () => {
        setQuery("");
        setVisibleItems(options.slice(0, CHUNK_SIZE)); // Reset to the initial options
    };
    // Handle scroll to load more items
    const handleScroll = (event) => {
        const listboxNode = event.target;
        if (
            listboxNode.scrollTop + listboxNode.clientHeight >=
            listboxNode.scrollHeight
        ) {
            // Load the next chunk of items
            setVisibleItems((prev) =>
                options
                    .filter((item) =>
                        item.label.toLowerCase().includes(query.toLowerCase())
                    )
                    .slice(0, prev.length + CHUNK_SIZE)
            );
        }
    };

    return (
        <Autocomplete
            fullWidth
            id={rest?.id || "packageId-select"}
            size={rest?.size || "small"}
            disabled={disabled}
            options={visibleItems}
            //   options={filteredOptions}
            // getOptionLabel={(option) => option.label || ""}
            onChange={(e, option) => handleSelect(e, option, false)}
            noOptionsText={noOptionsText}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    onChange={handleSearch} // Debounced input handler
                    sx={{
                        ...selectStylesOverride,
                        width: "100%",
                    }}
                />
            )}
            ListboxProps={{
                onScroll: handleScroll, // Attach scroll handler
            }}
            value={option?.label || ""}
            key={rest?.key || "packageId-select_gateOut"}
            onClose={handleDropdownClose} // Reset state when dropdown closes
        />
    );
};

export default GenericDropdown;
