import React, { useState } from "react";
import { textClasses } from "../../../../../static/styles/theme";
import { Autocomplete, Grid, Button, Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import SidebarCard from "./SidebarCard";
import AutomationTriggers from "./AutomationTriggers";
import theme, { buttonClasses } from "../../../../../static/styles/theme";
import { AUTOMATION_TRIGGER, JOB_CREATION_STEP, initialFieldConditionalValues } from "../constant";
import AutomationHeader from "./AutomationHeader";
import JobCreationStep from "./JobCreationStep";

const CreateRuleAutomation = () => {
  const [automationHeader, setAutomationHeader] = useState(
    "Automation Rule Name"
  );
  const [workflowFilter, setWorkflowFilter] = useState(null);
  const [step, setStep] = useState(AUTOMATION_TRIGGER);
  const [selectJobAutomation, setSelectJobAutomation] = useState({value: "",type: ""});
  const [selectJobType, setSelectJobType] = useState(null);
  const [creationStep, setCreationStep] = useState("");
  const [editHeader, setEditHeader] = useState(false);
  const [jobTypefilter, setJobTypefilter] = useState(null);
  const [taskFieldValue, setTaskFieldValue] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);
  const [fieldConditionalValue, setFieldConditionalValue] = useState(initialFieldConditionalValues);
  const [visibleEmail, setVisibleEmail] = useState([]);
  const [addEmail, setAddEmail] = useState("")
  const [ruleName, setRuleName] = useState("");
  const editHandler = (e) => {
    setAutomationHeader(e.target.value);
  };

  const isAutomationTrigger = step === AUTOMATION_TRIGGER;
  const isJobCreationStep = step === JOB_CREATION_STEP;

  return (
    <>
      <Grid
        sx={{
          flexWrap: "nowrap",
        }}
      >
        <Grid item xs={2}>
          <Typography sx={{ ...textClasses.cardTitle }}>Automation Rules</Typography>
          <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-new-kit">
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
              <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/setting/automation-rules">
                Automation Rules
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText }}>Create Rule</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid
        sx={{
          flexWrap: "nowrap",
          marginTop: "20px",
        }}
        container
        data-testid="create-automation"
      >
        <SidebarCard
          setStep={setStep}
          isJobCreationStep={isJobCreationStep}
          selectJobType={selectJobType}
          setCreationStep={setCreationStep}
          creationStep={creationStep}
          jobTypefilter={jobTypefilter}
          resetStates={() => {
            setSelectJobType(null)
            setCreationStep("")
            setJobTypefilter(null)
            setWorkflowFilter(null)
            setTaskFieldValue(null)
            setConditionValue(null)
            setFieldConditionalValue(initialFieldConditionalValues)
            setVisibleEmail([])
            setRuleName("")
          }}
        />
        <Grid sx={{ width: "77%", marginLeft: "20px" }}>
          <AutomationHeader
            setWorkflowFilter={setWorkflowFilter}
            workflowFilter={workflowFilter}
            editHandler={editHandler}
            editHeader={editHeader}
            setEditHeader={setEditHeader}
            automationHeader={automationHeader}
            step={step}
          />
          {isAutomationTrigger && (
            <AutomationTriggers
              setCreationStep={setCreationStep}
              setStep={setStep}
              selectJobAutomation={selectJobAutomation}
              setSelectJobAutomation={setSelectJobAutomation}
            />
          )}
          {isJobCreationStep && (
            <JobCreationStep
              selectJobType={selectJobType}
              setSelectJobType={setSelectJobType}
              setCreationStep={setCreationStep}
              creationStep={creationStep}
              jobTypefilter={jobTypefilter}
              setJobTypefilter={setJobTypefilter}
              resetStates={() => {
                setSelectJobType(null)
                setCreationStep("")
                setJobTypefilter(null)
                setWorkflowFilter(null)
                setTaskFieldValue(null)
                setConditionValue(null)
                setFieldConditionalValue(initialFieldConditionalValues)
                setVisibleEmail([])
                setRuleName("")
              }}
              workflowFilter={workflowFilter}
              taskFieldValue={taskFieldValue}
              setTaskFieldValue={setTaskFieldValue}
              conditionValue={conditionValue}
              setConditionValue={setConditionValue}
              fieldConditionalValue={fieldConditionalValue}
              setFieldConditionalValue={setFieldConditionalValue}
              addEmail={addEmail}
              setAddEmail={setAddEmail}
              visibleEmail={visibleEmail}
              setVisibleEmail={setVisibleEmail}
              ruleName={ruleName}
              setRuleName={setRuleName}
              selectJobAutomation={selectJobAutomation}
              setSelectJobAutomation={setSelectJobAutomation}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default CreateRuleAutomation;
