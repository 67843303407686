import React from "react";
import { Autocomplete, TextField, Typography,MenuItem } from "@mui/material";
import { inputClasses, textClasses } from "../../../../static/styles/theme";

const SelectField = ({ component, componentValues, disabled, handleChange, errors }) => (
    <>
        <Autocomplete
            // required={component.required}
            size="small"
            fullWidth
            disabled={disabled}
            // label={component.label}
            options={component.values || []}
            sx={{ ...inputClasses.shadowField }}
            //   getOptionLabel={(option) => option.label || ""}
            onChange={(event, value) => handleChange(component, value)}
            value={component.values?.find((opt) => opt.value === componentValues[component?._id]) || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={component.label}
                    size="small"
                    required={component.required}
                    placeholder={component.placeholder}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <MenuItem
                  value={option?.value || ""}
                  key={option?.value || ""}
                  {...props}
                >
                  <Typography
                    sx={{
                      fontSize: ".8rem",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {option?.label || ""}
                  </Typography>
                </MenuItem>
              )}
        />
        {errors && errors[component._id] && (
            <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                {errors && errors[component._id] ? errors[component._id].message : ""}
            </Typography>
        )}
    </>
)

export default SelectField