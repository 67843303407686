import React, { useEffect, useState,useMemo } from "react";
import { useDebounce } from "../../../../utils/hooks";
import {
  Grid,
  Button,
  MenuItem,
  Typography,
  Checkbox,
  Paper,
  Menu,
} from "@mui/material";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../services/history";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
  cardClasses,
} from "../../../../static/styles/theme"
import {
  Add,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { getSuperAdminCompanyData, getCompanyStatusCount, getCompanyDetails, getTemplateHeader } from "../../../../redux/actions";
import { capitalizeSentence, showToast, renderHeadersFilter, dataReadFunction } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import GenericPageConfig from "../../../Components/Generic/GenericPageConfig";
import ViewRowDetailCard from "../ViewRowDetailCard";
import EnableDisableDialog from "../EnableDisableDialog";
import ViewWarehouseFloorModal from "./ViewWarehouseFloorModal";
import { multiDateToISOConvertor } from "../../../Components/Generic/GenericMultiDateRange";



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let initial_state = {
  "search": {},
  "other": {},
}

const companyDetailTemplateHeaders2 = [

  {
    "label": "All Warehouses",
    "name": "totalWarehouseCount",
    "type": "showDetail",
    "handleType": "viewWarehouses",
    "format": "",
  },
  {
    "label": "All Floors",
    "name": "totalFloorCount",
    "type": "showDetail",
    "handleType": "viewFloors",
    "format": "",
  },
  {
    "label": "Created At",
    "name": "createdAt",
    "type": "date",
    "format": "",
  },
  {
    "label": "Updated At",
    "name": "updatedAt",
    "type": "date",
    "format": "",
  },

]

const CompanyPage = () => {
  const dispatch = useDispatch();
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};
  const [statusFilter, setStatusFilter] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [filter, setFilter] = useState(initial_state);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [companyData, setCompanyData] = useState([]);
  const [companyDetailData, setCompanyDetailData] = useState({})
  const [companyDataRows, setCompanyDataRows] = useState(0);
  const [companystatusCount, setCompanyStatusCount] = useState({})
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [companyDetailTemplateHeaders, setCompanyDetailTemplateHeaders] = useState([])
  const [viewCompanyDetail, setViewCompanyDetail] = useState(false)
  const [loader, setLoader] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [initialRender, setInitialRender] = useState(true)
  const [viewModal, setViewModal] = useState({ type: "", action: "", origin: "" })
  const debouncedFilterSearch = useDebounce(filter?.search, 500);
  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };
  const handleCheckAll = () => {
    if (selectedEntries.length < companyData?.length) {
      let sT = companyData?.map((t) => ({
        _id: t._id,
        status: t.status,
        name: t.name,
      }));
      setSelectedEntries(sT);
    } else {
      setSelectedEntries([]);
    }
  };
  const handleRowAndViewClick = (selectedRowData) => {
    setViewCompanyDetail(true)
    let payload = {
      _id: selectedRowData?._id,
    }
    setLoader("cardRowDetail")
    dispatch(getCompanyDetails(payload, ({ data = {}, success }) => {
      setLoader("")
      if (success) {
        setCompanyDetailData({ ...data, ...(data?.formData || {}) })
      }
    }))
    dispatch(getTemplateHeader({ type: "company" }, (data) => {
      setCompanyDetailTemplateHeaders([...data?.templateHeader, ...companyDetailTemplateHeaders2])
    }))
  }
  const handleCloseDetail = () => {
    setViewCompanyDetail(false)
  }

  const tableHeader = useSelector((state) => state.customHeader["companyTable"]);
  let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
    if (h.handleType === "selectRow") {
      return (
        <Checkbox
          data-testid="checkbox"
          checked={
            companyData?.length != 0 &&
            selectedEntries.length == companyData?.length
          }
          onChange={handleCheckAll}
          size="small"
        />
      )
    }
    return h.fieldName
  })
    || [];


  const getTableCell = (header, row, i) => {

    let value = ""
    if (header?.mappingPath) {
      value = dataReadFunction({ name: header?.mappingPath || "" }, row)
    }

    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
        );
      }
      else if (header?.handleType === "showDetail") {
        return (
          <Typography
            onClick={() => handleRowAndViewClick(row)}
            style={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {value ?? "-"}
          </Typography>
        );
      }
      else if (header?.handleType === "viewWarehouses") {
        return (
          value?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
            onClick={() => {
              setViewModal({ ...viewModal, type: header?.handleType, action: row?._id, origin: row?.type })
            }}>
            {value
              ? Array.isArray(value)
                ? value?.length > 0 && value.join(", ") || "-"
                : value
              : "-"
            }
          </Typography> : "-"
        )
      }
      else if (header?.handleType === "viewFloors") {
        return (
          value?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
            onClick={() => {
              setViewModal({ ...viewModal, type: header?.handleType, action: row?._id, origin: row?.type })
            }}>
            {value
              ? Array.isArray(value)
                ? value?.length > 0 && value.join(", ") || "-"
                : value
              : "-"
            }
          </Typography> : "-"
        )
      }
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: header?.color || theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      }
    }
    else if (header?.isFromRenderFunction === true) {
      let no;
      if (page === 0) {
        no = i + 1;
      } else {
        no = page * 10 + (i + 1);
      }
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
            {no}
          </Typography>
        );
      }
      else if (header?.renderFunction === "status") {
        let option = header?.values?.find(o => o.value == value) || {}
        return (
          <>
            <Typography
              sx={{
                ...textClasses.normalText,
                textTransform: "capitalize",
                textAlign: "center",
                color: option?.color || theme.themeText,
              }}
            >
              {option?.label || "-"}
            </Typography>
          </>
        );
      }
    }
    else {
      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
            {value && Array.isArray(value) ? value.join(", ") : value || "-"}
          </Typography>
        );
      }
      else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
          >
            {value ? moment(value).format(header?.format || "DD MMM YYYY hh:mm A") : "-"}
          </Typography>
        );
      }
      else {
        return (
          <Typography
            sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
          >
            {value ?? "-"}
          </Typography>
        );
      }
    }
  };

  const tableRows = companyData?.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }
    return items;
  });


  const statusList = [
    { label: 'Active', value: 'active' },
    { label: 'In-Active', value: 'inActive' },
  ];
  const handleTabChange = (value, statusObject = {}) => {
    setStatusFilter(statusObject)
    setPage(0);
    setTabIndex(value);
    setSelectedEntries([])
    refreshTableData(1, limit, value && statusList[value - 1]?.value || "", false, value)
    setViewCompanyDetail(false)
  };

  const handleClearFilter = () => {
    setFilter(initial_state)
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
    }
  };
  const handleAddSelection = (obj) => {
    setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldEntries = [...selectedEntries];
    const i = oldEntries.map((o) => o._id).indexOf(id);
    if (i >= 0) {
      oldEntries.splice(i, 1);
      setSelectedEntries(oldEntries);
      if (oldEntries.length == 0) {
        handleCloseConfirmModal();
      }
    }
  };
  const isFilterApplied = (() => {
    if (tabIndex == 0 && statusFilter?.value) {
      return true;
    }
    if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(filter["other"]).some(dl => dl && dl.length > 0)) {
      return true;
    }
    return false;
  })();




  const handleCheckChange = (event, row) => {
    if (event.target.checked) {
      handleAddSelection(row);
    } else {
      handleRemoveSelection(row._id);
    }
  };

  const searchableFilterItems = useMemo(() => {
    if (!tableHeader || !tableHeader.validHeader) return [];
    return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
  }, [tableHeader]);

  const refreshTableData = (pageNo, limitNo, status = "", fetchCount = true) => {
    setLoader("tableData");
    const payload = {
      page: pageNo,
      limit: limitNo,
      type: "company",
      status: status || ""
    };
    if (filter && Object.keys(filter).length > 0) {
      for (let key in filter) {
        if (key && Object.keys(filter[key]).length > 0) {
          Object.assign(payload, filter[key])
        }
      }
      // Process date filter items
      const dateFilterItems = searchableFilterItems?.filter(
        (item) => item?.searchInputType === "date"
      );

      dateFilterItems.forEach((element) => {
        const payloadKey = element?.payloadKey || element?.mappingPath || "";
        if (payloadKey && payload[payloadKey]) {
          payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
        }
      });
    }
    setCompanyData([])
    dispatch(
      getSuperAdminCompanyData(payload, (data = {}) => {
        setCompanyData(data?.data || [])
        setCompanyDataRows(data?.count)
        setLoader("");
      })
    );
    dispatch(getCompanyStatusCount({ ...payload, status: "" }, (data = {}) => {
      setCompanyStatusCount(data?.data);
    }));

  };


  let fetchData = (fetchCount = false) => {
    refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount, tabIndex);
    // setSelectedTemplates([]);
    setConfirmModal(null);
    // setReceiveModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
    setViewCompanyDetail(false)

  };

  useEffect(() => {

    fetchData(initialRender);
    if (initialRender) {
      setInitialRender(false)
    }

  }, [page, limit, filter.other, debouncedFilterSearch]);
  return (
    <>
      <Grid container>
        <Grid item sm={8}>
          <Typography sx={{ ...textClasses.cardTitle }}>
            Manage Company
          </Typography>
        </Grid>
        <Grid container item sm={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
          {permissions &&
            (permissions.permissions || []).some(
              (item) => item.permissionId === "createCompany"
            ) ? (
            <Button
              data-testid="create-new-company"
              variant="outlined"
              size="small"
              startIcon={<Add />}
              sx={{ ...buttonClasses.lynkitOrangeEmpty }}
              onClick={() => history.push("/dashboard/company/add")}
            >
              Add Company
            </Button>
          )
            : (
              null
            )}
        </Grid>
        <GenericPageConfig
          storedInPage={"companyPage"}
          pageName={"companyList"}
          // pageTabCount={companystatusCount}
          pageStatusCount={companystatusCount}
          // handlePageTabChange={handleTypeChange}
          selectedPageTab={"Shipment"}
          selectedStatusTab={tabIndex}
          handleStatusTabChange={handleTabChange}
          disabledPageTabs={loader}
          disabledStatusTabs={loader}

        />
      </Grid>
      <Grid container spacing={1} xs={12}>
        {renderHeadersFilter(tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), filter, setFilter, setPage, setLimit, tabIndex)}
        {isFilterApplied && (
          <Grid container item sx={2} alignItems={"center"}>
            <Typography
              sx={{
                ...textClasses.boldText,
                color: theme.themeOrange,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleClearFilter}
            >
              Clear Filter(s)
            </Typography>
          </Grid>
        )}
        <Grid container item xs={"auto"} alignItems={"center"}>
          <GenericCustomiseHeader
            tableName={"companyTable"}
            tableType={"companyTable"}
          />
        </Grid>
      </Grid>
      {selectedEntries?.length > 0 &&
        <Grid container sx={{ mt: 2, textTransform: "none" }}>

          {selectedEntries.length === 1 && permissions && (
            <>
              {(permissions.permissions || []).some(
                (item) => item.permissionId === "viewCompany"
              ) && (
                  <Button
                    data-testid="view-btn"
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() => handleRowAndViewClick(selectedEntries[0])}
                  >
                    View
                  </Button>
                )}

              {(permissions.permissions || []).some(
                (item) => item.permissionId === "editCompany"
              ) && (
                  <Button
                    data-testid="edit-btn"
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() =>
                      history.push(
                        `/dashboard/company/edit?id=${selectedEntries[0]._id}`
                      )
                    }
                  >
                    Edit
                  </Button>
                )}
            </>
          )}
          {
            permissions &&
            (permissions.permissions || []).some(
              (item) => item.permissionId === "changeCompanyStatus"
            ) && (
              <>
                {selectedEntries?.every(entry => entry.status === "active") && (
                  <Button
                    data-testid="disable-btn"
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() => setConfirmModal("disable")}
                  >
                    Disable
                  </Button>
                )}
                {selectedEntries?.every(entry => entry.status === "inActive") && (
                  <Button
                    data-testid="enable-btn"
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() => setConfirmModal("enable")}
                  >
                    Enable
                  </Button>
                )}
              </>
            )
          }
        </Grid>

      }
      <Grid container sx={{ mt: 2 }}>
        <Grid item sm={viewCompanyDetail ? 8 : 12} xs={12}>
          <Paper>
            {loader == "tableData" ? (
              <Grid
                data-testid="loader"
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{ p: 10 }}
              >
                <Grid item>
                  <GenericLoader />
                </Grid>
              </Grid>
            ) : (
              <GenericTable
                header={header}
                rows={tableRows}
                pageCount={companyDataRows}
                pageNumber={page}
                handleChangePage={(event, pagevalue) => {
                  setPage(pagevalue);
                }}
                handleChangeRowsPerPage={(event) => {
                  setLimit(+event.target.value);
                }}
                rowsPerPage={limit}
              />
            )}
          </Paper>
        </Grid>
        {
          viewCompanyDetail && (
            <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
              <ViewRowDetailCard
                detailData={companyDetailData}
                onClose={handleCloseDetail}
                readKey={'companyName'}
                cardHeader={companyDetailTemplateHeaders}
                loader={loader == "cardRowDetail"}
              />
            </Grid>
          )
        }


        {/* this is for the disable and enable buttons */}

        <EnableDisableDialog
          status={confirmModal}
          handleClose={handleCloseConfirmModal}
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
          refreshTableData={() => {
            refreshTableData(page + 1, limit)
            setTabIndex(0)
          }}
          handleRemoveSelection={handleRemoveSelection}
        // cardType={cardType}
        />

        {/* this is for the disable and enable action menu items */}

        <EnableDisableDialog
          status={actionConfirmModal}
          // cardType={cardType}
          handleClose={handleCloseAction}
          selectedEntries={
            actionIndex != null ? [companyData[actionIndex]] : []
          }
          refreshTableData={() => {
            refreshTableData(page + 1, limit)
            setTabIndex(0)
          }} />

        {viewModal?.type &&
          <ViewWarehouseFloorModal
            viewModal={viewModal}
            setViewModal={setViewModal}
          />
        }
        {actionIndex != null && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={actionIndex != null}
            onClose={handleCloseAction}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              display: actionIndex != null ? "block !important" : "none !important",
            }}
          >
            {permissions &&
              (permissions.permissions || []).some(
                (item) => item.permissionId === "viewCompany"
              ) && (
                <MenuItem
                  key={1}
                  sx={{ ...textClasses.normalText }}
                  onClick={() => {
                    handleRowAndViewClick(companyData[actionIndex]);
                    handleCloseAction();
                  }}
                >
                  View
                </MenuItem>
              )}
            {permissions &&
              (permissions.permissions || []).some(
                (item) => item.permissionId === "editCompany"
              ) && (
                <MenuItem
                  key={1}
                  sx={{ ...textClasses.normalText }}
                  onClick={() =>
                    history.push(
                      `/dashboard/company/edit?id=${companyData[actionIndex]?._id}`
                    )
                  }
                >
                  Edit
                </MenuItem>
              )}
            {permissions &&
              (permissions.permissions || []).some(
                (item) => item.permissionId === "changeCompanyStatus"
              ) && (
                <>
                  {companyData[actionIndex]?.status === "active" && (
                    <MenuItem
                      key={2}
                      sx={{ ...textClasses.normalText }}
                      onClick={() => setActionConfirmModal("disable")}
                    >
                      Disable
                    </MenuItem>
                  )}
                  {companyData[actionIndex]?.status === "inActive" && (
                    <MenuItem
                      key={3}
                      sx={{ ...textClasses.normalText }}
                      onClick={() => setActionConfirmModal("enable")}
                    >
                      Enable
                    </MenuItem>
                  )}
                </>
              )}
          </Menu>
        )}
      </Grid>
    </>
  );
}

export default CompanyPage;