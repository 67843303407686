import React from 'react';
import {lynkitIOURL} from '../../../../services/config';
import Integration from '../../../Components/Integration';
import { Grid } from '@mui/material';
import { useLocation} from "react-router-dom";


const Trip = () => {
    const location = useLocation();
    const todayDate = new Date().getTime();
    const queryParams = new URLSearchParams(location.search);
    const myVariable = `${lynkitIOURL}/login?action=/trip&filterType=${queryParams.get('filterType') || ""}&auth=WMS&timestamp=${todayDate}`;
    return (
        <Grid data-testid="tripHome">
            <Integration
                myVariable={myVariable}
                baseURL={lynkitIOURL}
            />
        </Grid>
    );
};

export default Trip;
