
import React from "react";
import {
  Typography,Grid
} from "@mui/material";
import {
  textClasses,
} from "../../../static/styles/theme";

const GenericDetailsView = ({ title, children, h = {} }) => {
    return (
        <Grid container xs={12}>
            <Grid item  sm={5} xs={5}>
                <Typography sx={{ ...textClasses.t12n, color: "#827878", fontSize: h?.fontSize || '14px', ml: h?.headerMarginLeft || "10px" }}>{title} : </Typography>
            </Grid>
            <Grid container item sm={7} xs={7} direction="row" alignItems={"center"} sx={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                {children}
            </Grid>
        </Grid>
    )
}
export default GenericDetailsView;