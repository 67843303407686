import React, { useEffect, useState } from "react";
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../static/styles/theme";
import { useDispatch } from "react-redux";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import { startKitPartialBundlingProcess } from "../../../../redux/actions";
import { showToast } from "../../../../services/functions";

const BundlingModal = ({ viewModal, setViewModal,refreshTableData,setSelectedEntries }) => {
    const dispatch = useDispatch();
    const maxBundlingCount = viewModal?.action?.availableForBundeling;
    const [bundlingCount, setBundlingCount] = useState(maxBundlingCount)
    const [dataReceieved, isDataReceived] = useState(false)
    const [loader, setLoader] = useState(false);
    const countChangeHandler = (count) => {
        setBundlingCount(count)
    }
    const handleSubmit = () => {
        isDataReceived(true)
        let payload = {
            KIT_ID: viewModal?.action?.KIT_ID,
            pickingJobId: viewModal?.action?.pickingJobId,
            count: bundlingCount
        }
        dispatch(
            startKitPartialBundlingProcess(payload, (data = {}) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    setViewModal({ ...viewModal, type: '' })
                    refreshTableData()
                    if (setSelectedEntries) {
                        setSelectedEntries([]);
                      }
                }
            })
        )
    }

    return (
        <>

            <AlertDialog
                viewDialog={viewModal.type === "startBundling"}
                handleClose={() => setViewModal({ ...viewModal, type: '' })}
                showDivider={true}
                width="60%"
                title="Start the Kit Bundling operation"
                body={
                    <>
                        {loader ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid> :
                            <Box sx={{ width: "inherit" }}>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} sx={{ textAlign: 'center' }}>
                                        <Typography sx={{ ...textClasses.t15n }}>
                                            Starting kit bundling in parallel while picking is ongoing can significantly enhance
                                            efficiency and reduce the time required for kit preparation.
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography sx={{ ...textClasses.t15n, color: theme.themeOrange }}>
                                            As per picked components QTY, you can initiate bundling for {maxBundlingCount} kits.
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                        <Typography sx={{ ...textClasses.t15n, mr: 2 }}>
                                            Enter No. of Kit you want to start bundling
                                        </Typography>
                                        <GenericSingleRangeComponent
                                            data-testid="range-input"
                                            startRange={bundlingCount}
                                            endRange={maxBundlingCount}
                                            getOnChange={(count) => countChangeHandler(count)}
                                            valueType={"integer"}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
                <Button
                    variant="outlined"
                    data-testid="submit-btn"
                    onClick={handleSubmit}
                    disabled={dataReceieved}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Submit
                </Button>
            </AlertDialog>
        </>
    )
}
export default BundlingModal;
