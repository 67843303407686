import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Typography } from "@mui/material";
import { inputClasses,textClasses } from "../../../../static/styles/theme";


const DateField = ({ component, componentValues, disabled, handleChange, errors }) => (
  <>
    <DatePicker
      required={component.required} size='small' fullWidth
      disabled={disabled}
      sx={{ ...inputClasses.shadowField }}
      label={component.label}
      onChange={(date) => handleChange(component, date)}
      value={componentValues[component?._id] || ""}
      slotProps={{ textField: { size: "small", fullWidth: true, required: component.required } }}
    />
    {errors && errors[component._id] && (
       <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
       {errors && errors[component._id] ? errors[component._id].message : ""}
   </Typography>
    )}
  </>
);

export default DateField