
import React from "react";
import {  Button, ButtonGroup } from "@mui/material";
import theme, { buttonClasses } from "../../../static/styles/theme";
import { Add, Remove } from "@mui/icons-material";
import { showToast } from "../../../services/functions";


function truncateNumber(num=0, decimals=3) {
    const factor = Math.pow(10, decimals); // 10^decimals
    return Number(Math.round(Number(num) * factor) / factor);
}

const GenericSingleRangeComponent = ({startRange,endRange,getOnChange,endRangeInfinity=false,disabled=false,valueType="decimal",style={}}) =>{
    if (!endRangeInfinity)  endRange=truncateNumber(endRange)
    const handleCountChange = (type, e) => {
        if (!type) return;
        if (type === "decrement") {
            if (startRange > 1) {
                let decrementValue = Number(startRange || 0) - 1 || ""
                decrementValue = truncateNumber(decrementValue || 0) || ""
                decrementValue=Number(decrementValue || 0)
                getOnChange(decrementValue);
            }
        } else if (type === "increment") {
            let incrementValue = Number(startRange || 0) + 1 || "";
            incrementValue = truncateNumber((incrementValue || 0)) || ""
            incrementValue=Number(incrementValue || 0)
            if (!endRangeInfinity) {
                incrementValue=Math.min(endRange, incrementValue)
            }
            // console.log(incrementValue)
            getOnChange(incrementValue)
        } else if (type === "input") {
            const inputValue = e.target.value || "";
    
            if (inputValue && valueType === "integer" && inputValue.includes(".")) {
                showToast("Please provide integer value only!", true);
                return;
            }
    
            let parsedValue;
            if (valueType === "integer") {
                parsedValue = parseInt(inputValue, 10) || "";
            } else {
                parsedValue = parseFloat(inputValue || 0) || "";
            }
    
            const numericValue = Number(parsedValue || 0);
            if (!isNaN(numericValue) && numericValue >= 0) {
                if(!endRangeInfinity){
                    getOnChange(Math.min(endRange, numericValue))
                }
                else{
                    getOnChange(numericValue);
                }
            }
        }
    };
    // console.log({startRange,endRange})
    return(
        <>
            <ButtonGroup size='small' sx={{...style, minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }} disabled={disabled}>
                <Button key="one" size='small' sx={{
                    ...buttonClasses.smallOrange, fontSize: "12px",
                    backgroundColor: startRange <= 1 ? 'grey' : theme.themeOrange,
                    cursor: startRange <= 1 ? "not-allowed" : "pointer",
                    maxHeight: "40px",
                }}
                    onClick={() => handleCountChange("decrement")} disabled={startRange <= 1}
                >
                    <Remove fontSize='inherit' sx={{ color: 'white' }} />
                </Button>

                <input
                    type="number"
                    value={startRange > 0 ? startRange : ""}
                    // value={valueType="decimal" ?(startRange ||  0) :startRange > 0 ? startRange : null}
                    style={{
                        fontSize: "12px",
                        border: "1px solid #D9D9D9",
                        maxWidth: "50px",
                        textAlign: "center",
                      }}
                    onChange={(e) => handleCountChange("input", e)}
                    onWheel={(event) => event.target.blur()}
                    disabled={disabled}
                    onKeyDown={(e) => {
                        // if (['e', 'E', '+', '-'].includes(e.key)) {
                        //     e.preventDefault();
                        // }
                        // else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                        //     e.preventDefault();
                        // }
                        if (
                            ["e", "E", "+", "-"].includes(e.key) || // Disallow exponential signs
                            (e.key === "0" && valueType === "integer" && e.target.value === "") || // Disallow leading zeros
                            (e.key === "." && e.target.value.includes(".")) // Prevent multiple dots
                        ) {
                            e.preventDefault();
                        }
                    }}
                />
                <Button key="three" size='small' sx={{
                    ...buttonClasses.smallOrange, fontSize: "12px",
                    backgroundColor:  !endRangeInfinity && startRange >= endRange ? 'grey' : theme.themeOrange,
                    cursor: !endRangeInfinity && startRange >= endRange ? "not-allowed" : "pointer",
                    maxHeight: "40px",
                }}
                    onClick={() => handleCountChange("increment")} disabled={endRangeInfinity == false ? startRange >= endRange : false}
                >
                    <Add fontSize='inherit' sx={{ color: 'white' }} />
                </Button>
            </ButtonGroup>
        </>
    )
}

export default GenericSingleRangeComponent;

