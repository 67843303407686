/* eslint-disable */

import { Button, Grid, Typography } from "@mui/material"
import theme, { textClasses, } from "../../../../static/styles/theme"
import { Add, Delete, Edit } from "@mui/icons-material"

const Table2 = ({header, rows, remainingItems, handleAddRow, handleRemoveRow, handleEditRow, AddRow }) => {
    // console.log(remainingItems, "show remaining items")
    return (
        <table
            style={{
                // display: "block",
                // maxWidth: "-moz-fit-content",
                // maxWidth: "fit-content",
                // margin: "0 auto",
                // overflowX: "auto",
                // whiteSpace: "nowrap",
                // borderRadius: "8px"
                width: "100%",
                textAlign: 'center'
            }}
        >
            <thead style={{backgroundColor: theme.themeGray}}>
                <tr style={{...textClasses.boldText,  backgroundColor: theme.themeOrange, color: "white", padding: "10px"}}>

                    {
                        header.slice(0, 1).map((head, i) => {
                            return (
                                <td style={{border: "1px solid white", padding: "10px", width: "150px"}} key={i}>{head}</td>
                            )
                        })
                    }
                    {
                        header.slice(1, header.length).map((head, i) => {
                            return (
                                <td style={{border: "1px solid white"}} key={i}>{head}</td>
                            )
                        })
                    }
                    
                   {(handleEditRow || handleRemoveRow) &&  <td style={{border: "1px solid white"}}>Action</td>}
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0
                    &&
                    <>
                        {
                            rows.map((row, i) => (
                                <tr
                                    key={i}
                                    style={{borderBottom: "1px solid #0000002B"}}   
                                >
                                    {
                                        row.map((col, j) => {
                                            return (
                                                // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                <td style={{padding: "10px", border: "1px solid #D9D9D9"}}>
                                                    {col}
                                                </td>    
                                            )
                                        })
                                    }
                                    {(handleEditRow || handleRemoveRow) && <td style={{padding: "10px", border: "1px solid #D9D9D9", width: "120px"}}>
                                        <Grid container justifyContent={"center"} alignItems="center">
                                            <Button
                                                variant="outlined"
                                                className='RF_outline-btn'
                                                sx={{ borderRadius: "8px", p: 0, height: "40px", minWidth: "40px", border: "1px solid #827878" }}
                                                onClick={() => handleEditRow(i)}
                                            >
                                                <Edit sx={{color: "#827878"}} />
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                className='RF_outline-btn'
                                                sx={{ borderRadius: "8px", ml: 1, p: 0, height: "40px",  minWidth: "40px", border: "1px solid #827878" }}
                                                onClick={() => handleRemoveRow(i)}
                                            >
                                                <Delete sx={{color: "#827878"}} />
                                            </Button>
                                        </Grid>
                                    </td>}
                                
                                </tr>
                            ))
                        }                           
                    </>    
                }
                {handleAddRow && 
                    <tr>
                       <td style={{padding: "10px", border: "1px solid #D9D9D9", textAlign: "left"}} colSpan={header?.length+1}>
                            <Typography
                                sx={{
                                    ...textClasses.boldText,
                                    color: (remainingItems > 0 || AddRow) ? theme.themeOrange : "#D7D7D7",
                                    textDecoration: "underline",
                                    cursor: (remainingItems > 0 || AddRow) ? "pointer" : "edit"
                                }}
                                onClick={() => (remainingItems > 0 || AddRow) ? handleAddRow() : () => { }}>
                                <Add fontSize='inherit' />
                                Add More Items
                            </Typography>
                        </td>
                        {/* {
                            header.map((_, i) => {
                                return (<td style={{padding: "10px", border: "1px solid #D9D9D9"}} key={i}></td>)
                            })
                        } */}
                    </tr>
                }
                
            </tbody>
        </table>
    )
}

export default Table2;