import styled from "@emotion/styled";
import {
  Button,
  Box,
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  useMediaQuery,
  TextField,
  Autocomplete,
  FormGroup
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { b64toBlob } from "../../../utils/services";
import { selectStylesOverride } from "../../../utils/util";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  getAllTemplates,
  getCompany,
  getWMSReport,
  getReportHeader,
  scheduleReportEmail,
} from "../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as ReportBackgroundImg } from "../../../static/images/Icon/ReportBackgroundImg (2).svg";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { grey } from "@mui/material/colors";
import { orange } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import ReportSettingIcon from "../../../static/images/Icon/ReportSettingIcon.png";
import InwardDownloadReportIcon from "../../../static/images/Icon/InwardDownloadReportIcon.png";
import NovariableIcon from "../../../static/images/Icon/NoVariableIcon.png";
import ExcelJS from "exceljs";
import { useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import { reportCards } from "./index";
import { GET_REPORT } from "../../../redux/types";
import { toast } from "react-toastify";
import { showToast } from "../../../services/functions";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import moment from "moment";
import EmailReportModal from "./EmailReportModal";
import GenericUserInputComponent from "../../Components/Generic/GenericUserNumberInput";
import { Add } from '@mui/icons-material';
import TypeAndSearch from "../../Components/TypeAndSearch";
import {component} from "../MainDashboard/utils"



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const useStyles = makeStyles({
  datePickerWrapper: {
    ".datePickerWrapper-1 MuiBox-root css-0": {
      with: "30%",
    },

    "& .react-datepicker-wrapper": {
      width: "100%",
      "& .react-datepicker__close-icon::after": {
        backgroundColor: "#FF7200",
        right: "30px",
        position: "absolute",
        top: "9px",
      },
      "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
        right: "0px",
        "& path": {
          fill: "grey !important",
        },
      },
    },
  },
  datePickerContainer: {
    border: "1px solid #FF7200",
    minHeight: "35px",
    minWidth: "100%",
    ...inputClasses.filterField,
    backgroundColor: "white",
    boxShadow: "none",
    "&::placeholder": {
      color: "#455A64",
      fontWeight: "300",
    },
    "&:hover": {
      border: "1px solid white",
    },
  },
});

let initial_state = {
  reportDetail: {}, //inwardReport
  warehouseId: "",
  floorId: "",
  fieldType: "", //tempSpecificReport,standardReport
  inventoryType: "",
  template_Id: "",
  fields: [],
  dateFrom: "",
  dateTo: "",
  asnTemplate: "",
  inventoryAge: { minAge: "", maxAge: "" },
  skuID: "",
  "cellId":[]
};



const standardReportTypeList = [
  "inwardSummaryReport", "outwardSummaryReport", "stockTransferReport",
  "inwardReport", "areaWiseStockSummaryReport", "dayWiseIrStockReport",
  "IrStockSummaryReport", "IrClosingStockReport", "areaMappingWiseSKUSummaryReport",
  "cellwiseAreaUtilizationReport", "ASNReceivingReport", "inventoryAgeReport","outwardReport",
  "oldStockReport"
];
const DownloadReport = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { userPermissions } = useSelector((state) => state.userPermission);
  const permissionIds = userPermissions?.permissions?.map(permission => permission.permissionId);
  const permittedReportCards = reportCards(permissionIds);
  const companies = useSelector((state) => state.superadmin.companies);
  const warehouseDetail = useSelector(
    (state) => state.superadmin.warehouseDetail
  );
  const { floorDetail } = useSelector((state) => state.superadmin);
  const allTemplates = useSelector((state) => state.templates.allTemplates);
  const { state } = useLocation();
  const [open, setOpen] = useState(false);

  const [variablesSettings, setVariablesSettings] = useState({
    ...initial_state,
  });

  const [selectedVariables, setSelectedVariables] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);
  const [addEmail, setAddEmail] = useState();
  const [visibleEmail, setVisibleEmail] = useState([]);
  const [selectCheckBox, setSelectCheckBox] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [asnTempId, setAsnTempId] = useState("");
  const [isAllSkus,setIsAllSkus] = useState(false)
  useEffect(() => {
    setSelectedVariables([]);
    setAgeRanges([]);
    dispatch({ type: GET_REPORT, payload: [] });

    if (state?.reportType) {
      const reportType = state?.reportType;
      const reportData = permittedReportCards?.find((dl) => dl?.reportType == reportType) || {};
      const inventoryType = getInventoryTypeByReport(reportData?.reportType);
      setVariablesSettings({
        ...initial_state,
        reportDetail: reportData,
        fieldType: reportType,
        inventoryType: inventoryType,
        templateType: reportData?.templateType || "",
      });
      getListHeaderData(inventoryType, reportType);
    }
    dispatch(
      getCompany({
        type: "company",
        status: "active",
      })
    );
  }, [state?.reportType]);

  useEffect(() => {
    if (companies?.data?.length) {
      getCompanyWarehouses(companies?.data[0]?.companyName);
    }
  }, [companies?.data]);

  const getInventoryTypeByReport = (reportType) => {
    let inventoryType = "";
    if (standardReportTypeList.includes(reportType)) {
      if (["inwardReport","outwardReport"].includes(reportType)) {
        inventoryType = "shipment";
      } 
      else if (["oldStockReport"].includes(reportType)) {
        inventoryType = "block";
      } 
      else {
        inventoryType = "package";
      }
    } 
    else {
      inventoryType = "all";
    }
    return inventoryType;
  };

  const getReportType = (reportData) => {
    return reportData?.reportType === "inventorySummaryReport" ||
      reportData?.reportType == "itemMasterReport" || 
      reportData?.reportType == "skuHistoryReport"
      ? "templateSpecific"
      : "standardReport";
  };

  useEffect(() => {
    if (warehouseDetail?.data?.length) {
      handleWarehouseFloors(warehouseDetail?.data?.[0]?._id);
      const reportData = permittedReportCards?.find((dl) => dl?.reportType == state?.reportType) || {};
      const reportType = getReportType(reportData);
      const inventoryType = getInventoryTypeByReport(reportData?.reportType);
      setVariablesSettings({
        ...variablesSettings,
        reportDetail: reportData,
        warehouseId: warehouseDetail?.data?.[0]?._id,
        fieldType: reportType,
        inventoryType: inventoryType,
      });
    }
  }, [warehouseDetail?.data]);

  useEffect(() => {
    if (floorDetail?.length>0) {
      getFloorValue(floorDetail?.[0]?._id)
      // setVariablesSettings({
      //   ...variablesSettings,
      //   floorId: floorDetail?.[0]?._id,
      // });
    }
  }, [floorDetail]);


  useEffect(() => {
    if (variablesSettings.fieldType === "templateSpecific") {
      dispatch(
        getAllTemplates({
          templateType: variablesSettings?.reportDetail?.templateType || "",projection:{name:1,_id:1,templateLevel:1,templateType:1}
        })
      );
    }
  }, [variablesSettings.fieldType]);

  const getCompanyWarehouses = (company) => {
    dispatch(
      getCompany({
        companyName: company,
        type: "warehouse",
        status: "active",
      })
    );
  };

  const handleWarehouseFloors = (warehouseId) => {
    setVariablesSettings({
      ...variablesSettings,
      warehouseId: warehouseId,
      cellId:[]
    });

    dispatch(
      getCompany({
        warehouseId: warehouseId,
        type: "floor",
        status: "active",
      })
    );
  };


  const getFloorValue = (floorId) => {
    setVariablesSettings({
      ...variablesSettings,
      floorId: floorId,
      cellId:[],
    });

  }

  const reportTypeHandle = (e) => {
    let report_d = {};
    if (e?.target?.value) {
      report_d =
        permittedReportCards.find((dl) => dl.reportType == e.target.value) || {};
    }
    const reportType = standardReportTypeList.includes(e?.target?.value)
      ? "standardReport"
      : "templateSpecific";

    const inventoryType = getInventoryTypeByReport(e?.target?.value);
    setVariablesSettings({
      ...initial_state,
      reportDetail: report_d,
      fieldType: reportType,
      warehouseId: variablesSettings?.warehouseId || "",
      floorId: variablesSettings?.floorId || "",
      inventoryType: e?.target?.value=="oldStockReport" && inventoryType=="all" ? "block":inventoryType,
    });
    setSelectedVariables([]);
    if (reportType == "templateSpecific") {
      const payload = {
        templateType: report_d?.templateType,
        projection:{name:1,_id:1,templateLevel:1,templateType:1}
      };
      dispatch(getAllTemplates(payload));
    }
    if (
      standardReportTypeList.includes(e?.target?.value)
      // && e?.target?.value !== "stockTransferReport" // if stock summary report dropdown not shown
    ) {
      let headerpayload = {
        report_type: report_d?.reportType,
        templateSpecific: variablesSettings.fieldType === "templateSpecific",
        inventory_type: e?.target?.value=="oldStockReport" ?"block" :"shipment",
      };
      dispatch(getReportHeader(headerpayload, (header = []) => {
        const updatedData = header?.map((item) => ({ ...item, isSelected: true, })) || [];
        setSelectedVariables(updatedData);
      }));
    }
  };

  const getListHeaderData = (inventoryType, reportType = "",asnTemplate_Id="") => {
    let payload = {
      report_type: reportType || variablesSettings.reportDetail.reportType,
      inventory_type: reportType=="oldStockReport" && inventoryType=="all" ? "block":inventoryType,
      templateSpecific: variablesSettings.fieldType === "templateSpecific",
      transaction_type: inventoryType,
    };
    if (variablesSettings.template_Id) {
      payload.template_Id = variablesSettings.template_Id;
    }
   if (asnTempId) {
      payload.asnTemplate_Id = asnTempId;
    }
    dispatch(getReportHeader(payload, (header = []) => {
      const updatedData = header?.map((item) => ({ ...item, isSelected: true, })) || [];
      setSelectedVariables(updatedData);
    }));
  };

  const getListHeader = (template_Id, asnTemplate_Id = "") => {
    let payload = {
      report_type: variablesSettings.reportDetail.reportType,
      template_Id: template_Id,
      templateSpecific: variablesSettings.fieldType === "templateSpecific",
      inventory_type: variablesSettings.reportDetail.reportType=="oldStockReport" && variablesSettings.inventoryType=="all" ?"block" :variablesSettings.inventoryType,
    };
    if (asnTemplate_Id) {
      payload.asnTemplate_Id = asnTemplate_Id;
    }
    dispatch(getReportHeader(payload, (header = []) => {
      const updatedData = header?.map((item) => ({ ...item, isSelected: true, })) || [];
      setSelectedVariables(updatedData);
    }));
  };


  const handleFieldChange = (e) => {
    // console.log("handleFieldChange",e)
    // setSelectedVariables([]);
    const reportData = permittedReportCards?.find((dl) => dl?.reportType == state?.reportType) || {};
    const reportType = getReportType(reportData);
    const inventoryType = reportType === "standardReport"
      ? reportData?.reportType === "oldStockReport" ? "block" : "shipment"
      : "all";
    if (e.target.name === "fieldType") {
      setSelectedVariables([])
      setVariablesSettings({
        ...variablesSettings,
        [e.target.name]: e.target.value,
        template_Id: "",
        skuID: "",
        inventoryType: inventoryType,
        asnTemplate: "",
        cellId:[],
      });
      if (e.target.value === "standardReport") {
        getListHeaderData(inventoryType, "");
      }
    } 
    else {
      setVariablesSettings({
        ...variablesSettings,
        skuID: "",
        cellId:[],
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "inventoryType" && variablesSettings.fieldType) {
      setSelectedVariables([])
      if (variablesSettings.fieldType === "standardReport") {
        getListHeaderData(e.target.value);
      } 
      else if (
        variablesSettings.fieldType === "templateSpecific" &&
        variablesSettings.template_Id
      ) 
      {
          getListHeaderData(e.target.value,"",asnTempId);
      }
    }
    if (e.target.name === "template_Id") {
      setSelectedVariables([])
      getListHeader(e.target.value);
      setSelectCheckBox(true);
    }
    // setSelectedVariables([]);
  };

  const handleAsnField = (e, option) => {
    setVariablesSettings({
      ...variablesSettings,
      asnTemplate: option?.name,
    });
    getListHeader(variablesSettings?.template_Id, option?._id);
    setAsnTempId(option?._id);
  };

  const handleVariableCheckBox = (value) => {
    const updatedData = selectedVariables.map((item) => {
      if (item.field_name === value) {
        return {
          ...item,
          isSelected: !item.isSelected,
        };
      }
      return item;
    });
    setSelectedVariables(updatedData);
  };

  const handleSelectAll = (e) => {
    const updatedData = selectedVariables.map((item) => {
      return {
        ...item,
        isSelected: e.target.checked,
      };
    });
    setSelectedVariables(updatedData);
    // setSelectCheckBox(false)
  };


  const handleDateRangeStart = (start_date) => {
    setVariablesSettings({
      ...variablesSettings,
      dateFrom: start_date,
    });
  };

  const handleDateRangeEnd = (end_date) => {
    let tempEndDate = end_date && dayjs(end_date).hour() === 0 && dayjs(end_date).minute() === 0
      ? dayjs(end_date).hour(23).minute(59).second(0).millisecond(0)
      : end_date;

    setVariablesSettings({
      ...variablesSettings,
      dateTo: tempEndDate,
    });
  };
  const handleClearDate = (dateFeild) => {
    setVariablesSettings({
      ...variablesSettings,
      [dateFeild]: "",
    });
  };

  const handleInventoryAgeChange = (type = "minAge", newValue) => {
    setVariablesSettings({
      ...variablesSettings,
      inventoryAge: {
        ...variablesSettings.inventoryAge,
        [type]: newValue
      }
    });
  }

  const handleClearField = () => {
    const updatedData = selectedVariables.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    });
    setSelectedVariables(updatedData);
  };

  const downloadExcel = ({ success = false, message, data = "" }) => {
    showToast(message, !success);
    if (data) {
      let contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let blob = b64toBlob(data, contentType);
      let url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    }
    setIsLoader(false);
  };
  const handleSendEmail = (type = "getOnEmail", info = {}, cb) => {
    if (type == "getOnEmail") {
      const startTime = new Date(variablesSettings.dateFrom).getTime(); // Convert to timestamp in milliseconds
      const endTime = new Date(variablesSettings.dateTo).getTime(); // Convert to timestamp in milliseconds

      // Calculate time difference in milliseconds
      const timeDifferenceinMiliSec = endTime - startTime;

      // Check for skuHistoryReport with isAllSkus enabled
      if (
        variablesSettings?.reportDetail?.reportType === "skuHistoryReport" &&
        isAllSkus === true &&
        timeDifferenceinMiliSec > 86400000   // 24 hours in milliseconds     
      ) {
        showToast(
          "You can see only 24 hours report!",
          true
        );
        return;
      }

      // General condition for other reports
      if (timeDifferenceinMiliSec > 360 * 60 * 60 * 1000) {   //  // 360 hours in milliseconds
        showToast("You can see only 45 days report!", true);
        return;
      }
    }
    const payload = {
      template_Id: variablesSettings.template_Id,
      asnTemplate_Id: asnTempId,
      inventory_type: variablesSettings?.reportDetail?.reportType === "inventoryAgeReport" ? "" : variablesSettings.inventoryType,
      templateSpecific: variablesSettings.fieldType === "templateSpecific",
      transaction_type: variablesSettings.inventoryType,
      report_type: variablesSettings.reportDetail.reportType,
      download_type: "excel",
      selectedfield: selectedVariables.filter((item) => item.isSelected)?.map((item) => item?.field_id) || [],
      floorId: variablesSettings.floorId,
      warehouseId: variablesSettings.warehouseId,
      email: visibleEmail,
      inventoryAge: ageRanges,
      ...(type == "getOnEmail" ? {
        fromDate: variablesSettings.dateFrom,
        toDate: variablesSettings.dateTo
      } : { ...(info || {}) }),
    };
    if (variablesSettings?.reportDetail?.reportType === "skuHistoryReport" && !isAllSkus) payload["skuID"] = variablesSettings?.skuID
    if (variablesSettings?.reportDetail?.reportType === "skuHistoryReport" && isAllSkus) payload["allSKUsSelected"] = isAllSkus
    if (standardReportTypeList.includes(variablesSettings?.reportDetail?.reportType)) {
      if (variablesSettings?.reportDetail?.reportType === "inwardSummaryReport") {
        payload.job_type = "gateIn";
      }
      else if (variablesSettings?.reportDetail?.reportType === "outwardSummaryReport") {
        payload.job_type = "sendToUser";
      }
    }
    if (variablesSettings?.reportDetail?.reportType === "oldStockReport") {
      payload.cellId = variablesSettings?.cellId?.length ? variablesSettings?.cellId?.map(dl=>dl.cellId) :[];
    }
    setIsLoader(false);
    if (type == "getOnEmail") {
      dispatch(
        getWMSReport(payload, ({ success = "", message }) => {
          showToast(message, !success);
          if (success) {
            setVisibleEmail([]);
            setAddEmail("");
            setOpen(false);
          }
        })
      );
    }
    else {
      dispatch(
        scheduleReportEmail(payload, ({ success = "", message }) => {
          showToast(message, !success);
          if (cb) cb()
          if (success) {
            setVisibleEmail([]);
            setAddEmail("");
            setOpen(false);
          }
        })
      );
    }
    // }
  };
  const downloadSample = async () => {
    const startTime = new Date(variablesSettings.dateFrom).getTime(); // Convert to timestamp in milliseconds
    const endTime = new Date(variablesSettings.dateTo).getTime(); // Convert to timestamp in milliseconds

    // Calculate time difference in milliseconds
    const timeDifferenceinMiliSec = endTime - startTime;

    // Check for skuHistoryReport with isAllSkus enabled
    if (
      variablesSettings?.reportDetail?.reportType === "skuHistoryReport" &&
      isAllSkus === true &&
      timeDifferenceinMiliSec > 86400000   // 24 hours in milliseconds
    ) {
      showToast(
        "You can see only 24 hours report!",
        true
      );
      return;
    }

    // General condition for other reports
    if (timeDifferenceinMiliSec > 360 * 60 * 60 * 1000) {  // 360 hours in milliseconds
      showToast("You can see only 15 days report!", true);
      return;
    }

    const payload = {
      template_Id: variablesSettings.template_Id,
      templateSpecific: variablesSettings.fieldType === "templateSpecific",
      asnTemplate_Id: asnTempId,
      fromDate: variablesSettings.dateFrom,
      inventory_type: variablesSettings?.reportDetail?.reportType === "inventoryAgeReport" ? "" : variablesSettings.inventoryType,
      toDate: variablesSettings.dateTo,
      report_type: variablesSettings?.reportDetail?.reportType,
      download_type: "excel",
      selectedfield:
        selectedVariables
          .filter((item) => item.isSelected)
          ?.map((item) => item?.field_id) || [],
      floorId: variablesSettings.floorId,
      warehouseId: variablesSettings.warehouseId,
      transaction_type: variablesSettings.inventoryType,
      inventoryAge: ageRanges,
    };

    if (variablesSettings?.reportDetail?.reportType === "skuHistoryReport" && !isAllSkus) payload["skuID"] = variablesSettings?.skuID
    if (variablesSettings?.reportDetail?.reportType === "skuHistoryReport" && isAllSkus) payload["allSKUsSelected"] = isAllSkus

    if (standardReportTypeList.includes(variablesSettings?.reportDetail?.reportType)) {
      if (variablesSettings?.reportDetail?.reportType === "inwardSummaryReport") {
        payload.job_type = "gateIn";
      }
      else if (variablesSettings?.reportDetail?.reportType === "outwardSummaryReport") {
        payload.job_type = "sendToUser";
      }
    }
    if (variablesSettings?.reportDetail?.reportType === "oldStockReport") {
      payload.cellId = variablesSettings?.cellId?.length ? variablesSettings?.cellId?.map(dl=>dl.cellId) :[];
    }
    setIsLoader(true);
    dispatch(getWMSReport(payload, downloadExcel));

  };

  const handleClickOpen = (type = "getOnEmail") => {
    setOpen(type);
  };
  const handleClose = () => {
    setVisibleEmail([]);
    setAddEmail("");
    setOpen(false);
  };
  const frequencyData = {
    Custom: "Custom",
    Month: "month",
    Weak: "Weak",
  };

  const getSelectedVariableCount = () => {
    return selectedVariables.filter((item) => item.isSelected)?.length;
  };

  const getTemplateOption = (
    report_type,
    tem_list = [],
    filterTemplate = "inwardDataEntry"
  ) => {
    if (["itemMasterReport", "inventorySummaryReport","skuHistoryReport","outwardReport"].includes(report_type)) {
      return tem_list; //.filter((dl) => dl?.templateType == "itemMaster");
    }
    if (["inwardReport"].includes(report_type)) {
      let templateType = filterTemplate;
      tem_list = tem_list.filter((dl) => dl?.templateType == templateType);
      return tem_list;
    }
    return [];
  };

  const handleEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(addEmail)) {
      setVisibleEmail([...visibleEmail, addEmail]);
      setAddEmail("");
    } else {
      showToast("Please enter valid email", true);
    }
  };

  const reporthandle = () => {
    setVariablesSettings(initial_state);
    dispatch({ type: GET_REPORT, payload: [] });
    history.push("/dashboard/report-management");
    setSelectedVariables([]);
  };

  let inventoryVisibilityHandler = (type = ["S"]) => {
    if(["outwardReport","oldStockReport"].includes(variablesSettings.reportDetail.reportType)) return true;
    if (variablesSettings?.fieldType == "templateSpecific") {
      if (!variablesSettings.template_Id) {
        return false;
      }
      let isExist = allTemplates.find(
        (dl) => dl._id == variablesSettings.template_Id
      )?.templateLevel;
      return type.includes(isExist);
    }
    return true;
  };

  const handleAddAgeInput = () => {
    if (variablesSettings?.reportDetail?.reportType == "inventoryAgeReport") {

      if (variablesSettings?.inventoryAge?.minAge < 1 && variablesSettings?.inventoryAge?.maxAge < 1) {
        showToast("Please enter min and max ages respectively", true);
        return;
      }
      else if (variablesSettings?.inventoryAge?.maxAge < 1) {
        showToast("Maximum inventory age is required and must be greater then 1!!", true);
        return;
      }
      else if (variablesSettings?.inventoryAge?.minAge >= variablesSettings?.inventoryAge?.maxAge) {
        showToast("Max age must be greater than Min age!!", true);
        return;
      }

    }
    const newAgeRange = {
      minAge: variablesSettings?.inventoryAge?.minAge || 0,
      maxAge: variablesSettings?.inventoryAge?.maxAge
    };

    setAgeRanges((prevAgeRanges) => [...prevAgeRanges, newAgeRange]);

    setVariablesSettings((prevSettings) => ({
      ...prevSettings,
      inventoryAge: { minAge: "", maxAge: "" }
    }));

  }

  const handleRemoveAgeRange = (index) => {
    setAgeRanges((prevAgeRanges) => prevAgeRanges.filter((_, i) => i !== index));
  };

  const disabledCondition = (type="download") => {
    // Check for the skipTime condition first
    if(isLoader) return true
    if (variablesSettings?.reportDetail?.skipTime === true) {
      // Check if the reportType is "inventoryAgeReport"
      if (variablesSettings?.reportDetail?.reportType === "inventoryAgeReport") {
        // If ageRanges is less than 1, return true
        return ageRanges?.length < 1;
      }
      // Return false if the reportType is not "inventoryAgeReport"
      return false;
    }
    if (variablesSettings?.reportDetail?.reportType === "skuHistoryReport" && (!variablesSettings?.skuID && !isAllSkus)) {
      // If ageRanges is less than 1, return true
      return true;
    }

    // Check other conditions if skipTime is not true
    if (!selectedVariables.find((item) => item.isSelected)) return true

    if (type == "download") {
      return (!variablesSettings.dateTo || !variablesSettings.dateFrom);
    }
    return false
    
  }

// Usage example:


  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={3}>
          <Typography
            sx={{
              ...textClasses.cardTitle,
            }}
          >
            Download Reports
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              onClick={reporthandle}
              underline="hover"
              color="#FF7200"
              sx={{
                ...textClasses.t12n,
                color: "#FF7200",
                cursor: "pointer",
              }}
            >
              Report
            </Typography>
            <Typography
              sx={{
                ...textClasses.t12n,
                color: "#000000",
              }}
            >
              {(variablesSettings?.reportDetail?.header || "") +
                " -" +
                "Settings"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Report Type
                </InputLabel>
                <Select
                  labelId="select-Inward-Report"
                  id="Inward Report"
                  data-testid="reportType"
                  label="Report Type"
                  size="small"
                  name="reportType"
                  value={variablesSettings?.reportDetail?.reportType || ""}
                  onChange={reportTypeHandle}
                >
                  {permittedReportCards.map((item) => (
                    <MenuItem value={item.reportType}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Select Warehouse
                </InputLabel>
                <Select
                  labelId="select-Inward-Report"
                  id="Select Warehouse"
                  data-testid="warehouseFilter"
                  name="warehouseId"
                  value={variablesSettings.warehouseId}
                  label="Select Warehouse"
                  size="small"
                  onChange={(e) => {
                    handleWarehouseFloors(e.target.value);
                  }}
                >
                  {warehouseDetail?.data?.map((item) => (
                    <MenuItem value={item?._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Select Floor
                </InputLabel>
                <Select
                  labelId="select-Inward-Report"
                  id="Inward Report"
                  data-testid="floorFilter"
                  value={variablesSettings.floorId}
                  label="Inward Report"
                  size="small"
                  name="floorId"
                  onChange={(e) => {
                    getFloorValue(e.target.value);
                  }}
                >
                  {floorDetail?.map((item) => (
                    <MenuItem value={item._id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#ff72002b",
          minHeight: "110px",
          width: "100%",
          marginTop: "15px",
          borderRadius: "8px",
          border: "1px solid #FF7200",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ padding: '10px' }}
        >
          <Grid
            item
            xs={12} sm={4} md={3} lg={2}
          >
            <ReportBackgroundImg fullWidth />
          </Grid>
          <Grid container item xs={12} sm={8} md={9} lg={10}>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={7} md={9} lg={9.7}>
                <Typography
                  sx={{
                    ...textClasses.cardTitle,
                    color: "#FF7200",
                    fontWeight: "700",
                  }}
                >
                  Schedule Reports On Your Email(s)
                </Typography>
              </Grid>
              <Grid
                item
                xs={12} sm={5} md={3} lg={2}
              >
                <Button
                  variant="contained"
                  data-testid="emailBtn"
                  disabled={disabledCondition("scheduleEmail")}         
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    textTransform: "none",
                    width: 170
                  }}
                  onClick={() => handleClickOpen("scheduleEmail")}
                >
                  <ScheduleSendIcon
                    sx={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  Schedule Email
                </Button>
              </Grid>
            </Grid>
            <Typography
              sx={{
                ...textClasses.t16n,
                color: "#FF7200",
                fontWeight: "600",
                fontFamily: "Nunito",
              }}
            >
              You can map more than one report to a scheduler. This will be
              helpful when you want more than one report to be sent
              periodically.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={2}
        gap="15px"
        sx={{
          margin: "20px  0 0 0",
        }}
      >
        <Grid
          className="p-0"
          item
          // xs={3.6}
          xs={12} sm={12} md={3.6} lg={3.6}
          sx={{
            minHeight: "10vh",
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            padding: 5,
          }}
          spacing={2}
        >
          <Grid
            xs={12}
            className="pt-3 pb-3 mb-2"
            sx={{
              backgroundColor: "#FF7200",
              color: "white",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Box
              sx={{
                paddingLeft: "15px",
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  ...textClasses.t18n,
                  marginLeft: "10px",
                  color: "#FFFFFF",
                }}
              >
                <img
                  style={{ margin: " 0 10px 0 -10px" }}
                  src={ReportSettingIcon}
                />
                Reports - Variable & Settings{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sx={{
              width: "100%",
              p: 2,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 2
            }}

          >
            <Grid xs={12} sm={5} md={12}>
              <FormControl
                fullWidth
                data-testid="categoryFilter"
                sx={{
                  ...selectStylesOverride,
                  color: theme.themeOrange,
                }}
              >
                <InputLabel size="small" id="Standard-Report">
                  Select Category
                </InputLabel>
                <Select
                  labelId="Select Field"
                  id="Select Field"
                  label="Select Category"
                  size="small"
                  name="fieldType"
                  onChange={handleFieldChange}
                  value={variablesSettings.fieldType || ""}
                >
                  {standardReportTypeList.includes(
                    variablesSettings.reportDetail.reportType
                  ) && (
                      <MenuItem value="standardReport">
                        {" "}
                        Standard Report Download
                      </MenuItem>
                    )}
                  {(["inwardReport", "outwardReport"].includes(variablesSettings.reportDetail.reportType) || !standardReportTypeList.includes(
                    variablesSettings.reportDetail.reportType
                  )) && (
                      <MenuItem value="templateSpecific">
                        Template Specific Report Download
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            {variablesSettings.fieldType === "templateSpecific" && (
              <>
                <Grid xs={12} sm={5} md={12}>
                  <FormControl
                    fullWidth
                    sx={{
                      ...selectStylesOverride,
                      fontSize: "12px",
                      color: theme.themeOrange,
                    }}
                  >
                    <InputLabel
                      size="small"
                      id="Template Specific Report Download"
                    >
                      {variablesSettings.reportDetail.reportType ===
                        "inwardReport"
                        ? "Select Inward Template"
                        : "Select Template"}
                    </InputLabel>
                    <Select
                      labelId="Template Specific Report Download"
                      id="Template Specific Report Download"
                      label="Select Inward Template"
                      size="small"
                      name="template_Id"
                      onChange={handleFieldChange}
                      value={variablesSettings.template_Id || ""}
                    >
                      {getTemplateOption(
                        variablesSettings?.reportDetail?.reportType,
                        allTemplates,
                        "inwardDataEntry"
                      )?.map((item) => {
                        return (
                          <MenuItem value={item._id}> {item.name} </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                {variablesSettings.template_Id && variablesSettings.fieldType === "templateSpecific" && variablesSettings?.reportDetail?.reportType === "inwardReport" &&
                  (
                    <Grid xs={12} sm={5} md={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          size="small"
                          labelId="jobName-label"
                          sx={{ height: "1.1rem", margin: "10px 0 15px 0" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <Typography sx={{ fontSize: "12px" }}>
                                  Select ASN Template
                                </Typography>
                              }
                              sx={{
                                ...selectStylesOverride,
                                color: theme.themeOrange,
                                fontSize: "12px",
                                "& input::placeholder": {
                                  fontSize: "12px",
                                },
                              }}
                            />
                          )}
                          name="asnTemplate"
                          options={getTemplateOption(
                            variablesSettings?.reportDetail?.reportType,
                            allTemplates,
                            "ASNTemplate"
                          )}
                          getOptionLabel={(option) => option.name || ""} 
                          onChange={handleAsnField}
                          value={variablesSettings.asnTemplate ? { name: variablesSettings.asnTemplate } : null}
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              value={option?.name || ""}
                              key={option?.name || ""}
                              {...props}
                            >
                              <Typography
                                sx={{
                                  fontSize: ".8rem",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {option?.name || ""}
                              </Typography>
                            </MenuItem>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  )}

                {variablesSettings?.reportDetail?.reportType == "inventorySummaryReport" && (
                  <Grid xs={12}>
                    <FormControl
                      sx={{
                        ...textClasses.t14n,
                      }}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="inventoryType"
                        onChange={handleFieldChange}
                        value={variablesSettings.inventoryType}
                      >
                        <FormControlLabel
                          value="all"
                          control={
                            <Radio
                              sx={{
                                color: grey[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                              }}
                            />
                          }
                          label="all"
                        />
                        <FormControlLabel
                          value="with"
                          control={
                            <Radio
                              sx={{
                                color: grey[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                  fontSize: "14px",
                                },
                              }}
                            />
                          }
                          label="with transaction"
                        />
                        {/* <FormControlLabel
                          value="without"
                          control={
                            <Radio
                              sx={{
                                color: grey[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                              }}
                            />
                          }
                          label="without transaction"
                        /> */}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            <>
              {variablesSettings?.reportDetail?.reportType == "skuHistoryReport" && variablesSettings?.template_Id ?
                <Grid container>
                  {/* <Grid item md={12}>
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        // margin: "8px 0 0 20px",
                        color: "#000000",
                        mb: 2
                      }}
                    >
                      SKU* :
                    </Typography>
                  </Grid> */}
                  <Grid item  xs={12} sm={5} md={12}>
                    <TypeAndSearch
                      data-testid="skuFilter"
                      component={{
                        ...component({
                          type: "SKU",
                          filter: [
                            {
                              label: "template_id",
                              value: variablesSettings?.template_Id || "",
                            },
                            {
                              label: "isSkipTemplate",
                              value: false,
                            },
                            {
                              label: "dataEntryType",
                              value: ["item", "package", "kit"],
                            }
                          ]
                        }),
                      }}
                      disabled={!variablesSettings?.template_Id || isAllSkus}
                      handleChange={(e, option) => {
                        handleFieldChange({ target: { name: "skuID", value: option?.value || "" } });
                      }}
                      value={variablesSettings?.skuID || ""}
                      label={variablesSettings?.skuID || ""}
                      set_Value={true}
                    />
                    {/* <Grid item xs={3}>
                    </Grid> */}
                    <FormGroup>
                      <FormControlLabel control={<Checkbox
                        size="small"
                        checked={isAllSkus}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          setIsAllSkus(isChecked);
                          setVariablesSettings((prev) => ({
                            ...prev,
                            skuID: isChecked ? "" : prev.skuID, // Reset `skuID` if checked
                          }));
                        }}
                      />} label={<Typography sx={{...textClasses.t15n}}>
                        Select All SKUs
                      </Typography>} />
                    </FormGroup>
                  </Grid>
              
                </Grid>
                : ""
              }
              {variablesSettings?.reportDetail?.reportType == "inventoryAgeReport" ?
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        // margin: "8px 0 0 20px",
                        color: "#000000",
                        mb: 2
                      }}
                    >
                      Inventory Age (Time spent in warehouse)* :
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mr: 2 }}>
                    <GenericUserInputComponent
                      label="Min(days)"
                      value={variablesSettings?.inventoryAge?.minAge}
                      onChange={(newValue) => handleInventoryAgeChange("minAge", newValue)}
                      width="92px"
                      data-testid="min-age-filter"
                    />
                  </Grid>
                  <Grid item>
                    <GenericUserInputComponent
                      label="Max(days)"
                      value={variablesSettings?.inventoryAge?.maxAge}
                      onChange={(newValue) => handleInventoryAgeChange("maxAge", newValue)}
                      width="92px"
                      data-testid="max-age-filter"
                    />
                  </Grid>
                  <Grid item>
                    <Button onClick={handleAddAgeInput} sx={{ ...buttonClasses.lynkitBlackFill, fontSize: "15px", ml: 2, height: '32px', mt: 0.3 }}
                      disabled={
                        !variablesSettings?.inventoryAge?.maxAge || variablesSettings?.inventoryAge?.maxAge < 1}
                    ><Add fontSize="inherit" />Add</Button>
                  </Grid>
                </Grid>
                : ""
              }
              {["inwardSummaryReport", "outwardSummaryReport", "inwardReport","outwardReport","oldStockReport"].includes(variablesSettings.reportDetail.reportType)
                ? (
                  <Grid sx={12}>
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        // margin: "8px 0 0 20px",
                        color: "#000000",
                      }}
                    >
                      Select {variablesSettings?.reportDetail?.reportType=="oldStockReport"? "Location":"Inventory"} Type :
                    </Typography>
                    <FormControl
                      className="ml-2"
                      sx={{
                        margin: "0 0 0 15px",
                        ...textClasses.t14n,
                      }}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="inventoryType"
                        onChange={handleFieldChange}
                        value={variablesSettings.inventoryType}
                      >
                        {["inwardReport", "outwardReport"].includes(variablesSettings.reportDetail.reportType) && (
                          <FormControlLabel
                            value="shipment"
                            control={
                              <Radio
                                sx={{
                                  color: grey[800],
                                  "&.Mui-checked": {
                                    color: orange[800],
                                  },
                                }}
                              />
                            }
                            label="Shipment"
                          />
                        )}
                        {inventoryVisibilityHandler(["SPI", "SP"]) && (
                          <FormControlLabel
                            value={variablesSettings?.reportDetail?.reportType=="oldStockReport"? "block":"package"}
                            control={
                              <Radio
                              sx={{
                                color: grey[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                                }}
                                />
                              }
                            label={variablesSettings?.reportDetail?.reportType=="oldStockReport"? "Block":"Package"}
                          />
                        )}
                        {inventoryVisibilityHandler(["SPI", "SI"]) && (
                          <FormControlLabel
                            value={variablesSettings?.reportDetail?.reportType=="oldStockReport"? "rack":"item"}
                            control={
                              <Radio
                                sx={{
                                  color: grey[800],
                                  "&.Mui-checked": {
                                    color: orange[800],
                                  },
                                }}
                              />
                            }
                            label={variablesSettings?.reportDetail?.reportType=="oldStockReport"? "Rack":"Item"}
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
               {variablesSettings?.reportDetail?.reportType == "oldStockReport" ?
                <Grid container>
                  <TypeAndSearch
                    data-testid="skuFilter"
                    component={{
                      ...component({
                        type: "gridAllLocation",
                        filter: [
                          {
                            label: "floorId",
                            value: variablesSettings?.floorId || "",
                          },
                          {
                            label: "type",
                            value: variablesSettings.inventoryType || "block",
                          },
                        ]
                      }),
                    }}
                    disabled={!variablesSettings.inventoryType}
                    handleChange={(e, option) => {
                      let tempField={
                        skuID: "",
                        ...variablesSettings,
                      }
                      if(!tempField.cellId || !Array.isArray(tempField.cellId)){
                        tempField.cellId=[]
                      }
                      let isExist=tempField?.cellId?.find(dl=>dl.cellId==option.cellId)
                      if(!isExist){
                        tempField.cellId.unshift(option)
                        setVariablesSettings(tempField);
                      }
                    }}
                    value={""}
                    setEmptyOnChange={true}
                    // value={variablesSettings?.cellId?.label || ""}
                    // label={variablesSettings?.cellId?.value || ""}
                    // set_Value={true}
                  />
                </Grid>
                : ""
              }
            </>
          </Grid>

          <Typography
            sx={{
              ...textClasses.boldText,
              margin: "8px 0 0 20px",
              color: "#000000",
            }}
          >
            Select Variables :
          </Typography>
          {
            <Grid xs={12} container item>
              {selectedVariables?.length > 0 && selectCheckBox ? (
                <>
                  <FormControlLabel
                    sx={{
                      marginLeft: "5%",
                      ...textClasses.t14n,
                      color: "#827878",
                    }}
                    label="Select All"
                    name="selectedVariabled"
                    data-testid="variablesSelection"
                    onChange={handleSelectAll}
                    control={
                      <Checkbox
                        data-testid="checkbox"
                        checked={
                          selectedVariables.length ===
                          selectedVariables.filter((dl) => dl.isSelected === true)?.length
                        }
                      />
                    }
                  />
                  <Grid
                    container
                    item
                    sx={{
                      overflowY: "auto",
                      maxHeight: "35vh",
                      marginBottom: "10px",
                      flexGrow: 1,
                    }}
                  >
                    {selectedVariables?.map((item) => (
                      <Grid
                        key={item.field_name}
                        xs={12}
                        sm={5}
                        md={12}
                        sx={{
                          marginLeft: "5%",
                          ...textClasses.t14n,
                          color: "#827878",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          value={item.field_name}
                          onChange={(e) => handleVariableCheckBox(item.field_name)}
                          checked={item.isSelected}
                        />
                        {item.field_name}
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>

          }
        </Grid>
        <Grid
          className="p-0"
          item
          // xs={8.1}
          xs={12} sm={12} md={8.1} lg={8.1}
          sx={{
            minHeight: "10vh",
            overflow: "auto",
            border: "1px solid #D9D9D9",
            borderRadius: "8px",

          }}
        >
          <Box
            className="pt-2 pb-2 ps-3"
            sx={{
              display: "flex",
              backgroundColor: "#FF7200",
              color: "white",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={5.9}>
                <Typography
                  sx={{
                    ...textClasses.t18n,
                    marginLeft: "10px",
                    color: "#FFFFFF",
                    padding: "7px 0 0 0",
                  }}
                >
                  <img
                    style={{
                      margin: "0 10px 0 -10px",
                    }}
                    src={InwardDownloadReportIcon}
                  />
                  {(variablesSettings?.reportDetail?.title || "") +
                    " - " +
                    "Download"}
                </Typography>
              </Grid>
              {!variablesSettings?.reportDetail?.skipTime &&
                <Grid item xs={3} sx={{ position: "relative" }}>

                  <DateTimePicker
                    className={`${classes.datePickerContainer}`}
                    onChange={handleDateRangeStart}
                    slotProps={{ textField: { size: "small" } }}
                    placeholderText="From Date"
                    ClearIcon
                    value={variablesSettings.dateFrom}
                  />
                  {variablesSettings.dateFrom && (
                    <ClearIcon
                      sx={{
                        color: "grey",
                        position: "absolute",
                        top: 23,
                        right: 31,
                      }}
                      onClick={() => handleClearDate("dateFrom")}
                    />
                  )}
                </Grid>
              }
              {!variablesSettings?.reportDetail?.skipTime &&
                <Grid item xs={3} sx={{ position: "relative" }}>
                  <DateTimePicker
                    className={`${classes.datePickerContainer}`}
                    disabled={!variablesSettings.dateFrom}
                    value={variablesSettings.dateTo}
                    onChange={handleDateRangeEnd}
                    minDateTime={dayjs(variablesSettings.dateFrom).subtract(
                      0,
                      "day"
                    )}
                    maxDateTime={dayjs(variablesSettings.dateFrom).add(15, "day")}
                    slotProps={{ textField: { size: "small" } }}
                    placeholderText="To Date"
                  />
                  {variablesSettings.dateTo && (
                    <ClearIcon
                      sx={{
                        color: "grey",
                        position: "absolute",
                        top: 23,
                        right: 28,
                      }}
                      onClick={() => handleClearDate("dateTo")}
                    />
                  )}
                </Grid>
              }
            </Grid>
          </Box>

          {getSelectedVariableCount() ? (
            <Box
              sx={{
                padding: "5px 8px 10px 5px",
              }}
            >
              <Typography
                sx={{
                  ...textClasses.t12n,
                  fontFamily: "Nunito",
                  fontStyle: "italic",
                  color: "#000000",
                  padding: "8px 0 0 10px",
                }}
              >
                Note : Up to 15 days report can be downloaded by clicking on
                "Download Now" button, please use "Get On Email" button for
                monthly report downloads.
              </Typography>
              {getSelectedVariableCount() ? (
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography
                      sx={{
                        ...textClasses.t14n,
                        color: "#827878",
                        margin: "10px 0 0 0 ",
                      }}
                    >
                      {getSelectedVariableCount()} variables selected to
                      download reports
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        ...textClasses.t14n,
                        color: "#FF7200",
                        margin: "10px 0 0 0 ",
                        textDecoration: "underLine",
                        cursor: "pointer",
                      }}
                      onClick={handleClearField}
                    >
                      Clear all selected fields
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Box
                sx={{
                  border: getSelectedVariableCount() && "",
                  borderStyle: "dashed",
                  borderColor: "#D9D9D9",
                  margin: "13px 0 0 0",
                  padding: "10px 20px 30px 20px",
                }}
              >
                {selectedVariables?.map((item) =>
                  item.isSelected === true ? (
                    <span
                      style={{
                        backgroundColor: "rgb(255 112 0 / 18%)",
                        margin: "20px 10px 0 3px ",
                        padding: "0px 0 0px 5px",
                        color: "#FF7200",
                        border: "1px solid #FF7200",
                        borderRadius: "8px",
                        display: "inline-block",
                        ...textClasses.t12n,
                      }}
                    >
                      {item.field_name}{" "}
                      <IconButton
                        aria-label="close"
                        onClick={(e) => handleVariableCheckBox(item.field_name)}
                        sx={{
                          color: "#FF7200",
                          padding: "4px",
                        }}
                      >
                        <CloseIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            marginBottom: "3px",
                          }}
                        />
                      </IconButton>
                    </span>
                  ) : (
                    ""
                  )
                )}
                {variablesSettings?.reportDetail?.reportType == "inventoryAgeReport" ?
                  ageRanges?.map((range, index) => (
                    <span
                      style={{
                        backgroundColor: "rgb(255 112 0 / 18%)",
                        margin: "20px 10px 0 3px ",
                        padding: "0px 0 0px 5px",
                        color: "#FF7200",
                        border: "1px solid #FF7200",
                        borderRadius: "8px",
                        display: "inline-block",
                        ...textClasses.t12n,
                      }}
                    >
                      {range.minAge}-{range.maxAge} days

                      <IconButton
                        aria-label="close"
                        onClick={(e) => handleRemoveAgeRange(index)}
                        sx={{
                          color: "#FF7200",
                          padding: "4px",
                        }}
                      >
                        <CloseIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            marginBottom: "3px",
                          }}
                        />
                      </IconButton>
                    </span>
                  )) : ""
                }
                {variablesSettings?.reportDetail?.reportType == "oldStockReport" ?
                  variablesSettings?.cellId?.map((cellDetail, index) => (
                    <span
                      style={{
                        backgroundColor: "rgb(255 112 0 / 18%)",
                        margin: "20px 10px 0 3px ",
                        padding: "0px 0 0px 5px",
                        color: "#FF7200",
                        border: "1px solid #FF7200",
                        borderRadius: "8px",
                        display: "inline-block",
                        ...textClasses.t12n,
                      }}
                    >
                      {cellDetail?.name || ""}

                      <IconButton
                        aria-label="close"
                        onClick={(e) => {
                          let tempField = {
                            ...variablesSettings,
                          }
                          if (!tempField.cellId || !Array.isArray(tempField.cellId)) {
                            tempField.cellId = []
                          }
                          let isExist = tempField?.cellId?.findIndex(dl => dl.cellId == cellDetail.cellId)
                          if (isExist >= 0) {
                            tempField.cellId.splice(isExist, 1)
                            setVariablesSettings(tempField);
                          }
                        }}
                        sx={{
                          color: "#FF7200",
                          padding: "4px",
                        }}
                      >
                        <CloseIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            marginBottom: "3px",
                          }}
                        />
                      </IconButton>
                    </span>
                  )) : ""
                }
              </Box>
              <Grid container spacing={0} xs={12}>
                <Grid item>
                  <Button
                    variant="contained"
                    spacing={1}
                    disabled={disabledCondition("download")}
                    sx={{
                      ...buttonClasses.lynkitBlackFill,
                      marginRight: "10px",
                      marginTop: "20px",
                      width: 180
                    }}
                    onClick={downloadSample}
                  >
                    {isLoader ? (
                      "Generating..."
                    ) : (
                      <>
                        <DownloadIcon />
                        Download Now
                      </>
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    spacing={1}
                    onClick={() => handleClickOpen("getOnEmail")}
                    // hidden={true}
                    disabled={disabledCondition("getOnEmail")}
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      marginTop: "20px",
                    }}
                  >
                    <AttachEmailOutlinedIcon /> Get On Email(s)
                  </Button>
                  {open && open == "getOnEmail" && <BootstrapDialog
                    fullWidth={true}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open == "getOnEmail"}
                    className="report-dialog"
                  >
                    <DialogTitle
                      sx={{
                        m: 0,
                        p: 0,
                        textAlign: "start",
                        ...textClasses.cardTitle,
                        color: "#000000",
                      }}
                      id="customized-dialog-title"
                    >
                      Get Reports On Your Email(s)
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent>
                      {!variablesSettings?.reportDetail?.skipTime &&
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <Box>
                            <DateTimePicker
                              value={variablesSettings.dateFrom}
                              onChange={handleDateRangeStart}
                              slotProps={{ textField: { size: "small" } }}
                              placeholderText="Select Date Range"
                              sx={{ ...selectStylesOverride, color: theme.themeOrange, }}
                            />
                          </Box>
                          <Box>
                            <DateTimePicker
                              sx={{ ...selectStylesOverride, color: theme.themeOrange, }}
                              value={variablesSettings.dateTo}
                              disabled={!variablesSettings.dateFrom}
                              onChange={handleDateRangeEnd}
                              slotProps={{ textField: { size: "small" } }}
                              placeholderText="Select Date Range"
                            />
                          </Box>
                        </Box>
                      }
                      {variablesSettings?.reportDetail?.skipTime && variablesSettings?.reportDetail?.reportType == "inventoryAgeReport" &&
                        <Grid container>
                          <Grid item md={12}>
                            <Typography
                              sx={{
                                ...textClasses.boldText,
                                // margin: "8px 0 0 20px",
                                color: "#000000",
                                mb: 2
                              }}
                            >
                              Inventory Age (Time spent in warehouse)* :
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mr: 2 }}>
                            <GenericUserInputComponent
                              label="Min(days)"
                              value={variablesSettings?.inventoryAge?.minAge}
                              onChange={(newValue) => handleInventoryAgeChange("minAge", newValue)}
                              width="92px"
                            />
                          </Grid>
                          <Grid item>
                            <GenericUserInputComponent
                              label="Max(days)"
                              value={variablesSettings?.inventoryAge?.maxAge}
                              onChange={(newValue) => handleInventoryAgeChange("maxAge", newValue)}
                              width="92px"
                            />
                          </Grid>
                          <Grid item>
                            <Button onClick={handleAddAgeInput} sx={{ ...buttonClasses.lynkitBlackFill, fontSize: "15px", ml: 2, height: '32px', mt: 0.3 }}
                              disabled={
                                !variablesSettings?.inventoryAge?.maxAge || variablesSettings?.inventoryAge?.maxAge < 1}
                            ><Add fontSize="inherit" />Add</Button>
                          </Grid>
                          <Grid item md={12}>
                            {variablesSettings?.reportDetail?.reportType == "inventoryAgeReport" ?
                              ageRanges?.map((range, index) => (
                                <span
                                  style={{
                                    backgroundColor: "rgb(255 112 0 / 18%)",
                                    margin: "20px 10px 0 3px ",
                                    padding: "0px 0 0px 5px",
                                    color: "#FF7200",
                                    border: "1px solid #FF7200",
                                    borderRadius: "8px",
                                    display: "inline-block",
                                    ...textClasses.t12n,
                                  }}
                                >
                                  {range.minAge}-{range.maxAge} days

                                  <IconButton
                                    aria-label="close"
                                    onClick={(e) => handleRemoveAgeRange(index)}
                                    sx={{
                                      color: "#FF7200",
                                      padding: "4px",
                                    }}
                                  >
                                    <CloseIcon
                                      sx={{
                                        width: "20px",
                                        height: "20px",
                                        marginBottom: "3px",
                                      }}
                                    />
                                  </IconButton>
                                </span>
                              )) : ""
                            }
                          </Grid>
                        </Grid>
                      }
                      <Typography
                        sx={{
                          textAlign: "start",
                          margin: "25px 0 0 0 ",
                          ...textClasses.boldText,
                        }}
                      >
                        Enter Email(s) to get report at scheduled time period
                      </Typography>
                      <TextField
                        sx={{
                          width: "60%",
                          margin: "19px 0 0 0",
                        }}
                        id="outlined-basic"
                        size="small"
                        label="Enter email(s) to get reports"
                        variant="outlined"
                        onChange={(e) => {
                          setAddEmail(e.target.value);
                        }}
                        value={addEmail}
                      />

                      <Button
                        autoFocus
                        variant="contained"
                        spacing={1}
                        className="RF_sampledownload-btn"
                        onClick={handleEmail}
                        sx={{
                          ...buttonClasses.lynkitBlackFill,
                          margin: "18px 0 10px -20px",
                          textAlign: "start",
                        }}
                      >
                        <AddCircleIcon sx={{ mr: 1 }} />
                        Add
                      </Button>
                      <br></br>

                      {visibleEmail?.map((item, index) => (
                        <span
                          style={{
                            backgroundColor: "rgb(255 112 0 / 18%)",
                            margin: "0px 10px 0 3px ",
                            padding: "0px 7px",
                            color: "#FF7200",
                            border: "1px solid #FF7200",
                            borderRadius: "8px",
                            display: "inline-block",
                            ...textClasses.t12n,
                          }}
                        >
                          {item}
                          <IconButton
                            aria-label="close"
                            onClick={(e) => {
                              const emailList = visibleEmail.filter(
                                (email, emailIndex) => emailIndex !== index
                              );
                              setVisibleEmail(emailList);
                            }}
                            sx={{
                              color: "#FF7200",
                              padding: "4px",
                            }}
                          >
                            <CloseIcon
                              sx={{
                                width: "20px",
                                height: "20px",
                                marginBottom: "3px",
                              }}
                            />
                          </IconButton>
                        </span>
                      ))}
                    </DialogContent>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      }}
                    >
                      Note : 1. Report will be send to added email(s). 2. Date
                      range should be max one months (45 days). 3. If not found
                      mail on inbox Please check spam folder. 4. Please wait for
                      15 minutes to get report on mail.
                    </Typography>
                    <DialogActions
                      sx={{
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        autoFocus
                        onClick={handleClose}
                        variant="contained"
                        spacing={1}
                        className="RF_sampledownload-btn"
                        sx={{
                          ...buttonClasses.lynkitBlackFill,
                          marginRight: "5px",
                          marginTop: "20px",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          ...buttonClasses.lynkitOrangeFill,
                          backgroundColor:
                            visibleEmail?.length < 1 ? "grey" : "#FF7200",
                          border:
                            visibleEmail?.length < 1
                              ? "none"
                              : "1px solid #FF7200",
                          marginTop: "20px",
                          padding: "8px 28px",
                        }}
                        disabled={visibleEmail?.length < 1 ||
                          (variablesSettings?.reportDetail?.reportType == "inventoryAgeReport" ? ageRanges?.length < 1 : "")}
                        autoFocus
                        onClick={() => handleSendEmail("getOnEmail")}
                      >
                        Send Email(s)
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <img src={NovariableIcon} />
              <Typography
                sx={{
                  color: "#FF7200",
                  ...textClasses.t20n,
                  fontFamily: "Nunito",
                }}
              >
                No variable selected, Nothing to download
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Nunito",
                }}
              >
                To download report standard or template specific users need to
              </Typography>{" "}
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "Nunito",
                }}
              >
                {" "}
                select the variable and template (if any)
              </Typography>
            </Box>
          )}


        </Grid>
      </Grid>

      {open && open == "scheduleEmail" &&
        <EmailReportModal
          open={open == "scheduleEmail"}
          handleClose={handleClose}
          handleScheduleReportEmail={handleSendEmail}
          setVisibleEmail={setVisibleEmail}
          visibleEmail={visibleEmail}
        />}
    </>
  );
};
export default DownloadReport;
