import { useEffect, useState,useRef } from "react";
import { useHistory} from "react-router-dom";

// function useWindowOpener(){

// 	const history = useHistory();
// 	const searchParams = new URLSearchParams(window.location.search);
// 	const authParam = searchParams.get("auth");
// 	const actionParam = searchParams.get("action");
  
// 	// // Log the entire location for debugging
// 	// console.log('Res Current URL:', window.location.href);
// 	// console.log('Res Location object:', location);
// 	// console.log('Res SearchParams object:', searchParams);
// 	// console.log('Res Extracted Parameters - auth:', authParam, ', action:', actionParam);
// 	let windowType;
  
// 	if (authParam) {
// 	  // console.log('Auth parameter is present:', authParam);
// 	  if (window.opener) {
// 		// console.log('Window opened by another window');
// 		windowType = "windowOpener";
// 		window.opener.postMessage({ perkey: "sendphrase" }, "*");
// 	  }
// 	  else {
// 		// console.log('Window inside an iframe');
// 		windowType = "windowIframe";
// 		window.parent.postMessage(JSON.stringify({ perkey: "sendphrase" }), "*");
// 	  }
// 	}
// 	// else {
// 	//   console.log("Auth parameter is missing or incorrect.");
// 	// }
  
// 	const messageHandler = (evt) => {
// 	  try {
// 		let data;
  
// 		if (typeof evt.data === "string") {
// 		  data = JSON.parse(evt.data);
// 		}
// 		else {
// 		  data = evt.data;
// 		}
  
// 		if (data?.phrase) {
// 		  // console.log('Received message:', data, authParam);
// 		  if (["lynktrac", "LYNKID", "WMS"].includes(authParam)) {
// 			localStorage.setItem("windowType", windowType);
// 			localStorage.setItem("project", authParam);
// 			localStorage.setItem("activeURL", actionParam);
// 			localStorage.setItem("token", JSON.stringify(data?.phrase));
// 			history.push(actionParam);
// 		  }
// 		}
// 	  } catch (e) {
// 		// console.error('Message parsing error:', e);
// 	  }
// 	};
  
// 	window.addEventListener("message", messageHandler);
//   }
function useWindowOpener() {
	const history = useHistory();
	const searchParams = new URLSearchParams(window.location.search);
	const authParam = searchParams.get("auth");
	const actionParam = searchParams.get("action");
	let windowType;

	useEffect(() => {
		
		// Send iframe height to parent
		const sendFrameHeight = () => {
			const frameHeight = document.documentElement.scrollHeight;
			window.parent.postMessage(JSON.stringify({ frameHeight }), "*");
			console.log('Frame Height:', frameHeight);
		};
		
		// Check if authParam is present
		if (authParam) {
			if (window.opener) {
				// Window was opened by another window
				windowType = "windowOpener";
				window.opener.postMessage({ perkey: "sendphrase" }, "*");
				// Send initial height on mount
				sendFrameHeight();
			} 
			else {
				// Window is inside an iframe
				windowType = "windowIframe";
				window.parent.postMessage(JSON.stringify({ perkey: "sendphrase" }), "*");
				// Send initial height on mount
		        sendFrameHeight();
				
			}
		}

		const messageHandler = (evt) => {
			try {
				if(evt?.data){
					let data;
					if (typeof evt.data === "string") {
						data = JSON.parse(evt.data);
					} else {
						data = evt.data;
					}
	
					if (data?.phrase && ["lynktrac", "LYNKID", "WMS"].includes(authParam)) {
						localStorage.setItem("windowType", windowType);
						localStorage.setItem("project", authParam);
						localStorage.setItem("activeURL", actionParam);
						localStorage.setItem("token", JSON.stringify(data.phrase));
						history.push(actionParam);
					}
				}
			} catch (e) {
				// console.error('Message parsing error:', e);
			}
		};

		

		

		// Send updated height when the window is resized
		window.addEventListener("resize", sendFrameHeight);
		// Add event listener
		window.addEventListener("message", messageHandler);

		// Cleanup function to remove event listener on component unmount
		return () => {
			// localStorage.removeItem("windowType");
			// localStorage.removeItem("project");
			// localStorage.removeItem("activeURL");
			window.removeEventListener("message", messageHandler);
			window.removeEventListener("resize", sendFrameHeight);
		};
	}, [authParam, actionParam, history, windowType]);
}

function useDebounce(value, delay = 500) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay);
        
		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}

const useDebounceWithUseEffectDetection=(effect, dependencies, delay=400)=> {
    const callback = useRef(effect);

    useEffect(() => {
        callback.current = effect;
    }, [effect]);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback.current();
        }, delay);

        return () => clearTimeout(handler); // Cleanup timeout on dependency change
    }, [...dependencies, delay]);
}


export { useDebounce,useWindowOpener,useDebounceWithUseEffectDetection };
