import React, {useState, useEffect, useCallback,useMemo} from 'react'
import { 
    FormControl, Grid, OutlinedInput, Typography, Checkbox, ButtonGroup, Button, Card, CardHeader,
     CardContent, IconButton, Menu, MenuItem, Autocomplete, TextField, InputAdornment,Dialog, DialogContent
     } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import theme, { inputClasses, buttonClasses, textClasses, cardClasses } from '../../../../../../static/styles/theme'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import GenericTable from '../../../../../Components/Generic/GenericTable'
import { Add, ArrowDownward, Clear, Close, KeyboardArrowDown, Remove, RestaurantMenuRounded, Search, SellOutlined } from '@mui/icons-material'
import { getCamelCaseText, showToast,dateTimeFormater,truncateNumber } from '../../../../../../services/functions'
import FileModal from '../../../../EntityMaster/FileModal'
import { fetchFile, getAsnDetails,getReceivingConfig } from '../../../../../../redux/actions'
import { useDebounce } from '../../../../../../utils/hooks'
import { selectStylesOverride } from '../../../../../../utils/util'
import GenericLoader from '../../../../../Components/Generic/GenericLoader'
import AsnPackageTableDialog from './AsnPackageTableDialog'
import AsnPackageItemTableSection from './AsnPackageItemTableSection';
import GenericSingleRangeComponent from '../../../../../Components/Generic/GenericSingleRangeComponent'

const receivingTypeOptions = [
    { label: "Available QTY", value: "availableQuantity" },
    // { label: "Less QTY", value: "lessQuantity" },
    // { label: "Extra QTY", value: "exceedQuantity" }
]

export default function AsnSection({
    template, asn, asnTemplateHeader, spiType, objects, setObjects, receivingMethod,
    openAsnPackageTable,setOpenAsnPackageTable,
    receivingConfig={}
}) {
    

    const dispatch = useDispatch();
    const [selectedAsnItems, setSelectedAsnItems] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [showItemRow, setShowItemRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [loader, setLoader] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const asnDetailItemsCount = useSelector(state => state.asnEntry.asnDetailItemsCount);

   
    // const [selectedType, setSelectedType] = useState({ label: "Available QTY", value: "availableQuantity" });
    const [selectionConfig, setSelectionConfig] = useState({
        "pkgMultiple": {
            "isActive": false,
        },
        "itemMultiple": {
            "isActive": false,
        },
        "receivingTypeVisibility": {
            "isActive": false,
            "options":receivingTypeOptions
        }
    })

    const multiSelectFlag = (() => {
        if(spiType == "shipmentPackageOnly") return selectionConfig?.pkgMultiple?.isActive==true;
        else if(spiType == "shipmentItemOnly" || spiType == "SPI") return selectionConfig?.itemMultiple?.isActive==true;
        return false
    })()

    const [filters, setFilters] = useState({
        entityId: "",
        entityDetail: "",
        status: null,
        entityType: null,
        orderedAt: null,
    })
    const debouncedEntityId = useDebounce(filters.entityId, 500);
    const debouncedEntityDetail = useDebounce(filters.entityDetail, 500);


    const resetFilters = (key=null) => {
        if(!key || key == ""){
            setFilters({
                entityId:"",
                entityDetail:"",
                status: null,
                entityType: null,
                orderedAt: null,
            })
        }
        else{
            let old = {...filters}
            old[key] = "";
            setFilters({
                ...old,
            })
        }
        setIsDirty(true);
        setPage(0)
        setLimit(10);
        
    }

    const handleFilterChange = (e, key) => {
        let oldFilters = {...filters};
        if(["entityId","entityDetail"]?.includes(key)){
            oldFilters[key] = e.target.value;
        }
        else{
            oldFilters[key] = e
        }
        setFilters(oldFilters);
        setIsDirty(true);
        setPage(0);
        setLimit(10);

    }

    const handleRowsPerChange = (e) => {
        setIsDirty(true);
        setLimit(e.target.value);
        setPage(0);
    }
    
    const fetchASNItem = () => {
        setLoader(true);
        let payload = {
            ASN_ID: asn.ASN_Id,
            page: page + 1,
            limit: limit,
            filterTable: true
        }

        if (filters.entityId) {
            payload["entity_ID"] = filters.entityId
        }
        if (filters.entityDetail) {
            payload["entity_Detail"] = filters.entityDetail
        }
        if (filters.status) {
            payload["status"] = filters.status.value
        }
        if (filters.orderedAt) {
            payload["submissionDate"] = filters.orderedAt
        }
        payload["projection"] = {
            "closed_by": 0, "isCloseforceFully": 0, "received_by": 0, "template_id": 0, "purchaseOrderID": 0, "ASN_ItemId": 0,
            "base": 0, "created_by": 0, "savingMethod": 0, "itemMasterId": 0, "__v": 0
        }
        payload["shipmentProjection"] = {
            "closed_by": 0, "isCloseforceFully": 0, "received_by": 0, "template_id": 0, "purchaseOrderID": 0, "product_ID": 0, "ASN_ItemId": 0,
            "base": 0, "created_by": 0, "savingMethod": 0, "itemMasterId": 0, "__v": 0
        }
        dispatch(getAsnDetails(payload, (data) => {
            setLoader(false)
        }))
    }


    const isFilterApplied = (() => {
        if(filters.entityId != "" || filters.status != null || filters.orderedAt != null || filters.entityDetail != ""){
            return true;
        }
        return false;
    })();

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }
    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

         


    let isRejectedSelected = false
    let allReceivingTypeOption=selectionConfig?.receivingTypeVisibility?.options || receivingTypeOptions || []
    // console.log("selectionConfig",selectionConfig)
    const disabledType = (() => {
        let selectedComponent = new Set()
        objects.forEach((selectedItem) => {
            if (spiType === "SPI") {
                const selected = selectedItem?.itemDetail?.map((selectedItem) => {
                    if (!isRejectedSelected) {
                        isRejectedSelected = selectedItem?.receivingType === "rejectedQuantity"
                    }
                    return selectedItem?.ASN_ComponentId
                })
                selectedComponent.add(...selected)
            }
            else {
                if (!isRejectedSelected) {
                    isRejectedSelected = selectedItem?.receivingType === "rejectedQuantity"
                }
                selectedComponent.add(selectedItem?.ASN_ComponentId)
            }
        }, [])

        if(objects?.length){
            // console.log({isRejectedSelected},"isRejectedSelected")
            if(isRejectedSelected){
                allReceivingTypeOption=[ { label: "Rejected QTY", value: "rejectedQuantity" }]
            }
            else{
                allReceivingTypeOption=(selectionConfig?.receivingTypeVisibility?.options || receivingTypeOptions || [])?.filter((option)=>option.value!="rejectedQuantity")
            }
        }

        selectedComponent = [...selectedComponent]
        return selectedComponent
    })()


    // console.log({disabledType,isRejectedSelected},"disabledType")
    const handleCheckAll = (e) => {
        let check = e?.target?.checked || false
        if (check) {
            let tempSelected = []
            let isRejectItem = selectedAsnItems?.some(dl => dl?.receivingType == "rejectedQuantity")
            for (let it of (asn?.itemDetail || [])) {
                if (asn?.status == "received") continue
                let itemAdded = getSelectedItemCount(it?.formData?.product_ID);
                let count =truncateNumber(it.totalQuantity - it.receivedQuantity - it.rejectedQuantity - itemAdded)
                if (count > 0) {
                    let newI = {
                        ...it,
                        count: count,
                        maxCount: count,
                        product_ID: it?.formData?.product_ID,
                        UOM: it?.formData?.UOM,
                        receivingType: (isRejectedSelected || isRejectItem) && "rejectedQuantity" || "availableQuantity"
                    }
                    if (!isRejectedSelected && !isRejectItem) {
                        if (spiType === "SPI") {
                            let item = objects?.find(dl => dl?.itemDetail?.find(element => element?.ASN_ComponentId == it?._id))
                            if (item?.receivingType) {
                                newI["receivingType"] = item?.receivingType
                            }
                        }
                        else {
                            let item = objects?.find(dl => dl?.ASN_ComponentId == it?._id)
                            if (item?.receivingType) {
                                newI["receivingType"] = item?.receivingType
                            }
                        }
                    }
                    tempSelected.push(newI)
                }

            }
            setSelectedAsnItems([...tempSelected])
        }
        else {
            setSelectedAsnItems([]);
        }
    }

    const handleItemCountChange = (countValue, _id, limit) => {
        let oldEntries = [...selectedAsnItems];
        let index=oldEntries.findIndex(item => item._id == _id)
        // console.log(oldEntries, "oldEntries",countValue,index,limit)
        if (countValue <= 0) {
            oldEntries[index].count = 0
        }
        else {
            if (oldEntries?.at(index)?.receivingType === "exceedQuantity") {
                oldEntries[index].count = countValue
            }
            else {
                oldEntries[index].count = Math.min(limit, countValue)
            }
        }
        setSelectedAsnItems(oldEntries);
    }

    const getSelectedItemCount = (sku_number) => {
        if (spiType == "SPI") {
            let ob = objects.map(o => {
                let items = [...o.itemDetail];
                let itemCount = items.filter(it => it.SKU_Number == sku_number).map(it => it.count).reduce((a, b) => a+b, 0);
                return itemCount;
            })
            return ob.reduce((a, b) => a+b, 0);
        }
        else if(spiType == "shipmentPackageOnly"){
            let packageCount = objects.filter(it => it.packageName == sku_number).map(it => it.count).reduce((a, b) => a+b, 0);
            return packageCount;
        }
        else if(spiType == "shipmentItemOnly"){
            let itemCount = objects.filter(it => it.SKU_Number == sku_number).map(it => it.count).reduce((a, b) => a+b, 0);
            return itemCount;
        }
        return 0;
    }

    const isItemNotDisable = (item) => {
        if(item.status == "received") return false;
        const addedInObjects = getSelectedItemCount(item.formData.product_ID);
        if(addedInObjects >= item.totalQuantity) return false;
        return true;
    }

    const isItemRemaining = (() => {
        let rows = asn?.itemDetail?.filter((item, i) => {
        // let rows = asn?.itemDetail?.map((item, i) => {
            const quantity = item.totalQuantity - Number(item?.receivedQuantity || 0) - Number(item?.rejectedQuantity || 0) + Number(item?.exceedQuantity || 0);
            const addedInObjects = getSelectedItemCount(item?.formData?.product_ID);
            const remaining = quantity - addedInObjects;
            // console.log("Sum",remaining,addedInObjects);
            return remaining > 0
        }) || []
        return rows?.length || 0;
        // let sum = rows?.reduce((a, b) => a + b, 0);
        // console.log("Sum", sum,rows);
        // return sum <= 0;
    })();

    const handleChangeReceivingType = useCallback((option, id, limit) => {
        // setSelectedType(option)
        let oldEntries = [...selectedAsnItems]
        let isRejected = option?.value === "rejectedQuantity"
        oldEntries = oldEntries?.map(it => {
            let tempObj = { ...it }
            if (isRejected) {
                tempObj["receivingType"] = "rejectedQuantity"
            }
            else if (it?._id === id) {
                tempObj["receivingType"] = option?.value
            }
            else if (tempObj["receivingType"] === "rejectedQuantity") {
                tempObj["receivingType"] = "availableQuantity"
            }
            return tempObj
        })
        if (option?.value !== "exceedQuantity") {
            let index = oldEntries.map(o => o._id).indexOf(id);
            if (oldEntries[index].count > limit) {
                oldEntries[index].count = limit
            }
        }
        setSelectedAsnItems(oldEntries)
    }, [ selectedAsnItems])//setSelectedType,
      
    const asnItemRows = useMemo(() => {
        return (asn?.itemDetail || []).map((item, i) => {
            const quantity =truncateNumber(item.totalQuantity - Number(item?.receivedQuantity || 0) - Number(item?.rejectedQuantity || 0) + Number(item?.exceedQuantity || 0));
            const addedInObjects = getSelectedItemCount(item?.formData?.product_ID) || 0;
            let selectedIndex = selectedAsnItems.findIndex(dl => dl._id == item._id);
            let selectedObj = selectedIndex >= 0 ? selectedAsnItems[selectedIndex] : null;
            const SKU_Detail = item?.formData?.SKU_Detail || item?.itemMasterDetail?.SKU_Detail || "-";
            const selectedType = selectedObj?.receivingType || ""
            const remaining = truncateNumber(quantity - addedInObjects);
            const isDisableReceivingType = disabledType?.length ? disabledType?.filter((item) => item !== undefined) : []
            let row = [];
            row.push(
                (item.status != "received" && remaining > 0) &&
                <Checkbox
                    size="small"
                    checked={selectedIndex >= 0}
                    disabled={
                        (() => {
                            if (multiSelectFlag) return false;
                            return !(selectedAsnItems.length >= 1 && selectedAsnItems[0]._id == item._id || selectedAsnItems.length < 1)
                        })()
                    }
                    // disabled={!(selectedAsnItems.length == 1 && selectedAsnItems[0]._id == item._id || selectedAsnItems.length < 1)}
                    onChange={(e) => handleCheckChange(e, item)}
                />
            )
            row.push(<Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={() => setShowItemRow(item)}>{item.formData.product_ID}</Typography>)
            row.push(SKU_Detail)
            row.push("SKU")
            row.push(
                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                    {
                        `${item.totalQuantity} ${item.formData.UOM}`
                    }
                </Typography>
            )
            row.push(
                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                    {
                        `${quantity > 0 ? quantity : 0} ${item?.formData?.UOM || ""}`
                    }
                </Typography>
            )
            row.push(
                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                    {`${selectedObj?.count > 0 ? truncateNumber(Math.max(remaining - Number(selectedObj?.count || 0), 0)) : remaining} ${item?.formData?.UOM || ""}`}
                </Typography>
            )
            if (selectionConfig?.receivingTypeVisibility?.isActive == true) {
                row.push(
                    <Autocomplete
                        size="small"
                        sx={{ width: 150 }}
                        value={allReceivingTypeOption?.find(dl=>dl?.value==selectedType)?.label || ""}
                        disableClearable
                        disabled={selectedAsnItems.filter(s => s._id == item._id).length == 0 || isRejectedSelected || isDisableReceivingType?.includes(item?._id)}
                        options={allReceivingTypeOption}
                        onChange={(e, option) => {
                            handleChangeReceivingType(option, item._id, remaining)
                        }}
                        renderInput={(params) => (
                            <TextField
                                sx={{
                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                }}
                                {...params}
                                label={
                                    <Typography sx={{ fontSize: "14px" }}>
                                        Type
                                    </Typography>
                                }
                            />
                        )}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                style={{
                                    //   color: option.isCreateOption ? "#ff7200" : "#727376",
                                    fontSize: "14px",
                                }}
                            >
                                {option.label}
                            </li>
                        )}
                    />
                )
            }
            row.push(
                item.status != "received"
                    ?
                    <GenericSingleRangeComponent
                        startRange={selectedObj?.count || null}
                        disabled={!selectedType}
                        endRange={Number(remaining || 0)}
                        getOnChange={(countValue) => handleItemCountChange(countValue, item._id, remaining)}
                        endRangeInfinity={selectedType == "exceedQuantity"}
                        valueType={"decimal"}
                    />
                    :
                    "NA",
            )
            const statusColor = {
                partialreceived: "#3E621E",
                received: "#3E621E",
                rejected: "#E9001A",
                drafted: "#FF0101",
                incoming: "#FF7200",
                arrived: "#FF7200",
                default: "#000"
            }
            let statusText;
            if (item.status == "arrived") {
                statusText = "Arrived"
            }
            else if (item.status == "incoming") {
                statusText = "Incoming"
            }
            else if (item.status == "rejected") {
                statusText = `Rejected (${item.rejectedQuantity})`
            }
            else if (item.status == "partialReceived") {
                statusText = `Partial Received (${item.receivedQuantity})`
            }
            else if (item.status == "received") {
                statusText = `Received (${item.receivedQuantity})`
            }
            row.push(
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[item.status ? item.status.toLowerCase() : "default"] }}>
                    {statusText}
                </Typography>
            );
            row.push(dateTimeFormater(item.createdAt, "DD MMM YYYY hh:mm A"))
            row.push(<Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>)
            return row;
        })
    }, [asn?.itemDetail, selectedAsnItems, objects])
        

    // console.log({multiSelectFlag,isItemRemaining})

    let asnItemHeader = asn ? [
        (multiSelectFlag && isItemRemaining)
            ? <Checkbox
                data-testid="checkbox"
                checked={asn.itemDetail.length != 0 && selectedAsnItems?.length>0 && selectedAsnItems.length == isItemRemaining}
                onChange={handleCheckAll} size='small'
            /> : null,
        "Entity ID","Entity Detail", "Entity Type", "Order QTY", "Available QTY", "Remaining", "Receiving QTY", "Status", "Ordered At", "Action"] : []
    if (asnItemHeader?.length > 0 && selectionConfig?.receivingTypeVisibility?.isActive==true) {
        asnItemHeader.splice(asnItemHeader.length-4, 0,"Receiving Type")
    }

    const handleAddSelection = (obj) => {
        let tempReceivingType = isRejectedSelected && "rejectedQuantity" || "availableQuantity"

        if (!isRejectedSelected) {
            let item = selectedAsnItems?.some(dl => dl?.receivingType == "rejectedQuantity")
            if (item) {
                tempReceivingType = "rejectedQuantity"
            }
            if (tempReceivingType != "rejectedQuantity") {
                if (spiType === "SPI") {
                    let item = objects?.find(dl => dl?.itemDetail?.find(element => element?.ASN_ComponentId == obj?._id))
                    if (item?.receivingType) {
                        tempReceivingType = item?.receivingType
                    }
                }
                else {
                    let item = objects?.find(dl => dl?.ASN_ComponentId == obj?._id)
                    if (item?.receivingType) {
                        tempReceivingType = item?.receivingType
                    }
                }
            }
        }

        setSelectedAsnItems([...selectedAsnItems, {
            ...obj,
            maxCount: obj?.count || 0,
            product_ID: obj?.formData?.product_ID,
            UOM: obj?.formData?.UOM,
            receivingType: tempReceivingType,
        }]);
    }

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedAsnItems];
        const i = oldEntries.map(o => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedAsnItems(oldEntries);
            if(oldEntries.length == 0){
                handleCloseAsnPackageTable()
            }
        }

    }

    const handleCheckChange = (event, row) => {
        
        if (event.target.checked) {
            const itemAdded = getSelectedItemCount(row.formData.product_ID);
            handleAddSelection({...row, count: truncateNumber(row.totalQuantity - row.receivedQuantity - row.rejectedQuantity - itemAdded)});
        }
        else {
            handleRemoveSelection(row._id);
        }
    }    
    
    const handleOpenAsnPackageTable = (type) => {
        const isApproved = handleReceivingQuantity()
        if (isApproved &&  selectedAsnItems?.at(0)) {
            let isZeroCountExist=selectedAsnItems.findIndex(dl=>!dl.count || dl?.count<=0)
            if(isZeroCountExist>=0){
                showToast(`Selected Quantity cannot be less than 1 for ${selectedAsnItems[isZeroCountExist]?.product_ID}`, true)
                return;
            }
            setOpenAsnPackageTable(type)
        }
    }

    const handleCloseAsnPackageTable = () => {
        setOpenAsnPackageTable("")
    }

    const handleReceivingQuantity = useCallback(() => {
        if (!selectedAsnItems || selectedAsnItems.length == 0) {
            showToast("Please select atleast one item", true)
            return false;
        }
        for (let item of selectedAsnItems) {
            if (!item.count || item.count <= 0) {
                showToast(`Selected Quantity cannot be less than 1 for ${item.product_ID}`, true)
                return false;
            }
            if (item?.selectedType === "exceedQuantity" && item.count <= item.totalQuantity) {
                showToast(`Selected Quantity cannot be less than ${item?.totalQuantity} for ${item?.product_ID}`, true)
                return false;
            }
            else if (item?.selectedType === "lessQuantity" && item.count >= item.totalQuantity) {
                showToast(`Selected Quantity cannot be greater than or equal to available quantity for selected Receiving Type for ${item?.product_ID}`, true)
                return false;
            }
            else if ((item?.selectedType === "availableQuantity" || item?.selectedType === "rejectedQuantity") && item.count > item.totalQuantity) {
                showToast(`Selected Quantity cannot be greater than available quantity for selected Receiving Type for ${item?.product_ID}`, true)
                return false;
            }
        }
        return true
    }, [selectedAsnItems])


    const handleSubmitPackages = (packagesToAdd,cb) => {
        // console.log("Adding packages", packagesToAdd);
        setObjects([...objects, ...packagesToAdd])
        setSelectedAsnItems([]);
        cb();
    }

    useEffect(() => {

        fetchASNItem()
    }, [page, limit, filters.orderedAt,filters.status,debouncedEntityId,debouncedEntityDetail])
    
    useEffect(() => {
        setSelectionConfig(Object.keys(receivingConfig)?.length>0 ?receivingConfig : selectionConfig)
        setSelectedAsnItems([]);
    }, [])

    return (
        <Grid container sx={{p: 2}}>
            <Grid container>
                <Typography sx={{...textClasses.boldText, textDecoration: "underline"}}>Select Row(s) & Add Receiving Quantity To Receive The Shipment As Package, Pallet Or Loose</Typography>
            </Grid>
            <Grid container spacing={1} sx={{mt: 1}}>
                <Grid item sx={4} sm={2}>
                    <FormControl variant='outlined' size='small' fullWidth>
                        <OutlinedInput
                            
                            value={filters.entityId}
                            onChange={(e) => handleFilterChange(e, "entityId")}
                            placeholder='Search by Entity Id'
                            sx={{ ...inputClasses.filterField, "& input::placeholder": { fontSize: "14px" } ,"& .MuiSelect-iconOutlined": {display: filters.entityId != "" ? 'none': ''}}}
                            endAdornment={
                                filters.entityId == ""
                                ?
                                    <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                :
                                    <IconButton sx={{visibility: filters.entityId != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => resetFilters("entityId")}><Clear fontSize='inherit'/></IconButton>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item sx={4} sm={2}>
                    <FormControl variant='outlined' size='small' fullWidth>
                        <OutlinedInput
                            
                            value={filters.entityDetail}
                            onChange={(e) => handleFilterChange(e, "entityDetail")}
                            placeholder='Search by Entity Detail'
                            sx={{ ...inputClasses.filterField, "& input::placeholder": { fontSize: "14px" } ,"& .MuiSelect-iconOutlined": {display: filters.entityDetail != "" ? 'none': ''}}}
                            endAdornment={
                                filters.entityDetail == ""
                                ?
                                    <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                :
                                    <IconButton sx={{visibility: filters.entityDetail != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => resetFilters("entityDetail")}><Clear fontSize='inherit'/></IconButton>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item sx={4} sm={2}>
                    <Autocomplete
                        data-testid="status"
                        value={filters.status}
                        onChange={(e, option) => handleFilterChange(option, "status")}
                        size='small'
                        fullWidth
                        options={[{label: "Received", value: "received"}, {label: "Arrived", value: "arrived"}]}
                        sx={{...inputClasses.filterField, ".MuiInputBase-input": {
                            fontSize: "15px",
                            color: "#455A64"
                        }}}
                        renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Status" />}
                    />
                </Grid>
                
                <Grid item sx={4} sm={2}>
                    <DatePicker
                        data-testid="receivedAt"
                        value={filters.orderedAt}
                        format="DD/MM/YYYY"
                        onChange={(d) => handleFilterChange(d, "orderedAt")}
                        label="Ordered At"
                        sx={{...inputClasses.filterField}}
                        slotProps={{textField: {size: "small"}}}
                    />
                </Grid>
                {
                    isFilterApplied && 
                    <Grid container item sx={4} sm={2} alignItems={"center"}>
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}} onClick={() => resetFilters()}>
                            Clear Filter(s)
                        </Typography>
                    </Grid>
                }
                
            </Grid>
            {["package","pallet","bothPackageItem","bothPalletItem"].includes(openAsnPackageTable) && <AsnPackageTableDialog
                open={openAsnPackageTable}
                onClose={handleCloseAsnPackageTable}
                template={template}
                items={selectedAsnItems.filter(it => isItemNotDisable(it))}
                handleSubmitPackages={handleSubmitPackages}
                multiSKUSelected={selectedAsnItems.filter(it => isItemNotDisable(it))?.length>1}
                spiType={spiType}
                openType="add"
                receivingMethod={receivingMethod}
                isRejectReasonRequired={selectionConfig?.receivingTypeVisibility?.isRejectReasonRequired==true}
                />}
            {["uniqueItem", "bundleItem"].includes(openAsnPackageTable) &&
                <Dialog
                open={openAsnPackageTable!=""}
                scroll={"paper"}
                PaperProps={{ sx: { width: "100%" } }}
                maxWidth={"xl"}
                >
                    <DialogContent>
                        <AsnPackageItemTableSection
                            open={openAsnPackageTable}
                            selectedAsnItems={selectedAsnItems.filter(it => isItemNotDisable(it))}
                            onClose={handleCloseAsnPackageTable}
                            template={template}
                            totalItemCount={selectedAsnItems.filter(it => isItemNotDisable(it))?.reduce((a, b) =>Number(a ||  0) + Number(b.count || 0), 0) || 0} 
                            openType="add"
                            itemDetail={selectedAsnItems.filter(it => isItemNotDisable(it))}
                            saveItemHandler={(newItem=[])=>{
                                handleSubmitPackages(newItem, () => {
                                    handleCloseAsnPackageTable()
                                })
                            }}
                            multiSKUSelected={selectedAsnItems.filter(it => isItemNotDisable(it))?.length>1}
                            isRejectReasonRequired={selectionConfig?.receivingTypeVisibility?.isRejectReasonRequired==true}
                        />
                    </DialogContent>
                </Dialog>
            }
            {
                    selectedAsnItems.length > 0 &&
                    <Grid container sx={{ mt: 2 , textTransform:"none"}}>
                        {
                            ["pallets", "packages"].indexOf(receivingMethod?.value) >= 0 &&
                            <>
                                {
                                    <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                        onClick={() => handleOpenAsnPackageTable(receivingMethod?.value?.split("s")?.at(0))}>Create {getCamelCaseText(receivingMethod.value)}</Button>
                                }
                            </>
                        }
                        {
                            receivingMethod.value == "bothPackageItem" &&
                            <>
                                <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("package")}>Create Package</Button>
                            </>
                        }
                        {
                            receivingMethod.value == "bothPalletItem" &&
                            <>
                                <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("package")}>Create Pallet</Button>
                            </>
                        }
                        {
                            receivingMethod.value == "individualItems" &&
                            <>
                                <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("bundleItem")}>Create Items</Button>
                                {selectedAsnItems.length==1 && <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("uniqueItem")}>Create Unique Items</Button>}
                            </>
                        }
                    </Grid>
                }
            {
                loader
                ?
                    <Grid container justifyContent={"center"} sx={{m: 2}}>
                        <GenericLoader />
                    </Grid>
                :
                <Grid container sx={{mt: 2}}>
                    <Grid item xs={showItemRow ? 8 : 12}>
                        <GenericTable
                            header={asnItemHeader}
                            rows={asnItemRows}
                            totalRows={asnDetailItemsCount}
                            page={page}
                            limit={limit}
                            setPage={(p) => {setIsDirty(true); setPage(p)}}
                            setLimit={(l) => {setIsDirty(true); setLimit(l)}}
                            handleRowsPerChange={handleRowsPerChange}
                        />
                    </Grid>
                    {
                        showItemRow &&
                        <Grid item xs={4} sx={{p: 1, pt: 0}}>
                            <DetailCard
                                data={showItemRow}
                                templateHeader={asnTemplateHeader}
                                setShowItemRow={setShowItemRow}
                            />
                        </Grid>
                    }
                </Grid>
            }
            {
                actionIndex != null &&
                <Menu
                    anchorEl={anchorEl}
                    open={actionIndex != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                >
                    <MenuItem sx={{...textClasses.normalText}}
                        onClick={() => {setShowItemRow(asn?.itemDetail[actionIndex]); handleCloseAction();}}
                    >
                        View Details
                    </MenuItem>

                </Menu>
                }
                
        </Grid>
    )
}


const DetailCard = ({data, templateHeader, setShowItemRow }) => {
    ////("ASN data", data);
    const dispatch = useDispatch();
    
    const DetailRow = ({title, children}) => {
        return (
            <Grid container sx={{mt: 1}}>
                <Grid item sm={4} xs={4} sx={{wordWrap: "break-word", wordBreak:"break-all"}}>
                    <Typography sx={{...textClasses.t12n, color: "#827878"}}>{title}: </Typography>
                </Grid>
                <Grid container item sm={8} xs={8} direction="row" alignItems={"center"} sx={{wordWrap: "break-word", wordBreak:"break-all"}}>
                    {children}
                </Grid>
            </Grid>
        )
    }
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
  
    const displayFile = (fileUrl, fileKey) => {
      const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
      const isValidImageUrl = imageUrlRegex.test(fileKey);
      if (isValidImageUrl) {
        setOpenModal(true);
        setDisplayUrl(fileUrl);
      } else {
        window.open(fileUrl, "_blank");
      }
    };
  
    const handleFile = (fileKey) => {
      setOpenModal(false);
      setDisplayUrl('');
      dispatch(fetchFile({ fileKey }, displayFile));
    };

    return (
        <>
            {
                data && 
            
                <Card sx={{...cardClasses.basic, borderRadius: "8px", border: `1px solid ${theme.themeOrange}`, position: "relative", height: "400px"}}>
                    <CardHeader
                        sx={{height: "46px", backgroundColor: theme.themeOrange}}
                        title={
                            <Typography sx={{...textClasses.boldText, color: "white", fontSize: "16px"}}>
                                <SellOutlined fontSize='inherit' sx={{mr: 1}}/>
                                SKU ID: {data?.formData?.product_ID}
                            </Typography>
                        }
                        action={
                            <IconButton size='small' sx={{p: 0, mb: 1}} onClick={() => setShowItemRow(null)}>
                                <Close sx={{color: "white"}}/>
                            </IconButton>
                        }
                    />
                    <CardContent sx={{minHeight: "400px", maxHeight: "400px", overflowY: "scroll"}}>
                        <Grid container>
                            <Grid container item sm={6} xs={6} alignItems="center">
                                <Typography sx={{...textClasses.t12n, color: "#827878"}}>Ordered Qty</Typography>
                                <Button disabled size='small' sx={{ml: 2, borderRadius: "12px", border: `1px solid ${theme.themeOrange}`, backgroundColor: theme.themeLightOrange, color: `${theme.themeOrange} !important`, textTransform: "none", ...textClasses.t12n, minWidth: "70px"}}>{data.totalQuantity} pcs</Button>
                            </Grid>
                            <Grid container item sm={6} xs={6} alignItems="center">
                                <Typography sx={{...textClasses.t12n, color: "#827878"}}>Received Qty</Typography>
                                <Button disabled size='small' sx={{ml: 2, borderRadius: "12px", border: `1px solid ${theme.themeOrange}`, backgroundColor: theme.themeLightOrange, color: `${theme.themeOrange} !important`, textTransform: "none", ...textClasses.t12n, minWidth: "70px"}}>{data.receivedQuantity} pcs</Button>
                            </Grid>
                        </Grid>
                        
                        {
                            templateHeader.itemHeader && templateHeader.itemHeader.map((head, i) => {
                                return (
                                    <DetailRow title={head.label}>
                                        {/* <Typography sx={{...textClasses.t12n}}>{ data.formData[head.name] }</Typography> */}
                                        <Typography sx={{...textClasses.normalText, ml: 1}}>
                                                {data.formData[head.name] ?
                                                    head.type === "file" ? (
                                                        <div onClick={() => handleFile(data.formData[head.name])}>
                                                          <span
                                                            style={{
                                                              ...buttonClasses.viewFile,
                                                              cursor:"pointer"
                                                            }}
                                                          >
                                                            View File
                                                          </span>
                                                        </div>
                                                      ) : 
                                                    head.type === "date"
                                                        ? dateTimeFormater(data.formData[head.name], head.format || "DD MMM YYYY")
                                                        : data.formData[head.name] : "-"}
                                            </Typography>
                                            <FileModal
                                              openModal={openModal}
                                              displayUrl={displayUrl}
                                              setOpenModal={setOpenModal}
                                              />                  
                                    </DetailRow> 
                                )
                            })
                        }
                        {
                            data.itemMasterDetail && templateHeader && templateHeader.itemMaster && templateHeader.itemMaster.map((head, i) => {
                                return (
                                    <DetailRow title={head.label}>
                                        <Typography sx={{...textClasses.t12n}}>{ data.itemMasterDetail[head.name] }</Typography>
                                    </DetailRow>
                                )
                            })
                        }
                        <DetailRow title="ASN Submission Date">
                            <Typography sx={{...textClasses.t12n}}>{ dateTimeFormater(data.createdAt,"DD MMM YYYY hh:mm A")}</Typography>
                        </DetailRow>
                        <DetailRow title="ASN Received At">
                            <Typography sx={{...textClasses.t12n}}>{dateTimeFormater(data.receivedDate,"DD MMM YYYY hh:mm A")}</Typography>
                        </DetailRow>
                    </CardContent>
                    
                </Card>
               
            }
        </>
    )
}