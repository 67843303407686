import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    Grid, Card, Typography, Input, Checkbox, FormControlLabel, FormHelperText, Button,
    TextField, FormControl, Snackbar,Box
} from "@mui/material";
import MUILink from "@mui/material/Link";
import { Image } from 'mui-image'
import "../../static/css/login.css"
import { ReactComponent as LogoWhite } from "../../static/images/login/logo-white.svg"
import LogoTransparent from "../../static/images/login/Lynkgrid_warehouse.png"
import Logo from "../../static/images/login/logo-white.svg"
// import BackgroundImage from "../../static/images/login/wms-2\ 1.svg"
import BackgroundImage from "../../static/images/login/background.jpg"
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import theme, { inputClasses, cardClasses, textClasses, buttonClasses } from '../../static/styles/theme';
import { showToast } from "../../services/functions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import GenericLoader from '../Components/Generic/GenericLoader';
import { valid_email , valid_otp_six_digit} from "../../services/validation"
import { forgotPasswordCase,verifyOTPCase,changePasswordCase } from '../../redux/actions';
import { encryptTheData } from '../../services/Api';
import { localStorageLogoutConditions } from '../../utils/services';


const Login = (props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [loader, setLoader] = React.useState("global");
    const [step,setStep] = useState(0)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = (data) => {
        setLoader("login")
        let encryptedData={payloadData:encryptTheData(data)}
        props._onLogin(encryptedData,()=>{
            setLoader("")
        })
    }

    const handleResetLink = (data) => {
        setLoader("login")
        let encryptedData={payloadData:encryptTheData(data)}
        dispatch(
            forgotPasswordCase(encryptedData, (response = {}) => {
                showToast(response.message, !response.success);
                setLoader("")
                if (response.success) {
                    reset();
                    setStep(2)
                }
            })
        )
    }
    const handleOTPVerification = (data) => {
        if(data?.confirmPassword != data?.newPassword){
            showToast("Password did not matched correctly",true)
            return;
        }
        setLoader("login")
        let encryptedData={payloadData:encryptTheData(data)}
        dispatch(
            verifyOTPCase(encryptedData, (response = {}) => {
                showToast(response.message, !response.success);
                setLoader("")
                if (response.success) {
                    reset();
                    setStep(0)
                }
            })
        )
    }

    const onPressingEnterFunction = (e) => {
        let formData = getValues();
        const isEnterPressed = e.key === 'Enter';
      
        if (!isEnterPressed) return;
      
        const requiredFields = {
          0: ['email', 'password'],
          1: ['email'],
          2: ['email', 'otp', 'newPassword', 'confirmPassword'],
        };
      
        const isFormComplete = requiredFields[step]?.every((field) => formData?.[field]);
      
        if (!isFormComplete) {
          // showToast("Please fill all the details", true)
          return;
        }
      
        const handlers = {
          0: handleLogin,
          1: handleResetLink,
          2: handleOTPVerification,
        };
      
        if (handlers[step]) {
          handleSubmit(handlers[step])();
        }
      };

    useEffect(() => {
        let expiryMessage = window.localStorage.getItem("TokenExpirymesssage")
        if (expiryMessage) {
            showToast(expiryMessage, true)
            localStorageLogoutConditions()
            // window.localStorage.clear()
        }
        setTimeout(() => {
            setLoader("")
        }, 1000)
    }, [])
    return (
        <>
            {loader == "global" ?
                <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 5,height:"80vh" }}>
                    <Grid item><GenericLoader /></Grid>
                </Grid>
                :
                <Grid container>
                    <Grid item md={7} sm={12} xs={12} className="bg-image" sx={{
                        height: { md: "100vh", xs: "40vh", sm: "60vh" },
                        display: 'flex',        
                        alignItems: 'center',   
                        justifyContent: 'center'
                    }}>
                        <Box sx={{ width: '50%', marginTop: { md: 0, sm: "-20%", xs: "-20%" } }}> {/* This Box will wrap the image and control its width */}
                            <Image
                                data-testid="logo-company"
                                src={LogoTransparent}
                                alt="signin-logo"
                                layout="responsive" 
                            />
                        </Box>
                    </Grid>
                    <Grid container item md={5} sm={12} xs={12} justifyContent="center" alignItems="center" direction={"column"}
                        sx={{
                            height: { sm: "90vh", md: "100vh", xs: "95vh", },
                            zIndex: { sm: 1000, md: 0, xs: 1000 },
                            position: { sm: "absolute", md: "relative", xs: "absolute", },
                            marginTop: { sm: "10vh", md: "0", xs: "5vh", }
                        }}
                    >

                        <Card sx={{ ml: 8, mr: 8, ...cardClasses.basic }} className="mui-custom-card">
                            <Grid container sx={{ p: 2 }} alignItems={"center"} justifyContent={"center"}>
                                {(step==1 || step ==2 )&&
                                <Grid item sm={1} xs={1}>
                                 <ArrowBackIcon
                                 data-testid="arrow-back"
                                 onClick={()=>{
                                    setStep(step-1)
                                    reset()
                                    }} sx={{cursor:'pointer'}}/>
                                </Grid>
                                }
                                <Grid item sm={11} xs={11}>
                                    {step == 2 ?
                                        <Typography align='center' sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "27px", mr: 3 }}>Verify With Code</Typography> : step == 1 ?
                                            <Typography align='center' sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "27px", mr: 3 }}>Reset Password</Typography> :
                                            <Typography align='center' sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "27px", }}>Get Started With</Typography>
                                    }
                                </Grid>
                                <Grid item sx={{ mt: 1 }}>
                                    <img src={Logo} height="48px" />
                                </Grid>
                                <Grid item sm={12} xs={12} sx={{ mt: 1 }}>
                                    <Typography align='center' sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "27px" }}>Warehouse Management System</Typography>
                                </Grid>
                            </Grid>
                            {loader == "login" ?
                                <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                                    <Grid item><GenericLoader /></Grid>
                                </Grid>
                                :
                                <>
                                    {
                                        step == 2
                                            ?
                                            <Grid sx={{ pl: 8, pr: 8, mt: 2 }} container direction={"row"} alignItems={"center"} justifyContent={"center"} >
                                                <TextField
                                                    variant='outlined'
                                                    sx={inputClasses.textField}
                                                    size="small"
                                                    data-testid="otp-input"
                                                    type="number"
                                                    error={errors.otp ? true : false}
                                                    helperText={errors.otp ? "Please enter valid OTP(must be 6 digits)" : ""}
                                                    label="OTP" fullWidth placeholder='Enter Your OTP'
                                                    onKeyDown={(e) => { onPressingEnterFunction(e)}}
                                                    {...register("otp", { required: true, validate: valid_otp_six_digit })}
                                                />
                                            </Grid>
                                            : ""
                                    }
                                    <Grid sx={{ pl: 8, pr: 8, mt: 2 }} container direction={"row"} alignItems={"center"} justifyContent={"center"} >
                                       
                                       <TextField
                                            variant='outlined'
                                            sx={inputClasses.textField}
                                            size="small"
                                            data-testid="email-input"
                                            type="email"
                                            error={errors.email ? true : false}
                                            helperText={errors.email ? "Please enter valid email" : ""}
                                            label="Email" fullWidth placeholder='Enter Your Email'
                                            {...register("email", { required: true, validate: valid_email })}
                                            onKeyDown={(e) => {onPressingEnterFunction(e) }}
                                        /> 
                                        
                                    </Grid>
                                    {(step==0 || step ==2 ) &&
                                    <Grid sx={{ pl: 8, pr: 8, mt: 2 }} container direction={"row"} alignItems={"center"} justifyContent={"center"}>

                                        <FormControl fullWidth variant="outlined" size="small" sx={inputClasses.textField}>
                                            <InputLabel htmlFor="outlined-adornment-password">{step==2?"New Password":"Password"}</InputLabel>
                                            <OutlinedInput
                                                error={errors.password ? true : false}
                                                {...register(step==2?"newPassword":"password", { required: true })}
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                data-testid="password-input"
                                                label={step==2?"New Password":"Password"} fullWidth placeholder={step==2?"Enter New Password":"Enter Password"}
                                                onKeyDown={(e) => { onPressingEnterFunction(e)}}
                                            />
                                            {
                                                errors.password && <FormHelperText id="outlined-weight-helper-text" style={{ color: "#d32f2f" }}>Please enter {step==2?"new":""} password</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid> 
                                    }
                                    {
                                        step==2 && 
                                        <Grid sx={{ pl: 8, pr: 8, mt: 2 }} container direction={"row"} alignItems={"center"} justifyContent={"center"}>

                                        <FormControl fullWidth variant="outlined" size="small" sx={inputClasses.textField}>
                                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                error={errors.password ? true : false}
                                                {...register("confirmPassword", { required: true })}
                                                id="outlined-adornment-password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownConfirmPassword}
                                                            edge="end"
                                                            data-testid="show-password"
                                                        >
                                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                data-testid="confirm-password-input"
                                                label="Confirm Password" fullWidth placeholder="Enter Confirm Password"
                                                onKeyDown={(e) => {onPressingEnterFunction(e) }}
                                            />
                                            {
                                                errors.password && <FormHelperText id="outlined-weight-helper-text" style={{ color: "#d32f2f" }}>Please enter confirm password</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid> 
                                    }
                                    {
                                        step == 0 &&
                                        <Grid container item sx={{ pr: 8, mt: 1 }} justifyContent="right" alignItems="right">
                                            <Typography sx={{ ...textClasses.t15n, color: theme.themeOrange, cursor: 'pointer' }}
                                                data-testid="forgot-password"
                                                onClick={() => {
                                                    setStep(1)
                                                    reset()
                                                }}
                                            >
                                                Forgot Password ?
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid container item sx={{ pl: 8, pr: 8, mt: 2, mb: 4 }}>

                                        {step == 0 ?
                                            <Button fullWidth size='small' data-testid="signin-btn" role="signInUserButton" sx={buttonClasses.orange} onClick={handleSubmit(handleLogin)}>
                                                Sign In
                                            </Button>
                                            : step == 1?
                                            <Button fullWidth size='small' data-testid="resetlink-btn" role="signInUserButton" sx={buttonClasses.orange} onClick={handleSubmit(handleResetLink)}>
                                                Get Reset Link
                                            </Button> :
                                            <Button fullWidth size='small' data-testid="verifyotp-btn" role="signInUserButton" sx={buttonClasses.orange} onClick={handleSubmit(handleOTPVerification)}>
                                            Verify OTP
                                        </Button>
                                        }
                                    </Grid>
                                </>
                            }
                        </Card>

                    </Grid>
                </Grid>}

            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />

        </>
    )


};

export default Login;