import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumbs,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Button,
  IconButton,
  Divider,
  Collapse,
  DialogContent,
  Dialog,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  Autocomplete,
  TablePagination,
  Box,
} 
from "@mui/material";
import { Link, useParams, useLocation} from "react-router-dom";

import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../../static/styles/theme";
import {
  fetchFile,
  getAllUsers,
  getSalesDetails,
  getTemplateHeader,
  getAllWarehouses,
  approveRejectSales,
  getAllJobTypes,
  getESalesDetails,
  approveRejectESales
} from "../../../../redux/actions";

import GenericLoader from "../../../Components/Generic/GenericLoader";
import {
  Cancel,
  ExpandMore,
  ExpandMoreOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import FileModal from "../../EntityMaster/FileModal";
import history from "../../../../services/history";
import { capitalizeSentence, getCamelCaseText, showToast, dataReadFunction, renderOptionHandler, dateTimeFormater, truncateNumber } from '../../../../services/functions'
import {userDetailsVisibilityFields } from "../../../../services/constants";
import { customerMasterVisibilityFields} from "../../TMS/Addressbook/utils";
import GenericSummaryView from "../../../Components/Generic/GenericSummaryView";
import CustomizedSteppers from "../../../Components/Generic/GenericStepper";
import { set } from "date-fns";


const statusColor = {
  dispatched: "#3E621E",
  inprogress: "#FF7200",
  approved: "#FF7200",
  rejected: "#E9001A",
  drafted: "#FF0101",
  pending: "#3E621E",
  default: "#000",
};
const statusText = {
  dispatched: "Dispatched",
  inprogress: "Inprogress",
  approved: "Approved",
  rejected: "Rejected",
  drafted: "Drafted",
  pending: "Pending",
};

const ConfirmDialog = ({
  status,
  handleClose,
  selectedEntries,
  setSelectedEntries,
  refreshTableData,
  handleRemoveSelection,
  salesType
}) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const size = selectedEntries.length;
  const [reason, setReason] = useState("");

  const callBack = (data) => {
    showToast(data.message, false);
    refreshTableData();
    handleClose();
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
  };

  const handleStatusChange = () => {
    //console.log(status,"stattuus");
    let payload = {}
    if (salesType == "e-sales") {
      if (status == "reject") {
        payload = {
          responseId:
            selectedEntries.length == 1
              ? selectedEntries[0].responseId
              : selectedEntries.map((e) => e.responseId),
          reason: reason,
          "approved": false
        };
      }
      else {
        payload = {
          responseId:
            selectedEntries.length == 1
              ? selectedEntries[0].responseId
              : selectedEntries.map((e) => e.responseId),
          "approved": true
        };
      }

      dispatch(
        approveRejectESales(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
    else {

      if (status == "reject") {
        payload = {

          Sales_Id:
            selectedEntries.length == 1
              ? selectedEntries[0].Sales_Id
              : selectedEntries.map((e) => e.Sales_Id),
          reason: reason,
          "approved": false
        };
      }
      else {
        payload = {

          Sales_Id:
            selectedEntries.length == 1
              ? selectedEntries[0].Sales_Id
              : selectedEntries.map((e) => e.Sales_Id),
          "approved": true
        };
      }

      dispatch(
        approveRejectSales(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
  };

  useEffect(() => {
    setReason("");
  }, [status]);

  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Are you Sure ?
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            You want to {status} the {salesType == "e-sales"?"e-Sale Response":"Sales"}
          </Typography>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {selectedEntries.slice(0, showAll ? size : 5).map((st, i) => {
            return (
              <Grid item sm={4} xs={6} key={i}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1,
                    minHeight: "40px",
                  }}
                >
                  <Grid item sm={10} xs={8}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {salesType=="e-sales" ? (st.responseId && st.responseId.length > 15
                        ? st.responseId.substring(0, 15) + "..."
                        : st.responseId) :
                        (st.Sales_Id && st.Sales_Id.length > 15
                          ? st.Sales_Id.substring(0, 15) + "..."
                          : st.Sales_Id)}
                    </Typography>
                  </Grid>
                  {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={() => handleRemoveSelection(st._id)}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
          {size > 5 && (
            <>
              <Grid
                container
                item
                sm={4}
                xs={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? <>Collapse</> : <>View All ({size})</>}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        {
          status == "reject" ?
            <Grid container direction={"row"} sx={{ mt: 1 }}>
              <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
            :
            ""

        }

        <Grid container sx={{ p: 1, mt: 3 }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.lynkitBlackFill,
              height: "40px",
              minWidth: "150px",
            }}
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses.small,
              backgroundColor: theme.themeOrange,
              minWidth: "150px",
            }}
            disabled={selectedEntries.length <= 0}
            onClick={handleStatusChange}
          >
            Yes, {status}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const DispatchDialog = ({ status, entry, handleClose }) => {

  const dispatch = useDispatch();
  const [method, setMethod] = useState(null);
  const [allJobType, setAllJobType] = useState([]);
  const [jobTypeId, setJobTypeId] = useState(null);
  const [pickingMethod,setPickingMethod] = useState(null)
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const allWarehouses = useSelector((state) => state.inward.allWarehouses);

  let allMethods = [
    {
      label: "First In First Out",
      value: "FIFO",
    },
    {
      label: "Last In First Out",
      value: "LIFO",
    },
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Date Range",
      value: "dateRange",
    },
    {
      label: "First Expiry First Out",
      value: "FEFO",
  },
  ];

  let pickingMethods = [
    {
      label: "Create SKU Wise Pick List & Job",
      value: "skuWise",
    },
    {
      label: "Create Batch Wise Pick List & Job ",
      value: "batchWise",
    },
    {
      label: "Create Location Wise Pick List & Job",
      value: "locationWise",
    },
    {
      label: "Create Unique Customer Wise Pick List & Job",
      value: "customerWise",
    },
  ];


  useEffect(() => {
    if (status == "dispatch" && entry && entry.length > 0) {
      dispatch(getAllWarehouses({ type: "warehouse" }, (data) => {
        if (data && data.success) {
          if (data?.data?.length> 1) {
            setSelectedWarehouse(data.data[0]);
          }
          setMethod("FIFO")
        }
      }));
      dispatch(getAllJobTypes({fetch_for: "getForFilter",actionType:["picking","sendToUser"] },({data=[]})=>{
        let tempJobType = (data || [])?.map(dl=>({label:dl.name,value:dl._id})) || [];
        setAllJobType(tempJobType)
        setJobTypeId(tempJobType?.at(0) || null)
      }));
    }
    else {
      setSelectedWarehouse(null);
      setMethod(null);
      setPickingMethod(null);
      setAllJobType([]);
      setJobTypeId(null);
    }
  }, [status]);

  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        {status == "dispatch" && (
          <>
            <Grid container justifyContent={"center"} alignItems="center">
              <Typography sx={{ ...textClasses.cardTitle }}>
                Select Dispatch Sorting Method
              </Typography>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid container item sm={4}>
                <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                  Sorting Method :
                </FormLabel>
              </Grid>
              <Grid container item sm={8}>
                {allMethods.map((m, i) => {
                  return (
                    <Grid item sm={6} key={i}>
                      <FormControlLabel
                        onClick={() => setMethod(m.value)}
                        checked={method == m.value}
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {m.label}
                          </Typography>
                        }
                        control={
                          <Radio
                            value={m.value}
                            sx={{
                              "&, &.Mui-checked": { color: theme.themeOrange },
                            }}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid container item sm={4}>
                <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                  Select Warehouse :
                </FormLabel>
              </Grid>
              <Grid container item sm={8}>
                <Autocomplete
                  value={selectedWarehouse}
                  onChange={(e, option) => {
                    setSelectedWarehouse(option);
                  }}
                  getOptionLabel={(option) =>
                    capitalizeSentence(option.name || "")
                  }
                  size="small"
                  options={allWarehouses}
                  sx={{
                    ...inputClasses.filterField,
                    minWidth: "220px",
                    m: 0,
                    ".MuiInputBase-input": {
                      fontSize: "14px",
                      color: "#455A64",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#455A64", ...textClasses.normalText }}
                      {...params}
                      label="Warehouse"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid container item sm={4}>
                <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                  Select Job :
                </FormLabel>
              </Grid>
              <Grid container item sm={8}>
                <Autocomplete
                  value={jobTypeId}
                  onChange={(e, option) => {
                    setJobTypeId(option);
                  }}
                  size="small"
                  options={allJobType}
                  sx={{
                    ...inputClasses.filterField,
                    minWidth: "220px",
                    m: 0,
                    ".MuiInputBase-input": {
                      fontSize: "14px",
                      color: "#455A64",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#455A64", ...textClasses.normalText }}
                      {...params}
                      label="Job Type"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid container item sm={4}>
                <FormLabel  sx={{ mt: 1, color: "#000000" }}>
                  Picking Method :
                </FormLabel>
              </Grid>
              <Grid container item sm={8}>
              {pickingMethods?.map((m, i) => {
                  return (
                    <Grid item sm={12} key={i}>
                      <FormControlLabel
                        onClick={() => setPickingMethod(m.value)}
                        checked={pickingMethod == m.value}
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {m.label}
                          </Typography>
                        }
                        control={
                          <Radio
                            value={m.value}
                            sx={{
                              "&, &.Mui-checked": { color: theme.themeOrange },
                            }}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1, mt: 3 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  height: "40px",
                  minWidth: "150px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ml: 1,
                  ...buttonClasses.small,
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                }}
                disabled={selectedWarehouse == null || method == null || jobTypeId == null}
                onClick={() =>
                  history.push("/dashboard/jobs/create-job", {
                    salesDetail: entry,
                    salesWarehouse: selectedWarehouse,
                    sortingMethod: allMethods.find(m => m.value == method),
                    selectJobTypeId: jobTypeId?.value || null,
                    pickingMethod: pickingMethods?.find(m => m.value==pickingMethod)
                  })
                }
              >
                {status == "dispatch" ? "Allocate" : { status }}
              </Button>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ViewSales = () => {
  let { salesId } = useParams();
  const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const salesType = queryParams.get('type');
  const dispatch = useDispatch();
  const templateHeader = useSelector((state) => state.inventory.templateHeader);
  const { userPermissions } = useSelector((state) => state.userPermission);
  const SalesDetailItemsCount = useSelector(
    (state) => salesType=="e-sales"?state.ExternalForm.ESalesDetailItemsCount : state.SalesProcess.SalesDetailItemsCount
  );
  const { AllUserData } = useSelector((state) => state.users);
  const salesOrderDetail = useSelector(
    (state) => salesType=="e-sales"? state.ExternalForm.ESalesDetail: state.SalesProcess.SalesDetail
  );

  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [displayUrl, setDisplayUrl] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const historySize = salesOrderDetail?.shipmentDetail?.allocated_by?.length || 0;
  const [confirmModal, setConfirmModal] = useState(null);
  const [dispatchModal, setDispatchModal] = useState(null);
  // //console.log("Master Type", masterType);

  //console.log("salesss order", salesOrderDetail)
  const handleCloseModal = () => {
    setConfirmModal(null);
    setDispatchModal(null);
  }

  const handleApproveOrders = () => {
    let payload = {
      Sales_Id: salesOrderDetail.Sales_Id,
      "approved": true
    };
    dispatch(
      approveRejectSales(payload, (data) => {
        if (data) {
          refreshContent(page + 1, limit)
        }
      })
    );
  }

  const isSelectionAllocatable = (() => {
    //console.log("TEST", salesOrderDetail);
    if (!salesOrderDetail || !salesOrderDetail.shipmentDetail) {
      return false;
    }
    if (!["approved", "inprogress"].includes(salesOrderDetail?.shipmentDetail?.approvalStatus)) {
      return false;
    }
    let ap_c = Number(salesOrderDetail?.shipmentDetail?.approvalQuantity || 0);
    let al_c = Number(salesOrderDetail?.shipmentDetail?.allocatedQuantity || 0);
    let rj_c = Number(salesOrderDetail?.shipmentDetail?.rejectedQuantity || 0);
    if (ap_c - al_c - rj_c <= 0) {
      return false
    }
    return true;
  })();

  const displayFile = (fileUrl, fileKey) => {
    const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
    const isValidImageUrl = imageUrlRegex.test(fileKey);
    if (isValidImageUrl) {
      setOpenModal(true);
      setDisplayUrl(fileUrl);
    } else {
      window.open(fileUrl, "_blank");
    }
  };

  const handleFile = (fileKey) => {
    setOpenModal(false);
    setDisplayUrl("");
    dispatch(fetchFile({ fileKey }, displayFile));
  };

  const refreshContent = () => {
    setLoader(true);
    if(salesType=="e-sales"){
      dispatch(
        getESalesDetails(
          { responseId: salesId, page: page + 1, limit: limit },
          (data) => {
            if (data) {
              dispatch(
                getTemplateHeader({
                  template_id: data.shipmentDetail.template_id,
                })
              );
              setLoader(false);
            } else {
              setLoader(false);
            }
          }
        )
      );
    }
    else{
      dispatch(
        getSalesDetails(
          { Sales_Id: salesId, page: page + 1, limit: limit },
          (data) => {
            if (data) {
              dispatch(
                getTemplateHeader({
                  template_id: data.shipmentDetail.template_id,
                })
              );
              setLoader(false);
            } else {
              setLoader(false);
            }
          }
        )
      );
    }
  };



  let getUserDetail = (userId) => {
    return AllUserData?.data?.find((u) => u._id == userId) || {}
  }


  let historySummary = (history) => {
    let label = ""
    let date = ""
    let userId = ""
    let count = 0

    if (["dispatched", "partialDispatched"].includes(history?.status)) {
      label = "dispatched"

      if (history?.status == "partialDispatched") label = "dispatch ongoing"
      count = history?.scannedCount || 0
      date = dateTimeFormater(history?.dispatchedDate)
      userId = history?.dispatchedBy?._id
    }
    else {
      // other job status code will come here
      label = "allocated"
      count = history?.count || 0
      date = dateTimeFormater(history?.allocatedDate)
      userId = history?.allocatedBy?.userId
    }

    label += " (" + (history?.jobId || "") + ") "
    userId = getUserDetail(userId) || {}

    return <>Order {label} for <strong>{count} unit</strong>{" "} by {userId?.name || ""} at{" "} <strong>{date}</strong></>
  }


  let historyDetails = (history) => {
    let steps=[]
    let activeStep=1

    let userId=history?.allocatedBy?.userId || history?.allocatedBy?._id || ''

    let heading = "Stock Allocation"
    let subHeading = "Allocated by"+" "+getUserDetail(userId)?.name + " at " + dateTimeFormater(history?.allocatedDate)
    // let subHeading = getUserDetail(userId)?.name + " at " + dateTimeFormater(history?.allocatedDate) +", "+history?.count || 0 +"unit"
    let description = (history?.count || 0) +" unit allocated"
    let link = `/dashboard/jobs?jobId=${history?.jobId}`
    let isStepFailed=false
    
    steps.push({heading,subHeading,description,link,isStepFailed})
    
    heading = "Booking Creation"
    subHeading = ""
    description =""
    link = ""
    
    /*****check if booking created or not */
    if (history?.TMS_Booking?.booking_details?.booking_number) {
      let date = history?.TMS_Booking?.booking_details?.booking_date || history?.TMS_Booking?.booking_details?.booking_date || ""
      heading = "Booking Created"
      subHeading = "Booking Number - " + history?.TMS_Booking?.booking_details?.booking_number + " at " + dateTimeFormater(date)
      description = history?.TMS_Booking.message || ""
      link = ""
      activeStep = 2
    }
    else {
      subHeading = "Not Created"
      isStepFailed = (["dispatched", "partialDispatched"].includes(history?.status) || history?.TMS_Booking?.trip_details?.trip_number) || false
    }
    
    steps.push({heading,subHeading,description,link,isStepFailed})
    
    heading = "Trip Creation"
    subHeading = ""
    description =""
    link = ""
    isStepFailed=false
    
    if (history?.TMS_Booking?.trip_details?.trip_number) {
      let date=history?.TMS_Booking?.trip_details?.created_on || ""
      heading="Trip Created"
      subHeading="Trip Number - " + history?.TMS_Booking?.trip_details?.trip_number+ " at " + dateTimeFormater(date)
      description=history?.TMS_Booking.message || ""
      link=`/dashboard/trip`
      // link=`/dashboard/trip/trip-detail?trip-number=${history?.TMS_Booking?.trip_details?.trip_number}&trip-id=${history?.TMS_Booking?.trip_details?.trip_id}`
      activeStep=3
    }
    else {
      subHeading = "Not Created"
      isStepFailed = ["dispatched", "partialDispatched"].includes(history?.status) || false
    }
    
    steps.push({heading,subHeading,description,link,isStepFailed})
    
    heading = "Stock Dispatch"
    subHeading = "Not Started Yet"
    description =""
    link = ""
    isStepFailed=false
    
    
    if (["dispatched", "partialDispatched"].includes(history?.status)) {
      let heading = "Stock Dispatched"
      if (history?.status == "partialDispatched") heading = "Stock Dispatch Ongoing"
      heading= heading
      subHeading= "Dispatched by" + " " + getUserDetail(history?.dispatchedBy?._id)?.name + " at " + dateTimeFormater(history?.dispatchedDate)
      // subHeading=getUserDetail(history?.dispatchedBy?._id)?.name + " on " + dateTimeFormater(history?.dispatchedDate) +", "+history?.scannedCount || 0 +"unit"
      description= (history?.scannedCount || 0) + " unit dispatched"
      activeStep=4
      link = `/dashboard/jobs?jobId=${history?.jobId}`
    }
    
    steps.push({heading,subHeading,description,link,isStepFailed})
    
    activeStep=Math.min(activeStep,steps.length)

    // console.log("steps",steps)

    return <CustomizedSteppers
      orientation={'vertical'}
      expanded={true}
      activeStep={activeStep}
      steps={steps}
    />
  }

  let createrUserInfo = salesOrderDetail && AllUserData?.data?.find((u) => u._id == salesOrderDetail?.shipmentDetail?.created_by?._id) || {}
  let userInfo = salesOrderDetail && AllUserData?.data?.find((u) => u._id == salesOrderDetail?.shipmentDetail?.rejected_by?._id) || {}

  // Function to sort the data
  const sortDataByDate = (data) => {
    return data.sort((a, b) => {
      // Use the latest date between allocatedDate and dispatchedDate if available
      const dateA = new Date(a.dispatchedDate || a.allocatedDate);
      const dateB = new Date(b.dispatchedDate || b.allocatedDate);

      // Sort in descending order (newest first)
      return dateB - dateA;
    });
  };

const getTimeDifferenceInMinutes = (time1, time2) => {
  // Parse the time strings into Date objects
  const date1 = new Date(time1); // For slotTo
  const date2 = new Date(time2); // For slotCreatedBy
  
  // Check if dates are valid
  if (isNaN(date1) || isNaN(date2)) {
    return "Invalid Dates";
  }
  
  // Return the difference in minutes
  return Math.ceil(Math.abs((date1.getTime() - date2.getTime()) / (1000 * 60)));
};

  useEffect(() => {
    if (salesId || page || limit) {
      refreshContent();
      dispatch(getAllUsers({ isAdminIncluded: true }));
    }
  }, [salesId, page, limit]);

  return (
    <>
      <Grid container>
        <Grid container item sm={9} xs={6}>
          <Typography data-testid="salesTittle" sx={{ ...textClasses.cardTitle }}>
           {salesType=="e-sales"? 'e-Sale order Response' : 'Sales Order Processing' } 
          </Typography>
        </Grid>
        <Grid container item sm={3} xs={6} justifyContent="end">
          {salesOrderDetail?.shipmentDetail?.approvalStatus == "inprogress" ? (
            <Button
              disabled
              size="small"
              sx={{
                ml: 2,
                borderRadius: "16px",
                backgroundColor: statusColor[salesOrderDetail?.shipmentDetail?.approvalStatus],
                color: "white !important",
                textTransform: "none",
              }}
            >
              {getCamelCaseText(statusText[salesOrderDetail?.shipmentDetail?.approvalStatus])} (
              {Number(salesOrderDetail?.shipmentDetail?.allocatedQuantity || 0) - salesOrderDetail?.shipmentDetail?.dispatchedQuantity || 0})
            </Button>
          ) : (
            <></>
          )}
          {["inprogress", "dispatched"].includes(salesOrderDetail?.shipmentDetail?.approvalStatus) && Number(salesOrderDetail?.shipmentDetail?.dispatchedQuantity || 0) > 0 ? (
            <Button
              disabled
              size="small"
              sx={{
                ml: 2,
                borderRadius: "16px",
                backgroundColor: statusColor["dispatched"],
                color: "white !important",
                textTransform: "none",
              }}
            >
              {getCamelCaseText(statusText["dispatched"])} (
              {salesOrderDetail?.shipmentDetail?.dispatchedQuantity || 0})
            </Button>
          ) : (
            <></>
          )}


        </Grid>
      </Grid>
      <Grid container>
        <Grid container item sm={9} xs={6}>
          <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-viewsales"> 
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: theme.themeOrange,
              }}
            >
              <Link
                style={{ color: theme.themeOrange, textDecoration: "none" }}
                to="/dashboard/salesOrder"
              >
                Sales Order Processing
              </Link>
            </Typography>
            {salesType=="e-sales" &&
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                 <Link
                style={{ color: theme.themeOrange, textDecoration: "none" }}
                to="/dashboard/salesOrder"
              >
              e-Sales Response
              </Link>
            </Typography>}
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              view details - {salesId}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container item sm={3} xs={6} justifyContent="end" sx={{ mt: 1 }}>
          {
            isSelectionAllocatable &&
            <>
              <Button variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "#3E621E", border: "1px solid #3E621E", ml: 1, "&:hover": { backgroundColor: "#3E621E", border: "1px solid #3E621E", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", } }} onClick={() => setDispatchModal("dispatch")}>Allocate Stock</Button>
            </>
          }
            {
              salesOrderDetail && salesOrderDetail?.shipmentDetail?.approvalStatus == "pending" &&
              <>
                <Button data-testid="approve-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "#3E621E", border: "1px solid #3E621E", ml: 1, "&:hover": { backgroundColor: "#3E621E", border: "1px solid #3E621E", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", } }} onClick={() => setConfirmModal("approve")}>Approve</Button>
              </>
  
            }
          {
            salesOrderDetail && salesOrderDetail?.shipmentDetail?.approvalStatus == "pending" &&
            <>
              <Button data-testid="reject-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "#E9001A", border: "1px solid #E9001A", ml: 1, "&:hover": { backgroundColor: "#E9001A", border: "1px solid #E9001A", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", } }} onClick={() => setConfirmModal("reject")}>Reject</Button>
            </>

          }
          {
            salesOrderDetail && salesOrderDetail?.shipmentDetail?.approvalStatus == "rejected" &&
            <>
              <Button data-testid="rejected-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, color: '#E9001A', backgroundColor: "white", border: "1px solid #E9001A", ml: 1, "&:hover": { backgroundColor: 'white', border: "1px solid #E9001A", cursor: "default" } }} >Rejected</Button>
            </>

          }
          {
            salesOrderDetail && salesOrderDetail?.shipmentDetail?.approvalStatus == "approved" &&
            <>
              <Button data-testid="approved-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, color: '#3E621E', backgroundColor: "white", border: "1px solid #3E621E", ml: 1, "&:hover": { backgroundColor: 'white', border: "1px solid #3E621E", cursor: "default" } }} >Approved</Button>
            </>

          }
        </Grid>
      </Grid>

      {loader ? (
        <>
          <Grid container justifyContent={"center"} alignItems="center">
            <GenericLoader data-testid="genericloader" />
          </Grid>
        </>
      ) : (
        <>
          {salesOrderDetail && (
            <>
              <Grid container>
                <Grid container item sm={9} xs={6}></Grid>
                <Grid container item sm={3} xs={6} justifyContent={"end"}>
                  <ConfirmDialog status={confirmModal} handleClose={handleCloseModal} selectedEntries={salesOrderDetail ? [salesOrderDetail] : []} refreshTableData={refreshContent} salesType={salesType}/>
                  <DispatchDialog status={dispatchModal} handleClose={handleCloseModal} entry={salesOrderDetail ? [salesOrderDetail] : []} />

                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid container item sm={12} md={8} order={{ xs: 2, sm: 2,md:1 }} sx={{ height: "100%", overflowY: "auto" }}>
                  <Paper sx={{ width: "100%", p: 2, mb: 2 }}>
                    <Grid container sx={{ mb: 2 }}>
                      <Grid container item md={salesType == "e-sales" ?6:4} sm={6} xs={12} justifyContent={"start"}>
                        <Typography
                          sx={{ ...textClasses.boldText, fontSize: "18px" }}
                          data-testid="OrderDetail"
                        >
                          Order Details
                        </Typography>
                      </Grid>
                    
                      <Grid container item md={salesType == "e-sales" ?6:4} sm={6} xs={12} justifyContent={"end"}>
                          {salesType == "e-sales" ?
                            <Typography
                              sx={{
                                ...textClasses.boldText,
                                fontSize: "18px",
                                color: theme.themeOrange,
                              }}
                            >
                              Required Quantity :
                            </Typography> :
                            <Typography
                              sx={{
                                ...textClasses.boldText,
                                fontSize: "18px",
                                color: theme.themeOrange,
                              }}
                            >
                              Ordered Qty :
                            </Typography>
                          }
                        <Typography
                          sx={{
                            ...textClasses.boldText,
                            fontSize: "18px",
                            color: theme.themeOrange,
                            ml: 1,
                          }}
                        >
                          {salesOrderDetail.shipmentDetail?.totalQuantity
                            ? salesOrderDetail.shipmentDetail?.totalQuantity
                            : 0}
                        </Typography>
                      </Grid> 
                      {salesType !== "e-sales" && (
                      <Grid container item md={4} sm={6} xs={12} justifyContent={"end"} data-testid="AllocatedQty">
                        <Typography
                          sx={{
                            ...textClasses.boldText,
                            fontSize: "18px",
                            color: theme.themeOrange,
                          }}
                          data-testid="AllocatedQty"
                        >
                          Allocated Qty :
                        </Typography>
                        <Typography
                          sx={{
                            ...textClasses.boldText,
                            fontSize: "18px",
                            color: theme.themeOrange,
                            ml: 1,
                          }}
                        >
                          {salesOrderDetail.shipmentDetail?.allocatedQuantity}
                        </Typography>
                      </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      sx={{ maxHeight: "800px", overflowY: "auto" }}
                      data-testid="viewdetails"
                    >
                      {salesOrderDetail &&
                        salesOrderDetail?.itemDetail?.map((order, i) => {
                          return (
                            <OrderCollapsableCard
                              index={i}
                              order={order}
                              key={i}
                              templateHeader={templateHeader}
                              handleFile={handleFile}
                              salesType={salesType}
                            />
                          );
                        })}
                    </Grid>
                    {salesOrderDetail && salesOrderDetail?.itemDetail?.length && (
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent="center"
                      >
                        <TablePagination
                          component="div"
                          count={SalesDetailItemsCount}
                          page={page}
                          rowsPerPage={limit}
                          rowsPerPageOptions={
                            SalesDetailItemsCount > 5 ? [5, 10, 25, 50, 100] : [5]
                          }
                          onPageChange={(e, newPage) => {
                            setPage(newPage);
                          }}
                          onRowsPerPageChange={(e) => {
                            setLimit(e.target.value);
                            setPage(0);
                          }}
                          ActionsComponent={TablePaginationActions}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: "0.5em",
                            "& .MuiTablePagination-selectLabel ": {
                              margin: "0",
                            },
                            "& .MuiTablePagination-displayedRows": {
                              margin: "0",
                            },
                          }}
                        />
                      </Grid>
                    )}
                  </Paper>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  md={4}
                  order={{ xs: 1, sm: 1,md:2 }}
                  sx={{ height: "100%", overflowY: "auto" }}
                  padding={0}
                >
                  <Paper
                    sx={{
                      width: "100%",
                      p: 2,
                      mb: 2,
                      minHeight: "50%",
                      maxHeight: "80%",
                    }}
                  >
                    <Typography
                      sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}
                    >
                      Shipment details
                    </Typography>
                    <Grid container>
                      {templateHeader &&
                        templateHeader.shipmentHeader &&
                          templateHeader.shipmentHeader.map((head, i) => {
                            let formData = salesOrderDetail?.shipmentDetail?.formData || {}
                            return (
                              <Grid container item alignItems={"center"} sx={{ mb: 1 }}>

                                <Grid container>
                                  <Grid container item sm={4} xs={6}>
                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{head.label}: </Typography>
                                  </Grid>
                                  <Grid container item sm={8} xs={6}>
                                    <Typography sx={{ ...textClasses.normalText }}>
                                      {formData[head.name] ?
                                        head.type === "file" ? (
                                          <div
                                            onClick={() =>
                                              handleFile(
                                                formData[
                                                head.name
                                                ]
                                              )
                                            }
                                          >
                                            <span
                                              style={{
                                                ...buttonClasses.viewFile,
                                                cursor: 'pointer'
                                              }}
                                            >
                                              View File
                                            </span>
                                          </div>
                                        ) :

                                          head.type == "date"
                                            ?
                                            dataReadFunction(head, formData) && dateTimeFormater(dataReadFunction(head, formData), head?.format || "DD MMM YYYY") || "-"
                                            :
                                            head?.renderOption?.length > 0
                                              ? renderOptionHandler(formData, head?.renderOption) || "-"
                                              : dataReadFunction(head, formData) || "-"
                                        :
                                        "-"}
                                    </Typography>
                                  </Grid>
                                  <FileModal
                                    openModal={openModal}
                                    displayUrl={displayUrl}
                                    setOpenModal={setOpenModal}
                                  />
                                </Grid>
                              </Grid>
                            )

                          })}
                      <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                        <Grid container>
                          <Grid container item sm={4} xs={6}>
                            <Typography
                              sx={{ ...textClasses.normalText, color: "#827878" }}
                            >
                              Sales Create at:{" "}
                            </Typography>
                          </Grid>
                          <Grid container item sm={8} xs={6}>
                            <Typography sx={{ ...textClasses.normalText }}>
                              {salesOrderDetail?.shipmentDetail?.submissionDate
                                ? dateTimeFormater(
                                  salesOrderDetail?.shipmentDetail?.submissionDate
                                )
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                        <Grid container>
                          <Grid container item sm={4} xs={6}>
                            <Typography
                              sx={{ ...textClasses.normalText, color: "#827878" }}
                            >
                              Sales Created by:
                            </Typography>
                          </Grid>
                          <Grid container item sm={8} xs={6}>
                            <Typography sx={{ ...textClasses.normalText }}>
                              {createrUserInfo && createrUserInfo.name} ,{" "}
                              {createrUserInfo && createrUserInfo.designation}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  {
  salesOrderDetail?.shipmentDetail?.slotBookingComponentID && (
    <Paper
      sx={{
        width: "100%",
        p: 2,
        mb: 2,
        minHeight: "50%",
        maxHeight: "80%",
      }}
    >
      <Typography
        sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}
      >
        Preferred Delivery Slot
      </Typography>
                          <Grid container>
                            <Typography
                              sx={{
                                ...textClasses.normalText,
                                mb: 2,
                                p: 1,
                                color: theme.themeOrange,
                                backgroundColor: theme.themeLightOrange,
                                border: `1px solid ${theme.themeOrange}`,
                                borderRadius: "12px",
                              }}
                            >
                              {`On `}
                              <strong>
                                {dateTimeFormater(salesOrderDetail?.shipmentDetail?.slotBookingComponentID?.slotBookingDate, "DD MMM YYYY")}
                              </strong>
                              {` in Between `}
                              <strong>
                                {dateTimeFormater(salesOrderDetail?.shipmentDetail?.slotBookingComponentID?.slotFrom, "hh:mm A")}
                              </strong>
                              {` to `}
                              <strong>
                                {dateTimeFormater(salesOrderDetail?.shipmentDetail?.slotBookingComponentID?.slotTo, "hh:mm A")}
                              </strong>
                              {` `}
                              <strong>
                                {getTimeDifferenceInMinutes(
                                  salesOrderDetail?.shipmentDetail?.slotBookingComponentID?.slotTo,
                                  salesOrderDetail?.shipmentDetail?.slotBookingComponentID?.slotFrom
                                )} Min </strong>
                              {` Slot Booked By `}
                              <strong>
                                {getUserDetail(salesOrderDetail?.shipmentDetail?.slotBookingComponentID?.slotCreatedBy?._id)?.name}
                              </strong>
                            </Typography>
                          </Grid>
                        </Paper>
                      )
                    }

                  {salesType == "e-sales" && userDetailsVisibilityFields?.length >0  && (
                  <Paper
                        sx={{
                          width: "100%",
                          p: 2,
                          mb: 2,
                          minHeight: "50%",
                          maxHeight: "80%",
                          mt:1
                        }}
                      >
                        <Typography
                          sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}
                        >
                          User Information
                        </Typography>
                        <Grid container>
                          {userDetailsVisibilityFields.map((head, i) => {
                              return (
                                <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                  <Grid container>
                                    <Grid container item sm={4} xs={6}>
                                      <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>
                                        {head.label}:
                                      </Typography>
                                    </Grid>
                                    <Grid container item sm={8} xs={6}>
                                      <Typography sx={{ ...textClasses.normalText }}>
                                        {
                                          head.type == "date"
                                            ?
                                            dataReadFunction(head, salesOrderDetail?.userData) && dateTimeFormater(dataReadFunction(head.name, salesOrderDetail?.userData)) || "-"
                                            :
                                            head?.renderOption?.length > 0
                                              ? renderOptionHandler(salesOrderDetail?.userData, head?.renderOption) || "-"
                                              : dataReadFunction(head, salesOrderDetail?.userData) || "-"
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            })}
                            </Grid>
                        </Paper>
                  )}

                    {userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners") &&
                    customerMasterVisibilityFields?.length > 0 && salesOrderDetail?.shipmentDetail?.customerDetail?.blink_id &&
                      <Paper
                        sx={{
                          width: "100%",
                          p: 2,
                          mb: 2,
                          minHeight: "50%",
                          maxHeight: "80%",
                          mt:1
                        }}
                      >
                        <Typography
                          sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}
                        >
                          Customer details
                        </Typography>
                        <Grid container>
                          {customerMasterVisibilityFields.map((head, i) => {
                              return (
                                <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                  <Grid container>
                                    <Grid container item sm={4} xs={6}>
                                      <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>
                                        {head.label}:
                                      </Typography>
                                    </Grid>
                                    <Grid container item sm={8} xs={6}>
                                      <Typography sx={{ ...textClasses.normalText }}>
                                        {
                                          head.type == "date"
                                            ?
                                            dataReadFunction(head, salesOrderDetail?.shipmentDetail?.customerDetail) && dateTimeFormater(dataReadFunction(head.name, salesOrderDetail?.shipmentDetail?.customerDetail)) || "-"
                                            :
                                            head?.renderOption?.length > 0
                                              ? renderOptionHandler(salesOrderDetail?.shipmentDetail?.customerDetail, head?.renderOption) || "-"
                                              : dataReadFunction(head, salesOrderDetail?.shipmentDetail?.customerDetail) || "-"
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            })}
                        </Grid>
                      </Paper>
                    }

                  {salesOrderDetail &&
                    salesOrderDetail?.shipmentDetail?.rejectedReason && (
                      <Paper sx={{ width: "100%", p: 2, mt: 2, mb: 2 }}>
                        <Grid container>
                          <Grid container item sm={6} xs={6}>
                            <Typography
                              sx={{
                                ...textClasses.boldText,
                                fontSize: "18px",
                                mb: 2,
                              }}
                            >
                              Rejection Reason
                            </Typography>
                          </Grid>
                          <Grid container item sm={6} xs={6} justifyContent="end">
                            <Typography sx={{ ...textClasses.normalText, mb: 2 }}>
                              {dateTimeFormater(
                                salesOrderDetail?.shipmentDetail?.rejected_by?.date || null
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography sx={{ ...textClasses.normalText, mb: 2 }}>
                          {userInfo && userInfo.name},{" "}
                          {userInfo && userInfo.designation}
                        </Typography>
                        <Typography sx={{ ...textClasses.normalText, mb: 2 }}>
                          {salesOrderDetail?.shipmentDetail?.rejectedReason
                            ? salesOrderDetail?.shipmentDetail?.rejectedReason
                            : "-"}
                        </Typography>
                      </Paper>
                    )}


                  {salesOrderDetail &&
                    salesOrderDetail?.shipmentDetail?.allocated_by?.length > 0 && (
                      <Paper sx={{ width: "100%", p: 2, mt: 2, mb: 2, }}>
                        <Grid container>
                          <Grid container item sm={6} xs={6}>
                            <Typography
                              sx={{
                                ...textClasses.boldText,
                                fontSize: "18px",
                                mb: 2,
                              }}
                            >
                              Dispatch History
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                          {salesOrderDetail?.shipmentDetail?.allocated_by && sortDataByDate(salesOrderDetail?.shipmentDetail?.allocated_by || [])?.slice(0, showHistory ? historySize : 4)
                            .map((history) => {
                              return (
                                <GenericSummaryView
                                  isSummary={true}
                                  headingText={historySummary(history || {})}
                                  detailChilden={historyDetails(history || {})}
                                />
                              );
                            })}
                        </Box>
                        {historySize > 4 && (
                          <>
                            <Grid
                              container
                              item
                              justifyContent="center"
                              alignItems={"center"}
                            >
                              <Typography
                                sx={{
                                  ...textClasses.boldText,
                                  color: theme.themeOrange,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => setShowHistory(!showHistory)}
                              >
                                {showHistory ? (
                                  <>Collapse</>
                                ) : (
                                  <>View All ({historySize})</>
                                )}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Paper>
                    )}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
};
export default ViewSales;

const OrderCollapsableCard = ({ index, order, templateHeader, handleFile,salesType }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(index == 0 ? true : false);
  const fields = Object.keys(order.formData);

  const masterType = order.itemMasterDetail ? "itemMaster" : "";



  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ReceivingTypes = [
    { label: "Available Quantity", value: "availableQuantity" },
    { label: "Less Quantity", value: "lessQuantity" },
    { label: "Extra Quantity", value: "exceedQuantity" },
  ];

  return (
    <Paper
      sx={{
        width: "100%",
        p: 2,
        border: `1px solid ${theme.themeOrange}`,
        mb: 2,
        overflowY: "auto",
      }}
    >
      <Grid container>
        <Grid
          container
          item
          sm={4}
          xs={4}
          justifyContent="start"
          alignItems="center"
        >
          {templateHeader &&
            templateHeader?.itemHeader?.map((h, i) => {
              return h.name == "product_ID" ? (
                <>
                  <Typography
                    sx={{ ...textClasses.normalText, color: "#827878" }}
                  >
                    {h.label}:
                  </Typography>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      ml: 1,
                    }}
                  >
                    {order.product_ID}
                  </Typography>
                </>
              ) : (
                <></>
              );
            })}
        </Grid>
        <Grid
          container
          item
          sm={8}
          xs={8}
          justifyContent="end"
          alignItems="center"
        >
          {templateHeader &&
            templateHeader?.itemHeader?.map((f, i) => {
              if (f.name === "UOM") {
                return (
                  <>
                    {
                      salesType == "e-sales" ? <Typography
                        sx={{ ...textClasses.normalText, color: "#827878" }}
                      >
                        Required Quantity:{" "}
                      </Typography> :
                        <Typography
                          sx={{ ...textClasses.normalText, color: "#827878" }}
                        >
                          Ordered Qty:{" "}
                        </Typography>
                    }

                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        ml: 1,
                      }}
                    >
                      {order.totalQuantity} {order.formData[f.name]}
                    </Typography>
                  </>
                );
              }
            })}

          {order.approvalStatus == "inprogress" ? (
            <Button
              disabled
              size="small"
              sx={{
                ml: 2,
                borderRadius: "16px",
                backgroundColor: statusColor[order.approvalStatus],
                color: "white !important",
                textTransform: "none",
              }}
            >
              {getCamelCaseText(statusText[order.approvalStatus])} (
              {truncateNumber(Number(order.allocatedQuantity || 0) - Number(order.dispatchedQuantity || 0))})
            </Button>
          ) : (
            <></>
          )}


          {["inprogress", "dispatched"].includes(order.approvalStatus) && Number(order.dispatchedQuantity || 0) > 0 ? (
            <Button
              disabled
              size="small"
              sx={{
                ml: 2,
                borderRadius: "16px",
                backgroundColor: statusColor["dispatched"],
                color: "white !important",
                textTransform: "none",
              }}
            >
              {getCamelCaseText(statusText["dispatched"])} (
              {order.dispatchedQuantity || 0})
            </Button>
          ) : (
            <></>
          )}

          <Typography
            sx={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              textDecoration: "underline",
              cursor: 'pointer',
              ml: 2,

            }}
            onClick={handleExpandClick}
          >
            {expanded ? "Hide details" : "Show details"}
            <ExpandMore
              expand={expanded}

              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreOutlined sx={{ color: theme.themeOrange }} />
            </ExpandMore>
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Grid container sx={{ pt: 2 }}>
          {templateHeader &&
            templateHeader?.itemHeader?.map((f, i) => {
              return (
                <Grid
                  container
                  item
                  sm={6}
                  alignItems={"center"}
                  sx={{ mb: 0 }}
                >
                  <Grid container>
                    <Grid container item sm={4} xs={6}>
                      <Typography
                        sx={{ ...textClasses.normalText, color: "#827878" }}
                      >
                        {f.label}:
                      </Typography>
                    </Grid>
                    <Grid container item sm={8} xs={6}>
                      <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                        {order.formData[f.name] ? (
                          f.type === "file" ? (
                            <div
                              onClick={() => handleFile(order.formData[f.name])}
                            >
                              <span
                                style={{
                                  ...buttonClasses.viewFile,
                                  cursor: "pointer",
                                }}
                              >
                                View File
                              </span>
                            </div>
                          ) : (
                            order.formData[f.name]
                          )
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          {masterType == "itemMaster" &&
            templateHeader &&
            templateHeader?.itemMaster
              ?.filter((h) => ["SKU_ID", "UOM"].indexOf(h.name) < 0)
              .map((head, i) => {
                return (
                  <Grid
                    container
                    item
                    sm={6}
                    alignItems={"center"}
                    sx={{ mb: 0 }}
                  >
                    <Grid container>
                      <Grid container item sm={4} xs={6}>
                        <Typography
                          sx={{ ...textClasses.normalText, color: "#827878" }}
                        >
                          {head.label}:
                        </Typography>
                      </Grid>
                      <Grid container item sm={8} xs={6}>
                        <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                          {/* {order.itemMasterDetail[head.name]? order.itemMasterDetail[head.name] : "-"} */}
                          {order.itemMasterDetail[head.name] ? (
                            head.type === "file" ? (
                              <div
                                onClick={() =>
                                  handleFile(order.itemMasterDetail[head.name])
                                }
                              >
                                <span
                                  style={{
                                    ...buttonClasses.viewFile,
                                    cursor: "pointer",
                                  }}
                                >
                                  View File
                                </span>
                              </div>
                            ) : (
                              <span style={{ marginLeft: '10px' }}>{order.itemMasterDetail[head.name]}</span>
                            )
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          {order.receivingType && (
            <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
              <Grid container>
                <Grid container item sm={4} xs={6}>
                  <Typography
                    sx={{ ...textClasses.normalText, color: "#827878" }}
                  >
                    Receiving Type:
                  </Typography>
                </Grid>
                <Grid container item sm={8} xs={6}>
                  <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                    {ReceivingTypes.find(
                      (type) => type.value === order.receivingType
                    )?.label ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Paper>
  );
};
