import React, { useEffect } from "react";
import { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  Add,
  Cancel,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRejectCompleteSales,
  approveRejectDeKit,
  getCurrentKitCount,
  getJobProcessConfig,
} from "../../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../../static/styles/theme"
import {
  showToast,
} from "../../../../services/functions";
import GenericSingleRangeComponent from "../../../Components/Generic/GenericSingleRangeComponent";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { removeUntilE } from "../../../../utils";

const ApproveRejectConfirmationDialog = ({
    status,
    handleClose,
    selectedEntries,
    setSelectedEntries,
    refreshTableData,
    handleRemoveSelection,
    operationType,
  }) => {
    const dispatch = useDispatch();
    const [showAll, setShowAll] = useState(false);
    const [dataReceived,isDataReceived]= useState(false)
    const size = selectedEntries.length;
    const [reason, setReason] = useState("");
    const [loader, setLoader] = useState(false);
    const { AllUserData } = useSelector((state) => state.users);
    const [worker, setWorker] = useState("");
    const [maxPickingCount,setMaxPickingCount]=useState(0)
    const [pickingCount, setPickingCount] = useState(0);
    const [isWorkerMandatory,setIsWorkerMandatory] = useState(false)
    const callBack = (data) => {
      showToast(data.message, !data.success);
      isDataReceived(false);
      if(data.success){
        refreshTableData();
        handleClose();
        if (setSelectedEntries) {
          setSelectedEntries([]);
        }
      }
    };
  
    const handleStatusChange = () => {
      isDataReceived(true)
      let payload = {}
      if (status == "rejected") {
        if (operationType == "kit") {
          payload = {
            KIT_ID:
              selectedEntries.length == 1
                ? selectedEntries[0]?.KIT_ID
                : selectedEntries.map((e) => e.KIT_ID),
            status: status,
            reason: reason,
          };
        }
        else {
          payload = {
            kitIds:
              selectedEntries.length == 1
                ? selectedEntries[0]?.itemId
                : selectedEntries.map((e) => e.itemId),
            status: status,
            reason: reason,
          }
        }
      }
      else {    // status is approved
        if (operationType == "kit") {
          payload = {
  
            KIT_ID:
              selectedEntries.length == 1
                ? selectedEntries[0]?.KIT_ID
                : selectedEntries.map((e) => e.Sales_Id),
            status: status,
            assignee_id: worker,
            kitCount:pickingCount
          };
        } else {
          payload = {
            kitIds:
              selectedEntries.length == 1
                ? selectedEntries[0]?.itemId
                : selectedEntries.map((e) => e.itemId),
            status: status,
          }
        }
      }
      if (operationType == "kit") {
        dispatch(
          approveRejectCompleteSales(payload, (data) => {
            if (data) {
              callBack(data);
            }
          })
        );
      }
      else {
        dispatch(
          approveRejectDeKit(payload, (data) => {
            if (data) {
              callBack(data);
            }
          })
        );
      }
    };
  
    const countChangeHandler = (count) => {
      setPickingCount(count)
    }
  
    useEffect(() => {
      if (operationType == "kit" && status == "approved") { // if status is approved and operationType is Kit
        setLoader(true)
        dispatch(getJobProcessConfig({ projections: { requiredFields: 1 }, approved: true }, (data) => {
          setIsWorkerMandatory(data?.data?.requiredFields?.userRequired)
      }))
        dispatch(getCurrentKitCount({ KIT_ID: selectedEntries[0]?.KIT_ID }, (data={}) => {
          setLoader(false)
          setMaxPickingCount(data?.count || 0)
          setPickingCount(data?.count || 0)
        }))
      }
      else {   // if status is rejected
        setReason("");
      }
    }, [status]);
  
  
    const workerOptions = [
      // { label: "--select--", value: "" },
      ...(AllUserData?.data || [])?.filter(dl=>dl.userType=="worker")?.map((_) => {
        return {
          label: _.name,
          value: _._id,
        };
      }),
    ];
  
    const getDisableCondition = () => {
      if (dataReceived) return true;
      if (operationType == "kit") {
        if (status == "approved") {
          if (!pickingCount || (isWorkerMandatory && !worker)) return true;
        }
        else {  // status is rejected
          if (!(reason || "").trim()) return true;
        }
  
      } else {   // op Type = Dekit
        if (status == "approved") {
          return false;
        }
        else {  // status is rejected
          if (!(reason || "").trim()) return true;
        }
      }
    }
    const handleWorkerSelect = (e, option) => {
      if (option) {
        // ////(option);
        const { value, label } = option; //e.target;
        setWorker(value);
      } else {
        setWorker("");
      }
    };
    return (
      <Dialog
        open={status != null}
        onClose={handleClose}
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"md"}
      >
        {loader ? (
          <Grid
            data-testid="loader"
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid>
        ) : (
          <DialogContent>
            <Grid container justifyContent={"center"} alignItems="center">
              {status == "approved" ? operationType == "kit" ? <><Typography sx={{ ...textClasses.cardTitle }}>
                Approve the Kit preparation operation(s)
              </Typography>
                <Typography sx={{ ...textClasses.t15n, textAlign: 'center' }}>
                  Approve the Kit Preparation and start with kit component picking job. On Kit Approval A Component picking Job auto created.
                </Typography></> : <> <Typography sx={{ ...textClasses.cardTitle }}>
                  Approve the de-Kit request operation(s)
                </Typography>
                <Typography sx={{ ...textClasses.t15n, textAlign: 'center' }}>
                  Approve the de-kit process and start with kit component put-away process to add-on to stock.
                </Typography></>
                : <Typography sx={{ ...textClasses.cardTitle }}>
                  Are you Sure ?
                </Typography>}
            </Grid>
            <Grid container justifyContent={"center"} alignItems="center">
              {status == "rejected" ? <Typography sx={{ ...textClasses.cardTitle }}>
                {operationType == "kit" ? `You want to ${removeUntilE(status)} the KIT` : `You want to ${removeUntilE(status)} the de-kit Request(s).`}
              </Typography> : <></>}
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={1}>
              {selectedEntries.slice(0, showAll ? size : 5).map((st, i) => {
                return (
                  <Grid item sm={4} xs={6} key={i}>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        backgroundColor: theme.themeLightOrange,
                        border: `1px solid ${theme.themeOrange}`,
                        borderRadius: "12px",
                        pl: 1,
                        pr: 1,
                        minHeight: "40px",
                      }}
                    >
                      <Grid item sm={10} xs={8}>
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            color: theme.themeOrange,
                            textAlign: "start",
                          }}
                        >{operationType == "kit" ?
                          st?.KIT_ID && st?.KIT_ID?.length > 15
                            ? st?.KIT_ID?.substring(0, 15) + "..."
                            : st?.KIT_ID :
                          st?.itemId && st?.itemId?.length > 17
                            ? st?.itemId?.substring(0, 17) + "..."
                            : st?.itemId}
                        </Typography>
                      </Grid>
                      {handleRemoveSelection && (
                        <Grid item sm={2} xs={4}>
                          <IconButton
                            sx={{ alignContent: "right" }}
                            onClick={() => handleRemoveSelection(st._id)}
                          >
                            <Cancel sx={{ color: theme.themeOrange }} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {size > 5 && (
                <>
                  <Grid
                    container
                    item
                    sm={4}
                    xs={6}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        color: theme.themeOrange,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? <>Collapse</> : <>View All ({size})</>}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            {
              operationType == "kit" && status == "approved" ?
                <Grid item xs={6} sm={5} sx={{ mt: 2, mb: 2 }}>
                  <Grid item sm={12}>
                    <Typography sx={{ ...textClasses.t15n, color: theme.themeOrange }}>
                      As per available components Stock, you can {maxPickingCount < 1 ? "not make any kit." : `initiate component picking for ${maxPickingCount} kits only.`}
                    </Typography>
                  </Grid>
                  {maxPickingCount > 0 ?
                  <Grid item sm={12} sx={{ display: 'inline-flex', alignItems: 'center', mt: 1 }}>
                    <Typography sx={{ ...textClasses.t15n, mr: 2 }}>
                      Enter No. of Kit(s), for which you want to start component picking :
                    </Typography>
                    <GenericSingleRangeComponent
                      data-testid="range-input"
                      startRange={pickingCount}
                      endRange={maxPickingCount}
                      getOnChange={(count) => countChangeHandler(count)}
                      valueType={"integer"}
                    />
                  </Grid> : ""}
                </Grid> : ""
            }
            {
              operationType == "kit" && status == "approved" && maxPickingCount > 0 ?
                <Grid item xs={6} sm={5} >
                  <Autocomplete
                    id="assign-user-label"
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Assign User ${isWorkerMandatory ? '*' : '(optional)'}`}
                      //   sx={{
                      //     ...selectStylesOverride,
                      //   }}
                      />
                    )}
                    sx={{ mt: 2, ...inputClasses.filterField, width: '50%' }}
                    //   disabled={!jobType?.value}
                    //   data-testid="user-select"
                    //   sx={{ flexBasis: "50%", mb: 1 }}
                    options={workerOptions}
                    onChange={handleWorkerSelect}
                    value={
                      workerOptions.find((_) => _.value === worker)?.label ||
                      ""
                    }
                  />
                </Grid> : ""
            }
            {
              status == "rejected" ?
                <Grid container direction={"row"} sx={{ mt: 1 }}>
                  <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </Grid>
                :
                ""
            }
  
          <Grid container sx={{ p: 1, mt: 3 }}>
            <Button
              variant="outlined"
              size="small"
              sx={{
                ...buttonClasses.lynkitBlackFill,
                height: "40px",
                minWidth: "150px",
              }}
              disabled={dataReceived}
              onClick={handleClose}
            >
              No, Cancel
            </Button>
        <Button
              variant="contained"
              size="small"
              sx={{
                ml: 1,
                ...buttonClasses.small,
                backgroundColor: theme.themeOrange,
                minWidth: "150px",
              }}
              disabled={getDisableCondition()}
              onClick={handleStatusChange}
            >
              Yes, {removeUntilE(status)}
            </Button> 
          </Grid>
        </DialogContent>
         )}
      </Dialog>
      
    );
  };

  export default ApproveRejectConfirmationDialog;