import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid, Box, InputLabel, Select, MenuItem, FormControl, Button, Typography, Autocomplete, TextField, OutlinedInput, InputAdornment, Menu,
    Card, CardContent, CardActions, CardMedia, Tooltip, IconButton, Radio, RadioGroup, FormControlLabel,
} from "@mui/material";
import "./Style/3dView.css";
// import GridViewLayout from "./Components/GridViewLayout";
// import WarehouseModel from "./Components/WarehouseModel";
// import AccessbilityAndUtilization from "./Components/AccessbilityAndUtilization";
import ThreedViewLayout from "./Components/3dViewLayout";
import EmptyPage from "../../Modules/EntityMaster/EmptyPage";
import {
    getCompany,
    getGridDetail,
    addRack,
    viewFilledLocationByShipment,
    generateQrCode,
    generateGridExcel,
    getUtilizationAnalytics,
    getGridBehaviourAnalytics,
    openCloseGridHeatmap
} from "../../../redux/actions";
import { getMappedArea } from "../../../redux/actions/AreaMapping";
import { showToast } from "../../../services/functions";
import { ToastContainer } from "react-toastify";
import { selectStylesOverride } from "../../../utils/util";
import DownloadIcon from "../../../static/images/Icon/Download-Icon.png";
import RedirectIcon from "../../../static/images/Icon/Redirect_icon.png";
import {
    buttonClasses,
    inputClasses,
    textClasses,
} from "../../../static/styles/theme";
import GenericLoader from "../Generic/GenericLoader";
import AlertDialog from "../DialogBox/dialogbox";
import history from "../../../services/history";
import GenericToggle from "../GenericToggle/index";
import GenericDateRange from "../Generic/GenericDateRange";
import moment from "moment";
import dayjs from "dayjs";


const initailView = {
    view: "gridView",
    fromDate: null,
    toDate: null,
    disabled: false,
}


function ThreeDView() {
    const dispatch = useDispatch();
    const { gridCellDetails, FilledLocationByShipment } = useSelector(
        (state) => state.warehouseData
    );
    const {
        warehouseDetail,
        floorDetail,
        generatedQr = false,
    } = useSelector((state) => state.superadmin);
    const heatMapOpen = useSelector(state => state.ui.heatMapOpen);
    const { userPermissions = {} } = useSelector((state) => state.userPermission);
    const { mappedArea } = useSelector((state) => state.areaMappingData);

    const [warehouseInfo, setWarehouseInfo] = useState({});
    const [warehouseData, setWarehouseData] = useState([]);
    const [cellInfo, setCellInfo] = useState({});



    const [floorData, setFloorData] = useState([]);
    const [floorInfo, setFloorInfo] = useState({});
    const [condition, setCondition] = useState(true);

    const [allFilledLocation, setAllFilledLocation] = useState({});
    const [areaName, setAreaName] = useState("");
    const [userType, setUserType] = useState("");
    const [withWFFetch, setWithWFFetch] = useState({
        warehouse: true,
        floor: true,
    });

    const [floorLoader, setFloorLoader] = useState(true);
    const [qrLoader, setQrLoader] = useState(false);
    const [visibleCard, setVisibleCard] = useState("");
    const [showRfidTags, setShowRfidTags] = useState(false)
    const [analyticsBlockDetail, setAnalyticsBlockDetail] = useState([]);
    const [utilizationTypeValue, setUtilizationTypeValue] = useState("countPercentageFilled");
    const [isViewEnabled, setIsViewEnabled] = useState({ ...initailView });
    const [heatMapAnalytics, setHeatMapAnalytics] = useState({
        "1727185818240201": {
            "name": "R1",
            "count": 619,
            "movementStatus": "fastMoving"
        },
        "1727185818240101": {
            "name": "R1",
            "count": 484,
            "movementStatus": "slowMoving"
        }
    })

    const handleUtilizationValueChange = (event) => {
        setUtilizationTypeValue(event.target.value);
    };


    const colorHanlder = (cell_Data, analyticsRackDetail) => {
        if (cell_Data) {
            if (areaName && cell_Data.productType != areaName) {
                return "grey";
            }
            if (cell_Data.status == "full") {
                return "red";
            } else if (cell_Data.status == "partial") {
                let utilizationInfo = analyticsRackDetail?.find(dl => dl?.cellId === cell_Data?.cellId) || {};
                let percentage = 0;
                if (utilizationInfo[utilizationTypeValue] > 0) {
                    percentage = (utilizationInfo[utilizationTypeValue] || 0)
                }
                percentage = Number(percentage || 0)
                if (percentage >= 100) {
                    return "red";
                }
                return "yellow";
            } else if (cell_Data.status == "available") {
                return "green";
            } else {
                return "green";
            }
        } else {
            return "green";
        }
    };


    const showWarehouseModal = async (cellId = "") => {
        let data = gridCellDetails.find((ele) => ele.cellId == cellId);
        setVisibleCard("warehouse");
        setCellInfo(data);
    };

    const selectWarehouseHandler = (warehouse = {}) => {
        if (warehouse) {
            dispatch(
                getCompany({
                    warehouseId: warehouse?.warehouseId || "",
                    type: "floor",
                    status: "active",
                    "fetch_for": "gridView"
                })
            );
            setWarehouseInfo({
                ...warehouse,
                label: warehouse.name || "",
                value: warehouse.name || "",
            });
        } else {
            setWarehouseInfo({});
        }

        setFloorData([]);
        clearState()
    };

    const selectFloorHandler = (floor = {}) => {
        clearState()
        if (floor) {
            setFloorLoader(true);
            dispatch(
                getGridDetail(
                    {
                        floorId: floor?.floorId || "",
                        type: "block",
                        fetch_for: "gridview",
                    },
                    () => {
                        setFloorLoader(false);
                    }
                )
            );
            dispatch(
                viewFilledLocationByShipment({
                    type: ["block", "stagingArea"],
                    floorId: floor?.floorId || "",
                })
            );
            dispatch(
                getMappedArea({ floorId: floor?.floorId || "", allAreaNameOnly: true })
            );
            setFloorInfo({
                ...floor,
                label: floor.name || "",
                value: floor.name || "",
            });
            dispatch(
                getUtilizationAnalytics({
                    floorId: floor?.floorId || "", type: "block"
                }, (data) => {
                    setAnalyticsBlockDetail(data.data)
                }))
        }

        setWithWFFetch({ ...withWFFetch, graph: true });
    };
    const getDownloadExcel = () => {
        setShowRfidTags(true)
    }
    const clearState = () => {
        setFloorInfo({});
        setVisibleCard("")
        setAllFilledLocation([]);
        setIsViewEnabled({ ...initailView })
        setUtilizationTypeValue("countPercentageFilled")
    }


    const handleAddRack = (data) => {
        if (data) {
            let obj = {
                ...data,
                warehouseId: warehouseInfo?.warehouseId || "",
                floorId: floorInfo?.floorId || "",
            };
            dispatch(
                addRack(obj, ({ success, message }) => {
                    showToast(message, !success);
                    if (success) {
                        setVisibleCard("");
                        setCellInfo({});
                        setFloorLoader(true);

                        dispatch(
                            getGridDetail(
                                {
                                    floorId: floorInfo?.floorId || "",
                                    type: "block",
                                    fetch_for: "gridview",
                                },
                                () => {
                                    setFloorLoader(false);
                                }
                            )
                        );
                        dispatch(
                            viewFilledLocationByShipment({
                                type: ["block", "stagingArea"],
                                floorId: floorInfo?.floorId || "",
                            })
                        );
                    }
                })
            );
        }
    };

    const getQrCode = ({ warehouseId = "", _id = "", base = "" }) => {
        setQrLoader("qr");
        dispatch(
            generateQrCode(
                { warehouseId, floorId: _id + "", base },
                ({ success, message, awsUrl }) => {
                    showToast(message, !success);
                    setQrLoader(false);
                    if (success && awsUrl) {
                        window.open(awsUrl, "_blank");
                    }
                }
            )
        );
    };

    let getToDate = () => {
        let current1 = dayjs().hour(0).minute(0).second(0).millisecond(0);
        let fromDate = current1.subtract(6, "day");
        let toDate = current1.hour(23).minute(59).second(59).millisecond(999);

        let date = [fromDate, toDate];
        return date;
    };

    const heatMapDataHandler = (fromDate, toDate, callback) => {
        if (toDate && fromDate) {
            let days = moment(toDate.toISOString()).diff(moment(fromDate.toISOString()), 'days')
            if (days < 6) {
                showToast("Date range must be minimum 7 days!", true)
                return
            }
            setIsViewEnabled({ ...isViewEnabled, fromDate, toDate })
        }
        else {
            setIsViewEnabled({ ...isViewEnabled, fromDate: null, toDate: null })
        }
        if (callback) {
            callback(true)
        }
    };

    useEffect(() => {
        if (floorInfo?.floorId && FilledLocationByShipment) {
            setAllFilledLocation(FilledLocationByShipment);
        }
    }, [FilledLocationByShipment]);


    useEffect(() => {
        let userData = localStorage.getItem("user");
        // //('data-userdata', userData)
        if (userData && userData.userType) {
            setUserType(userData.userType);
        }
        if (condition && (!warehouseData || warehouseData.length == 0)) {
            dispatch(
                getCompany({
                    companyName: userData.company,
                    type: "warehouse",
                    status: "active",
                    "fetch_for": "gridView"
                })
            );
            setCondition(false);
        }
        else {
            if (warehouseDetail?.data?.length > 0) {
                setWarehouseData(warehouseDetail.data);
                if (withWFFetch && withWFFetch.warehouse) {
                    if (!warehouseInfo?.warehouseId) {
                        selectWarehouseHandler({
                            ...warehouseDetail.data[0],
                            warehouseId: warehouseDetail.data[0]?._id || "",
                        });
                    }
                    let tempFetch = { ...withWFFetch, warehouse: false };
                    setWithWFFetch(tempFetch);
                }
            }
            else {
                setWarehouseData([]);
            }
            let tempFloor = (floorDetail || [])?.filter(dl => dl.warehouseId == warehouseInfo?.warehouseId) || []
            if (warehouseInfo?.warehouseId && tempFloor?.length > 0) {
                setFloorData(tempFloor);
                if (withWFFetch && withWFFetch.floor) {
                    if (!floorInfo?.floorId) {
                        selectFloorHandler({
                            ...tempFloor?.at(0),
                            floorId: tempFloor?.at(0)?._id || "",
                        });
                    }
                    let tempFetch = { ...withWFFetch, floor: false };
                    setWithWFFetch(tempFetch);
                }
            }
            else {
                setFloorData([]);
            }
        }
    }, [warehouseDetail, floorDetail]);


    useEffect(() => {
        if (isViewEnabled.view == "heatMap") {
            let payload = {
                floorId: floorInfo?.floorId || "",
                warehouseId: warehouseInfo?.warehouseId || "",
                fromDate: isViewEnabled?.fromDate || "",
                toDate: isViewEnabled?.toDate || "",
                level: "block",
            }
            setIsViewEnabled({ ...isViewEnabled, disabled: true })
            dispatch(getGridBehaviourAnalytics(payload, (output) => {
                setHeatMapAnalytics(output?.data || {})
                setIsViewEnabled({ ...isViewEnabled, disabled: false })
            }))
        }
        else {
            setHeatMapAnalytics({})
        }
    }, [isViewEnabled.fromDate, isViewEnabled.toDate, isViewEnabled.view])

    useEffect(() => {
        // console.log("comee")
        if (isViewEnabled?.view == "heatMap" && !heatMapOpen) {
            setIsViewEnabled({ view: "gridView", fromDate: null, toDate: null })
        }
    }, [heatMapOpen])

    return (
        <>
            <Grid container className="GridView_container" data-testid="3dView-dashboard" >
                <Grid container margin="normal">
                    <Grid item xs={1.5}>
                        <h2 className="fs-24 mt-1 font-Nunito fw-600">Grid View</h2>
                    </Grid>
                    <Grid item xs={10.5}>
                        <Grid container item spacing={2} justifyContent={"end"} xs={12} flexWrap={"wrap"}>
                            <Grid item xs={2.5}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        size="small"
                                        labelId="jobName-label"
                                        sx={{ height: "1.1rem" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <Typography sx={{ fontSize: "12px" }}>
                                                        Select Warehouse
                                                    </Typography>
                                                }
                                                sx={{
                                                    ...selectStylesOverride,
                                                    fontSize: "12px",
                                                    "& input::placeholder": {
                                                        fontSize: "9px",
                                                    },
                                                }}
                                            />
                                        )}
                                        options={
                                            warehouseData.map((item) => ({
                                                label: item.name,
                                                name: item.name,
                                                value: item.name,
                                                warehouseId: item?._id,
                                            })) || []
                                        }
                                        onChange={(e, option) => selectWarehouseHandler(option)}
                                        value={warehouseInfo?.label || ""}
                                        renderOption={(props, option, { selected }) => (
                                            <MenuItem
                                                value={option?.label || ""}
                                                key={option?.label || ""}
                                                {...props}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: ".8rem",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {option?.label || ""}
                                                </Typography>
                                            </MenuItem>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.5}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        size="small"
                                        labelId="jobName-label"
                                        sx={{ height: "1.1rem" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <Typography sx={{ fontSize: "12px" }}>
                                                        Select Floor
                                                    </Typography>
                                                }
                                                sx={{
                                                    ...selectStylesOverride,
                                                    fontSize: "12px",
                                                    "& input::placeholder": {
                                                        fontSize: "9px",
                                                    },
                                                }}
                                            />
                                        )}
                                        options={
                                            floorData.map((item) => ({
                                                ...item,
                                                label: item.name,
                                                value: item.name,
                                                floorId: item?._id,
                                            })) || []
                                        }
                                        onChange={(e, option) => selectFloorHandler(option)}
                                        value={floorInfo?.label || ""}
                                        renderOption={(props, option, { selected }) => (
                                            <MenuItem
                                                value={option?.label || ""}
                                                key={option?.label || ""}
                                                {...props}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: ".8rem",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {option?.label || ""}
                                                </Typography>
                                            </MenuItem>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2.5}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride }}
                                >
                                    <Autocomplete
                                        size="small"
                                        labelId="jobName-label"
                                        sx={{ height: "1.1rem" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={
                                                    <Typography sx={{ fontSize: "12px" }}>
                                                        Area Type
                                                    </Typography>
                                                }
                                                sx={{
                                                    ...selectStylesOverride,
                                                    fontSize: "12px",
                                                    "& input::placeholder": {
                                                        fontSize: "9px",
                                                    },
                                                }}
                                            />
                                        )}
                                        options={
                                            mappedArea?.data?.filter((dl) => !dl.isDefault) || []
                                        }
                                        onChange={(e, option) => setAreaName(option?.value || "")}
                                        value={{
                                            label:
                                                (areaName &&
                                                    mappedArea?.data.find((dl) => dl.value == areaName)
                                                        ?.label) ||
                                                "",
                                            value: areaName || "",
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <MenuItem
                                                value={option?.value || ""}
                                                key={option?.value || ""}
                                                {...props}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: ".8rem",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {option?.label || ""}
                                                </Typography>
                                            </MenuItem>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid margin="normal" container sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "space-between", }} >
                        <Grid sx={{ borderRadius: "10px", border: "1px solid #D9D9D9", width: visibleCard ? "69%" : "100%", }} item >
                            <Grid container spacing={2} padding={2}>
                                <Grid item xs={3} display={"flex"} justifyContent={"start"}>
                                    <Box className="GV-title-container">
                                        <h2 className="orange-color font-Nunito fs-18 fw-600">
                                            {warehouseInfo?.name || ""}
                                            {floorInfo?.name ? ` - ${floorInfo?.name}` : ""}{" "}
                                        </h2>
                                    </Box>
                                </Grid>
                                <Grid item xs={9} padding={0} sx={{ display: "flex", justifyContent: "end" }} >
                                    <Box className="GV-label-container">
                                        <Box className="GV-container">
                                            <Box className="GV-green-box"></Box>&nbsp;&nbsp;
                                            <span className="fs-12 GV-center">
                                                Fully Available (
                                                {allFilledLocation.available
                                                    ? allFilledLocation.available
                                                    : "0"}
                                                )
                                            </span>
                                        </Box>
                                        <Box className="GV-container">
                                            <Box className="GV-yellow-box"></Box>&nbsp;&nbsp;
                                            <span className="fs-12 GV-center">
                                                Partially Filled (
                                                {allFilledLocation.partial
                                                    ? allFilledLocation.partial
                                                    : "0"}
                                                )
                                            </span>
                                        </Box>
                                        <Box className="GV-container">
                                            <Box className="GV-red-box"></Box>&nbsp;&nbsp;
                                            <span className="fs-12 GV-center">
                                                Fully Filled (
                                                {allFilledLocation.complete
                                                    ? allFilledLocation.complete
                                                    : "0"}
                                                )
                                            </span>
                                        </Box>
                                        <Box className="GV-container">
                                            <Box className="GV-white-box"></Box>&nbsp;&nbsp;
                                            <span className="fs-12 GV-center">
                                                Disabled (
                                                {allFilledLocation.disable
                                                    ? allFilledLocation.disable
                                                    : "0"}
                                                )
                                            </span>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            {floorLoader ? (
                                <Grid
                                    container
                                    direction={"column"}
                                    justifyContent={"center"}
                                    alignItems="center"
                                    sx={{ p: 10 }}
                                >
                                    <Grid item>
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    {floorInfo &&
                                        Object.keys(floorInfo).length > 0 &&
                                        floorInfo.row &&
                                        floorInfo.col ? (
                                        <ThreedViewLayout 
                                            areaName={areaName}
                                            floorInfo={floorInfo}
                                            gridCellDetails={gridCellDetails}
                                            showWarehouseModal={(cellID) => showWarehouseModal(cellID)}
                                            showRackModal={(cellID) =>
                                            // showRackModal(cellID)
                                            showWarehouseModal(cellID)
                                            }
                                            setVisibleCard={setVisibleCard}
                                            analyticsBlockDetail={analyticsBlockDetail}
                                            utilizationTypeValue={utilizationTypeValue}
                                            heatMapAnalytics={heatMapAnalytics}
                                            isViewEnabled={{ view: isViewEnabled?.view || "" }}
                                        />
                                    ) : (
                                        <EmptyPage />
                                    )}
                                </>
                            )}
                        </Grid>

                        <ToastContainer
                            position="bottom-center"
                            autoClose={false}
                            closeOnClick={false}
                        />

                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </>
    );
}

export default ThreeDView;