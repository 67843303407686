import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	FormControl, Grid, InputAdornment, OutlinedInput, FormControlLabel,
	Button, TextField, Select, MenuItem, FormLabel, Pagination,
	InputLabel, Typography, Tab, Tabs, Box, Checkbox, Stack,
	Card, CardHeader, CardContent, CardActions, Avatar, Tooltip,
	IconButton, Chip, Collapse, styled, CircularProgress, Autocomplete,
	Breadcrumbs
} from "@mui/material";
import colors, { buttonClasses, textClasses, inputClasses } from '../../../static/styles/theme';
import CustomModel from '../../Components/CustomModels/CustomModel1';
import { DatePicker } from '@mui/x-date-pickers'
import EmptyPage from "./EmptyPage";
import { selectStylesOverride } from "../../../utils/util";
import {
	Add, ExpandMore as ExpandMoreIcon, Search, BusinessCenter, Download, KeyboardArrowDown,
	Close, ArrowForwardIos, ArrowBackIos, ArrowBackIosNew, FiberManualRecord as FiberManualRecordIcon
} from "@mui/icons-material";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import {
	getAllJobTypes, getAllJobs, getJobDetailV2, getJobProcessConfig, assignJobToUser, getUsersToAssignJob,
	getJobsStatus, getTemplateHeaders, getJobLocationDetail, getJobPendencyDetail,
	approveJobCompletion, endJobFunction,
	getSuggestionLocation,
} from "../../../redux/actions/Job";
import { getAllWarehouses, getWarehouseFloors,getCompany } from '../../../redux/actions';
import Badge from '@mui/material/Badge';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import theme from "../../../static/styles/theme";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from '@mui/material';
import GenericTable from "../../Components/CustomTable/GenericTable"
import GenericCustomiseHeader from "../../Components/Generic/GenericCustomiseHeader";
import { useDebounce } from "../../../utils/hooks";
import { dateTimeFormater, capitalizeSentence, timeConversionFunction, showToast, dataReadFunction, textCapitalize, renderHeadersFilter, truncateNumber } from "../../../services/functions";
import { getAllUsers } from "../../../redux/actions";
import { getLabelKeyForJobConfig } from "./Constant";
import GenericFilterSection from "../../Components/Generic/GenericFilterSection";
import GenericLoader from "../../Components/Generic/GenericLoader";
import { b64toBlob } from "../../../utils/services";
import AlertDialog from "../../Components/DialogBox/dialogbox";
import CustomChip from "../../Components/CustomChip";
import ViewWorkOrderList from "./viewWorkOrderList";
import StartJobInward from "./StartJobInward";
import StartJobDispatch from "./StartJobDispatch";
import { multiDateToISOConvertor } from "../../Components/Generic/GenericMultiDateRange";


function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const statusColor = {
	ongoing: "#FF7200",
	pending: "#FCCB3E",
	failed: "#E9001A",
	completed: "green",
};
const tabStautusMap = {
	0: "",
	1: "pending",
	2: "ongoing",
	3: "completed",
};

const initFilters = {
	"search": {},
    "other": {},
	page: 1,
	limit: 10,
	// jobStatus: "",
	// jobName: "",
	// jobType: "",
	// createdOn: null,
	// completedOn: null,
	// jobId: "",
	// shipmentId: "",
};




const initail_pendency = { pendencyType: "", items: [], title: "", pendencyColumn: "" }

export default function JobModule() {
	const dispatch = useDispatch();
    const muiTheme = useTheme();
    const isMd = useMediaQuery(muiTheme.breakpoints.up('md'));
    const isLg = useMediaQuery(muiTheme.breakpoints.up('lg'));
    const isSm = useMediaQuery(muiTheme.breakpoints.between('sm', 'md'));
	const history = useHistory();
	const loggedInUser = JSON.parse(localStorage.getItem("user")) || {};
	// let {jobId} = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const jobId = queryParams.get('jobId');
	const allWarehouses = useSelector((state) => state.inward.allWarehouses);
    const allFloors = useSelector((state) => state.inward.allFloors);
	const jobs = useSelector((state) => state.job.jobs.data);
	const jobCount = useSelector((state) => state?.job?.jobs?.totalCount);
	const { jobDetail} = useSelector((state) => state.job);
	const jobTypes = useSelector((state) => state?.job?.job_types?.data || []);
	const statusCount = useSelector((state) => state.job.jobs.statusCount);
	const allJobTypeName = jobTypes.map((jt) => ({ label: capitalizeSentence(jt.name), value: jt._id, _id: jt._id }));
	const { AllUserData } = useSelector((state) => state.users);
	const { userPermissions, sidebarList = [] } = useSelector((state) => state.userPermission);
	const { jobProcessConfig } = useSelector((state) => state?.job);//{jobCreationCategory=[]}

	const [tabIndex, setTabIndex] = useState(0);
	const [isViewDetail, setIsViewDetail] = useState("");
	const [checkedRow, setCheckedRow] = useState({ visible: false, selectedRow: [], type: "" });
	const [pendencyDetail, setPendencyDetail] = useState(initail_pendency)
	const [loader, setLoader] = useState(true);
	const [user, setUser] = useState({});
	const [condition, setCondition] = useState(true);
	const [filter, setFilter] = useState({ ...initFilters });
	const debouncedJobId = useDebounce(filter.jobId, 500);
	const debouncedShipmentId = useDebounce(filter.shipmentId, 500);
	const debouncedFilterSearch = useDebounce(filter?.search, 500);
	const [openAdvanceFilter, setOpenAdvanceFilter] = useState({right:false});
	const [advancedTempFilter, setAdvancedTempFilter] = useState(filter);
	const [warehouseFilter, setWarehouseFilter] = useState(null);
    const [floorFilter, setFloorFilter] = useState(null);
	let unassignedJob = [];
	(jobs || []).map((info, index) => {
		let { jobApprovalDetail = false, assignedTo = false, status, jobScanningType = "" } = info?.commonDetails || {}
		let condition1 = (!assignedTo || !assignedTo._id) && status == "pending"
		if (condition1) {
			unassignedJob.push({ groupBy: info?._id?.groupBy, isForApproval: false, isForUser: true })
		}
		else if (jobApprovalDetail?.isApprovalRequired && status == "completed" && !jobApprovalDetail.isApproved && Number(jobApprovalDetail?.allApporvals > 0) > 0) {
			unassignedJob.push({ groupBy: info?._id?.groupBy, isForApproval: true, isForUser: false })
		}
		let pendingCondition = status === "pending"
		if (status == "ongoing") {
			pendingCondition = assignedTo._id == loggedInUser?._id;
		}
		if (pendingCondition && (["gateIn"].includes(info?.items?.[0]?.jobType) || (info?.items?.[0]?.jobType == "sendToUser" && jobScanningType == "restricted"))) {
			if (condition1) {
				unassignedJob.splice(index, 0, {
					...unassignedJob[index],
					isForJobCompletion: true,
					floorId: info?.commonDetails?.shipmentDetail?.at(0)?.floorId,
					jobComponentId: info?.items?.at(0)?._id,
					jobType: info?.items?.at(0)?.jobType,
				});
			}
			else {
				unassignedJob.push({
					groupBy: info?._id?.groupBy,
					floorId: info?.commonDetails?.shipmentDetail?.[0]?.floorId,
					jobType: info?.items?.at(0)?.jobType,
					jobComponentId: info?.items?.at(0)?._id,
					isForJobCompletion: true
				})
			}
		}
	})

	const toggleDrawer = (anchor, open) => (event) => {
		if (
		  event &&
		  event.type === 'keydown' &&
		  (event.key === 'Tab' || event.key === 'Shift')
		) {
		  return;
		}
	
		setOpenAdvanceFilter({ ...openAdvanceFilter, [anchor]: open });
	  };

	const setPageHandler = (pageNo = 0) => {
        // setFilter({ ...filter, page: pageNo + 1 });
    }

    const setLimitHandler = (pageLimit = 10) => {
        // setFilter({ ...filter, limit: pageLimit });
    }
	const handleTabChange = (event, value) => {
		setTabIndex(value);
		const jobStatus = tabStautusMap[value];
		setFilter((f) => ({ ...f, jobStatus }));
		setIsViewDetail("")
		dispatch(getJobsStatus({ type: "all" }))
	};

	const handleRowSelection = (event, selected = {}) => {
		let checked = event.target.checked
		let temp_checkedRow = { ...checkedRow, type: "", visible: false }
		if (!temp_checkedRow["selectedRow"]) temp_checkedRow["selectedRow"] = []
		if (checked) {
			temp_checkedRow["selectedRow"].push(selected)
		}
		else {
			temp_checkedRow["selectedRow"] = temp_checkedRow["selectedRow"].filter(dl => dl?.groupBy != selected?.groupBy)
		}
		// console.log("temp_checkedRow",temp_checkedRow)
		setCheckedRow(temp_checkedRow)
	}

	const handleRowAllSelection = (event) => {
		let temp_checkedRow = { ...checkedRow, type: "", visible: false }
		let checked = event.target.checked
		if (!temp_checkedRow["selectedRow"]) temp_checkedRow["selectedRow"] = []
		if (checked) {
			let tempFilterJob = unassignedJob.filter(el => !temp_checkedRow?.selectedRow?.map(dl => dl.groupBy).includes(el.groupBy))
			let allJobId = [...tempFilterJob, ...temp_checkedRow["selectedRow"]]
			temp_checkedRow["selectedRow"] = allJobId
		}
		else {
			temp_checkedRow["selectedRow"] = []
		}
		// console.log("temp_checkedRow",temp_checkedRow)
		setCheckedRow(temp_checkedRow)
	}

	const tableHeader = useSelector((state) => state.customHeader["jobTable"]);
	let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
		if (h.handleType === "selectRow") {
			if (unassignedJob?.length > 0 && userPermissions?.permissions?.some(dl => ["jobReAssign", "approveJobCompletion", "manualJobEnd"].includes(dl?.permissionId))) {
				return(
					< Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
						<Checkbox
							checked={unassignedJob?.length > 0 ?
								unassignedJob?.every(dl => checkedRow?.selectedRow?.map(el => el.groupBy)?.includes(dl?.groupBy))
								: false
							}
							onClick={(e) => handleRowAllSelection(e)}
							size='small'
						/>
					</Typography >
				)
			
			}
			else{
				return("")
			}
		}
		return h.fieldName
	})
		|| [];

	const getTableCell = (header, row, i) => {
		let value = ""
		if (header?.mappingPath) {
			value = dataReadFunction({ name: header?.mappingPath || "", renderOption: header?.renderOption || [] }, row)
		}
		if (header?.handleType) {
			if (header?.handleType === "selectRow") {
				if (unassignedJob?.length > 0 && userPermissions?.permissions?.some(dl => ["jobReAssign", "approveJobCompletion", "manualJobEnd"].includes(dl?.permissionId))) {
					let isForApproval = (row?.commonDetails?.jobApprovalDetail?.isApprovalRequired || false) && !(row?.commonDetails?.jobApprovalDetail?.isApproved || false) && (row?.commonDetails?.jobApprovalDetail?.allApporvals || 0) > 0 && row?.commonDetails?.status == "completed"
					let isForUser = (!row?.commonDetails?.assignedTo || !row?.commonDetails?.assignedTo?._id) && row?.commonDetails?.status == "pending"
					let pendingCondition = row?.commonDetails?.status === "pending"
					if (row?.commonDetails?.status == "ongoing") {
						pendingCondition = row?.commonDetails?.assignedTo._id == loggedInUser?._id;
					}
					let isForJobCompletion = pendingCondition && (["gateIn"].includes(row?.items?.[0]?.jobType) || (row?.items?.[0]?.jobType == "sendToUser" && row?.commonDetails?.jobScanningType == "restricted"))
					if (isForApproval || isForUser || isForJobCompletion) {
						return (< Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
							<Checkbox
								data-testid="checkbox"
								size='small'
								key={row?._id?.groupBy + "/" + i}
								disabled={!isForUser && !isForApproval && !isForJobCompletion}
								checked={checkedRow?.selectedRow?.some(dl => dl?.groupBy == row?._id?.groupBy)}
								onClick={(e) => handleRowSelection(e, {
									groupBy: row?._id?.groupBy, isForUser, isForApproval, isForJobCompletion,
									floorId: row?.commonDetails?.shipmentDetail?.at(0)?.floorId || "",
									jobType: row?.items?.at(0)?.jobType || "",
									jobComponentId: row?.items?.at(0)?._id || ""
								})}
							/>
						</Typography >)
					}
					else {
						return ("")
					}
				}
			}
			else if (header?.handleType === "showDetail") {
				return (
					<Typography
						// onClick={() => handleRowAndViewClick(row)}
						onClick={() => {
							dispatch(getJobDetailV2({ jobId: row?._id?.groupBy, call_from: "web", page: 1, limit: 1 }));
							setIsViewDetail(row?._id?.groupBy);
						}}
						style={{
							...textClasses.normalText,
							color: theme.themeOrange,
							textAlign: "center",
							cursor: "pointer",
						}}
					>
						{value ?? "-"}
					</Typography>
				);
			}
			else if (header?.handleType === "handleAssignedTo") {
				return (
					row?.commonDetails?.assignedTo?._id ?
						<Typography
							sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
						>
							{value ||  "-"}
						</Typography>
						:
						userPermissions?.permissions?.some(dl => ["jobReAssign"].includes(dl?.permissionId)) && row?.commonDetails?.status == "pending"
							?
							<Typography
								variant="caption"
								key={row?._id?.groupBy}
								gutterBottom
								// onClick={() => setCheckedRow({ visible: true, selectedRow: [{ groupBy, isForApproval: false, isForUser: true }], type: "assignTrigger" })}
								sx={{
									...buttonClasses.lynkitOrangeFill,
									pr: 1, pl: 1,
									fontWeight: "500",
									border: `1px solid ${theme.themeWhite}`,
									// color: theme.themeWhite,
									// backgroundColor: theme.themeOrange,
									// borderRadius: "12px",
									// fontWeight: "500",
									cursor: "pointer",
								}}
							>
								Assign Job
							</Typography>
							: "-"
				);
			}
			else if (header?.handleType === "viewPendency") {
				return (
					<Typography sx={{	...textClasses.t13n,
						color: value ? theme.themeOrange : "black",
						textAlign: "center",
						cursor: value ? "pointer" : "" }}
						onClick={() => {
							header?.fieldName == "Package Count" ?
								setPendencyDetail({ pendencyType: "packageWise", items: row?.items, title: "Package Wise Pendency", pendencyColumn: getLabelKeyForJobConfig(jobProcessConfig, "package", "label") }) :
								header?.fieldName == "Shipment Count" ?
									setPendencyDetail({ pendencyType: "shipmentWise", items: row?.items, title: "Shipment Wise Pendency", pendencyColumn: getLabelKeyForJobConfig(jobProcessConfig, "shipment", "label") }) :
									setPendencyDetail({ pendencyType: "itemWise", items: row?.items, title: "SKU Wise Pendency", pendencyColumn: getLabelKeyForJobConfig(jobProcessConfig, "item", "label") });
						}}>
						{value || 0}
					</Typography>
				)
			}
			else if (header?.handleType === "action") {
				return (
					<Typography
						data-testid="dropdown"
						sx={{
							...textClasses.normalText,
							color: header?.color || theme.themeOrange,
							cursor: "pointer",
						}}
					// onClick={(e) => handleOpenAction(e, i)}
					>
						Action <KeyboardArrowDown />
					</Typography>
				);
			}
		}
		else if (header?.isFromRenderFunction === true) {
			let no;
			if (filter?.page === 0) {
				no = i + 1;
			} else {
				no = (filter?.page - 1) * filter?.limit + (i + 1);
			}
			if (header?.renderFunction === "srNo") {
				return (
					<Typography
						variant="caption"
						display="block"
						sx={{ textAlign: "center" }}
						gutterBottom

					>
						<FiberManualRecordIcon sx={{
							color: row?.commonDetails?.jobApprovalDetail?.rejectedBy?._id
								? theme.themeRed
								: (row?.commonDetails?.status == "completed" && ((row?.commonDetails?.jobApprovalDetail?.isApproved || true) || (row?.commonDetails?.jobApprovalDetail?.allApporvals || 0) == 0)) ? theme.themeGreen : theme.themeYellow
						}}
						/>						{no}
					</Typography>
				);
			}
			else if (header?.renderFunction === "status") {
				// let option = header?.values?.find(o => o.value == value) || {}
				return (
					<>
						<Typography
							variant="caption"
							// display="block"
							gutterBottom
							sx={{
								pr: 1, pl: 1,
								color: statusColor[row?.commonDetails?.status],
								fontWeight: "500",
								border: `1px solid ${statusColor[row?.commonDetails?.status]
									}`,
								borderRadius: "12px",
								fontWeight: "500",

							}}
						>
							{/* {option?.label || "-"} */}
							{row?.commonDetails?.status == "completed" && !(row?.commonDetails?.jobApprovalDetail?.isApproved || false) &&
								(row?.commonDetails?.jobApprovalDetail?.allApporvals || 0) > 0
								? "Under Approval"
								: textCapitalize(row?.commonDetails?.status || "-")
							}

						</Typography>
					</>
				);
			}
			
		}
		else {
			if (header?.fieldType === "array") {
				return (
					<Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
						{value && Array.isArray(value) ? value?.filter(dl=>!!dl).join(", ") : value || "-"}
						</Typography>
				);
			}
			else if (header?.fieldType === "date") {
				return (
					<Typography
						sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
					>
						{value ? dateTimeFormater(value) : "-"}
					</Typography>
				);
			}
			else {

				return (
					<Typography
						sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
					>
						{(value) || "-"}
					</Typography>
				);
			}
		}
	};

	const tableRows = jobs?.map((row, i) => {
		let items = [];

		const validHeaders = tableHeader?.validHeader ?? [];
		for (const header of validHeaders) {
			items.push(getTableCell(header, row, i));
		}
		return items;
	});

    const isFilterApplied = (() => {
        if (tabIndex == 0 && filter?.other?.status) {
            return true;
        }
        let {status,...restOther}= { ...filter["other"] || {} }
        // delete other["status"]
        if (Object.values(restOther || {})?.some(dl => dl && (typeof dl == "object" && !Array.isArray(dl)? dl?.value : dl.length > 0)) || Object.values(filter["search"]).some(dl => dl && (typeof dl == "object" && !Array.isArray(dl)? dl?.value : dl.length > 0))) {
            return true;
        }
        return false;
    })();

	const handleClearFilter = () => {
        let tempFilter = { ...initFilters }
        tempFilter["jobStatus"] = tabIndex == 0 ? null : filter?.jobStatus || ""
        // tempFilter["other"]["status"] = tabIndex == 0 ? null : filter?.other?.status || ""
        setFilter(tempFilter)
		setIsViewDetail("")
    };


	const searchableFilterItems = useMemo(() => {
		if (!tableHeader || !tableHeader.validHeader) return [];
		return tableHeader.validHeader.filter((dl) => dl?.isSearchable) || [];
	  }, [tableHeader]);
	
	

	const refreshTableData = (pageNo, limitNo, fetchCount = false,makeInitial=false) => {

        setLoader(true);

        const payload = {
            page: pageNo,
            limit: limitNo,
			jobStatus: filter?.jobStatus,
			warehouseId:warehouseFilter ? warehouseFilter._id : "",
            floorId: floorFilter ? floorFilter._id : "",
        }
		let allDynamicKeys= tableHeader?.validHeader?.filter(dl=>dl?.dynamic)?.map(dl=>dl?.payloadKey || dl?.mappingPath)
        if(makeInitial){
            setFilter({...initFilters,page: pageNo,limit: limitNo,})
        }
		else {
			// console.log(filter,"filter")
			if (filter && Object.keys(filter || {}).length > 0) {
				for (let key in filter) {
					if (key && Object.keys(filter[key] || {}).length > 0) {
						for (let filterKey in filter[key]) {
							// if (filterKey && Object.keys(filter[key][filterKey] || {}).length > 0) {
							if (allDynamicKeys?.includes(filterKey)) {
								// Object.assign(payload, filter[key][filterKey])
								payload[filterKey] = filter[key][filterKey]?.value || ""

							} else {
								payload[filterKey] = filter[key][filterKey] || ""
							}
						}
					}
				}
				// Process date filter items
				const dateFilterItems = searchableFilterItems?.filter(
					(item) => item?.searchInputType === "date"
				);
				dateFilterItems.forEach((element) => {
					const payloadKey = element?.payloadKey || element?.mappingPath || "";
					if (payloadKey && payload[payloadKey]) {
						payload[payloadKey] = multiDateToISOConvertor(payload[payloadKey]);
					}
				});
			}
		}
		setIsViewDetail("")
		dispatch(getAllJobs(payload, () => {
			setLoader(false)
			if (fetchCount) dispatch(getJobsStatus({ type: "all",
                warehouseId: warehouseFilter ? warehouseFilter._id : "",
                floorId: floorFilter ? floorFilter._id : "",
            }))
			if (payload.makeInitial) {
				setFilter({ ...filter, initail: false, makeInitial: false })
			}

		}));
        // delete payload.status
		// if (fetchCount) dispatch(getJobsStatus({ type: "all" }))

    }


	const get_Job_All = (filer_d, fetchCount = false) => {
		refreshTableData(filer_d?.page,filer_d?.limit,fetchCount)
	}

	
	const list = (anchor) => (
<Grid container sx={{ mt: 6, width: isLg ? '20vw' : '30vw', p: 2 }} spacing={2}>
{renderHeadersFilter(
			  tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath), 
			  advancedTempFilter, 
			  (updatedTempFilter) => {
				setAdvancedTempFilter({...updatedTempFilter}); 
			  },
			  setPageHandler, 
			  setLimitHandler, 
			  tabIndex, 
			  true
			)}
	
		
		  <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
			<Grid item >
			  <Button
				data-testid="clearall-btn"
				variant="outlined"
				size="small"
				sx={{
				  ...buttonClasses.outlined,
				  color: theme.themeOrange,
				  minWidth: "150px",
				}}
				onClick={() => {
				  // Optionally clear temp filters here if needed
				  setAdvancedTempFilter(initFilters);
				  toggleDrawer(anchor, false)();
				}}
			  >
				Clear All
			  </Button>
			</Grid>
			<Grid item>
			  <Button
				data-testid="submit-btn"
				variant="contained"
				size="small"
				sx={{
				  ...buttonClasses.small,
				  minHeight: "40px",
				  backgroundColor: theme.themeOrange,
				  minWidth: "150px",
				}}
				onClick={() => {
				  setFilter({ ...advancedTempFilter, page: 1, limit: 10 });
				  toggleDrawer(anchor, false)(); 
				}}
			  >
				Apply Filter(s)
			  </Button>
			</Grid>
		  </Grid>
		  </Grid>
	  );

	// const endJobAPI=()=>{
	// 	let payload={
	// 		"jobComponentId": "66584194a053d027887ac9c4",
	// 		"location": [
	// 			{
	// 				"count": 1, //give count in which u want to perform job
	// 				"shipmentId": "C-1900-S-160124/P2/I1"
	// 			}
	// 		]
	// 	}
	// 	for(let i=0;i<10;i++){
	// 		dispatch(endJobFunction(payload))
	// 	}
	// }


	useEffect(() => {
		
		if (!condition) {
			get_Job_All(filter, false)
		}
	}, [filter.other, debouncedFilterSearch, filter.page, filter.limit, filter.jobStatus, warehouseFilter, floorFilter]);

	useEffect(() => {
		
		if (!condition) {
			get_Job_All(filter, true)
		}
	}, [warehouseFilter, floorFilter]);

	useEffect(() => {
		if (condition || location) {
			setLoader(true)
			if (jobId) {
				dispatch(getJobDetailV2({ jobId, call_from: "web", page: 1, limit: 1 }, () => {
					setLoader(false)
				}));
				setIsViewDetail(jobId);
			}
			else {
				get_Job_All(initFilters, true)
				// get_Job_All({ ...initFilters, jobId }, !jobId)
			}
			dispatch(getAllJobTypes({ fetch_for: "getForFilter" }));
			dispatch(getAllUsers({ "approved": "all", "isAdminIncluded": true }));
			dispatch(getJobProcessConfig({ approved: true }));
			setCondition(false)
		}
	}, [location]);

	useEffect(() => {
		let temp = {}
		if (AllUserData?.data?.length) {
			for (let ele of AllUserData?.data) {
				temp[ele._id] = { name: ele.name, designation: ele.designation, email: ele.email }
			}
		}
		setUser(temp)
	}, [AllUserData]);

	useEffect(()=>{
        dispatch(getAllWarehouses({ type: "warehouse" }));
      },[])

      useEffect(() => {
        if (warehouseFilter) {
          dispatch(
            getWarehouseFloors({ type: "floor", warehouseId: warehouseFilter?._id })
          );
        }
      }, [warehouseFilter]);

	return (
		<Grid>
			{false ? (
				<EmptyPage />
			) : (
				<Box sx={{ flexShrink: 1 }}>
					<Grid container data-testid="job-dashboard">
						<Grid item sm={jobId ? 10 : 4} data-testid="job-header">
							<Typography sx={{ ...textClasses.cardTitle }}>
								{/* {jobId
									? "Job Detail"
									: "Manage Warehouse Jobs"
								} */}
								Manage Warehouse Jobs
							</Typography>
						</Grid>
							
						{!jobId && 
						<Grid
							container
							item
							sm={8}
							// xs={9}
							justifyContent="end"
							alignItems="end"
							gap={1}
						>
							<Autocomplete
								value={warehouseFilter}
								onChange={(e, option) => {
									setWarehouseFilter(option);
									setFilter({ ...filter, page: 1, limit: 10 });
									setFloorFilter(null)
								}}
								getOptionLabel={(option) => option.name}
								size="small"
								options={allWarehouses}
								sx={{
									...inputClasses.filterField,
									minWidth: "250px",
									mr: 1,
									".MuiInputBase-input": {
										fontSize: "15px",
										color: "#455A64",
									},
								}}
								renderInput={(params) => (
									<TextField
										sx={{ color: "#455A64", ...textClasses.normalText }}
										{...params}
										label="All Warehouse"
									/>
								)}
							/>
							{warehouseFilter && (
								<Autocomplete
									value={floorFilter}
									onChange={(e, option) => {
										setFloorFilter(option);
										setFilter({ ...filter, page: 1, limit: 10 });

									}}
									getOptionLabel={(option) => option.name}
									size="small"
									options={allFloors}
									sx={{
										...inputClasses.filterField,
										minWidth: "250px",
										mr: 1,
										".MuiInputBase-input": {
											fontSize: "15px",
											color: "#455A64",
										},
									}}
									renderInput={(params) => (
										<TextField
											sx={{ color: "#455A64", ...textClasses.normalText }}
											{...params}
											label="Select Floor"
										/>
									)}
								/>
							)}
							{userPermissions?.permissions?.find(dl => dl.permissionId == "viewJobType") && <Button
								variant="contained"
								size="small"
								startIcon={<BusinessCenter />}
								data-testid="viewJob-TypeButton"
								sx={buttonClasses.lynkitOrangeEmpty}
								onClick={() => {
									history.push("/dashboard/job/viewJobTypes")
								}}
							>
								View Job Types
							</Button>}
							{userPermissions?.permissions?.find(dl => ["createPickPackJob", "createPutAwayJob", "createStockTranferJob", "createReturnSellerJob", "createDispatchJob", "createNewJob"].includes(dl.permissionId)) && <Button
								variant="contained"
								size="small"
								data-testid="create-job-Button"
								startIcon={<Add />}
								sx={{
									...buttonClasses.lynkitOrangeFill,

								}}
								onClick={() => {
									history.push("/dashboard/jobs/create-job");
								}}
							>
								Create New Job
							</Button>}
						</Grid>}
					</Grid>

					{!jobId && <Box
						sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}
					>
						<Tabs
							value={tabIndex}
							aria-label="disabled tabs example"
							onChange={handleTabChange}
							style={{ height: "20px" }}
							sx={{
								"& .MuiTabs-indicator": {
									backgroundColor: theme.themeOrange,
								},
								"& .MuiTab-root.Mui-selected": {
									color: theme.themeOrange,
								},
							}}
							data-testid="job-tabs"
						>
							<Tab
								label={`All Jobs (${statusCount ? statusCount.total : 0
									})`}
								{...a11yProps(0)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
							<Tab
								label={`Pending Jobs (${statusCount ? statusCount.pending : 0
									})`}
								{...a11yProps(1)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
							<Tab
								label={`Ongoing Jobs (${statusCount ? statusCount.ongoing : 0
									})`}
								{...a11yProps(1)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
							<Tab
								label={`Completed Jobs (${statusCount ? statusCount.completed : 0
									})`}
								{...a11yProps(1)}
								sx={{ ...textClasses.normalText, textTransform: "none" }}
							/>
						</Tabs>
					</Box>}
					{!jobId && statusCount && statusCount.total ?
						<Grid container spacing={2} xs={12}>
								<GenericFilterSection
									validHeader={tableHeader?.validHeader?.filter(dl => dl.isSearchable && dl?.mappingPath)}
									filter={filter}
									setFilter={(tempFilter) => {
										setFilter({ ...tempFilter, page: 1, limit: 10 })
									}}
									setPage={setPageHandler}
									setLimit={setLimitHandler}
									tabIndex={tabIndex}
									advancedFilterPart={false}
								/>
                 
          
							{/* {viewClearButton() &&
								<Grid container item alignItems={"center"} xs={"auto"} data-testid="job-clear-filter">
									<Button type="button">
										<Typography
											sx={{
												...textClasses.boldText,
												color: theme.themeOrange, textDecoration: "underline", cursor: "pointer",
												textTransform: "none"
											}}
											onClick={() => {
												setFilter({ ...initFilters, jobStatus: filter.jobStatus });
												setIsViewDetail("")
												if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(filter["other"]).some(dl => dl && dl.length > 0)) {
													return true;
												}
												// setTabIndex(0);
												// setFilter({...initFilters,jobStatus:filter.jobStatus});
												// setTabIndex(
												// 	!jobStatus
												// 		? 0
												// 		: jobStatus == "pending"
												// 			? 1
												// 			: jobStatus == "ongoing"
												// 				? 2
												// 				: 3
												// );
											}}
										>
											Clear Filter(s)
										</Typography>
									</Button>
								</Grid>
							} */}
							<Grid container item xs={"auto"} alignItems={"flex-start"}>
								<GenericCustomiseHeader
									data-testid="customise-table"
									tableName={"jobTable"}
									tableType={"jobTable"}
								/>
							</Grid>
							{isFilterApplied && (
                            <Grid container item xs={"auto"}  alignItems={"center"}>
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleClearFilter}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        )}
						</Grid>
						: ""
					}

					{
						!jobId &&
						<>
							{checkedRow?.selectedRow?.some(dl => dl.isForUser) && userPermissions?.permissions?.some(dl => dl?.permissionId == "jobReAssign") &&

								<Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "assignTrigger" })}
								>
									Assign Job
								</Button>
							}
							{
								userPermissions?.permissions?.find(dl => dl.permissionId == "manualJobEnd") &&
								checkedRow?.selectedRow?.length == 1 && checkedRow?.selectedRow?.some(dl => dl?.isForJobCompletion) &&
								<Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: checkedRow?.selectedRow?.at(0)?.jobType })}
								>
									Start Job
								</Button>
							}
							{checkedRow?.selectedRow?.length == 1 && checkedRow?.selectedRow?.some(dl => dl.isForApproval) &&
								userPermissions?.permissions?.some(dl => dl?.permissionId == "approveJobCompletion") &&

								<Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "approveTrigger" })}
								>
									Approve Job
								</Button>
							}
							{checkedRow?.selectedRow?.length == 1 && checkedRow?.selectedRow?.some(dl => dl.isForApproval) &&
								userPermissions?.permissions?.some(dl => dl?.permissionId == "approveJobCompletion") && <Button
									size="small"
									variant="contained"
									sx={{
										...buttonClasses.lynkitOrangeFill,
										mr: 1, mt: 1,
										textTransform: "none",
									}}
									onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "rejectTrigger" })}
								>
									Reject Job
								</Button>
							}
						</>
					}
					{
						jobId &&
						<Grid container item sm={9} xs={6}>
							<Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-viewkit">
								<Typography sx={{ ...textClasses.t13n, color: theme.themeOrange }}>
									<span style={{ color: theme.themeOrange, textDecoration: "none", cursor: 'pointer' }} onClick={() => history.goBack()}>
										Job Detail
									</span>
								</Typography>
								<Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>Job Id - {jobId}</Typography>
							</Breadcrumbs>
						</Grid>
					}
					{/*Complete Job UI */}
					{checkedRow?.visible == true && ["gateIn"].includes(checkedRow?.type) &&
						<StartJobInward
							checkedRow={checkedRow}
							setCheckedRow={setCheckedRow}
							get_Job_All={get_Job_All}
							initFilters={initFilters}
						/>
					}

					{checkedRow?.visible == true && ["sendToUser"].includes(checkedRow?.type) &&
						<StartJobDispatch
							checkedRow={checkedRow}
							setCheckedRow={setCheckedRow}
							get_Job_All={get_Job_All}
							initFilters={initFilters}
						/>
					}
					<Grid container sx={{ mt: 2 }}>
						{loader
							? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>

								<Grid item><GenericLoader /></Grid>
							</Grid>
							: ""
						}
						{!loader && !jobId ?
							<Grid
								item
								md={jobId ? 12 : isViewDetail ? 8 : 12}
								sm={12} xs={12} order={{ xs: 2, sm: 2, md: 1 }}
								sx={{ display: { xs: isViewDetail ? "none" : "visible", sm: isViewDetail ? "none" : "visible", md: "unset" } }}
							// xs={12}
							// sx={{ transition: "all 1s" }}
							>
								<GenericTable
									data-testid="job-table"
									header={header}
									rows={tableRows}
									pageCount={jobCount}
									pageNumber={filter.page - 1}
									handleChangePage={(event, pagevalue) => {
										setFilter((f) => ({
											...f,
											page: pagevalue + 1,
										}));
									}}
									handleChangeRowsPerPage={(event) => {
										setFilter((f) => ({
											...f,
											limit: +event.target.value,
										}));
									}}
									rowsPerPage={filter.limit}
								/>
							</Grid>
							: ""
						}
						{isViewDetail && Object.keys(jobDetail).length ? (
							<JobDetail
								data-testid="job-card-detail"
								setIsViewDetail={setIsViewDetail}
								isViewDetail={isViewDetail}
								allJobTypeName={allJobTypeName}
								user={user}
								jobProcessConfig={jobProcessConfig}
								AllUserData={AllUserData}
								viewPageRender={!!jobId}
								checkedRow={checkedRow}
								setCheckedRow={setCheckedRow}
							/>
						) : null}
						{pendencyDetail && pendencyDetail?.pendencyType && pendencyDetail?.items?.length > 0 ? (
							<PendencyDetailView
								data-testid="pendency-card-detail"
								hide={() => setPendencyDetail(initail_pendency)}
								open={pendencyDetail?.pendencyType && pendencyDetail?.items?.length > 0 || false}
								pendencyDetail={pendencyDetail}
							/>
						) : null}
						{checkedRow?.visible && checkedRow?.selectedRow?.length > 0 && checkedRow?.type == "assignTrigger" ? (
							<AssignJobToUserSection
								setCheckedRow={setCheckedRow}
								checkedRow={{ ...checkedRow, selectedRow: checkedRow?.selectedRow?.filter(dl => dl.isForUser) }}
								setFilter={setFilter}
								setTabIndex={setTabIndex}
								get_Job_All={get_Job_All}
							/>
						) : null}
						{checkedRow?.visible && checkedRow?.selectedRow?.length > 0 && ["rejectTrigger", "approveTrigger"].includes(checkedRow?.type) ? (
							<ApproveJobCompletionSection
								setCheckedRow={setCheckedRow}
								checkedRow={{ ...checkedRow, selectedRow: checkedRow?.selectedRow?.filter(dl => dl.isForApproval) }}
								setFilter={setFilter}
								setTabIndex={setTabIndex}
								get_Job_All={get_Job_All}
							/>
						) : null}
					</Grid>

				</Box>
			)}
		</Grid>
	);
}

const ApproveJobCompletionSection = (props) => {
	let dispatch = useDispatch()
	let { checkedRow, setCheckedRow, setFilter, setTabIndex, get_Job_All } = props
	let [loader, setLoader] = useState(null)

	// console.log("hhhh",checkedRow)

	let approveRejectHandler = () => {
		setLoader(true)
		dispatch(approveJobCompletion({ jobId: checkedRow?.selectedRow?.map(dl => dl.groupBy) || [], approved: checkedRow?.type == "rejectTrigger" ? false : true }, ({ success, message }) => {
			showToast(message, !success)
			setLoader(false)
			if (success) {
				setCheckedRow({ visible: false, selectedRow: [] })
				setFilter({ ...initFilters })
				get_Job_All(initFilters, true)
				setTabIndex(0)
			}
		}))
	}


	return <AlertDialog
		viewDialog={checkedRow?.visible}
		sx={{ borderRadius: "50px" }}
		handleClose={() => setCheckedRow({ ...checkedRow, visible: false })}
		bodyCSS={{ color: theme.themeBlack }}
		actionsCSS={{ marginTop: "-15px" }}
		body={
			<Box sx={{ marginTop: "-10px" }}>
				<Grid container xs={12} >
					<Grid container item xs={12} justifyContent="flex-start">
						<Typography sx={{ color: theme.themeBlack, fontWeight: 600, fontSize: "1.3rem" }} fullWidth>
							{checkedRow?.type == "rejectTrigger" ? "Reject" : "Approve"} Job{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""} Completion
						</Typography>
					</Grid>
				</Grid>
				<Box sx={{ mt: 1 }}>
					{loader
						? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12}>

							<Grid item><GenericLoader /></Grid>
						</Grid>
						: <CustomChip
							data={checkedRow?.selectedRow || []}
							readLabelKey="groupBy"
							makeDisabled={true}
							handleDelete={(index, id) => {
								// console.log(id, "id")
								let tempRowSelect = checkedRow?.selectedRow || []
								tempRowSelect.splice(index, 1)
								setCheckedRow({ ...checkedRow, selectedRow: tempRowSelect })
							}}
						/>
					}
				</Box>
			</Box>
		}
	>
		<Button
			variant="outlined"
			disabled={loader}
			onClick={() => setCheckedRow({ ...checkedRow, visible: false })}
			sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, mt: 0, fontSize: ".7rem", minWidth: "130px" }}
		>Cancel</Button>

		<Button
			autoFocus
			variant="outlined"
			disabled={loader || !checkedRow || !checkedRow.selectedRow || checkedRow.selectedRow.length == 0}
			sx={{
				...buttonClasses.lynkitOrangeFill, m: 1, mt: 0, fontSize: ".7rem", border: 'none', minWidth: "130px",
			}}
			onClick={approveRejectHandler}
		>
			{checkedRow?.type == "rejectTrigger" ? "Reject" : "Approved"}{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""}
		</Button>
	</AlertDialog>
}
const AssignJobToUserSection = (props) => {
	let dispatch = useDispatch()
	let { checkedRow, setCheckedRow, setFilter, setTabIndex, get_Job_All } = props
	let [userList, setUserList] = useState([])
	let [assignTo, setAssignTo] = useState(null)
	let [loader, setLoader] = useState(null)


	let assignUserHandler = () => {
		setLoader(true)
		dispatch(assignJobToUser({ jobId: checkedRow?.selectedRow?.map(dl => dl.groupBy) || [], userId: assignTo?.value }, ({ success, message }) => {
			showToast(message, !success)
			setLoader(false)
			if (success) {
				setAssignTo(null)
				setCheckedRow({ visible: false, selectedRow: [] })
				setFilter({ ...initFilters })
				get_Job_All(initFilters, true)
				setTabIndex(0)
			}
		}))
	}

	useEffect(() => {
		// setLoader(true)
		if (checkedRow?.selectedRow?.length > 0) {
			dispatch(getUsersToAssignJob({ jobId: checkedRow?.selectedRow?.map(dl => dl?.groupBy) || [] }, ({ data }) => {
				setUserList(data?.map(dl => ({ label: (dl.name + ", " + dl.designation), value: dl._id })) || [])
				// setLoader(false)
			}))
		}
		else {
			setUserList([])
		}
	}, [checkedRow.selectedRow])

	return <AlertDialog
		viewDialog={checkedRow?.visible}
		sx={{ borderRadius: "50px" }}
		bodyCSS={{ color: theme.themeBlack }}
		handleClose={() => setCheckedRow({ ...checkedRow, visible: false })}
		actionsCSS={{ marginTop: "-15px" }}
		body={
			<Box sx={{ marginTop: "-10px" }}>
				<Grid container xs={12} >
					<Grid container item xs={12} sm={8.5} justifyContent="flex-start">
						<Typography sx={{ color: theme.themeBlack, fontWeight: 600, fontSize: "1.3rem" }} fullWidth>
							Assign Job{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""} To Worker
						</Typography>
					</Grid>
					<Grid item data-testid="user-select" xs={12} sm={3.5} justifyContent="flex-end">
						<FormControl
							variant="outlined"
							size="small"
							fullWidth
							sx={{ ...selectStylesOverride }}
						>
							<Autocomplete
								size="small"
								disabled={loader}
								labelId="user--select-label"
								renderInput={(params) => (
									<TextField
										{...params}
										label={<Typography sx={{ fontSize: "12px" }}>Select User</Typography>}
										sx={{
											...selectStylesOverride,
											fontSize: "12px",
											"& input::placeholder": {
												fontSize: "9px",
											},
										}}
									/>
								)}
								options={userList}
								noOptionsText="No user found, Which have all permissions for completion of selected job(s)"
								onChange={(e, option) => setAssignTo(option?.value ? option : null)}
								value={assignTo}
								renderOption={(props, option) => (
									<MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
										<Tooltip title={option?.label || ""} placement="top">
											<Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
												{option?.label || ""}
											</Typography>
										</Tooltip>
									</MenuItem>
								)
								}
							/>
						</FormControl>
					</Grid>

				</Grid>
				<Box sx={{ mt: 1 }}>
					{loader
						? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12}>

							<Grid item><GenericLoader /></Grid>
						</Grid>
						: <CustomChip
							data={checkedRow?.selectedRow || []}
							readLabelKey="groupBy"
							makeDisabled={true}
							handleDelete={(index, id) => {
								// console.log(id, "id")
								let tempRowSelect = checkedRow?.selectedRow || []
								tempRowSelect.splice(index, 1)
								setCheckedRow({ ...checkedRow, selectedRow: tempRowSelect })
							}}
						/>
					}
				</Box>
			</Box>
		}
	>
		<Button
			variant="outlined"
			disabled={loader}
			onClick={() => setCheckedRow({ ...checkedRow, visible: false })}
			sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, mt: 0, fontSize: ".7rem", minWidth: "130px" }}
		>Cancel</Button>

		<Button
			autoFocus
			variant="outlined"
			disabled={loader || !checkedRow || !checkedRow.selectedRow || checkedRow.selectedRow.length == 0 || !assignTo || !assignTo?.value}
			sx={{
				...buttonClasses.lynkitOrangeFill, m: 1, mt: 0, fontSize: ".7rem", border: 'none', minWidth: "130px",
			}}
			onClick={assignUserHandler}
		>
			Assign Job{checkedRow?.selectedRow?.length > 1 ? "(s)" : ""}
		</Button>
	</AlertDialog>
}

const PendencyDetailView = (props) => {
	let dispatch = useDispatch();
	const { open, hide,
		pendencyDetail: { items = [], pendencyType = "shipmentWise", pendencyColumn = "Shipment Id", title = "Shipment Wise Pendency" },
	} = props
	const [loader, setLoader] = useState(false)
	const [condition, setCondition] = useState(true)
	const [detail, setDetail] = useState({ data: [], count: 0 })
	const [filter, setFilter] = useState({ page: 1, limit: 10 })
	const [tabIndex, setTabIndex] = useState(0)

	const getPendency = (index = tabIndex) => {
		setLoader(true)
		setDetail({ data: [], count: 0 })
		setTabIndex(index)
		dispatch(getJobPendencyDetail({ jobComponentId: items?.at(index)?._id || "", pendencyType, page: filter.page, limit: filter.limit }, ({ success = false, message, data = [], count = 0 }) => {
			setLoader(false)
			setDetail({ data, count })
		}));
	}

	let allHeader = [
		"S.No",
		`${pendencyColumn}`,
		"Total QTY",
		"Scanned QTY",
		"Remaining QTY"
	]
	if (items?.at(tabIndex)?.jobType == "inventoryAudit") {
		allHeader.pop()
	}

	const getRows = detail?.data && detail?.data.map((element, index) => {
		let row = []
		let { count = 0, scannedCount = 0, shipmentId, packageName = "", SKU_Number = "", uniqueItemCount = 0,
			uniquePackageCount = 0 } = element

		row.push(((filter.page - 1) * (filter.limit)) + index + 1, count, scannedCount, truncateNumber(Number(count) - Number(scannedCount)))

		if (items?.at(tabIndex)?.jobType == "inventoryAudit") {
			row.pop()
		}
		if (pendencyType == "shipmentWise") {
			row.splice(1, 0, shipmentId)
		}
		else if (pendencyType == "packageWise") {
			row.splice(1, 0, `${packageName} (${uniquePackageCount} Package)`)
		}
		else {
			row.splice(1, 0, `${SKU_Number} (${uniqueItemCount} Item)`)
		}
		return row
	})

	useEffect(() => {
		if (items?.length) getPendency(0)
	}, [items])

	useEffect(() => {
		if (!condition) {
			getPendency(tabIndex)
		}
		setCondition(false)
	}, [filter.page, filter.limit]);

	return (
		<>
			<CustomModel
				autoClose={false}
				data-testid={open}
				show={open || false} onClose={() => hide()}
				minWidth={200} maxWidth={800}
				pt={1} pb={3} pl={3} pr={3}
				maxHeight={"90vh"} viewCloseIcon={true}
				childrenMaxHeight={"inherit"}
				// childrenMaxHeight={"85vh"}
				Title={title}
			>
				<Box>
					<Tabs
						value={tabIndex}
						aria-label="disabled tabs example"
						onChange={(event, value) => getPendency(value)}
						style={{ height: "20px" }}
						sx={{
							"& .MuiTabs-indicator": {
								backgroundColor: theme.themeOrange,
							},
							"& .MuiTab-root.Mui-selected": {
								color: theme.themeOrange,
							},
						}}
						data-testid="job-tabs"
					>
						{
							items.map((dl, index) => (
								<Tab
									label={dl.actionName || ''}
									{...a11yProps(index)}
									sx={{ ...textClasses.normalText, textTransform: "none" }}
								/>
							))
						}

					</Tabs>
					{loader
						? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>

							<Grid item><GenericLoader /></Grid>
						</Grid>
						:
						<Box sx={{ width: "inherit" }}>
							<GenericTable
								data-testid="pendency-table"
								header={allHeader}
								rows={getRows}
								pageCount={detail.count || 0}
								pageNumber={filter.page - 1}
								handleChangePage={(event, pagevalue) => {
									setFilter((f) => ({
										...f,
										page: pagevalue + 1,
									}));
								}}
								handleChangeRowsPerPage={(event) => {
									setFilter((f) => ({
										...f,
										limit: +event.target.value,
									}));
								}}
								rowsPerPage={filter.limit}
							/>
						</Box>
					}
				</Box>

			</CustomModel>
		</>
	)
}

const JobDetail = ({ isViewDetail, setIsViewDetail, allJobTypeName, user, jobProcessConfig, AllUserData, viewPageRender = false, checkedRow, setCheckedRow }) => {
	const dispatch = useDispatch();
	const { jobDetail: { data = [], count = 0 } } = useSelector((state) => state.job);
	const template_headers = useSelector((state) => state.job.template_headers);
	const [downloadPdf, setDownloadPdf] = useState(false);
	const [isExpanded, setIsExpanded] = useState({
		jobDetail: true,
		dispatchDetail: false,
		locationDetail: false,
		warehouseDetail: false,
		salesOrderDetail: false,
		kitDetails: false,
		remarks: false,
		userDetail: false
	});
	// console.log(user)
	const [isActiveCard, setIsActiveCard] = useState(1);
	const [locationPage, setLocationPage] = useState({ page: 1, limit: 10 });
	const [loader, setLoader] = useState("");
	const [jobLocation, setJobLocation] = useState({});
	const formDetailHeader = {
		"stockTransfer": 'Stock Transfer Details',
		"sendToUser": "Dispatch Details",
		"picking": "Dispatch Details",
	}

	const {
		_id, jobName, status, jobType, assignedTo, assignedBy, completedBy, result, remark, jobAllocationDate, shipmentDetail, salesOrderDetail,
		jobTemplateDetail = {}, jobTypeId, jobEndDate, totalCount = 0, scannedCount = 0, jobAcceptedTime = "", isOpen = false, jobExtraDetail,
		kitDetails, jobScanningType,actionName=""
	} = data?.length && data[0] || {};
	// console.log("dataa")
	// console.log(data)
	// console.log("Job Extra Detail", jobExtraDetail)
	// console.log("jobType",jobType)

	let extraUserDetail = (() => {
		if (jobExtraDetail && jobExtraDetail.category == "internalUser" && AllUserData && AllUserData.data) {
			let filtered = AllUserData.data.filter(u => {
				return u._id == jobExtraDetail.userId
			})
			if (filtered.length > 0) {
				return filtered[0];
			}
			return null;
		}
		else if (jobExtraDetail && jobExtraDetail.category == "externalUser") {
			return jobExtraDetail;
		}
		return null;
	})();

	// console.log("Enter Detail", extraUserDetail);

	const { templateData = false, templateId = false } = jobTemplateDetail


	const assigned_to = assignedTo?._id
		? capitalizeSentence(user[assignedTo._id]?.name || "") + ", " + capitalizeSentence(user[assignedTo._id]?.designation || "")
		: "-"
	const assigned_by = assignedBy?._id
		? capitalizeSentence(user[assignedBy._id]?.name || "") + ", " + capitalizeSentence(user[assignedBy._id]?.designation || "")
		: "-"
	const completed_by = completedBy?._id
		? capitalizeSentence(user[completedBy._id]?.name || "") + ", " + capitalizeSentence(user[completedBy._id]?.designation || "")
		: "-"
	const created_at = jobAllocationDate && dateTimeFormater(jobAllocationDate) || "-"

	const fetchLocation = ({ jobComponentId, page = 1, limit = 10 }) => {
		setLoader("location")
		dispatch(getJobLocationDetail({ jobComponentId, page, limit }, (output = {}) => {
			// ////("output",output);
			setLoader(false)
			setLocationPage({ page, limit })
			setJobLocation(output)
		}));
	}
	const fetchJobDetail = ({ page = 1, limit = 1 }) => {
		setLoader("jobDetail")
		dispatch(getJobDetailV2({ jobId: isViewDetail, call_from: "web", page, limit }, () => {
			setLoader(false)
			setIsActiveCard(page)
		}));
	}

	const renderRow = (label, value) => {
		return <Box
			sx={{
				display: "flex",
				gap: "0.25em",
				fontSize: "13px",
				justifyContent:
					"space-between",
			}}
		>
			<Typography
				variant="span"
				sx={{
					color: "gray",
				}}
			>
				{label} :
			</Typography>
			<Typography
				variant="span"
				sx={{
					flexBasis:
						"60%",
				}}
			>
				{value}
			</Typography>
		</Box>
	}
	const handleGenerateExcel = () => {
		setLoader("locationSuggestion")
		let payload = {
			jobId: isViewDetail
		}
		dispatch(
			getSuggestionLocation(payload, ({ success = "", message }) => {
				showToast(message, !success);
				setLoader("")
			})
		);
	}
	useEffect(() => {
		if (templateId) {
			dispatch(getTemplateHeaders({ template_id: templateId }));
		}
		if (_id) {
			fetchLocation({ ...locationPage, jobComponentId: _id })
		}
	}, [templateId, _id]);

	useEffect(() => {
		setLoader("all")
		setIsActiveCard(1)
		setLocationPage({ page: 1, limit: 10 })
	}, [isViewDetail]);

	// const getPickListHandler = () => {
	// 	setDownloadPdf(true)
	// 	dispatch(
	// 		generateJobPickList(
	// 			{ jobComponentId: _id },
	// 			({ success, message, data }) => {
	// 				showToast(message, !success);
	// 				if (success && data) {
	// 					let blob = b64toBlob(data, "application/pdf");
	// 					let url = URL.createObjectURL(blob);
	// 					window.open(url, "_blank");
	// 					setDownloadPdf(false)
	// 				}
	// 			}
	// 		)
	// 	);
	// };

	return (
		<>
			<Grid item
				sx={viewPageRender
					? { width: isViewDetail ? "auto" : "0" }
					: { width: isViewDetail ? "0" : "auto" }}
				data-testid="job-detail-dashboard"
				md={viewPageRender ? 12 : 4} sm={12} xs={12} order={{ xs: 1, sm: 1, md: 2 }}
			>
				{/* <Grid item xs sx={{ width: isViewDetail ? "0" : "auto" }} data-testid="job-detail-dashboard"> */}
				<Grid container sx={{ ml: viewPageRender ? 0 : 2 }}>
					{
						loader == "all" ?
							<Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
								<Grid item><GenericLoader /></Grid>
							</Grid>
							:
							<Card sx={{ width: "100%" }}>
								<CardHeader
									// avatar={
									// 	true
									// 	// (["sendToUser", "pickingAndPackaging", "picking", "gateIn", "kitItemPicking", "returnToSeller","kitItemBundeling"].includes(jobType)
									// 	// 	// &&status !== "completed"
									// 	// )
									// 	&&
									// 	<Download
									// 		sx={{ "&:hover": { cursor: downloadPdf === true ? "not-allowed" : "pointer" } }}
									// 		// onClick={() => downloadPdf === false && getPickListHandler()}
									// 		onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "viewWorkOrderList" })}
									// 	/>
										
									// }
									avatar={
										<Box sx={{ display: "flex", gap: 1 }}>
											<Download
												sx={{ "&:hover": { cursor: downloadPdf === true ? "not-allowed" : "pointer" } }}
												onClick={() => setCheckedRow({ ...checkedRow, visible: true, type: "viewWorkOrderList" })}
											/>
											{
												jobType == "gateIn" &&
												<FileOpenIcon
													sx={{ "&:hover": { cursor: loader == "locationSuggestion" ? "not-allowed" : "pointer" } }}
													onClick={handleGenerateExcel}
													disabled={loader == "locationSuggestion"}
												/>
											}

										</Box>
									}
									title={
										<Typography
											data-testid="job-detail-header"
											variant="span"
											sx={{
												display: "flex-inline",
												justifyContent: "center",
												alignItems: "center",
												// gap: "0.5em",
											}}
										>
											Job ID : {isViewDetail || "-"}
											{!viewPageRender && <Chip
												label={capitalize(status || "pending")}
												sx={{
													backgroundColor: "#fff",
													marginLeft: "1em",
													color: statusColor[status || "pending"],
													height: "23px",
													fontWeight: "500",
												}}
											/>}
										</Typography>
									}
									action={
										<>
											{viewPageRender ? <Chip
												label={capitalize(status || "pending")}
												sx={{
													backgroundColor: "#fff",
													marginRight: ".7em",
													color: statusColor[status || "pending"],
													height: "23px",
													fontWeight: "500",
												}}
											/>
												:
												<IconButton
													data-testid="job-detail-action"
													sx={{
														backgroundColor: theme.themeOrange,
														color: "#fff",
													}}
													onClick={() => setIsViewDetail("")}
												>
													<Close />
												</IconButton>
											}
										</>
									}
									sx={{
										backgroundColor: theme.themeOrange,
										color: "#fff",
										padding: "7px 6px"
									}}
								/>
								<CardContent
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: "0.75em",
									}}
									data-testid="job-detail-content"
								>
									{count > 1
										? <Stack spacing={2} sx={{
											display: "flex-inline",
											justifyContent: "center",
											alignItems: "center",
										}}>
											<Pagination
												count={count}
												variant="outlined"
												onChange={(event, value) => fetchJobDetail({ page: value, limit: 1 })}
												shape="rounded"
												page={isActiveCard}

											/>
										</Stack>
										: ""
									}
									{/* Job Detail */}
									<Card data-testid="job-detail-card">
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
													}}
												>
													Job Details
												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.jobDetail}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															jobDetail: !o.jobDetail,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.jobDetail}
											timeout="auto"
											unmountOnExit
										>
											<CardContent
												sx={{
													display: "flex",
													flexDirection: "column",
													gap: ".5em",
												}}
											>
												{
													loader == "jobDetail"
														? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
															<Grid item><GenericLoader /></Grid>
														</Grid>
														:
														<>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Job Name :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{capitalizeSentence(allJobTypeName.find(dl => dl._id == jobTypeId)?.label || "-")}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Job Type :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{actionName || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Scan Type :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobScanningType == "anyInventory" ? "Any Inventory" : "Restricted"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Total Count :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{truncateNumber(totalCount || "0",3,true)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Scanned Count :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{truncateNumber(scannedCount || "0",3,true)}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Result :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{result || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	{" "}
																	Assigned To :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{assigned_to}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}

																>
																	{" "}
																	Job Allocate Date :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobAllocationDate && dateTimeFormater(jobAllocationDate) || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	{" "}
																	Job Accepted Date :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobAcceptedTime && dateTimeFormater(jobAcceptedTime) || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Assigned By :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{assigned_by}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Completed By :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{completed_by}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Job End Date :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{jobEndDate && dateTimeFormater(jobEndDate) || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent: "space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{ color: "gray" }}
																>
																	Total Time Spent :
																</Typography>
																<Typography
																	variant="span"
																	sx={{ flexBasis: "60%" }}
																>
																	{status != "pending" ?
																		timeConversionFunction(`${moment(isOpen ? new Date() : jobEndDate).diff(moment(jobAcceptedTime || jobAllocationDate), 'seconds')}`)
																		: ""
																	}
																</Typography>
															</Box>
														</>
												}
											</CardContent>
										</Collapse>
									</Card>

									{/* Work Order List PDF */}
									{checkedRow?.visible == true && checkedRow?.type == "viewWorkOrderList" &&
										<ViewWorkOrderList
											checkedRow={checkedRow}
											setCheckedRow={setCheckedRow}
											_id={_id}
										/>
									}


									{/* Form Detail */}
									{templateId && Object.keys(templateData).length ? (
										<Card data-testid="job-templateData-card">
											<CardHeader
												title={
													<Typography
														variant="span"
														sx={{
															color: theme.themeOrange,
														}}
													>
														{formDetailHeader[jobType] || "Dispatch Details"}
													</Typography>
												}
												action={
													<ExpandMore
														expand={
															isExpanded.dispatchDetail
														}
														onClick={() => {
															setIsExpanded((o) => ({
																...o,
																dispatchDetail:
																	!o.dispatchDetail,
															}));
														}}
													>
														<ExpandMoreIcon />
													</ExpandMore>
												}
											/>
											<Collapse
												in={isExpanded.dispatchDetail}
												timeout="auto"
												unmountOnExit
											>
												<CardContent
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: ".5em",
													}}
												>
													<RenderFormData
														templateHeader={template_headers}
														templateData={templateData}
													/>
												</CardContent>
											</Collapse>
										</Card>
									) : null}

									{/* Location Detail */}
									{jobLocation?.data?.length ? <Card

										data-testid="job-location-card"
									>
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
														display: "flex-inline",
														justifyContent: "center",
														alignItems: "center",
														// gap: "0.5em",
													}}
												>
													Location Details

												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.locationDetail}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															locationDetail:
																!o.locationDetail,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.locationDetail}
											timeout="auto"
											unmountOnExit
										>
											{jobLocation?.count > 3 ?
												<Stack spacing={2} sx={{
													display: "flex-inline",
													justifyContent: "center",
													alignItems: "center",
												}}>
													<Pagination
														count={Math.ceil(jobLocation?.count / locationPage?.limit)}
														// count={jobLocation?.count || 1}
														// count={10}
														variant="outlined"
														onChange={(event, value) => fetchLocation({ jobComponentId: _id, page: value, limit: locationPage?.limit })}
														shape="rounded"
														// rowsPerPage={locationPage?.limit || 3}
														page={locationPage?.page || 1}
													/>
												</Stack>
												: null
											}
											<Box sx={viewPageRender ? { overflowY: "auto" } : { maxHeight: "500px", overflowY: "auto" }}>
												{
													loader == "location"
														? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
															<Grid item><GenericLoader /></Grid>
														</Grid>
														:
														<RenderLocationData
															location={jobLocation.data || []}
															locationPage={locationPage}
															cellOnly={jobType == "inventoryCheck"}
															jobType={jobType}
															jobProcessConfig={jobProcessConfig}
														/>
												}
											</Box>

										</Collapse>
									</Card> : null}

									{/* Warehouse Detail */}
									<Card data-testid="job-warehouse-card">
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
													}}
												>
													Warehouse Details
												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.warehouseDetail}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															warehouseDetail:
																!o.warehouseDetail,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.warehouseDetail}
											timeout="auto"
											unmountOnExit
										>
											{shipmentDetail?.map((shipment, index) => {
												const {
													warehouseName,
													shipmentId,
													floorName,
												} = shipment;

												return (
													<>
														<CardContent
															sx={{
																display: "flex",
																flexDirection: "column",
																gap: ".5em",
																border: "1px solid #ccc",
																margin: ".5em",
																borderRadius: "12px",
															}}
															key={index}
														>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent:
																		"space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{
																		color: "gray",
																	}}
																>
																	Shipment ID:
																</Typography>
																<Typography
																	variant="span"
																	sx={{
																		flexBasis:
																			"60%",
																	}}
																>
																	{shipmentId || "-"}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent:
																		"space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{
																		color: "gray",
																	}}
																>
																	Warehouse :
																</Typography>
																<Typography
																	variant="span"
																	sx={{
																		flexBasis:
																			"60%",
																	}}
																>
																	{warehouseName}
																</Typography>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	gap: "0.25em",
																	fontSize: "13px",
																	justifyContent:
																		"space-between",
																}}
															>
																<Typography
																	variant="span"
																	sx={{
																		color: "gray",
																	}}
																>
																	Floor :
																</Typography>
																<Typography
																	variant="span"
																	sx={{
																		flexBasis:
																			"60%",
																	}}
																>
																	{floorName || "-"}
																</Typography>
															</Box>
														</CardContent>
													</>
												);
											})}
										</Collapse>
									</Card>

									{
										extraUserDetail &&

										<Card data-testid="job-user-card">
											<CardHeader
												title={
													<Typography
														variant="span"
														sx={{
															color: theme.themeOrange,
														}}
													>
														Stock Transfer Details
													</Typography>
												}
												action={
													<ExpandMore
														expand={isExpanded.userDetail}
														onClick={() => {
															setIsExpanded((o) => ({
																...o,
																userDetail:
																	!o.userDetail,
															}));
														}}
													>
														<ExpandMoreIcon />
													</ExpandMore>
												}
											/>
											<Collapse
												in={isExpanded.userDetail}
												timeout="auto"
												unmountOnExit
											>
												<CardContent
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: ".5em",
														border: "1px solid #ccc",
														margin: ".5em",
														borderRadius: "12px",
													}}
												>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Name :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.name}
														</Typography>
													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Mobile :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.mobile}
														</Typography>

													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Email :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.email}
														</Typography>

													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Address :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.address ? extraUserDetail.address : "-"}
														</Typography>

													</Box>
													<Box
														sx={{
															display: "flex",
															gap: "0.25em",
															fontSize: "13px",
															justifyContent:
																"space-between",
														}}
													>
														<Typography
															variant="span"
															sx={{
																color: "gray",
															}}
														>
															Designation :
														</Typography>
														<Typography
															variant="span"
															sx={{
																flexBasis:
																	"60%",
															}}
														>
															{extraUserDetail.userType ? extraUserDetail.userType : "-"}
														</Typography>

													</Box>
												</CardContent>
											</Collapse>
										</Card>
									}

									{/* Sales Order Detail */}
									{
										salesOrderDetail?.length ?
											<>
												<Card data-testid="sales-detail-card">
													<CardHeader
														title={
															<Typography
																variant="span"
																sx={{
																	color: theme.themeOrange,
																}}
															>
																Sales Order Detail
															</Typography>
														}
														action={
															<ExpandMore
																expand={isExpanded.salesOrderDetail}
																onClick={() => {
																	setIsExpanded((o) => ({
																		...o,
																		salesOrderDetail: !o.salesOrderDetail,
																	}));
																}}
															>
																<ExpandMoreIcon />
															</ExpandMore>
														}
													/>
													<Collapse
														in={isExpanded.salesOrderDetail}
														timeout="auto"
														unmountOnExit
													>
														{salesOrderDetail?.map((sales, index) => {
															const {
																sales_Id,
																salesDetailID,
																count,
															} = sales;

															return (
																<>
																	<CardContent
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			gap: ".5em",
																			border: "1px solid #ccc",
																			margin: ".5em",
																			borderRadius: "12px",
																		}}
																		key={index}
																	>
																		{renderRow("Sales ID", sales_Id ? sales_Id : "-")}
																		{renderRow("Sales Item ID", salesDetailID ? salesDetailID : "-")}
																		{renderRow("Count", count ? count : "0")}
																	</CardContent>
																</>
															);
														})}
													</Collapse>
												</Card>
											</>
											:
											""
									}
									{/* kitDetails */}
									{
										kitDetails?.length ?
											<>
												<Card data-testid="kit-detail-card">
													<CardHeader
														title={<Typography variant="span" sx={{ color: theme.themeOrange, }}>Kit Detail</Typography>}
														action={<ExpandMore expand={isExpanded.kitDetails}
															onClick={() => {
																setIsExpanded((o) => ({
																	...o,
																	kitDetails: !o.kitDetails,
																}));
															}}
														>
															<ExpandMoreIcon />
														</ExpandMore>
														}
													/>
													<Collapse
														in={isExpanded.kitDetails}
														timeout="auto"
														unmountOnExit
													>
														{kitDetails?.map((kit, index) => {
															const {
																KIT_ID,
																requiredSKUs = {},
																requiredKits = {},
																totalKits = 0,
																scannedKits = 0
															} = kit;

															return (
																<>
																	<CardContent
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			gap: ".5em",
																			border: "1px solid #ccc",
																			margin: ".5em",
																			borderRadius: "12px",
																		}}
																		key={index}
																	>
																		{renderRow("Kit ID", KIT_ID ? KIT_ID : "-")}
																		{renderRow("Total Kits", totalKits || "0")}
																		{renderRow("Scanned Kits", scannedKits || "0")}
																		{renderRow(
																			"Per Kit SKUs",
																			Object.entries(requiredSKUs || {})?.map(([SKU, perKitCount]) => `${SKU}(${perKitCount})`)?.join(", ")
																		)}
																		{renderRow(
																			"Build Kit",
																			Object.entries(requiredKits || {})?.filter(([ID, scanned]) => scanned == true)?.map(([ID, scanned]) => ID)?.join(", ")
																		)}
																	</CardContent>
																</>
															);
														})}
													</Collapse>
												</Card>
											</>
											:
											""
									}

									{/* Remark detail */}
									{remark?.length > 0 ? <Card
										data-testid="job-remark-card"
										sx={{ maxHeight: "350px", overflowY: "auto" }}
									>
										<CardHeader
											title={
												<Typography
													variant="span"
													sx={{
														color: theme.themeOrange,
														display: "flex-inline",
														justifyContent: "center",
														alignItems: "center",
														// gap: "0.5em",
													}}
												>
													Remark Details

												</Typography>
											}
											action={
												<ExpandMore
													expand={isExpanded.remarks}
													onClick={() => {
														setIsExpanded((o) => ({
															...o,
															remarks:
																!o.remarks,
														}));
													}}
												>
													<ExpandMoreIcon />
												</ExpandMore>
											}
										/>
										<Collapse
											in={isExpanded.remarks}
											timeout="auto"
											unmountOnExit
										>

											<RenderRamarkData
												user={user}
												remark={remark}
											/>
										</Collapse>
									</Card> : null}
								</CardContent>
								<CardActions></CardActions>
							</Card>
					}
				</Grid>
			</Grid>
		</>
	);
};

const RenderRamarkData = ({ remark, user }) => {


	const renderRow = (label, value) => {
		return <Box sx={{ display: "flex", gap: "0.25em", fontSize: "13px", justifyContent: "space-between" }}
		>
			<Typography variant="span" sx={{ color: "gray" }}>{label} :</Typography>
			<Typography variant="span" sx={{ flexBasis: "70%" }}>{value || "-"}</Typography>
		</Box>
	}

	return (
		<>
			{remark.map((data, index) => {
				const { remarkValue, date, _id, email, designation, name } = data;
				return (
					<CardContent
						sx={{
							display: "flex", flexDirection: "column", gap: ".5em",
							border: "1px solid #ccc", margin: ".5em", borderRadius: "12px",
						}}
						key={index}
					>
						<Box sx={{ display: "flex", fontSize: "13px", justifyContent: "end", marginBottom: "-19px" }}
						>
							<Typography variant="span"
								sx={{
									background: "#F27700",
									transform: "translate(16px, -16px)",
									borderTopRightRadius: "10px",
									padding: "5px 10px"
								}}
							>{capitalize(user[_id]?.name || name || "-")}</Typography>
						</Box>
						{renderRow("Email", user[_id]?.email || email || "")}
						{renderRow("Remark", capitalize(remarkValue || ""))}
						{date ? renderRow("Date", dateTimeFormater(date)) : null}
						{renderRow("Designation", capitalize(user[_id]?.designation || designation || ""))}

					</CardContent>
				);
			})}
		</>
	)
}
const RenderLocationData = ({ location, cellOnly = false, jobType = false, jobProcessConfig = {}, locationPage = {} }) => {

	// console.log("locationPage",locationPage)
	const renderRow = (label, value, isLocation = false) => {
		return <Box sx={{ display: "flex", gap: "0.25em", fontSize: "13px", justifyContent: "space-between" }}
		>
			<Typography variant="span" sx={{ color: "gray" }}>{label} :</Typography>
			{isLocation
				? Array.isArray(value)
					?
					<Typography variant="span" sx={{ flexBasis: "60%" }}>
						{
							value.map((dl, index) => {
								let { cellName, rackName, count = 0, scannedCount = 0 } = dl
								return <Typography variant="span" >
									{(cellName || "") + (rackName ? " - " + rackName : "") + (`[${truncateNumber(scannedCount,3,true)}/${truncateNumber(count,3,true)}]`) + (value.length > index + 1 ? `, ` : "")}
									{/* {} */}
								</Typography>
							})
						}</Typography>
					: "-"
				: <Typography variant="span" sx={{ flexBasis: "60%" }}>{value || ""}</Typography>
			}		</Box>
	}
	// (cellName || "") + (rackName ? " - " + rackName : "")
	return (
		<>
			{location.map((data, index) => {
				const {
					SKU_Number, SKU_Name, USN_Value, cellName, count = 0, shipmentId, packageId, mappingArea = "",
					itemId, packageName, rackName, floorName, warehouseName, cellDetail = [], scannedCount = 0, indicatedTo, masterPackageId
				} = data;
				return (
					<CardContent
						sx={{
							display: "flex", flexDirection: "column", gap: ".5em",
							border: "1px solid #ccc", margin: ".5em", borderRadius: "12px",
						}}
						key={index}
					>
						<Box sx={{ display: "flex", fontSize: "13px", justifyContent: "space-between", marginBottom: "-19px" }}
						>
							<Typography variant="span"
								sx={{
									background: "#F27700",
									transform: "translate(-16px, -16px)",
									borderTopLeftRadius: "10px",
									padding: "5px 10px"
								}}
							>
								{((locationPage.page - 1) * (locationPage.limit)) + index + 1}
							</Typography>
							<Typography variant="span"
								sx={{
									background: "#F27700",
									transform: "translate(16px, -16px)",
									borderTopRightRadius: "10px",
									padding: "5px 10px"
								}}
							>
								{cellOnly
									? (cellDetail?.at(0)?.cellName || "")
									: jobType == "pickingAndPackaging"
										? masterPackageId
										: shipmentId || "-"
								}</Typography>
						</Box>

						{renderRow("Warehouse", (warehouseName || "") + (floorName ? " - " + floorName : ""))}
						{cellOnly
							? cellDetail?.at(0)?.rackName && renderRow("Rack", cellDetail?.at(0)?.rackName) || null
							: !["pickingAndPackaging", "inventoryAudit"].includes(jobType)
								? cellDetail?.length > 0
									? renderRow("Location", cellDetail, true)
									: renderRow("Mapping Area", mappingArea ? mappingArea : "PUT IT TO ANYWHERE")
								: jobType == "inventoryAudit"
									? renderRow("Cell Detail", (cellName || "") + (rackName ? " - " + rackName : "")) || null
									: ""
						}
						{/* {renderRow("Shipment ID",shipmentId)} */}
						{itemId ? renderRow("Item Id", itemId) : null}
						{SKU_Number ? renderRow("SKU Number", SKU_Number) : null}
						{SKU_Name ? renderRow("SKU Name", SKU_Name) : null}
						{USN_Value ? renderRow("USN Value", USN_Value) : null}
						{packageId ? renderRow("Package ID", packageId) : null}
						{packageId && packageName ? renderRow("Package Name", packageName) : null}
						{data?.pallet_SKU_ID ? renderRow("Pallet ID", data?.pallet_SKU_ID) : null}
						{jobType == "pickingAndPackaging" && itemId && indicatedTo ? renderRow("Item Refer To", indicatedTo) : null}
						{/* {renderRow("Mapping Area",mappingArea ? mappingArea :"PUT IT TO ANYWHERE")} */}
						{renderRow("Total Qty", truncateNumber(count,3,true))}
						{renderRow("Scanned Qty", truncateNumber(scannedCount,3,true))}
					</CardContent>
				);
			})}
		</>
	)
}
const RenderFormData = ({ templateHeader, templateData }) => {

	return (
		<>
			{
				templateHeader?.length ? templateHeader.map((ele, index) => {
					const { label, type, format, name,values=[] } = ele
					let value = templateData[name] || ""
					if (type == "file") {
						value = ""
					}
					else if (type == "date" && value) {
						value = format ? moment(value).format(format) : dateTimeFormater(value)
					}
					else if (type == "checkbox") {
						value = Array.isArray(value) ? value : []
						if(value?.length){
							value=value.map(dl=>values?.find(dll=>dll?.value==dl)?.label || dl)
						}
						value = value.join(", ")
					}
					else if (type == "select" || type == "radio") {
						if(value){
							value=values?.find(dll=>dll?.value==value)?.label || value
						}
						else{value=""}
					}
					else if(Array.isArray(value)) {
						value = value.join(", ")
					}
					else{
						value = value && typeof value=="string" && value.trim() || value || ""
					}
					return (
						<Box
							sx={{
								display: "flex",
								gap: "0.25em",
								fontSize: "13px",
								justifyContent:
									"space-between",
							}}
							key={index}
						>

							<Typography
								variant="span"
								sx={{
									color: "gray",
								}}
							>
								{label} :
							</Typography>
							<Typography
								variant="span"
								sx={{ flexBasis: "60%", textTransform: "capitalize" }}
							>
								{value}
							</Typography>
						</Box>
					)

				})
					: ""
			}
		</>

	);
}

export { JobDetail, RenderRamarkData, RenderLocationData, RenderFormData }

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

function capitalize(str) {
	if (!str) return;
	str = str + ""
	// ////({ str });
	return str[0].toUpperCase().concat(str.slice(1));
}
