import React, { useEffect, useState, } from "react";
import { Link,useParams } from "react-router-dom";
import {
  FormControl,
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  Autocomplete,
  Breadcrumbs,
  Tooltip
} from "@mui/material";
import { useDispatch} from "react-redux";
import history from "../../../../services/history";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../../static/styles/theme"

import { addGridDetails,getGridDetail} from "../../../../redux/actions";
import {  showToast, validateFieldsSingle } from "../../../../services/functions";
import { useLocation } from "react-router-dom";
import { getFieldsLayout } from "../../../Components/TemplateInputConfiguration";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import { FloodRounded } from "@mui/icons-material";


let initial_state = {
    "row": 0,
    "col": 0,
    "isGridMade":false,
}
let initial_grid_state = {
    "gridCells":[],
    "typeDropdown": "block",
    "subTypeDropdown": "",
    "labelInput": "",
    "rowsCount": 0,
    "columnCount": 0,
    "length": 0,
    "width": 0,
    "height": 0,
    "unitDropdown": "feet",
    "bgColor": "",
    "fontColor": "",
}

let inputForm = [
    {
        "_id": "row",
        "type": "number",
        "label": "Floor Rows",
        "description": "Floor Rows",
        "placeholder": "Floor Rows",
        "errorText": "Enter integer value only",
        "required": true,
        "regex": "^[0-9]*$"
    },
    {
        "_id": "col",
        "type": "number",
        "label": "Floor Cols",
        "errorText": "Enter integer value only",
        "description": "Floor Cols",
        "placeholder": "Floor Cols",
        "required": true,
        "regex": "^[0-9]*$",
    }
]



const typeOptions = [
    { label: "Block", value: "block" },
    { label: "Space", value: "space" },
    {
        label: "Staging Area", value: "stagingArea",
        subType: [
            { label: "Inward", value: "inward" },
            { label: "Outward", value: "outward" },
        ]
    },
    {
        label: "Dock Area", value: "dock",
        subType: [
            { label: "Inward", value: "inward" },
            { label: "Outward", value: "outward" },
        ]
    },
    // {label:"Merge Block",value:"merge_block"}
]
const unitOptions=[
    {label:"Feet",value:"feet"},
    {label:"Meter",value:"meter"},
]

const AddGridPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    let floorDetail=location?.state?.floorDetail
    let isGridExist=floorDetail?.isGridExist==true
    // const queryParams = new URLSearchParams(location.search);
    // const floorId = queryParams.get("id")
    let { floorId } = useParams();
    // console.log(floorId,"floorId")

    const [state, setState] = useState({ ...initial_state })
    const [grid, setGrid] = useState({ ...initial_grid_state })
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState("initial")
    const [selectedCells, setSelectedCells] = useState([])

    const handleInputChange = async (targetField={}, event) => {
        let oldObject = { ...state };
        let fieldId = targetField?._id;
        if (targetField.type == "file") {
            // const file = await convertDocsBase64(event.target.files[0]);
            // oldObject[fieldId] = file;
        }
        else if (targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if (event.checked) {
                oldValues.push(event.value);
            }
            else {
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else if(targetField.type=="number"){
            oldObject[fieldId] = Number(event.target.value);
        }
        else {
            oldObject[fieldId] = event.target.value;
        }

        let keys = []
        Object.entries(oldObject).filter(([key, value]) => {
            if (Array.isArray(value) ? value?.length : value) {
                keys.push(key)
            }
        })
        let tempError = validateFieldsSingle(inputForm?.filter(dl => keys.includes(dl?._id)), oldObject)
        // console.log(oldObject,tempError)
        setErrors(tempError)
        setState(oldObject);
    }

    const layout = getFieldsLayout({ fields: inputForm, componentValues: { ...state }, errors, disabled:isGridExist || state?.isGridMade || false, handleChange: handleInputChange });

    const createTableLayout = () => {
        let rows = state?.row || 0;
        let cols = state?.col || 0;
        let newArry = []

        if (rows && cols) {
            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < cols; j++) {
                    newArry.push({
                        "cellId": new Date().getTime() + "" + (i * rows) + "" + j + "",
                        "name": "",
                        "type": "NA",
                        "subType": "",
                        "isRackExist": false,
                        "rowIndex": i,
                        "colIndex": j,
                        "disable": false,//(i>10 && j>1)?true:
                        "floorId": floorId,
                        "status": "available",
                        "selected": false,
                        "fontColor": "",
                        "bgColor": "",
                        "length": "",
                        "width": "",
                        "height": "",
                        "unit": "",
                        "isCopied": false
                    })
                }
            }
        }
        setGrid({ ...initial_grid_state, gridCells: newArry })
        setState({ ...state, isGridMade: true })
    }

    const selectGrid = (cellId) => {

        let cellSelect = [...selectedCells]
        let allCells = [...grid?.gridCells]
        let gridBlockIndex = allCells?.findIndex(el => el.cellId === cellId)
        if (gridBlockIndex >= 0) {
            let gridBlock = allCells?.at(gridBlockIndex) || {}
            
            let valid = true
            if (gridBlock.isSelected) {
                valid = true
                let tempIndex = selectedCells.findIndex(el => el.cellId == cellId)
                cellSelect.splice(tempIndex, 1)
                setSelectedCells(cellSelect)
            }
            else {
                if (selectedCells?.length > 0) {
                    if (gridBlock?.name) {
                        valid = false
                    }
                    else {
                        valid = !cellSelect.some(dl => !!dl.name)
                        // console.log("cellSelect",cellSelect)
                    }
                }
                if(!valid){
                    showToast("Not valid selection!!", true)
                    return
                }
                cellSelect.push(gridBlock)
                setSelectedCells(cellSelect)
            }
            if (valid) {
                gridBlock.isSelected = gridBlock.isSelected ? false : true
                allCells.splice(gridBlockIndex, 1, gridBlock)
                let tempObj = {
                    ...grid,
                    gridCells: allCells,
                }

                if (cellSelect.length == 0) {
                    tempObj = {
                        ...tempObj,
                        typeDropdown: "block",
                        subTypeDropdown: "",
                        labelInput: "",
                        length: 0,
                        width: 0,
                        height: 0,
                        unitDropdown: "",
                        bgColor: "",
                        fontColor: "",
                    }
                }
                else if (gridBlock?.name) {
                    tempObj = {
                        ...tempObj,
                        typeDropdown: gridBlock.type,
                        subTypeDropdown: gridBlock?.subType || "",
                        labelInput: gridBlock.name,
                        length: gridBlock?.length || 0,
                        width: gridBlock?.width || 0,
                        height: gridBlock?.height || 0,
                        unitDropdown: gridBlock?.unit || "",
                        bgColor: gridBlock?.bgColor || "",
                        fontColor: gridBlock?.fontColor || "",
                    }
                }
                setGrid(tempObj)
            }
        }
    }

    const handleChange = (name = "", value = "", newValue) => {
        // console.log(name,value,"handleChange",newValue)
        setGrid(values => ({ ...values, [name]: value }))
    }

    const getBlockByIndex = (rowIndex, colIndex) => {
        return grid?.gridCells?.find(el => el.rowIndex == rowIndex && el.colIndex == colIndex)
    }

    const createCells = () => {
        let allCells = [...grid?.gridCells]
        let type = grid.typeDropdown;
        let subType = grid?.subTypeDropdown || "";
        // let type = "merge"
        let mergeRowsInput = grid.rowsCount
        let mergeColumnInput = grid.columnCount
        let labelInput = grid.labelInput
        let bgColor = grid.bgColor || ""
        let fontColor = grid.fontColor || ""
        let length = grid.length ? grid.length : ""
        let width = grid.width ? grid.width : ""
        let height = grid.height ? grid.height : ""
        let unit =grid.unitDropdown ? grid.unitDropdown : "feet"
        let valid = false
        let newCells = grid?.gridCells?.filter(arr => arr.isSelected === true)
        let obj = getBoundaryCordinates(newCells)
        if (newCells.length) {
            valid = checkLayout(obj, newCells)
        }

        if (valid) {
            let mergeRows = Number(mergeRowsInput) || (obj.endRow - obj.startRow + 1) || 0
            let mergeColumn = Number(mergeColumnInput) || (obj.endCol - obj.startCol + 1) || 0

            // let mergeRows = newCells.length === 1 ? Number(mergeRowsInput) : (obj.endRow - obj.startRow + 1)
            // let mergeColumn = newCells.length === 1 ? Number(mergeColumnInput) : (obj.endCol - obj.startCol + 1)

            if ((mergeColumn || mergeRows) && type) {
                // if ((mergeColumn > 1 || mergeRows > 1) && type) {
                let gridBlockIndex = allCells?.findIndex(el => el.rowIndex == obj.startRow && el.colIndex == obj.startCol)
                if (gridBlockIndex >= 0) {
                    let gridBlock = allCells?.at(gridBlockIndex) || {}
                    gridBlock.type = type
                    gridBlock.subType = subType
                    gridBlock.name = labelInput ? labelInput.toUpperCase() : ""
                    gridBlock.length = length
                    gridBlock.width = width
                    gridBlock.height = height
                    gridBlock.unit = unit
                    if (mergeRows > 1) {
                        gridBlock.mergeRows = mergeRows

                    }
                    if (mergeColumn > 1) {
                        gridBlock.mergeColumn = mergeColumn

                    }
                    gridBlock.bgColor = bgColor;
                    gridBlock.fontColor = fontColor;
                    for (let i = 0; i < Number(mergeRows); i++) {
                        for (let j = 0; j < Number(mergeColumn); j++) {
                            let index = allCells.findIndex(el => el.colIndex == gridBlock.colIndex + j && el.rowIndex == gridBlock.rowIndex + i && el.cellId != gridBlock.cellId)
                            if (index > -1) {
                                allCells.splice(index, 1)
                            }
                        }
                    }
                    newCells = []
                    setSelectedCells([])
                    delete gridBlock.isSelected
                    allCells.splice(gridBlockIndex, 1, gridBlock)
                    setGrid({ ...initial_grid_state, gridCells: allCells, typeDropdown: "block",subType:""})
                    // console.log(allCells,"iiiii")
                }
                else {
                    showToast("Not valid selection!!", true)
                }
            }
        }
        else {
            showToast("Not valid selection!", true)
        }

    }

    const checkLayout = (obj, arr) => {

        let valid = true
        for (let i = obj.startRow; i <= obj.endRow; i++) {
            for (let j = obj.startCol; j <= obj.endCol; j++) {
                let tempIndex = arr.findIndex(el => el.rowIndex == i && el.colIndex == j)

                if (tempIndex == -1) {
                    valid = false

                    break;
                }
            }
            if (valid == false) {
                break;
            }
        }
        return valid
    }

    const copyLayout = (cellId) => {
        let gridBlock = grid?.gridCells?.find(el => el.cellId == cellId)

        gridBlock.isSelected = false;
        if (gridBlock.mergeColumn || gridBlock.mergeRows) {
            setGrid({ ...grid, isCopied: true, copiedCellId: cellId })
        }
    }

    const unSelect = (cellId) => {
        // console.log("1 ", grid?.gridCells?);
        let gridBlock = grid?.gridCells?.find(el => el.cellId == cellId)

        let index = grid?.gridCells?.findIndex(block => block.cellId == cellId)

        let unselected = []
        let rows = gridBlock.mergeRows || 1
        let cols = gridBlock.mergeColumn || 1

        for (let i = 0; i < rows; i++) {

            for (let j = 0; j < cols; j++) {

                unselected.push({
                    "cellId": new Date().getTime() + "" + (i * rows) + "" + j + "",
                    "name": "",
                    // "name" : ArrChar[i]+""+(j+1),
                    // "base": floorData.base,
                    // "warehouseId": floorData.warehouseId,
                    "type": "NA",
                    "isRackExist": false,
                    "rowIndex": gridBlock.rowIndex + i,
                    "colIndex": gridBlock.colIndex + j,
                    "disable": false,//(i>10 && j>1)?true:
                    "floorId": floorId,
                    "partial": true,
                    "status": "available",
                    // "selected": false,
                    "fontColor": "",
                    "bgColor": "",
                    // "isCopied": false
                })
            }
        }
        let local = [...grid?.gridCells]

        local.splice(index, 1, ...unselected)

        setGrid({ ...grid, gridCells: local })
    }

    const pasteLayout = (cellId) => {
        let gridBlock = grid?.gridCells?.find(el => el.cellId == cellId)

        if (grid?.isCopied && grid?.copiedCellId) {
            let copiedCell = grid?.gridCells?.find(el => el.cellId == grid?.copiedCellId)

            let pasteCell = [];
            // selectedCells = []
            let copiedRows = copiedCell.mergeRows || 1
            let copiedCells = copiedCell.mergeColumn || 1
            for (let i = 0; i < copiedRows; i++) {
                for (let j = 0; j < copiedCells; j++) {
                    let tempCell = grid?.gridCells?.find(el => el.rowIndex == gridBlock.rowIndex + i && el.colIndex == gridBlock.colIndex + j)

                    if (tempCell && (!tempCell.mergeRows || tempCell.mergeRows == 1) && (!tempCell.mergeColumn || tempCell.mergeColumn == 1)) {

                        tempCell.isSelected = true
                        pasteCell.push(tempCell)
                        setSelectedCells([...pasteCell])

                    }
                }
            }

            gridBlock.isCopied = !gridBlock.isCopied
            setGrid({ ...grid, isCopied: false, copiedCellId: "" })
        }
    }

    const getBoundaryCordinates = (arr) => {

        let obj = {
            startRow: '',
            startCol: '',
            endRow: '',
            endCol: ""
        }
        if (arr.length) {
            let rowsArr = arr.map(el => el.rowIndex)
            let colsArr = arr.map(el => el.colIndex)
            obj.startRow = Math.min(...rowsArr)
            obj.endRow = Math.max(...rowsArr)
            obj.startCol = Math.min(...colsArr)
            obj.endCol = Math.max(...colsArr)
        }

        return obj

    }


    // Helper function to compute maximum row and column indices
    const getMaxRowAndColumn = (gridCells) => {
        let maxColumn = 0;
        let maxRow = 0;

        for (const cell of gridCells) {
            if (cell.type !== "NA") {
                const endCol = cell.colIndex + (cell.mergeColumn || 1);
                const endRow = cell.rowIndex + (cell.mergeRows || 1);

                maxColumn = Math.max(maxColumn, endCol);
                maxRow = Math.max(maxRow, endRow);
            }
        }

        return { maxRow, maxColumn };
    };

    // Helper function to check for uncovered "NA" cells
    const hasUncoveredCells = (gridCells, maxRow, maxColumn) => {
        // console.log(gridCells, maxRow, maxColumn)
        return gridCells.some(cell =>
            cell.type === "NA"  ||  ((cell.rowIndex) < maxRow) ||((cell.colIndex) < maxColumn)
        );
    };

    const idDisabledmit=(()=>{
        if (!grid?.gridCells || grid?.gridCells?.length==0 || state?.isGridMade!=true || grid?.gridCells?.some(cell =>cell.type === "NA")) {
            return true;
        }
        // const { maxRow, maxColumn } = getMaxRowAndColumn(grid?.gridCells || []);

        // console.log("maxRow", maxRow, "maxColumn", maxColumn)
        // // Check if there are uncovered "NA" cells
        // if (hasUncoveredCells(grid.gridCells, maxRow, maxColumn)) {
        //     console.log("true",true)
        //     return true
        // }
        // console.log("false",false)
        return false
    })();

    // Main function to submit the grid
    const submitGrid = () => {
        // Check if grid and gridCells are defined
        if (!grid?.gridCells || grid?.gridCells?.length==0 || state?.isGridMade!=true || grid?.gridCells?.some(cell =>cell.type === "NA")) {
            showToast("Grid data is missing!", true);
            return;
        }
        const isMinOneBlock = grid.gridCells.some(cell => cell.type== "block");
        if(!isMinOneBlock){
            showToast("In grid, minimum one cell need to define as block!", true);
            return
        }

        const { maxRow, maxColumn } = getMaxRowAndColumn(grid?.gridCells || []);

        // Filter out cells of type "NA"
        const sendArr = grid.gridCells.filter(cell => cell.type !== "NA");

        // Check if there are uncovered "NA" cells
        // if (hasUncoveredCells(grid.gridCells, maxRow, maxColumn)) {
        //     showToast("Please complete your grid first!", true);
        // }
        // else {
            const GridData = {
                floorId: floorId,
                row: maxRow,
                col: maxColumn,
                gridData: [...sendArr]
            };

            setLoader("submit")
            dispatch(addGridDetails(GridData, ({ success, message }) => {
                showToast(message, !success);
                setLoader("")
                if (success) {
                    history.push("/dashboard/warehouseFloor");
                }
            }));
        // }
    };


    const renameGrid = (cellId) => {
        let gridBlock = grid?.gridCells?.find(el => el.cellId == cellId)
        // "typeDropdown": "block",
        // "subTypeDropdown": "",
        // "labelInput": "",
        // "rowsCount": 0,
        // "columnCount": 0,
        // "length": 0,
        // "width": 0,
        // "height": 0,
        // "unitDropdown": "feet",
        // "bgColor": "",
        // "fontColor": "",

        // console.log("ddd",gridBlock,{
        //     ...grid,
        //     typeDropdown: gridBlock.type,
        //     subTypeDropdown: gridBlock?.subType || "",
        //     labelInput: gridBlock.name,
        //     length: gridBlock?.length || 0,
        //     width: gridBlock?.width || 0,
        //     height: gridBlock?.height || 0,
        //     unitDropdown: gridBlock?.unit || "",
        //     bgColor: gridBlock?.bgColor || "",
        //     fontColor: gridBlock?.fontColor || "",
        // })
        setGrid({
            ...grid,
            typeDropdown: gridBlock.type,
            subTypeDropdown: gridBlock?.subType || "",
            labelInput: gridBlock.name,
            length: gridBlock?.length || 0,
            width: gridBlock?.width || 0,
            height: gridBlock?.height || 0,
            unitDropdown: gridBlock?.unit || "",
            bgColor: gridBlock?.bgColor || "",
            fontColor: gridBlock?.fontColor || "",
        })
    }

    useEffect(()=>{
        if(isGridExist){
            dispatch(getGridDetail({floorId,type: "block",fetch_for: "gridview",},({data=[]})=>{
                setLoader("")
                setState({row:floorDetail?.row,col:floorDetail?.col,isGridMade:true})
                setGrid({gridCells:data})
            }))
        }
        else{
            setLoader("")
        }
        return (()=>{

        })
    },[])

    return (
        <>
            <Grid>
                <Grid item xs={12}>
                    <Typography sx={{ ...textClasses.cardTitle }}>
                        Manage Grid
                    </Typography>
                    {isGridExist==false && <Typography sx={{ ...textClasses.boldText }}>
                        Define layout of warehouse and create grid for area mapping.
                    </Typography>}
                    <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-new-kit">
                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                            <Link style={{ color: theme.themeOrange, textDecoration: "none", textTransform: "capitalize" }} to="/dashboard/warehouseFloor">
                                {isGridExist == true ? "View" : "Create"} Grid
                            </Link>
                        </Typography>

                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                            Floor Name - {floorDetail?.name || ""}
                        </Typography>
                    </Breadcrumbs>

                    <Grid
                        sx={{ border: `1px solid ${theme.themeOrange}`, p: 2, borderRadius: "5px", mt: 2 }}
                        spacing={2}
                        // display={"flex"}
                        // flexDirection={"row"}
                        // justifyContent={"space-between"}
                        // flexWrap={"wrap"}
                        container
                        xs={12}
                    >
                        {loader == "initial" ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15, pt: 10, pb: 10 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid>
                            :
                            <Grid
                                container
                                item
                                justifyContent={"space-between"} alignItems="center"
                                flexWrap={"wrap"}
                            >
                                <Grid container item spacing={2} sx={{ mt: 1, mb: 4 }} xs={12} sm={9}>
                                    {
                                        layout?.single?.map((field, i) => {
                                            return (
                                                <Grid key={i} sx={{ mr: 1 }} xs={12} sm={2}>
                                                    {field}
                                                </Grid>
                                            )
                                        })
                                    }
                                    {isGridExist == false && <>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                                            onClick={createTableLayout}
                                            disabled={
                                                Object.keys(validateFieldsSingle(inputForm?.filter(dl => ["row", "col"].includes(dl?._id)), state))?.length > 0 ||
                                                state?.isGridMade == true
                                            }
                                        >
                                            Create Layout
                                        </Button>
                                        <Button
                                            sx={{
                                                ...textClasses.boldText,
                                                color: theme.themeOrange,
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                ml: 2,
                                                textTransform: "none"
                                            }}
                                            disabled={state?.isGridMade == false || !!loader}
                                            onClick={() => {
                                                setState({ ...initial_state })
                                                setGrid({ ...initial_grid_state })
                                            }}
                                        >
                                            Clear Layout
                                        </Button>
                                    </>}
                                </Grid>
                                {isGridExist == false && <Grid container spacing={2} sx={{ mt: 1, mb: 4 }} item xs={12} sm={3} justifyContent={"end"} alignItems="center">
                                    <Box className="">
                                        <Button id="submitButton" onClick={() => submitGrid()}
                                            sx={{ ...buttonClasses.lynkitOrangeFill }}
                                            disabled={idDisabledmit || !!loader}
                                        >
                                            Add Grid
                                        </Button>
                                    </Box>
                                </Grid>}
                            </Grid>
                        }

                        {state?.isGridMade && grid?.gridCells?.length > 0 && <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                        >
                            <Grid
                                {... isGridExist ? {} : {sm: 9,md: 9.5}}
                                xs={12}
                                order={{ xs: 2, sm: 1, md: 1 }}
                                sx={{ p: 2, }}
                                item
                                container
                            >
                                <Grid sx={{
                                    p: 1, display: "flex", justifyContent: "center", alignItems: "center",
                                    border: `1px solid ${theme.themeGray}`, borderRadius: "5px",
                                    minHeight: "30vh",maxHeight: "70vh", overflowY: "auto" ,
                                }} item
                                    container>
                                    <Grid className=""
                                        sx={{
                                            display: "block",
                                            overflowX: "auto",
                                            whiteSpace: "nowrap",
                                            p: 1,
                                        }}
                                        item
                                    >
                                        <table
                                            style={{
                                                borderCollapse: "collapse",
                                                border: "1px solid #0000002B",
                                                tableLayout: "fixed",
                                            }}
                                        >
                                            {Array.from({ length: state.row ? state.row : '' }, (_, i) => {
                                                return (
                                                    <tr className="gridRow" key={"blr-" + i}>
                                                        {Array.from({ length: state.col ? state.col : '' }, (_, j) => {
                                                            let gridBlock = getBlockByIndex(i, j)
                                                            // console.log("gridBlock", gridBlock)
                                                            if (!gridBlock) {
                                                                return null;
                                                            }
                                                            const colSpan =( Number(gridBlock?.mergeColumn) || 1)*100;
                                                            const proportionalWidth = (colSpan / state.col) * 100
                                                            return (
                                                                <td
                                                                    onClick={() =>{ 
                                                                        if(!loader && isGridExist==false){
                                                                            selectGrid(gridBlock.cellId)
                                                                        }
                                                                    }}
                                                                    onMouseEnter={(event) => {
                                                                        if (event.buttons == 1 && !loader && isGridExist==false) {
                                                                            selectGrid(gridBlock.cellId)
                                                                            event.preventDefault()
                                                                        }
                                                                    }}
                                                                    className={`${gridBlock.type == "space" ? "space_area" : ""} `}
                                                                    colSpan={gridBlock.mergeColumn || 1}
                                                                    rowSpan={gridBlock.mergeRows || 1}
                                                                    style={{
                                                                        backgroundColor: gridBlock.isSelected ? '#ff7200' : gridBlock.bgColor,
                                                                        color: gridBlock.isSelected ? "" : gridBlock.fontColor,
                                                                        border: "1px solid #0000002B",
                                                                        textAlign: "center",
                                                                        verticalAlign: "middle",
                                                                        padding: "4px",
                                                                        // minWidth: (100*Number(gridBlock?.mergeColumn || 1))+"px",
                                                                        // maxWidth: (100*Number(gridBlock?.mergeColumn || 1))+"px",
                                                                        width: (100*Number(gridBlock?.mergeColumn || 1))+"px",
                                                                        // width: `${proportionalWidth}%`, // Apply proportional width
                                                                        height: (100*Number(gridBlock?.mergeRows || 1))+"px",
                                                                        overflow: "hidden", 
                                                                        textOverflow: "ellipsis",
                                                                    }}

                                                                >
                                                                    {/* {(i + 1) * (j + 1)} */}
                                                                    {gridBlock.length && gridBlock.width && <Box className="top-left-Box">{gridBlock.length + " x " + gridBlock.width}</Box>}
                                                                    <Tooltip title={gridBlock.name}>
                                                                        <span className={"td-head-name" + (isGridExist ? "" : "hover-grid")}
                                                                            title={isGridExist ? "" : "name"}
                                                                            // style={{
                                                                            //     cursor: isGridExist ? "" : "pointer",
                                                                            // }}
                                                                            // onClick={() => {
                                                                            //     console.log("ddd",{loader,isGridExist})
                                                                            //     if (!loader && isGridExist == false) {
                                                                            //         renameGrid(gridBlock.cellId)
                                                                            //     }
                                                                            // }}
                                                                        >{gridBlock.name}</span>
                                                                    </Tooltip>

                                                                    <Box className="copy-updn">
                                                                        {isGridExist ?
                                                                            <span style={{textTransform:"capitalize",color:theme.themeOrange}}>
                                                                                ({gridBlock?.status})
                                                                            </span>
                                                                            :
                                                                            <>
                                                                                {(gridBlock.mergeRows || gridBlock.mergeColumn) &&
                                                                                    <span onClick={(evt) => {
                                                                                        if (!loader) {
                                                                                            evt.preventDefault()
                                                                                            evt.stopPropagation()
                                                                                            copyLayout(gridBlock.cellId)
                                                                                        }
                                                                                    }} className="copy inside-grid hover-grid">copy</span>
                                                                                }
                                                                                {grid?.isCopied &&
                                                                                    <span onClick={(evt) => {
                                                                                        if (!loader) {
                                                                                            evt.preventDefault()
                                                                                            evt.stopPropagation()
                                                                                            pasteLayout(gridBlock.cellId)
                                                                                        }
                                                                                    }} className="paste inside-grid hover-grid" >paste</span>
                                                                                }
                                                                                {(gridBlock.name || gridBlock.mergeRows || gridBlock.mergeColumn) &&
                                                                                    <span onClick={(evt) => {
                                                                                        if (!loader) {
                                                                                            evt.preventDefault()
                                                                                            evt.stopPropagation()
                                                                                            unSelect(gridBlock.cellId)
                                                                                        }
                                                                                    }} className="clear inside-grid hover-grid">clear</span>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Box>
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            }
                                            )
                                            }
                                        </table>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {floorDetail?.isGridExist == false && <Grid
                                xs={12}
                                sm={3}
                                md={2.5}
                                order={{ xs: 1, sm: 2, md: 2 }}
                                sx={{ p: 2, minHeight: "30vh", maxHeight: "70vh", overflowY: "auto", mt: 1 }}
                                item
                                container
                            >
                                <Grid sx={{
                                    p: 1,
                                    border: `1px solid ${theme.themeGray}`, borderRadius: "5px",
                                    // backgroundColor:"red"
                                }}
                                    item
                                    xs={12}
                                    container
                                    spacing={2}
                                >

                                    <Grid xs={12} item >
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="typeDropdown"
                                                name="typeDropdown"
                                                options={typeOptions}
                                                value={{ value: grid.typeDropdown || "", label: typeOptions?.find(dl => dl.value === grid.typeDropdown)?.label || "" }}
                                                onChange={(event, newValue) => handleChange("typeDropdown", newValue?.value || "")}
                                                renderInput={(params) => <TextField {...params} label="Type" />}
                                                size="small"
                                                sx={{ ...inputClasses.shadowField, width: "100%" }}
                                                clearIcon={false}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {grid.typeDropdown && typeOptions?.find(dl => dl.value === grid.typeDropdown)?.subType?.length &&
                                        <Grid xs={12} item >
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    id="subtypeDropdown"
                                                    name="subtypeDropdown"
                                                    options={typeOptions?.find(dl => dl.value === grid.typeDropdown)?.subType || []}
                                                    value={{
                                                        value: grid?.subTypeDropdown || "",
                                                        label: typeOptions?.find(dl => dl.value === grid.typeDropdown)?.subType?.find(dl => dl.value === grid.subTypeDropdown)?.label || ""
                                                    }}
                                                    onChange={(event, newValue) => handleChange("subTypeDropdown", newValue?.value || "")}
                                                    renderInput={(params) => <TextField {...params} label="Sub Type" />}
                                                    size="small"
                                                    sx={{ ...inputClasses.shadowField, width: "100%" }}
                                                    clearIcon={false}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid xs={12} item>
                                        <TextField
                                            type="number" name="rowsCount" value={grid.rowsCount || ""}
                                            placeholder="No. of Rows Merge(when single cell selected)"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                            sx={{ ...inputClasses.shadowField }}
                                            label={"No. of Rows Merge(when single cell selected)"}
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="number" name="columnCount" value={grid.columnCount || ""}
                                            placeholder="No. of Column Merge(when single cell selected)"
                                            label="No. of Column Merge(when single cell selected)"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="text" name="labelInput" value={grid.labelInput || ""}
                                            placeholder="New Label"
                                            label="New Label"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>


                                    {/* <Grid xs={12} item container> */}
                                    <Grid xs={7} item>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="number" name="length" value={grid.length || ""}
                                            placeholder="Length of Block"
                                            label="Length of Block"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid xs={5} item>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="unitDropdownLength"
                                                name="unitDropdown"
                                                options={unitOptions}
                                                value={{ value: grid.unitDropdown || "", label: unitOptions?.find(dl => dl.value == grid.unitDropdown)?.label || "" }}
                                                onChange={(event, newValue) => handleChange("unitDropdown", newValue?.value || "")}
                                                renderInput={(params) => <TextField {...params} label="Unit Of Length" />}
                                                // disabled
                                                size="small"
                                                sx={{ ...inputClasses.shadowField }}
                                                clearIcon={false}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* </Grid> */}
                                    {/* <Grid xs={12} item container> */}
                                    <Grid xs={7} item>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="number" name="width" value={grid.width || ""}
                                            placeholder="Width of Block"
                                            label="Width of Block"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid xs={5} item>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                id="unitDropdownLength"
                                                name="unitDropdown"
                                                options={unitOptions}
                                                value={{ value: grid.unitDropdown || "", label: unitOptions?.find(dl => dl.value == grid.unitDropdown)?.label || "" }}
                                                onChange={(event, newValue) => handleChange("unitDropdown", newValue?.value || "")}
                                                renderInput={(params) => <TextField {...params} label="Unit Of Width" />}
                                                // disabled
                                                size="small"
                                                sx={{ ...inputClasses.shadowField }}
                                                clearIcon={false}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* </Grid> */}

                                    {/* <Grid xs={12} item container> */}
                                    <Grid xs={7} item>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="number" name="height" value={grid.height || ""}
                                            placeholder="Height of Block"
                                            label="Height of Block"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />

                                    </Grid>
                                    <Grid xs={5} item>
                                        <FormControl fullWidth>

                                            <Autocomplete
                                                id="unitDropdownWidth"
                                                name="unitDropdown"
                                                options={unitOptions}
                                                value={{ value: grid.unitDropdown || "", label: unitOptions?.find(dl => dl.value === grid.unitDropdown)?.label || "" }}
                                                onChange={(event, newValue) => handleChange("unitDropdown", newValue?.value || "")}
                                                renderInput={(params) => <TextField {...params} label="Unit Of Height" />}
                                                // disabled
                                                size="small"
                                                sx={{
                                                    ...inputClasses.shadowField,
                                                    borderRadius: "none",
                                                    borderTopLeftRadius: "0",
                                                    borderBottomLeftRadius: "0",
                                                }}
                                                clearIcon={false}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* </Grid> */}
                                    {/* <Grid xs={12} item>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="text" name="fontColor" value={grid.fontColor || ""}
                                            placeholder="Font Color" 
                                            label="Font Color" 
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid> */}
                                    <Grid xs={12} item>
                                        <label >Font Color:</label>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="color" name="fontColor" value={grid.fontColor || ""}
                                            placeholder="Font Color"
                                            label="Font Color"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <label >Background Color:</label>
                                        <TextField
                                            sx={{ ...inputClasses.shadowField }}
                                            type="color" name="bgColor" value={grid.bgColor || ""}
                                            placeholder="Background Color"
                                            label="Background Color"
                                            onChange={(event) => handleChange(event?.target?.name, event?.target?.value)}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid xs={12} item>
                                        <Button
                                            id="submitButton"
                                            disabled={!grid.labelInput || !!loader}
                                            onClick={() => createCells()}
                                            sx={{ ...buttonClasses.lynkitBlackFill }}
                                        >
                                            {selectedCells?.some(dl=>dl?.name)? "Update":"Create"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            }

                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}

export default AddGridPage;