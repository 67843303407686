import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { textCapitalize } from '../../../services/functions';
import CustomModel from '../../Components/CustomModels/UserModel';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Box, Typography } from "@mui/material";
import theme from '../../../static/styles/theme';
import colors, { buttonClasses, textClasses } from '../../../static/styles/theme';
import { Delete, Edit, Block, CheckCircleOutline } from '@mui/icons-material';
import { getWorkflowDetails } from '../../../redux/actions';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import ConfirmDialogPopUp from './ConfirmDialogPopUp';
import GenericDetailsView from '../../Components/Generic/GenericDetailsView';

function EditViewWorkFlow(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const warehouseDetail = useSelector(
        (state) => state.superadmin.warehouseDetail
    );
    const [tasksArray, setTasksArray] = useState([])
    const [workflowDetail, setWorkflowDetail] = useState({})
    const [confirmModal, setConfirmModal] = useState(null)
    const { open, hide, item, selectedEntries, setSelectedEntries, handleRemoveSelection, refreshTableData } = props
    const selectedWorkflowId = selectedEntries?.at(0)?.objectId || ""
    const handleCloseConfirmModal = () => {
        setConfirmModal(null)
    }
    useEffect(() => {
        const arrayWithShowKey = selectedEntries?.at(0)?.tasks.map((task, index) => {
            if (index === selectedEntries?.at(0).tasks.length - 1) {
                return { task, show: false };
            } else {
                return { task, show: true };
            }
        });
        const arrayConverted = [
            {
                workflowName: selectedEntries?.at(0)?.workflowName,
                tasks: arrayWithShowKey
            }
        ];
        setTasksArray(arrayConverted)
    }, [selectedEntries])

    const getWorkflowDetailHandler = () => {
        dispatch(getWorkflowDetails({ objectId: selectedWorkflowId }, (data) => {
            setWorkflowDetail(data?.data)
        }))
    }
    useEffect(() => {
        getWorkflowDetailHandler();
    }, [selectedWorkflowId, selectedEntries])
    return (
        <>
            <CustomModel
                show={open}
                onClose={() => hide()}
            >
                <div className='ppmodal' style={{ height: 'auto' }} data-testid='editModal'>
                    <div className='modelHead'>
                        <span style={{ color: '#000', fontWeight: '600' }}>{textCapitalize(workflowDetail?.workflowType)}</span>
                        <div>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<Edit />}
                                onClick={()=>{
                                    history.push(`/dashboard/setting/edit-workflow/${selectedWorkflowId}`)
                                }}
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    fontSize: "12px", borderRadius: "8px", marginRight: '10px'
                                }}
                            >
                                Edit
                            </Button>
                            {
                                workflowDetail?.approved ?
                                    <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<Block />}
                                        onClick={() => {
                                            // setActionStatus("Delete")
                                            // setEditBlock(true)
                                            setConfirmModal("disable")
                                        }}
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            fontSize: "12px", borderRadius: "8px", marginRight: '10px'
                                        }}
                                    > Disable
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<CheckCircleOutline />}
                                        onClick={() => {
                                            // setActionStatus("Delete")
                                            // setEditBlock(true)
                                            setConfirmModal("enable")
                                        }}
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            fontSize: "12px", borderRadius: "8px", marginRight: '10px'
                                        }}
                                    > Enable
                                    </Button>
                            }
                            <Button
                                variant="contained"
                                // className='UM_delete-btn'
                                size="small"
                                startIcon={<Delete />}
                                onClick={() => {
                                    // setActionStatus("Delete")
                                    // setEditBlock(true)
                                    setConfirmModal("delete")
                                }}
                                sx={{
                                    ...buttonClasses.lynkitBlackFill,
                                    fontSize: "12px", borderRadius: "8px", marginRight: '10px'
                                }}
                            > Delete
                            </Button>
                        </div>

                    </div>
                    <Grid container spacing={2} sx={{ border: '1px dashed #ff7200', p: '10px', ml: 0, width:'100%'}} mt={2}>
                        <Grid item lg={12}>
                            <GenericDetailsView title="Workflow Name">
                                <Typography sx={{ ...textClasses.t12n }}>{workflowDetail?.workflowName}</Typography>
                            </GenericDetailsView>
                        </Grid>
                        <Grid item lg={12}>
                            <GenericDetailsView title="Workflow Scope">
                                <Typography sx={{ ...textClasses.t12n }}>{textCapitalize(workflowDetail?.workflowScope)}</Typography>
                            </GenericDetailsView>
                        </Grid>
                        {workflowDetail?.warehouseId?.length > 0 &&
                            <Grid item lg={12}>
                                <GenericDetailsView title="Warehouses">
                                    <Typography sx={{ ...textClasses.t12n }}>{workflowDetail?.warehouseId?.map(dl => warehouseDetail?.data?.find(ele => ele?._id == dl)?.name)?.join(", ")}</Typography>
                                </GenericDetailsView>
                            </Grid>
                        }
                        {workflowDetail?.skuId?.length > 0 &&
                            <Grid item lg={12}>
                                <GenericDetailsView title="SKUs">
                                    <Typography sx={{ ...textClasses.t12n }}>{workflowDetail?.skuId?.join(", ")}</Typography>
                                </GenericDetailsView>
                            </Grid>
                        }
                        {workflowDetail?.description && <Grid item lg={12} sx={{ mb: 1 }}>
                            <GenericDetailsView title="Description">
                                <Typography sx={{ ...textClasses.t12n }}>{workflowDetail?.description}</Typography>
                            </GenericDetailsView>
                        </Grid>
                        }

                    </Grid>
                    <Grid container className='modalBody' sx={{
                        flexDirection: 'row', maxHeight: '170px', overflowY: 'auto', overflowX: 'hidden',
                        border: '1px dashed #ff7200', flexWrap: 'wrap', minHeight: '70px', marginTop: '15px', padding: '10px'
                    }}>
                        {tasksArray && tasksArray.map((item) => {
                            return (
                                item.tasks.map((data, ind) => {
                                    return (
                                        <Grid item className='pBoxVertical'>
                                            <Grid item sx={{
                                                mr: '5px', mb: '8px', width: 'auto', display: 'flex', alignItems: 'center', p: '3px', border: '1px solid #ff7200', whiteSpace: "nowrap",padding:'4px',marginRight:'5px',
                                                overflow: "hidden", textOverflow: "ellipsis", backgroundColor: "#FFE3CC", color: "#ff7200", cursor: "move", borderRadius: '8px', fontSize: '13px', justifyContent: 'center'
                                            }} >
                                                {data && data?.task}
                                            </Grid>
                                            {data.show === true ?
                                                <Grid item sx={{ml:1,mr:1,color:theme.themeOrange}} className="doubleArr" >
                                                    <DoubleArrowIcon />
                                                </Grid>
                                                : ''}
                                        </Grid>
                                    )
                                })

                            )
                        })}
                    </Grid>

                </div>
                {confirmModal && <ConfirmDialogPopUp
                    status={confirmModal}
                    handleClose={() => {
                        if (confirmModal == "delete") {
                            hide();
                        }
                        else {
                            getWorkflowDetailHandler();
                        }
                        handleCloseConfirmModal();
                    }}
                    selectedEntries={selectedEntries}
                    setSelectedEntries={setSelectedEntries}
                    handleRemoveSelection={handleRemoveSelection}
                    refreshTableData={refreshTableData}
                />}

            </CustomModel>
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </>
    )
}

export default EditViewWorkFlow;
