
import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Divider, Autocomplete, TextField } from '@mui/material';
import theme, { textClasses, buttonClasses, inputClasses } from '../../../static/styles/theme';
import { selectStylesOverride } from '../../../utils/util';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Chip from '@mui/material/Chip';
import { getAvailableSlots } from '../../../redux/actions';
import dayjs from "dayjs";
import DeliverySlotConfirmationModal from './DeliverySlotConfirmationModal';
import { useDispatch } from 'react-redux';
import GenericLoader from '../Generic/GenericLoader';
import { dateTimeFormater } from '../../../services/functions';
import SelectImage from "../../../static/images/salesOrder/online-slot-booking.svg";

const GenericSlotBookingUI = () => {
  const currentRoute = window.location.pathname;
  let isExternalRoute = true
  if (currentRoute == "/dashboard/bookSlot") {
    isExternalRoute = false;
  }
  const storageKey = isExternalRoute ? "e-slotsBooked" : "slotsBooked";

  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [viewModal, setViewModal] = useState("")
  const [allSlotsLoader, setAllSlotsLoader] = useState(false)
  const deliverySlotOptions = [
    { label: "15 mins delivery slot", value: 15 },
    { label: "30 mins delivery slot", value: 30 },
    { label: "45 mins delivery slot", value: 45 },
    { label: "60 mins delivery slot", value: 60 },
  ];

  const handleCalendarChange = (newValue) => {
    setSelectedDate(newValue);
    setSelectedDeliverySlot(null);
    setTimeSlots([]);
    handleDeliverySlotChange(deliverySlotOptions[deliverySlotOptions?.length - 1], newValue)
  }

  const handleDeliverySlotChange = (option, date) => {
    setSelectedDeliverySlot(option);
    if (option?.value) {
      setAllSlotsLoader(true)
      dispatch(getAvailableSlots({ slotBookingDate: dayjs(date).format("YYYY-MM-DD"), slotDuration: option?.value, isExternalRoute}, (data) => {
        setTimeSlots(data?.data || []);
        setAllSlotsLoader(false)
      }));
    } else {
      setTimeSlots([]);
    }
  };


  const handleChipClick = (value) => {
    setSelectedSlot(value); // Update selected chip index
  };

  // Disable dates before today
  const disablePastDates = (date) => {
    return dayjs(date).isBefore(dayjs(), "day");
  };


  const handleBookSlot = () => {
    const slotsData = {
      ...selectedSlot,
      slotBookingDate: selectedDate,
      slotDuration: selectedDeliverySlot?.value,
    };
  
    localStorage.setItem(storageKey, JSON.stringify(slotsData));
  
    setViewModal("slotBookingSuccessfull");
  };
  

  useEffect(() => {
    let existingSlotBookedDetail = localStorage.getItem(storageKey);
    if (existingSlotBookedDetail) {
      try {
        const parsedData = JSON.parse(existingSlotBookedDetail);

        if (typeof parsedData === "object" && parsedData !== null) {
          // Pre-fill states with data from localStorage
          setSelectedDate(dayjs(parsedData?.slotBookingDate));
          setSelectedSlot(parsedData);
          handleDeliverySlotChange(deliverySlotOptions.find(
            (option) => option.value === parsedData.slotDuration
          ), dayjs(parsedData?.slotBookingDate))
        }
      } catch (error) {
        // console.error("Invalid JSON in localStorage for 'slotsBooked':", error);
      }
    }
  }, []);


  return (
    <>
      <Grid container sx={{ display: "flex", border: `1px solid ${theme.themeGray}`, p: 2, borderRadius: 3 , ...(isExternalRoute && { margin: 3 }) }}>
        <Grid item xs={12}>
          <Typography sx={{ ...textClasses.t20n }}>Book Your Delivery Slot</Typography>
        </Grid>
        <Grid container xs={3} sx={{ mt: 2, height: 'fit-content', }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={selectedDate}
              data-testid="date-calendar"
              onChange={(newValue) => handleCalendarChange(newValue)}
              shouldDisableDate={disablePastDates} // Disable past dates
              sx={{
                border: `1px solid ${theme.themeGray}`,
                borderRadius: "10px",
                m: 0,
                "& .MuiPickersDay-root": {
                  "&.Mui-selected": {
                    backgroundColor: "#FF7200",
                    color: "white",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "#FF7200",
                  },
                  "&.Mui-selected.Mui-focusVisible": {
                    backgroundColor: "#FF7200",
                  },
                  "&.Mui-selected:focus": {
                    backgroundColor: "#FF7200",
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        {selectedDate == null ?

          <Grid container item xs={8.5} sx={{ border: `1px solid ${theme.themeGray}`, borderRadius: "10px", p: 2, mt: 2, height: 'fit-content', ml:2 }} data-testid="no-data">
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
              <img src={SelectImage} width="430" height="234" />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography sx={{ ...textClasses.cardTitle }}>
                Select a date and time to book your slot..
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography>
                Start by choosing a date, then pick your desired time slot.
              </Typography>
            </Grid>
          </Grid>
          :
          <Grid container item xs={8.5} sx={{ border: `1px solid ${theme.themeGray}`, borderRadius: "10px", p: 2, mt: 2, height: 'fit-content', ml:2 }}>
            <Grid item xs={6}>
              <Chip
                label={selectedDate ? dayjs(selectedDate).format("DD MMM YYYY") : "Select Date"}
                variant="outlined"
                size="small"
                color="warning"
                sx={{
                  height: 40,
                  width: 110,
                  fontWeight:700,
                  borderRadius: 1,
                }}
                data-testid="date-selected"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                data-testid="select-duration-dropdown"
                value={selectedDeliverySlot}
                onChange={(e, option) => {
                  handleDeliverySlotChange(option, selectedDate);
                  setSelectedSlot(null); // Reset selected chip when delivery slot changes
                }}
                size="small"
                options={deliverySlotOptions}
                sx={{
                  ...inputClasses.textField,
                  ...selectStylesOverride,
                  minWidth: "220px",
                  m: 0,
                  ".MuiInputBase-input": {
                    fontSize: "14px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label="Select Delivery Slot (in min)"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <Typography sx={{ ...textClasses.t15nBold }}>
                Select A Deilvery Time
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <Typography sx={{ ...textClasses.t15nBold }}>
                (GMT + 5:30) Indian Standard Time - Kolkata
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={1} sx={{
              mt: 2,
              maxHeight: "212px",
              overflowY: "auto",
            }}>
              {
                allSlotsLoader && selectedDeliverySlot?.value ?
                  <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{}}>
                    <Grid item><GenericLoader /></Grid>
                  </Grid>
                  :
                  timeSlots?.map((slot, index) => (
                    <Grid item key={index}>
                      <Chip label={`${dateTimeFormater(slot?.slotFrom, "hh:mm A")}-${dateTimeFormater(slot?.slotTo,"hh:mm A")}`} variant="outlined" size="small"
                        sx={{
                          color: selectedSlot?.slotFrom === slot?.slotFrom ? "orange" : "gray",
                          borderColor: selectedSlot?.slotFrom === slot?.slotFrom ? "orange" : "gray",
                          cursor: "pointer",
                          height: 40,
                          width: 150,
                          borderRadius: 1,
                        }}
                        disabled={slot?.isBooked}
                        onClick={() => handleChipClick(slot)} // Handle chip click
                        data-testid="all-slots"
                      />
                    </Grid>
                  ))
              }
            </Grid>
          </Grid>
        }
        
        <Grid container item xs={3} sx={{ display: "flex", justifyContent: "flex-start", mt: 3 }}>
          <Grid item>
            <Button
              variant="outlined"
              data-testid="clear-btn"
              sx={{ ...buttonClasses.lynkitBlackEmpty }}
              onClick={() => {
                setSelectedDate(null);
                setSelectedDeliverySlot(null);
                setTimeSlots([]);
              }}
              size="medium"
            >
              Clear All
            </Button>
          </Grid>
          <Grid item >
            <Button
              data-testid="book-slot"
              variant="outlined"
              sx={{ ...buttonClasses.lynkitOrangeFill, ml: 3 }}
              disabled={!selectedDate || !selectedDeliverySlot || !selectedSlot}
              size="medium"
              onClick={handleBookSlot}
            >
              Book Slot
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {viewModal == "slotBookingSuccessfull" &&
        <DeliverySlotConfirmationModal
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      }
    </>
  );
};

export default GenericSlotBookingUI;


