
import React, { useState,useEffect,useRef } from 'react';
import { Button,Box } from '@mui/material';
import Spreadsheet from 'react-spreadsheet';
import theme from '../../../static/styles/theme';
import { exportToExcel } from '../../../services/functions';


const createFormulaParser = (data, columns) => {
  // Helper function to evaluate basic arithmetic expressions
  const evaluateExpression = (expression) => {
    try {
      // Replace cell references like A1, B2, etc., with the actual values from the data
      const replacedExpression = expression.replace(/[A-Z]+\d+/g, (match) => {
        const col = match.match(/[A-Z]+/)[0];
        const row = parseInt(match.match(/\d+/)[0], 10) - 1; // 1-based index to 0-based
        const colIndex = columns.indexOf(col);

        if (colIndex >= 0 && row >= 0 && row < data.length) {
          return data[row][colIndex]?.value || 0; // Return the value or 0 if empty
        }
        return 0; // If the cell is invalid or out of bounds, return 0
      });

      // Use JavaScript's eval function to calculate the result of the arithmetic expression
      return new Function('return ' + replacedExpression)(); // Create a function to evaluate the expression
    } catch (e) {
      return '#ERROR'; // Return error if something goes wrong during evaluation
    }
  };

  // Function to process formulas in the data
  const processFormulas = (data) => {
    return data.map((row, rowIndex) =>
      row.map((cell, colIndex) => {
        if (cell?.value?.startsWith('=')) {
          // This is a formula, evaluate it
          const formula = cell.value.slice(1).trim(); // Remove the '=' from the start of the formula
          const result = evaluateExpression(formula);
          return { ...cell, value: result }; // Update the cell value with the result
        }
        return cell; // If not a formula, leave the cell unchanged
      })
    );
  };

  return { processFormulas };
};

const GenericExcelLikeUI = ({data,setData,initialRows=5,headers=[]}) => {

  // const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedCell, setSelectedCell] = useState({ row: null, col: null });
  const lastDataRef = useRef(data); // Track the last data state to avoid unnecessary updates
   // Formula parser instance
  //  const { processFormulas } = createFormulaParser(data, columns);


  const onEvaluatedDataChange = (args) => {
    const newData = args.slice(1); // Remove headers if applicable
    if (
      selectedCell.row !== null &&
      selectedCell.col !== null &&
      selectedCell.row > 0 &&
      JSON.stringify(lastDataRef.current) !== JSON.stringify(newData)
    ) {
      // Perform the update and track the last data to prevent redundant updates
      setData(newData);
      lastDataRef.current = newData; // Update the ref to the new data
      // console.log("gggggg")
    }
  };

  // Handle cell selection
  const handleCellClick = (args) => {
    // console.log(args, "handleCellClick")
    if (args?.row > 0) {
      setSelectedCell({ row: args.row, col: args.column });
    }
  };

  // Insert a new row at the start or end
  const insertRowAtStartOrEnd = (position) => {
    if (!selectedCell.row || selectedCell.row<1) return; // No range selected

    // console.log(selectedCell ,"selectedCell")
    // Determine where to insert the row based on the position (start or end)
    const insertIndex = position === 'start' ? selectedCell.row-1 : selectedCell.row; // Insert before start or after end
    const newRow = Array.from({ length: columns.length }, () => ({ value: '' }));

    const newData = [
      ...data.slice(0, insertIndex),
      newRow,
      ...data.slice(insertIndex)
    ];

    setData(newData);
    setSelectedCell({ row: null, col: null });
  
  };

  // Remove rows or columns based on selected range
  const removeSelectedRowOrColumn = (type) => {

    if (selectedCell.row > 0) {
      const { row, col } = selectedCell || {};
      let length = 0
      if (type === 'row') {
        const newData = data.filter((_, rowIndex) => rowIndex !== (row - 1));
        length = newData.length
        setData(newData);
      }
      else if (type === 'col') {
        const newColumns = columns.filter((_, colIndex) => colIndex != col);
        setColumns(newColumns);

        const newData = data.map(row => row.filter((_, colIndex) => colIndex != col));
        length = newData.length
        setData(newData);
      }
      setSelectedCell({ row: null, col: null });
      // if (length < row) {
      //   if (length < 2) {
      //     setSelectedCell({ row: null, col: null });
      //   }
      //   else {
      //     setSelectedCell({ row: length, col });
      //   }
      // }
    }
  };
  // Convert data to Excel and download
  const exportToExcelHandler= () => {
    const sheetData = [
      columns, // Headers
      ...data.map(row => row.map(cell => cell?.value))
    ];
    
    exportToExcel(sheetData)
  };

  // Global paste event handler
  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData.getData('text');
      const rows = clipboardData.trim().split('\n').map((row) => row.split('\t'));
      // const rows = [...data,...clipboardData.trim().split('\n').map((row) => row.split('\t'))];
      const { row, col } = selectedCell || {};
      if (row === null || col === null || row <= 0 || col < 0) return; // Ensure there's a valid cell selection

      const maxCols = Math.max(columns.length, ...rows.map((row) => row.length));
      const rowCount = Math.max(data.length, rows.length);

      // // Adjust column headers if needed
      // if (maxCols > columns.length) {
      //   const newColumns = [
      //     ...columns,
      //     ...Array.from({ length: maxCols - columns.length }, (_, i) => `Extra${columns.length + i + 1}`),
      //   ];
      //   setColumns(newColumns);
      // }

      // Adjust data array to fit the pasted content starting at selected row and col
      const newData = [...data];

      // // Fill empty cells and match dimensions
      // const newData = Array.from({ length: rowCount }, (_, rowIndex) =>
      //   Array.from({ length: maxCols }, (_, colIndex) => ({
      //     value: rows[rowIndex]?.[colIndex] || data[rowIndex]?.[colIndex]?.value || '',
      //   }))
      // );

      // Iterate over the rows of pasted data and update the respective cells
      rows.forEach((pastedRow, rowIndex) => {
        // Ensure we don't go out of bounds of the existing data
        if (row + rowIndex - 1 < newData.length) {
          const currentRow = newData[row + rowIndex - 1];
          pastedRow.forEach((cellValue, colIndex) => {
            if (col + colIndex < currentRow.length) {
              currentRow[col + colIndex].value = cellValue;
            }
          });
        }
      });

      // if (data.length <= rows.length) {
      //   const newRow = Array.from({ length: columns.length }, () => ({ value: '' }));
      //   newData.push(newRow);
      // }
      // console.log("event",event)
      // const processedData = processFormulas(newData); // Process formulas after pasting
      // setData(processedData);
      setData(newData);
    };

    // Attach event listener
    document.addEventListener('paste', handlePaste);


    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  useEffect(() => {
    setColumns(headers && [...headers] || [])
    // console.log("ffff",headers)
  }, [headers])

  return (
    <Box>
      <h6>Copy and Paste(Fill) Your Data Here</h6>
      
      <Button
        onClick={() => insertRowAtStartOrEnd('start')}
        sx={{ mr: 1,color:theme.themeOrange }}
        disabled={!selectedCell.row || selectedCell.row < 1}
        >
        Insert Row Before
      </Button>

      <Button
        onClick={() => insertRowAtStartOrEnd('end')}
        disabled={!selectedCell.row || selectedCell.row < 1}
        sx={{ mr: 1,color:theme.themeOrange }}
      >
        Insert Row After
      </Button>

      <Button
        onClick={() => removeSelectedRowOrColumn('row')}
        sx={{ mr: 1,color:theme.themeOrange }}
        disabled={!selectedCell.row || selectedCell.row < 1 || data.length < 2}
      >
        Remove Entire Row
      </Button>

      <Button
        onClick={exportToExcelHandler}
        sx={{ mr: 1, color: theme.themeOrange }}
      >
        Export to Excel
      </Button>
      <br />
      <Box sx={{width:"100%",maxHeight:"50vh",overflow:"auto",mb:1}}>
        <Spreadsheet
          data={[columns.map((col) => ({ value: col, readOnly: true })), ...data]}
          onActivate={(args) => handleCellClick(args)} // Ensure the cell click is properly 
          // onSelect={(args) => {
          //   console.log(args, "args")
          // }}
          onEvaluatedDataChange={onEvaluatedDataChange}
        />
      </Box>
    </Box>
  );
};

export default GenericExcelLikeUI;
