import axios from "../../services/Api";
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,GET_ZONE_USER,TRACK_USER,
    SHOW_MESSAGE, GET_ALL_ANALYTICS, GET_DASHBOARD_DATA, GET_WAREHOUSE_OPRATION, GET_WAREHOUSE_INVENTORY, GET_REPORT, GET_HEADER, GET_ITEM_VISE_REPORT
} from "../types";

var token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6eyJjcmVhdGVkRGF0ZSI6IjIwMjMtMDYtMDlUMTA6NDI6MTEuNzEyWi02MzNmZjRmZDY1YWYyMzUwMmZmYmMwYjUiLCJwZXJtaXNzaW9ucyI6eyJuZXdfZ3JpZFZpZXciOnRydWUsImNyZWF0ZVRlbXBsYXRlIjp0cnVlLCJ2aWV3VGVtcGxhdGUiOnRydWUsImVkaXRUZW1wbGF0ZSI6dHJ1ZSwiZGlzYWJsZVRlbXBsYXRlIjp0cnVlLCJkZWxldGVUZW1wbGF0ZSI6dHJ1ZSwiZ2F0ZUluZ2F0ZU91dCI6dHJ1ZSwiZGF0YUVudHJ5Ijp0cnVlLCJ2aWV3UGFja2FnZURldGFpbCI6dHJ1ZSwidmlld1Byb2R1Y3RNYXN0ZXJEYXRhIjp0cnVlLCJhZGRQcm9kdWN0TWFzdGVyRGF0YSI6dHJ1ZSwiYXJlYU1hcHBpbmciOnRydWUsImRhc2hib2FyZCI6dHJ1ZSwidmlld1dhcmVob3VzZUpvYnMiOnRydWUsImdyaWRNb2RpZmljYXRpb24iOnRydWUsImpvYkFsbG9jYXRpb24iOnRydWUsImdhdGVPdXREZXRhaWxFbnRyeSI6dHJ1ZSwiYXJlYU1hcHBpbmdBbmFseXRpY2VzIjp0cnVlLCJzaGlwbWVudFJlcG9ydCI6dHJ1ZSwicmVwb3J0RG93bmxvYWQiOnRydWUsInZpZXdJbnZlbnRvcnkiOnRydWUsInZpZXdNYXN0ZXJJbnZlbnRvcnkiOnRydWUsInZpZXdKb2JzIjp0cnVlLCJ2aWV3VXNlcnMiOnRydWUsImNyZWF0ZVVzZXJzIjp0cnVlLCJ2aWV3RHV0eUxvZ3MiOnRydWUsInJmaWRTZWFsVXBsb2FkIjp0cnVlLCJyZmlkU2VhbE1hcHBpbmciOnRydWUsImluZG9vclRyYWNraW5nIjp0cnVlfSwiX2lkIjoiNjMzZmY0ZmQ2NWFmMjM1MDJmZmJjMGI1IiwidXNlclR5cGUiOiJhZG1pbiIsIm5hbWUiOiJBZG1pbl9DTiIsIm1vYmlsZSI6IjgxMzA1MjAwNjEiLCJlbWFpbCI6ImNvZGluZ25pbmphc0BnbWFpbC5jb20iLCJpc09uRHV0eSI6ZmFsc2UsImFwcHJvdmVkIjp0cnVlLCJjb21wYW55IjoiTmluamEgQ29kaW5nIiwiYmFzZSI6IkNvZGluZyBOaW5qYXMiLCJkZXNpZ25hdGlvbiI6IkFkbWluIn0sImlhdCI6MTY4NjMwNzMzMSwiZXhwIjo2NDgwMDIxMTMzMX0.9XanJ34PMOYye9Vw0hr9JfjzIS4gc3aIl0p2zRPIP10"
var token2 = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6eyJjcmVhdGVkRGF0ZSI6IjIwMjMtMDYtMDlUMTA6MDY6MTIuMjg5Wi02NDM3OWM2NmMzNDJmMDA0ZGQwZmEyOGQiLCJwZXJtaXNzaW9ucyI6eyJpbmRvb3JUcmFja2luZyI6dHJ1ZX0sIl9pZCI6IjY0Mzc5YzY2YzM0MmYwMDRkZDBmYTI4ZCIsInVzZXJUeXBlIjoiYWRtaW4iLCJuYW1lIjoiRXhwZXJpZW5jZSBDZW50ZXIiLCJlbWFpbCI6ImV4cGVyaWVuY2VAbHlua2l0LmluIiwiaXNPbkR1dHkiOmZhbHNlLCJhcHByb3ZlZCI6dHJ1ZSwiY29tcGFueSI6IkV4cGVyaWVuY2UgQ2VudGVyIiwiYmFzZSI6IjEzNjQyN2M3LTJjYWMtNDUxNS04MWFkLWI4YWJkNDZiZGYzNy0xNjgxMzY1OTkxNzc4IiwiZGVzaWduYXRpb24iOiJFeHBlcmllbmNlIn0sImlhdCI6MTY4NjMwNTE3MiwiZXhwIjo2NDgwMDIwOTE3Mn0.XBmEH9W7gR1BoGiG7lgW8gKYabKIB7tc94fh9ewrNSk"

export const getWarehouseAnalytics = (obj, cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
              axios
                .post("/inventory/inventoryAnalytics", obj)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_ALL_ANALYTICS, payload: data })
                    if(cb){
                        cb();
                    }
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    dispatch({ type: GET_ALL_ANALYTICS, payload: {} })
                    if(cb){
                        cb();
                    }
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb();
                }
                ////("Error****:", error.message);
            });
    };
};

export const getZoneUser = (obj) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        axios({
            method: 'post',
            url: 'https://warehouse.lynkgrid.com/v1/zone/getAllUserData',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token2
            },
            data: obj
        })
        // axios.post('zone/getAllUserData', obj)
        .then(({ data }) => {

            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SHOW_MESSAGE, payload: data.message });
                dispatch({ type: GET_ZONE_USER, payload: data });
            }
            else {
                //dispatch({type: FETCH_ERROR, payload: data.message});
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
        });
    }
};

export const trackUser = (obj) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        ////(obj,'track');
        // axios.post('track/getTrack', obj )
        axios({
            method: 'post',
            url: 'https://warehouse.lynkgrid.com/v1/track/getTrack',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token2
            },
            data: obj
        })
        .then(({ data }) => {
            ////(data,'track');
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SHOW_MESSAGE, payload: data.message });
                dispatch({ type: TRACK_USER, payload: data.data });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
        });
    }
};

export const dashBoardData = (obj,cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios
        .post("/dashboard/getDashBoardData", obj)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: GET_DASHBOARD_DATA, payload: data });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data });
          }
          if (cb) {
            cb(data)
        }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
            cb({ success: false, message: error.message })
        }
        });
    };
  };
  
  export const warehouseOperations = (body,cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios
        .post("/dashboard/getWareHouseOperationCountDayWise",body)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: GET_WAREHOUSE_OPRATION, payload: data });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
            cb(data)
        }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
            cb({ success: false, message: error.message })
        }
        });
    };
  };
  
  export const WarehouseInventory = (body,cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios
        .post("/dashboard/getWareHouseInventoryCountDayWise", body)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_START, payload: data.success });
            dispatch({ type: GET_WAREHOUSE_INVENTORY, payload: data });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
            cb(data)
        }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
            cb({ success: false, message: error.message })
        }
        });
    };
  };


  export const getReportHeader= (body,callBack) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios
        .post("/report/headers",body)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_START, payload: data.success });
            dispatch({ type: GET_REPORT, payload: data });
          }
          else {
            dispatch({ type: GET_REPORT, payload: {} });
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if(callBack)callBack(data?.data || [])
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if(callBack)callBack([])
        });
    };
  };

export const getWMSReport = (body, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post("/report/getWMSReport", body)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_START, payload: data.success });
          dispatch({ type: GET_ITEM_VISE_REPORT, payload: data });
        }
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GET_ITEM_VISE_REPORT, payload: {} });
        }
        if (cb) {
          cb({ success: data.success, message: data.message, data: data.data });
        }
      })
      .catch(function (error) {
        dispatch({ type: GET_ITEM_VISE_REPORT, payload: {} });
        dispatch({ type: FETCH_ERROR, payload: error.message });
        if (cb) {
          cb({ success: false, message: error.message, data: "" });
        }
      });
    };
  };
export const scheduleReportEmail = (body, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post("/report/scheduleReportEmail", body)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_START, payload: data.success });
          dispatch({ type: FETCH_SUCCESS, payload: data.message });
        }
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (cb) {
          cb({ success: data.success, message: data.message});
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        if (cb) {
          cb({ success: false, message: error.message});
        }
      });
    };
  };



  