import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Grid, Typography, Autocomplete, TextField, Checkbox, Link, IconButton
} from '@mui/material'
import CustomModel from '../../Components/CustomModels/CustomModel1';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import theme, { inputClasses, textClasses, buttonClasses } from '../../../static/styles/theme';
import SelectImage from "../../../..//src/static/images/data_entry/select_location.svg"
import { saveItem, editItem, getConnectedSKUComponentDetails } from '../../../redux/actions';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../../services/functions';
import { Add, Delete } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DynamicFormRender from "./../../Components/DynamicFormRender"
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { selectStylesOverride } from "../../../utils/util";
import { handleDynamicChange } from '../../../services/formFunction';
import { modelInfoSingle } from './Utils';
import TypeAndSearch from '../../Components/TypeAndSearch';
import { component } from '../MainDashboard/utils';
import { useDebounce } from "../../../utils/hooks";
import GenericSingleRangeComponent from '../../Components/Generic/GenericSingleRangeComponent';
import GenericTable from '../../Components/CustomTable/GenericTable';
import GenericCustomiseHeader from '../../Components/Generic/GenericCustomiseHeader';
import GenericUserInputComponent from '../../Components/Generic/GenericUserNumberInput';
import DetailDialog from '../DataEntry/Asn/DetailDialog';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const initialData = {
  SKU_ID: "",
  count: 0,
  formData: {},
  template_id: "",
  SKU_Detail: ""
}
function AddViewEdit(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open, hide, action, sharedObj, urlPath, clearFilter, templateIndex, setCheckedArray } = props
  const [dialogData, setDialogData] = useState(null);
  const allTemplates = useSelector(state => state.templates.allTemplates);
  const tableHeader = useSelector((state) => state.customHeader["kitComponentTable"]);
  const [totalRows, setTotalRows] = useState(0)
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState(initialData);
  const [selectedEntries, setSelectedEntries] = useState([])
  const [pagination, setPagination] = useState({ page: 0, limit: 10 })
  const [step, setStep] = useState(1)
  const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();
  const skuIdDebounceFilter = useDebounce(selectedData.SKU_ID, 500)
  const getSKUComponentTableDetails = () => {
    let payload = {
      itemMasterId: sharedObj?._id,
      fetchFormData: true,
    }
    // setLoader(true)
    dispatch(getConnectedSKUComponentDetails(payload, (data) => {
      if (data.success) {
        setTableData(data?.data?.map((dl) => ({
          SKU_ID: dl?.SKU_ID,
          count: dl?.quantity,
          formData: dl?.formData || {},
          template_id: dl?.template_id || "",
          SKU_Detail: dl?.SKU_Detail || ""
        })));
        setTotalRows(data?.count)
      }
    }));
  }

  const handleCloseDetail = () => {
    setDialogData(null)
  }

  const countTableChangeHandler = (count, skuId) => {
    const updatedTableData = [...tableData];
    const index = updatedTableData.findIndex(
      (dl) => dl.SKU_ID === skuId
    );
    if (index !== -1) {
      updatedTableData.splice(index, 1, { ...updatedTableData[index], count: count > 0 ? count : "" });
      setTableData(updatedTableData);
    }
  }

  const handleAddSelection = (obj) => {
    setSelectedEntries((prevEntries) => [...prevEntries, obj]);
  };

  const handleRemoveSelection = (SKU_ID) => {
    setSelectedEntries((prevEntries) =>
      prevEntries.filter(
        (entry) =>
          !(entry.SKU_ID === SKU_ID)
      )
    );
  };

  const handleCheckChange = (event, SKU_ID) => {
    if (event.target.checked) {
      handleAddSelection({ SKU_ID });
    } else {
      handleRemoveSelection(SKU_ID);
    }
  };

  const handleCheckAll = () => {
    if (selectedEntries.length < tableData?.length) {
      let sT = tableData?.map((t) => ({
        SKU_ID: t?.SKU_ID
      }));
      setSelectedEntries(sT);
    } else {
      setSelectedEntries([]);
    }
  };
  
  let header =
    tableHeader && tableHeader?.validHeader?.length > 0
      ? tableHeader?.validHeader?.map((h) => {
        if (h.handleType === "selectRow") {
          return (
            <Checkbox
              data-testid="checkbox"
              checked={
                tableData?.length != 0 &&
                selectedEntries.length == tableData?.length
              }
              onChange={handleCheckAll}
              size="small"
            />
          )
        }
        return h.fieldName
      })
      : [];

  const getTableCell = (header, row, i) => {
    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries.some(
              (entry) =>
                entry.SKU_ID === row.SKU_ID
            )}
            onChange={(e) => handleCheckChange(e, row?.SKU_ID)}
          />
        );
      } else if (header?.handleType === "showDetail") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {row[header?.mappingPath] ?? "-"}
          </Typography>
        );
      } else if (header?.handleType === "action") {
        return (
          <>
            <IconButton
              aria-label="view"
              key={`view-${i}`}
              onClick={() => setDialogData(row)}
            >
              <VisibilityIcon sx={{ color: theme.themeOrange }} />
            </IconButton>
            <IconButton
              aria-label="delete"
              key={`delete-${i}`}
              onClick={() => handleRowDelete(i)}
            >
              <Delete sx={{ color: "red" }} />
            </IconButton>
          </>
        );
      }
    } else if (header?.isFromRenderFunction === true) {
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {(i + 1) + (pagination?.page * pagination?.limit)}
          </Typography>
        );
      }
    } else {
      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {header.mappingPath && row[header.mappingPath]
              ? Array.isArray(row[header.mappingPath])
                ? row[header.mappingPath].join(", ")
                : row[header.mappingPath]
              : "-"}
          </Typography>
        );
      } else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {/* {header.mappingPath && row[header.mappingPath]
              ? moment(row[header.mappingPath]).format(
                header?.format || "DD MMM YYYY hh:mm A"
              )
              : "-"} */}
          </Typography>
        );
      } else if (header?.renderFunction === "itemCount") {
        return (
          <Typography sx={{ ...textClasses.normalText, }}>
            <GenericSingleRangeComponent
              data-testid="rangefilter"
              startRange={row?.count}
              endRangeInfinity={true}
              getOnChange={(count) => countTableChangeHandler(count, row?.SKU_ID)}
              valueType={"decimal"}
              // valueType={row?.precisionType || "integer"}
            />
          </Typography>
        );
      } else {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath ? row[header.mappingPath] : "-"}
          </Typography>
        );
      }
    }
  };


  const tableRows = tableData?.slice(pagination.page * pagination.limit, pagination.page * pagination.limit + pagination.limit)?.map((row, i) => {
    let items = [];
    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }

    return items;

  })

  const handleTemplateChange = (option) => {
    if (option?.value === 'create') {
      setTimeout(() => {
        history.push({
          pathname: "/dashboard/templates/create",
          state: { status: 'addnew', selectedCard: [] }
        })
      }, 50)
    }
    else if (option?.value !== 'create') {
      if (option?.value !== null) {
        setSelectedTemplate(option?.value || '')
      }
      else {
        setSelectedTemplate('')
      }
    }
    reset()
  }

  const handleAddMore = () => {
    setSelectedTemplate("");
  }

  const saveItemEntry = () => {
    handleSubmit((data) => {
      let payload = {
        template_id: selectedTemplate,
        formData: {
          ...data
        }
      };
  
      if (urlPath === "kit-master") {
        payload.kitComponentData = tableData.map(item => ({
          SKU_ID: item.SKU_ID,
          SKU_Detail: item.SKU_Detail,
          quantity: item.count
        }));
        if(action=="copy"){
          payload.referenceMasterId=sharedObj?._id
        }
      }
   
  
      let apiKeyword = modelInfoSingle[urlPath]["saveAPIPath"]
      // console.log("pay",payload)
      dispatch(
        saveItem(payload, apiKeyword, ({ message, success = false }) => {
          showToast(message, !success)
          if (success) {
            hide("success")
            clearFilter(selectedTemplate, true, true)
            setCheckedArray([])
          }
        })
      )
    }, (errors) => {
      let errorField = Object.keys(errors)[0];
      showToast(`${errors[errorField].message}`, true);
    })();
  }

  const editItemEntry = () => {
    let apiKeyword = modelInfoSingle[urlPath]["editAPIPath"]


    handleSubmit((data) => {
      let apiKey = modelInfoSingle[urlPath]["apiKey"]
      let payload = {
        [apiKey]: sharedObj._id,
        formData: {
          ...data
        },
        kitComponentData: tableData.map(item => ({
          SKU_ID: item.SKU_ID,
          SKU_Detail: item.SKU_Detail,
          quantity: item.count
        }))
      }
      dispatch(
        editItem(payload, apiKeyword, ({ message, success = false }) => {
          showToast(message, !success)
          if (success) {
            hide("success")
            clearFilter(selectedTemplate, true, true)
            setCheckedArray([])
          }
        })
      )
    }, (errors) => {
      let errorField = Object.keys(errors)[0];
      showToast(`${errors[errorField].message}`, true);
    })();
  }

  const handleClearAll = () => {
    let resetD = { ...getValues() };
    let rKeys = Object.keys(resetD);
    for (let i = 0; i < rKeys.length; i++) {
      resetD[rKeys[i]] = ""
    }
    reset({
      ...resetD
    });
  }

  const handleRowDelete = (index) => {
    setTableData(prevTableData => prevTableData.filter((_, i) => i !== index));
  }
  const handleDeleteButton = () => {

    if (selectedEntries?.length > 0) {
      let updatedTableData = tableData;

      selectedEntries.forEach(entry => {
        updatedTableData = updatedTableData.filter(dl => dl.SKU_ID !== entry.SKU_ID);
      });

      setTableData(updatedTableData);
      setSelectedEntries([])
    }
  }

  const handleAddTableData = () => {
    if (!tableData?.find(dl => dl?.SKU_ID == selectedData?.SKU_ID)) {
      setTableData(prevTableData => [selectedData, ...prevTableData]);
    }
    else {
      showToast("SKU ID already exists", true)
    }
    setSelectedData(initialData);
  }
  const handleSkuIdFilter = async (event, option) => {

    if (event.target.value != "") {
      let details = {}
      setSelectedData({...initialData,loading:true})
      await handleDynamicChange(details, component({ type: "SKU" }), { value: option?.value || "" }, "itemDetail")
      setSelectedData({
        ...initialData, SKU_ID: option?.value || "",loading:false,
        formData: details?.itemDetail?.formData || {},
        template_id: details?.itemDetail?.template_id || "",
        SKU_Detail: details?.itemDetail?.formData?.SKU_Detail || "",
        precisionType: details?.itemDetail?.formData?.precisionType || "integer",
      })

    }
    else {
      setSelectedData({
        ...initialData, SKU_ID: "", count: selectedData?.count || 0
      });
    }
  }
  const isAddButtonDisabled = () => {
    return !selectedData.SKU_ID ||
      selectedData.count == 0
  };

  useEffect(() => {
    if (action === 'view') {
      setSelectedTemplate(sharedObj.template_id)
      for (const key in sharedObj && sharedObj.formData) {
        setValue(key, sharedObj.formData[key])
      }
    }
    else if (action === 'edit') {
      setSelectedTemplate(sharedObj.template_id)
      for (const key in sharedObj && sharedObj.formData) {
        setValue(key, sharedObj.formData[key])
      }
      getSKUComponentTableDetails()
    }
    else if (action === 'add') {
      if (allTemplates?.length > 0) {
        setSelectedTemplate(allTemplates[templateIndex]?._id)
      }
    }
    else if (action === 'copy') {
      setSelectedTemplate(sharedObj.template_id)
      for (const key in sharedObj && sharedObj.formData) {
        let value = sharedObj.formData[key] 
        if(["SKU_Detail","SKU_ID"].includes(key))value=""
        setValue(key,value )
      }
      getSKUComponentTableDetails()
    }
  }, [action])


  return (
    <>
      <CustomModel

        autoClose={false}
        show={open || false} onClose={() => hide()}
        minWidth={500} maxWidth={open == "Add" && 1100 || 900}
        pt={1} pb={3} pl={3} pr={3}
        maxHeight={"90vh"} viewCloseIcon={["Edit", "Add"].includes(open)}
        childrenMaxHeight={"inherit"}
        // childrenMaxHeight={"85vh"}
        Title={["Edit", "Add"].includes(open) && `${open} Job Type`}
      >
        <Grid container padding={1} data-testid='genricmodal' className='ppmodal'>
          <Grid item xs={24}  >

            <Grid container width='100%'  >
              <Grid container item sm={5} xs={5} sx={{ p: 2 }} position='relative' alignContent="center" alignItems='center' minWidth="100%">
                {step == 2 && <IconButton sx={{ color: theme.themeBlack, textAlign: 'center' }} data-testid="back-btn">
                  <ArrowBackOutlinedIcon onClick={() => {
                    setStep(1)

                  }} /> </IconButton>}
                <Typography sx={{ ...textClasses.t20n, mt: "5px", fontWeight: '800', textTransform: "capitalize" }}>


                  {(["add","copy"]).includes(action)?"add"+ " " + modelInfoSingle[urlPath]["page_label"]: action+ " " + modelInfoSingle[urlPath]["page_label"] } Data
                </Typography>
                {action === 'add' ?
                  <Autocomplete
                    size='small'
                    options={allTemplates && [{ label: '+ Create New', value: 'create', isCreateOption: true }, ...allTemplates.map(dl => ({ label: dl.name, value: dl._id }))]}
                    sx={{ width: 300, marginLeft: '22px' }}
                    onChange={(e, option) => {
                      handleTemplateChange(option)
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          fontSize: "252px",
                          ...selectStylesOverride,
                        }}
                        {...params}

                        label={<Typography sx={{ fontSize: "12px" }}>
                          {['item-master', "kit-master"].includes(urlPath) ? "Select Item Master Template" : '' || urlPath === 'customer-master' ? "Select Customer Master Template" : '' || urlPath === 'supplier-master' ? "Select Supplier Master Template" : ''}
                        </Typography>}
                      />
                    )}

                    renderOption={(props, option) => (

                      <li {...props} style={{ color: option.isCreateOption ? '#ff7200' : '#727376', fontSize: '14px' }}>
                        {option.label}
                      </li>
                    )}
                    value={{ value: selectedTemplate, label: allTemplates.find(dl => dl._id == selectedTemplate)?.name || "" }}
                  />

                  : ''}
                <CloseIcon onClick={() => hide()} className='crossIconPri' />

              </Grid>

            </Grid>
            {
              ["add", "edit","copy"].includes(action) && step == 1 && urlPath=="kit-master" &&
              <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: 'pointer', textAlign: 'right', mr: 3 }}
                onClick={() => {
                  setStep(2)
                }}>
                {["add","copy"].includes(action) ?<Typography><Add fontSize="small"/>Add Kit Components</Typography> : <Typography>Edit Components</Typography> }
              </Typography>
            }

            {
              selectedTemplate !== ''
                ?
                <>

                  <Grid container sx={{ p: 2 }} maxHeight='70vh' >
                    <Grid item sm={12} xs={12} maxHeight='57vh' style={{ overflowY: 'auto', border: '1px solid #E8E8E8', overflowX: 'hidden', padding: '10px', paddingTop: '20px', paddingBottom: '20px' }}>
                      {step == 1 && <DynamicFormRender
                        formHooks={{ register, control, errors, handleSubmit, reset, setValue, action,getValues }}
                        templateId={selectedTemplate}
                        disabled={action=="edit"?["SKU_Detail","SKU_ID"]:[]}
                      />
                      }
                      {["add", "edit","copy"].includes(action) && step == 2 &&
                        <>
                          <Typography sx={{ ...textClasses.t15n, fontWeight: 800, mt: 1 }}>Add Components</Typography>
                          <Grid container sx={{ mt: 2, mb: 1 }}>
                            {false ? "Searching..." :<Grid item xs={12} sm={5} xl={3.5}>
                            {/* {selectedData?.loading ? "Searching..." :<Grid item xs={12} md={5} xl={3}> */}
                              <TypeAndSearch
                                data-testid="skuFilter"
                                fullWidth
                                component={component({ type: "SKU" })}
                                value={selectedData?.SKU_ID}
                                label={selectedData?.SKU_ID}
                                set_Value={true}
                                setEmptyOnChange={false}
                                handleChange={(event, option) => {
                                  handleSkuIdFilter(event, option)
                                }}
                                disabled={selectedData?.loading==true}
                              />
                            </Grid>}


                          <Grid item xs={"auto"} sx={{ ml: 2}} data-testid="rangefilter">
                            {/* <GenericUserInputComponent
                              label="Per Kit QTY"
                              value={selectedData?.count}
                              onChange={(newValue) => {
                                setSelectedData({ ...selectedData, count: newValue })
                              }}
                              width="218px"
                            /> */}
                            <GenericSingleRangeComponent
                              startRange={selectedData?.count}
                              endRangeInfinity={true}
                              getOnChange={(count) => setSelectedData({ ...selectedData, count })}
                              valueType={"decimal"}
                              // valueType={selectedData?.precisionType || "integer"}
                              style={{height:"35px"}}
                              disabled={selectedData?.loading==true || !selectedData.SKU_ID}
                            />
                          </Grid>

                          <Grid item xs={"auto"}>
                              <Button
                                data-testid="addBtn"
                                variant="contained"
                                spacing={1}
                                disabled={isAddButtonDisabled()}
                                onClick={handleAddTableData}
                                sx={{
                                  ...buttonClasses.lynkitBlackFill,
                                  // position:"relative",
                                  // left:"-10px"
                                  ml: 1,
                                  fontSize: "12px",
                                  maxHeight: "40px!important",
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                            <Grid item xs={"auto"} sx={{ml: 1}}>
                              <GenericCustomiseHeader
                                tableName={"kitComponentTable"}
                                tableType={"kitComponentTable"}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {selectedEntries?.length > 0 &&
                                <Button
                                  size="small"
                                  variant="contained"
                                  sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    mr: 1, mt: 1, 
                                    textTransform: "none",
                                  }}
                                  onClick={handleDeleteButton}
                                >
                                  Delete
                                </Button>


                              }
                            </Grid>
                          </Grid>
                          {tableData?.length > 0 &&
                            <GenericTable
                              data-testid="addKitMasterTable"
                              header={header}
                              rows={tableRows ||   []}
                              pageCount={tableData.length }
                              pageNumber={pagination.page}
                              handleChangePage={(event, pagevalue) => {
                                setPagination({ ...pagination, page: pagevalue })
                              }}
                              handleChangeRowsPerPage={(event) => {
                                setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                              }}
                              rowsPerPage={pagination.limit}
                            />
                          }
                        </>
                      }

                    </Grid>
                    {dialogData && <DetailDialog
                      data={dialogData}
                      onClose={handleCloseDetail}
                    />}

                    <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}  >
                      {(["add","copy"]).includes(action) ?
                        <>
                          {
                            step == 1 ?
                              <Button
                                variant='outlined'
                                size='small'
                                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                                onClick={handleClearAll}>
                                Clear All
                              </Button> :
                              <Button
                                variant='outlined'
                                size='small'
                                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                                onClick={() => { setStep(1) }}>
                                Back
                              </Button>
                          }
                          <Button
                            variant='contained'
                            size='small'
                            sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                            onClick={() => saveItemEntry()} >
                            Submit
                          </Button>


                        </>
                        : ''}

                      {
                        action === 'view' ?
                          <>
                            <Button
                              variant='outlined'
                              size='small'
                              sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                              onClick={() => hide()}>
                              Cancel
                            </Button>
                          </>
                          : ''}

                      {action === 'edit' ?
                        <>
                          <Button
                            variant='outlined'
                            size='small'
                            sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                            o onClick={() => hide()}>
                            Cancel
                          </Button>
                          <Button
                            variant='contained'
                            size='small'
                            sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                            onClick={() => editItemEntry()} >
                            Update
                          </Button>


                        </>
                        : ''}

                    </Grid>
                  </Grid>

                </>
                :
                <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }} height="70vh" >
                  <img src={SelectImage} />
                  <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>{modelInfoSingle[urlPath]["page_header"]}</Typography>
                  <Typography sx={{ ...textClasses.t13n, textAlign: "center", maxWidth: "600px", mt: 2 }}>{modelInfoSingle[urlPath]["page_detail"]}</Typography>
                  {/* <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>Please select an Item Master template first.</Typography>
                                    <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                                        Hey, you need to select a previously created Item Master data entry template, to enter details for master SKU's/Items and to automate the Inward operation(s).
                                    </Typography> */}

                </Grid>
            }

          </Grid>


          {/* <Grid item sm={12} flexDirection={'column'} paddingRight={2} sx={{ width: '100%', height: "auto", overflow: "auto" }}>
                        <Typography variant='h6' sx={{ paddingLeft: "15px", fontSize: "20px", fontWeight: "600" }}>{action === "edit" ? 'Edit' : (action === "view") ? "View" : 'Add New'} Item</Typography>
                        <Box marginTop={1} sx={{height:'0px' ,maxHeight:"200px",overflowY:"scroll", overflow: "auto" }}>

                        {Object.entries(sharedObj.formData).map(([key, value]) =>{return(
                                    <Box margin={2}>
                                        <FormControl variant='outlined' size='small' fullWidth error>
                                            <OutlinedInput
                                                value={value}
                                                required
                                                disabled={action === "view" ? true : false}
                                                error={errorMessage.name ? true : false}
                                                type='text'
                                                sx={{ fontSize: "12px", boxShadow: 1, "& input::placeholder": { fontSize: "14px", fontWeight: "400" } }}
                                                placeholder={key}
                                            />
                                        </FormControl>
                                    </Box>
                                    ) })}


                           

                        </Box>

                        {<Box sx={{ marginTop: "10px", padding: "0px 10px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => { hide()}}
                                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem" }}

                            >Cancel</Button>
                            {action !== 'view' ?
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none' }}
                                    onClick={() => handleValidationSubmit()}
                                > {action === "addnew" ? 'Create' : 'Update'}</Button>
                                : null}
                        </Box>}
                    </Grid> */}

        </Grid>

      </CustomModel>
      <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
    </>
  )
}

export default AddViewEdit;
