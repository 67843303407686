import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    FETCH_TEMPLATE,
    FETCH_TEMPLATES,
    FETCH_STD_COMPS,
    FETCH_PRV_COMPS,
    SAVE_TEMPLATE,
    FETCH_TEMPLATES_ERR,
    FETCH_USERS,
    FETCH_STATUS_COUNT,
    FETCH_REGEX_LIST,
    FETCH_ALL_TEMPLATE_TYPES,

  } from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";


const form={
    "_id": "657ad7cfbd3db52cf83ac585",
    "attributes": [
        {
            "handle": true,
            "_id": "SKU_ID",
            "type": "input",
            "icon": "simple-icon-note",
            "label": "SKU ID",
            "description": "Enter your SKU ID",
            "placeholder": "Enter your SKU ID",
            "className": "form-control",
            "subtype": "text",
            "forType": "table",
            "required": true,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "value": "",
            "templateType": "itemMaster",
            "elementType": "Predefined Element",
            "sortType": 1,
            "removable": false,
            "staticLabel": true,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "renderOption": [],
            "extraValue": [],
            "dynamic": false,
            "formulaFields": [],
            "regex": ""
        },
        {
            "handle": true,
            "_id": "SKU_Detail",
            "type": "input",
            "icon": "simple-icon-note",
            "label": "SKU Detail",
            "description": "Enter SKU Detail",
            "placeholder": "Enter SKU Detail",
            "className": "form-control",
            "subtype": "text",
            "forType": "table",
            "required": true,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "value": "",
            "templateType": "itemMaster",
            "elementType": "Predefined Element",
            "sortType": 1,
            "removable": false,
            "staticLabel": true,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "renderOption": [],
            "extraValue": [],
            "dynamic": false,
            "formulaFields": [],
            "regex": ""
        },
        {
            "_id": "UOM",
            "type": "select",
            "label": "Unit Of Measurement (UOM)",
            "description": "unit of measurements",
            "placeholder": "select Unit",
            "required": true,
            "dynamic": true,
            "auth": true,
            "elementType": "Selection Type",
            "apiDomain": "/v1/",
            "apiEndPoint": "base/getUOMList",
            "forType": "all",
            "apiMethod": "post",
            "name": "UOM List",
            "apiDataReturnKey": "data",
            "apiParameters": [
                {
                    "label": "page",
                    "value": 1
                },
                {
                    "label": "limit",
                    "value": 25
                },
                {
                    "label": "unitName",
                    "value": "onSearch"
                },
                {
                    "label": "unitValue",
                    "value": "onSearch"
                }
            ],
            "fetched": "",
            "templateType": "itemMaster",
            "sortType": 2,
            "values": [],
            "removable": false,
            "apiBindingKey": "unitName",
            "apiBindingValue": "unitValue",
            "delete": false,
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "formulaFields": [],
            "extraValue": [
                {
                    "label": "precisionType",
                    "value": "precisionType"
                }
            ],
            "renderOption": [
                {
                    "label": "",
                    "multiple": true,
                    "prefix": "",
                    "postfix": "",
                    "key": "",
                    "keys": [
                        "unitName",
                        "UOM"
                    ]
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " (",
                    "postfix": ")",
                    "key": "precisionType"
                }
            ],
            "regex": ""
        },
        {
            "_id": "e5185c6b-b57e-4679-877c-1f2b312628a7",
            "type": "textarea",
            "icon": "iconsmind-Text-Box",
            "label": "Long Text",
            "className": "form-control",
            "forType": "all",
            "elementType": "Text and input",
            "sortType": 1,
            "removable": false
        },
        {
            "_id": "cd438b5b-ae02-48f3-9472-0bfb86b95f87",
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Number",
            "description": "Enter your number",
            "placeholder": "Enter your number placeholder",
            "className": "form-control",
            "subtype": "text",
            "regex": "^[0-9]*$",
            "handle": true,
            "forType": "all",
            "elementType": "Text and input",
            "sortType": 1,
            "defaultValueFunction": "((function(){return 100})())",
            "setDefaultValue": true,
            "isPredefinedElement": false,
            "removable": false
        },
        {
            "_id": "standardWeight",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Standard Weight",
            "description": "Enter weight ",
            "placeholder": "Enter weight",
            "className": "form-control",
            "subtype": "text",
            "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "",
            "max": "",
            "required": false,
            "multiple": true,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "sortType": 1,
            "removable": false,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "length",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Length (in inches)",
            "description": "Enter length ",
            "placeholder": "Enter length in inches",
            "className": "form-control",
            "subtype": "text",
            "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "removable": false,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "width",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Width",
            "description": "Enter width ",
            "placeholder": "Enter width",
            "className": "form-control",
            "subtype": "text",
            "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "removable": false,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "height",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Height",
            "description": "Enter height ",
            "placeholder": "Enter height",
            "className": "form-control",
            "subtype": "text",
            "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "removable": false,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "shelfLife",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-calendar",
            "label": "Self Life",
            "placeholder": "Self Life",
            "className": "form-control datepicker",
            "subtype": "text",
            "forType": "all",
            "elementType": "Predefined Element",
            "sortType": 1,
            "delete": false,
            "hideDataForInvoice": false,
            "hideLabel": false,
            "multiple": false,
            "required": false,
            "conditionalView": false,
            "sourceElement": "",
            "sourceValue": "",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "isForBarcode": false,
            "showInLabel": false,
            "regex": "^[0-9]*$",
            "format": "DD MMM YYYY",
            "errorText": "Integer value only",
            "removable": false,
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "avgCount",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Avg. Demand",
            "description": "Avg. Demand",
            "placeholder": "Avg. Demand",
            "className": "form-control",
            "subtype": "text",
            "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "1",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "removable": false,
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "leadTime",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Lead Time",
            "description": "Lead Time",
            "placeholder": "Lead Time",
            "className": "form-control",
            "subtype": "text",
            "regex": "^[1-9][0-9]*$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "1",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "dependOn": [
                {
                    "label": "Min Stock",
                    "value": "minCount"
                },
                {
                    "label": "Max Stock",
                    "value": "maxCount"
                },
                {
                    "label": "Avg/Demanded Stock",
                    "value": "avgCount"
                }
            ],
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "removable": false,
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "minCount",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Min Stock",
            "description": "Min Stock",
            "placeholder": "Min Stock",
            "className": "form-control",
            "subtype": "text",
            "regex": "^[1-9][0-9]*$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "extraCondition": {
                "lte": "$maxCount"
            },
            "removable": false,
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "maxCount",
            "handle": true,
            "staticLabel": true,
            "type": "number",
            "icon": "simple-icon-note",
            "label": "Max Stock",
            "description": "Max Stock",
            "placeholder": "Max Stock",
            "className": "form-control",
            "subtype": "text",
            "regex": "^[1-9][0-9]*$",
            "forType": "table",
            "errorText": "Enter numeric value only",
            "min": "1",
            "max": "",
            "required": false,
            "multiple": false,
            "hideLabel": false,
            "delete": false,
            "elementType": "Predefined Element",
            "isPredefinedElement": true,
            "isStaticValue": true,
            "defaultVisible": "N/A",
            "isForBarcode": false,
            "showInLabel": false,
            "sortType": 1,
            "format": "DD MMM YYYY",
            "setDefaultValue": false,
            "defaultValueFunction": "",
            "renderFunction": "",
            "extraCondition": {
                "gte": "$minCount"
            },
            "removable": false,
            "renderOption": [],
            "extraValue": []
        },
        {
            "_id": "DHwu8LI03UWzoou3Q0rLU",
            "type": "checkbox",
            "required": false,
            "label": "Checkbox",
            "icon": "simple-icon-list",
            "description": "Checkbox",
            "inline": true,
            "values": [
                {
                    "label": "Option 1",
                    "value": "option 1"
                },
                {
                    "label": "Option 2",
                    "value": "option 2"
                },
                {
                    "label": "Option 3",
                    "value": "option 3"
                }
            ],
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2,
            "value": [],
            "isPredefinedElement": false,
            "placeholder": "c",
            "isForBarcode": false,
            "showInLabel": false,
            "removable": false
        },
        {
            "_id": "DHwu8LI03UWzoou3Q0rbc",
            "type": "select",
            "required": false,
            "label": "Select",
            "icon": "simple-icon-list",
            "description": "Select",
            "inline": true,
            "values": [
                {
                    "label": "Option 1",
                    "value": "option 1"
                },
                {
                    "label": "Option 2",
                    "value": "option 2"
                },
                {
                    "label": "Option 3",
                    "value": "option 3"
                }
            ],
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2,
            "value": "",
            "isPredefinedElement": false,
            "placeholder": "c",
            "isForBarcode": false,
            "showInLabel": false,
            "removable": false
        }
    ],
    "otherTemplate": [],
    "type": "wms",
    "frequency": 6,
    "totalElement": 16,
    "status": "published",
    "isDeleted": false,
    "name": "As Packagee Item",
    "description": "as pack",
    "creationDate": "2023-12-14T10:24:14.993Z",
    "templateType": "itemMaster",
    "templateCategory": "entityMaster",
    "base": "Coding Ninjas",
    "createdBy": {
        "base": "Coding Ninjas",
        "_id": "633ff4fd65af23502ffbc0b5",
        "email": "codingninjas@gmail.com",
        "company": "Ninja Coding",
        "name": "Admin_CN"
    },
    "templateCode": "AKX",
    "dataEntryType": "package",
    "createdAt": "2023-12-14T10:24:15.014Z",
    "updatedAt": "2024-12-27T11:39:14.167Z",
    "__v": 0,
    "lastUsedTime": "2024-09-10T13:57:33.395Z",
    "modifiedOn": "2024-12-27T11:39:14.157Z"
}
export const getTemplateTypes = (cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateTypes', {
            fetchSingle: false
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_TEMPLATE_TYPES, payload: data.data})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_TEMPLATE_TYPES, payload: []})
                
            }
            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getTemplate = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateDetail', payload).then(({data}) => {
        // data.data=form
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_TEMPLATE, payload: data.data})
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_TEMPLATE, payload: {}})
        }

        if(cb){
            cb(data?.data || {})
        }
       
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};

export const getConnectedTemplate = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateDetail', payload).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            // dispatch({ type: FETCH_TEMPLATE, payload: data.data})
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            // dispatch({ type: FETCH_TEMPLATE, payload: {}})
        }

        if(cb){
            cb(data?.data || {})
        }
       
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};
  
export const getAllTemplates = (body, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateList',body).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_TEMPLATES, payload: {row: data.data, count: data.count}})
        } 
        else {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({type: FETCH_TEMPLATES_ERR, payload: data.message});
            dispatch({ type: FETCH_TEMPLATES, payload: {row: [], count: 0}})
        }

        if(cb){
            cb(data?.data || [], data?.count || 0);
        }

        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const getStandardComonents = ({ type }) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getStandardComponentList', {
            type: type,
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_STD_COMPS, payload: data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getPreviousComonents = ({ type }) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getUsedComponentList', {
            type: type,
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_PRV_COMPS, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const saveTemplate = (body, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/saveTemplateDetailV2', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback(data) 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Template created successfully", false)
            dispatch(getAllTemplates())
            cb(data.data);
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("Error****:", error.message);
        });
    }
};

export const editTemplate = (body, callback) =>
{
    return (dispatch) =>
    {
        dispatch({ type: FETCH_START });
        axios.post('dynamicForm/editTemplateDetails', body).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast("Template edited successfully", false)
                // dispatch(getAllTemplates())
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                // showToast(data.message, true);
            }
            if (callback) {
                callback({
                    success: data.success || false,
                    message: data.message || (data.success && "Template Edit successfully" || "Some error occure, please try after some time")
                })
            }
        }).catch(function (error) {
            // showToast(error.message, true);
            if (callback) {
                callback({ success: false, message: error.message })
            }
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
        });
    }
};

// export const getTemplate = ({templateId}) =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('dynamicForm/getTemplateDetail', {
//             template_id: templateId
//         }
//         ).then(({data}) => {
//         if (data.success) 
//         {
//             callback();
//         } 
//         else 
//         {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//         }

//         function callback() 
//         {
//             dispatch({ type: FETCH_SUCCESS });
//             dispatch({ type: FETCH_TEMPLATE, payload: data.data})
//         }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             //("Error****:", error.message);
//         });
//     }
// };

// export const getTemplateTypes = () =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('dynamicForm/getTemplateDetail', {
//             template_id: templateId
//         }
//         ).then(({data}) => {
//         if (data.success) 
//         {
//             callback();
//         } 
//         else 
//         {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//         }

//         function callback() 
//         {
//             dispatch({ type: FETCH_SUCCESS });
//             dispatch({ type: FETCH_TEMPLATE, payload: data.data})
//         }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             //("Error****:", error.message);
//         });
//     }
// };


export const getStatusCount = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateStatusCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getRegexList = (payload={}) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getRegexList',payload
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_REGEX_LIST, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const changeTemplateStatus = (body, cb) => {
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/changeTemplateStatus', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Template status changed successfully", false)
            // dispatch(getAllTemplates())
            if(cb){
                cb();
            }
            
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("Error****:", error.message);
        });
    }
};


export const removeTemplates = (body, cb) => {
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/deleteTemplate', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Template deleted successfully", false)
            // dispatch(getAllTemplates())
            if(cb){
                cb();
            }
            cb();
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("Error****:", error.message);
        });
    }
};