import {
    FETCH_ERROR,
    FETCH_SUCCESS, FETCH_START, FETCH_INWARD_WORKFLOW_LISTING, FETCH_INHOUSE_WORKFLOW_LISTING, FETCH_OUTWARD_WORKFLOW_LISTING, FETCH_WORKFLOW_DETAILS,
    FETCH_WORKFLOW_TYPEWISE_COUNT,
} from "../types"
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const saveWorkflow = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/saveWorkflow', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getWorkflowList = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/getWorkflow', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_WORKFLOW_DETAILS, payload: data?.data || {} })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_WORKFLOW_DETAILS, payload: {} })
            }


            // if (data.success) {
            //     dispatch({ type: FETCH_SUCCESS });
            //     if (workflowType == "inwardOperations") {
            //         dispatch({ type: FETCH_INWARD_WORKFLOW_LISTING, payload: { data: data?.data || {}, count: data?.count } })
            //     } else if (workflowType == "inHouseOperations") {
            //         dispatch({ type: FETCH_INHOUSE_WORKFLOW_LISTING, payload: { data: data?.data || {}, count: data?.count } })
            //     } else {
            //         dispatch({ type: FETCH_OUTWARD_WORKFLOW_LISTING, payload: { data: data?.data || {}, count: data?.count } })
            //     }
            // }
            // else {
            //     dispatch({ type: FETCH_ERROR, payload: data.message });
            //     dispatch({ type: FETCH_INWARD_WORKFLOW_LISTING, payload: { data: {}, count: 0 } })
            //     dispatch({ type: FETCH_INHOUSE_WORKFLOW_LISTING, payload: { data: {}, count: 0 } })
            //     dispatch({ type: FETCH_OUTWARD_WORKFLOW_LISTING, payload: { data: {}, count: 0 } })
            // }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getWorkflowDetails = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/getWorkflowDetails', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_WORKFLOW_DETAILS, payload: data?.data || {} })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_WORKFLOW_DETAILS, payload: {} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getWorkflowTypeWiseCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/getWorkflowTypesWiseCounts', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_WORKFLOW_TYPEWISE_COUNT, payload: data?.data || {} })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_WORKFLOW_TYPEWISE_COUNT, payload: {} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};



export const deleteWorkflows = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/deleteWorkflow', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const disableEnableWorkflow = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/updateWorkflowStatus', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const updateWorkflow = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/updateWorkflow', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};