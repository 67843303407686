
import React, { useEffect } from 'react';
import { Grid, Button, Typography, Divider } from '@mui/material';
import { textClasses, buttonClasses } from '../../../static/styles/theme';
import { renderHeadersFilter } from '../../../services/functions';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import CloseIcon from '@mui/icons-material/Close';



const GenericAdvanceFilterComponent = ({
  validHeader = [],
  applyAdvanceFilter,
  tabIndex,
  isFilterOpen = "",
  advancedTempFilter,
  setAdvancedTempFilter,
  initAdvTempFilters,
  toggleDrawer,
  showStatusFilterIndex=[0]
}) => {


  const list = (anchor) => (
    <Grid container sx={{ mt: 8, width: "35vw", p: 1.5 }} spacing={2}>
      <Grid container p={1}>
        <Grid container item xs={9} justifyContent={"space-around"}>
          <Grid item xs={1} sx={{ cursor: 'pointer' }}>
            <CloseIcon
              onClick={() => toggleDrawer(anchor, false)()} />
          </Grid>
          <Grid item xs={10}>
            <Typography sx={{ ...textClasses.t20n, fontWeight: 600 }} >Advance Filter(s)</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={3} justifyContent={"flex-end"} >
          <Button
            data-testid='upload'
            variant="contained"
            size="small"
            sx={{
              ...buttonClasses.lynkitOrangeFill,
              textTransform: 'none'
            }}
            onClick={() => {
              applyAdvanceFilter(advancedTempFilter)
              toggleDrawer(anchor, false)();

            }}
          >
            {`Apply(${Object.values(advancedTempFilter?.search || {})?.filter(dl => typeof dl == "object" && !Array.isArray(dl)? dl?.value : dl?.length)?.length
              +
              Object.values(advancedTempFilter?.other || {})?.filter(dl => typeof dl == "object" && !Array.isArray(dl)? dl?.value :dl?.length)?.length})`
            }
          </Button>

        </Grid>
        <Grid item xs={12} sx={{ my: 1 }}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2} m={1} sx={{
        overflowY: 'auto',
        maxHeight: '70vh',

      }}>
        {renderHeadersFilter(validHeader, advancedTempFilter, setAdvancedTempFilter, "", "", tabIndex, true,showStatusFilterIndex)}
      </Grid>
      <Grid container justifyContent="flex-start" spacing={2} sx={{ mt: 1, ml: 1 }}>
        <Grid item >
          <Button
            data-testid="clearall-btn"
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.lynkitOrangeEmpty,
              minWidth: 120
              // color: theme.themeOrange,
            }}
            onClick={() => {
              setAdvancedTempFilter({ ...initAdvTempFilters });
            }}
          >
            Clear All
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  // console.log(advancedTempFilter, "advancedTempFilter")
  return (
    <Grid item xs={"auto"} alignItems={"center"}>
      {isFilterOpen==true && <SwipeableDrawer
        anchor="right"
        open={isFilterOpen==true}
        onOpen={toggleDrawer('right', true)}
      >
        {list('right')}
      </SwipeableDrawer>
      }
    </Grid>
  );
};

export default GenericAdvanceFilterComponent;
