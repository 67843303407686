import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button, Typography, Box, Grid, Dialog,
    DialogContent, IconButton
} from '@mui/material'
import { deleteWorkflows, disableEnableWorkflow } from '../../../redux/actions';
import theme, { buttonClasses, textClasses } from '../../../static/styles/theme';
import { showToast, textCapitalize } from '../../../services/functions';
import { Cancel } from "@mui/icons-material"

function ConfirmDialogPopUp(props) {
    const dispatch = useDispatch();
    const { status,
        handleClose,
        selectedEntries,
        setSelectedEntries,
        refreshTableData,
        handleRemoveSelection,
        closeEditView
        // selectedTab
    } = props
    const [showAll, setShowAll] = useState(false);
    const [isAPICalled, setIsAPICalled] = useState("");
    const size = selectedEntries.length;

    const handleStatusChange = () => {
        if (status == "delete") {
            setIsAPICalled("apiCalled")
            let payload = {
                objectId: selectedEntries?.map((e) => e.objectId)
            };
            dispatch(deleteWorkflows(payload, callBack));
        }
        else {
            setIsAPICalled("apiCalled")
            let payload = {
                objectId: selectedEntries?.map((e) => e.objectId),
                approved: status == "enable" ? true : false,
            };
            dispatch(disableEnableWorkflow(payload, callBack));
        }
    };

    const callBack = (data) => {
        showToast(data.message, !data.success);
        setIsAPICalled("")
        if (data.success) {
            refreshTableData();
            handleClose();
            // closeEditView();
            // Close the EditView modal.
            // if (closeEditView) {
            //   }

            // if (setSelectedEntries) {
            //   setSelectedEntries([]);
            // }
        }
    };
    return (
        <Dialog
            open={status != null}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>

                <Box>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>You want to {status} workflow ?</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1 }} spacing={1}>
                        {selectedEntries?.slice(0, showAll ? size : 5).map((st, i) => {
                            return (
                                <Grid item sm={4} xs={6} key={i}>
                                    <Grid
                                        container
                                        direction={"row"}
                                        justifyContent="center"
                                        alignItems={"center"}
                                        sx={{
                                            backgroundColor: theme.themeLightOrange,
                                            border: `1px solid ${theme.themeOrange}`,
                                            borderRadius: "12px",
                                            pl: 1,
                                            pr: 1,
                                            minHeight: "40px",
                                        }}
                                    >
                                        <Grid item sm={10} xs={8}>
                                            <Typography
                                                sx={{
                                                    ...textClasses.normalText,
                                                    color: theme.themeOrange,
                                                    textAlign: "start",
                                                }}
                                            >
                                                {
                                                    st?.workflowName && st?.workflowName?.length > 15
                                                        ? st?.workflowName?.substring(0, 15) + "..."
                                                        : st?.workflowName
                                                }
                                            </Typography>
                                        </Grid>
                                        {handleRemoveSelection && (
                                            <Grid item sm={2} xs={4}>
                                                <IconButton
                                                    sx={{ alignContent: "right" }}
                                                    onClick={() => handleRemoveSelection(st._id)}
                                                >
                                                    <Cancel sx={{ color: theme.themeOrange }} />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                        {size > 5 && (
                            <>
                                <Grid
                                    container
                                    item
                                    sm={4}
                                    xs={6}
                                    justifyContent="center"
                                    alignItems={"center"}
                                >
                                    <Typography
                                        sx={{
                                            ...textClasses.boldText,
                                            color: theme.themeOrange,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setShowAll(!showAll)}
                                    >
                                        {showAll ? <>Collapse</> : <>View All ({size})</>}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {/* <Box padding={3} sx={{ display: "flex", justifyContent: "center", flexWrap:'wrap' }}>
                    </Box> */}
                    <Grid container sx={{ p: 1, mt: 3 }}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                ...buttonClasses.lynkitBlackFill,
                                height: "40px",
                                minWidth: "150px",
                            }}
                            disabled={isAPICalled == "apiCalled"}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ml: 1,
                                ...buttonClasses.small,
                                backgroundColor: theme.themeOrange,
                                minWidth: "150px",
                            }}
                            disabled={selectedEntries.length <= 0 || isAPICalled == "apiCalled"}
                            onClick={handleStatusChange}
                        >
                            {textCapitalize(status)}
                        </Button>
                    </Grid>
                </Box>

            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialogPopUp;
