import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Autocomplete, CardHeader, CircularProgress, TextField
} from "@mui/material";
import Paper from '@mui/material/Paper';
import { Add, ExpandMore, Search, Edit, Delete, CheckCircleOutlineIcon, Block, CheckCircleOutline } from '@mui/icons-material';
import colors, { buttonClasses, text } from '../../../static/styles/theme';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { getWorkflowList, getCompany, getWorkflowTypeWiseCount } from '../../../redux/actions';
import group from '../../../static/images/templates/Group.png'
import pana from '../../../static/images/templates/pana.svg'
import theme, { textClasses } from "../../../static/styles/theme";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditView from './EditView'
import ConfirmDialogPopUp from './ConfirmDialogPopUp';
import MultiSelectAll from '../MainDashboard/MultiSelect';
import GenericLoader from '../../Components/Generic/GenericLoader';

const Workflow = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const warehouseDetail = useSelector(
        (state) => state.superadmin.warehouseDetail
    );
    const [editView, setEditView] = useState(false)
    const [loader,setLoader] = useState(false)
    const [selectedWarehouses, setSelectedWarehouses] = useState([])
    const [item, setItem] = useState({})
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [initialRender, setInitialRender] = useState(true)
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 3,
        hasMoreData: true
    });
    const [confirmModal, setConfirmModal] = useState(null);
    const [workflowTypewiseCount, setWorkflowTypewiseCount] = useState({})
    const [workflowState, setworkflowState] = useState([
        {
            type: 'Workflows',
            subText: '* All  process steps in their completion order. you can create multiple workflows and update them anytime.',
            operations: [
                // {
                //     workflowName: 'Workspace-1',
                //     tasks: [
                //         // {
                //         //     taskNames: 'Advanced Shipping notice.'
                //         // },

                //     ]
                // },
            ]

        }
    ])
    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);


        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 150;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 150;
        };
    }

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    };
    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map((o) => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
            if (oldEntries.length == 0) {
                handleCloseConfirmModal();
            }
        }
    };
    const handleWarehouseMultiSelect = (result) => {
        setPagination({...pagination,page:0,limit:3})
        setSelectedWarehouses(result)
    }
    const handleCloseEditView = () => {
        setEditView(false);
    };
    const getWorkflowListingData = (page, limit, fetchCount = false) => {
        setLoader(true)
        const tempWorkflowState = [...workflowState]; // Create a copy of the state
        const payload = {
            ...(selectedWarehouses?.length > 0 && { warehouseId: selectedWarehouses.map((dl) => dl?._id) }),
            page: page,
            limit: limit,
        };
        dispatch(
            getWorkflowList(payload, (data) => {
                setLoader(false)
                const transformedWorkflowState = data?.data?.map((workflow) => ({
                    workflowName: workflow?.workflowName,
                    workflowScope: workflow?.workflowScope,
                    workflowType: workflow?.workflowType,
                    objectId: workflow?._id,
                    isEnabled: workflow?.approved,
                    tasks: workflow?.jobTypesId,
                }));
                tempWorkflowState.at(0).operations = transformedWorkflowState;
                setworkflowState(tempWorkflowState);
                // Calculate whether more data exists
                const totalLoadedItems = ((page - 1) * limit) + data?.data?.length;
                setPagination((prev) => ({
                    ...prev,
                    page: page,
                    hasMoreData: totalLoadedItems < data?.count,
                }));
            })
        );

        if (fetchCount) {
            dispatch(
                getWorkflowTypeWiseCount({}, (data) => {
                    setWorkflowTypewiseCount(data?.data);
                })
            );
        }
    };

    // }
    useEffect(() => {
        getWorkflowListingData(pagination?.page + 1, pagination?.limit, initialRender);
        setConfirmModal(null);
        if (initialRender) {
            dispatch(getCompany({ type: "warehouse", status: "active", projection: { name: 1, type: 1 } }));
            setInitialRender(false)
        }
    }, [selectedWarehouses])

    useEffect(() => {
        if (initialRender) {
            dispatch(getCompany({ type: "warehouse", status: "active", projection: { name: 1, type: 1 } }));
            dispatch(getWorkflowTypeWiseCount({}, (data) => {
                setWorkflowTypewiseCount(data?.data)
            }));
            setInitialRender(false)
        }

    }, []);

    return (

        <>
            {loader?(
        <Grid
          data-testid="loader"
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          sx={{ p: 10 }}
        >
          <Grid item>
            <GenericLoader />
          </Grid>
        </Grid>
      ):(!workflowTypewiseCount || !workflowTypewiseCount.totalWorkflow || workflowTypewiseCount?.totalWorkflow === 0) ?
                <div>
                    <Grid container>
                        <Grid item sm={6}>
                            <Typography variant="h6">
                                Manage Workflows
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            sm={6}
                            justifyContent="end"
                            alignItems="end"
                            gap={1}
                        >
                        </Grid>

                    </Grid>

                    <div className='pOuterParent' >
                        <div className='pInnerTop pAlignCent'>
                            <img src={pana} alt='' />
                        </div>
                        <div className='pInnerBody pAlignCent pjustifySBtw'>
                            <span style={{ color: '#ff7200', fontSize: '24px', fontWeight: '700' }}>Make your workflow your way</span>
                            <span style={{ color: '#000', fontSize: '14px', fontWeight: '600' }}>Workflows respresent your warehouse operations and control how the team member's progress their work/assigned jobs(s).</span>
                            <Button
                                variant="contained"
                                size="small"
                                data-testid='create-workflow'
                                startIcon={<Add />}
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    paddingTop: "5.5px",
                                    paddingBottom: "5.5px",
                                }}
                                onClick={() => history.push("/dashboard/setting/create-workflow")}
                            >
                                Create Workflow(s)
                            </Button>

                        </div>
                        <div className='pInnerBottom '>
                            <div className='pCard' data-testid='table1'>
                                <div className='pCardTop' style={{ color: '#000', fontSize: '14px', fontWeight: '800' }}>
                                    Steps to be followed :
                                </div>
                                <div className='pCardBody'>
                                    <span className='cardPoints'>• Click on 'Create Workflow' button, It redirects to a new page.</span>
                                    <span className='cardPoints'>• To create workflow, select warehouse from the dropdown first.</span>
                                    <span className='cardPoints'>• You will find a default warehouse workflow, here you needs to add the process steps in their completion order.</span>
                                    <span className='cardPoints'>• Drag and drop standard warehouse operations steps to create the your workflow. </span>
                                    <span className='cardPoints'>• Use options to add new steps and re-order the same. </span>
                                    <span className='cardPoints'>• After creating, submit and it’s done. </span>
                                </div>

                            </div>
                            <div className='pCard' data-testid='table2'>
                                <div className='pCardTop' style={{ color: '#000', fontSize: '14px', fontWeight: '800' }}>
                                    What is a warehouse workflow ?
                                </div>
                                <div className='pCardTop' style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginTop: '10px' }}>
                                    Two concepts define the warehouse workflow-
                                </div>
                                <div className='pCardBody'>
                                    <span className='cardPoints' style={{ marginBottom: '15px' }}>1.) Operations : the steps in your warehouse operations that describe the journey of any shipments, packages or items with in the warehouse.</span>
                                    <span className='cardPoints'>2.) Transitions : how an shipment, packages or items can move between operations or statuses.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Grid container sx={{ flexDirection: "row" }}>
                    <Grid container sx={{display:'flex',justifyContent:'space-between'}}>
                    <Grid item sm={4}>
                        <Typography sx={{ fontSize: "24px", fontWeight: 600 }} data-testid='manage'>
                            Manage Workflow(s)
                        </Typography>
                    </Grid>
                    <Grid item container sm={8} justifyContent="end" alignItems="end">
                    <Button
                                    data-testid='add-workflow'
                                    variant="contained"
                                    size="small"
                                    startIcon={<Add />}
                                    sx={{ ...buttonClasses.lynkitOrangeEmpty, textTransform: 'none' }}
                                    onClick={() => history.push('/dashboard/setting/create-workflow')}
                                    // onClick={() => { handleAction('', '', "add") }}
                                >
                                    Add New
                                </Button>
                    </Grid>
                    </Grid>
                    <div className="customCard">
                        <div className="customCardLeft" data-testid='mainTable' >
                            <div className='clHeader' >
                                <span >My Workflow(s)</span>
                                <div style={{ width: '300px' }}>
                                    <MultiSelectAll
                                        data-testid="select-warehouses"
                                        sx={{
                                            width: "100%",
                                        }} items={warehouseDetail?.data || []}
                                        selectAllLabel="Select All"
                                        value={selectedWarehouses}
                                        onChange={handleWarehouseMultiSelect}
                                        label="Select Warehouse"
                                        defaultSelectAll={false}
                                    />
                                </div>
                            </div>
                            <div style={{ borderBottom: '1px solid #D9D9D9', width: '97%', margin:'15px' }}>
                                <span style={{ fontSize: '12px' }}>

                                </span>
                            </div>
                            {workflowState.map((item, Parentindex) => {
                                return (
                                    <div key={Parentindex} className="pcardContainer" data-testid="workflow-table" >
                                        <div className="pcardCTop" style={{ paddingTop: '0px' }}>
                                            <span data-testid='workflow-heading' className="workflowName">{item.type} ({workflowTypewiseCount?.totalWorkflow || 0})</span>
                                            <span className="operationText">{item.subText}</span>
                                        </div>

                                        <div className="pcardCBodyNew" >
                                            <div className='viewCardArrow' style={{ position: 'absolute', left: '0px' }} data-testid='workflow-leftpageicon'>
                                                <ChevronLeftIcon
                                                    id={`slideLeft-${Parentindex}`}
                                                    style={{
                                                        cursor: pagination?.page > 1 ? "pointer" : "not-allowed",
                                                        opacity: pagination?.page > 1 ? 1 : 0.2,
                                                    }}
                                                    onClick={() => {
                                                        if (pagination?.page > 1) {
                                                            const newPage = pagination?.page - 1;
                                                            setPagination((prev) => ({ ...prev, page: newPage }));
                                                            slide(Parentindex);
                                                            getWorkflowListingData(newPage, pagination.limit);
                                                        }
                                                    }}
                                                />
                                            </div>


                                            <div className='ccontaoner' >
                                                {/* <div className='constantCard' data-testid='addWorkflow' style={{ display: 'flex', justifyContent: 'center', marginRight: '20px', alignItems: 'center' }}>
                                                    <Add style={{ color: '#ff7200', cursor: 'pointer' }} onClick={() => history.push('/dashboard/setting/create-workflow')} />
                                                    <span style={{ color: '#ff7200', fontSize: '14px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => history.push('/dashboard/setting/create-workflow')}>Add New</span>
                                                </div> */}
                                                <div className='cardP' id={`container-${Parentindex}`}>

                                                    {item?.operations?.map((data, ind) => {
                                                        return (
                                                            data?.tasks?.length !== 0 ?
                                                                <div key={ind} className='viewCard' style={{ marginRight: '20px' }}>
                                                                    <div className='viewCardHeader'>
                                                                        <span
                                                                            className='viewHeading'
                                                                            title={data?.workflowName.length > 9 ? data?.workflowName : ''} // Tooltip for full name
                                                                        >
                                                                            {data && (data.workflowName.length > (data.workflowScope === 'warehouse' ? 6 : 9)
                                                                                ? `${data.workflowName.slice(0, data.workflowScope === 'warehouse' ? 6 : 9)}...`
                                                                                : data.workflowName)}
                                                                            ({data?.workflowScope})
                                                                        </span>

                                                                        <span>
                                                                            {/* <Edit onClick={() => { setEditView(true) }} className='myIcon' /> */}
                                                                            <VisibilityIcon data-testid='view' onClick={() => { setItem(data); setEditView(true); setSelectedEntries([data]) }} className='myIcon' />
                                                                            {data?.isEnabled ? <Block className='myIcon' onClick={() => {
                                                                                setConfirmModal("disable")
                                                                                setSelectedEntries([data])
                                                                            }} /> :
                                                                                <CheckCircleOutline className='myIcon' onClick={() => {
                                                                                    setConfirmModal("enable")
                                                                                    setSelectedEntries([data])
                                                                                }} />}
                                                                            <Delete
                                                                                data-testid='delete'
                                                                                onClick={() => {
                                                                                    setConfirmModal("delete")
                                                                                    setSelectedEntries([data])
                                                                                }} className='myIcon' />
                                                                        </span>

                                                                    </div>

                                                                    <div className='viewCradBody'>
                                                                        {data?.tasks?.map((list) => {
                                                                            return (
                                                                                <Grid item data-testid='tasks' sx={{
                                                                                    mb: '8px', width: 'auto', display: 'flex', alignItems: 'center', p: '3px', border: '1px solid #ff7200', whiteSpace: "nowrap",
                                                                                    overflow: "hidden", textOverflow: "ellipsis", backgroundColor: "#FFE3CC", color: "#ff7200", cursor: "move", borderRadius: '8px', fontSize: '13px',justifyContent:'center'
                                                                                }} >
                                                                                    {list}
                                                                                </Grid>
                                                                            )
                                                                        })}

                                                                    </div>

                                                                </div>
                                                                : null
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            <div className='viewCardArrow' style={{ position: 'absolute', right: '0px' }} data-testid='workflow-rightpageicon'>
                                                <ChevronRightIcon
                                                    id={`slideRight-${Parentindex}`}
                                                    style={{
                                                        cursor: pagination?.hasMoreData ? "pointer" : "not-allowed",
                                                        opacity: pagination?.hasMoreData ? 1 : 0.2,
                                                    }}
                                                    onClick={() => {
                                                        if (pagination.hasMoreData) {
                                                            const newPage = pagination?.page + 1;
                                                            setPagination((prev) => ({ ...prev, page: newPage }));
                                                            slide(Parentindex);
                                                            getWorkflowListingData(newPage, pagination.limit);
                                                        }
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="customCardRight crChanges">
                            <div className='cRConstant'>
                                <img style={{ width: '60%' }} src={group} alt='' />
                                <span style={{ color: '#ff7200', fontSize: '22px', fontWeight: '900' }}>Make Your Work<br />flow Your Way</span>
                                <span style={{ color: '#ff7200', fontSize: '14px', fontWeight: '600' }}>Workflows represent your warehouse operations and control how the team member’s progress with their work/ assigned job(s). Add process completion rule and define your workflow movement rules. </span>
                            </div>
                        </div>

                    </div>
                    {editView && <EditView
                        open={editView}
                        hide={handleCloseEditView}
                        confirmModal={confirmModal}
                        item={item}
                        refreshTableData={() => {
                            getWorkflowListingData(1, pagination?.limit)
                        }}
                        selectedEntries={selectedEntries}
                        setSelectedEntries={setSelectedEntries}
                        handleCloseConfirmModal={handleCloseConfirmModal}
                        handleRemoveSelection={handleRemoveSelection}
                        setConfirmModal={setConfirmModal}

                    />}
                    {confirmModal && <ConfirmDialogPopUp
                        status={confirmModal}
                        handleClose={handleCloseConfirmModal}
                        selectedEntries={selectedEntries}
                        setSelectedEntries={setSelectedEntries}
                        refreshTableData={() => {
                            getWorkflowListingData(1, pagination?.limit)
                        }}
                        handleRemoveSelection={handleRemoveSelection}
                    />}
                </Grid>
            }

        </>
    )
}

export default Workflow;