/* eslint-disable */

import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  Box,
  Tabs,
  Tab,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Divider,
  TablePagination,
  Modal,
  TextField,
  Menu,
  Stack,
  Dialog,
  DialogContent,
  Checkbox,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import {
  ArrowDropDown,
  Cancel,
  Clear,
  Close,
  ControlPoint,
  KeyboardArrowDown,
  Label,
  QrCodeScanner,
  QuestionMark,
  Search,
  Sell,
  SellOutlined,
} from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useCallback, useEffect, useState } from "react";
import theme, {
  buttonClasses,
  cardClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFile,
  getAllInventory,
  getAllUsers,
  getAllWarehouses,
  getFloorMapping,
  getInventoryCount,
  getInventoryForModal,
  getInventoryStatusCount,
  getInvTableHeader,
  getItemDetail,
  getMappedArea,
  getPackageDetail,
  getShipmentConnectedMasterDetail,
  getShipmentDetail,
  getShipmentMasterHeader,
  getTemplateHeader,
  getWarehouseFloors,
  updateInvTableHeader,
  deleteInventoryData,
  getCompany,
  getvalidateGateInInventory,
  approveRejectPendingShipment,
  getReceivingConfig,
  getParcelDimensionsData,
  initiateDekitProcess
} from "../../../redux/actions";
import { getSpentTime, getHeader, getTableCell } from "./Utils";
import Popover from '@mui/material/Popover';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GenerateLabel from "./Label";
import Rfid from "./Rfid";
import history from "../../../services/history";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { DataNotFound } from "../../Components/TemplateInputConfiguration";
import { dateTimeFormater, showToast, dataReadFunction, truncateNumber } from "../../../services/functions";
import FileModal from "../EntityMaster/FileModal";
import { useDebounce } from "../../../utils/hooks";
import GenericLoader from "../../Components/Generic/GenericLoader";
import styled from "@emotion/styled";
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { selectStylesOverride } from "../../../utils/util";
import { FixedSizeGrid } from "react-window";
import { AutoSizer } from "react-virtualized";
import GenericRangeFilter from "../../Components/Generic/GenericRangeFilter";
import GenericPageConfig from "../../Components/Generic/GenericPageConfig";
import { renderData } from "../../Components/CustomTable/GenericTable";
import GenericMultiDateRange,{ multiDateToISOConvertor } from '../../Components/Generic/GenericMultiDateRange'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import GenericCustomiseHeader from "../../Components/Generic/GenericCustomiseHeader";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = (width) => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
  };
};
const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: "#FF7200",
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: "white"
      },
    },
  },
}));



export default function AllInvetory() {
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState(null);
  const [inventories,setInventories] = useState([])
  const totalRows = useSelector((state) => state.inventory.totalRows);
  const inventoryTypeCount = useSelector(
    (state) => state.inventory.inventoryTypeCount
  );
  const inventoryStatusCount = useSelector(
    (state) => state.inventory.inventoryStatusCount
  );

  const shipmentData = useSelector((state) => state.inventory.shipmentDetail);
  const packageData = useSelector((state) => state.inventory.packageDetail);
  const itemData = useSelector((state) => state.inventory.itemDetail);
  const { pageTitle="All Inventory" } = useSelector(state => state?.DynamicPageConfig?.inventoryPage) || {};
  const allWarehouses = useSelector((state) => state.inward.allWarehouses);
  const allFloors = useSelector((state) => state.inward.allFloors);
  const mappingLocations = useSelector((state) => state.inward.allMapping);

  const [loader, setLoader] = useState(false);
  const [invType, setInvType] = useState("Shipment");
  const [warehouseFilter, setWarehouseFilter] = useState(null);
  const [floorFilter, setFloorFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState("");
  const [skuNameFilter, setSkuNameFilter] = useState("");
  const [packageDescFilter, setPackageDescFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState(null);
  const [poNumberFilter, setPONumberFilter] = useState("")
  const [statusFilter, setStatusFilter] = useState(null);
  const [nameTypeFilter, setNameTypeFilter] = useState(""); // for shipmnt name, pack, item name
  const [dateFilter, setDateFilter] = useState([]);
  const [rangeFilter,setRangeFilter] =useState({
    start: "",
    end: ""
})
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [tabIndex, setTabIndex] = useState(0);
  const [labelModal, setLabelModal] = useState(null);
  const [rfidModal, setRfidModal] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [showAlertDialog,SetshowAlertDialog] = useState(false)
  const [jobValue,setJobValue] = useState('')
  const [isValid,setIsValid]=useState(false)
  ////("Customize Header", customiseHeader);
  ////per
  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};
 
  const [anchorEl1, setAnchorEl1] = useState(null);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? 'simple-popover' : undefined;
  const isFilterApplied = (() => {
    if (tabIndex == 0 && statusFilter != null) {
      return true;
    }
    if (
      nameFilter != "" ||
      skuNameFilter != "" ||
      packageDescFilter != "" ||
      areaFilter != null ||
      nameTypeFilter != "" ||
      dateFilter?.length>0|| 
      poNumberFilter != "" ||
      (rangeFilter.start !== "" || rangeFilter.end !== "")
    ) {
      return true;
    }
    return false;
  })();

  const handleAddSelection = (obj) => {
    setSelectedObjects([...selectedObjects, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldSelected = [...selectedObjects];
    const i = oldSelected.map((o) => o._id).indexOf(id);
    if (i >= 0) {
      oldSelected.splice(i, 1);
      setSelectedObjects(oldSelected);
      if(oldSelected.length == 0){
        handleCloseConfirmModal();
      }
    }
  };

  const handleCheckChange = (event, obj) => {
    if (event.target.checked) {
      handleAddSelection(obj);
    } else {
      handleRemoveSelection(obj._id);
    }
  };

  const handleCheckAll = () => {
    if (selectedObjects.length < inventories.length) {
      // let sT = inventories.map(t => ({ _id: t._id, status: t.status, name: t.name }))
      setSelectedObjects(inventories);
    } else {
      setSelectedObjects([]);
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  // const isFilterApplied = (() => {
  //     if(nameFilter != "" || warehouseFilter != "all" || floorFilter != "all" || nameFilter != "all" || statusFilter != "" || nameTypeFilter != ""){
  //         return true;
  //     }
  //     return false;
  // })();

  const handleOpenRfidModal = (data) => {
    let inventoryType=invType=="Package"?"package":"item"
    let tempData=data.map(dl=>({label:dl[`${inventoryType}Id`],value:dl[`${inventoryType}Id`],isItemExist:dl?.isItemExist}))
    setRfidModal(tempData);
  };

  const handleCloseRfidModal = () => {
    setRfidModal([]);
  };

  const handleOpenLabelModal = (data) => {
    setLabelModal(data);
  };

  const handleCloseLabelModal = () => {
    setLabelModal(null);
  };

  const [detailModal, setDetailModal] = useState(null);
 

  const handleOpenDetailModal = (value) => {
    setDetailModal({
      ...detailModal,
      ...value,
    });
  };

  const handleCloseDetailModal = () => {
    setDetailModal(null);
  };
 
  const getShipmentPackageItemDetails=()=>{
    if (invType == "Shipment") {
      dispatch(getShipmentDetail({ shipmentId: selectedRow.shipmentId }));
      dispatch(getTemplateHeader({ template_id: selectedRow.template_id }));
    }
    if (invType == "Package") {
      let payload = {
        shipmentId: selectedRow.shipmentId,
        packageId: selectedRow.packageId,
      }
      dispatch(
        getPackageDetail(payload, () => {
         
        })
      );
      dispatch(
        getTemplateHeader({ template_id: selectedRow.dataEntryTemplate_Id })
      );
    }
    if (["Item","Kit"].includes(invType)) {
      dispatch(
        getItemDetail({
          shipmentId: selectedRow.shipmentId,
          itemId: selectedRow.itemId,
        })
      );
      
      dispatch(
        getTemplateHeader({ template_id: selectedRow.dataEntryTemplate_Id })
      );
    }
  }
  useEffect(() => {
    if (selectedRow) {
      getShipmentPackageItemDetails()
    }
    
  }, [selectedRow]);

  const handleSelectedRow = (option) => {
    ////('option',option)
    setSelectedRow(option);
  };

  const handleTabChange = (value,statusObject={}) => {

    // switch (value) {
    //   case 0:
    //     setStatusFilter(null);
    //     break;
    //   case 1:
    //     setStatusFilter({label: "Under-Approval", value:'underApproval'})
    //     break;
    //   case 2:
    //     setStatusFilter({ label: "Pending", value: "pending" });
    //     break;
    //   case 3:
    //     setStatusFilter({ label: "Gated-In", value: "gateIn" });
    //     break;
    //   case 4:
    //     setStatusFilter({
    //       label: "Gated-Out",
    //       value: ["gateOut", "partialGateOut"],
    //     });
    //     break;
    //   case 5:
    //     setStatusFilter({
    //       label: "Rejected",
    //       value: "rejected",
    //     });
    //     break;
    // }
    setStatusFilter(statusObject)
    setPage(0);
    setTabIndex(value);
  };

  const tableHeader = useSelector((state) => state?.customHeader && state?.customHeader["inventoryTable"]) || {};
  // const tableHeader = useSelector((state) => state.inventory.tableHeader);
  // let {baseLabels: header, baseIds: headerIds} = getHeader(invType);
  // header = [<Checkbox checked={inventories.length != 0 && selectedObjects.length == inventories.length} onChange={handleCheckAll} size='small' />].concat(header).concat(["Action"]);
  let validHeaderIds =tableHeader?.validHeader?.length>0? tableHeader.validHeader.map((h) => h.fieldKey): [];
  let header = tableHeader?.validHeader?.length>0? tableHeader.validHeader.map((h) => h.fieldName): [];

  if (validHeaderIds.indexOf("checkbox") >= 0) {
    header[validHeaderIds.indexOf("checkbox")] = (
      <Checkbox
        checked={
          inventories.length != 0 &&
          selectedObjects.length == inventories.length
        }
        onChange={handleCheckAll}
        size="small"
      />
    );
  }


  const getDateEntryFilter = (statusFilter)=>{
    ////console.log(statusFilter);

    let dateFilterKey = "entryTime";
    if (!statusFilter || statusFilter.value == "pending") {
      dateFilterKey = "entryTime"
    }
    else if (["partialGateIn", "gateIn"].includes(statusFilter.value) ||
      (Array.isArray(statusFilter.value) && statusFilter?.value?.some(dl => ["partialGateIn", "gateIn"].includes(dl)))
    ) {
      dateFilterKey = "gateInTime"
    }
    else if (["gateOut", "partialGateOut"].includes(statusFilter.value) ||
      (Array.isArray(statusFilter.value) && statusFilter?.value?.some(dl => ["gateOut", "partialGateOut"].includes(dl)))
    ) {
      dateFilterKey = "gateOutTime"
    }

    if(tableHeader && tableHeader?.validHeader){
      let index;
      index = tableHeader.validHeader.flatMap((h)=>{
        if(h.mappingPath === dateFilterKey){
          return h.mappingPath
        }
      }).indexOf(dateFilterKey)
      if(index === -1){
        index = tableHeader.validHeader.flatMap((h)=>{
          if(h.mappingPath === "created_by.date"){
            return h.mappingPath.split(".").pop()
          }
        }).indexOf("date")
      }
      if(index !== -1){
        return index
      }else return -1
    }
  }

  const filterIndex = {
    idFilter:
      tableHeader && tableHeader.validHeader
        ? tableHeader.validHeader
            .map((h) => h.mappingPath)
            .indexOf(`${(invType=="Kit"?"Item":invType).toLowerCase()}Id`)
        : -1,
    poNumberFilter:
        tableHeader && tableHeader.validHeader
          ? tableHeader.validHeader
              .map((h) => h.mappingPath.split(".").pop())
              .indexOf("purchaseOrderID")
          : -1,
    nameFilter:
      tableHeader && tableHeader.validHeader
        ? tableHeader.validHeader
            .map((h) => h.mappingPath.split(".").pop())
            .indexOf(invType == "Package" ? "packageName" : "SKU_Number")
        : -1,
    skuNameFilter:
      tableHeader && tableHeader.validHeader
        ? tableHeader.validHeader
            .map((h) => h.mappingPath.split(".").pop())
            .indexOf("SKU_Name")
        : -1,
    packageDescFilter:
      tableHeader && tableHeader.validHeader
        ? tableHeader.validHeader
            .map((h) => h.mappingPath.split(".").pop())
            .indexOf("packageDescription")
        : -1,
    status:
      tableHeader && tableHeader.validHeader
        ? tableHeader.validHeader.map((h) => h.renderFunction).indexOf(`status`)
        : -1,
    mappingArea:
      tableHeader && tableHeader.validHeader
        ? tableHeader.validHeader.map((h) =>{
          if(h.mappingPath){
            return h.mappingPath
          }else if(h.renderFunction){
            return h.renderFunction
          }
        }).indexOf(`mappingArea`)
        : -1,
    dataEntryTime: getDateEntryFilter(statusFilter),
    totalTimeSpend:
    tableHeader && tableHeader.validHeader
      ? tableHeader.validHeader.map((h) =>{
        if(h.mappingPath){
          return h.mappingPath
        }else if(h.renderFunction){
          return h.renderFunction
        }
      }).indexOf(`totalTimeSpend`)
      : -1,
  };


  const getTableCell = (header, row, i, detailCard = false, type) => {
    let circleColor = theme?.themeGreen || "green"; 
    if (row?.status === 'rejected') {
      circleColor = theme?.themeRed || 'red';
    } else if (row?.status === "underApproval") {
      circleColor = theme?.themeYellow || 'yellow';
    }
    let value = dataReadFunction(header, row, "mappingPath")
    if (!value) {
      if (header?.mappingPath === "balanceCount.physical") {
        value = "0"
      }
      else {
        value = header?.defaultValue || ""
      }
    }
    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return ( <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          size="small"
          checked={selectedObjects?.map((o) => o?._id)?.indexOf(row?._id) >= 0}
          onChange={(e) => handleCheckChange(e, row)}
        />
        <div
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: circleColor,
            marginLeft: '5px', 
          }}
        ></div>
      </div>
        );
      }
      else if (header?.handleType === "showDetail") {
        if (detailCard) {
          return row.customItemId ? row.customItemId : row[header?.mappingPath];
        }
        return <Typography display={"block"} 
        sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} 
        onClick={() => handleSelectedRow(row)}>
          {value}
        </Typography>
        
      } 
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      } 
      else if (header?.handleType === "showPackageDetail") {
        if (header?.isFromRenderFunction === true) {
          if(header?.renderFunction === "balancePackageCount" && type === "Shipment"){
            if (!row.isPackageExist) {
              return "-";
            }
            if (detailCard) {
              return row[header?.renderFunction]
                ? row[header?.renderFunction]
                : "-";
            }
            let num = row?.balancePackage?.physical || 0;
            num = num == null ? "0" : num;
            let den =  row.gateInPackage;
            return (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  color: theme.themeOrange,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDetailModal({
                    modalType: "packageDetail",
                    title: "Packages (s) Details",
                    type: type,
                    data: row,
                  })
                }
              >
                {row.totalPackage ? `${num} / ${den}` : "-"}
              </Typography>
            );

          }else
          if (header?.renderFunction) {
            if (!row.isPackageExist) {
              return "-";
            }
            if (detailCard) {
              return row[header?.renderFunction]
                ? row[header?.renderFunction]
                : "-";
            }
            let num = row.gateInPackage;
            num = num == null ? "0" : num;
            let den = row.totalPackage;
            return (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  color: theme.themeOrange,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDetailModal({
                    modalType: "packageDetail",
                    title: "Packages (s) Details",
                    type: type,
                    data: row,
                  })
                }
              >
                {row.totalPackage ? `${num} / ${den}` : "-"}
              </Typography>
            );
          }
        }
      } 
      else if (header?.handleType === "showItemDetail") {
        if (header?.renderFunction) {
          let id,num, den;
          if (header?.renderFunction === "itemCount" || header?.renderFunction === "count" || header?.renderFunction == "totalItem" || header?.renderFunction == "balanceItemCount") {
            if(type === "Shipment" && header?.renderFunction === "balanceItemCount"){
              id = "gateInItem";
              // if (!row.isItemExist) {
              //   return row.count;
              // }
              if (!row.isItemExist) {
                return "-";
              }
              if (detailCard) {
                return row[id] ? row[id] : "-";
              }
              num = row?.balanceItem?.physical || 0;
              num = num == null ? "0" : num;
              den = row.gateInItem;
              den = den == null ? "0" : den;

            }
            else {
              id = header?.renderFunction === "count" ? header?.renderFunction : "totalItem";
              if (type == "Package" && !row.isItemExist) {
                return row.count;
              }
              if (!row.isItemExist) {
                return "-";
              }
              if (detailCard) {
                return row[id] ? row[id] : "-";
              }
              num = type == "Package" ? row.gateInCount : row.gateInItem;
              num = num == null ? "0" : num;
              den = type == "Package" ? row.count : row.totalItem;
              den = den == null ? "0" : den;
            }
            ////(num,"nummmmm",den)
            return (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  color: theme.themeOrange,
                  textDecoration: row[id] ?  "underline" : "none",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleOpenDetailModal({
                    modalType: "itemDetail",
                    title: "Items (s) Details",
                    type: type,
                    data: row,
                  })
                }
              >
                {row[id] ? `${num} / ${den}` : "-"}
              </Typography>
            );
        }
      }
      }
      else if(header?.handleType === "showLabel"){
        return (
          <Typography sx={{ ...textClasses.normalText, cursor: "pointer" }}
            onClick={() => handleOpenLabelModal({
              shipmentId: row.shipmentId,
              templateId: type == "Shipment" ? row.template_id : row.dataEntryTemplate_Id
            })}
          >
            {row.status === "rejected" ? "-" : (
              <>
                <QrCodeScanner fontSize='inherit' sx={{ color: theme.themeOrange, mr: 1 }} />
                Label
              </>
            )}
          </Typography>
        )  

      }
      else if(header?.handleType === "viewQCResult"){
        if (value) {
          let tempType = type
          if (type == "Kit") tempType = "Item"
          let isTrue=false
          if(["Item","KIT"].includes(type)){
            isTrue=true
          }
          else if(type=="Shipment" && (!row?.isItemExist && !row?.isPackageExist)){
            isTrue=true
          }
          else if(type=="Package" && !row?.isItemExist){
            isTrue=true
          }
          else{
            isTrue=false
          }
          return (
              <Typography sx={{ ...textClasses.normalText, cursor: isTrue?"pointer":"" }}
                {...isTrue && {onClick:()=>history.push(`/dashboard/inventory/qualityControl?inventoryId=${row[tempType?.toLowerCase() + "Id"] || ""}&inventoryType=${tempType?.toLowerCase()}`)}}

              >
                <span style={{color:value=="PASS"? theme.themeGreen:theme.themeRed}}>{value || "-"}</span>
                {isTrue && <>
                  <span style={{color:theme.themeGreen}}> | </span> 
                  <span style={{color:theme.themeOrange}}>View({row?.allQCDetail?.qcFrequency || 0})</span>
                </>}
              </Typography>
          )
        }
        return "-"
      }
      else{
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center",color:header?.fontColor || "" }}>
            {value
              ? Array.isArray(value)
              ? value.join(", ")
              : value
              : "-"
            }
          </Typography>
        );
      }
    } 
    else if (header?.isFromRenderFunction === true) {
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {page == 0 ? i+1 : (page*limit)+(i+1)}
          </Typography>
        );
      } else if (header?.renderFunction === "mappingArea") {
        let convertedMappingArea;
        if (
          detailCard &&
          row?.balanceCount?.physical > 0 &&
          row?.location?.length > 0
        ) {
          convertedMappingArea = [];
          let arrTemp = [];
          row.location.map((dl) => {
            let isExist = arrTemp.findIndex((el) => {
              let condition = el.cellId == dl.cellId;
              if (dl.rackId) {
                condition = condition && el.rackId == dl.rackId;
              }
              return condition;
            });
            if (isExist < 0) {
              arrTemp.push(dl);
            } else {
              arrTemp.splice(isExist, 1, {
                ...arrTemp[isExist],
                count:
                  Number(dl.count || 0) +
                  Number(arrTemp[isExist]["count"] || 0),
              });
            }
          });

          arrTemp.map((dl) => {
            let str_temp = dl.cellName || "";
            if (dl.rackName)
              str_temp = str_temp + "-" + dl.rackName + `(${dl.count})`;
            else {
              str_temp = str_temp + `(${dl.count})`;
            }
            if (str_temp) {
              convertedMappingArea.push(str_temp);
            }
          });
        } else {
          if (type == "Item") {
            convertedMappingArea =
              row.mappingArea == "" ? "PUT IT ANYWHERE" : row.mappingArea;
          } else {
            ////(data.mappingArea);
            convertedMappingArea =
              row.mappingArea &&
              Array.isArray(row.mappingArea) &&
              row.mappingArea.length > 0
                ? row.mappingArea.map((ma) =>
                    ma == "" ? "PUT IT ANYWHERE" : ma
                  )
                : ["PUT IT ANYWHERE"];
          }
        }

        if (detailCard) {
          if (!Array.isArray(convertedMappingArea)) return convertedMappingArea;
          else return convertedMappingArea.join(", ");
        }
        return (
          <Tooltip title={convertedMappingArea.toString()}>
            <Typography sx={{ ...textClasses.normalText }}>
              <ControlPoint
                fontSize="inherit"
                sx={{ color: theme.themeOrange, mr: 1 }}
              />
              {convertedMappingArea.toString().length > 15
                ? convertedMappingArea.toString().substring(0, 15) + "..."
                : convertedMappingArea.toString()}
            </Typography>
          </Tooltip>
        );
      }else if(header?.renderFunction === "status"){
        let statusMapping = {
            'partialGateOut': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Partially Gated-Out"
            },
            'gateOut': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Gated-Out"
            },
            'gateIn': {
                color: "#3E621E",
                background: "#D7ECC4",
                label: "Gated-In"
            },
            'pending': {
                color: "#FF7200",
                background: "#ff720026",
                label: "Pending"
            },
            'underApproval': {
              color: "#FF7200",
              background: "#ff720026",
              label: "Under-Approval"
          },
            'partialGateIn': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Partially Gated-In"
            },
            'rejected': {
              color: "#E9001A",
              background: "#E9001A26",
              label: "Rejected"
          },
            'underDekitting': {
              color: "#FF7200",
              background: "#ff720026",
              label: "Under-Dekitting"
          },
          'dekitted': {
            color: "#E9001A",
            background: "#E9001A26",
            label: "De-Kitted"
          }
        }
        if (detailCard) {
          return statusMapping[row.status]
        }
        else {
          return <Typography sx={{ ...textClasses.normalText, color: statusMapping[row.status]?.color }}>{statusMapping[row.status]?.label}</Typography>
        }
      }else if(header?.renderFunction === "totalTimeSpend" || header?.renderFunction === "timeSpent"){
        let timeSpent = getSpentTime(row.gateInTime, row.gateOutTime)
        if(detailCard){
            return timeSpent
        }
        return <Typography sx={{...textClasses.normalText}}>{timeSpent}</Typography>
    }
    else if(header?.renderFunction === "gateOutCount"){
      if(row?.isWarehouseFloorEnabled==true){
        return <Typography sx={{...textClasses.normalText}}>{row?.gateOutCount || "-"}</Typography>
      }
      let gateCount =truncateNumber(row.gateInCount && row.balanceCount && (row.gateInCount-row.balanceCount?.physical) || 0)
      return <Typography sx={{...textClasses.normalText}}>{gateCount?gateCount:"-"}</Typography>

    }
    } else {

      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {value
              ? Array.isArray(value)
              ? value.join(", ")
              : value
              : "-"}
          </Typography>
        );
      }
      else if (header?.fieldType === "date") {
        // if(detailCard) ////console.log(value,header,"card");
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {value
              ? moment(value).format(
                  header?.format || "DD MMM YYYY hh:mm A"
                )
              : "-"}
          </Typography>
        );

        
      }
       else {
          return (
            <Typography
              sx={{
                ...textClasses.normalText,
                textAlign: "center",
                textTransform: "none",
              }}
            >
              {value ?? "-"}
            </Typography>
          );
      }
    }
  };

  const rows = inventories?.map((d, i) => {
    let row = [];

    const validHeaders = tableHeader.validHeader ?? [];
    for (const header of validHeaders) {
      row.push(getTableCell(header, d, i,false, invType));
    }

    // for (let j = 0; j < validHeaderIds.length; j++) {
    //   if (validHeaderIds[j] == "srNo") {
    //     row.push(
    //       getTableCell(
    //         i + 1,
    //         invType,
    //         validHeaderIds[j],
    //         false,
    //         handleSelectedRow,
    //         handleOpenDetailModal,
    //         handleOpenLabelModal,
    //         handleOpenRfidModal,
    //         actionMenu,
    //         handleOpenAction,
    //         handleCloseAction
    //       )
    //     );
    //   } else if (validHeaderIds[j] == "checkbox") {
    //     row.push(
    //       <Checkbox
    //         size="small"
    //         checked={selectedObjects.map((o) => o._id).indexOf(d._id) >= 0}
    //         onChange={(e) => handleCheckChange(e, d)}
    //       />
    //     );
    //   } else if (validHeaderIds[j] == "action") {
    //     row.push(
    //       <Typography
    //         sx={{
    //           ...textClasses.normalText,
    //           color: theme.themeOrange,
    //           cursor: "pointer",
    //         }}
    //         onClick={(e) => handleOpenAction(e, i)}
    //       >
    //         Action <KeyboardArrowDown />
    //       </Typography>
    //     );
    //   } else {
    //     row.push(
    //       getTableCell(
    //         d,
    //         invType,
    //         validHeaderIds[j],
    //         false,
    //         handleSelectedRow,
    //         handleOpenDetailModal,
    //         handleOpenLabelModal,
    //         handleOpenRfidModal,
    //         actionMenu,
    //         handleOpenAction,
    //         handleCloseAction
    //       )
    //     );
    //   }
    // }
    // row.push(
    //     <Checkbox
    //         size="small"
    //         checked={selectedObjects.map(o => o._id).indexOf(d._id) >= 0}
    //         onChange={(e) => handleCheckChange(e, d)}
    //     />
    // )
    // for (let j = 0; j < headerIds.length; j++) {
    //     row.push(getTableCell(d, invType, headerIds[j], false, handleSelectedRow, handleOpenDetailModal, handleOpenLabelModal, handleOpenRfidModal, actionMenu, handleOpenAction, handleCloseAction))
    // }
    // row.push(
    //     <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>
    // )
    return row;
  });

 

  const handleTypeChange = (type) => {
    setSelectedObjects([])
    setInvType(type);
    setSelectedRow(null);
    setPage(0);
    setLimit(10);
  };

  const handleNameChange = (e) => {
    setNameFilter(e.target.value);
    setPage(0);
    setLimit(10);
  };
  const handleSkuNameChange = (e) => {
    setSkuNameFilter(e.target.value);
    setPage(0);
    setLimit(10);
  };
  const handlePackageDescChange = (e) => {
    setPackageDescFilter(e.target.value);
    setPage(0);
    setLimit(10);
  };
  const handleNChange = (e) => {
    setNameTypeFilter(e.target.value);
    setPage(0);
    setLimit(10);
  };

  const handlePONumberFilter = (e) =>{
    setPONumberFilter(e.target.value);
    setPage(0);
    setLimit(10)
  }

  const handleDateChange = (d) => {
    setDateFilter(d);
    setPage(0);
    setLimit(10);
  };
  const handleClearFilter = () => {
    setWarehouseFilter(null);
    setFloorFilter(null);
    setNameFilter("");
    setSkuNameFilter("")
    setPackageDescFilter("")
    setPONumberFilter('');
    setAreaFilter(null);
    setDateFilter(null);
    setNameTypeFilter("");
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
    }
    setRangeFilter({
      start: "",
      end: ""
    })
  };

  const poNumberDebounceFilter = useDebounce(poNumberFilter, 500)
  const nameDebounceFilter = useDebounce(nameFilter, 500)
  const skuNameDebounceFilter = useDebounce(skuNameFilter, 500)
  const packageDescDebounceFilter = useDebounce(packageDescFilter, 500)
  const dateDebounceFilter = useDebounce(dateFilter, 500)
  const nameTypeDebounceFilter = useDebounce(nameTypeFilter, 500)
  const areaDebounceFilter = useDebounce(areaFilter, 500)
  const statusDebounceFilter = useDebounce(statusFilter, 500)


  const refreshTableData = (pageNo, limitNo) => {
   
    const payload = {
      type: "wms",
      page: pageNo,
      limit: limitNo,
      dataFetchType: invType.toLowerCase() + "Wise",
      warehouseId: warehouseFilter ? warehouseFilter._id : "",
      floorId: floorFilter ? floorFilter._id : "",
      mappingArea: areaFilter ? areaFilter.value : "",
      status: statusFilter ? statusFilter.value : "",
    };
    let tempType=invType=="Kit"?"Item" :invType
    if (nameFilter) {
      payload[`${tempType.toLowerCase()}Id`] = nameFilter;
    }
    if(invType == "Shipment"){
      payload["purchaseOrderId"] = poNumberFilter;
    }
    if (invType == "Package") {
      payload["packageName"] = nameTypeFilter;
    }
    if (["Item","Kit"].includes(invType)) {
      payload["itemName"] = nameTypeFilter;
    }
    if (["Item","Kit"].includes(invType)) {
      payload["skuName"] = skuNameFilter;
    }
    if (invType == "Package") {
      payload["packageDescription"] = packageDescFilter;
    }
    if (rangeFilter.start) {
      payload["minAge"] = rangeFilter.start;
    }
    if (rangeFilter.end) {
      payload["maxAge"] = rangeFilter.end;
    }
    // if(createdFilter && createdFilter != "" && createdFilter != "all"){
    //     payload["createdBy"] = createdFilter
    // }
    if (dateFilter?.length>0) {
      let key = "createdOn";
      // console.log("statusFilter",statusFilter)
      if(!statusFilter || statusFilter.value == "pending" || statusFilter.value == "underApproval"){
        key = "createdOn"
      }
      else if (["partialGateIn", "gateIn"].includes(statusFilter.value) ||
        (Array.isArray(statusFilter.value) && statusFilter?.value?.some(dl => ["partialGateIn", "gateIn"].includes(dl)))
      ) {
        key = "gateInOn"
      }
      else if (["gateOut", "partialGateOut"].includes(statusFilter.value) ||
        (Array.isArray(statusFilter.value) && statusFilter?.value?.some(dl => ["gateOut", "partialGateOut"].includes(dl)))
      ) {
        key = "gateOutOn"
      }
      payload[key] =multiDateToISOConvertor(dateFilter)
    }
    // ////(payload);
    setLoader(true);
    setInventories([]);
    dispatch(
      getAllInventory(payload, ({data=[]}) => {
        setLoader(false);
        setInventories(data)
      })
    );
    payload.status = "";
    dispatch(getInventoryStatusCount(payload));
    setSelectedRow(null);
  };

  const deleteInventory = () => {
    setConfirmModal("Delete")
  }
  // const deleteInventory = useCallback((objects)=>{
  //   let ids;
  //   if(Array.isArray(objects)){
  //      ids = objects?.map((object)=> object?.shipmentId)
  //   }else {
  //     ids = [objects?.shipmentId]
  //   }

    // const payload ={
    //   shipmentId: ids,
    // }

    // dispatch(deleteInventoryData(payload, ()=>{
    //   setSelectedObjects([])
    //   refreshTableData(page, limit);
    // }))
  // },[dispatch, setSelectedObjects, refreshTableData, page, limit])

  useEffect(() => {
    refreshTableData(page + 1, limit);
  }, [
    warehouseFilter,
    floorFilter,
    invType,
    nameDebounceFilter,
    skuNameDebounceFilter,
    packageDescDebounceFilter,
    dateDebounceFilter,
    nameTypeDebounceFilter,
    poNumberDebounceFilter,
    areaDebounceFilter,
    statusDebounceFilter,
    page,
    limit,rangeFilter
    // tableHeader,
  ]);

  useEffect(() => {
    dispatch(getInvTableHeader({ tableType:invType=="Kit"?"kitInventoryTable" :`${invType.toLowerCase()}Table`  }));
  }, [invType]);

  useEffect(() => {
    dispatch(getAllWarehouses({ type: "warehouse" }));
    dispatch(getReceivingConfig({ configType: "inventoryUpdateWithJob" }, (data) => {
  }))
  }, []);

  useEffect(() => {
    if (warehouseFilter && warehouseFilter != "all") {
      dispatch(
        getWarehouseFloors({ type: "floor", warehouseId: warehouseFilter?._id })
      );
    }
  }, [warehouseFilter]);

  useEffect(() => {
    if (floorFilter && floorFilter != "all") {
      dispatch(getFloorMapping({ floorId: floorFilter }));
    }
  }, [floorFilter]);

  useEffect(() => {
    // dispatch(
    //   getAllInventory({
    //     page: 1,
    //     limit: 10,
    //     dataFetchType: "shipmentWise",
    //   })
    // );
    dispatch(
      getInventoryCount({
        type: "wms",
        warehouseId:
          warehouseFilter && warehouseFilter != "all" ? warehouseFilter : "",
        floorId: floorFilter && floorFilter != "all" ? floorFilter : "",
      })
    );
    dispatch(getFloorMapping({}));
  }, []);

  const isDeleteAllowed = (data)=>{
    if(invType=="Kit" || data.dataEntryType=="kit") return false
    if(!Array.isArray(data)){
      return (
        data?.status === "pending" &&
        (!data.balanceCount || !data.balanceCount.committed ||  data.balanceCount.committed<=0) &&
        (!data?.gateInHoldingQty || data?.gateInHoldingQty === 0)
      );
    }else if(Array.isArray(data)){
      return !data.some(
        (object) =>
          object.status !== "pending" ||
          (object?.balanceCount?.committed>0 && object.gateInHoldingQty !== 0)
      );
    }
  }
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl2(null);
  };
  const handleDialog =(item)=>{
    if(item == "dispatch"){
      setIsValid(true)
    }
    setJobValue(item)
    setAnchorEl2(null);
    SetshowAlertDialog(true)
  }

  return (
    <Grid container>
      <Grid item sm={12} xs={12}>
        <Grid container>
          <Grid item sm={3} xs={4}>
            <Typography sx={{ ...textClasses.cardTitle }}>
              {pageTitle}
            </Typography>
          </Grid>
          <Grid container item sm={9} xs={8} justifyContent="end">
            <Autocomplete
              value={warehouseFilter}
              onChange={(e, option) => {
                setWarehouseFilter(option);
                setPage(0);
                setLimit(10);
              }}
              getOptionLabel={(option) => option.name}
              size="small"
              options={allWarehouses}
              sx={{
                ...inputClasses.filterField,
                minWidth: "250px",
                ".MuiInputBase-input": {
                  fontSize: "15px",
                  color: "#455A64",
                },
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "#455A64", ...textClasses.normalText }}
                  {...params}
                  label="All Warehouse"
                />
              )}
            />
            {warehouseFilter && warehouseFilter != "all" && (
              <Autocomplete
                value={floorFilter}
                onChange={(e, option) => {
                  setFloorFilter(option);
                  setPage(0);
                  setLimit(10);
                }}
                getOptionLabel={(option) => option.name}
                size="small"
                options={allFloors}
                sx={{
                  ...inputClasses.filterField,
                  minWidth: "250px",
                  ml: 2,
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label="Select Floor"
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <GenericPageConfig
        storedInPage={"inventoryPage"}
        pageName={"inventory"}
        pageTabCount={inventoryTypeCount}
        pageStatusCount={inventoryStatusCount}
        handlePageTabChange={handleTypeChange}
        selectedPageTab={invType}
        selectedStatusTab={tabIndex}
        handleStatusTabChange={handleTabChange}
        disabledPageTabs={loader}
        disabledStatusTabs={loader}
      />
      <Grid container spacing={2}>
        {filterIndex.idFilter >= 0 &&
          tableHeader.validHeader[filterIndex.idFilter]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <FormControl variant="outlined" size="small" fullWidth>
                <OutlinedInput
                  data-testid="search-input"
                  fullWidth
                  value={nameFilter}
                  onChange={handleNameChange}
                  sx={{
                    ...inputClasses.filterField,
                    "&:focus": {
                      borderColor: theme.themeOrange,
                    },
                    "&:before": {
                      borderColor: theme.themeOrange,
                    },
                    "&:after": {
                      borderColor: theme.themeOrange,
                    },
                    "& input::placeholder": { fontSize: "14px" },
                  }}
                  placeholder={`${tableHeader.validHeader[filterIndex.idFilter].fieldName
                    }`}
                  endAdornment={
                    nameFilter == "" ? (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        sx={{
                          visibility: nameFilter != "" ? "visible" : "hidden",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setNameFilter("");
                          setPage(0);
                          setLimit(10);
                        }}
                      >
                        <Clear fontSize="inherit" />
                      </IconButton>
                    )
                  }
                />
              </FormControl>
            </Grid>
          )}

        {filterIndex.poNumberFilter >= 0 &&
          tableHeader.validHeader[filterIndex.poNumberFilter]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <FormControl variant="outlined" size="small" fullWidth>
                <OutlinedInput
                  data-testid="PONumberFilter"
                  fullWidth
                  value={poNumberFilter}
                  onChange={handlePONumberFilter}
                  sx={{
                    ...inputClasses.filterField,
                    "&:focus": {
                      borderColor: theme.themeOrange,
                    },
                    "&:before": {
                      borderColor: theme.themeOrange,
                    },
                    "&:after": {
                      borderColor: theme.themeOrange,
                    },
                    "& input::placeholder": { fontSize: "14px" },
                  }}
                  placeholder={`${tableHeader.validHeader[filterIndex.poNumberFilter].fieldName
                    }`}
                  endAdornment={
                    poNumberFilter == "" ? (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        sx={{
                          visibility: poNumberFilter != "" ? "visible" : "hidden",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setPONumberFilter("");
                          setPage(0);
                          setLimit(10);
                        }}
                      >
                        <Clear fontSize="inherit" />
                      </IconButton>
                    )
                  }
                />
              </FormControl>
            </Grid>
          )}

        {["Package", "Item", "Kit"].indexOf(invType) >= 0 &&
          filterIndex.nameFilter >= 0 &&
          tableHeader.validHeader[filterIndex.nameFilter]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <FormControl variant="outlined" size="small" fullWidth>
                <OutlinedInput
                  fullWidth
                  value={nameTypeFilter}
                  onChange={handleNChange}
                  sx={{
                    ...inputClasses.filterField,
                    "&:focus": {
                      borderColor: theme.themeOrange,
                    },
                    "&:before": {
                      borderColor: theme.themeOrange,
                    },
                    "&:after": {
                      borderColor: theme.themeOrange,
                    },
                    "& input::placeholder": { fontSize: "14px" },
                  }}
                  placeholder={`${tableHeader.validHeader[filterIndex.nameFilter].fieldName
                    }`}
                  endAdornment={
                    nameTypeFilter == "" ? (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        sx={{
                          visibility:
                            nameTypeFilter != "" ? "visible" : "hidden",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setNameTypeFilter("");
                          setPage(0);
                          setLimit(10);
                        }}
                      >
                        <Clear fontSize="inherit" />
                      </IconButton>
                    )
                  }
                />
              </FormControl>
            </Grid>
          )}

        {filterIndex.skuNameFilter >= 0 &&
          tableHeader.validHeader[filterIndex.skuNameFilter]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <FormControl variant="outlined" size="small" fullWidth>
                <OutlinedInput
                  data-testid="search-input"
                  fullWidth
                  value={skuNameFilter}
                  onChange={handleSkuNameChange}
                  sx={{
                    ...inputClasses.filterField,
                    "&:focus": {
                      borderColor: theme.themeOrange,
                    },
                    "&:before": {
                      borderColor: theme.themeOrange,
                    },
                    "&:after": {
                      borderColor: theme.themeOrange,
                    },
                    "& input::placeholder": { fontSize: "14px" },
                  }}
                  placeholder={`${tableHeader.validHeader[filterIndex.skuNameFilter].fieldName
                    }`}
                  endAdornment={
                    skuNameFilter == "" ? (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        sx={{
                          visibility: skuNameFilter != "" ? "visible" : "hidden",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setSkuNameFilter("");
                          setPage(0);
                          setLimit(10);
                        }}
                      >
                        <Clear fontSize="inherit" />
                      </IconButton>
                    )
                  }
                />
              </FormControl>
            </Grid>
          )}

        {filterIndex.packageDescFilter >= 0 &&
          tableHeader.validHeader[filterIndex.packageDescFilter]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <FormControl variant="outlined" size="small" fullWidth>
                <OutlinedInput
                  data-testid="search-input"
                  fullWidth
                  value={packageDescFilter}
                  onChange={handlePackageDescChange}
                  sx={{
                    ...inputClasses.filterField,
                    "&:focus": {
                      borderColor: theme.themeOrange,
                    },
                    "&:before": {
                      borderColor: theme.themeOrange,
                    },
                    "&:after": {
                      borderColor: theme.themeOrange,
                    },
                    "& input::placeholder": { fontSize: "14px" },
                  }}
                  placeholder={`${tableHeader.validHeader[filterIndex.packageDescFilter].fieldName
                    }`}
                  endAdornment={
                    packageDescFilter == "" ? (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        sx={{
                          visibility: packageDescFilter != "" ? "visible" : "hidden",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setPackageDescFilter("");
                          setPage(0);
                          setLimit(10);
                        }}
                      >
                        <Clear fontSize="inherit" />
                      </IconButton>
                    )
                  }
                />
              </FormControl>
            </Grid>
          )}


        {filterIndex.mappingArea >= 0 &&
          tableHeader.validHeader[filterIndex.mappingArea]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <Autocomplete
                data-testid="MappingAreaFilter"
                value={areaFilter}
                onChange={(e, option) => {
                  setAreaFilter(option);
                  setPage(0);
                  setLimit(10);
                }}
                // getOptionLabel={(option) => option.label}
                size="small"
                fullWidth
                options={mappingLocations}
                sx={{
                  ...inputClasses.filterField,
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label={`${tableHeader.validHeader[filterIndex.mappingArea].fieldName
                      }`}
                  />
                )}
              />
            </Grid>
          )}
        {tabIndex <= 0 && filterIndex?.status >= 0 &&
          tableHeader.validHeader[filterIndex?.status]?.isSearchable ===
          true && (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <Autocomplete
                value={statusFilter}
                onChange={(e, option) => {
                  setStatusFilter(option);
                  setPage(0);
                  setLimit(10);
                }}
                // getOptionLabel={(option) => option.label}
                size="small"
                fullWidth
                options={[
                  ...(invType != "Kit" ? [{ label: "Under-Approval", value: "underApproval" }] : []),
                  { label: "Pending", value: "pending" },
                  { label: "Gated-In", value: "gateIn" },
                  ...(invType == "Kit" ? [
                    { label: "Under-Dekitting", value: "underDekitting" },
                    { label: "De-Kitted", value: "dekitted" },
                  ] : [
                    { label: "Partial Gated-In", value: "partialGateIn" },
                    { label: "Partial Gated-Out", value: "partialGateOut" },
                    { label: "Rejected", value: "rejected" },
                  ]),
                  { label: "Gate-Out", value: "gateOut" },
                ]}
                sx={{
                  ...inputClasses.filterField,
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label={`${tableHeader.validHeader[filterIndex.status].fieldName}`}


                  />
                )}
              />
            </Grid>
          )}
        
        {filterIndex.dataEntryTime >= 0 && tableHeader.validHeader[filterIndex.dataEntryTime]?.isSearchable === true &&
          (
            <Grid item xs={12} sm={6} md={3} lg={2.3}>
              <FormControl sx={{ ...selectStylesOverride, width: '100%', display: "flex", height: "100%" }} fullWidth>
                <GenericMultiDateRange
                  label={`${tableHeader.validHeader[filterIndex.dataEntryTime].fieldName}`}
                  getOnChange={(newValue) => handleDateChange(newValue || [])}
                  value={dateFilter || []}
                  inputStyle={inputClasses.filterField}
                />
              </FormControl>
            </Grid>
          )}
          {filterIndex?.totalTimeSpend >= 0 && tableHeader?.validHeader[filterIndex?.totalTimeSpend]?.isSearchable === true &&
          (
            <Grid item xs={12} sm={6} md={3} lg={1.8}>
              <Button
                data-testid="ageFilterButton"
                aria-describedby={id1}
                onClick={handleClick1}
                size="medium"
                fullWidth
                sx={{
                  ...buttonClasses.medium
                }}

                endIcon={anchorEl1 === null ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}

              ><Typography sx={{ ...textClasses.normalText, fontSize: "14px", textAlign: 'left' }}
              >{rangeFilter?.start || rangeFilter?.end ? `Age (${rangeFilter?.start} - ${rangeFilter?.end})` :
                "Inventory Age (Days)"}
                </Typography></Button>

              <Popover
                id={id1}
                open={open1}
                anchorEl={anchorEl1}
                onClose={handleClose1}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <GenericRangeFilter
                  data-testid="ageFilterComponent"
                  minLabel="Min Age"
                  maxLabel="Max Age"
                  initialState={rangeFilter}
                  handleRangeChange={(r) => {
                    // console.log("dar", r)
                    setAnchorEl1(null)
                    setRangeFilter(r)
                  }}
                />
              </Popover>
            </Grid>
          )}
        {isFilterApplied && (
          <Grid container item xs={"auto"} alignItems={"center"}>
            <Typography
              sx={{
                ...textClasses.boldText,
                color: theme.themeOrange,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleClearFilter}
            >
              Clear Filter(s)
            </Typography>
          </Grid>
        )}
        <Grid container item xs={"auto"} alignItems={"flex-start"}>
          <GenericCustomiseHeader
            tableName={"inventoryTable"} // (saleTableHeader)for sales table data
            tableType={invType == "Kit" ? "kitInventoryTable" : `${invType.toLowerCase()}Table`}  // (SaleOrderTable) for sales table data
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        status={confirmModal}
        handleClose={handleCloseConfirmModal}
        selectedObjects={selectedObjects}
        setSelectedObjects={setSelectedObjects}
        refreshTableData={() => refreshTableData(page + 1, limit)}
        handleRemoveSelection={handleRemoveSelection}
      />
      {selectedObjects.length > 0 && (
        <Grid container sx={{ mt: 2, textTransform: "none" }}>
            {invType == "Shipment" && isDeleteAllowed(selectedObjects) && permissions?.permissions?.some((item) => item.permissionId === "deleteInventory") && (
           
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                    textTransform: "none",
                  }}
                  onClick={()=>deleteInventory(selectedObjects)}
                >
                 Delete Inventory
                </Button>
              )}

          {selectedObjects.length == 1 && (
            <>
              {permissions &&
              (permissions.permissions || []).some((item) => item.permissionId === "viewInventoryDetails") ? (
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                    textTransform: "none",
                  }}
                  onClick={() => handleSelectedRow(selectedObjects[0])}
                >
                  View Details
                </Button>
              ) : (
                ""
              )}
          
              {selectedObjects[0].isPackageExist && (
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                    textTransform: "none",
                  }}
                  onClick={() =>
                    handleOpenDetailModal({
                      modalType: "packageDetail",
                      title: "Packages (s) Details",
                      type: invType,
                      data: selectedObjects[0],
                    })
                  }
                >
                  View Package
                </Button>
              )}
              {selectedObjects[0].isItemExist && (
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                    textTransform: "none",
                  }}
                  onClick={() =>
                    handleOpenDetailModal({
                      modalType: "itemDetail",
                      title: "Items (s) Details",
                      type: invType,
                      data: selectedObjects[0],
                    })
                  }
                >
                  View Items
                </Button>
              )}
              {permissions &&
              (permissions.permissions || []).some(
                (item) => item.permissionId === "generateInventoryLabel"
              ) && selectedObjects?.every((item) => !["rejected"].includes(item?.status))  ? (
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    mr: 1,
                    textTransform: "none",
                  }}
                  onClick={() =>
                    handleOpenLabelModal({
                      shipmentId: selectedObjects[0].shipmentId,
                      templateId:
                        invType == "Shipment"
                          ? selectedObjects[0].template_id
                          : selectedObjects[0].dataEntryTemplate_Id,
                    })
                  }
                >
                  <QrCodeScanner
                    fontSize="inherit"
                    sx={{ color: "white", mr: 1 }}
                  />
                  Generate Label
                </Button>
              ) : (
                ""
              )}
            </>
          )}
          {selectedObjects.length>0 &&  invType=="Kit" &&  
               ( selectedObjects[0].status=="gateIn" || selectedObjects[0].status=="pending") ?
               <Button
               data-testid="create-dekit"
               size="small"
               variant="contained"
               sx={{
                 ...buttonClasses.lynkitBlackFill,
                 mr: 1,
                 textTransform: "none",
               }}
               onClick={() => setConfirmModal("Dekit")}
             >
              Create De-Kit Request
             </Button>
               :" "
              }
          {/* {
            invType == "Shipment"?'':<Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1,textTransform:"none" }} onClick={() => handleOpenRfidModal(selectedObjects)}>
            <Sell fontSize='inherit' sx={{color: "white", mr: 1}} />
            RFID Mapping
        </Button>
          } */}
          {/* <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, backgroundColor: "black", border: "1px solid black" ,textTransform:"none" }} onClick={() => setConfirmModal("Delete")}>Delete</Button> */}
          <div>
            {((warehouseFilter?._id && selectedObjects?.every((item) => ["gateIn", "partialGateOut", "partialGateIn"].includes(item?.status)) && permissions?.permissions?.some((item) => item.permissionId === "createDispatchJob"))
                || (selectedObjects?.every((item) => ["pending", "partialGateIn"].includes(item?.status)) && permissions?.permissions?.some((item) => item.permissionId === "createPutAwayJob"))) &&
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // variant="contained"
                sx={{
                  border: '1px solid #FF7200',
                  color: '#FF7200',
                  borderRadius: '10px',
                  textTransform: "capitalize"
                }}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Create Job
              </Button>
            }

            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl2}
              open={open}
              onClose={handleClose}
            >


              {
                warehouseFilter?._id && selectedObjects?.every((item) => ["gateIn", "partialGateOut", "partialGateIn"].includes(item?.status)) &&
                permissions && (permissions.permissions || []).find((item) => item.permissionId === "createDispatchJob") &&
                <MenuItem onClick={() => handleDialog('dispatch')} disableRipple>
                  Dispatch
                </MenuItem>
              }
              {selectedObjects?.every((item) => ["pending", "partialGateIn"].includes(item?.status)) &&
                permissions && (permissions.permissions || []).find((item) => item.permissionId === "createPutAwayJob") &&
                <MenuItem onClick={() => handleDialog('gateIn')} disableRipple >
                  Put Away
                </MenuItem>}
            </StyledMenu>
          </div>
          {selectedObjects.length > 0 && selectedObjects?.every((item) => ["underApproval"].includes(item?.status)) && invType == "Shipment" &&
            permissions && (permissions.permissions || []).find( (item) => item.permissionId === "dataEntryApproval") && (
              <>
                <Button data-testid="approve-btn" variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 ,textTransform: "none",}} onClick={() => setConfirmModal("Approve")}>Approve</Button>
                <Button data-testid="reject-btn" variant="outlined"  sx={{ ...buttonClasses.lynkitBlackFill, ml: 1 ,textTransform: "none",}} onClick={() => setConfirmModal("Reject")}>Reject</Button>

              </>)}
        </Grid>
      )}

    <JobCreation
      showAlertDialog={showAlertDialog}
      SetshowAlertDialog={SetshowAlertDialog}
      selectedObjects={selectedObjects || []}
      handleRemoveSelection={handleRemoveSelection}
      jobValue={jobValue}
      warehouseFilter={warehouseFilter}
      floorFilter={floorFilter}
      invType={invType=="Kit"?"Item" :invType}
      isValid={isValid}
      setIsValid={setIsValid}
      />
   
      
      <ConfirmDialog
        status={actionConfirmModal}
        handleClose={handleCloseAction}
        selectedObjects={actionIndex != null ? [inventories[actionIndex]] : []}
        refreshTableData={() => refreshTableData(page + 1, limit)}
        onSuccess={handleCloseAction}
      />
      {actionIndex != null && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          <MenuItem
            sx={{ ...textClasses.normalText }}
            onClick={() => {
              handleSelectedRow(inventories[actionIndex]);
              handleCloseAction();
            }}
          >
            View Details
          </MenuItem>
          {inventories[actionIndex].isPackageExist && (
            <MenuItem
              sx={{ ...textClasses.normalText }}
              onClick={() =>
                handleOpenDetailModal({
                  modalType: "packageDetail",
                  title: "Packages (s) Details",
                  type: invType,
                  data: inventories[actionIndex],
                })
              }
            >
              View Package
            </MenuItem>
          )}
          {inventories[actionIndex].isItemExist && (
            <MenuItem
              sx={{ ...textClasses.normalText }}
              onClick={() =>
                handleOpenDetailModal({
                  modalType: "itemDetail",
                  title: "Items (s) Details",
                  type: invType,
                  data: inventories[actionIndex],
                })
              }
            >
              View Items
            </MenuItem>
          )}

          {invType == "Shipment" && isDeleteAllowed(inventories[actionIndex]) && permissions?.permissions?.some((item) => item.permissionId === "deleteInventory") && (
            <MenuItem
              sx={{ ...textClasses.normalText }}
              // onClick={()=>deleteInventory(inventories[actionIndex])}
              onClick={() => setActionConfirmModal("Delete")}
            >
              Delete Inventory
            </MenuItem>
          )}
          {invType == "Kit" &&
            (inventories[actionIndex]?.status == "gateIn" || inventories[actionIndex]?.status == "pending") ?
            <MenuItem
              sx={{ ...textClasses.normalText }}
              onClick={() => setActionConfirmModal("Dekit")}
            >
              Create De-Kit Request
            </MenuItem>
            :
            ""
          }

          {permissions &&
          (permissions.permissions || []).some(
            (item) => item.permissionId === "generateInventoryLabel"
          ) && (!["rejected"].includes(inventories[actionIndex]?.status)) ? (
            <MenuItem
              sx={{ ...textClasses.normalText }}
              onClick={() =>
                handleOpenLabelModal({
                  shipmentId: inventories[actionIndex].shipmentId,
                  templateId:
                    invType == "Shipment"
                      ? inventories[actionIndex].template_id
                      : inventories[actionIndex].dataEntryTemplate_Id,
                })
              }
            >
              {/* <QrCodeScanner fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}} /> */}
              Generate Label
            </MenuItem>
          ) : (
            ""
          )}

          {/* <MenuItem sx={{...textClasses.normalText}} onClick={() => handleOpenRfidModal({shipmentId: inventories[actionIndex].shipmentId})}>
                        RFID Mapping
                    </MenuItem> */}
          {/* <MenuItem sx={{...textClasses.normalText}} key={5} onClick={() => setActionConfirmModal("Delete")}>Delete</MenuItem> */}
        </Menu>
      )}
      <Grid container sx={{ mt: 2 }}>
        <Grid item sm={selectedRow ? 8 : 12} xs={12}>
          <Paper>
            {loader ? (
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{ p: 10 }}
              >
                <Grid item>
                  <GenericLoader />
                </Grid>
              </Grid>
            ) : (
              <Table
                header={header}
                rows={rows}
                totalRows={totalRows}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
              />
            )}
          </Paper>
        </Grid>
        {selectedRow && (
          <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
            <DetailCard
              // invType={invType}
              invType={invType=="Kit"?"Item" :invType}
              data={(() => {
                if (invType == "Shipment") return shipmentData;
                if (invType == "Package") return packageData;
                if (["Item","Kit"].includes(invType)) return itemData;
                return null;
              })()}
              isDeleteAllowed= {isDeleteAllowed}
              handleDeleteInventory={deleteInventory}
              handleSelectedRow={handleSelectedRow}
              validHeaders={tableHeader.validHeader}
              getTableCell={getTableCell}
              handleOpenDetailModal={handleOpenDetailModal}
              handleOpenLabelModal={handleOpenLabelModal}
              handleOpenRfidModal={handleOpenRfidModal}
              getShipmentPackageItemDetails={getShipmentPackageItemDetails}
              refreshTableData={() => refreshTableData(page + 1, limit)}
              setActionConfirmModal={setActionConfirmModal}
              permissions={permissions}
              // actionMenu
              // handleOpenAction
              // handleCloseAction
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={detailModal != null}
        onClose={handleCloseDetailModal}
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"lg"}
      >
        <DialogContent>
          <DetailModal
            detailModal={detailModal}
            handleCloseDetailModal={handleCloseDetailModal}
          />
        </DialogContent>
      </Dialog>
      {/*
            <Modal
                open={detailModal != null}
                onClose={handleCloseDetailModal}
            >
                <Card sx={{...style("65%"), pt: 1}}>
                    <DetailModal detailModal={detailModal} handleCloseDetailModal={handleCloseDetailModal}/>
                </Card>
            </Modal>
            */}
      {labelModal != null && (
        <GenerateLabel
          data={labelModal}
          showModal={labelModal != null}
          handleCloseModal={handleCloseLabelModal}
        />
      )}
      { rfidModal?.length>0 && <Rfid
      invType={invType}
        data={rfidModal}
        showModal={rfidModal?.length>0}
        handleCloseModal={handleCloseRfidModal}
        refreshInvetory={() => refreshTableData(page + 1, limit)}
      />}
      {/* <Menu
                id="action-menu"
                anchorEl={actionMenu}
                open={actionMenu != null}
                onClose={handleCloseAction}
                MenuListProps={{
                    'aria-labelledby': 'action-button',
                }}
            >
                <MenuItem onClick={handleCloseAction}>Profile</MenuItem>
                <MenuItem onClick={handleCloseAction}>My account</MenuItem>
                <MenuItem onClick={handleCloseAction}>Logout</MenuItem>
            </Menu> */}
    </Grid>
  );
}
const JobCreation=(props)=>{
  const dispatch=useDispatch()
  const {showAlertDialog=false,SetshowAlertDialog,selectedObjects=[],jobValue,warehouseFilter,handleRemoveSelection,invType,isValid,setIsValid}=props
  const [allInv,setAllInv]=useState([])
  const [allWFData,setAllWFData]=useState([])
  const [wfDetail,setWFDetail]=useState({warehouseId:"",floorId:""})

  const warehouseOptions = [
    ...allWFData
      .filter((el) => el?.type == "warehouse")
      .map((_) => ({
        label: _.name,
        value: _._id,
        single: _.single || false,
      })),
  ];

  const floorOptions = (warehouse_Id) => {
    let options = []; 
    if(!warehouse_Id) return []
    for (let _ of allWFData) {
      if (
        warehouse_Id &&
        _?.type == "floor" &&
        _?.warehouseId == warehouse_Id
      ) {
        options.push({
          label: _.name,
          value: _._id,
        });
      }
    }

    return options;
  };

  const handleValidater =() =>{
    const payload ={
      inventoryType:invType.toLowerCase()+'Id',
      inventoryID :allInv.map((_)=>(_[invType.toLowerCase()+'Id'])),
      floorId:wfDetail?.floorId
    }
    dispatch(getvalidateGateInInventory(payload,({success,message})=>{
      showToast(message,!success)
      setIsValid(success)
    }))
  }
  const handleJobRedirect =(jobValue) =>{
    history.push('/dashboard/jobs/create-job',{
      jobType:jobValue,
      warehouseId:wfDetail?.warehouseId || '',
      floorId:wfDetail?.floorId || '',
      inventoryType:invType.toLowerCase()+'Id',
      inventoryID :allInv?.map((_)=>(_[invType.toLowerCase()+'Id'])),
      
    })
  }

  useEffect(()=>{
    dispatch(getCompany({ type: ["warehouse", "floor"], status: "active" },({data=[]})=>{
      setAllWFData(data?.length && data || [])
    }));
  },[])

  useEffect(()=>{
    setWFDetail({warehouseId:warehouseFilter?._id || "",floorId:""})
    setAllInv(selectedObjects || [])
  },[warehouseFilter,selectedObjects, showAlertDialog])


  const handleRemoveitem = (item) =>{
    let listOfPils =[...allInv];
    const filteredList = listOfPils.filter((o)=>o.shipmentId !== item);
    setAllInv([...filteredList]);
  }
 
  const numberOfRows = Math.ceil(allInv.length/4 || 0)
  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 4 + columnIndex; // Assuming 3 columns
    const item = allInv[index]?.shipmentId;
    //console.log('itemn',item);
    return (
      <div style={{ ...style, padding: "1px" }}>
        {item && (
           <div style={{ margin: '3px' }}>
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.themeLightOrange,
              border: `1px solid ${theme.themeOrange}`,
              borderRadius: "12px",
              pl: 1,
              pr: 1,
              minHeight: "32px",
          
            }}
          >
            <Grid item sm={10} xs={8} >
              {item && item.length > 12 ? (
                <Tooltip arrow placement="top" title={item}>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      textAlign: "start",
                    }}
                  >
                    {item && item.length > 20
                      ? item.substring(0, 20) + "..."
                      : item}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    color: theme.themeOrange,
                    textAlign: "start",
                  }}
                >
                  {item}
                </Typography>
              )}
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "end" }}>
               <IconButton
                sx={{ alignContent: "right" }}
                onClick={() => {handleRemoveitem(item)}}
                disabled={allInv?.length<2}
                >
                <Cancel sx={{ color: theme.themeOrange }} />
                </IconButton> 
                </Grid>
            </Grid>
          
          </div>
        )}
      </div>
    );
  };

  const getWarehouseSnippet=()=>{
  return <>
         <Box sx={{display:'flex'}}>
        <Box sx={{width:"30%"}}>
        <Typography>
          Select Warehouse *:
        </Typography>
        </Box>
        <Box sx={{width:"70%"}}>
          <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      labelId="jobName-label"
                      sx={{ height: "1.1rem" }}
                      disabled={warehouseFilter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <Typography sx={{ fontSize: "12px" }}>
                              Warehouse
                            </Typography>
                          }
                          sx={{
                            ...selectStylesOverride,
                            fontSize: "12px",
                            "& input::placeholder": {
                              fontSize: "9px",
                            },
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.label}
                          </Box>
                        )}
                      // getOptionLabel={option=>option?.label}
                      options={warehouseOptions || []}
                      onChange={(e, option) => {
                        setWFDetail({warehouseId:option?.value || "",floorId:""})
                        setIsValid(false)
                      }}
                       value={wfDetail.warehouseId && warehouseOptions.find(dl=>dl?.value==wfDetail.warehouseId)?.label || ""}
                    />
          </FormControl>
        </Box>
        </Box>
        {
          jobValue =='dispatch'?'': <Box sx={{display:'flex',mt:3}}>
          <Box sx={{width:"30%"}}>
          <Typography>
            Select Floor *:
          </Typography>
          </Box>
          <Box sx={{width:"70%"}}>
            <FormControl fullWidth>
                      <Autocomplete
                        size="small"
                        labelId="jobName-label"
                        sx={{ height: "1.1rem" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <Typography sx={{ fontSize: "12px" }}>
                              Floor
                          </Typography>
                            }
                            sx={{
                              ...selectStylesOverride,
                              fontSize: "12px",
                              "& input::placeholder": {
                                fontSize: "9px",
                              },
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.label}
                            </Box>
                          )}
                        // getOptionLabel={option=>option?.label}
                        options={floorOptions(wfDetail?.warehouseId || "") || []}
                        onChange={(e, option) => {
                          setWFDetail({...wfDetail,floorId:option?.value || ""})
                          setIsValid(false)
                        }}
                        value={wfDetail.floorId && floorOptions(wfDetail?.warehouseId || "").find(dl=>dl?.value==wfDetail.floorId)?.label || ""}
                      />
            </FormControl>
           
          </Box>
          </Box>
        }
    </>
  }
  const getDisableCondition=()=>{
    let condition=true

    if(jobValue=="gateIn"){
      condition=!isValid
    }
    else{
      condition=!(wfDetail?.warehouseId || false)
    }
    return condition
  }
  return (
    <AlertDialog
        viewDialog={showAlertDialog}
        title={<span>Create Job :{jobValue ==="gateIn"? <span style={{color:"#FF7200"}}>Put Away Job</span>: <span style={{color:"#FF7200"}}>Dispatch</span>}</span>}
        handleClose={() => SetshowAlertDialog(false)}
        onClick={() =>SetshowAlertDialog(false)}
        body={ 
        <>
               <Box
          sx={{
            border: "2px solid #FF7200",
            borderRadius: "8px",
            padding: "0px 20px 10px 0",
            marginBottom: "20px",
            width:'100%',
            height: numberOfRows <= 4 ? `${57 * numberOfRows}px` : "215px",
          }}
        >
          {
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <FixedSizeGrid
                    columnCount={4} // Number of columns
                    columnWidth={190} // Width of each column
                    height={numberOfRows <= 4 ? 57 * numberOfRows : 210} // Total height of the grid
                    rowCount={numberOfRows}
                    rowHeight={50} // Height of each row
                    width={width} // Total width of the grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width:'765px',
                      position:'absolute',
                     left:'3px'
                    }}
                  >
                    {cellRenderer}
                    
                  </FixedSizeGrid>
                );
              }}
            </AutoSizer>
          }
        </Box>
       {getWarehouseSnippet()}
        </>
        }
      >
       <Box sx={{display:"flex",justifyContent:'flex-start'}}>
          <Button
          fullWidth
          variant="outlined"
          onClick={() => SetshowAlertDialog(false)}
          sx={{ ...buttonClasses.lynkitBlackFill, m:1, fontSize: ".9rem", height: '37px',width:'95px' }}
          >
            Cancel
          </Button>
         
         
          {jobValue == "gateIn" ?
             <Button
              fullWidth
              disabled={!wfDetail || !wfDetail.floorId || isValid || !allInv || allInv.length==0}
              variant="outlined"
              onClick={handleValidater}
              sx={{ ...buttonClasses.lynkitOrangeFill, m:1, fontSize: ".9rem", height: '37px',width:'95px' }}
            >
            validate
           </Button>
          :''}
          <Button
          fullWidth
          disabled={getDisableCondition()}
          variant="outlined"
          onClick={() => handleJobRedirect(jobValue,warehouseFilter)}
          sx={{ ...buttonClasses.lynkitOrangeFill, m:1, fontSize: ".9rem", height: '37px',width:'95px' }}
          >
            Next
          </Button>

       </Box>
      </AlertDialog>
  )
}

const ConfirmDialog = ({
  status,
  handleClose,
  selectedObjects,
  setSelectedObjects,
  refreshTableData,
  handleRemoveSelection,
}) => {
  const dispatch = useDispatch();
  const [dataReceived,isDataReceived]= useState(false)
  const [showAll, setShowAll] = useState(false);
  const [reason, setReason] = useState("");
  const size = selectedObjects.length;
  const callBack = (data) => {
    showToast(data.message, !data.success);
    isDataReceived(false);
    if(data.success){
      refreshTableData();
      handleClose();
      if (setSelectedObjects) {
        setSelectedObjects([]);
      }
    }
  };

  const handleStatusChange = () => {
    isDataReceived(true)
    let payload = {}
    if (status == "Delete") {
      payload = {
        shipmentId: selectedObjects.map(s => s.shipmentId),
      }
      dispatch(deleteInventoryData(payload, callBack))
    }
    else if (status == "Dekit") {
      payload = {
        itemIds: selectedObjects.map(s => s.itemId),
      }
      dispatch(initiateDekitProcess(payload, callBack))
    }
    else {
      if (status == "Approve") {
        payload = {
          shipmentIds: selectedObjects.map(s => s.shipmentId),
          "approved": true
        }
      }
      else {
        payload = {
          shipmentIds: selectedObjects.map(s => s.shipmentId),
          "approved": false,
          reason: reason
        }
      }
      dispatch(
        approveRejectPendingShipment(payload, (data) => {
          if (data) {
            callBack(data);
          }
        })
      );
    }
  };

  useEffect(() => {
    setReason("");
  }, [status]);


  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"md"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
          {status == "Dekit" ?  "Create de-kit request of selected Kit(s)" :"Are you Sure" }
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          {status == "Dekit" ? <Typography sx={{ ...textClasses.t16n }}>
          All de-kit request will be listed in kitting & De-kitting page for further process for same.
          </Typography> :  <Typography sx={{ ...textClasses.cardTitle }}>
           You want to {status} the selected inventory
          </Typography> }
        
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {selectedObjects.slice(0, showAll ? size : 5).map((st, i) => {
            return (
              <Grid item sm={3} xs={4} key={i}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1,
                    minHeight: "40px",
                  }}
                >
                  <Grid item sm={10} xs={8}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {/* {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name} */}
                      {status == "Dekit"?st.itemId :st.shipmentId }
                    </Typography>
                  </Grid>
                  {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={() => handleRemoveSelection(st._id)}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
          {size > 5 && (
            <>
              <Grid
                container
                item
                sm={4}
                xs={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? <>Collapse</> : <>View All ({size})</>}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        {
          status == "Reject" ?
            <Grid container direction={"row"} sx={{ mt: 1 }}>
              <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
            :
            ""

        }
        <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
          <Button
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.outlined,
              borderColor: theme.themeOrange,
              color: theme.themeOrange,
              minWidth: "150px",
            }}
            disabled={dataReceived}
            onClick={handleClose}
            >
            No, Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses.small,
              backgroundColor: theme.themeOrange,
              minWidth: "150px",
            }}
            disabled={selectedObjects.length <= 0 ||status=="Reject" ? (!(reason || "").trim() || dataReceived):(dataReceived || false)  }
            onClick={handleStatusChange}
            >
            Yes, {status === "Dekit" ? "De-kit" : status}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const DetailModal = ({ detailModal, handleCloseDetailModal }) => {
  const title = detailModal ? detailModal.title : "Loading...";
  const modalType = detailModal ? detailModal.modalType : null;
  const invType = modalType == "packageDetail" ? "Package" : "Item";
  const data = detailModal ? detailModal.data : null;

  ////("DETAIL MODAL", detailModal);

  const dispatch = useDispatch();
  const inventories = useSelector((state) => state.inventory.modalInventories) || [];
  const totalRows = useSelector((state) => state.inventory.modalTotalRows);

  
  const { baseLabels: header, baseIds: headerIds } = getHeader(invType, true);


  const rows = inventories?.map((d, i) => {
    let row = [];
    for (let j = 0; j < headerIds.length; j++) {
      row.push(
        getTableCell(
          d,
          invType,
          headerIds[j],
          false,
          () => {},
          () => {}
        )
      );
    }
    return row;
  });

  // console.log("rows",inventories)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);

  const [packageFilter, setPackageFilter] = useState(
    data ? data.packageId : ""
  );
  const [itemFilter, setItemFilter] = useState("");

  const handlePackageChange = (e) => {
    setPackageFilter(e.target.value);
  };

  const handleItemChange = (e) => {
    setItemFilter(e.target.value);
  };

  useEffect(() => {
    if (detailModal) {
      // ////("Shipment Id");
      // ////(modalType);
      // ////(data.shipmentId);
      let payload = {
        page: page + 1,
        limit: limit,
        shipmentId: data.shipmentId,
      };
      if (modalType == "itemDetail") {
        // ////("Setting packageId " + data.packageId);
        payload["packageId"] = packageFilter;
        payload["itemId"] = itemFilter;
      }
      setLoader(true)
      dispatch(
        getInventoryForModal(modalType, {
          ...payload,
        },(response)=>{
          setLoader(false)
        })
      );
    }
    // else{
    //     dispatch(getInventoryForModal(modalType, {}))
    // }
  }, [detailModal, packageFilter, itemFilter, page, limit]);
  return (
    <>
      {detailModal && (
        <>
          <Grid container>
            <Grid
              container
              item
              sm={12}
              xs={12}
              alignItems="center"
              justifyContent={"end"}
            >
              <IconButton sx={{ mb: 1 }} onClick={handleCloseDetailModal}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item sm={4} xs={6}>
              <Typography sx={{ ...textClasses.cardTitle }}>{title}</Typography>
            </Grid>
            {/* <Grid item sm={8} xs={6}>
                            {
                                modalType == "itemDetail" &&
                                <TextField size='small' sx={{...inputClasses.shadowField, float: "right", ml: 2}} placeholder="Search Item Id" value={itemFilter} onChange={handleItemChange}/>
                            }
                            {
                                data && data.isPackageExist &&
                                <TextField size='small' sx={{...inputClasses.shadowField, float: "right"}} placeholder="Search Package Id" value={packageFilter} onChange={handlePackageChange}/>
                            }
                            
                            
                        </Grid> */}
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            {
              loader
              ?
                <Grid container justifyContent="center">
                  <GenericLoader/>


                </Grid>
              :
              <Paper sx={{ width: "100%", overflowX: "hidden" }}>
                <Table
                  header={header}
                  rows={rows}
                  totalRows={totalRows}
                  page={page}
                  limit={limit}
                  setPage={setPage}
                  setLimit={setLimit}
                />
              </Paper>

            }
           
          </Grid>
        </>
      )}
    </>
  );
};

const DetailCard = ({
  invType,
  data,
  handleSelectedRow,
  isDeleteAllowed,
  handleDeleteInventory,
  // getTableCell,
  validHeaders,
  handleOpenDetailModal,
  handleOpenLabelModal,
  handleOpenRfidModal,
  getShipmentPackageItemDetails,
  refreshTableData,
  setActionConfirmModal,
  permissions
}) => {
  const dispatch = useDispatch();
  data = invType == "Shipment" ? data : data ? data[0] : null;
  // const header = validHeaders?.find((header)=> header?.renderFunction && header?.renderFunction === "status")
  // const status = data && getTableCell(header, data, undefined, true, invType);
  const status = data && getTableCell(data, invType, "status", true);
  let templateHeader = useSelector((state) => state.inventory.templateHeader);
  const asnHeader = useSelector((state) => state.asnEntry.shipmentTemplateHeader);
  const itemMaster = useSelector((state) => state.asnEntry.orderConnectedMasterData);
  const { AllUserData } = useSelector((state) => state.users);
  const isScanned =data?.ItemMaster_Detail && (data.ItemMaster_Detail.formData || data.ItemMaster_Detail.itemData);

  const skipValues = ["productName", "SKU_Number", "USN_Value", "SKU_Name", "customShipment", "customPkg", "customItem",
    "SKU_ID", "SKU_Detail", "UOM", "quantity", "product_ID"
  ]

  ////("Users Data", AllUserData);
  let userInfo =
    data && AllUserData && AllUserData.data
      ? AllUserData.data.filter((u) => {
          if (data.created_by && data.created_by._id) {
            return u._id == data.created_by._id;
          }
          return false;
        })
      : [];
  userInfo = userInfo.length > 0 ? userInfo[0] : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [cardConfirmModal, setCardConfirmModal] = useState(null);

  ////("Template Detail Card Header");
  ////(templateHeader);
  const [displayUrl, setDisplayUrl] = useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const displayFile = (fileUrl, fileKey) => {
    const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
    const isValidImageUrl = imageUrlRegex.test(fileKey);
    if (isValidImageUrl) {
      setOpenModal(true);
      setDisplayUrl(fileUrl);
    } else {
      window.open(fileUrl, "_blank");
    }
  };

  const handleFile = (fileKey) => {
    setOpenModal(false);
    setDisplayUrl("");
    dispatch(fetchFile({ fileKey }, displayFile));
  };
  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const DetailRow = ({ title, children }) => {
    return (
      <Grid container sx={{ mt: 1 }}>
        <Grid item sm={4} xs={4}>
          <Typography
            sx={{
              ...textClasses.t12n,
              color: "#827878",
              wordWrap: "break-word",
              mr: 1,
            }}
          >
            {title}:{" "}
          </Typography>
        </Grid>
        <Grid
          container
          item
          sm={8}
          xs={8}
          direction="row"
          alignItems={"center"}
          sx={{ wordWrap: "break-word", wordBreak: "break-all" }}
        >
          {children}
        </Grid>
      </Grid>
    );
  };

  const handleCloseConfirm = () => {
    setCardConfirmModal(null);
    // handleSelectedRow(null)
  };

  const renderDynamicData = (value, head) => {
    if (["length", "width", "height", "weight"].includes(head?.name) && !value) {
      return (<Typography
        sx={{
          ...textClasses.t12n,
          color: theme.themeOrange,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => dispatch(getParcelDimensionsData({ parcelID: data[invType.toLowerCase() + "Id"], isUpdate: true, inventoryType: invType.toLowerCase() }, (data) => {
          showToast(data.message, !data.success);
          if (data.success) {
            getShipmentPackageItemDetails()
          }
        }))}
      >fetch data
      </Typography>)
    }
    else {
      if (Array.isArray(value)) {
        return value.join(", ") || "-"
      }
      return value || "-"
    }
  }

  useEffect(() => {
    if (data?.template_id || data?.dataEntryTemplate_Id) {
      dispatch(getAllUsers({ isAdminIncluded: true ,projection:{name:1,_id:1,designation:1}}));
      if (data?.ASN_Detail?.template_id || data?.ASN_Detail?.ASN_ItemDetail?.template_id) {
        dispatch(
          getShipmentMasterHeader({
            template_id:
              invType == "Shipment"
                ? data.ASN_Detail.template_id
                : data.ASN_Detail.ASN_ItemDetail.template_id,
          })
        );
      }
      else if(data?.ItemMaster_Detail && (data?.ItemMaster_Detail?.formData || data?.ItemMaster_Detail?.itemData)){
        dispatch(
          getShipmentMasterHeader({
            template_id: data.ItemMaster_Detail.templateId,
          })
        );
      }
    }
  }, [data]);

  return (
    <>
      {data && (
        <Card
          sx={{
            ...cardClasses.basic,
            borderRadius: "8px",
            border: `1px solid ${theme.themeOrange}`,
            position: "relative",
            height: "700px",
          }}
        >
          <CardHeader
            sx={{ height: "46px", backgroundColor: theme.themeOrange }}
            title={
              <Typography
                sx={{
                  ...textClasses.boldText,
                  color: "white",
                  fontSize: "16px",
                }}
              >
                <SellOutlined fontSize="inherit" sx={{ mr: 1 }} />
                {invType} ID: {data[invType.toLowerCase() + "Id"]}
              </Typography>
            }
            action={
              <IconButton
                size="small"
                sx={{ p: 0, mb: 1 }}
                onClick={() => handleSelectedRow(null)}
              >
                <Close sx={{ color: "white" }} />
              </IconButton>
            }
          />
          <CardContent
            sx={{ minHeight: "600px", maxHeight: "600px", overflowY: "scroll" }}
          >
            {data.status != "gateOut" && (
              <DetailRow title="Location">
                <Typography sx={{ ...textClasses.t12n }}>{
                  (data?.balanceCount?.physical > 0 || data.mappingArea && data.mappingArea.length > 0)
                    ?
                    getTableCell(data, invType, "mappingArea", true)
                    :
                    "Put It Anywhere"

                }</Typography>
              </DetailRow>
            )}

            <DetailRow title="Current Status">
              <Button
                sx={{
                  p: status?.padding,
                  cursor: "text !important",
                  ...buttonClasses.small,
                  color: status?.color,
                  backgroundColor: status,
                  border: `1px solid ${status?.color}`,
                  "&:hover": { backgroundColor: status?.background },
                }}
              >
                {status?.label}
              </Button>
              <Typography
                sx={{ ...textClasses.t12n, color: theme.themeOrange, ml: 1 }}
              >
                {data.status == "pending"}
                {data.status == "gateIn"}
                {data.status == "gateOut"}
              </Typography>
            </DetailRow>
            <DetailRow title="QC Result">
              {getTableCell(data, invType, "qcResult", true)}
            </DetailRow>
            
            {(invType == "Package" || invType == "Item") && data?.palletMasterDetail?.SKU_ID && (
              <>
                <DetailRow title="Pallet ID">
                  <Typography sx={{ ...textClasses.t12n }}>
                    {data?.palletMasterDetail?.SKU_ID ? data?.palletMasterDetail?.SKU_ID : "-"}
                  </Typography>
                </DetailRow>
              </>
            )}
            <DetailRow title="Data Entry Time">
                <Typography sx={{ ...textClasses.t12n }}>
                  {getTableCell(data, invType, "entryTime", true)}
                </Typography>
              </DetailRow>
            {["Item","Kit"].includes(invType) && (
              <>
                <DetailRow title={"SKU Number"}>
                  <Typography sx={{ ...textClasses.t12n }}>
                    {data.SKU_Number}
                  </Typography>
                </DetailRow>
                <DetailRow title={"SKU Name"}>
                  <Typography sx={{ ...textClasses.t12n }}>
                    {data?.dynamicData?.SKU_Name
                      ?data?.dynamicData?.SKU_Name
                      : "-"}
                  </Typography>
                </DetailRow>
                {"Item" == invType && <>
                  <DetailRow title={"USN"}>
                    <Typography sx={{ ...textClasses.t12n }}>
                      {data?.dynamicData?.USN_Value
                        ? data?.dynamicData?.USN_Value
                        : "-"}
                    </Typography>
                  </DetailRow>
                  <DetailRow title={"Package Id"}>
                    <Typography sx={{ ...textClasses.t12n }}>
                      {data.packageId ? data.packageId : "-"}
                    </Typography>
                  </DetailRow>
                </>
                }
              </>
            )}
            {["Package","Item","Kit"].indexOf(invType) >= 0 && (
              <>
                <DetailRow title="Shipment Id">
                  <Typography sx={{ ...textClasses.t12n }}>
                    {data.shipmentId ? data.shipmentId : "-"}
                  </Typography>
                </DetailRow>
              </>
            )}

            {invType == "Shipment" && data.isPackageExist && (
              <DetailRow title="Total Package Count">
                <Typography
                  sx={{
                    ...textClasses.t12n,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleOpenDetailModal({
                      modalType: "packageDetail",
                      title: "Package (s) Details",
                      type: invType,
                      data: data,
                    })
                  }
                >
                  View Packages ({data.totalPackage})
                </Typography>
              </DetailRow>
            )}
            {["Shipment", "Package"].indexOf(invType) >= 0 &&
              data.isItemExist && (
                <DetailRow title="Total Item Count">
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      color: theme.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleOpenDetailModal({
                        modalType: "itemDetail",
                        title: "Item (s) Details",
                        type: invType,
                        data: data,
                      })
                    }
                  >
                    View Items ({data.totalItem ? data.totalItem : data.count})
                  </Typography>
                </DetailRow>
              )}
            {data.gateOutTime && (
              <DetailRow title="Gate Out Time">
                <Typography sx={{ ...textClasses.t12n }}>
                  {getTableCell(data, invType, "gateOutTime", true)}
                </Typography>
              </DetailRow>
            )}
            {data.gateInTime && (
              <DetailRow title="Gate In Time">
                <Typography sx={{ ...textClasses.t12n }}>
                  {getTableCell(data, invType, "gateInTime", true)}
                </Typography>
              </DetailRow>
            )}
            {templateHeader && templateHeader[invType.toLowerCase() + "Header"] && templateHeader[invType.toLowerCase() + "Header"]
              .filter(head => skipValues.indexOf(head.name) < 0)
              .map((head, i) => {
                skipValues.push(head?.name || {})
                let dataObj=invType == "Shipment" ? data.formData :data?.dynamicData || {}
                let value=dataReadFunction(head, dataObj, "name")
                return (
                  <DetailRow title={head.label} key={i}>
                    <Typography sx={{ ...textClasses?.t12n }}>
                      {
                        head.type == "file" && value ? (
                          <div onClick={() => handleFile(value)}>
                            <span style={{ ...buttonClasses.viewFile, cursor: "pointer" }}>
                              View File
                            </span>
                          </div>
                        ) : head.type == "date" && value
                          ? dateTimeFormater(value, head?.format || "DD MMM YYYY")
                          : renderDynamicData(value || "", head)
                      }
                    </Typography>
                    
                  </DetailRow>
                );
              }
              )}

            {data.ASN_Detail && (
              <>
                {asnHeader && asnHeader[invType == "Shipment" ? "shipmentHeader" : "itemHeader"] &&
                 (asnHeader[invType == "Shipment" ? "shipmentHeader" : "itemHeader"] ||   [])
                    .filter((h) => skipValues.indexOf(h.name) < 0)
                    .map((head, i) => {
                      let asnObj = (invType == "Shipment" ? data?.ASN_Detail?.formData || {} : data?.ASN_Detail?.ASN_ItemDetail?.formData || {}) || {};
                      skipValues.push(head?.name || {})
                      let value = dataReadFunction(head, asnObj, "name")
                      return (
                        <DetailRow title={head.label} key={i}>
                          <Typography sx={{ ...textClasses?.t12n }}>
                            {
                              head.type == "file" && value ? (
                                <div onClick={() => handleFile(value)}>
                                  <span style={{ ...buttonClasses.viewFile, cursor: "pointer" }}>
                                    View File
                                  </span>
                                </div>
                              ) : head.type == "date" && value
                                ? dateTimeFormater(value, head?.format || "DD MMM YYYY")
                                : renderDynamicData(value || "", head)
                            }
                          </Typography>

                        </DetailRow>
                      );
                    })}
                {data?.ASN_Detail?.receivingMethod && (
                  <>
                    <DetailRow title={"Receiving Method"}>
                      <Typography sx={{ ...textClasses.t12n }}>
                        {data?.ASN_Detail?.receivingMethod?.label}
                      </Typography>
                    </DetailRow>
                  </>
                )}
                {invType != "Shipment" && (asnHeader?.itemMaster?.length && asnHeader?.itemMaster || [])
                  .filter((h) => skipValues.indexOf(h.name) < 0)
                  .map((head, i) => {
                    let formData = data?.ItemMaster_Detail?.itemData || data?.ASN_ItemDetail?.itemMasterDetail?.itemMasterData || {}
                    skipValues.push(head?.name || {})
                    let value = dataReadFunction(head, formData, "name")
                    return (
                      <DetailRow title={head.label} key={i}>
                        <Typography sx={{ ...textClasses?.t12n }}>
                          {
                            head.type == "file" && value ? (
                              <div onClick={() => handleFile(value)}>
                                <span style={{ ...buttonClasses.viewFile, cursor: "pointer" }}>
                                  View File
                                </span>
                              </div>
                            ) : head.type == "date" && value
                              ? dateTimeFormater(value, head?.format || "DD MMM YYYY")
                              : renderDynamicData(value || "", head)
                          }
                        </Typography>

                      </DetailRow>
                    );
                  })
                }
              </>
            )}
            {isScanned && asnHeader && Array.isArray(asnHeader) &&
              asnHeader
                .filter((h) => skipValues.indexOf(h.name) < 0)
                .map((head, i) => {
                  let dataObj = data?.ItemMaster_Detail?.itemData || {}
                  let value = dataReadFunction(head, dataObj, "name")
                  return (
                    <DetailRow title={head.label} key={i}>
                      <Typography sx={{ ...textClasses?.t12n }}>
                        {
                          head.type == "file" && value ? (
                            <div onClick={() => handleFile(value)}>
                              <span style={{ ...buttonClasses.viewFile, cursor: "pointer" }}>
                                View File
                              </span>
                            </div>
                          ) : head.type == "date" && value
                            ? dateTimeFormater(value, head?.format || "DD MMM YYYY")
                            : renderDynamicData(value || "", head)
                        }
                      </Typography>

                    </DetailRow>
                  );
                })
            }
            {userInfo && (
              <>
                <DetailRow title="Created By">
                  <Typography sx={{ ...textClasses.t12n }}>
                    {userInfo.name ? userInfo.name : "-"} ,{" "}
                    {userInfo.designation ? userInfo.designation : "-"}
                  </Typography>
                </DetailRow>
              </>
            )}
          </CardContent>
          <Grid container sx={{ position: "absolute", bottom: "10px" }}>
            <hr />
            <Grid
              container
              direction={"row"}
              justifyContent="center"
              alignItems="center"
            >
              {
                !["rejected"].includes(data?.status) &&
              <Button
                size="small"
                variant="contained"
                sx={{ ...buttonClasses.lynkitOrangeFill }}
                onClick={() =>
                  handleOpenLabelModal({
                    shipmentId: data.shipmentId,
                    templateId:
                      invType == "Shipment"
                        ? data.template_id
                        : data.dataEntryTemplate_Id,
                  })
                }
              >
                <QrCodeScanner
                  fontSize="inherit"
                  sx={{ color: "white", mr: 1 }}
                />
                Generate Label
              </Button>
              }
              <Button
                size="small"
                variant="contained"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  borderRadius: "8px",
                  ml: 2,
                }}
                onClick={handleOpenMenu}
              >
                Action <ArrowDropDown />
              </Button>
            </Grid>
          </Grid>
          <ConfirmDialog
            status={cardConfirmModal}
            handleClose={handleCloseConfirm}
            selectedObjects={data ? [data] : []}
            refreshTableData={refreshTableData}
          />
          <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
            {invType == "Shipment" && data.isPackageExist && (
              <MenuItem
                sx={{ ...textClasses.normalText }}
                onClick={() =>
                  handleOpenDetailModal({
                    modalType: "packageDetail",
                    title: "Package (s) Details",
                    type: invType,
                    data: data,
                  })
                }
              >
                View Packages
              </MenuItem>
            )}
            {["Shipment"].indexOf(invType) >= 0 && isDeleteAllowed(data) && permissions?.permissions?.some((item) => item.permissionId === "deleteInventory") && 
              (
                <MenuItem
                  sx={{ ...textClasses.normalText }}
                  // onClick={()=>handleDeleteInventory(data)}
                  onClick={() => setCardConfirmModal("Delete")}
                >
                  Delete Inventory
                </MenuItem>
              )}
            {["Shipment", "Package"].indexOf(invType) >= 0 &&
              data.isItemExist && (
                <MenuItem
                  sx={{ ...textClasses.normalText }}
                  onClick={() =>
                    handleOpenDetailModal({
                      modalType: "itemDetail",
                      title: "Item (s) Details",
                      type: invType,
                      data: data,
                    })
                  }
                >
                  View Items
                </MenuItem>
              )}
            {!["rejected"].includes(data?.status) &&
              <MenuItem
                sx={{ ...textClasses.normalText }}
                onClick={() =>
                  handleOpenLabelModal({
                    shipmentId: data.shipmentId,
                    templateId:
                      invType == "Shipment"
                        ? data.template_id
                        : data.dataEntryTemplate_Id,
                  })
                }
              >
                Generate Label
              </MenuItem>
            }
            {invType == "Kit" &&
              (data?.status == "gateIn" || data?.status == "pending") ?
              <MenuItem
                sx={{ ...textClasses.normalText }}
                onClick={() => setActionConfirmModal("Dekit")}
              >
                Create De-Kit Request
              </MenuItem>
              :
              ""
            }
            {/* <MenuItem sx={{...textClasses.normalText}}
                            onClick={() => handleOpenRfidModal({
                                shipmentId: data.shipmentId
                            })}
                        >
                            Map RFID
                        </MenuItem> */}
          </Menu>

          <FileModal
            openModal={openModal}
            displayUrl={displayUrl}
            setOpenModal={setOpenModal}
          />
        </Card>
      )}
    </>
  );
};

const Table = ({ header, rows, totalRows, page, limit, setPage, setLimit }) => {
  return (
    <>
      <Box
        className="muitable"
        sx={{
          maxWidth: "100vw!important",
          // maxWidth: "92.5vw!important",
          // maxWidth: "1260px",
          overflowX: "auto",
        }}
      >
        <table
          style={{
            // display: "inline-block",
            // // width: "100%",
            width: "100%",

            // borderCollapse: "collapse",
            // maxHeight: "520px",
            // overflowX: "auto",
            // overflowY: "auto",
            // whiteSpace: "nowrap"
          }}
        >
          <thead style={{ backgroundColor: theme.themeGray }}>
            <tr>
              {header.slice(0, 2).map((head, i) => {
                return (
                  <td
                    style={{
                      padding: "14px 10px 14px 14px",
                      ...textClasses.boldText,
                      textAlign: "left",
                      overflowWrap: "break-word",
                    }}
                    key={i}
                  >
                    {head}
                  </td>
                );
              })}
              {header.slice(2, header.length).map((head, i) => {
                return (
                  <td
                    style={{
                      padding: "13px 10px 13px 10px",
                      ...textClasses.boldText,
                      textAlign: "center",
                      overflowWrap: "break-word",
                    }}
                    key={i}
                  >
                    {head}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              <>
                {rows.map((row, i) => (
                  <tr key={i} style={{ borderBottom: "1px solid #0000002B" }}>
                    {row.slice(0, 2).map((col, j) => {
                      return (
                        // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                        <td
                          style={{
                            textAlign: "left",
                            padding: "14px 5px 14px 14px",
                            ...textClasses.normalText,
                          }}
                          key={j}
                        >
                          {renderData(col,27)}
                        </td>
                      );
                    })}
                    {row.slice(2, row.length).map((col, j) => {
                      return (
                        // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "14px 5px 14px 14px",
                            ...textClasses.normalText,
                          }}
                          key={j}
                        >
                          {renderData(col,27)}
                          </td>
                      );
                    })}
                  </tr>
                ))}
              </>
            ) : (
              <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td
                  component="th"
                  scope="row"
                  colSpan={100}
                  style={{
                    ...textClasses.normalText,
                    textAlign: "center",
                    padding: "14px 0px 14px 0",
                  }}
                >
                  <Grid container justifyContent={"center"} alignItems="center">
                    <DataNotFound />
                  </Grid>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      {rows.length > 0 && (
        <Grid container alignItems={"center"} justifyContent="center">
          <TablePagination
            sx={{
              ".MuiTablePagination-selectLabel": { pt: "10px" },
              ".MuiTablePagination-displayedRows": { pt: "10px" },
            }}
            component="div"
            count={totalRows}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            rowsPerPage={limit}
            onRowsPerPageChange={(e) => {
              setLimit(e.target.value);
              setPage(0);
            }}
          />
        </Grid>
      )}
    </>
  );
};
