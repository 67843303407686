import React, { useState, useEffect,useRef } from 'react'
import {
    Grid, Typography, Button, Dialog, DialogContent, IconButton, ButtonGroup, FormControlLabel, Checkbox, Tooltip, Box
} from '@mui/material'
import theme, { inputClasses, buttonClasses, textClasses, cardClasses } from '../../../../../../static/styles/theme'
import { Add, AddOutlined, Cancel, Remove } from '@mui/icons-material';
import { getCamelCaseText, showToast, deepCopy_Object, calculateFormulaHelper,truncateNumber } from '../../../../../../services/functions';
import { useSelector } from 'react-redux';
import { getInputFieldForTableRow } from '../../../../../Components/TemplateInputConfiguration';
import AsnPackageItemTableSection from './AsnPackageItemTableSection';
import { getPalletComponent } from '../constant';
import GenericSingleRangeComponent from '../../../../../Components/Generic/GenericSingleRangeComponent';
import { useDebounceWithUseEffectDetection } from '../../../../../../utils/hooks';
import { validateFieldsMultiple } from '../../../../../../services/functions';

const AsnPackageTableDialog = ({ 
    open, spiType, openType = "add", onClose, template, items = [], 
    handleSubmitPackages, multiSKUSelected,isRejectReasonRequired
 }) => {
    //("Items in selected", items);
    const [packages, setPackages] = useState([]);
    const [errors, setErrors] = useState({});
    const [viewType, setViewType] = useState("package");
    const [packageObj, setPackageObj] = useState(null);
    const [isInitial, setIsInitial] = useState(true);
    const [pIndex, setPIndex] = useState(null);
    const [boxCount, setBoxCount] = useState(1);
    const [countPerBox, setCountPerBox] = useState(null);
    // const size = items.length;
    const packageItemCount = open != "" ? items?.map(i => i.count).reduce((a, b) => a + b, 0) : 0;
    const countTillNow = packages?.map((i) => Number(i.count)).reduce((a, b) => a + b, 0)

    const remainingItems = packageItemCount - countTillNow;

    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    let mandatoryFieldsId = useSelector(state => state.canvas.components[`packageTemplate`].mandatory.filter(f => {
        if (!f.templateType) return true;
        if (f.templateType && f.templateType == template.templateType) {
            return true;
        }
        return false;
    }).map(f => f._id));

    const mappingLocations = useSelector(state => state.inward.allMapping);

    let field = {
        type: "select",
        label: "Mapping Area",
        required: true,
        _id: "mappingArea",
        values: mappingLocations || []
        // .map((loc) => ({
        //     label: loc.label,
        //     value: loc.value
        // }))
        ,
        defaultValue: "PUT IT ANY WHERE"
    }
    //("Mandatory Ids", mandatoryFieldsId);

    const templateDetails = template.otherTemplate.find(ot => ot.templateType == `packageDetailTemplate`);
    const mandatoryFields = templateDetails ? templateDetails.attributes.filter(f => mandatoryFieldsId.indexOf(f._id) >= 0) : [];
    const otherFields = templateDetails ? templateDetails.attributes.filter(f => mandatoryFieldsId.indexOf(f._id) < 0) : [];
    const SI_templateDetails = template?.otherTemplate?.find(ot => ot?.templateType == `itemDetailTemplate`)?.attributes || [];

  

    let uomIndex = mandatoryFields.map(f => f._id).indexOf("UOM");
    if (uomIndex >= 0) {
        mandatoryFields.splice(uomIndex);
    }

    let completeFields = mandatoryFields.concat(otherFields)
    if (spiType == "shipmentPackageOnly") {
        completeFields = completeFields.concat([field])
        if(items?.some(dl=>dl.receivingType=="rejectedQuantity")){
            completeFields.splice(3,0,{
                _id: "rejectedReason",
                type: "input",
                label: "Reject Reason",
                placeholder: "Reject Reason",
                required: !!isRejectReasonRequired,
            })
        }
    }
    
    // if (permissions?.permissions?.some((item) => item.permissionId === "attachPalletMaster") && ["pallets", "bothPalletItem"].includes(receivingMethod?.value)) {
    //     let isExist= completeFields.findIndex(f => f._id=="pallet_ID")
    //     if(isExist<0){
    //         const palletField = getPalletComponent();
    //         completeFields.splice(0, 0, palletField)
    //     }
    // }
    const getTableHeader = (headerType) => {
        let base = ["Count"]
        let otherHeader = completeFields.filter(f => f._id != "count").map((of) => {
            let label = of.label;
            // if(headerType == "excel" && ["select", "radio", "checkbox"].indexOf(of.type) >= 0 && of.values != null && Array.isArray(of.values)){
            //     label = `${label} (${of.values.map(o => o.value).join(",")})`
            // }
            return label
        })
        let tempHeader = [...base, ...otherHeader, "Add Item*"]
        if (spiType == "shipmentPackageOnly") {
            tempHeader.pop()
        }
        return tempHeader;
    }

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleGenericChange = async (event, i, fieldId,option={}) => {
        const targetField = completeFields.find(f => f._id == fieldId) || {};
        let oldPackages = deepCopy_Object(packages);
        let oldObject = { ...oldPackages[i] };
        if (targetField.type == "file") {
            const file = await convertDocsBase64(event.target.files[0])
            oldObject[fieldId] = file;
        }
        else if (targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if (event.checked) {
                oldValues.push(event.value);
            }
            else {
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else {
            oldObject[fieldId] = event.target.value;
        }

        if (targetField?.extraValue?.length) {
            targetField.extraValue.map(dl => {
                if (typeof dl == "string") {
                    oldObject[dl] = option[dl] || ""
                }
                else if (typeof dl == "object" && dl.label) {
                    oldObject[dl.label] = option[dl.label] || ""
                }
            })
        }

        let formulaFields = completeFields?.find(dl=>dl?._id==fieldId)?.formulaFields || []
        if (formulaFields?.length > 0) {
            console.log(formulaFields,"formulaFields0")
            calculateFormulaHelper(oldObject, formulaFields, oldObject)
        }

        oldPackages[i] = { ...oldObject };
        if (spiType == "shipmentPackageOnly" && fieldId == "mappingArea" && oldObject.sameForNext) {
            oldPackages = oldPackages.map((oi, j) => {
                let ni = { ...oi };
                if (j > i) {
                    let change = true
                    ni.sameForNext = false
                    if (!oldObject.mappingArea) {
                        ni.mappingArea = ""
                    }
                    else if (ni?.restrictedMappingArea?.length > 0 && !ni?.restrictedMappingArea?.includes(oldObject.mappingArea)) {
                        change = false
                    }
                    else {
                        change = true
                    }
                    if (change) {
                        ni.mappingArea = oldObject.mappingArea;
                    }
                }
                return ni;
            })
        }



        setPackages([...oldPackages]);
    }

    const handleDeleteItem = (i) => {
        let oldPackages = deepCopy_Object(packages);
        let itemDeleted = oldPackages[i] || {}
        oldPackages.splice(i, 1);
        let nextItemWithSKU = oldPackages.findIndex(dl => dl.SKU_Number == itemDeleted.SKU_Number)
        if (nextItemWithSKU >= 0) {
            oldPackages.splice(nextItemWithSKU, 1, {
                ...oldPackages[nextItemWithSKU],
                maxCount: truncateNumber(Number(oldPackages[nextItemWithSKU]?.maxCount || 0) + Number(itemDeleted.maxCount || 0))
            });
        }
        setPackages(oldPackages);
    }

    const handleAddItem = (i) => {
        let oldPackages = deepCopy_Object(packages);
        let itemFromAdded = oldPackages[i] || {}
        if (Number(itemFromAdded?.maxCount || 0) > Number(itemFromAdded?.count || 0)) {
            let newItem = deepCopy_Object(itemFromAdded)
            let availCount = truncateNumber(Number(itemFromAdded?.maxCount || 0) - Number(itemFromAdded?.count || 0))
            newItem["count"] = availCount
            newItem["maxCount"] = availCount

            itemFromAdded["maxCount"] = truncateNumber(Number(itemFromAdded?.count || 0))
            let formulaFields = completeFields?.find(dl=>dl?._id=="count")?.formulaFields || []
            if (formulaFields?.length > 0) {
                calculateFormulaHelper(newItem, formulaFields, newItem)
            }
            oldPackages.splice(i, 1, itemFromAdded, newItem);

            setPackages(oldPackages);
        }
    }

    const autoFillOnFocus = (index) => {
        if (dimensions) {
            let oldPackages = deepCopy_Object(packages);
            for (let i = 0; i < completeFields.length; i++) {
                let compId = completeFields[i]._id
                if (["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]) {
                    oldPackages[index][compId] = dimensions[compId];
                }
            }
            setPackages(oldPackages)
        }
    }

    const handleItemCount = (countValue,i,maxCount = 0) => {
        if (!multiSKUSelected) {
            return;
        }
        let oldItems = deepCopy_Object(packages);
        if(countValue<=0){
            oldItems[i].count=0
        }
        else{
            oldItems[i].count = Math.min(countValue, Number(maxCount || 0))
        }
        let formulaFields = completeFields?.find(dl=>dl?._id=="count")?.formulaFields || []
        if (formulaFields?.length > 0) {
            calculateFormulaHelper(oldItems[i], formulaFields, oldItems[i])
        }
        setPackages(oldItems);
    }


    const handleChangeView = (type, i) => {
        if (type == "item") {
            setViewType("item");
            setPIndex(i);
            setPackageObj(packages[i])
        }
        else {
            setViewType("package");
            setPIndex(null);
            setPackageObj(null);
        }
    }

    const getItemRow = (i) => {
        let item = packages[i];
        let base = [
            (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 1 }}>
                    {spiType == "shipmentPackageOnly" && multiSKUSelected && packages?.length > 0 &&
                        <Tooltip title="This is for removing package" placement='right'>
                            <Button
                                disabled={packages?.length == 0}
                                key="four" size='small'
                                sx={{
                                    ...buttonClasses.lynkitBlackFill, fontSize: "12px",
                                    maxHeight: "13px!important",
                                    maxWidth: "30px!important",
                                    minWidth: "30px!important"
                                }}
                                onClick={() => handleDeleteItem(i)}
                            >
                                <Remove fontSize='inherit' />
                            </Button>
                        </Tooltip>
                    }

                    <GenericSingleRangeComponent
                        startRange={packages[i]?.count || null}
                        disabled={!(spiType == "shipmentPackageOnly" && multiSKUSelected)}
                        endRange={item?.maxCount || 0}
                        getOnChange={(countValue) => handleItemCount(countValue,i,item?.maxCount || 0)}
                        endRangeInfinity={false}
                        valueType={"integer"}
                    />
                    {spiType == "shipmentPackageOnly" && multiSKUSelected && (Number(item?.count || 0) < Number(item?.maxCount || 0)) &&
                        <Tooltip title="This is for adding new package from same package for remaining count" placement='right'>
                            <Button
                                disabled={Number(item?.count || 0) >= Number(item?.maxCount || 0)}
                                key="four" size='small'
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill, fontSize: "12px",
                                    maxHeight: "13px!important",
                                    maxWidth: "30px!important",
                                    minWidth: "30px!important"
                                }}
                                onClick={() => handleAddItem(i)}
                            >
                                <Add fontSize='inherit' />
                            </Button>
                        </Tooltip>
                    }
                </Box>
            )
        ]

        const others = completeFields.filter(f => f._id != "count").map((of) => {
            let component = { ...of };
            if (of._id == "UOM") {
                component.type = "input"
                component.disabled = true;
                component.dontFetch = true
            }
            else if (of._id == "USN_Value") {
                component.disabled = item.count > 1
                // console.log("fff",item)
            }
            else if (["UOM"].indexOf(of._id) >= 0 && item[of._id] != null && item[of._id] != "") {
                component.disabled = true;
            }
            // else if (["packageName"].indexOf(of._id) >= 0 && item[of._id] != null && item[of._id] != "") {
            //     component.disabled = true;
            // }
            // else if (["packageDescription"].indexOf(of._id) >= 0 && item.masterType && item[of._id] != null && item[of._id] != "") {
            //     component.disabled = true;
            // }
            else {
                if (item.disabled?.length > 0 && item.disabled.includes(of._id)) {
                    component.disabled = true
                }
                else {
                    component.disabled = false;
                }
            }

            let isVisible = true
            if (of._id == "mappingArea") {
                if (item?.masterType && item?.restrictedMappingArea?.length > 0) {
                    //(component?.values?.find(dl=>dl.isDefault) || {}
                    // console.log(items)
                    let restrict = item?.restrictedMappingArea?.map(dl => ({ label: dl, value: dl }))
                    component.values = [...restrict]
                    component.label = component.label + " (Restricted)"
                    isVisible = true
                }
            }
            if (i == packages.length - 1) {
                isVisible = false
            }
            return (
                <>
                    {
                        getInputFieldForTableRow(component, packages, i, handleGenericChange, autoFillOnFocus, errors, false, null)
                    }
                    {
                        isVisible && of._id == "mappingArea" && spiType == "shipmentPackageOnly" &&
                        <FormControlLabel
                            control={<Checkbox
                                checked={packages && packages[i]?.sameForNext == true}
                                onClick={(e) => handleSaveForNext(i, e)} size='small' />}
                            label={<Typography sx={{ fontSize: "12px" }}>Same for next</Typography>
                            } />
                    }
                </>
            )
        })
        const disabledButton = !packages[i].packageName
        const addItemButton = spiType != "shipmentPackageOnly" ? (
            <Grid container justifyContent={"center"} alignItems={"center"}>
                <Button
                    size='small'
                    onClick={() => handleChangeView("item", i)}
                    disabled={disabledButton}
                    sx={{ ...buttonClasses.lynkitBlackFill, backgroundColor: disabledButton ? "#DDD" : "black" }}
                >
                    View & Add Items Details
                </Button>
            </Grid>
        ) : []

        return [...base, ...others, addItemButton]

    }


    const header = getTableHeader();
    const rows = packages.map((item, i) => {
        return getItemRow(i);
    })

    const getPackageRow = (count, items_s = []) => {
        let fieldsData = {}//,formulaFields=completeFields?.filter(dl=>dl?.formulaFields?.length>0)?.flatMap(dl=>dl.formulaFields) || []
        if (openType == "edit" ? true : !isInitial && packages?.length > 0) {
            let temPkg = items_s?.at(0) || {}
            fieldsData = { ...(temPkg), count: count }
            if (spiType == "SPI") {
                if (!multiSKUSelected) {
                    let tempItems = { ...(temPkg?.itemDetail?.at(0) || {}), count: count }
                    fieldsData["itemDetail"] = [tempItems]
                }
            }
            else {
                fieldsData["itemDetail"] = []
            }
            let formulaFields=completeFields?.find(dl=>dl?._id=="count")?.formulaFields || []
            if (formulaFields?.length > 0) {
                calculateFormulaHelper(fieldsData, formulaFields || [], fieldsData)
            }
            return fieldsData
        }
        else {
            let fields = completeFields.filter(f => f._id != "count")
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].type == "checkbox") {
                    fieldsData[fields[i]._id] = []
                }
                else if (fields[i].type == "file") {
                    fieldsData[fields[i]._id] = null;
                }
                else {
                    fieldsData[fields[i]._id] = ""
                }
            }
            let newRow = {
                ...fieldsData,
                mappingArea: "PUT IT ANY WHERE",
                count: count,

                // itemDetail: initialItems,
            }


            // console.log("initialItems", items_s)
            let initialItems = []
            for (let si of items_s) {
                // let initialItems = items_s.map((si) => {
                let tempCount = count
                if (multiSKUSelected) {
                    tempCount = Number(si?.count || 0)
                }
                let p = {
                    count: tempCount,
                    // count: Number(si?.count || 0),
                    mappingArea: "PUT IT ANY WHERE",
                    USN_Value: count > 1 ? "Not Applicable" : "",
                    // USN_Value: "",
                    receivingType: si?.receivingType || "availableQuantity",
                    ASN_ComponentId: si._id,
                    UOM: si?.formData?.UOM ? si.formData.UOM : "",
                    sameForNext: false,
                    disabled: [],
                    maxCount: si?.maxCount || 0,
                    product_ID:si?.product_ID || "",
                    masterDetail: (si?.itemMasterDetail || {}),
                    asnDetail: (si?.formData || {})
                }
                let insertKey_SKU_ID = spiType == "shipmentPackageOnly" ? "packageName" : "SKU_Number"
                let insertKey_SKU_Detail = spiType == "shipmentPackageOnly" ? "packageDescription" : "SKU_Name"
                p[insertKey_SKU_ID] = si?.formData?.product_ID
                p[insertKey_SKU_Detail] = (si?.formData?.SKU_Detail || si?.itemMasterDetail?.SKU_Detail) ? (si?.formData?.SKU_Detail || si?.itemMasterDetail?.SKU_Detail) : ""

                p.masterType = si.itemMasterDetail != null

                if (p[insertKey_SKU_ID]) {
                    p.disabled.push(insertKey_SKU_ID)

                }
                if (p[insertKey_SKU_Detail]) {
                    p.disabled.push(insertKey_SKU_Detail)
                }

                let templateFields=[],skipFields=[]

                if(spiType=="SPI"){
                    templateFields=[...SI_templateDetails]
                    skipFields=["SKU_Number", "SKU_Name"]
                }
                else{
                    
                    skipFields=["packageName","packageDescription"]
                    templateFields=[...completeFields]
                }

                
                if(templateFields?.length>0){
                    for (let item of templateFields) {
                        let readkey = item._id
                        if (!skipFields.includes(item._id)) {
                            if(item._id!="count"){
                                if (si?.formData && si?.formData[readkey]) {
                                    p[item._id] = si?.formData[readkey]
                                    p.disabled.push(item._id)
                                }
                                else if (si?.itemMasterDetail && si?.itemMasterDetail[readkey]) {
                                    p[item._id] = si?.itemMasterDetail[readkey]
                                    p.disabled.push(item._id)
                                }
                            }
                            if (item?.formulaFields?.length > 0) {
                                calculateFormulaHelper(p, (item?.formulaFields || [])?.filter(ell => ell.isPrimary), p, true, skipFields)
                            }
                        }
                    }
                }
                // console.log(p)

                if (si.itemMasterDetail != null && si?.itemMasterMappingArea?.length) {
                    p["restrictedMappingArea"] = si?.itemMasterMappingArea || []
                    p["mappingArea"] = si?.itemMasterMappingArea?.at(0) || ""
                }
                //     return p;
                // })
                initialItems.push(p)
            }
            if (spiType == "shipmentPackageOnly") {
                newRow = {
                    ...newRow,
                    ...(initialItems?.at(0) || {}),
                }
            }
            else {
                newRow["itemDetail"] = initialItems

            }

            newRow["product_ID"] = items_s?.at(0)?.product_ID || ""
            // console.log("New Row", newRow);
            return newRow;
        }

    }

    const addItemRow = () => {
        if (spiType == "shipmentPackageOnly") {
            let tempItems = items.map((itemInfo) => {
                return getPackageRow(Number(itemInfo?.count || 0), [itemInfo] || {})
            })
            setPackages([...tempItems]);
        }
        else {
            const newRow = getPackageRow(remainingItems, items);
            setPackages([...packages, newRow]);

        }
    }
    // console.log("Packages ---- ", packages);


    const validateFields = () => {

        return validateFieldsMultiple(completeFields || [], packages || [])
        let newErrors = [];

        for (let j = 0; j < packages.length; j++) {
            const row = packages[j];
            let rowErrors = {};
            const keys = Object.keys(row);
            for (let i = 0; i < keys.length; i++) {
                const fieldId = keys[i];
                const element = row[fieldId];
                const targetField = completeFields.filter(f => f._id == fieldId)[0];
                if (targetField) {
                    if (targetField.required && (!element || element == "")) {
                        // //// //(`${targetField.label} failing required test with value ${element}`);

                        rowErrors[fieldId] = {
                            name: targetField.label,
                            message: `${targetField.label} is required`
                        }
                    }
                    else if (targetField.pattern && !(new RegExp(targetField.pattern)).test(element)) {
                        // //// //(`${targetField.label} failing pattern test ${targetField.pattern} with value ${element}`);
                        rowErrors[fieldId] = {
                            name: targetField.label,
                            message: `Invalid value for ${targetField.label}`
                        }
                    }
                }
            }
            newErrors.push(rowErrors)
        }

        return newErrors;
    }

    const getOtherDetail = (item) => {
        let other = {
            mappingArea: "PUT IT ANY WHERE",
            masterType: item.itemMasterDetail != null
        }

        if (item.itemMasterDetail != null && item?.itemMasterMappingArea?.length) {
            other["restrictedMappingArea"] = item?.itemMasterMappingArea || []
            other["mappingArea"] = item?.itemMasterMappingArea?.at(0) || ""
        }
        return other
    }

    const handleSubmit = () => {
        let newErrors = validateFields();
        if (newErrors.filter(row => Object.keys(row).length != 0).length != 0) {
            showToast("Form Error: Please check for errors", true);
            setErrors(newErrors);
        }
        else {
            handleSubmitPackages(packages, () => {
                let tempMessage = "Added Package(s) successfully"
                if (openType == "edit") {
                    tempMessage = "Package(s) updated successfully"
                }
                showToast(tempMessage, false)
                if (onClose) {
                    onClose();
                }
            })
        }

    }

    const handleBoxChangeCount = (countValue) => {
        let totalCount = packageItemCount
        let count_temp = 0
        if (countValue<=0) {
            count_temp = 0
        }
        else{
            count_temp=Math.min(totalCount,countValue)
            count_temp = Math.ceil(count_temp)
        }
        let cpb;
        if ((count_temp - 1) * Math.ceil(totalCount / count_temp) < totalCount) {
            cpb = Math.ceil(totalCount / count_temp)
        }
        else {
            cpb = Math.floor(totalCount / count_temp)
        }

        // console.log("count_temp", {count_temp,cpb})
        setBoxCount(count_temp)
        setCountPerBox(cpb);
    }

    const countPerBoxHandler = (countValue) => {
        let totalCount = packageItemCount;
        let count_temp = totalCount
        if (countValue<=0) {
            count_temp = 0
        }
        else{
            count_temp=countValue//Math.min(totalCount,countValue)
        }

        if ((boxCount - 1) * count_temp < totalCount) {
            setCountPerBox(count_temp)
        }
        else {
            showToast(`Cannot allocate ${count_temp} item per package`, true)
        }
    }

    const handleSaveForNext = (i, e) => {
        if (packages[i].sameForNext) {
            let oldPackages = deepCopy_Object(packages)
            let object = {
                ...oldPackages[i],
                sameForNext: false
            };
            oldPackages[i] = object
            setPackages([...oldPackages]);
        }
        else {
            let oldPackages = deepCopy_Object(packages)
            let object = { ...oldPackages[i] };
            let newPackages = oldPackages.map((oi, j) => {
                let ni = { ...oi };
                if (j == i) {
                    ni.sameForNext = e.target.checked
                }
                if (j > i) {
                    let change = true
                    ni.sameForNext = false
                    if (!object.mappingArea) {
                        ni.mappingArea = ""
                    }
                    else if (ni?.restrictedMappingArea?.length > 0 && !ni?.restrictedMappingArea?.includes(object.mappingArea)) {
                        change = false
                    }
                    else {
                        change = true
                    }
                    if (change) {
                        ni.mappingArea = object.mappingArea;
                    }
                }
                return ni;
            })
            setPackages([...newPackages]);
        }
    }

    // useEffect(() => {
    //     if (!multiSKUSelected && !isInitial) {
    //         if (open != "" && boxCount >= 1 && countPerBox >= 1) {
    //             let totalCount = packageItemCount;
    //             let newItems;
    //             let packageToDivide=(items || [])?.slice(0, 1)
    //             if(packages?.length>0){
    //                 packageToDivide=packages?.slice(0, 1)
    //             }
               
    //             // console.log(boxCount,"Box Count", countPerBox);
    //             if (countPerBox * boxCount == totalCount) {
    //                 // In case of equal division
    //                 newItems = Array(boxCount).fill(1).map((it, i) => {
    //                     return getPackageRow(Number(countPerBox), packageToDivide?.slice(0, 1) || []);
    //                     // return getPackageRow(Number(countPerBox), items?.slice(0, 1) || []);
    //                 });
    //                 // });
    //             }
    //             else {
    //                 newItems = Array(boxCount - 1).fill(1).map((it, i) => {
    //                     return getPackageRow(Number(countPerBox), packageToDivide?.slice(0, 1) || []);
    //                     // return getPackageRow(Number(countPerBox), items?.slice(0, 1) || []);
    //                 });
    //                 newItems.push({
    //                     ...getPackageRow(Number(totalCount - (countPerBox * (boxCount - 1))), packageToDivide?.slice(0, 1) || [])
    //                     // ...getPackageRow(Number(totalCount - (countPerBox * (boxCount - 1))), items?.slice(0, 1) || [])
    //                 })
    //             }
    //             setPackages(newItems);
    //         }
    //         else if (boxCount == "") {
    //             setPackages([])
    //         }
    //         setErrors({});
    //     }
    // }, [boxCount, countPerBox])

    useDebounceWithUseEffectDetection(() => {
        if (!multiSKUSelected && !isInitial) {
            if (open !== "" && boxCount >= 1 && countPerBox >= 1) {
                const totalCount = packageItemCount;
                const CHUNK_SIZE = 500; // Processed rows per chunk
                let processedBoxes = 0;
                let newPackages = [];
                let packageToDivide = (packages?.length > 0 ? packages : items || []).slice(0, 1);
    
                const processBoxes = () => {
                    const remainingBoxes = boxCount - processedBoxes;
                    const boxesToProcess = Math.min(CHUNK_SIZE, remainingBoxes);
    
                    for (let i = 0; i < boxesToProcess; i++) {
                        const isLastBox = processedBoxes + i === boxCount - 1;
                        let packageCount = isLastBox && countPerBox * boxCount !== totalCount
                            ? totalCount - (countPerBox * (boxCount - 1))
                            : countPerBox;
                            packageCount = truncateNumber(packageCount); // Round to 3 decimals
                            // console.log("packageCount",packageCount,remainingBoxes)
                        newPackages.push(getPackageRow(Number(packageCount), packageToDivide));
                    }
    
                    processedBoxes += boxesToProcess;
    
                    if (processedBoxes < boxCount) {
                        setTimeout(processBoxes, 0); // Schedule next chunk
                    } else {
                        setPackages(newPackages); // Finalize when done
                    }
                };
    
                processBoxes();
            } else if (boxCount === "") {
                setPackages([]);
            }
            setErrors({});
        }
    }, [boxCount, countPerBox],300);

    
    useEffect(() => {
        if(isInitial){
            setViewType("package")
            setPackageObj(null);
            setPIndex(null);
            if (open != "") {
                addItemRow();
            }
            else {
                setPackages([]);
            }
            setIsInitial(false)
        }
    }, [open])

    return (
        <Dialog
            open={open != ""}
            // onClose={()=>onClose()}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"xl"}
        >
            <DialogContent dividers>
                {
                    viewType == "package"
                        ?

                        <Grid>
                            <Typography sx={{ ...textClasses.cardTitle }}>{openType == "edit" ? "Update" : "Create"} {getCamelCaseText(open)}(s)</Typography>
                            {spiType !== "shipmentPackageOnly" && <Typography sx={{ ...textClasses.boldText }}>
                                *{openType == "edit" ? "Update" : "Create"}  {open == "pallet" ? "Pallet(s)" : "Package(s)"} & {openType == "edit" ? "update" : "add"} items details with in {open == "pallet" ? "Pallet(s)" : "Package(s)"} in a single view
                            </Typography>}
                            {!multiSKUSelected &&
                                <Grid sx={{ p: 2, border: "1px solid #D9D9D9", borderRadius: "8px", mt: 2 }}>

                                    <Grid container spacing={1}>
                                        <Grid container item sm={3} md={3} xs={6} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "35px" }}>
                                            {
                                                items && items.length >= 1 &&
                                                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                    {(items?.at(0)?.product_ID?.length > 15) ? items?.at(0)?.product_ID?.substring(0, 15) + "..." : items?.at(0)?.product_ID}
                                                    {items?.at(0)?.UOM ? `(${items?.at(0).count} ${items?.at(0)?.UOM})` : `(${items?.at(0).count})`}
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid container item sm={3} md={3} xs={6} justifyContent={"center"} alignItems={"center"}>
                                            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 }}>Selected QTY: </Typography>
                                            <GenericSingleRangeComponent
                                                startRange={packageItemCount || null}
                                                disabled={true}
                                                endRange={0}
                                                getOnChange={() => { }}
                                                endRangeInfinity={false}
                                                valueType={"decimal"}
                                            />
                                        </Grid>
                                        <Grid container item sm={3} md={3} xs={6} justifyContent={"center"} alignItems={"center"}>
                                            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 }}>No of packages: </Typography>
                                            
                                            <GenericSingleRangeComponent
                                                startRange={boxCount || null}
                                                // disabled={true}
                                                endRange={packageItemCount}
                                                getOnChange={(countValue) => handleBoxChangeCount(countValue)}
                                                endRangeInfinity={false}
                                                valueType={"integer"}
                                            />
                                        </Grid>
                                        <Grid container item sm={3} md={3} xs={6} justifyContent={"center"} alignItems={"center"}>
                                            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, mr: 2 }}>Per package QTY: </Typography>
                                            <GenericSingleRangeComponent
                                                startRange={countPerBox || null}
                                                // disabled={true}
                                                endRange={packageItemCount}
                                                getOnChange={(countValue) => countPerBoxHandler(countValue)}
                                                endRangeInfinity={false}
                                                valueType={"decimal"}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                            }
                            <Grid container sx={{ width: "fit-to-content", margin: "0 auto", overflow: "auto", mt: 2, maxHeight: "50vh" }}>
                                <Table header={header} rows={rows} countTillNow={countTillNow} packageItemCount={packageItemCount} remainingItems={remainingItems} />
                            </Grid>
                            <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Button variant='contained' size='small' sx={{ ...buttonClasses.lynkitBlackFill, minHeight: "40px", minWidth: "150px" }} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px", ml: 2 }} onClick={handleSubmit}>
                                    {openType == "edit" ? "Update" : "Submit"} {open == "pallet" ? "Pallet(s)" : "Package(s)"}
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <AsnPackageItemTableSection
                            open={open}
                            openType={openType}
                            openFrom="pkgTable"
                            multiSKUSelected={multiSKUSelected}
                            template={template} packages={packages} setPackages={setPackages} pIndex={pIndex} setPIndex={setPIndex}
                            packageObj={packageObj} setPackageObj={setPackageObj} onClose={onClose}
                            selectedAsnItems={
                                // openType == "edit"
                                //     ? packageObj?.itemDetail || []
                                    // : items
                                    packageObj?.itemDetail || []
                            }
                            onBack={() => setViewType("package")}
                            totalItemCount={packageObj?.count || 0}
                            itemDetail={packageObj?.itemDetail || []}
                            saveItemHandler={(newItem = [], indexInsert = 0) => {
                                let oldPackages = deepCopy_Object(packages) || [];
                                if(oldPackages[indexInsert]){
                                    oldPackages[indexInsert].itemDetail = [...newItem];
                                    oldPackages[indexInsert].count = newItem.reduce((a, b) => Number(a || 0) + Number(b.count || 0), 0) || 0;
                                }
                                setPackages(oldPackages);
                            }}
                            isRejectReasonRequired={isRejectReasonRequired}
                        />
                }
            </DialogContent>
        </Dialog>
    )
}

const Table = ({ header, rows }) => {

    return (
        <table
            style={{
                width: "inherit",
                // display: "block",
                // maxWidth: "-moz-fit-content",
                // maxWidth: "fit-content",
                // width:"90vw",
                // margin: "0 auto",
                // overflow: "auto",
                whiteSpace: "nowrap",
                borderRadius: "8px",
                border: `1px solid ${theme.themeGray}`
            }}
        >
            <thead style={{ backgroundColor: theme.themeGray }}>
                <tr className='roundedRow'>

                    {
                        header.slice(0, 1).map((head, i) => {
                            return (
                                <td style={{ padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center" }} key={i}>{head}</td>
                            )
                        })
                    }
                    {
                        header.slice(1, header.length).map((head, i) => {
                            return (
                                <td style={{ padding: "13px 10px 13px 10px", minWidth: "200px", ...textClasses.boldText, textAlign: "center" }} key={i}>{head}</td>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0
                    &&
                    <>
                        {
                            rows.map((row, i) => (
                                <tr
                                    key={i}
                                    style={{ borderBottom: "1px solid #0000002B" }}
                                >
                                    {
                                        row.map((col, j) => {
                                            return (
                                                <td style={{ padding: "10px 5px 10px 10px", ...textClasses.normalText }}>
                                                    {col}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            ))
                        }

                    </>
                }
            </tbody>
        </table>
    )
}

export default AsnPackageTableDialog;