import React, {useState,useEffect} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import penicon from "../../../../../static/images/Icon/createAutomation/pen.svg";
import { Grid, Input, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allWorkflows } from "../constant";
import { textClasses, inputClasses } from "../../../../../static/styles/theme";
import CheckIcon from '@mui/icons-material/Check';
import { getWorkflowList } from "../../../../../redux/actions";

const AutomationHeader = ({
  setWorkflowFilter,
  workflowFilter,
  editHandler,
  automationHeader,
  editHeader,
  setEditHeader,
  step
}) => {
  const dispatch = useDispatch();
  const [workflowList,setWorkflowList] = useState([]);
  useEffect(() => {
    dispatch(getWorkflowList({ projections: { workflowId: 1, workflowName: 1, _id: 1 }, approved:true }, (data) => {
      setWorkflowList(data?.data)
    }))
  }, [])

  return (
    <Grid
      sx={{
        backgroundColor: "#FF7200",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
        width: "100%",
      }}
      container
      data-testid="create-automation"
    >
      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Grid item xs={8}>
          {/* <Grid
            sx={{
              ...textClasses.t18n,
              fontFamily: "Nunito",
              color: "white",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                borderBottom: "1px solid",
                padding: "5px",
                width: "65%",
                justifyContent: "space-between",
              }}
            >
              {editHeader ? (
                <>
                  <input style={{background:'transparent', border:'none', outline:'none', color:'#fff'}} value={automationHeader} onChange={editHandler}/>
                  <CheckIcon  onClick={() => {
                      setEditHeader((prev) => !prev);
                    }} />
                </>
              ) : (
                <>
                  <Typography
                    sx={{ color: "#fff" }}
                    type="text"
                    id="message"
                    name=""
                  >
                    {automationHeader}
                  </Typography>
                  <img
                    src={penicon}
                    onClick={() => {
                      setEditHeader((prev) => !prev);
                    }}
                  />
                </>
              )}
            </Stack>
          </Grid> */}
        </Grid>
        {step == "JOB_CREATION_STEP" &&
        <Grid item xs={8}>
          <Grid container item justifyContent="end">
            <Autocomplete
              value={workflowFilter}
              data-testid="select-workflow"
              onChange={(e, option) => {
                setWorkflowFilter(option);
              }}
              getOptionLabel={(option) => option?.workflowName}
              size="small"
              options={workflowList || []}
              sx={{
                ...inputClasses.filterField,
                width: "380px",
                margin: "10px 0 0 35px",
                background: "white",
             
              }}
              renderInput={(params) => (
                <TextField
                sx={{
                  ...inputClasses.filterField,
                  minWidth: "250px",
                  mr:1,
                  ".MuiInputBase-input": {
                      fontSize: "15px",
                      color: "#455A64",
                  },
                  "& label.Mui-focused": {
                    color: "#000" ,
                  }
              }}
                  {...params}
                  label="Select Workflow"
                />
              )}
            />
          </Grid>
        </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default AutomationHeader;
