import React from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { selectStylesOverride } from "../../../utils/util";
import "../../../static/css/date-picker-css.css"; // Import custom CSS
import {TextField,IconButton } from "@mui/material"; 
import { Clear as ClearIcon, Event as EventIcon} from "@mui/icons-material"; // MUI Clear icon
import "../../../static/css/multirangedatepicker.css"

export function multiDateToISOConvertor(date = []) {
    if (Array.isArray(date)) {
        if (date?.length) {
            let tempDate = [];

            // Adjust the from date (date[0]) only if it hasn't been adjusted yet
            if (date?.at(0)) {
                const startDate = new Date(date[0].toDate());

                // Only adjust the from date (previous day) once
                startDate.setDate(startDate.getDate() - 1); // Decrease the date by 1 to get the previous day
                startDate.setUTCHours(18, 30, 0, 0); // Set time to 18:30 UTC for the from date
                tempDate[0] = startDate;
                // console.log("From Date (adjusted):", startDate); // Log adjusted from date
            }

            // Set the to date (date[1]) without modification
            if (date?.at(1)) {
                const endDate = new Date(date[1].toDate());
                endDate.setUTCHours(18, 29, 0, 0); // Set time to 18:30 UTC for the to date
                tempDate[1] = endDate;
                // console.log("To Date:", endDate); // Log to date (no adjustment)
            }
            return tempDate?.map(d => d.toISOString())
        }
        return []
    }
    else {
        if (typeof date == "string" && date?.length) {
            return date.toISOString();
        }
        return ""
    }
}

export default function GenericMultiDateRange({ label = "Date", getOnChange, value = [],inputStyle={},portal=true }) {
    // console.log(multiDateToISOConvertor(value));//
    return <>

        <DatePicker
            className="custom-range-picker"
            value={Array.isArray(value) && value || []}
            format="YYYY-MM-DD"
            onChange={(date, { input, isTyping }) => {
                if (!isTyping) return getOnChange(date);

                const strings = input.value.split("/");
                const numbers = strings.map(Number);
                const [year, month, day] = numbers;

                if (input.value && numbers.some((number) => isNaN(number))) {
                    return false; //in case user enter something other than digits
                }

                if (month > 12 || month < 0) return false; //month < 0 in case user want to type 01
                if (day < 0 || (date && day > date.day)) return false;
                if (strings.some((val) => val.startsWith("00"))) return false;

                getOnChange(date);
            }}
            range
            rangeHover
            dateSeparator=" - "
            {...true ? {plugins:[<DatePanel />]} : {}}
            zIndex={1305}
            // style={{ zIndex:1305 }} // Increase zIndex directly
            portal={true} // Ensure the calendar is rendered in a portal
            // className="red"
            render={(value, openCalendar) => (
                <>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={value}
                        onClick={openCalendar}
                        label={label || "Date"}
                        size="small"
                        sx={{
                            fontSize: "12px",
                            ...selectStylesOverride,
                            ...inputStyle,
                            "& input::placeholder": {
                                fontSize: "12px",
                            },
                            width: "100%", // Make it take full width
                            fontSize: "16px", // Set font size
                        }}
                    />
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: '0px', // Positioning the calender icon
                            top: '50%',
                            transform: 'translateY(-50%)', // Vertically center the icon
                        }}
                        size="small"
                        onClick={(e) => openCalendar(e)}
                    >
                        <EventIcon />
                    </IconButton>
                </>
            )}
        />
        {/* Clear Icon */}
        {value?.length > 0 && <IconButton
            onClick={() => getOnChange([])}
            sx={{
                position: 'absolute',
                right: '22px', // Positioning the clear button
                top: '50%',
                transform: 'translateY(-50%)', // Vertically center the icon
            }}
            size="small"
        >
            <ClearIcon />
        </IconButton>
        }

    </>
}