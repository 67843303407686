import React, { useEffect, useState } from "react"
import AlertDialog from "../DialogBox/dialogbox";
import { Grid, Box, Typography, Button,OutlinedInput, FormControl,IconButton, InputAdornment} from "@mui/material";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import SelectImage from "../../../static/images/salesOrder/slot-booking-confirmation.svg";

const DeliverySlotConfirmationModal = ({ viewModal, setViewModal, sharedObj }) => {

  return (
    <>
      <AlertDialog
        viewDialog={viewModal === "slotBookingSuccessfull"}
        handleClose={() => { setViewModal("") }}
        viewSearchInput={true}
        showDivider={true}
        width="60%"
        actionsCSS={{ justifyContent: 'flex-start' }}
    
        body={
          <>
                 <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={SelectImage} width="450" height="300" />
            </Grid>
            <Grid container sx={{ display: 'flex', alignItems: 'center',flexDirection:"column" }}>
              <Grid item >
                <Typography sx={{ ...textClasses.cardTitle }}>
                  Your delivery slot is confirmed!
                </Typography>
              </Grid>
              <Grid item >
                <Typography>
                  Please complete the remaining steps to finalize your order and ensure a smooth delivery.
                </Typography>
              </Grid>
            </Grid>
          </>
        }

      >
        <Button
          variant="outlined"
          data-testid="cancel-btn"
          onClick={() => { setViewModal("") }}
          sx={{ ...buttonClasses.lynkitBlackEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
        >Cancel
        </Button>
        <Button
          variant="outlined"
          data-testid="cancel-btn"
          onClick={() => {  window.close();}}
          sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
        >Done
        </Button>
      </AlertDialog>
    </>
  )
}
export default DeliverySlotConfirmationModal;