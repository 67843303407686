import React, { useCallback,useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { showToast } from "../../../services/functions";
import ClearIcon from "@mui/icons-material/Clear";
import {
  dashBoardData,
  warehouseOperations,
  WarehouseInventory,
} from "../../../redux/actions/MainDashboard";
import { ReactComponent as OperationIcon } from "../../../static/images/Icon/OperationIcon.svg";
import { ReactComponent as WarehouseIcon } from "../../../static/images/Icon/WarehouseIcon.svg";
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import { selectStylesOverride } from "../../../utils/util";
import "react-datepicker/dist/react-datepicker.css";
import TypeAndSearch from "../../Components/TypeAndSearch";
import { getAllJobs } from "../../../redux/actions/Job";
import { red, blue, green, grey, yellow } from "@mui/material/colors";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import $, { data } from "jquery";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import {
  Button,
  Divider,
} from "@mui/material";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import {
  getAllUsers,
  getCompany,
} from "../../../redux/actions";
import MultiSelectAll from "./MultiSelect";
import { makeStyles } from "@mui/styles";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import GenericLoader from "../../Components/Generic/GenericLoader";
import dayjs from "dayjs";
import GenericTable from "../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../utils/hooks";
import { component } from "./utils";
import GenericDashboardCard from "../../Components/GenericDashboardCard";
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles({
  datePickerWrapper: {
    "& .react-datepicker-wrapper": {
      width: "100%",
      paddingTop: "10px",
      "& .react-datepicker__close-icon::after": {
        backgroundColor: "#FF7200",
        right: "30px",
        position: "absolute",
        top: "9px",
      },
      "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
        right: "0px",
        "& path": {
          fill: "grey !important",
        },
      },
    },
  },
  datePickerContainer: {
    border: "1px solid #adb5bd",
    minHeight: "35px",
    minWidth: "100%",
    padding: "0 0 0 10px !important",
    ...inputClasses.filterField,
    boxShadow: "none",
    "&::placeholder": {
      color: "#455A64",
      fontWeight: "300",
    },
    "&:hover": {
      border: "1px solid #FF7200",
    },
  },
});


export default function MainDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { AllUserData } = useSelector((state) => state.users);
  const { userPermissions } = useSelector((state) => state.userPermission);

  const warehouseDetail = useSelector(
    (state) => state.superadmin.warehouseDetail
  );
  const jobs = useSelector((state) => state.job.jobs.data);
  const jobcount = useSelector((state) => state.job.jobs.totalCount);

  let getToDate = () => {
    let current1 = dayjs().hour(0).minute(0).second(0).millisecond(0);
    let fromDate = current1.subtract(6, "day");
    let toDate = current1.hour(23).minute(59).second(59).millisecond(999);

    let date = [fromDate, toDate];
    return date;
  };
  const [dateRange, setDateRange] = useState(getToDate);
  const [condition, setCondition] = useState(true);
  const [analyticsLoader, setAnalyticsLoader] = useState(true);
  const [barChartLoader, setBarChartLoader] = useState(true);
  const [lineChartLoader, setLineChartLoader] = useState(true);
  const [dashboardDataLoader, setDashboardDataLoader] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [nameFilter, setNameFilter] = useState({});
  const [allChartData, setAllChartData] = useState(null);
  const [warehouseData, setWarehouseData] = useState(null);
  const [warehouseInventoryData, setWarehouseInventoryData] = useState(null);
  const [mappingAreaFilter, setMappingAreaFilter] = useState("")
  const [skuFilter, setSKUFilter] = useState("");
  const [isInitial, setInitial] = useState(true);


  var chart;

  const getUserData = (userId) => {
    const user = AllUserData?.data?.find((user) => user._id === userId);
    if (user) {
      return user?.name + "," + user?.designation;
    } else {
      return "-";
    }
    // user?user?.name + "," + user?.designation:"-"
  };
  const handleJobs = () => {
    history.push("/dashboard/jobs");
  };
  const statusColor = {
    ongoing: "#FF7200",
    pending: "#FCCB3E",
    failed: "#E9001A",
    completed: "green",
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (condition) {
      dispatch(
        getAllJobs({ page: 1, limit: 10 }, () => {
          dispatch(getAllUsers({ isAdminIncluded: true }));
          setAnalyticsLoader(false);
        })
      );
    }
    const payload = { type: "warehouse", status: "active" };
    dispatch(getCompany(payload));
    if (condition) {
      setCondition(false);
    }
  }, []);

  useEffect(() => {
    barChart();
    instanceChart();
    instanceChart2();
    reportXChart();
    // heatMap();
  }, [warehouseData]);

  useEffect(() => {
    lineChart();
  }, [warehouseInventoryData]);

  const getTextBoxInputValue = useCallback((input) =>
    input.map((itm) => itm._id)
  );

  const [currentSelection, setCurrentSelection] = useState(
    getTextBoxInputValue([])
  );

  const handleSelectionChange = (result) => {
    // const valueToSave = result.map((itm) => itm._id);
    setCurrentSelection(result);
    fetch_data(result, dateRange);
  };

  const handleDateChange = useCallback(
    (date, type) => {
      let tem_data = [];
      if (type === "from") {
        if (!date) {
          tem_data = [null, dateRange[1] || null];
        } else if (date?.isAfter(dateRange[1])) {
          showToast("Start Date must be smaller than End Date", true);
          tem_data = [null, dateRange[1] || null];
        } else {
          tem_data = [date, dateRange[1] || null];
        }
      } else {
        //if (type === "to")
        if (!date) {
          tem_data = [dateRange[0] || null, null];
        } else if (date?.isBefore(dateRange[0])) {
          showToast("End Date must be Greater than Start Date", true);
          tem_data = [dateRange[0] || null, null];
        } else {
          let tempEndDate = date;
          tempEndDate = dayjs(tempEndDate)
            .hour(23)
            .minute(59)
            .second(59)
            .millisecond(999);
          tem_data = [dateRange[0] || null, tempEndDate];
        }
      }
      setDateRange(tem_data);
      fetch_data(currentSelection, tem_data);
    },
    [dateRange]
  );

  let fetch_data = (warehouse = [], date_range) => {
    setDashboardDataLoader(true)
    setBarChartLoader(true)
    setLineChartLoader(true)
    let payload = { wareHouse: warehouse?.map(dl=>dl?._id) };
    if (date_range[0] && date_range[1]) {
      payload.dateFrom = date_range[0];
      payload.dateTo = date_range[1];
    }

    dispatch(warehouseOperations(payload,(data)=>{
      setBarChartLoader(false)
      setWarehouseData(data)
    }));
    dispatch(dashBoardData(payload,(data)=>{
      setDashboardDataLoader(false);
      setAllChartData(data)
    }));
    dispatch(WarehouseInventory(payload,(data)=>{
      setLineChartLoader(false)
      setWarehouseInventoryData(data)
    }));
 
    setInitial(false)
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getAllJobs({ page: newPage + 1, limit: rowsPerPage }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getAllJobs({ page: 1, limit: +event.target.value }));
  };

  const tableHeader = [
    "Job Name",
    "Status",
    "Warehouse",
    "Created On",
    "Assigned To",
  ];

  const tableRowData = jobs?.map((job, index) => {
    const item = [];
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {capitalize(
          job.commonDetails?.jobName == "Send To User"
            ? "Gate Out"
            : job.commonDetails?.jobName || "-"
        )}
      </Typography>
    );
    item.push(
      <Typography
        variant="caption"
        gutterBottom
        sx={{
          pr: 1,
          pl: 1,
          color: statusColor[job.commonDetails?.status],
          fontWeight: "500",
          border: `1px solid ${statusColor[job.commonDetails?.status]}`,
          borderRadius: "12px",
          fontWeight: "500",
        }}
      >
        {capitalize(job.commonDetails?.status || "-")}
      </Typography>
    );
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {
          job.commonDetails?.shipmentDetail
            .map(info => info?.warehouseName)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ")
        }
        {/* {job.commonDetails?.shipmentDetail.map((item) => (
          <div>{item?.warehouseName}</div>
        ))} */}
      </Typography>
    );
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {job.commonDetails?.jobAllocationDate == "Send To User"
          ? "Gate Out"
          : moment(job.commonDetails?.jobAllocationDate).format(
            "DD MMM YYYY hh:mm A"
          ) || "-"}
      </Typography>
    );
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {getUserData(job?.commonDetails?.assignedTo?._id)}
      </Typography>
    );
    return item;
  });

  const nameDebounceFilter = useDebounce(nameFilter, 500)
  const areaMappingDebounceFilter = useDebounce(mappingAreaFilter, 500)
  const skuDebounceFilter = useDebounce(skuFilter, 500)

  useEffect(() => {
    if (!isInitial) {
      var payload = { wareHouse: currentSelection?.map(dl=>dl?._id), jobTypeId: nameFilter?._id };
      // var payload = { wareHouse: currentSelection, jobName: nameFilter };
      if (dateRange[0] && dateRange[1]) {
        payload.dateFrom = dateRange[0];
        payload.dateTo = dateRange[1];
      }
      setBarChartLoader(true)
      dispatch(warehouseOperations(payload,(data)=>{
        setBarChartLoader(false)
        setWarehouseData(data)
      }));
     
    }

  }, [nameDebounceFilter])

  useEffect(() => {

    if (!isInitial) {
      var payload = { wareHouse: currentSelection?.map(dl=>dl?._id), mappingArea: mappingAreaFilter, SKU_ID: skuFilter };

      if (dateRange[0] && dateRange[1]) {
        payload.dateFrom = dateRange[0];
        payload.dateTo = dateRange[1];
      }
      setLineChartLoader(true)
      dispatch(WarehouseInventory(payload,(data)=>{
        setLineChartLoader(false)
        setWarehouseInventoryData(data)
      }));
    }
  }, [areaMappingDebounceFilter, skuDebounceFilter])

  const handleNameChange = (e, option) => {
    // console.log("option",option)
    setNameFilter(option || {});
    // setNameFilter(e.target.value);
  };

  const handleMappingAreaChange = (e, option) => {
    setMappingAreaFilter(option?.value || "")
    // setMappingAreaFilter(e.target.value)
  }

  const handleSKUChange = (e, option) => {
    setSKUFilter(option?.value || "")
    // setSKUFilter(e.target.value)
  }

  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            ...textClasses.cardTitle,
            color: "#000000",
            fontWeight: "700",
          }}
        >
          Overview
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Grid
            item
            xs={10}
            sm={6}
            md={4}
            sx={{
              margin: "0 0 0 0 ",
            }}
          >
            <MultiSelectAll
              sx={{ maxheight: "700px" }}
              items={warehouseDetail?.data || []}
              selectAllLabel="Select All"
              value={currentSelection}
              onChange={handleSelectionChange}
              label="Select Warehouse"
            />
          </Grid>
          <Grid
          container
            item
            xs={12}
            sm={6}
            md={4.5}
            sx={{
              margin: "-8px 0 0 0 ",
            }}
            className={classes.datePickerWrapper}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid item xs={12}
                md={6} sx={{ position: "relative" }}>
                <DateTimePicker
                  sx={{
                    marginTop: "10px",
                    ...selectStylesOverride,
                    '& .MuiOutlinedInput-root': {
                      height: 46, 
                    },
                    '& .MuiInputLabel-root': {
                      marginTop: '5px'
                    },
                  }}
                  label="From Date"
                  value={dateRange[0] || null}
                  slotProps={{ textField: { size: "small",textAlign:'center' } }}
                  onChange={(d) => handleDateChange(d, "from")}
                  format="DD/MM/YYYY HH:mm" 
                  />
                {dateRange[0] && (
                  <ClearIcon
                    sx={{
                      color: "grey",
                      position: "absolute",
                      top: '19px',
                      right: '36px',
                    }}
                    onClick={() => handleDateChange("", "from")}
                  />
                )}
              </Grid>
              <Grid item xs={12}
                md={6} sx={{ position: "relative" }}>
                <DateTimePicker
                  sx={{
                    marginTop: "10px",
                    ...selectStylesOverride,
                    '& .MuiOutlinedInput-root': {
                      height: 46,
                    },
                    marginLeft: "15px",
                    '& .MuiInputLabel-root': {
                      marginTop: '5px'
                    },
                  }}
                  label="To Date"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(d) => handleDateChange(d, "to")}
                  value={dateRange[1] || null}
                  format="DD/MM/YYYY HH:mm" 
                />
                {dateRange[1] && (
                  <ClearIcon
                    sx={{
                      color: "grey",
                      position: "absolute",
                      top: '20px',
                      right: '30px',
                    }}
                    onClick={() => handleDateChange("", "to")}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          margin: "10px 0 15px 0",
        }}
      />
      <Grid container spacing={3}>
        {
          dashboardDataLoader
            ?
            <Grid container sx={{ p: 3, display: 'flex', justifyContent: 'center', }}>
              {Array.from(
                { length: 4 },
                (_, rowIndex) => {
                  return <Grid item xs={12} md={3} key={rowIndex} >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, }}>
                      <Skeleton variant="round" animation="wave" width={40} height={40}>
                        <Avatar />
                      </Skeleton>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width="70%"
                        height={20}
                        sx={{ ml: 2 }}
                      />
                    </Box>
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width="90%"
                      height={20}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width="90%"
                      height={20}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width="90%"
                      height={20}
                    />
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width="90%"
                      height={20}
                    />
                  </Grid>;
                }
              )}
            </Grid>
            :
            <>
              <GenericDashboardCard
                title="My Warehouse"
                bgColor={green[900]}
                icon={<WarehouseIcon />}
                route="/dashboard/area/mapping"
                data={[
                  { label: "All Active", value: allChartData?.data?.myWarehouse?.activeWareHouse || 0 },
                  { label: "All Blocked", value: allChartData?.data?.myWarehouse?.InActiveWareHouse || 0 }
                ]}
              />
              <GenericDashboardCard
                title="Operations"
                bgColor={blue[900]}
                icon={<OperationIcon />}
                route="/dashboard/jobs"
                data={[
                  { label: "Total Inward", value: allChartData?.data?.operationsCount?.putAwayCompletedJob || 0 },
                  { label: "Total Outward", value: allChartData?.data?.operationsCount?.dispatchCompletedJob || 0 }
                ]}
              />
              <GenericDashboardCard
                title="Inventory Count"
                bgColor={red[900]}
                icon={<Inventory2OutlinedIcon />}
                route="/dashboard/inventory/all"
                data={[
                  { label: "In Stock Units", value: allChartData?.data?.inventory?.inStock || 0 },
                  { label: "To Be Received", value: allChartData?.data?.inventory?.toBeReceived || 0 },
                  { label: "Gate In Count", value: allChartData?.data?.inventory?.gateInCount || 0 },
                  { label: "Gate Out Count", value: allChartData?.data?.inventory?.gateOutCount || 0 }
                ]}
              />
              <GenericDashboardCard
                title="Inventory Weight"
                bgColor={yellow[900]}
                icon={<MonitorWeightIcon />}
                route="/dashboard/inventory/all"
                data={[
                  { label: "In Stock Weight", value: allChartData?.data?.weight?.inStockWeight || 0 },
                  { label: "To Be Received Weight", value: allChartData?.data?.weight?.toBeReceivedWeight || 0 },
                  { label: "Gate Out Weight", value: allChartData?.data?.weight?.gateOutWeight || 0 },
                  { label: "Gate In Weight", value: allChartData?.data?.weight?.gateInWeight || 0 },
                ]}
              />
            </>
        }
        {barChartLoader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} className="cp">
            <Paper elevation={3}>
              <Item>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid container spacing={2} alignItems="start">
                      {/* Left Column - Title Section */}
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            ...textClasses.t20n,
                            fontWeight: "700",
                            textAlign: { xs: "center", md: "start" },
                            color: "#000000",
                            padding: { xs: "16px 0", md: "16px 0 16px 20px" },
                          }}
                        >
                          Daily Warehouse Operations Counts
                        </Typography>
                      </Grid>

                      {/* Right Column - Status and Search */}
                      <Grid item xs={12} md={8}>
                        <Grid container direction="column" spacing={2}>
                          {/* Top Row - Status Indicators */}
                          <Grid
                            item
                            container
                            justifyContent={{ xs: "center", md: "flex-end" }}
                            alignItems="center"
                            gap={1}
                          >
                            <Item
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#E39323",
                                margin: "0 6px 0 0",
                              }}
                            />
                            <Typography variant="contained" sx={{ ...textClasses.t14n, color: "#000000" }}>
                              Created Job
                            </Typography>
                            <Item
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#3E621E",
                                margin: "0 6px 0 0",
                              }}
                            />
                            <Typography variant="contained" sx={{ ...textClasses.t14n, color: "#000000" }}>
                              Completed Job
                            </Typography>
                          </Grid>

                          {/* Bottom Row - Search Filter */}
                          <Grid container spacing={2} alignItems="center" justifyContent="flex-end" sx={{ mt: 0.5, mb: 0.5 }}>
                            <Grid item xs={12} md={4}>
                              <TypeAndSearch
                                data-testid="jobNameFilter"
                                component={component({ type: "jobName" })}
                                handleChange={(e, option) => {
                                  handleNameChange(e, option);
                                }}
                                label={nameFilter?.label || ""}
                                value={nameFilter?.value || ""}
                                sx={{ width: "100%" }}
                              />
                               {/* <TypeAndSearch
                                data-testid="mappingFilter"
                                component={component({ type: "mappingArea" })}
                                handleChange={(e, option) => {
                                  handleMappingAreaChange(e, option);
                                }}
                                label={mappingAreaFilter || ""}
                                value={mappingAreaFilter || ""}
                                sx={{
                                  width: "100%", // Full width for responsiveness
                                }}
                              /> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                <Divider />
                <div style={{ width: "100%", height: "507px" }} id="bar"></div>
              </Item>
            </Paper>
          </Grid>
        )}
        {lineChartLoader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} className="cp">
            <Paper elevation={3}>
              <Item>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid container spacing={2} alignItems="start">
                      {/* Left Column - Title Section */}
                      <Grid item xs={12} md={4}>
                        <Typography
                          sx={{
                            ...textClasses.t20n,
                            fontWeight: "700",
                            textAlign: { xs: "center", md: "start" },
                            color: "#000000",
                            padding: { xs: "16px 0", md: "16px 0 16px 20px" },
                          }}
                        >
                          Warehouse Inventory
                        </Typography>
                      </Grid>

                      {/* Right Column - Status and Search */}
                      <Grid item xs={12} md={8}>
                        <Grid container direction="column" spacing={2}>
                          {/* Top Row - Status Indicators */}
                          <Grid
                            item
                            container
                            justifyContent={{ xs: "center", md: "flex-end" }}
                            alignItems="center"
                            gap={1}
                          >
                            <Item
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#E9001A",
                                margin: "0 6px 0 0",
                              }}
                            />
                            <Typography variant="contained" sx={{ ...textClasses.t14n, color: "#000000" }}>
                              Total Inward
                            </Typography>
                            <Item
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#3E621E",
                                margin: "0 6px 0 0",
                              }}
                            />
                            <Typography variant="contained" sx={{ ...textClasses.t14n, color: "#000000" }}>
                              In Stock
                            </Typography>
                            <Item
                              sx={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#E39323",
                                margin: "0 6px 0 0",
                              }}
                            />
                            <Typography variant="contained" sx={{ ...textClasses.t14n, color: "#000000" }}>
                              Total Outward
                            </Typography>
                          </Grid>

                          {/* Bottom Row - Search Filters */}
                          <Grid container spacing={2} alignItems="center" justifyContent={"flex-end"} sx={{mt:.5,mb:.5}}>
                          {/* xl={3} */}
                            <Grid item xs={12} md={4} >
                              <TypeAndSearch
                                data-testid="mappingFilter"
                                component={component({ type: "mappingArea" })}
                                handleChange={(e, option) => {
                                  handleMappingAreaChange(e, option);
                                }}
                                label={mappingAreaFilter || ""}
                                value={mappingAreaFilter || ""}
                                sx={{
                                  width: "100%", // Full width for responsiveness
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TypeAndSearch
                                data-testid="skuFilter"
                                component={component({ type: "SKU" })}
                                handleChange={(e, option) => {
                                  handleSKUChange(e, option);
                                }}
                                label={skuFilter || ""}
                                value={skuFilter || ""}
                                sx={{
                                  width: "100%", // Full width for responsiveness
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                <Divider />
                <div style={{ width: "100%", height: "507px" }} id="line"></div>
              </Item>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={12} className="cp">
          {analyticsLoader ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    marginTop: "20px",
                    border: "1px solid #D9D9D9",
                    padding: "2px 15px 15px 15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "4px",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...textClasses.cardTitle,
                          textAlign: "start",
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                        }}
                      >
                        Job Status
                      </Typography>
                    </Box>

                    <Box>
                      <Button
                        data-testid="viewAllBtn"
                        variant="contained"
                        size="small"
                        sx={{
                          ...buttonClasses.lynkitOrangeFill,
                          textTransform: "none",
                          marginTop: "10px",
                        }}
                        onClick={handleJobs}
                      >
                        View All
                      </Button>
                    </Box>
                  </Box>
                  <Divider />

                  <Paper
                    elevation={0}
                    sx={{
                      paddingTop: "8px",
                    }}
                  >
                    <GenericTable
                      header={tableHeader}
                      rows={tableRowData}
                    // pageCount={jobcount}
                    // pageNumber={page}
                    // handleChangePage={handleChangePage}
                    // handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // rowsPerPage={rowsPerPage}
                    />
                  </Paper>
                </Box>
              </Grid>
              {/* <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      ddd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper>
                     ddd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper>
                    ddd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper>
                      dd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper>
                     ddd
                    </Paper>
                  </Grid>
                </Grid> */}
            </>
          )}
        </Grid>
      </Grid>

      {userPermissions?.permissions?.find(
        (dl) => dl.permissionId == "staticChart"
      ) ? (
        <>
          {false ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10, mt: 2 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className="cp" sx={{ mt: 2 }}>
              <Paper elevation={3}>
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#000000",
                          padding: "8px 0 10px 20px",
                        }}
                      >
                        Area Mapping & Utilization
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{ width: "50%", height: "507px" }}
                      id="chartdiv"
                    ></div>
                    <div
                      style={{ width: "50%", height: "507px" }}
                      id="chartdiv2"
                    ></div>
                  </Box>
                </Item>
              </Paper>
            </Grid>
          )}
          {false ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10, mt: 2 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className="cp" sx={{ mt: 2 }}>
              <Paper elevation={3}>
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#000000",
                          padding: "8px 0 10px 20px",
                        }}
                      >
                        Dispatch Trends
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{ width: "100%", height: "507px" }}
                      id="chartdiv3"
                    ></div>
                  </Box>
                </Item>
              </Paper>
            </Grid>
          )}
        </>
      ) : (
        ""
      )}

      {/* <Grid item xs={12} md={12} className="cp" sx={{ mt: 2 }}>
        <Paper elevation={3}>
          <Item>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    ...textClasses.t20n,
                    fontWeight: "700",
                    textAlign: "start",
                    color: "#000000",
                    padding: "8px 0 10px 20px",
                  }}
                >
                  Heat Map
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{ width: "100%", height: "507px" }}
                id="heatMap"
              ></div>
            </Box>
          </Item>
        </Paper>
      </Grid> */}
    </Grid>
  );

  function barChart() {
    chart = am4core.create("bar", am4charts.XYChart);

    chart.colors.step = 0;

    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";
    // chart.legend.paddingBottom = 20;
    // chart.legend.labels.template.maxWidth = 95;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.tooltip.disabled = true;
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.extraMax = 0.05;
    yAxis.renderer.grid.template.disabled = false;
    xAxis.renderer.grid.template.disabled = true;
    yAxis.title.text = "Jobs count";
    yAxis.title.fontWeight = "bold";
    // yAxis.title.fontSize = "20px";

    function createSeries(value, name, color) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "category";
      series.name = name;
      series.columns.template.fill = am4core.color(color);
      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);
      series.columns.template.tooltipText = "{valueY}";
      // series.tooltip.getFillFromObject = false;
      // series.tooltip.label.fill = "black";
      // series.tooltip.background.fill = "white";
      var bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      return series;
    }

    chart.data = warehouseData?.data?.map((item) => {
      return {
        category: item.date,
        first: item.createdTaskCount,
        third: item.completedTaskCount,
      };
    });

    createSeries("first", "", "#E39323");
    // createSeries('second', 'Pending Jobs', '#727377');
    createSeries("third", "", "#228b22");

    function arrangeColumns() {
      var series = chart.series.getIndex(0);

      var w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    setTimeout(() => {
      $('g:has(> g[stroke="#3cabff"])').hide();
    }, 100);
  }

  function lineChart() {
    var chart = am4core.create("line", am4charts.XYChart);

    chart.plotContainer.stroke = am4core.color("#aaa");
    chart.plotContainer.strokeOpacity = 1;
    chart.paddingRight = 40;

    // Add data
    chart.data = warehouseInventoryData?.data?.map((item) => ({
      date: item.date,
      instock: item.instock,
      inward: item.totalInward,
      outward: item.totalOutward,
    }));

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    // Create inward axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.05;
    valueAxis.title.text = "Inventory Count";
    valueAxis.title.fontWeight = "bold";

    var lineSeries2 = chart.series.push(new am4charts.LineSeries());
    lineSeries2.dataFields.valueY = "inward";
    lineSeries2.dataFields.dateX = "date";
    // lineSeries2.name = "inward";
    lineSeries2.strokeWidth = 3;
    lineSeries2.fill = am4core.color("#fff");
    lineSeries2.stroke = am4core.color("red");
    lineSeries2.smoothing = "monotoneX";

    // Create series
    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "instock";
    lineSeries.dataFields.dateX = "date";
    // lineSeries.name = "instock";
    lineSeries.strokeWidth = 3;
    lineSeries.fill = am4core.color("#fff");
    lineSeries.stroke = am4core.color("#3E621E");
    lineSeries.smoothing = "monotoneX";

    var lineSeries1 = chart.series.push(new am4charts.LineSeries());
    lineSeries1.dataFields.valueY = "outward";
    lineSeries1.dataFields.dateX = "date";
    // lineSeries1.name = "Outward";
    lineSeries1.strokeWidth = 3;
    lineSeries1.fill = am4core.color("#fff");
    lineSeries1.stroke = am4core.color("#FF7200");
    lineSeries1.smoothing = "monotoneX";

    // Add simple bullet
    var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#7AEDE3");
    bullet.circle.strokeWidth = 1;
    bullet.circle.propertyFields.fill = "color";

    var bullet = lineSeries1.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#7AEDE3");
    bullet.circle.strokeWidth = 1;
    bullet.circle.propertyFields.fill = "color";

    var bullet = lineSeries2.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#7AEDE3");
    bullet.circle.strokeWidth = 1;
    bullet.circle.propertyFields.fill = "color";

    chart.cursor = new am4charts.XYCursor();
    // chart.legend = new am4charts.Legend();

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    setTimeout(() => {
      $('g:has(> g[stroke="#3cabff"])').hide();
    }, 100);
  }

  function instanceChart() {
    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        country: "RM COIL",
        litres: getRandomInt(120),
        color: am4core.color("#3E621E"),
      },
      {
        country: "FINISHED GOODS",
        litres: getRandomInt(120),
        color: am4core.color("#FF7722"),
      },
      {
        country: "WIP MATERIAL",
        litres: getRandomInt(120),
        color: am4core.color("#000000"),
      },
      {
        country: "CRCA COIL",
        litres: getRandomInt(120),
        color: am4core.color("#89570E"),
      },
      {
        country: "GA COIL",
        litres: getRandomInt(120),
        color: am4core.color("#5962B5"),
      },
      {
        country: "GL COIL",
        litres: getRandomInt(120),
        color: am4core.color("#4F1825"),
      },
      {
        country: "Free Space",
        litres: getRandomInt(120),
        color: am4core.color("#FF0848"),
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.propertyFields.fill = "color";

    chart.legend = new am4charts.Legend();
  }
  function instanceChart2() {
    // Create chart instance
    var chart = am4core.create("chartdiv2", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        country: "Filled",
        litres: getRandomInt(120),
        color: am4core.color("#FF0"),
      },
      {
        country: "Available",
        litres: getRandomInt(120),
        color: am4core.color("#008000"),
      },
      {
        country: "Disable",
        litres: getRandomInt(120),
        color: am4core.color("#FF7722"),
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.propertyFields.fill = "color";

    chart.legend = new am4charts.Legend();
  }

  function reportXChart() {
    let x = am4core.create("chartdiv3", am4charts.XYChart);
    x.paddingRight = 20;
    let data = [];
    let visits = 0; // starting value for visits
    let today = new Date(); // current date
    for (let i = 6; i >= 0; i--) {
      // visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
     // Generate a random fluctuation and allow decimals
        visits += (Math.random()) * Math.random();


        // Round to 2 decimal places
        visits = parseFloat(visits.toFixed(2));
      let date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - i
      );
      data.push({ date: date, name: "name" + i, value: visits });
    }
    //console.log("data", data);
    x.data = data;
    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 40; // Set the minimum grid distance to ensure last 7 days are shown
    dateAxis.dateFormats.setKey("day", "MMM dd"); // Format the date labels
    dateAxis.periodChangeDateFormats.setKey("day", "MMM dd"); // Format the date axis tooltip
    dateAxis.baseInterval = { count: 1, timeUnit: "day" }; // Set the base interval to 1 day
    dateAxis.start = today.getTime() - 6 * 24 * 60 * 60 * 1000; // Set the start date to 7 days ago
    dateAxis.end = today.getTime(); // Set the end date to the current date
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    // valueAxis.min = 0;
    valueAxis.extraMax = 0.05;
    valueAxis.title.text = "Inventory Weight(MT)";
    valueAxis.title.fontWeight = "bold";


    // Ensure decimal values are displayed on Y-axis
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#.##"; // Format as 2 decimals

    let series = x.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.tooltipText = "{valueY.value}";
    x.cursor = new am4charts.XYCursor();
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    x.scrollbarX = scrollbarX;
  }

  function heatMap() {
    // Create chart
    var chart = am4core.create("heatMap", am4charts.XYChart);
    chart.maskBullets = false;

    // Create X Axis (Days of the week or Location)
    var xAxis = createXAxis(chart);

    // Create Y Axis (Hours from 12 AM to 24 PM)
    var yAxis = createYAxis(chart);

    // Create series for heatmap
    var series = createHeatmapSeries(chart);

    // Create Heat Legend
    var {heatLegend,legend} = createHeatLegend(chart, series);

    // Generate dynamic dates for the last 7 days
    const last7Days = getLast7Days();

    // Create chart data dynamically for the last 7 days and hourly data
    const chartData = generateChartData(last7Days);

    chart.data = chartData;

    // Add total touches per day to X-axis labels
    addTotalTouchesToXAxisLabels(xAxis, chart.data);

    // Add event listeners for hover and tooltips
    addHeatMapInteractions(series, heatLegend);


    // Function to create the X Axis
    function createXAxis(chart) {
      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      xAxis.dataFields.category = "name";
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;
      xAxis.renderer.labels.template.horizontalCenter = "middle"; // Center the labels
      xAxis.renderer.labels.template.verticalCenter = "middle";
      return xAxis;
    }

    // Function to create the Y Axis
    function createYAxis(chart) {
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      yAxis.dataFields.category = "hour";
      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true; // Inverse for 12 AM at the top
      yAxis.renderer.minGridDistance = 30;
      return yAxis;
    }

    // Function to create the heatmap series
    function createHeatmapSeries(chart) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "name"; // X-axis (Days or Locations)
      series.dataFields.categoryY = "hour"; // Y-axis (Hours)
      series.dataFields.value = "touches"; // Heatmap intensity based on touches
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      // Column Template
      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.stroke = am4core.color("#ffffff"); // White borders for grid cells
      columnTemplate.tooltipText = "{touches} touches at {name}, Hour: {hour}";
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      // Heat Rules: Color intensity based on touches
      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color("#0f0"),
        max: am4core.color("#f00") // Heatmap colors
      });

      series.columns.template.column.adapter.add("fill", function (fill, target) {
        if (target.dataItem) {
          if (target.dataItem.value >= 600) {
            return am4core.color("#f00");
          } else if (target.dataItem.value >= 400) {
            return am4core.color("#ff0");
          } else {
            return am4core.color("#0f0");
          }
        }
        return fill;
      });

      return series;
    }

    // Function to create the Heat Legend
    function createHeatLegend(chart, series) {
      var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
      heatLegend.width = am4core.percent(100);
      heatLegend.series = series;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
      heatLegend.valueAxis.renderer.minGridDistance = 30;

      // Define colors for the heat legend
      var legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.data = [
        { "name": ">= 600", "fill": am4core.color("#f00") },
        { "name": ">=1", "fill": am4core.color("#ff0") },
        { "name": "< 1", "fill": am4core.color("#0f0") }
      ];

     // Keep track of which legend items are active
     var activeLegendItems = {
      ">= 600": true,
      ">= 400": true,
      "< 400": true
  };

  // Add event listeners to the legend items
  legend.itemContainers.each(container => {
      container.events.on("over", () => {
          const legendName = container.dataItem.dataContext.name;
          updateGraphCells(legendName, false); // Disable cells that match the legend
      });

      container.events.on("out", () => {
          const legendName = container.dataItem.dataContext.name;
          updateGraphCells(legendName, true); // Reset cells on mouse out
      });

      container.events.on("hit", () => {
          const legendName = container.dataItem.dataContext.name;
          activeLegendItems[legendName] = !activeLegendItems[legendName]; // Toggle active state
          updateGraphCells(legendName, activeLegendItems[legendName]);
      });
  });

  function updateGraphCells(legendName, isReset) {
      series.columns.each(column => {
          const value = column.dataItem.value;

          if (isReset) {
              // Reset color based on original value
              if (value >= 600) {
                  column.fill = am4core.color("#f00");
              } else if (value >= 400) {
                  column.fill = am4core.color("#ff0");
              } else {
                  column.fill = am4core.color("#0f0");
              }
          } else {
              // Disable color based on legend
              if (legendName === ">= 600" && value >= 600) {
                  column.fill = am4core.color("#ccc"); // Gray out the cell
              } else if (legendName === ">= 400" && value >= 400) {
                  column.fill = am4core.color("#ccc"); // Gray out the cell
              } else if (legendName === "< 400" && value < 400) {
                  column.fill = am4core.color("#ccc"); // Gray out the cell
              }
          }
      });
  }
      return { heatLegend, legend };
    }

    // Function to generate the last 7 days
    function getLast7Days() {
      const days = [];
      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        days.push(dayNames[date.getDay()]);
      }
      return days;
    }

    // Function to generate chart data
    function generateChartData(last7Days) {
      const chartData = [];
      const hours = [
         "12 PM","11 PM", "10 PM", "9 PM", "8 PM", "7 PM", "6 PM",
        "5 PM", "4 PM", "3 PM", "2 PM", "1 PM",
        "11 AM", "10 AM", "9 AM", "8 AM", "7 AM", "6 AM",
        "5 AM", "4 AM", "3 AM", "2 AM", "1 AM", "12 AM"
      ];

      const staticData = [
        [120, 300, 450, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400], // Monday's touch data
        [240, 600, 850, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700], // Tuesday's touch data
        [150, 500, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900], // Wednesday's touch data
        [200, 450, 800, 850, 950, 1050, 1150, 1250, 1350, 1450, 1550, 1650], // Thursday's touch data
        [170, 350, 750, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600], // Friday's touch data
        [300, 700, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800], // Saturday's touch data
        [250, 550, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800]  // Sunday's touch data
      ];

      last7Days.forEach((day, dayIndex) => {
        staticData[dayIndex].forEach((touches, hourIndex) => {
          chartData.push({
            name: day,
            hour: hours[hourIndex],
            touches: touches
          });
        });
      });

      return chartData;
    }

    // Function to add total touches to X-axis labels
    function addTotalTouchesToXAxisLabels(xAxis, data) {
      var totalTouchesPerDay = {};

      // Calculate total touches per day
      data.forEach(item => {
        if (!totalTouchesPerDay[item.name]) {
          totalTouchesPerDay[item.name] = 0;
        }
        totalTouchesPerDay[item.name] += item.touches;
      });

      xAxis.renderer.labels.template.adapter.add("text", (text, target) => {
        let category = target.dataItem.category;  // Access the actual category value
        if (category) {
          let cleanedCategory = category.trim();  // Clean up the category
          // Check if the cleaned category exists in the totalTouchesPerDay object
          if (totalTouchesPerDay[cleanedCategory]) {
            return cleanedCategory + " (" + totalTouchesPerDay[cleanedCategory] + ")";
          } else {
            return cleanedCategory + " (0)";  // Fallback in case there's no match
          }
        }
        return text; // If no data, just return the day name
      });
    }

    // Function to add interactions for the heatmap
    function addHeatMapInteractions(series, heatLegend) {
      series.columns.template.events.on("over", (event) => {
        handleHover(event.target, heatLegend);
      });
      series.columns.template.events.on("hit", (event) => {
        handleHover(event.target, heatLegend);
      });
      series.columns.template.events.on("out", (event) => {
        heatLegend.valueAxis.hideTooltip();
      });
    }

    // Handle hover events for the heatmap
    function handleHover(column, heatLegend) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value);
      } else {
        heatLegend.valueAxis.hideTooltip();
      }
    }
  }

  

  // function reportXChart(){
  //   let x = am4core.create("chartdiv3", am4charts.XYChart);

  //   x.paddingRight = 20;

  //   let data = [];
  //   let visits = 1000; // starting value for visits
  //   let today = new Date(); // current date
  //   for (let i = 6; i >= 0; i--) {
  //     visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
  //     let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
  //     data.push({ date: date, name: "name" + i, value: visits });
  //   }

  //   //console.log("data",data)
  //   x.data = data;

  //   let dateAxis = x.xAxes.push(new am4charts.DateAxis());
  //   dateAxis.renderer.grid.template.location = 0;
  //   dateAxis.min = 0;

  //   let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis.tooltip.disabled = true;
  //   valueAxis.renderer.minWidth = 35;

  //   let series = x.series.push(new am4charts.LineSeries());
  //   series.dataFields.dateX = "date";
  //   series.dataFields.valueY = "value";
  //   series.tooltipText = "{valueY.value}";
  //   x.cursor = new am4charts.XYCursor();

  //   let scrollbarX = new am4charts.XYChartScrollbar();
  //   scrollbarX.series.push(series);
  //   x.scrollbarX = scrollbarX;

  // };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function capitalize(str) {
  if (!str) return;
  // ////({ str });
  return str[0].toUpperCase().concat(str.slice(1));
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
