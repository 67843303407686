import React, { useState } from "react";
import theme from "../../../static/styles/theme";

const CollapsibleTable = ({ headers=[], rows=[],rowValueStyle={},headerValueStyle={} }) => {
//   const [isCollapsed, setIsCollapsed] = useState(false);

//   console.log(headers,rows)
//   const toggleRows = () => {
//     setIsCollapsed(!isCollapsed);
//   };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const cellStyle = {
    border: `1px solid ${theme.themeGray}`,
    padding: "8px",
    textAlign: "left",
  };

//   const buttonStyle = {
//     cursor: "pointer",
//     backgroundColor: "#f2f2f2",
//     border: "none",
//     padding: "8px",
//     textAlign: "left",
//     width: "100%",
//   };

//   const buttonHoverStyle = {
//     backgroundColor: "#ddd",
//   };

  return (
    <div>
      {/* <h2>Collapsible Table</h2>
      <p>Click the button to expand or collapse the rows:</p> */}

      <table style={tableStyle}>
        {/* Collapsible Header */}
        <thead>
          <tr>
            {/* <th colSpan={headers.length} style={cellStyle}>
              <button
                style={{
                  ...buttonStyle,
                  ...(isCollapsed ? {} : buttonHoverStyle),
                }}
                onClick={toggleRows}
              >
                Toggle Rows
              </button>
            </th> */}
          </tr>
          {/* Table Headers */}
          <tr>
            {headers.map((header, index) => (
              <th key={index} style={{...cellStyle,...headerValueStyle}}>
                {header}
              </th>
            ))}
          </tr>
        </thead>

        {/* Collapsible Rows */}
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              style={{
                ...cellStyle,
                display:"table-row",
                // display: isCollapsed ? "none" : "table-row",
              }}
            >
              {row.map((cell, idx) => (
                  <td key={idx} style={{
                      ...cellStyle,
                      color: theme.themeBlack,
                      ...rowValueStyle,
                      textAlign: isNaN(cell) ? "left" : "right"
                  }}>
                      {cell}
                  </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CollapsibleTable;
