import { Breadcrumbs, Grid, Paper, Typography, Button, IconButton, Divider, Collapse, DialogContent, Dialog, TextField, FormLabel, FormControl, FormControlLabel, Radio, Autocomplete, TablePagination, Box, Checkbox } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { styled, useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { 
    getAllTemplates, getAllUsers, getAsnDetails, approveASNArrival,
     getTemplateHeader, rejectAsn, fetchFile, closeAsn 
    } from '../../../../redux/actions'
import theme, { buttonClasses, inputClasses, textClasses } from '../../../../static/styles/theme'
import history from '../../../../services/history'
import moment from 'moment'
import { FETCH_ASN_DETAIL } from '../../../../redux/types'
import FileModal from "../../EntityMaster/FileModal";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import GenericLoader from '../../../Components/Generic/GenericLoader'
import { capitalizeSentence, getCamelCaseText, showToast, dataReadFunction, renderOptionHandler, dateTimeFormater,truncateNumber } from '../../../../services/functions'
import { customerMasterVisibilityFields } from "../../TMS/Addressbook/utils";
import CloseDialog from '../../Inventory/CloseDialog'
import GenericSummaryView from "../../../Components/Generic/GenericSummaryView";



const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))


export default function ViewAsn({ boosterAsnId }) {

    let { asnId } = useParams();
    if (boosterAsnId) {
        asnId = boosterAsnId
    }
    const dispatch = useDispatch();

    const asnDetail = useSelector(state => state.asnEntry.asnDetail)
    const asnDetailItemsCount = useSelector(state => state.asnEntry.asnDetailItemsCount)
    const shipmentMasterData = useSelector(state => state.asnEntry.shipmentConnectedMasterData)
    const orderMasterData = useSelector(state => state.asnEntry.orderConnectedMasterData)
    const { userPermissions } = useSelector((state) => state.userPermission);


    const templateHeader = useSelector(state => state.inventory.templateHeader);
    const { AllUserData } = useSelector((state) => state.users);
    // console.log("ASN ID", asnId);
    // console.log("ASN DETAIL", asnDetail);
    // console.log("template header", templateHeader);
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(0);
    const [showHistory, setShowHistory] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([])
    const [closeModal, setCloseModal] = useState(null);
    const [closeReason, setCloseReason] = useState("")
    const historySize = asnDetail?.shipmentDetail?.received_by?.length;

    const isPartialReceived = asnDetail?.shipmentDetail?.status === "partialReceived";

    const displayFile = (fileUrl, fileKey) => {
        const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
        const isValidImageUrl = imageUrlRegex.test(fileKey);
        if (isValidImageUrl) {
            setOpenModal(true);
            setDisplayUrl(fileUrl);
        } else {
            window.open(fileUrl, "_blank");
        }
    };

    const DetailRow = ({ title, children }) => {
        return (
            <Grid container sx={{ mt: 1 }}>
                <Grid item sm={4} xs={4}>
                    <Typography
                        sx={{
                            ...textClasses.t12n,
                            color: "#827878",
                            wordWrap: "break-word",
                            mr: 1,
                        }}
                    >
                        {title}:{" "}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    sm={8}
                    xs={8}
                    direction="row"
                    alignItems={"center"}
                    sx={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                    {children}
                </Grid>
            </Grid>
        );
    };

    const handleFile = (fileKey) => {
        setOpenModal(false);
        setDisplayUrl('');
        dispatch(fetchFile({ fileKey }, displayFile));
    };
    let createrUserInfo = (asnDetail && AllUserData && AllUserData.data) ? AllUserData.data.filter(u => {
        if (asnDetail.shipmentDetail.created_by && asnDetail.shipmentDetail.created_by._id) {
            return u._id == asnDetail.shipmentDetail.created_by._id
        }
        return false;
    }) : []
    createrUserInfo = createrUserInfo.length > 0 ? createrUserInfo[0] : null

    let userInfo = (asnDetail && AllUserData && AllUserData.data) ? AllUserData.data.filter(u => {
        if (asnDetail.shipmentDetail.rejected_by && asnDetail.shipmentDetail.rejected_by._id) {
            return u._id == asnDetail.shipmentDetail.rejected_by._id
        }
        return false;
    }) : []
    userInfo = userInfo.length > 0 ? userInfo[0] : null

    const [masterType, setMasterType] = useState("");
    const [loader, setLoader] = useState(false);
    const [dialogStatus, setDialogStatus] = useState(null);
    // console.log("Master Type", masterType);

    const handleOpenDialog = (status) => {
        setDialogStatus(status)
    }

    const handleCloseDialog = () => {
        setDialogStatus(null);
    }

    const refreshContent = () => {
        setLoader(true);
        dispatch(
            getAsnDetails({ ASN_ID: asnId, page: page + 1, limit: limit }, (data) => {

                if (data) {
                    dispatch(
                        getTemplateHeader({ template_id: data.shipmentDetail.template_id })
                    )
                    let masterType;
                    setMasterType(masterType);
                    setLoader(false);
                }
                else {
                    setLoader(false);
                }

            })
        )
    }

    useEffect(() => {
        if (asnId || page || limit) {
            refreshContent();
            dispatch(getAllUsers({ "isAdminIncluded": true }))
        }
    }, [asnId, page, limit])

    const handleCloseModal = useCallback(() => {
        setCloseModal(null)
        setCloseReason("")

    }, [])

    const callBack = (data) => {
        refreshContent();
        handleCloseModal();
    };

    const handleCloseConfirm = (operationType = "close") => {
        let payload = {
            ASN_Id: asnDetail?.ASN_Id,
        };
        if (operationType === "ArrivalASNShipment") {
            payload["arrivalType"]="ASN"
            dispatch(
                approveASNArrival(payload, (data) => {
                    if (data) {
                        callBack(data);
                    }
                })
            );
        }
        else {
            payload = {
                ...payload,
                reason: closeReason,
                product_ID: selectedProducts,
                closingType: "item",
            };
            dispatch(
                closeAsn(payload, (data) => {
                    if (data) {
                        callBack(data);
                    }
                })
            );
        }
    };

    const handleRemoveSelection = useCallback((id) => {
        const ids = selectedProducts;
        const updatedIds = ids.filter((itemId) => {
            return itemId !== id
        })
        setSelectedProducts(updatedIds)
    }, [selectedProducts, setSelectedProducts])

    let getUserDetail = (userId) => {
        return AllUserData?.data?.find((u) => u._id == userId) || {}
      }
    
    let historySummary = (history) => {
       
        let count = history?.quantity || 0
        let date = dateTimeFormater(history?.date)
        let userId = getUserDetail(history?._id) || {}

        return <>Received <strong>{count} pcs</strong>{" "} by {userId?.name || ""} at{" "} <strong>{date}</strong></>
    }

    // Function to sort the data
    const sortDataByDate = (data=[]) => {
        return data.sort((a, b) => {
            const dateA = new Date(a.date).getTime();
            const dateB = new Date(b.date).getTime();

            // Sort in descending order (newest first)
            return dateB-dateA;
        });
    };

    // {
    //     "base": "Coding Ninjas",
    //     "email": "codingninjas@gmail.com",
    //     "_id": "633ff4fd65af23502ffbc0b5",
    //     "userType": "admin",
    //     "name": "Admin_CN",
    //     "date": "2024-08-11T20:05:13.427Z",
    //     "quantity": 668,
    //     "previousAvailableQuantity": 1000,
    //     "shipmentId": "C-2878-S-120824"
    // }
    let historyDetails = (history) => {
       
        let userId =getUserDetail(history?._id)  || {}
        let date=dateTimeFormater(history?.date)
        return (
            <>
                <DetailRow title={"Shipment ID"} key={1}>
                    <Typography sx={{ ...textClasses?.t12n }}>{history.shipmentId || "-"} </Typography>
                </DetailRow>
                <DetailRow title={"Received By"} key={2}>
                    <Typography sx={{ ...textClasses?.t12n }}>{userId.name || "-"} </Typography>
                </DetailRow>
                <DetailRow title={"Receiving Date"} key={3}>
                    <Typography sx={{ ...textClasses?.t12n }}>{date || "-"} </Typography>
                </DetailRow>
                <DetailRow title={"Received Qty"} key={4}>
                    <Typography sx={{ ...textClasses?.t12n }}>{history?.quantity || 0} pcs</Typography>
                </DetailRow>
                <DetailRow title={"Last Available Qty"} key={5}>
                    <Typography sx={{ ...textClasses?.t12n }}>{history?.previousAvailableQuantity || 0} pcs</Typography>
                </DetailRow>
                {Number(history?.exceedQuantity || 0)>0 && <DetailRow title={"Exceed Qty"} key={7}>
                    <Typography sx={{ ...textClasses?.t12n }}>{history?.exceedQuantity || 0} pcs</Typography>
                </DetailRow>}
                {Number(history?.rejectedQuantity || 0)>0 && <DetailRow title={"Rejected Qty"} key={8}>
                    <Typography sx={{ ...textClasses?.t12n }}>{history?.rejectedQuantity || 0} pcs</Typography>
                </DetailRow>}
            </>
        )

    }

    return (
        <>
            <Typography sx={{ ...textClasses.cardTitle }}>Advance Shipment Notice</Typography>
            <Grid container>
                <Grid container item sm={9} xs={6}>
                    <Breadcrumbs sx={{ mb: 1 }}>
                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                            <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/inventory/asn">
                                Advance Shipment Notice ASN
                            </Link>
                        </Typography>

                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>view details - {asnId}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid container item sm={3} xs={6} justifyContent="end">
                    {
                        (asnDetail?.shipmentDetail?.status && (asnDetail.shipmentDetail?.status == "drafted" || (asnDetail.shipmentDetail?.status === "incoming" && asnDetail.shipmentDetail?.rejectedQuantity === 0))) &&
                        userPermissions?.permissions?.some((item) => item.permissionId === "updateASN") &&
                        <Button variant="outlined" sx={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={() => history.push(`/dashboard/data/edit-asn/${asnDetail && asnDetail.shipmentDetail._id}`)}>Edit</Button>
                    }
                    {
                        asnDetail?.shipmentDetail?.status && ["partialReceived"].indexOf(asnDetail.shipmentDetail.status) >= 0 &&
                        userPermissions?.permissions?.some((item) => item.permissionId === "closeASN") &&
                        <>
                            <Button variant="outlined"
                                disabled={!selectedProducts.length} sx={{
                                    ...buttonClasses.lynkitOrangeFill, backgroundColor: !selectedProducts.length ? "grey" : "#3E621E", border: "1px solid #3E621E", ml: 1, "&.Mui-disabled": {
                                        backgroundColor: "lightgrey", // Custom background color for disabled state
                                        color: "grey", // Optional: change text color for disabled state
                                        border: "1px solid grey", // Optional: change border color for disabled state
                                    }, "&:hover": { backgroundColor: "#3E621E", border: "1px solid #3E621E", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", }
                                }} onClick={() => { setCloseModal("CloseASNItem") }}>Close</Button>
                        </>
                    }

                    {
                        asnDetail?.shipmentDetail?.status && ["arrived", "partialReceived"].indexOf(asnDetail?.shipmentDetail?.status) >= 0 &&
                        userPermissions?.permissions?.some((item) => item.permissionId === "asnReceiving") &&

                        <>
                            <Button
                                variant="outlined"
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    backgroundColor: "#3E621E",
                                    border: "1px solid #3E621E",
                                    ml: 1,
                                    "&:hover": {
                                        backgroundColor: "#3E621E",
                                        border: "1px solid #3E621E",
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }
                                }}
                                onClick={() => handleOpenDialog("receive")}
                            >Receive</Button>
                        </>
                    }
                    {
                        asnDetail?.shipmentDetail?.status=="incoming" &&
                        userPermissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN") &&

                        <>
                            <Button
                                variant="outlined"
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    backgroundColor: "#3E621E",
                                    border: "1px solid #3E621E",
                                    ml: 1,
                                    "&:hover": {
                                        backgroundColor: "#3E621E",
                                        border: "1px solid #3E621E",
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }
                                }}
                                onClick={() => setCloseModal("ArrivalASNShipment")}
                            >Approve Arrival</Button>
                        </>
                    }
                    {
                        ["incoming","arrived"].indexOf(asnDetail?.shipmentDetail?.status) >= 0 &&
                        userPermissions?.permissions?.some((item) => item.permissionId === "acceptRejectASN" ||  item.permissionId === "arrived") &&

                        <>
                            <Button
                                variant="outlined"
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    backgroundColor: "#E9001A",
                                    border: "1px solid #E9001A",
                                    ml: 1,
                                    "&:hover": {
                                        backgroundColor: "#E9001A",
                                        border: "1px solid #E9001A",
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }
                                }}
                                onClick={() => handleOpenDialog("reject")}
                            >Reject</Button>
                        </>

                    }

                </Grid>
            </Grid>
            {dialogStatus && <ConfirmDialog
                asn={asnDetail} status={dialogStatus}
                templateHeader={templateHeader}
                handleClose={handleCloseDialog}
                refreshContent={refreshContent}
            />}
            {closeModal && <CloseDialog
                status={closeModal}
                selectedProducts={selectedProducts}
                closeReason={closeReason}
                handleRemoveSelection={handleRemoveSelection}
                handleConfirm={()=>handleCloseConfirm(closeModal)}
                setCloseReason={setCloseReason}
                subTitle={closeModal=="ArrivalASNShipment"?"You want to approve the arrival of ASN.":`You want to close the further receiving.`}
                handleClose={handleCloseModal}
                selectedEntries={asnDetail ? asnDetail?.itemDetail?.at(0) && ([asnDetail?.itemDetail?.at(0)] || []) : []}
            />}

            {
                loader
                    ?
                    <>
                        <Grid container justifyContent={"center"} alignItems="center">
                            <GenericLoader />
                        </Grid>
                    </>
                    :
                    <>
                        {
                            asnDetail &&
                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                <Grid container item sm={12} md={8} order={{ xs: 2, sm: 2, md: 2 }} sx={{ height: "100%", overflowY: "auto" }}>
                                    <Paper sx={{ width: "100%", p: 2, mb: 2 }}>
                                        <Grid container sx={{ mb: 2 }}>
                                            <Grid container item md={4} sm={6} xs={12} justifyContent={"start"} alignItems={"center"}>
                                                {isPartialReceived && asnDetail?.itemDetail?.some((entry) => entry.status === "partialReceived" || entry.status === "incoming") && <Checkbox
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            const ids = asnDetail?.itemDetail?.filter((item) => { return item.status === "partialReceived" || item.status === "incoming" })?.map(({ product_ID }) => product_ID)
                                                            setSelectedProducts(ids)
                                                        } else {
                                                            setSelectedProducts([])
                                                        }
                                                    }}
                                                    checked={
                                                        asnDetail.itemDetail.filter((entry) => entry.status === "partialReceived" || entry.status === "incoming").every((item) => selectedProducts.includes(item.product_ID))
                                                    }
                                                />}
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px" }}>Order Details</Typography>
                                            </Grid>
                                            <Grid container item md={4} sm={6} xs={12} justifyContent={"end"}>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px", color: theme.themeOrange }}>
                                                    Total Quantity :

                                                </Typography>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px", color: theme.themeOrange, ml: 1 }}>
                                                    {
                                                        (asnDetail.shipmentDetail.totalQuantity ? asnDetail.shipmentDetail.totalQuantity : 0)
                                                    }
                                                </Typography>

                                            </Grid>
                                            <Grid container item md={4} sm={6} xs={12} justifyContent={"end"}>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px", color: theme.themeOrange }}>
                                                    Remaining Quantity :

                                                </Typography>
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "18px", color: theme.themeOrange, ml: 1 }}>
                                                    {
                                                        truncateNumber((asnDetail.shipmentDetail.totalQuantity ? asnDetail.shipmentDetail.totalQuantity : 0) - ((asnDetail?.shipmentDetail.receivedQuantity ?? 0) 
                                                        -(asnDetail?.shipmentDetail.exceedQuantity ?? 0)) 
                                                        -(asnDetail.shipmentDetail.rejectedQuantity ?? 0) 
                                                        -(asnDetail.shipmentDetail.closedQuantity ?? 0))
                                                    }
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                        <Grid container
                                            sx={{ maxHeight: "800px", overflowY: "auto" }}
                                            data-testid="viewdetails">
                                            {
                                                asnDetail && asnDetail?.itemDetail?.map((order, i) => {
                                                    return (
                                                        <OrderCollapsableCard index={i} order={order} key={i}
                                                            isPartialReceived={isPartialReceived}
                                                            selectedProducts={selectedProducts}
                                                            setSelectedProducts={setSelectedProducts}
                                                            templateHeader={templateHeader}
                                                            handleFile={handleFile}
                                                        />
                                                    )
                                                })
                                            }



                                        </Grid>
                                        {
                                            asnDetail && asnDetail?.itemDetail?.length && (
                                                <Grid container alignItems={"center"} justifyContent="center">
                                                    <TablePagination
                                                        component="div"
                                                        count={asnDetailItemsCount}
                                                        page={page}
                                                        rowsPerPage={limit}
                                                        rowsPerPageOptions={asnDetailItemsCount > 5 ? [5, 10, 25, 50, 100] : [5]}
                                                        onPageChange={(e, newPage) => {
                                                            setPage(newPage)

                                                        }}
                                                        onRowsPerPageChange={(e) => {
                                                            setLimit(e.target.value);
                                                            setPage(0);
                                                        }}
                                                        ActionsComponent={TablePaginationActions}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            mt: "0.5em",
                                                            "& .MuiTablePagination-selectLabel ": {
                                                                margin: '0'
                                                            },
                                                            "& .MuiTablePagination-displayedRows": {
                                                                margin: '0'
                                                            }
                                                        }}
                                                    />
                                                </Grid>)
                                        }

                                    </Paper>
                                </Grid>
                                <Grid container
                                    item
                                    sm={12}
                                    md={4}
                                    order={{ xs: 1, sm: 1, md: 2 }}
                                    sx={{ height: "100%", overflowY: "auto" }}
                                    padding={0}>
                                    <Paper sx={{
                                        width: "100%",
                                        p: 2,
                                        mb: 2,
                                        minHeight: "50%",
                                        maxHeight: "80%",
                                    }}>
                                        <Typography sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}>Shipment details</Typography>
                                        <Grid container>
                                            {
                                                templateHeader && templateHeader?.shipmentHeader && templateHeader?.shipmentHeader?.map((head, i) => {

                                                    let formData=asnDetail?.shipmentDetail?.formData  || {}
                                                    return (
                                                        <Grid container item alignItems={"center"} sx={{ mb: 1 }}>

                                                            <Grid container>
                                                                <Grid container item sm={4} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{head.label}: </Typography>
                                                                </Grid>
                                                                <Grid container item sm={8} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText }}>
                                                                        {formData[head.name] ?
                                                                            head.type === "file" ? (
                                                                                <div
                                                                                    onClick={() =>
                                                                                        handleFile(
                                                                                            formData[
                                                                                            head.name
                                                                                            ]
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            ...buttonClasses.viewFile,
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        View File
                                                                                    </span>
                                                                                </div>
                                                                            ) :
                                                                             
                                                                                head.type == "date"
                                                                                    ?
                                                                                    dataReadFunction(head, formData) && dateTimeFormater(dataReadFunction(head, formData), head?.format || "DD MMM YYYY") || "-"
                                                                                    :
                                                                                    head?.renderOption?.length > 0
                                                                                        ? renderOptionHandler(formData, head?.renderOption) || "-"
                                                                                        : dataReadFunction(head, formData) || "-"
                                                                             :
                                                                            "-"}
                                                                    </Typography>
                                                                </Grid>
                                                                <FileModal
                                                                    openModal={openModal}
                                                                    displayUrl={displayUrl}
                                                                    setOpenModal={setOpenModal}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }

                                            <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                                <Grid container>
                                                    <Grid container item sm={4} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>ASN Create at: </Typography>
                                                    </Grid>
                                                    <Grid container item sm={8} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText }}>{asnDetail.shipmentDetail.createdAt ? moment(asnDetail.shipmentDetail.createdAt).format("DD MMM YYYY hh:mm A") : "-"}</Typography>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                            <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                                <Grid container>
                                                    <Grid container item sm={4} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>ASN Created by:</Typography>
                                                    </Grid>
                                                    <Grid container item sm={8} xs={6}>
                                                        <Typography sx={{ ...textClasses.normalText }}>{createrUserInfo && createrUserInfo.name} , {createrUserInfo && createrUserInfo.designation}</Typography>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    {userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners") &&
                                        customerMasterVisibilityFields?.length > 0 && asnDetail?.shipmentDetail?.customerDetail?.blink_id &&
                                        <Paper
                                            sx={{
                                                width: "100%",
                                                p: 2,
                                                mb: 2,
                                                minHeight: "50%",
                                                maxHeight: "80%",
                                            }}
                                        >
                                            <Typography
                                                sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}
                                            >
                                                Customer details
                                            </Typography>
                                            <Grid container>
                                                {customerMasterVisibilityFields?.map((head, i) => {
                                                    return (
                                                        <Grid container item alignItems={"center"} sx={{ mb: 1 }}>
                                                            <Grid container>
                                                                <Grid container item sm={4} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>
                                                                        {head.label}:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container item sm={8} xs={6}>
                                                                    <Typography sx={{ ...textClasses.normalText }}>
                                                                        {
                                                                            head.type == "date"
                                                                                ?
                                                                                dataReadFunction(head, asnDetail?.shipmentDetail?.customerDetail) && dateTimeFormater(dataReadFunction(head, asnDetail?.shipmentDetail?.customerDetail)) || "-"
                                                                                :
                                                                                head?.renderOption?.length > 0
                                                                                    ? renderOptionHandler(asnDetail?.shipmentDetail?.customerDetail, head?.renderOption) || "-"
                                                                                    : dataReadFunction(head, asnDetail?.shipmentDetail?.customerDetail) || "-"
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Paper>
                                    }
                                    {
                                        asnDetail && asnDetail.shipmentDetail.rejectedReason &&
                                        <Paper sx={{ width: "100%", p: 2, mt: 2 }}>
                                            <Grid container>
                                                <Grid container item sm={6} xs={6}>
                                                    <Typography sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}>Rejection Reason</Typography>
                                                </Grid>
                                                <Grid container item sm={6} xs={6} justifyContent="end">
                                                    <Typography sx={{ ...textClasses.normalText, mb: 2 }}>{moment(asnDetail.shipmentDetail.rejected_by.date).format("DD MMM YYYY hh:mm A")}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography sx={{ ...textClasses.normalText, mb: 2 }}>{userInfo && userInfo.name}, {userInfo && userInfo.designation}</Typography>
                                            <Typography sx={{ ...textClasses.normalText, mb: 2 }}>
                                                {asnDetail.shipmentDetail.rejectedReason ? asnDetail.shipmentDetail.rejectedReason : "-"}
                                            </Typography>
                                        </Paper>
                                    }
                                    {
                                        asnDetail && asnDetail?.shipmentDetail?.received_by.length > 0 &&
                                        <Paper sx={{ width: "100%", p: 2, mt: 2 }}>
                                            <Grid container>
                                                <Grid container item sm={6} xs={6}>
                                                    <Typography sx={{ ...textClasses.boldText, fontSize: "18px", mb: 2 }}>Receiving History</Typography>
                                                </Grid>
                                            </Grid>

                                                    <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                                                        {asnDetail?.shipmentDetail?.received_by && sortDataByDate(asnDetail?.shipmentDetail?.received_by || [])?.slice(0, showHistory ? historySize : 4)
                                                            .map((history) => {
                                                                return (
                                                                    <GenericSummaryView
                                                                        isSummary={true}
                                                                        headingText={historySummary(history || {})}
                                                                        detailChilden={historyDetails(history || {})}
                                                                    />
                                                                );
                                                            })}
                                                    </Box>
                                                    {historySize > 5 && (
                                                        <>
                                                            <Grid
                                                                container
                                                                item
                                                                justifyContent="center"
                                                                alignItems={"center"}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        ...textClasses.boldText,
                                                                        color: theme.themeOrange,
                                                                        textDecoration: "underline",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => setShowHistory(!showHistory)}
                                                                >
                                                                    {showHistory ? (
                                                                        <>Collapse</>
                                                                    ) : (
                                                                        <>View All ({historySize})</>
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {/* {
                                                        asnDetail?.shipmentDetail?.received_by?.slice(0, showHistory ? historySize : 5)?.map((history) => {
                                                    return <Typography sx={{ ...textClasses.normalText, mb: 2, p: 1, color: theme.themeOrange, backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px" }}>
                                                        Received <strong>{history.quantity} pcs</strong> by {history.name} at <strong>{moment(history.date).format(
                                                            "DD MMM YYYY hh:mm A"
                                                        )}</strong>
                                                    </Typography>
                                                }) */}
                                            {/* } */}
                                            {/* {
                                                historySize > 5 &&
                                                <>
                                                    <Grid container item justifyContent="center" alignItems={"center"}>
                                                        <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowHistory(!showHistory)}>
                                                            {
                                                                showHistory
                                                                    ?
                                                                    <>Collapse</>
                                                                    :
                                                                    <>View All ({historySize})</>
                                                            }
                                                        </Typography>
                                                    </Grid>

                                                </>
                                            } */}
                                        </Paper>
                                    }

                                </Grid>
                            </Grid>
                        }
                    </>
            }
        </>
    )
    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? (
                        <LastPageIcon />
                    ) : (
                        <FirstPageIcon />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? (
                        <FirstPageIcon />
                    ) : (
                        <LastPageIcon />
                    )}
                </IconButton>
            </Box>
        );
    }
}


const OrderCollapsableCard = ({ index, order, isPartialReceived, templateHeader, handleFile, selectedProducts, setSelectedProducts }) => {
    const [expanded, setExpanded] = useState(index == 0 ? true : false);

    const masterType = order.itemMasterDetail ? "itemMaster" : "";

    const statusColor = {
        partialReceived: "#3E621E",
        received: "#3E621E",
        rejected: "#E9001A",
        drafted: "#FF0101",
        incoming: "#FF7200",
        arrived: "#FF7200",
        default: "#000"
    }
    const statusText = {
        partialReceived: "Partially Received",
        received: "Received",
        rejected: "Rejected",
        drafted: "Drafted",
        arrived: "Arrived",
        incoming: "Incoming"

    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const ReceivingTypes = [{ label: "Available Quantity", value: "availableQuantity" }, { label: "Less Quantity", value: "lessQuantity" }, { label: "Extra Quantity", value: "exceedQuantity" }]

    return (
        <Paper sx={{ width: "100%", p: 2, border: `1px solid ${theme.themeOrange}`, mb: 2, overflowY: "scroll" }}>
            <Grid container>
                {isPartialReceived && (order.status === "partialReceived" || order.status === "arrived") && <Checkbox
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedProducts((prev) => [...prev, order.product_ID])
                        } else {
                            const ids = selectedProducts.filter((product) => product !== order.product_ID)
                            setSelectedProducts(ids)
                        }
                    }}
                    checked={selectedProducts?.includes(order.product_ID)
                    }
                />}
                <Grid container item sm={3} xs={3} justifyContent="start" alignItems="center">
                    {
                        templateHeader && templateHeader?.itemHeader?.map((h, i) => {
                            return (

                                h.name == "product_ID" ?
                                    <>
                                        <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{h.label}:</Typography>
                                        <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, ml: 1 }}>{order.product_ID}</Typography>
                                    </>
                                    :
                                    <></>

                            )
                        })
                    }

                </Grid>
                <Grid container item justifyContent="end" alignItems="center" width={"auto"}>
                    {templateHeader && templateHeader?.itemHeader?.map((f, i) => {
                        if (f.name === "UOM") {
                            return (
                                <>
                                    <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>Ordered Quantity: </Typography>
                                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, ml: 2 }}>{order.totalQuantity} {order.formData[f.name]}</Typography>
                                </>
                            )
                        }
                    })}
                    {
                        order.status == "partialReceived"
                            ?
                            <Button disabled size='small' sx={{ ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none" }}>
                                {getCamelCaseText(statusText[order.status])} ({order.receivedQuantity})
                            </Button>
                            :
                            <></>
                    }
                    {
                        order.status == "received" || order.status == "incoming" || order.status == "arrived"
                            ?
                            <Button disabled size='small' sx={{ ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none" }}>
                                {getCamelCaseText(statusText[order.status])} ({["incoming","arrived"].includes(order.status) ?(order?.totalQuantity  || 0):(order?.receivedQuantity || 0)})
                            </Button>
                            :
                            <></>
                    }
                    {
                        order.status == "drafted"
                            ?
                            <Button disabled size='small' sx={{ ml: 2, borderRadius: "16px", backgroundColor: statusColor[order.status], color: "white !important", textTransform: "none" }}>{getCamelCaseText(statusText[order.status])}</Button>
                            :
                            <></>

                    }
                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", ml: 2 }}>
                        {
                            expanded ? "Hide" : "Show"
                        }
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreOutlined sx={{ color: theme.themeOrange }} />
                        </ExpandMore>
                    </Typography>

                </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider />
                <Grid container sx={{ pt: 2 }}>
                    {
                        templateHeader && templateHeader?.itemHeader?.map((f, i) => {
                            // console.log(order.formData[f.name], "show values", f.name, f.label)
                            return (
                                <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
                                    <Grid container>
                                        <Grid container item sm={4} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{f.label}:</Typography>
                                        </Grid>
                                        <Grid container item sm={8} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                                                {order.formData[f.name] ?
                                                    f.type === "file" ? (
                                                        <div onClick={() => handleFile(order.formData[f.name])}>
                                                            <span
                                                                style={{
                                                                    ...buttonClasses.viewFile,
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                View File
                                                            </span>
                                                        </div>
                                                    ) : order.formData[f.name] : "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {
                        masterType == "itemMaster" && templateHeader && templateHeader?.itemMaster?.filter(h => ["SKU_ID", "UOM"].indexOf(h.name) < 0)?.map((head, i) => {
                            return (
                                <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
                                    <Grid container>
                                        <Grid container item sm={4} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>{head.label}:</Typography>

                                        </Grid>
                                        <Grid container item sm={8} xs={6}>
                                            <Typography sx={{ ...textClasses.normalText, ml: 1 }}>
                                                {/* {order.itemMasterDetail[head.name]? order.itemMasterDetail[head.name] : "-"} */}
                                                {order.itemMasterDetail[head.name] ?
                                                    head.type === "file" ? (
                                                        <div onClick={() => handleFile(order.itemMasterDetail[head.name])}>
                                                            <span
                                                                style={{
                                                                    ...buttonClasses.viewFile,
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                View File
                                                            </span>
                                                        </div>
                                                    ) :
                                                        <span style={{ marginLeft: '12px' }}>
                                                            {order.itemMasterDetail[head.name]}
                                                        </span>
                                                    : "-"
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            )
                        })
                    }
                    {order.receivingType && <Grid container item sm={6} alignItems={"center"} sx={{ mb: 0 }}>
                        <Grid container>
                            <Grid container item sm={4} xs={6}>
                                <Typography sx={{ ...textClasses.normalText, color: "#827878" }}>Receiving Type:</Typography>

                            </Grid>
                            <Grid container item sm={8} xs={6}>
                                <Typography sx={{ ...textClasses.normalText, ml: 1 }}>

                                    {ReceivingTypes.find(type => type.value === order.receivingType)?.label ?? "-"}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>}

                </Grid>
            </Collapse>

        </Paper>
    )
}

const ConfirmDialog = ({ asn, status, templateHeader, handleClose, refreshContent }) => {
    const dispatch = useDispatch();
    const [reason, setReason] = useState("");
    const [method, setMethod] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const getSpiType = (template) => {
        if (template) {
            let type;
            if (!template.otherTemplate || template.otherTemplate.length == 0) {
                type = "shipmentOnly";
            }
            else if (template.otherTemplate.length == 2) {
                type = "SPI";
            }
            else if (template.otherTemplate.length == 1 && template.otherTemplate[0].templateType == "packageDetailTemplate") {
                type = "shipmentPackageOnly";
            }
            else {
                type = "shipmentItemOnly";
            }

            return type;
        }
    }

    const allTemplates = useSelector(state => state.templates.allTemplates).filter((t) => {
        if (["packagingPI", "palletingPI", "bothPackageItem", "bothPalletItem"].includes(method) && getSpiType(t) == "SPI") {
            return true;
        }
        if (["pallets", "packages"].includes(method) && ["SPI", "shipmentPackageOnly"].includes(getSpiType(t))) {
            return true;
        }
        // else if(method == "bothPackageItem" && getSpiType(t) == "SPI"){
        //     return true;
        // }
        // else if ((method == "packagingPI" || method == "palletingPI") && getSpiType(t) == "SPI") {
        //     return true;
        // }
        else if (method == "individualItems" && getSpiType(t) == "shipmentItemOnly") {
            return true;
        }
        return false;
    });

    let allMethods = [
        { label: "Pallets", value: "pallets", templateType: ["SPI", "SP"], isBoth: true, isPackage: true },
        { label: "Packages", value: "packages", templateType: ["SP", "SPI"], isPackage: true, isBoth: true },
        { label: "Both(Package & Item)", value: "bothPackageItem", templateType: ["SPI"], isBoth: true },
        { label: "Both(Pallet & Item)", value: "bothPalletItem", templateType: ["SPI"], isBoth: true },
        { label: "Loose or Individual Items", value: "individualItems", templateType: ["SI"], isItem: true },
        // {label:"Only Quantity",value:"onlyQuantity",templateType:["S"],isBoth:false,isPackage:false,isItem:false},
        // {label: "Packaging (Package & Item)",value: "packagingPI",templateType: ["SPI"],isBoth: true},
        // {label: "Palleting (Package & Item)",value: "palletingPI",templateType: ["SPI"],isBoth: true},
    ]

    const handleStatusChange = () => {
        dispatch(
            rejectAsn({
                ASN_Id: asn.ASN_Id,
                reason: reason
            }, (data) => {
                if (data) {
                    showToast(data.message, false);
                    handleClose();
                    refreshContent(asn.ASN_Id);
                }
            })
        )
    }

    useEffect(() => {
        setReason("");
        if (status == "receive") {
            dispatch(
                // getAllTemplates({type: "wms", templateType: "inwardDataEntry", status: "published"}, (data) => {
                // })
                // setSelectedTemplate
                getAllTemplates({ type: "wms", templateType: "inwardDataEntry", status: "published", fetchAttributes: true }, (data) => {
                    if (data && data.length == 1) {
                        setSelectedTemplate(data[0])
                    }
                })
            )
        }
    }, [status])

    return (
        <Dialog
            open={status != null}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                {
                    status == "receive" &&
                    <>
                        <Grid container justifyContent={"center"} alignItems="center">
                            <Typography sx={{ ...textClasses.cardTitle }}>
                                Select Inward Data Entry Template & Receiving Method
                            </Typography>
                        </Grid>
                        <Grid container sx={{ mt: 1 }}>
                            <Grid container item sm={4}>
                                <FormLabel required sx={{ mt: 1 }}>Receving Method</FormLabel>
                            </Grid>
                            <Grid container item sm={8}>
                                {
                                    allMethods?.map((m, i) => {
                                        return (
                                            <Grid item sm={6} key={i}>
                                                <FormControlLabel
                                                    onClick={() => setMethod(m.value)}
                                                    checked={method == m.value}
                                                    label={<Typography sx={{ fontSize: "13px" }}>{m.label}</Typography>}
                                                    control={<Radio value={m.value} sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} />}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 1 }}>
                            <Grid container item sm={4}>
                                <FormLabel required sx={{ mt: 1 }}>Select Template</FormLabel>
                            </Grid>
                            <Grid container item sm={8}>
                                <Autocomplete
                                    value={selectedTemplate}
                                    onChange={(e, option) => {
                                        setSelectedTemplate(option);
                                    }}
                                    getOptionLabel={(option) => capitalizeSentence(option.name || "")}
                                    size='small'
                                    options={allTemplates}
                                    sx={{
                                        ...inputClasses.filterField,
                                        minWidth: "220px",
                                        m: 0,
                                        ".MuiInputBase-input": {
                                            fontSize: "14px",
                                            color: "#455A64"
                                        }
                                    }}
                                    renderInput={(params) => <TextField sx={{ color: "#455A64", ...textClasses.normalText }} {...params} label="Template" />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ p: 1, mt: 3 }}>
                            <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitBlackFill, height: "40px", minWidth: "150px" }} onClick={handleClose}>
                                No, Cancel
                            </Button>
                            <Button
                                variant='contained'
                                size='small'
                                sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                                disabled={selectedTemplate == null || method == null}
                                onClick={() => history.push("/dashboard/data/inward", { asnDetail: {ASN_Id: asn.ASN_Id}, asnTemplateHeader: templateHeader, inwardTemplate: selectedTemplate, receivingMethod: method })}
                            >
                                Yes, {status}
                            </Button>
                        </Grid>
                    </>
                }
                {
                    status == "reject" &&
                    <>
                        <Grid container justifyContent={"center"} alignItems="center">
                            <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                        </Grid>
                        <Grid container justifyContent={"center"} alignItems="center">
                            <Typography sx={{ ...textClasses.cardTitle }}>You want to {status} the ASN</Typography>
                        </Grid>
                        <Grid container direction={"row"} sx={{ mt: 1 }}>
                            <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
                            <TextField
                                variant='outlined'
                                fullWidth
                                multiline
                                rows={3}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />

                        </Grid>
                        <Grid container sx={{ p: 1, mt: 3 }}>
                            <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitBlackFill, height: "40px", minWidth: "150px" }} onClick={handleClose}>
                                No, Cancel
                            </Button>
                            <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }} onClick={handleStatusChange}>
                                Yes, {status}
                            </Button>
                        </Grid>
                    </>
                }

            </DialogContent>
        </Dialog>
    )
}