import React from "react";
import { TextField, Typography } from "@mui/material";
import { inputClasses,textClasses } from "../../../../static/styles/theme";


const NumberField = ({ component, componentValues, disabled, handleChange, errors }) => (
  <>
    <TextField
      required={component.required}
      type="number"
      size="small"
      fullWidth
      sx={{ ...inputClasses.shadowField }}
      disabled={disabled}
      label={component.label}
      placeholder={component.placeholder}
      onChange={(event) => handleChange(component, event)}
      value={componentValues[component?._id] || ""}
    />
    {errors && errors[component._id] && (
     <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
     {errors && errors[component._id] ? errors[component._id].message : ""}
 </Typography>
    )}
  </>
);

export default NumberField
