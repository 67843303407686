
import React, { useEffect, useRef, useState } from 'react'
import { 
    TextField, Typography,Grid, Button, DialogContent,Dialog,Breadcrumbs,Paper,Card, CardHeader,
     CardContent, ButtonGroup, InputAdornment, IconButton, Autocomplete, Divider, CircularProgress,
      Tooltip,Box } from '@mui/material'
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../../static/styles/theme'
import { Link, useParams } from 'react-router-dom'
import { Add, AddOutlined, Close, CoPresentOutlined, Delete, Edit, Remove, Search, Sell } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { 
    clearConnectedMasterDetail, editAsnEntry, getAllTemplates, getAsnDetails, getSelectedTemplate,getReceivingConfig,
     getShipmentConnectedMasterDetail, getTemplateHeader, getTemplateHeaderShell, saveAsnEntry,generatePONumber
    } from '../../../../redux/actions'
import { getCamelCaseText, showToast ,validateFieldsMultiple,dateTimeFormater, showErrorDynamicField,truncateNumber} from '../../../../services/functions'
import SelectImage from "../../../../../src/static/images/data_entry/select_location.svg"
import { getFieldsLayoutForEntry, getInputFieldForTableRow } from '../../../Components/TemplateInputConfiguration'
import { useForm } from 'react-hook-form'
import { parseCamelCase } from '../../../../utils/util'
import history from '../../../../services/history'
import { handleDynamicChange,calculateTarget } from '../../../../services/formFunction'
import GenericLoader from '../../../Components/Generic/GenericLoader'
import CutomerSupplierAdd from '../../TMS/Addressbook/cutomerSupplierAdd'
import Table2 from './Table2'
import DetailDialog from './DetailDialog'
import GenericSingleRangeComponent from '../../../Components/Generic/GenericSingleRangeComponent'


export default function EditAsn() {
    const {compId} = useParams();
    const dispatch = useDispatch();
    
    const asnDetail = useSelector(state => state.asnEntry.asnDetail)
    const template = useSelector(state => state.inward.selectedTemplate);
    // const allTemplates = useSelector(state => state.templates.allTemplates);
    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);

    // //("TEMPLATE", template);
    // console.log("hi")

    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: {errors} } = useForm();
    const [items, setItems] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isDataSubmit, setIsDataSubmit] = useState(false);
    const [connectedCust_Sup_Detail, setConnectedCust_Sup_Detail] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateHeader, setTemplateHeader] = useState(null)
    const [asnID, setAsnID] = useState("")

    // const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, control: control2, reset: reset2, formState:{errors: errors2} } = useForm();


    const totalQuantity = watch("quantity");
    const countTillNow = items.map((i) => Number(i.quantity)).reduce((a, b) => a + b , 0);
    const remainingItems = totalQuantity - countTillNow;

    const [errors2, setErrors2] = useState([])
    const [configDetail, setConfigDetail] = useState({});
    const allFields = (template && template.otherTemplate) ? template.otherTemplate[0].attributes : [];

    const validateFields = () => {
        
        return validateFieldsMultiple(allFields || [], items || [])
    }

    const checkItemSubmit = () => {
        let zeroCount = items.filter(it => !it.quantity );
        if(zeroCount.length > 0){
            showToast("Item count cannot be 0", true)
            return false;
        }
        let newErrors = validateFields();
        setErrors2(newErrors);
        if(newErrors.filter(row => Object.keys(row).length != 0).length != 0){
            showToast("Form Error: Please check for errors", true);
            return false;
        } else if(remainingItems != 0){
            showToast(`Form Error: Total items should be equal to ${countTillNow + remainingItems}, currently = ${countTillNow}`, true)
            return false;
        }
        return true;
    }

    const handleDraft = (data) => {
        handleSave({...data, drafted: true})
    }

    const handleUpcoming = (data) => {
        handleSave({...data, drafted: false})
    }
    
    const handleSave = (data) => {
        let payload = {
            componentId: compId,
            shipmentData: data,
            drafted: data.drafted,
        }
        if (connectedCust_Sup_Detail?.value) {
            payload["supplierCustomerId"] = connectedCust_Sup_Detail?.value;
        }
        
        
        const itemValidation = checkItemSubmit();
        if(itemValidation){
            if(template.connectedMaster && template.connectedMaster.itemMaster){
                let nonSearched = items.filter(i => i.itemDetail == null)
                if(nonSearched.length > 0){
                    showToast(`Please finish search of Product Id ${nonSearched[0].product_ID}`, true)
                    return;
                }
            }
            payload['itemData'] = items
            // //(payload);
            setIsDataSubmit(true)
            dispatch(
                editAsnEntry(payload, (data) => {
                    setIsDataSubmit(false)
                    if(data){
                        history.push("/dashboard/inventory/asn");
                    }
                })
            )
        }
        
    }

    const searchMaster = async (value,option={}) => {
        let tempMaster = option?.value && option || {};
        setConnectedCust_Sup_Detail(tempMaster);
      };

    const handleClear = () => {
        // setSelectedTemplate(null);
        let keys = Object.keys(getValues())
        for (let i = 0; i < keys.length; i++) {
            setValue(keys[i], "");
        };
        setConnectedCust_Sup_Detail({});
        setItems([]);
        dispatch(
            clearConnectedMasterDetail()
        );
    }

    const refreshContent = (compId) => {
        setLoader(true);
        dispatch(
            getAsnDetails({componentId: compId}, (data) => {
                if(data?.shipmentDetail?.template_id){
                    if(data?.shipmentDetail?.customerDetail){
                        setConnectedCust_Sup_Detail({
                            ...data.shipmentDetail.customerDetail,
                            label:data.shipmentDetail?.customerDetail?.company,
                            value:data.shipmentDetail?.customerDetail?.blink_id,
                        })
                    }
                    setAsnID(data?.ASN_Id)
                    dispatch(getSelectedTemplate({template_id: data.shipmentDetail.template_id},async(selTem)=>{
                        // console.log("selTem",selTem);
                        if(selTem?.attributes?.length){
                            setSelectedTemplate(data.shipmentDetail.template_id);
                            dispatch(
                                getTemplateHeaderShell({template_id: data.shipmentDetail.template_id}, (data) => {
                                    if(data){
                                        setTemplateHeader(data)
                                    }
                                })
                            )
                            let shipmentFields = selTem.attributes;
                            let itemFields = selTem.otherTemplate.filter(t => t.templateType == "itemDetailTemplate")[0].attributes;
                            
                            
                            for (let i = 0; i < shipmentFields.length; i++) {
                                const field = shipmentFields[i];
                                
                                // console.log("hereeee",field._id,data.shipmentDetail.formData[field._id])
                                
                                // setValue(field._id, data?.shipmentDetail?.formData[field._id]); 
                                // console.log(field._id,data.shipmentDetail.formData[field._id])
                                setValue(field._id, data.shipmentDetail.formData[field._id])
                            }
                            
                            let localItems = [];
                            for (let i = 0; i < data.itemDetail.length; i++) {
                                const order = data.itemDetail[i];
                                let localI = {itemDetail:null}
                                
                                for (let i = 0; i < itemFields.length; i++) {
                                    const field = itemFields[i];
                                    localI[field._id] = order.formData[field._id]
                                    if(["select"].includes(field.type) && field.setTargetValue){
                                        localI.loader = true;
                                        await handleDynamicChange(localI,field,{value:localI[field._id] || ""},"itemDetail")
                                        localI.loader = false;

                                    }
                                    
                                }
                                // console.log("localI",localI);
                                // localI.itemDetail = null;
                                localI.loader = false;
                                localItems.push(localI);
                            }
                            setItems(localItems);
                            setLoader(false);
                        }
                        else{
                            // Redirect to All ASN
                            history.push("/dashboard/inventory/asn");
                        }
                    }))
                }
                else{
                    // Redirect to All ASN
                    history.push("/dashboard/inventory/asn");
                }

            })
        )
        dispatch(getReceivingConfig({ configType: "asnCreation" }, (data) => {
            setConfigDetail(data?.configDetail || {})
          }))
    }

    useEffect(() => {
        refreshContent(compId)
    }, [compId])
    
  return (
    <>
        <Typography sx={{...textClasses.cardTitle}}>Advance Shipment Notice</Typography>

        <Breadcrumbs sx={{mb: 1}}>
        <Typography sx={{...textClasses.normalText, fontSize: "12px", color: theme.themeOrange}}>
            <Link style={{color: theme.themeOrange, textDecoration: "none"}} to="/dashboard/inventory/asn">
            Advance Shipment Notice ASN
            </Link>
        </Typography>

        <Typography sx={{...textClasses.normalText, fontSize: "12px"}}>Edit ASN - {asnID}</Typography>
        </Breadcrumbs>

        <Paper elevation={1} sx={{...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%"}}>
            {/* <Grid container>
                <Grid container item sm={5} xs={5} alignContent="center">
                    <Typography sx={{...textClasses.t20n, mt: "5px"}}>Template: </Typography>
                    <Autocomplete
                        disabled
                        value={selectedTemplate}
                        onChange={(e, option) => {
                            setSelectedTemplate(option);
                        }}
                        getOptionLabel={(option) => getCamelCaseText(option.name)}
                        size='small'
                        options={allTemplates}
                        sx={{...inputClasses.shadowField,
                            minWidth: "220px",
                            m: 0,
                            ml: 2,
                            ".MuiInputBase-input": {
                            fontSize: "14px",
                            color: "#455A64"
                        }}}
                        renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Select Template" />}
                    />
                </Grid>
            </Grid> */}
            {/* <Divider sx={{m: "0 5px 0 5px", mb: 2, mt: 2}}/> */}
              {loader ?
                  <Grid
                      item={true}
                      data-testid="loader"
                      container
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems="center"
                      xs={12}
                      sx={{ p: 1 }}
                  >
                      <Grid item>
                          <GenericLoader />
                      </Grid>
                  </Grid>
                  :
                  selectedTemplate != null //&& template
                      ?
                      <>
                          <ShipmentSection
                              configDetail={configDetail}
                              template={template}
                              templateHeader={templateHeader}
                              connectedCust_Sup_Detail={connectedCust_Sup_Detail}
                              searchMaster={searchMaster}
                              formHooks={{ register: register, control: control, setValue: setValue, errors: errors }}
                          />
                          <OrderSection
                              template={template}
                              items={items}
                              setItems={setItems}
                              totalQuantity={totalQuantity}
                              countTillNow={countTillNow}
                              remainingItems={remainingItems}
                              // formHooks={{register: register, control: control, setValue: setValue, errors: errors}}
                              errors={errors2}
                          />

                          <Grid container direction={"row"} sx={{ mt: 2 }}>
                              <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px" }} onClick={handleClear}>
                                  Clear All
                              </Button>
                              {asnDetail && asnDetail?.shipmentDetail?.status == "drafted" && <Button variant='contained' size='small' sx={{ ...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2 }}
                                  disabled={isDataSubmit || validateFields()?.length > 0 || items?.length == 0}
                                  onClick={handleSubmit(handleDraft, showErrorDynamicField)}
                              >
                                  Draft
                              </Button>}
                              <Button variant='contained' size='small' sx={{ ...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2 }}
                                  disabled={isDataSubmit || validateFields()?.length > 0 || items?.length == 0}
                                  onClick={handleSubmit(handleUpcoming, showErrorDynamicField)}
                              >
                                  Submit
                              </Button>
                          </Grid>
                      </>
                      :
                      <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                          <img src={SelectImage} />
                          <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>select a template first..</Typography>
                          <Typography sx={{ ...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2 }}>
                              Hey, you need to select a previously ASN template, to enter details for upcoming shipment.
                          </Typography>
                      </Grid>
                  // <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{p: 4}}>
                  //     <img src={SelectImage} />
                  //     <Typography sx={{...textClasses.cardTitle, mt: 2}}>select a template first..</Typography>
                  //     <Typography sx={{...textClasses.t12n, textAlign: "center", maxWidth: "500px", mt: 2}}>
                  //         Hey, you need to select a previously ASN template, to enter details for upcoming shipment.
                  //     </Typography>
                  // </Grid>
              }

            
        </Paper>
    </>
        
  )
}


const ShipmentSection = ({
    template, connectedCust_Sup_Detail={},
    searchMaster, formHooks, dimensions ,configDetail={}
}) => {

    const dispatch = useDispatch();
    const { userPermissions} = useSelector((state) => state.userPermission);

    const {register, control, setValue, errors} = formHooks;

    // console.log("getValues",getValues());
    const [loader, setLoader] = useState(false);
    const [poGenerated, setPOGenerated] = useState(false);
    const allFields = template ? template.attributes.map((field, i) => {
        if(field._id=="purchaseOrderID" && poGenerated){
            field.disabled = true
        }
        return field;
    }) : [];
    // //("All Fields", allFields);
    const mandatoryFields = useSelector(state => state.canvas.components.attributes.mandatory).filter(
        (f) => f.templateType == template.templateType
    );
    const otherFields = allFields.filter(f => mandatoryFields.map(m => m._id).indexOf(f._id) < 0)

    const masterData = useSelector(state => state.asnEntry.shipmentConnectedMasterData);

    const autoFillOnFocus = () => {
        const socketInputs = ["weight", "length", "breadth", "height"]
        if(dimensions){
            for (let i = 0; i < otherFields.length; i++) {
                let compId = otherFields[i]._id
                if(socketInputs.indexOf(compId) >= 0 && dimensions[compId]){
                    setValue(compId, dimensions[compId]);
                }
            }
        }
    }

    const layout = getFieldsLayoutForEntry(allFields, register, control, autoFillOnFocus, errors);

    const dispatchOrderNoGenerator=()=>{
		setLoader("fetchPONumber");
		dispatch(generatePONumber({templateId:template?._id || ""},({success=false,message,data=""})=>{
			showToast(message,!success)
			if(success){
				formHooks.setValue("purchaseOrderID",data)
                // setPOGenerated(true);
				setLoader("");
			}
		}))
	}


    return (
        <Grid container>
            <Grid item sm={12} xs={10}>
                <Card sx={{borderRadius: "8px"}}>
                    <CardHeader
                        sx={
                            {
                                padding: "5px 15px",
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray"
                            }
                        }
                    
                        title={
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography sx={{ ...textClasses.normalText }}>1. Shipment Details</Typography>
                                {template?.attributes?.find(dl => dl?._id == "purchaseOrderID") &&
                                    <Button style={{ ...buttonClasses.lynkitOrangeEmpty }} onClick={() => dispatchOrderNoGenerator()} disabled={loader == "PO_Number" || poGenerated}>
                                        Generate {template?.attributes?.find(dl => dl?._id == "purchaseOrderID")?.label || "PO Number"}
                                    </Button>
                                }
                            </Box>
                        }
                    />
                        <CardContent>
                            <Grid container sx={{mb: 1}} spacing={2}>
                                {   
                                    layout.single.map((field, i) => {
                                        return (
                                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                                {
                                                    field
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            
                            <Grid container spacing={2} sx={{pt: 1}}>
                                {
                                    layout.multi.map((field, i) => {
                                        return (
                                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                                {
                                                    field
                                                }
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            
                             {/* Customer detail section */}
                             {userPermissions?.permissions?.find(dl=>dl.permissionId=="get_business_partners") &&  template?.connectedWithAddressbook?.at(0) &&  
                             <CutomerSupplierAdd
                                connectedCust_Sup_Detail={connectedCust_Sup_Detail}
                                searchMaster={searchMaster}
                                type={"Customer"} 
                                filter={configDetail?.filter || []}
                                header={configDetail?.header || []}
                                connectedWithAddressBook={template?.connectedWithAddressbook}
                                heading={configDetail?.heading || "Vender Detail"}

                            />}

    
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

const OrderSection = ({template, errors, dimensions, items, setItems, totalQuantity, countTillNow, remainingItems, validationCallback}) => {
    const dispatch = useDispatch();
    const masterType = (() => {
        if(template){
            if(template.connectedMaster && template.connectedMaster.itemMaster){
                return "itemMaster";
            }
        }
        return null;
    })();

    const [dialogData, setDialogData] = useState(null);
    // const [errors, setErrors] = useState({})

    const allFields = template.otherTemplate ? template.otherTemplate[0].attributes : [];
    const mandatoryFields = useSelector(state => state.canvas.components.itemTemplate.mandatory).filter(
        (f) => f.templateType == template.templateType
    );
    const otherFields = allFields.filter(f => mandatoryFields.map(m => m._id).indexOf(f._id) < 0)
    
    const autoFillOnFocus = (index) => {
        if(dimensions){
            let oldItems = [...items];
            for (let i = 0; i < otherFields.length; i++) {
                let compId = otherFields[i]._id
                if(["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]){
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            setItems(oldItems)
        }
    }

    const handleGenericChange = async(event, i, fieldId,option={}) => {
        const targetField = allFields.find(f => f._id == fieldId);
        let oldItems = [...items];
        let oldObject = {...oldItems[i]};
        if(targetField.type == "file"){
            oldObject[fieldId] = event.target.files;
        }
        else if(targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if(event.checked){
                oldValues.push(event.value);
            }
            else{
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else{
            oldObject[fieldId] = event.target.value;
        }
        if (targetField?.extraValue?.length) {
            targetField.extraValue.map(dl => {
                if (typeof dl == "string") {
                    oldObject[dl] = option[dl] || ""
                }
                else if (typeof dl == "object" && dl.label) {
                    oldObject[dl.label] = option[dl.label] || ""
                }
            })
        }
        if(["select"].includes(targetField.type) && targetField.setTargetValue){
            oldObject["itemDetail"] = null
            if (event.target.value) {
                changeLoader(i, true)
                await handleDynamicChange(oldObject, targetField, { value: event.target.value || "" }, "itemDetail")
                if (!oldObject["itemDetail"] || Object.keys(oldObject["itemDetail"]).length == 0) {
                    oldObject["itemDetail"] = null
                }
                oldObject["loader"]=false
                for(let fields of allFields){
                    let isValue=oldObject[fields?._id]
                    if(fields?.type=="checkbox"){
                        isValue=isValue?.length>0
                    }
                    else{
                        isValue=!!isValue
                    }
                    if(!isValue ){
                        let tempValue=""
                        if (fields?.setDefaultValue == true && fields?.defaultValueFunction && !fields?.renderFunction) {
                            tempValue = eval(fields?.defaultValueFunction || "")
                            if (fields?.type == "date") {
                                tempValue = dateTimeFormater(tempValue,fields?.format || "YYYY-MM-DD")
                            }
                        }
                        
                        if (fields?.renderFunction) {
                            tempValue = eval(fields.renderFunction + "('" + JSON.stringify(oldObject) + "')")
                            if (fields?.type == "date") {
                                tempValue = dateTimeFormater(tempValue,fields?.format || "YYYY-MM-DD")
                            }
                        }
                    }
                }
            }
            else {
                calculateTarget(oldObject, targetField, {})
            }
        }
        oldItems[i] = {...oldObject};
        setItems([...oldItems]);
    }

    const getTableHeader = (headerType) => {
        let base = ["Quantity", <Box sx={{minWidth:"200px"}}>Item Details</Box>]
        if(masterType == "itemMaster"){
            base.push("Detail")
        }
        let otherHeader = allFields.filter(f => ["product_ID", "quantity"].indexOf(f._id) < 0).map((of) => {
            let label = of.label;
            if(headerType == "excel" && ["select", "radio", "checkbox"].indexOf(of.type) >= 0){
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            return label
        })
        return [...base, ...otherHeader];
    }

    const getItemRow = (i) => {
        let item = items[i];

        const adornment = (
            <InputAdornment position='end'>
                <IconButton
                    onClick={() => handleSearchButton(i)}
                    disabled={item.product_ID.length < 3 || item.itemDetail != null}
                >
                    <Search/>
                </IconButton>
            </InputAdornment>
        )
        
        let base = [
            (
                <GenericSingleRangeComponent
                startRange={item.quantity || null}
                endRange={Number(remainingItems || 0) + Number(item?.quantity || 0)}
                getOnChange={(countValue) => handleCountChange(countValue, i)}
                endRangeInfinity={false}
                valueType={"decimal"}
            />
            ),
            getInputFieldForTableRow(allFields.filter(f => f._id == "product_ID")[0], items,  i, handleGenericChange, autoFillOnFocus, errors, false, masterType == "itemMaster" ? adornment : null,  (item && !!item.itemDetail))
        ]
        if(masterType == "itemMaster"){
            base.push(
                item.loader
                    ?
                        <GenericLoader/>
                    :
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange,cursor:"pointer"}} onClick={() => handleOpenDetail(i)}>
                            {
                                item.itemDetail != null
                                ?
                                    "View Details"
                                :
                                    "No data yet"
                            }

                        </Typography>
            )
        }
        const others = allFields.filter( f => ["product_ID", "quantity"].indexOf(f._id) < 0).map((of) => {
            let comp = {...of};
            
            if(comp._id == "UOM" && masterType != null && items[i][comp._id] != null && items[i][comp._id] != ""){
                comp.disabled = true;
            }
            else{
                comp.disabled = false;
            }
            return (
                getInputFieldForTableRow(comp, items,  i, handleGenericChange, autoFillOnFocus, errors, false)
            )  
        })
        
        return [...base, ...others]
    }

    const header = getTableHeader();
    const rows = items.map((item, i) => {
        return getItemRow(i);
    })
    

    // const layout = getFieldsLayoutForEntry(otherFields, register, control, autoFillOnFocus, errors);

    const handleAddRow = () => {
        // let row = {
        //     itemId: "",
        //     itemDetail: null,
        //     quantity: 1,
        //     loader: false
        // }
        // setItems([...items, row])

        let fieldsData = {}
        let fields = allFields.filter(f => f._id != "quantity")
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].type == "checkbox"){
                fieldsData[fields[i]._id] = []    
            }
            else if (fields[i].type == "file"){
                fieldsData[fields[i]._id] = null;
            }
            else{
                fieldsData[fields[i]._id] = ""
            }
        }
        let newRow = {
            quantity: 1,
            itemDetail: null,
            loader: false,
            ...fieldsData
        }
        setItems([...items, newRow]);
    }

    const handleRemoveRow = (i) => {
        let oldItems = [...items];
        oldItems.splice(i, 1)
        setItems(oldItems);
    }

    const handleEditRow = (i) => {
        let oldItems = [...items];
        oldItems[i] = {
            ...oldItems[i],
            itemDetail: null
        }
        setItems(oldItems)
    }

   
    const handleCountChange = (countValue, i) => {
        let oldItems = [...items];
        if (countValue <= 0) {
            oldItems[i].quantity = 0
        }
        else {
            let maxCap = truncateNumber(totalQuantity - (countTillNow - Number(oldItems[i].quantity)),3,true);
            oldItems[i].quantity = Math.min(maxCap, countValue)
        }
        setItems(oldItems);
    }

    const changeLoader = (i, status) => {
        let oldItems = [...items]
        oldItems[i] = {
            ...oldItems[i],
            loader: status
        }
        setItems(oldItems);
    }

    const handleSearchButton = (i) => {
        let id = items[i].product_ID;
        let payload = {
            template_id: masterType ? template.connectedMaster.itemMaster : "",
        }
        if(masterType){
            payload["SKU_ID"] = id;
        }
        
        changeLoader(i, true)
        dispatch(
            getShipmentConnectedMasterDetail(masterType, payload, (data) => {
                if(data){
                    let oldItems = [...items]
                    oldItems[i] = {
                        ...oldItems[i],
                        itemDetail: data,
                        loader: false
                    }
                    setItems(oldItems);
                }
                else{
                    let oldItems = [...items]
                    oldItems[i] = {
                        ...oldItems[i],
                        itemDetail: null,
                        loader: false
                    }
                    setItems(oldItems);
                }
            })
        )
    }

    const handleOpenDetail = (i) => {
        setDialogData(items[i].itemDetail)
    }

    const handleCloseDetail = () => {
        setDialogData(null)   
    }


    return (
        <Grid container sx={{mt: 2}}>
            <Grid item sm={12} xs={10}>
                <Card sx={{borderRadius: "8px"}}>
                    <CardHeader
                        sx={
                            {
                                padding: "5px 15px",
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray"
                            }
                        }
                    
                        title={
                            <Typography sx={{...textClasses.normalText}}>2. Order Details</Typography>
                        }
                    />
                        <CardContent>
                            <Grid container>
                                <Table2 
                                    header={header}
                                    rows={rows}
                                    remainingItems={remainingItems}
                                    handleAddRow={handleAddRow}
                                    handleRemoveRow={handleRemoveRow}
                                    handleEditRow={handleEditRow}

                                />  
                            </Grid>
                            {dialogData && <DetailDialog
                                data={dialogData}
                                onClose={handleCloseDetail}
                            />}
                            <Typography sx={{...textClasses.boldText, color: theme.themeOrange, mt: 2, ml: 2}}>
                                Total Order Quantity: {truncateNumber(Number(countTillNow || 0) || 0)}
                            </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

}

