import React, { useState, useEffect,useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomModel from '../../Components/CustomModels/CustomModel1';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import theme, { inputClasses, textClasses,buttonClasses } from '../../../static/styles/theme';
import selectDownload from "../../../..//src/static/images/data_entry/select_download.png"
import { getAllTemplates, uploadItem, getSelectedTemplate ,getItemMaster} from '../../../redux/actions';
import 'react-toastify/dist/ReactToastify.css';
import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SampleXlsx from '../../../static/file/sealsItem.xlsx'
import DownloadIcon from '@mui/icons-material/Download';
import * as xlsx from 'xlsx';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
    TableFooter, TablePagination, IconButton, Autocomplete, TextField, Button, Select, MenuItem, Grid,
    Typography, Box, FormControl, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper,
    Checkbox 
} from '@mui/material'
import { showToast } from '../../../services/functions';
import { selectStylesOverride } from "../../../utils/util";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
// import { capitalizeSentence } from '../../../services/functions'
import { modelInfoMultiple } from './Utils';
import GenericTable from '../../Components/CustomTable/GenericTable';
import GenericLoader from '../../Components/Generic/GenericLoader';
import GenericExcelLikeUI from '../../Components/Generic/GenericExcelLikeUI';
import { getExcelStarterData,exportToJsonArray } from '../../../utils/services';
 


const initialRows=10
const UploadItem = (props) => {
    const { urlPath,clearFilter,templateIndex } = props
    const dispatch = useDispatch();
    const history = useHistory();
    const allTemplates = useSelector(state => state?.templates?.allTemplates) || [];
    const fileInputRef = React.useRef();
    const [selectedTemplate, setSelectedTemplate] = useState("all");
    const [modelTableData, setModelTableData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableStatus, setTableStaus] = useState(false)
    const [uploadStatus, setUploadStaus] = useState(false)
    const template = useMemo(()=>{
        return selectedTemplate && allTemplates?.find(dl=>dl._id==selectedTemplate) ||  {};
    },[selectedTemplate,allTemplates])
    const { open, hide} = props

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const uploadTemplate = (e) => {
        e.preventDefault();
        let fileObj = e.target.files[0];

        if (fileObj && fileObj.name) {
            let fileExtension = fileObj.name.split('.');
            if (fileExtension[fileExtension.length - 1] === "xls" || fileExtension[fileExtension.length - 1] === "xlsx") {
                if (e.target.files) {
                    const reader = new FileReader();
                    let dateFormat = "YYYY-MM-DD"//'YYYY-MM-DD HH:mm:ss'
                    reader.onload = (e) => {
                        const data = e.target.result;
                        const workbook = xlsx.read(data, { type: 'binary', cellText: false, cellDates: true });
                        const sheetName = workbook.SheetNames[0];

                        const worksheet = workbook.Sheets[sheetName];
                        const result = xlsx.utils.sheet_to_json(worksheet, {
                            header: 0, defval: "",//raw: true,
                            raw: false, dateNF: dateFormat
                        });
                        if (result.length > 0) {
                            ////({result});
                            setModelTableData(result)
                        }
                    };
                    reader.readAsArrayBuffer(e.target.files[0]);

                }
            }
            else {
                fileInputRef.current.value = ""
                setModelTableData([])
                // setHeader([])
                //console.error('You can only upload excel file!')
            }
        }
        else {
            setModelTableData([])
            // setHeader([])
            fileInputRef.current.value = ""
        }
    }
    const resetData = () => {
        setModelTableData([])
        fileInputRef.current.value = ""
    }
    const uploadItems = () => {
        
        // console.log("modelTableData",modelTableData);
        // let formData=exportToJsonArray(template?.attributes?.map(field => field.label) || [],modelTableData)
        // if(!formData || formData.length==0){
        //     showToast("Minimum one SKU detail is required!", true)
        //     return;
        // }
        if(!modelTableData || modelTableData.length==0){
            showToast("Minimum one SKU detail is required!", true)
            return;
        }
        let bodyData = {
            "template_id": selectedTemplate,
            // "formData": formData
            "formData": [...modelTableData]
        }
        
        setUploadStaus(true)
        // console.log("bodyData",bodyData);
        let uploadAPIKeyword = modelInfoMultiple[urlPath]["bulkUploadAPI"]
        dispatch(uploadItem(bodyData, uploadAPIKeyword, ({success=false, message}) => {
            setUploadStaus(false)
            showToast(message, !success)
            if (success) {
                hide()
                clearFilter(selectedTemplate,true,true)
            }
        }))
    }

    const handleTemplateChange = (option) => {
        if (option?.value === 'create') {
            setTimeout(() => {
                history.push({
                    pathname: "/dashboard/templates/create",
                    state: { status: 'addnew', selectedCard: [] }
                })
            }, 50)
        }
        else if (option?.value !== 'create') {
            if (option?.value !== null) {
                setSelectedTemplate(option?.value || '')
            }
            else {
                setSelectedTemplate('')
            }
        }
        resetModelData(option?.value)
    }

    const modelTableRows = (modelTableData || [])?.map((row, i) => {
        let items = [];
        Object.keys(row || {}).map((key, j) => (
            items.push(
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
                    {row[key]}
                </Typography>
            )
        ));
        return items;
    });

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
  
    const getTableHeader = (headerType) => {

        let otherHeader = template?.attributes?.map((of) => {
            let label = of.label || "";
            // if (headerType == "excel" && ["select", "checkbox", "radio"].indexOf(of.type) >= 0) {
            //     label = `${label} (${of.values.map(o => o.value).join(",")})`
            // }
            return label
        })

        // otherHeader.push("Rack Family")
        return [...otherHeader];
    }


    const downloadSample = async () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml;charset=UTF-8";
        const fileExt = ".xlsx";

        const excelData = {};
        const theaders = getTableHeader("excel");
        for (let i = 0; i < theaders.length; i++) {
            excelData[theaders[i]] = ""
        }

        const ws = XLSX.utils.json_to_sheet([excelData]);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const buffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
        const data = new Blob([buffer], { type: fileType });
        FileSaver.saveAs(data, 'sample' + fileExt);

    }

    const resetModelData=(_id)=>{
        // let rows=getExcelStarterData(_id && allTemplates?.find(dl => dl._id == _id)?.attributes?.length || 0, initialRows)
        // setModelTableData(rows)
        setModelTableData([])
    }

    useEffect(() => {
        if (selectedTemplate != "all") {
            dispatch(getSelectedTemplate({ template_id: selectedTemplate }))
        }
       
    }, [selectedTemplate])

    useEffect(() => {
        if(allTemplates?.length >0){
            // if (allTemplates?.length == 1) setSelectedTemplate(allTemplates[0])
            // else {
                setSelectedTemplate(allTemplates[templateIndex]?._id)
                resetModelData(allTemplates[templateIndex]?._id)
            // }
        }
        else{
            setSelectedTemplate("")
            setModelTableData([])
        }
    }, [allTemplates,templateIndex]);

    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false} onClose={() => hide()}
                minWidth={300} maxWidth={open == "Add" && 1100 || 1250}
                pt={1} pb={3} pl={3} pr={3}
                maxHeight={"90vh"} viewCloseIcon={["Edit", "Add"].includes(open)}
                childrenMaxHeight={"inherit"}
                Title={["Edit", "Add"].includes(open) && `${open} Job Type`}
            >
                <Grid container padding={1} data-testid='uploadModal' className='ppmodal'>
                    <Grid item xs={24}  >
                        <Grid container width='100%'  >
                            <Grid container item sm={5} xs={5} sx={{ p: 2 }} position='relative' alignContent="center" alignItems='center' minWidth="100%">
                                <Typography sx={{ ...textClasses.t20n, mt: "5px", fontWeight: '800' }}>
                                   Upload {modelInfoMultiple[urlPath]["page_label"]} Data
                                </Typography>
                                <CloseIcon onClick={() => hide()} className='crossIconPri' />
                            </Grid>

                            <Grid container>
                                <Grid item sm={4}>
                                    <Autocomplete
                                        size='small'
                                        options={allTemplates && [{ label: '+ Create New', value: 'create', isCreateOption: true }, ...allTemplates?.map(dl => ({ label: dl.name, value: dl._id }))]}
                                        sx={{ width: 300, marginLeft: '22px' }}
                                        onChange={(e, option) => {
                                            handleTemplateChange(option)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    fontSize: "12px",
                                                    ...selectStylesOverride,
                                                }}
                                                {...params}

                                                label={<Typography sx={{ fontSize: "14px" }}>
                                                    {urlPath === 'item-master' ? "Select Item Master Template" : '' || urlPath === 'customer-master' ? "Select Customer Master Template" : '' || urlPath === 'supplier-master' ? "Select Supplier Master Template" : ''}

                                                </Typography>}
                                            />
                                        )}

                                        renderOption={(props, option) => (

                                            <li {...props} style={{ color: option.isCreateOption ? '#ff7200' : '#727376', fontSize: '14px' }}>
                                                {option.label}
                                            </li>
                                        )}
                                        value={{ value: selectedTemplate, label: allTemplates.find(dl => dl._id == selectedTemplate)?.name || "" }}
                                    />
                                </Grid>
                                <Grid container item sm={8} justifyContent="end" alignItems="end">
                                    {/* <Button
                                        variant="contained"
                                        spacing={1}
                                        className={modelTableData.length === 0 || selectedTemplate === 'all' ? "" : 'RF_uploadfile-btn'}
                                        disabled={modelTableData.length === 0 || selectedTemplate === 'all' || uploadStatus ? true : false}
                                        sx={{ ...buttonClasses.lynkitOrangeFill, height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px" }}
                                        onClick={() => uploadItems()}
                                    >
                                        <FileUploadIcon /> Upload
                                    </Button> */}
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box border={1} sx={{ padding: "5px", borderColor: 'grey.500', borderRadius: "4px", width: "fit-content",}}>
                                            <a target="_blank" rel="noopener noreferrer" download>
                                                <Button
                                                    variant="contained"
                                                    spacing={1}
                                                    className='RF_sampledownload-btn'
                                                    onClick={downloadSample}
                                                    disabled={selectedTemplate === 'all' ? true : false}
                                                    sx={{...buttonClasses.lynkitBlackFill, height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px"}}>
                                                    <DownloadIcon /> Sample Download
                                                </Button>
                                            </a>
                                            <FormControl spacing={1} sx={{ marginRight: "5px", marginTop: "2px" }}>
                                                <input
                                                    type="file"
                                                    name="upload"
                                                    id="upload"
                                                    className='RF_fileupload-input'
                                                    ref={fileInputRef}
                                                    onChange={uploadTemplate}
                                                />
                                            </FormControl>
                                            <Button
                                                variant="outlined"
                                                spacing={1}
                                                className='RF_outline-btn'
                                                onClick={() => { resetData(); setTableStaus(false) }}
                                                sx={{...buttonClasses.lynkitOrangeEmpty, marginRight: "5px", borderRadius: "8px" }}>
                                                <DeleteIcon />
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                spacing={1}
                                                className='RF_outline-btn'
                                                color="primary"
                                                sx={{...buttonClasses.lynkitOrangeEmpty, marginRight: "5px", borderRadius: "8px" }}
                                                disabled={modelTableData.length > 0 ? false : true}
                                                onClick={() => setTableStaus(true)}
                                            >
                                                <VisibilityIcon />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                spacing={1}
                                                className={modelTableData.length === 0 || selectedTemplate === 'all' ? "" : 'RF_uploadfile-btn'}
                                                disabled={modelTableData.length === 0 || selectedTemplate === 'all' || uploadStatus ? true : false}
                                                sx={{...buttonClasses.lynkitOrangeFill, height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px" }}
                                                onClick={() => uploadItems()}
                                            >
                                                <FileUploadIcon /> Upload File
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                {uploadStatus ?
                    <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                        <Grid item><GenericLoader /></Grid>
                    </Grid>
                    : tableStatus === true
                        ?
                        <Grid item sm={12} style={{ maxHeight: '85vh', minHeight: '25vh' }}>
                            <Box>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className='RF-header'> View Uploaded Excel File Data </span>
                                    <span className='curser' onClick={() => setTableStaus(false)}><CloseIcon /></span>
                                </Typography>
                            </Box>
                            <Box mt={2} sx={{ width: "inherit"}}>
                                <GenericTable
                                    header={template?.attributes?.length > 0 && template?.attributes?.map(dl => dl.label) || []}
                                    rows={modelTableRows?.length > 0 && modelTableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []}
                                    pageCount={modelTableRows.length}
                                    pageNumber={page}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPage={rowsPerPage}
                                />
                            </Box>
                        </Grid>
                        : 
                        // selectedTemplate
                        //     ? <Grid container xs={12} justifyContent="center" alignItems="start"
                        //         sx={{ p: 2 }}
                        //     >
                        //         <Box border={1} sx={{ padding: "10px", borderColor: 'grey.500', borderRadius: "4px", maxWidth: "100%" }}>
                        //             <GenericExcelLikeUI
                        //                 headers={template?.attributes?.map(field => field.label) || []}
                        //                 initialRows={initialRows}
                        //                 data={modelTableData}
                        //                 setData={setModelTableData}
                        //             />
                        //         </Box>
                        //     </Grid>
                        //     :
                            <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4, overflow: 'auto', height: "inherit" }} >
                                <img src={selectDownload} />
                                <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>{modelInfoMultiple[urlPath]["page_header"]}</Typography>
                                <Typography sx={{ ...textClasses.t13n, textAlign: "center", maxWidth: "600px", mt: 2 }}>{modelInfoMultiple[urlPath]["page_detail"]}</Typography>
                            </Grid>
                }

            </CustomModel>
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </>
    );
};

export default UploadItem;