import {
    FETCH_ERROR, FETCH_SUCCESS, FETCH_START, FETCH_SKU_GROUP_DATA, FETCH_SKU_GROUP_DETAIL, FETCH_AUTOMATION_RULES_STATUS_COUNT, FETCH_AUTOMATION_TABLE_DATA, FETCH_AUTOMATION_TASK_FIELDS, FETCH_JOB_TYPES_BY_WORKFLOW
} from "../types"
import axios from "../../services/Api";

export const submitSkuGroupRule = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('skuManagement/saveSKUPlacementRules', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getSkuGroupTableData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('skuManagement/listAllPlacementRules', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_SKU_GROUP_DATA, payload: { data: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_SKU_GROUP_DATA, payload: { data: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getSkuGroupDetail = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('skuManagement/getPlacementRuleDetails', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_SKU_GROUP_DETAIL, payload: { data: data?.data || {} } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_SKU_GROUP_DETAIL, payload: { data: {}} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};



export const getAutomationRulesStatusCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('skuManagement/getAutomationRulesCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_AUTOMATION_RULES_STATUS_COUNT, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_AUTOMATION_RULES_STATUS_COUNT, payload: null })
            }
            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
  };


export const deleteSkuGroupRule = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('skuManagement/deletePlacementRule', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const disableEnableSkuGroupRule = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('skuManagement/updateRuleStatus', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getAutomationRulesTableData = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/automation/getAutomationRules', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_AUTOMATION_TABLE_DATA, payload: { data: data?.data || [], count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_AUTOMATION_TABLE_DATA, payload: { data: [], count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getAutomationTaskFields = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('automation/getTaskFieldKeys', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_AUTOMATION_TASK_FIELDS, payload: { data: data?.data || {} } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_AUTOMATION_TASK_FIELDS, payload: { data: {}} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getJobTypeListByWorkflow = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('workflow/getJobTypesListByWorkflow', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_JOB_TYPES_BY_WORKFLOW, payload: { data: data?.data || {} } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_JOB_TYPES_BY_WORKFLOW, payload: { data: {}} })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const saveAutomationTriggerRules = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('automation/saveAutomationRules', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};