/* eslint-disable react/react-in-jsx-scope */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Checkbox,
  DialogActions,
  Button,
} from "@mui/material";
import theme, {
  textClasses,
  inputClasses,
  buttonClasses,
} from "../../../static/styles/theme";
import GenericCollapsableItemList from "../../Components/Generic/GenericCollapsableItemList";
import CloseIcon from "@mui/icons-material/Close";

import GenericTable from "../../Components/CustomTable/GenericTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import {  getSalesOrderitemList } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { removeLeadingChars, showToast } from "../../../services/functions";
import GenericLoader from "../../Components/Generic/GenericLoader";

const SelectSalesItems = ({
  open,
  handleRemoveSelectedObject,
  onClose,
  selectedItems,
  setSelectedItems,
  onSubmit,
  salesDetail,
  addedItems,
  warehouseId
}) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ limit: 100, page: 1 });
  const [gridData, setGridData] = useState([]);
  const [loader, setLoader] = useState(false);

  const {allSaleOrderItems, orderItemRows} = useSelector(state => state.SalesProcess)
  // console.log("salesss detail")
  // console.log(salesDetail);

  useEffect(() => {
      const payload = {
          Sales_Id: salesDetail.map(sd => sd.Sales_Id),
          fetch_for: "stockAlllocation",
          warehouseId:warehouseId,
          ...filter
      }
      if (open) {
         setLoader(true);
          dispatch(getSalesOrderitemList(payload, () => {
            setLoader(false);
          }))
      }
    
  }, [salesDetail, open, dispatch, filter]);
  
  useEffect(() => {
      // console.log("All Sales Order", allSaleOrderItems);
      if(allSaleOrderItems && allSaleOrderItems.length > 0){
          setGridData(allSaleOrderItems.map((oi) => ({...oi, _id: `${oi.product_ID}_${oi.Sales_Id}`})))
      }
  }, [allSaleOrderItems])



  const handleCheckChange = useCallback(
    (e, item) => {
      // item["selectedQTY"] = item.availableQty;
      if (e.target.checked) {
        setSelectedItems((prevItems) => [item, ...prevItems]);
      } else {
        setSelectedItems((prevItems) => {
          return prevItems.filter((prevItem) => prevItem._id !== item._id);
        });
      }
    },
    [setSelectedItems]
  );
  const handleCheckAll = useCallback(
    (e) => {
      if (e.target.checked) {
          setSelectedItems(
            gridData
            ?.filter((gd) => !addedItems.some((ai) => ai._id === gd._id) &&  Math.min(gd.availableCount, gd.pendingCount)>0)
            ?.map(dl=>({...dl,filledCount:Math.min(Number(dl?.availableCount || 0), Number(dl?.pendingCount || 0))})) ||[]
          );
      } else {
        setSelectedItems([]);
      }
    },
    [setSelectedItems, gridData]
  );

  const countHandler = (count_num = "", index, available_count) => {
      let temp_added = [...gridData];
      let elem = temp_added[index],
        count_convert = removeLeadingChars(count_num);

      let selectionCount;
      if (Number(count_convert) > available_count) {
        showToast(
          `Maximum count should be ${available_count} for row number ${
            index + 1
          }`,
          true
        );
        elem = { ...elem, filledCount: available_count };
        selectionCount = available_count
        temp_added.splice(index, 1, elem);
      } else {
        elem = { ...elem, filledCount: count_convert };
        selectionCount = count_convert
        temp_added.splice(index, 1, elem);
      }

      let sel_index = selectedItems.map(si => si._id).indexOf(gridData[index]._id);
      if(sel_index >= 0){
        let temp_sel = [...selectedItems];
        let elem2 = temp_sel[sel_index];
        elem2 = {...elem, filledCount: selectionCount};
        temp_sel.splice(sel_index, 1, elem2);

        setSelectedItems(temp_sel);
      }
      setGridData(temp_added);
  };

  const headers = useMemo(() => {
    return [
      <Checkbox
        size="small"
        onChange={handleCheckAll}
        disabled={gridData?.length == 0 || gridData.every(item=>addedItems.some((ai) => ai._id === item._id) || Math.min(item.availableCount, item.pendingCount)==0)}
        checked={
          selectedItems.length !== 0 && gridData.filter(
              (gd) => !addedItems.some((ai) => ai._id === gd._id)
          ).length === selectedItems.length
        }
      />,
      "SKU Number",
      "Sales Order ID",
      "Ordered QTY",
      "Pending QTY",
      "In Stock QTY",
      "Allocated QTY",
      
      // "Action",
      // "Gate-InTime",
    ];
  }, [selectedItems, handleCheckAll]);

  const handleClearFilter = (type) => {
    if (type === "all") {
      const { gateInTime, entityID, entityType, ...rest } = filter;
      setFilter({ ...rest, page: 1, limit: 100 });
    } else if (type === "gateInTime") {
      if (filter?.gateInTime) {
        const { gateInTime, ...rest } = filter;
        setFilter({ ...rest, page: 1, limit: 100 });
      }
    } else if (type === "product_ID") {
      if (filter?.product_ID) {
        const { product_ID, ...rest } = filter;
        setFilter({ ...rest, page: 1, limit: 100 });
      }
    }
  };

  const rowData = gridData?.map((item, i) => {
    const rows = [];
    // console.log(item,";;;")
    let av_c = Math.min(item.availableCount, item.pendingCount)

    item = {
      ...item,
      // filledCount: "filledCount" in item ? item.filledCount : (item.availableCount || "")
      filledCount: "filledCount" in item ? item.filledCount : (av_c || "")

    }

    rows.push(
      <Checkbox
        size="small"
        checked={
          selectedItems.length && selectedItems?.some((selectedItem) => selectedItem._id === item._id)
        }
        disabled={!item.availableCount || item.availableCount == 0 || addedItems.some((ai) => ai._id === item._id)}
        // selectedItems.length &&
        //   !!selectedItems?.find(
        //     (selectedItem) => selectedItem._id === `${item.product_ID}_${item.Sales_Id}`
        //   )
        // disabled={addedItems.length && addedItems?.find(
        //   (addedItem) => addedItem._id === `${item.product_ID}_${item.Sales_Id}`
        // )}
        onChange={(e) => handleCheckChange(e, item)}
      />
    );
    
    rows.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {item.product_ID}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {item.Sales_Id}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>
        {item.approvalQuantity}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>
        {item.pendingCount}
      </Typography>
    );

    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>
        {item.availableCount}
      </Typography>
    );
    
      //min of availableCount and pendingCount
    rows.push(
      <TextField
      disabled={!item?.availableCount || item?.availableCount==0 || addedItems.some((ai) => ai._id === item._id)}
        id="filled-number"
        // label={"Count" + " ( Available : " + item.availableCount + " )"}
        label={"Count" + " ( Available : " + av_c + " )"}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onKeyDown={(e) => {
          if (["e", "E", "+", "-"].includes(e.key)) {
            e.preventDefault();
          } else if (
            e.key == "0" &&
            (e.target.value == "" || e.target.value[0] == "0")
          ) {
            e.preventDefault();
          }
        }}
        value={item.filledCount || ""}
        onChange={(e) => countHandler(e.target.value, i, av_c)}
        // onChange={(e) => countHandler(e.target.value, i, item.availableCount)}
        size="small"
        variant="filled"
      />
    );

    // rows.push(
    //   <Typography sx={{ ...textClasses.normalText }}>
    //     {item.approvalStatus}
    //   </Typography>
    // );

    return rows;
  });

  const handleSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [onClose, onSubmit]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedItems([]);
  }, [onClose, setSelectedItems]);

  const handleFilterChange = useCallback(
    (e, filterType) => {
      if (filterType === "product_ID") {
        if (e.target.value) {
          setFilter((prev) => ({
            ...prev,
            product_ID: e.target.value,
            limit: 100,
            page: 1,
          }));
        } else {
          const { entityID, ...rest } = filter;
          setFilter(rest);
        }
      } else if (filterType === "entityType") {
        if (e) {
          setFilter((prev) => ({
            ...prev,
            entityType: e.value,
            limit: 100,
            page: 1,
          }));
        } else {
          const { entityType, ...rest } = filter;
          setFilter(rest);
        }
      } else if (filterType === "gateInTime") {
        if (e) {
          setFilter((prev) => ({ ...prev, gateInTime: e, limit: 100, page: 1 }));
        } else {
          const { gateInTime, ...rest } = filter;
          setFilter(rest);
        }
      }
    },
    [filter]
  );

  const viewClearButton = useCallback(() => {
    if (filter?.entityID || filter?.gateInTime || filter?.entityType) {
      return true;
    }
    return false;
  }, [filter]);

  const renderTableFooter = () => {
    let S = [],
      P = [],
      I = [];

    for (let ele of selectedItems) {
      if (ele?.shipmentId && !S.includes(ele.shipmentId)) {
        S.push(ele.shipmentId);
      }
      if (ele?.packageId && !P.includes(ele.packageId)) {
        P.push(ele.packageId);
      }
      if (ele?.itemId && !I.includes(ele.itemId)) {
        I.push(ele.itemId);
      }
    }

    if (S.length == 0 && P.length == 0 && I.length == 0) return;
    return (
      <Grid container sx={{ flexDirection: "row", mt: 2 }}>
        <Typography sx={{ fontWeight: "800", fontSize: ".9rem", mr: 4 }}>
          Selected QTY :
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Unique Shipments(s) :{S.length}
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Unique Package(s) : {P.length}
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Item(s) : {I.length}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"lg"}
      >
        <DialogTitle id="responsive-dialog-title" sx={{display: "flex", justifyContent: "space-between", marginRight: "1.5rem", alignItems: "center"}}>
          <Typography sx={{ ...textClasses.cardTitle }}>
            Select Inventories From List
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={2}>
              <Typography>Selected Sales Orders:</Typography>
            </Grid>
            <Grid item xs={10}>
              <GenericCollapsableItemList
                items={salesDetail}
                limit={3}
                itemSpacing={{ sm: 3, xs: 4 }}
                handleRemoveItem={handleRemoveSelectedObject}
                getContent={(itemList, i) => {
                  let itemObj = itemList[i];
                  return itemObj?.Sales_Id;
                }}
                sx={{ mb: 3 }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2}>
            {/* <Grid item xs={4} sm={2}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    value={filter?.entityID ?? ""}
                    onChange={(e) => handleFilterChange(e, "product_ID")}
                    placeholder="SKU Number"
                    sx={{
                      ...inputClasses.filterField,
                      "& input::placeholder": { fontSize: "14px" },
                      "& .MuiSelect-iconOutlined": { display: "" },
                    }}
                    endAdornment={
                      filter.entityID == "" ? (
                        <InputAdornment position="end">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ) : (
                        <IconButton
                          sx={{
                            visibility:
                              filter.entityID != "" ? "visible" : "hidden",
                            fontSize: "14px",
                          }}
                          onClick={() => handleClearFilter("product_ID")}
                        >
                          <Clear fontSize="inherit" />
                        </IconButton>
                      )
                    }
                  />
                </FormControl>
              </Grid> */}

            {viewClearButton() && (
              <Grid container item sx={4} sm={2} alignItems={"center"}>
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                    textTransform: "none",
                    marginTop: "6px",
                  }}
                  onClick={() => handleClearFilter("all")}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
          </Grid>
          {
            loader
              ?
              <Grid container justifyContent={"center"} alignItems="center">
                <GenericLoader data-testid="genericloader" />
              </Grid>
              :
              <>
                <Grid xs={12} mt={2}>
                  <GenericTable
                    header={headers}
                    rows={rowData}
                    pageCount={orderItemRows}
                    pageNumber={filter.page - 1}
                    handleChangePage={(event, pagevalue) => {
                      setFilter((f) => ({
                        ...f,
                        page: pagevalue + 1,
                      }));
                    }}
                    handleChangeRowsPerPage={(event) => {
                      setFilter((f) => ({
                        ...f,
                        page: 1,
                        limit: +event.target.value,
                      }));
                    }}
                    rowsPerPage={filter.limit}
                  />
                </Grid>
                {selectedItems?.length ? renderTableFooter(selectedItems) : ""}
              </>
          }
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "flex-start", pl: 2, pb: 2 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "130px",
                }}
                onClick={handleClose}
                disabled={loader}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  minHeight: "40px",
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                  ml: 2,
                }}
                onClick={handleSubmit}
                disabled={selectedItems.length === 0 || loader}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectSalesItems;
