import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Divider, Autocomplete, TextField } from '@mui/material';
import theme, { textClasses, buttonClasses, inputClasses } from '../../../static/styles/theme';
import { dateTimeFormater } from '../../../services/functions';

const ViewSlotBookingDetail = ({ bookedSlotData = {}, resetBookedSlotData, isInternal = true }) => {
    return (<>
        <Grid container gap={2} sx={{ mt: 1, border: `1px solid ${theme.themeGray}`, borderRadius: 3, p: 2 }}>
            <Grid item xs={12}>
                <Typography sx={{ ...textClasses.t14n }} >
                    Preferred Delivery Slot(Optional)
                </Typography>
            </Grid>
            {
                bookedSlotData?.slotFrom ?
                    <Grid container sx={{}}>

                        <Grid item xs={12} md={7}>
                            <Typography
                                sx={{
                                    ...textClasses.t14n,
                                    textAlign: "left",
                                    border: "1px solid #FF7200",
                                    bgcolor: "#ff720014",
                                    padding: "10px",
                                    color: "#FF7200",
                                    //   fontWeight: "700", // Ensures overall text is bold
                                    width: "fit-content",
                                    mt: { sm: 1, md: 0 },
                                }}
                                data-testid="bookedSlotDetail"
                            >
                                {`On`} <strong>{dateTimeFormater(bookedSlotData?.slotBookingDate, "DD MMM YYYY")}</strong>
                                {` In Between`} <strong>{dateTimeFormater(bookedSlotData?.slotFrom, "hh:mm A")}</strong>
                                {` to`} <strong>{dateTimeFormater(bookedSlotData?.slotTo, "hh:mm A")}</strong>
                                {` (`}{bookedSlotData?.slotDuration}{` min Slot)`}
                            </Typography>
                        </Grid>


                        <Grid container md={5} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                            <Grid item>
                                <Button
                                    data-testid="cancel-btn"
                                    variant="outlined"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        backgroundColor: "#fff",
                                        border: "1px solid #E9001A",
                                        color: "#E9001A",
                                        ml: 1,
                                        "&:hover": {
                                            backgroundColor: "#E9001A",
                                            border: "1px solid #E9001A",
                                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                            color: 'white'
                                        }
                                    }}
                                    onClick={() => {
                                        const storageKey = isInternal ? "slotsBooked" : "e-slotsBooked";
                                        localStorage.removeItem(storageKey);
                                        resetBookedSlotData();
                                    }}
                                    size="medium"
                                >
                                    Cancel Slot
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    data-testid="change-btn"
                                    variant="outlined"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        backgroundColor: "#3E621E",
                                        border: "1px solid #3E621E",
                                        ml: 1,
                                        "&:hover": {
                                            backgroundColor: "#3E621E",
                                            border: "1px solid #3E621E",
                                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                        }
                                    }}
                                    size="medium"
                                    onClick={() => {
                                        window.open(isInternal ? '/dashboard/bookSlot' : '/bookSlot', '_blank' )
                                    }}
                                >
                                    Change Slot
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                    :
                    <Grid item sx={{}}>

                        <Button variant="outlined"
                            size="medium"
                            data-testid="delivery-btn"
                            sx={{
                                ...buttonClasses.lynkitBlackFill,
                                width: 200,
                                // ml: 2
                            }}
                            onClick={() => {
                                window.open(isInternal ? '/dashboard/bookSlot' : '/bookSlot', '_blank' )
                            }}
                        >
                            Select Delivery Slot
                        </Button>
                    </Grid>
            }

        </Grid>
    </>);

}

export default ViewSlotBookingDetail;