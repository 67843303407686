import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Breadcrumbs, Tooltip, FormControl, Button, Divider, Grid, OutlinedInput, Autocomplete, Paper, IconButton, Box, Checkbox, TextField, Typography } from '@mui/material'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../static/styles/theme";
import {  showToast, convertInExcel } from "../../../../services/functions";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { selectStylesOverride } from '../../../../utils/util';
import { ruleCategoryOptions, component } from './constant';
import { submitSkuGroupRule } from '../../../../redux/actions/AutomationRule';
import TypeAndSearch from '../../../Components/TypeAndSearch';
import GenericFileUpload from '../../../Components/Generic/GenericFileUpload';
import GenericAutoSizerComponent from '../../../Components/Generic/GenericAutoSizerComponent';
import history from '../../../../services/history';
import * as xlsx from 'xlsx';


const CreateSkuGroup = () => {
    const dispatch = useDispatch();
    const [groupRule, setGroupRule] = useState({
        selectedRuleCategoryOption: null,
        ruleGroupName: "",
        selectedSkuList: []
    })
    const [checkedItems, setCheckedItems] = useState({}); // Store the checked state for each item
    const [excelDataEntry, setExcelDataEntry] = useState({ file: "" });
    const [dataReceieved, isDataReceived] = useState(false)

    // Function to handle checkbox toggle and clearing state if unchecked
    const handleCheckboxChange = (index, fieldId) => {
        setCheckedItems(prevState => {
            const isChecked = !prevState[index]; // Determine the new checked state
            const updatedCheckedItems = {
                ...prevState,
                [index]: isChecked // Update checked state
            };

            // Remove the fieldId from groupRule if unchecked
            if (!isChecked) {
                setGroupRule(prevGroupRule => {
                    const { [fieldId]: _, ...restGroupRule } = prevGroupRule; // Remove the fieldId
                    return restGroupRule; // Return the updated state without the removed field
                });
            }
            return updatedCheckedItems;
        });
    };


    const isDisableUpload = useMemo(() => {
        return !excelDataEntry?.file
    })

    const handleDeleteFile = () => {
        setExcelDataEntry({ ...excelDataEntry, file: "" })
    }


    const handleFileChange = useCallback((file) => {
        setExcelDataEntry(prev => ({
            ...prev,
            file: file.target.files,
        }))
    }, [setExcelDataEntry])

    const handleUploadFile = (e, callback) => {
        e.preventDefault();
        let fileObj = excelDataEntry?.file[0];

        if (fileObj && fileObj?.name) {
            let fileExtension = fileObj.name.split('.');
            if (fileExtension[fileExtension.length - 1] === "xls" || fileExtension[fileExtension.length - 1] === "xlsx") {
                if (excelDataEntry?.file) {
                    const reader = new FileReader();
                    let dateFormat = "YYYY-MM-DD"//'YYYY-MM-DD HH:mm:ss'
                    reader.onload = (e) => {
                        const data = e.target.result;
                        const workbook = xlsx.read(data, { type: 'binary', cellText: false, cellDates: true });
                        const sheetName = workbook.SheetNames[0];

                        const worksheet = workbook.Sheets[sheetName];
                        const result = xlsx.utils.sheet_to_json(worksheet, {
                            header: 0, defval: "",
                            raw: false, dateNF: dateFormat
                        });
                        if (result?.length > 0) {
                            if(result.some(dl=>!dl?.SKU_ID.trim())){
                                showToast("SKU_ID can not contain space!",true)
                                return ;
                            }
                            let existingSkuId = result.find(dl=>groupRule?.selectedSkuList?.map(val=>val?.value)?.includes(dl?.SKU_ID))
                            if (existingSkuId) {
                                showToast(`SKU_ID : ${existingSkuId?.SKU_ID} already exists!`, true)
                                return;
                            }
                            ////({result});
                            // setGroupRule({...groupRule,selectedSkuList:result?.map((dl)=>({ value:dl?.SKU_ID,label:dl?.SKU_ID}))}) this will overwrite
                            setGroupRule({
                                ...groupRule,
                                selectedSkuList: [
                                    ...(groupRule.selectedSkuList || []), // Spread the existing array if it exists, or use an empty array
                                    ...result.map((dl) => ({ value: dl?.SKU_ID, label: dl?.SKU_ID })) // Append new items
                                ]
                            });
                            handleDeleteFile();
                        }
                    };
                    reader.readAsArrayBuffer(excelDataEntry?.file[0]);
                }
            }
            else {
                callback()
                setGroupRule({ ...groupRule, selectedSkuList: [] })
            }
        }
        else {
            setGroupRule({ ...groupRule, selectedSkuList: [] })
            callback()
        }
    }

    const handleSubmitData = (clickType) => {
        isDataReceived(true)
        const { selectedSkuList, selectedRuleCategoryOption, ruleGroupName, ...rest } = groupRule;
        const payload = {
            SKU_IDs: selectedSkuList?.map(dl => dl?.value) || [],
            ruleCategory: selectedRuleCategoryOption?.value || "",
            ruleName: ruleGroupName,
            options: { ...rest } // All other fields go into 'options'
        }
        if(payload?.ruleCategory!= "canNotBePlacedTogether"){
            delete payload?.options;
        }
        dispatch(submitSkuGroupRule(payload, (data) => {
            showToast(data.message, !data.success);
            isDataReceived(false)
            if (data.success) {
                if (clickType == "submit") {
                    history.push("/dashboard/setting/automation-rules")
                } else {
                    window.location.reload()
                }
            }
        }))
    }

    // Set all checkboxes to true initially
    useEffect(() => {
        if (groupRule?.selectedRuleCategoryOption?.options) {
            const initialCheckedState = {};
            groupRule.selectedRuleCategoryOption.options.forEach((_, index) => {
                initialCheckedState[index] = true; // Set each checkbox as true
            });
            setCheckedItems(initialCheckedState);
        }
    }, [groupRule?.selectedRuleCategoryOption]);

    return (<>
        <Typography sx={{ ...textClasses.cardTitle }}>Automation Rules</Typography>

        <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-new-kit">
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/setting/automation-rules">
                    Settings / Automation Rules
                </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText }}>SKU Group & Placement Rule</Typography>
        </Breadcrumbs>
        <Grid container sx={{ mt: 2 }}>
            <Paper elevation={1} sx={{ ...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%" }}>
                <Grid container mb={2}>
                    <Grid item sm={6} xs={6}>
                        <Typography sx={{ ...textClasses.t15nBold }}>Create SKU Group</Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="space-between" spacing={1} mb={2}>
                    <Grid item xs={3} data-testid="skuId-select">
                        <TypeAndSearch
                            data-testid="skuFilter"
                            component={component({ type: "SKU",filter:[{label:"dataEntryType",value:["item","package","kit"]}] })}
                            // set_Value={true}
                            setEmptyOnChange={true}
                            handleChange={(e, option) => {
                                const fetchBlock = option?.value.split("_")
                                const existingBlock = fetchBlock && fetchBlock[0] && groupRule?.selectedSkuList?.find((option) => option.value === fetchBlock[0])
                                if (existingBlock) {
                                    showToast("This SKU_ID is already selected!", true)
                                } else
                                    if (
                                        option &&
                                        !groupRule?.selectedSkuList.find(
                                            (item) => item.value === option.value
                                        )
                                    ) {
                                        setGroupRule({ ...groupRule, selectedSkuList: [option, ...groupRule?.selectedSkuList] });
                                    }
                            }}
                        />
                    </Grid>
                    <Grid item xs={.5} xl={1.5}>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography sx={{ ...textClasses.normalText, mx: 1, }}>OR</Typography>
                    </Grid>
                    <Grid item xs={.5} xl={1.5}>
                        <Divider />
                    </Grid>
                    <Grid item xs={7} xl={5.5} data-testid="generic-upload">
                        <GenericFileUpload
                            payload={excelDataEntry}
                            previewAvailable={false}
                            onChange={handleFileChange}
                            onUpload={handleUploadFile}
                            onDelete={handleDeleteFile}
                            handleDownload={() => convertInExcel([{ label: "SKU_ID" }], 'SKU_Group_Sample_File')}
                            isDisableUpload={isDisableUpload}
                        // viewExcel={viewExcel}
                        />
                    </Grid>

                </Grid>

                {groupRule?.selectedSkuList?.length === 0 ? null :
                    <GenericAutoSizerComponent
                        mappedData={groupRule?.selectedSkuList?.map(
                            (dl) => dl.value
                        ) || []}
                        containerHeight="220px"
                        onDelete={(index) => {
                            const filteredData = groupRule?.selectedSkuList?.filter((_, i) => i !== index);
                            setGroupRule({ ...groupRule, selectedSkuList: filteredData });
                        }}
                    />
                }
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography sx={{ ...textClasses.t15nBold }}>Rule Category</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            data-testid="select-template-dropdown"
                            value={groupRule?.selectedRuleCategoryOption}
                            onChange={(e, option) => {
                                setGroupRule({ ...groupRule, selectedRuleCategoryOption: option })
                            }}
                            // getOptionLabel={(option) => getCamelCaseText(option.label)}
                            size="small"
                            options={ruleCategoryOptions}
                            sx={{
                                ...inputClasses.textField,
                                ...selectStylesOverride,
                                minWidth: "220px",
                                m: 0,
                                // ml: 2,
                                ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64",
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ color: "#455A64", ...textClasses.normalText }}
                                    {...params}
                                    label="Select Rule Category"
                                />
                            )}
                        />
                    </Grid>


                </Grid>
                {groupRule?.selectedRuleCategoryOption && groupRule?.selectedRuleCategoryOption?.options?.length > 0 &&(
                    <>
                        <Grid container spacing={2} mt={2} mb={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ ...textClasses.t15nBold }}>
                                    Rule Gap Distance
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {groupRule?.selectedRuleCategoryOption?.options.map((item, index) => (
                                <Grid container item alignItems="center" gap={2} xs={4} mt={3} key={index}>
                                    {/* Checkbox and Label together */}
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={!!checkedItems[index]} // Check if the item is checked
                                            onChange={() => handleCheckboxChange(index, item?.fieldId)} // Toggle checkbox state and pass fieldId
                                        />
                                        <Typography sx={{ ...textClasses.normalText }}>
                                            {item?.name}
                                        </Typography>
                                    </Grid>

                                    {/* OutlinedInput or Autocomplete on the same line, to the right */}
                                    <Grid item sx={{ width: '30%' }}>
                                        {item?.type === "number" && (
                                            <FormControl sx={{ width: '100%' }}>
                                                <OutlinedInput
                                                    value={groupRule[item?.fieldId] || ''} // Bind value based on fieldId
                                                    onChange={(e) =>
                                                        setGroupRule(prevGroupRule => ({
                                                            ...prevGroupRule,
                                                            [item?.fieldId]: Number(e.target.value) // Ensure value is a number
                                                        }))
                                                    }
                                                    onWheel={(event) => event.target.blur()}
                                                    onKeyDown={(e) => {
                                                        if (['e', 'E', '+', '-'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                        else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    type="number"
                                                    sx={{
                                                        fontSize: '12px',
                                                        width: '100%',
                                                        height: '35px',
                                                        ...selectStylesOverride,
                                                        '& input::placeholder': {
                                                            fontSize: '12px',
                                                        },
                                                    }}
                                                    disabled={!checkedItems[index]} // Disable if checkbox is unchecked
                                                />
                                            </FormControl>
                                        )}

                                        {item?.type === "dropdown" && (
                                            <Autocomplete
                                                value={item?.dropdownOptions.find(option => option.value === groupRule[item?.fieldId]) || null
                                                } // Bind the selected value
                                                onChange={(e, option) => {
                                                    setGroupRule(prevGroupRule => ({
                                                        ...prevGroupRule,
                                                        [item?.fieldId]: option?.value // Store selected option in groupRule
                                                    }));
                                                }}
                                                options={item?.dropdownOptions || []} // Ensure dropdownOptions is defined
                                                getOptionLabel={(option) => option.label || ""} // Display the label of the option
                                                size="small"
                                                sx={{
                                                    ...inputClasses.textField,
                                                    ...selectStylesOverride,
                                                    minWidth: "200px",
                                                    ".MuiInputBase-input": {
                                                        fontSize: "14px",
                                                        color: "#455A64",
                                                    },
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{ color: "#455A64", ...textClasses.normalText }}
                                                        {...params}
                                                        label={`Select ${item?.name}`}
                                                    />
                                                )}
                                                disabled={!checkedItems[index]} // Disable if checkbox is unchecked
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            ))}

                        </Grid>
                    </>
                )}

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <Typography sx={{ ...textClasses.t15nBold }}>Rule Group Name</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                            <OutlinedInput
                                value={groupRule?.ruleGroupName}
                                onChange={(e) => {
                                    setGroupRule({ ...groupRule, ruleGroupName: e.target.value })
                                }}
                                sx={{
                                    fontSize: "12px",
                                    width: '100%',
                                    height: '35px',
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                // disabled={action === "view" ? true : false}
                                // error={errorMessage.name ? true : false}
                                placeholder='Enter Group Name'
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction={"row"} sx={{ mt: 6 }}>
                    <Button variant="outlined" sx={{ ...buttonClasses.lynkitBlackFill }} onClick={() => window.location.reload()}>Cancel</Button>
                    <Button variant="outlined" sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 2 }} onClick={() => handleSubmitData("submitAndCreateNew")} disabled={!groupRule?.selectedRuleCategoryOption || !groupRule?.ruleGroupName || groupRule?.selectedSkuList?.length < 2 || dataReceieved}>Submit & Create A New</Button>
                    <Button variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 2 }} onClick={() => handleSubmitData("submit")} disabled={!groupRule?.selectedRuleCategoryOption || !groupRule?.ruleGroupName || groupRule?.selectedSkuList?.length < 2 || dataReceieved}>Submit</Button>
                </Grid>


            </Paper>
        </Grid>

    </>)
}

export default CreateSkuGroup;